import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF, DATE_FORMAT } from "../admin.model";
import * as moment from "moment";

export const INSTALLATION_INVERTERS_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const INSTALLATION_INVERTERS_COL_DEFS: ColDef[] = [
  {
    field: 'installationInverterId',
    headerName: 'ID',
    checkboxSelection: function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'name',
    headerName: 'Nombre',
  },
  {
    field: 'code',
    headerName: 'Código',
  },
  {
    field: 'nominalPower',
    headerName: 'Potencia nominal',
  },
  {
    field: 'peakPower',
    headerName: 'Potencia pico',
  },
  {
    field: 'serialNumber',
    headerName: 'Número de serie',
  },
  {
    field: 'installationId',
    headerName: 'Instalación asociada',
    valueFormatter: function (r) {
      if (r && r.data) {
        const installation = r.data.installation;
        return installation ? installation.name : '';
      }
    },
  },
  {
    field: 'creationDate',
    headerName: 'Fecha de alta',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.creationDate);
        return date.isValid() ? r.data.creationDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'deletionDate',
    headerName: 'Fecha de baja',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.deletionDate);
        return date.isValid() ? r.data.deletionDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'note',
    headerName: 'Nota',
  },
  {
    field: 'readingStartDate',
    headerName: 'Fecha inicio lecturas',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.readingStartDate);
        return date.isValid() ? r.data.readingStartDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'lastReadDate',
    headerName: 'Fecha última lectura',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.lastReadDate);
        return date.isValid() ? r.data.lastReadDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'lastEventDate',
    headerName: 'Fecha último evento',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.lastEventDate);
        return date.isValid() ? r.data.lastEventDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'activated',
    headerName: 'Activo',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkboxComponent',
  },
  {
    field: 'deviationProduction',
    headerName: 'Desviación producción',
  },
  {
    field: 'deviationMeter',
    headerName: 'Desviación contador',
  },
  {
    field: 'instantTimeOut',
    headerName: 'Time out contadores instantáneos',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'historicalTimeOut',
    headerName: 'Time out contadores históricos',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'dataLogger',
    headerName: 'Data logger',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkboxComponent',
  },
  {
    field: 'ip',
    headerName: 'Dirección IP',
  },
  {
    field: 'port',
    headerName: 'Puerto TCP',
  },
];
