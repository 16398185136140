import { Component, EventEmitter, AfterViewInit, Renderer2, ElementRef, ViewChild, Output, OnInit, Input } from '@angular/core';
import { MapLoaderService } from './map-loader.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PVSelfInstallationField } from 'src/app/modules/pvself/models/PvSelfCalculation.model';

declare const google;
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements AfterViewInit {
  @Output() drawData = new EventEmitter<any>();
  @Output() clearAll = new EventEmitter<any>();
  @Output() places = new EventEmitter<any>();
  @Input('initialLatitude') initialLatitude: number;
  @Input('initialLongitude') initialLongitude: number;
  @Input('ubication') ubication: string;
  @Input('disabled') disabled: boolean;
  @Input('polygon') inputPolygon: [];

  statePlaces = true;
  mapId: string;
  loaderId: string;
  map: any;
  drawingManager: any;
  poly: any;
  autocomplete: any;
  input: any;
//  allOverlays = [];
  polygon: any;
  placesId: string;
  marker: any;

  constructor(public renderer: Renderer2, private elem: ElementRef, private ngxLoader: NgxUiLoaderService) {
    this.mapId = 'map-' + Math.floor((Math.random() * 100) + 1);
    this.placesId = 'places-' + Math.floor((Math.random() * 100) + 1);
    this.loaderId = 'loader-map-' + Math.floor((Math.random() * 100) + 1);
  }


  ngAfterViewInit() {
    this.ngxLoader.startLoader(this.loaderId); // start non-master loader
    MapLoaderService.load().then(() => {
      this.loadMap();
    });
  }

  loadMap() {
    const self = this;
    this.map = new google.maps.Map(document.getElementById(this.mapId), {
      center: { lat: this.initialLatitude, lng: this.initialLongitude },
      zoom: 18,
      zoomControl: true,
      mapTypeId: 'satellite',
      heading: 90,
      tilt: 0,
      disableDefaultUI: true
    });
    this.input = document.getElementById(this.placesId);
    this.autocomplete = new google.maps.places.Autocomplete(this.input);
    this.autocomplete.addListener('place_changed', () => {
      const place = this.autocomplete.getPlace();
      this.onPlaceChanged(place);
      this.clearAll.emit();
    });

    this.marker = new google.maps.Marker({
      position: { lat: this.initialLatitude, lng: this.initialLongitude },
      map: this.map,
    });

    if (this.inputPolygon !== undefined && this.inputPolygon.length > 0) {
      this.polygon = null;
      this.drawPolygonFromObject(this.inputPolygon);
    }

    google.maps.event.addListenerOnce(this.map, 'idle', function () {
      self.ngxLoader.stopLoader(self.loaderId);
    });
  }


  // updateMapLocation(latitude:number, longitude: number) {
  //   this.map.setCenter(new google.maps.LatLng(latitude, longitude));
  // }

  onPlaceChanged(place) {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    this.places.emit(
      {
        lat: lat,
        lng: lng
      }
    );
    this.map.panTo(place.geometry.location);
    this.marker.setPosition(place.geometry.location);
    this.map.setZoom(18);
  }

//   removeLast() {
// //    this.drawPolygonFromObject([]);
//     // debugger
//     // if (this.allOverlays.length > 0) {
//     //   this.allOverlays[this.allOverlays.length - 1].overlay.setMap(null);
//     //   this.allOverlays.splice(-1, 1);
//     // }
//   }

  clearDraw() {
    this.polygon.setMap(null);
    this.drawData.emit({ path: [], surface: 0 });
    this.polygon = null;
  }

  drawPolygon() {
    const self = this;
//    this.removeLast();
    if (this.polygon != null) {
      this.polygon.setMap(null);
    }

    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        strokeColor: '#eb6e34',
        fillColor: '#eb6e34',
        fillOpacity: 0.3,
        strokeWeight: 5,
        clickable: true,
        editable: true,
        draggable: true,
        zIndex: 1
      }
    });

    this.drawingManager.setMap(this.map);
    google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
      this.polygon = event.overlay;
//     this.allOverlays.push(event);
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        self.drawingManager.setDrawingMode(null);
        const surface: number = google.maps.geometry.spherical.computeArea(event.overlay.getPath());
        self.drawData.emit({ path: event.overlay.getPath(), surface: surface.toFixed(2) });
      }
      google.maps.event.addListener(event.overlay.getPath(), 'set_at', (e) => {
        const surface: number = google.maps.geometry.spherical.computeArea(event.overlay.getPath());
        self.drawData.emit({ path: event.overlay.getPath(), surface: surface.toFixed(2) });
      });
    });
  }




  drawPolygonFromObject(data) {
    const self = this;
    if (this.polygon != null) {
      this.polygon.setMap(null);
    }
    this.polygon = new google.maps.Polygon({
      paths: data,
      strokeColor: '#eb6e34',
      fillColor: '#eb6e34',
      fillOpacity: 0.3,
      strokeWeight: 5,
      clickable: true,
      editable: true,
      draggable: true,
      zIndex: 1
    });
    this.polygon.setMap(this.map);
    google.maps.event.addListener(this.polygon.getPath(), 'set_at', function (event) {
      const surface: number = google.maps.geometry.spherical.computeArea(self.polygon.getPath());
      self.drawData.emit({ path: self.polygon.getPath(), surface: surface.toFixed(2) });
    });
//    this.drawingManager.setDrawingMode(null);
  }

}
