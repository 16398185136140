export class Role {
    roleId: number;
    description: number;
    activated: boolean;
    rolePermissions ?: RolePermission[];
}


export class RolePermission {
    roleId: number;
    permissionId: number;
}
