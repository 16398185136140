import { NgModule } from '@angular/core';
import { PvselfComponent } from './pvself.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AngularResizedEventModule } from 'angular-resize-event';
import { LayoutModule } from '@angular/cdk/layout';
import { AdminPvselfModule } from './admin-pvself/admin-pvself.module';
import { MatSelectModule } from '@angular/material/select';
import { ConsumerModule } from './calculation/consumer/consumer.module';
import { PreDimensionedModule } from './calculation/pre-dimensioned/pre-dimensioned.module';
import { ConfigModule } from './calculation/config/config.module';
import { CalculationComponent } from './calculation/calculation.component';
import { ResultsModule } from './calculation/results/results.module';
import { PVSelfCalculationService } from './services/pvself-calculation.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { PPlusTabsModule } from 'projects/pplus/src/public_api';
import { NbCardModule } from '@nebular/theme';
import { AuthGuard } from 'src/app/@core/_auth/auth.guard';
import { MatButtonModule, MatIconModule, MatSnackBarModule } from '@angular/material';
import { PVSelfProducerModule } from './calculation/producer/pvself-producer.module';
import { PVSelfCalendarHeaderComponent } from './shared/components/pvself-calendar-header/pvself-calendar-header.component';
import { SizeBasicModule } from '../size-basic/size-basic.module';
import { PVSelfReportModule } from './calculation/pvself-report/pvself-report.module';

export const PvselfRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: PvselfComponent
  },
];
@NgModule({
  declarations: [
    PvselfComponent,
    CalculationComponent,
    PVSelfCalendarHeaderComponent
  ],
  imports: [
    RouterModule.forChild(PvselfRoutes),
    MatSelectModule,
    MatButtonModule,
    CommonModule,
    FormsModule,
    AngularResizedEventModule,
    LayoutModule,
    TranslateModule,
    NgxUiLoaderModule,
    ReactiveFormsModule,
    NbCardModule,
    MatIconModule,
    PPlusTabsModule,
    AdminPvselfModule,
    ConsumerModule,
    PreDimensionedModule,
    ConfigModule,
    ResultsModule,
    PVSelfProducerModule,
    MatSnackBarModule,
    SizeBasicModule,
    PVSelfReportModule
  ],
  exports: [
    PvselfComponent,
    //    AdminPvselfModule
  ],
  providers: [
    PVSelfCalculationService,
  ]
})
export class PvselfModule { }
