import { Validators } from '@angular/forms';
import { Fields, DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';

const GENERALES: Fields [] = [
  {
    disabled: true,
    name: 'entityDomainStyleId',
    placeholder: 'ID',
    type: 'text',
    value: '',
  },
  {
    name: 'description',
    placeholder: 'DESCRIPTION',
    type: 'text',
    value: '',
    validators: [
      Validators.required
    ]
  },
  {
    name: 'colors',
    placeholder: 'COLORS',
    type: 'text',
    value: '',
  },
  {
    name: 'css',
    placeholder: 'CSS',
    type: 'text',
    value: '',
    validators: [
      Validators.required
    ]
  },
  // {
  //   name: 'logo',
  //   placeholder: 'LOGO',
  //   type: 'text',
  //   value: '',
  //   validators: [
  //     Validators.required
  //   ]
  // },
  {
    name: 'entityDomainId',
    label: 'ENTITY OWNER',
    type: 'select',
    validators: [
      Validators.required
    ],
    value: '',
    options: []
  },
  {
    name: 'activated',
    placeholder: 'ACTIVATED',
    type: 'checkbox',
    value: false,
  },
];

export const STYLES_FORM: DynamicForm [] = [
  {
    title: 'DATOS GENERALES',
    open: true,
    fields: GENERALES,
  },
];
