import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Profile } from '../../models/permissions/profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  constructor(private httpClient: HttpClient) {
  }

  getList(): Observable<Profile[]> {
    return this.httpClient.get<Profile[]>(environment.API_URL + '/api/admin/profiles/getList').pipe(
      map(data => data));
  }

  update(profile: Profile): Observable<Boolean> {
    return this.httpClient.post<Boolean>(environment.API_URL + '/api/admin/profiles/update', profile).pipe(
      map(data => data));
  }

  create(profile: Profile): Observable<Boolean> {
    return this.httpClient.post<Boolean>(environment.API_URL + '/api/admin/profiles/create', profile).pipe(
      map(data => data));
  }

  delete(profileId: number): Observable<Boolean> {
    return this.httpClient.post<Boolean>(environment.API_URL + '/api/admin/profiles/delete/', profileId).pipe(
      map(data => data));
  }
}
