import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { map } from 'rxjs/operators';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { AppTypeService } from '../app-type.service';
import { IEntityAppType } from 'projects/pplus/src/lib/interfaces/interfaces';
import { AdminService } from '../../admin.service';
import { ApplicationTypeDetailsService } from './application-type-details.service';

@Component({
  selector: 'app-application-type-details',
  templateUrl: './application-type-details.component.html',
  styleUrls: ['./application-type-details.component.scss']
})
export class ApplicationTypeDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  data: any;

  formGroup: FormGroup;
  formData: DynamicForm[];

  constructor(
    private dataRow: RowDataService,
    private appTypeService: AppTypeService,
    private dialog: NbDialogService,
    private adminService: AdminService,
    private applicationTypeDetailsService: ApplicationTypeDetailsService,
  ) {
    this.formData = this.applicationTypeDetailsService.getDynamicForm();
  }

  generateForm(data: DynamicForm[]) {
    const fieldsCtrls = {};
    data.forEach(item => {
      const fields = item.fields;
      fields.forEach(f => {
        fieldsCtrls[f.name] = new FormControl(f.value , f.validators);
      });
    });
    this.formGroup = new FormGroup(fieldsCtrls);
  }

  ngOnInit() {
    this.dataRow.currentRowData.pipe(map(o => {
      this.formData.forEach(data => {
        const fields = data.fields;
        fields.map(field => {
          if (o[field.name]) {
            field.value = o[field.name];
          } else field.value = '';
        });
      });
      return o;
    })).subscribe(data => {
      this.data = data;
      this.generateForm(this.formData);
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IEntityAppType>this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.appTypeService.createAppType(data).subscribe(newAppType => {
        if (newAppType != null) {
          this.data.applicationTypeId = newAppType.applicationTypeId;
          this.formGroup.patchValue({applicationTypeId: this.data.applicationTypeId});
          this.adminService.showToast('success',
              'Se ha creado el nuevo tipo de aplicación: ' + data.name,
              'Elemento creado');
          this.updateDelete.emit(true);
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al crear el tipo de aplicación: ' + data.name,
              'Error');
        }
      });
    }
  }

  update() {
    if (this.data.applicationTypeId == null) {
      this.onSubmit();
    } else if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IEntityAppType>this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.appTypeService.updateAppType(this.data.applicationTypeId, data).subscribe(success => {
        if (success) {
          this.adminService.showToast('success',
              'Se ha actualizado el tipo de aplicación: ' + data.name,
              'Elemento actualizado');
          this.updateDelete.emit();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al actualizar el tipo de aplicación: ' + data.name,
              'Error');
        }
      });
    }
  }

  close() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  delete() {
    if (this.data.applicationTypeId == null) {
      this.adminService.showToast('info', 'Seleccione un tipo de aplicación', 'Info');
    } else {
      this.appTypeService.deleteAppType(this.data.applicationTypeId).subscribe(success => {
        if (success) {
          this.adminService.showToast('success',
              'Se ha borrado el tipo de aplicación: ' + this.data.name,
              'Elemento eliminado');
          this.updateDelete.emit();
          this.clearData();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al borrar el tipo de aplicación: ' + this.data.name,
              'Error');
        }
      });
    }
  }

  copy() {
    if (this.data.applicationTypeId == null) {
      this.adminService.showToast('info', 'Seleccione un tipo de aplicación para copiar la información', 'Info');
    } else {
      this.formGroup.setValue(Object.assign(this.data, this.formGroup.value, {
        applicationTypeId: null,
        name: '',
      }));
    }
  }

  clearData() {
    this.data = {};
    this.formGroup.reset();
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.applicationTypeId == null) {
      this.adminService.showToast('info', 'No hay ningún tipo de aplicación seleccionado', 'Info');
    } else {
      this.dialog.open(dialog, { context: '¿Desea eliminar el tipo de aplicaciones?', hasScroll: false });
    }
  }

}
