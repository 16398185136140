import {Injectable} from '@angular/core';
import {Observable, BehaviorSubject} from 'rxjs';
import {TreeData} from '../../../models/components/tree-data.model';
import {environment} from '../../../../../../../src/environments/environment';
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

const data = [
  {
    id: 1,
    text: 'entity_1',
    selected: true,
    children: [
      {
        id: 2,
        text: 'entity_1.1',
        children: null,
        selected: false,
      },
      {
        id: 3,
        text: 'entity_1.2',
        children: null,
        selected: true,
      },
      {
        id: 4,
        text: 'entity_1.3',
        children: null,
        selected: true,
      },
    ],
  },
  {
    id: 1,
    text: 'entity_2',
    selected: false,
    children: [
      {
        id: 2,
        text: 'entity_2.1',
        children: null,
        selected: true,
      },
      {
        id: 3,
        text: 'entity_2.2',
        children: null,
        selected: false,
      },
      {
        id: 4,
        text: 'entity_2.3',
        children: null,
        selected: true,
      },
    ],
  },
];


const params = new HttpParams().set('token', localStorage.getItem('token'));


@Injectable({
  providedIn: 'root'
})
export class EntitySelectorService {
  private data = new BehaviorSubject(<TreeData []>[]);
  private _entitiesApiUrl = environment.API_URL + '/api/entity/byUser';
  currentdata = this.data.asObservable();
  fakeData = data;

  constructor(private http: HttpClient) {
    // this.data.next(this.fakeData);

    // this.getAllEntities().subscribe((response) => {
    //   const entitiesModified: TreeData[] = [];
    //   for (const entity of response) {

    //     const tree = new TreeData;
    //     tree.id = entity.entityId;
    //     tree.text = entity.description;
    //     tree.selected = false;
    //     tree.children = [];

    //     if (entity.entities.length > 0) {
    //       for (const childEntity1 of entity.entities) {
    //         const tree2 = new TreeData;
    //         tree2.id = childEntity1.entityId;
    //         tree2.text = childEntity1.description;
    //         tree2.selected = false;
    //         tree2.children = [];
    //         if (childEntity1.entities.length > 0) {
    //           for (const childEntity2 of childEntity1.entities) {
    //             const tree3 = new TreeData;
    //             tree3.id = childEntity2.entityId;
    //             tree3.text = childEntity2.description;
    //             tree3.selected = false;
    //             tree2.children.push(tree3);
    //           }
    //         }
    //         tree.children.push(tree2);
    //       }

    //     }
    //     entitiesModified.push(tree);
    //   }

    //   this.data.next(entitiesModified);
    // });

  }

  getAllEntities(): Observable<any> {
    return this.http
      .get(this._entitiesApiUrl)
      .pipe(map((response: any) => {
        const entitiesModified: TreeData[] = [];
        for (const entity of response) {
          const tree = new TreeData;
          tree.id = entity.entityId;
          tree.text = entity.description;
          tree.selected = false;
          tree.children = [];
          if (entity.entities && entity.entities.length > 0) {
            for (const childEntity1 of entity.entities) {
              const tree2 = new TreeData;
              tree2.id = childEntity1.entityId;
              tree2.text = childEntity1.description;
              tree2.selected = false;
              tree2.children = [];
              if (childEntity1.entities.length > 0) {
                for (const childEntity2 of childEntity1.entities) {
                  const tree3 = new TreeData;
                  tree3.id = childEntity2.entityId;
                  tree3.text = childEntity2.description;
                  tree3.selected = false;
                  tree2.children.push(tree3);
                }
              }
              tree.children.push(tree2);
            }

          }
          entitiesModified.push(tree);
        }
        this.data.next(entitiesModified);
      }));
  }

  changeData(obj: TreeData []): void {
    this.data.next(obj);
  }
}
