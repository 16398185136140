import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pplus-user-error',
  templateUrl: './user-error.component.html',
  styleUrls: ['./user-error.component.scss']
})
export class UserErrorComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {

    (async () => {
      await this.delay(6000);
      this.router.navigateByUrl('/auth/login');
    })();
  }




  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
