import { NgModule} from '@angular/core';
import { CommonModule, DecimalPipe} from '@angular/common';
import { SelfviewComponent} from './selfview.component';
import { FormsModule} from '@angular/forms';
import { CdkTreeModule} from '@angular/cdk/tree';
import { NgxEchartsModule} from 'ngx-echarts';
import { MatSelectModule} from '@angular/material/select';
import { NbDatepickerModule, NbCalendarModule, NbCardModule, NbPopoverModule, NbToastrService, NbToastrModule} from '@nebular/theme';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatInputModule} from '@angular/material/input';
import { MatIconModule} from '@angular/material/icon';
import { MatTableModule} from '@angular/material/table';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatButtonModule} from '@angular/material/button';
import { MatTabsModule} from '@angular/material/tabs';
import { HttpErrorHandler} from '../../http-error-handler.service';
import { NgxUiLoaderModule, NgxUiLoaderConfig} from 'ngx-ui-loader';
import { TranslateModule } from '@ngx-translate/core';
import { ThirdCardComponent } from './selfview-general/selfview-maximeter/selfview-maximeter.component';
import { SecondCardComponent } from './selfview-general/selfview-coverage/selfview-coverage.component';
import { FirstCardComponent } from './selfview-general/selfview-main/selfview-main.component';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { CountUpModule } from 'countup.js-angular2';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatTooltipModule, MatPaginatorModule, MatSortModule, MatRadioModule, MatChipsModule, MatAutocompleteModule } from '@angular/material';
import { PPlusTabsModule, PPlusAgGridModule } from 'projects/pplus/src/public_api';
import { AuthGuard } from 'src/app/@core/_auth/auth.guard';
import { Routes, RouterModule } from '@angular/router';
import { TabsComponent } from 'projects/pplus/src/lib/components/tabs/tabs.component';
import { SelfviewGeneralComponent } from './selfview-general/selfview-general.component';
import { SelfviewMeterGeneratedComponent } from './selfview-meter-generated/selfview-meter-generated.component';
import { MeterModule } from 'projects/pplus/src/lib/components/elements/meter/meter.module';
import { ConsumptionBorderMeterModule } from 'projects/pplus/src/lib/components/elements/consumption-border-meter/consumption-border-meter.module';
import { SelfviewMeterConsumedComponent } from './selfview-meter-consumed/selfview-meter-consumed.component';
import { SelfviewMeterCosphiComponent } from './selfview-meter-cosphi/selfview-meter-cosphi.component';
import { SelfviewMeterDataDownloadComponent } from './selfview-meter-data-download/selfview-meter-data-download.component';
import { SelfviewMainTableComponent } from './selfview-general/selfview-main/selfview-main-table/selfview-main-table.component';
import { PPlusTimePickerModule } from 'projects/pplus/src/lib/components/time-picker/time-picker.module';
import { SelfviewPeriodTableComponent } from './selfview-general/selfview-maximeter/selfview-period-table/selfview-period-table.component';
import { SelfviewMeterTotalizerComponent } from './selfview-meter-totalizer/selfview-meter-totalizer.component';
import { SelfviewReactiveComponent } from './selfview-reactive/selfview-reactive.component';
import { TotalsChartComponent } from './selfview-reactive/reactive-totals-series/totals-chart/totals-chart.component';
import { ReactivePeriodsComponent } from './selfview-reactive/reactive-periods/reactive-periods.component';
import { ReactiveTotalSeriesComponent } from './selfview-reactive/reactive-totals-series/reactive-totals-series.component';
import { AgGridModule } from 'ag-grid-angular';
import { SelfViewActiveComponent } from './selfview-active/self-view-active.component';
import { SelfviewActivePeriodsComponent } from './selfview-active/selfview-active-periods/selfview-active-periods.component';
import { SelfviewActiveCoverageComponent } from './selfview-active/selfview-active-coverage/selfview-active-coverage.component';
import { ReactiveMainTableComponent } from './selfview-reactive/reactive-main/reactive-main-table/reactive-main-table.component';
import { ReactiveMainComponent } from './selfview-reactive/reactive-main/reactive-main.component';
import { TotalsTableComponent } from './selfview-reactive/reactive-totals-series/totals-table/totals-table.component';
import { ReactivePeriodsChartComponent } from './selfview-reactive/reactive-periods/reactive-periods-chart/reactive-periods-chart.component';
import { ReactivePeriodsTableComponent } from './selfview-reactive/reactive-periods/reactive-periods-table/reactive-periods-table.component';
import { SelfviewZeroInjectionComponent } from './selfview-zero-injection/selfview-zero-injection.component';
import { ZeroInjectionEfficaciesComponent } from './selfview-zero-injection/zero-injection-efficacies/zero-injection-efficacies.component';
import { ZeroInjectionInstantComponent } from './selfview-zero-injection/zero-injection-instant/zero-injection-instant.component';
import { SelfviewWeatherStationComponent } from './selfview-weather-station/selfview-weather-station.component';
import { SelfviewActiveMainComponent } from './selfview-active/selfview-active-main/selfview-active-main.component';
import { SelfviewActivePeriodsChartComponent } from './selfview-active/selfview-active-periods/selfview-active-periods-chart/selfview-active-periods-chart.component';
import { SelfviewActivePeriodsTableComponent } from './selfview-active/selfview-active-periods/selfview-active-periods-table/selfview-active-periods-table.component';
import { SelfviewActiveMainTableComponent } from './selfview-active/selfview-active-main/selfview-active-main-table/selfview-active-main-table.component';
import { WeatherStationForecastComponent } from './selfview-weather-station/weather-station-forecast/weather-station-forecast.component';
import { WeatherStationsComponent } from './selfview-weather-station/weather-stations/weather-stations.component';
import { WeatherStationInstantComponent } from './selfview-weather-station/weather-station-instant/weather-station-instant.component';
import { SelfviewConsumptionMeterComponent } from './selfview-consumption-meter/selfview-consumption-meter.component';
import { SelfviewBorderMeterComponent } from './selfview-border-meter/selfview-border-meter.component';
import { SelfviewInvertersComponent } from './selfview-inverters/selfview-inverters.component';
import { InvertersMainBlockComponent } from './selfview-inverters/inverters-main-block/inverters-main-block.component';
import { InvertersInstantBlockComponent } from './selfview-inverters/inverters-instant-block/inverters-instant-block.component';
import { InvertersElementBlockComponent } from './selfview-inverters/inverters-element-block/inverters-element-block.component';
import { PPlusReportsModule } from 'projects/pplus/src/lib/components/pplus-reports/pplus-report.module';


export const SelfviewRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: SelfviewComponent
  }
];

const SELFVIEW_CONFIG: NgxUiLoaderConfig = {
  logoUrl: 'assets/loader.svg',
  fgsColor: 'rgba(255,255,255,0)',
  overlayColor: 'rgba(0,0,0,0.8)',
  logoSize: 180,
  blur: 15,
};
@NgModule({
  declarations: [
    SelfviewComponent,
    ThirdCardComponent,
    SecondCardComponent,
    FirstCardComponent,
    SelfviewGeneralComponent,
    SelfviewMeterGeneratedComponent,
    SelfviewMeterConsumedComponent,
    SelfviewMeterCosphiComponent,
    SelfviewMainTableComponent,
    SelfviewPeriodTableComponent,
    SelfviewMeterDataDownloadComponent,
    SelfviewMeterTotalizerComponent,
    SelfviewReactiveComponent,
    SelfviewZeroInjectionComponent,
    ReactiveMainComponent,
    ReactiveTotalSeriesComponent,
    TotalsChartComponent,
    ReactivePeriodsComponent,
    SelfViewActiveComponent,
    SelfviewActivePeriodsComponent,
    SelfviewActiveCoverageComponent,
    ReactiveMainTableComponent,
    TotalsTableComponent,
    ReactivePeriodsChartComponent,
    ReactivePeriodsTableComponent,
    SelfviewActiveMainComponent,
    SelfviewActivePeriodsChartComponent,
    SelfviewActivePeriodsTableComponent,
    SelfviewActiveMainTableComponent,
    SelfviewWeatherStationComponent,
    WeatherStationInstantComponent,
    WeatherStationsComponent,
    WeatherStationForecastComponent,
    ZeroInjectionInstantComponent,
    ZeroInjectionEfficaciesComponent,
    SelfviewConsumptionMeterComponent,
    SelfviewBorderMeterComponent,
    SelfviewInvertersComponent,
    InvertersMainBlockComponent,
    InvertersInstantBlockComponent,
    InvertersElementBlockComponent
  ],
  imports: [
    RouterModule.forChild(SelfviewRoutes),
    TranslateModule,
    CommonModule,
    FormsModule,
    CdkTreeModule,
    NgxEchartsModule,
    MatSelectModule,
    NbDatepickerModule.forRoot(),
    NbCalendarModule,
    MatCheckboxModule,
    MatInputModule,
    MatIconModule,
    MatTableModule,
    MatDatepickerModule,
    MatButtonModule,
    MatTabsModule,
    NgxUiLoaderModule,
    MatSlideToggleModule,
    CountUpModule,
    NgxDaterangepickerMd,
    MatTooltipModule,
    PPlusTabsModule,
    NbCardModule,
    NbPopoverModule,
    MatPaginatorModule,
    NbToastrModule.forRoot(),
    MatSortModule,
    PPlusTimePickerModule,
    MeterModule,
    ConsumptionBorderMeterModule,
    PPlusAgGridModule,
    AgGridModule,
    MatRadioModule,
    MatChipsModule,
    MatAutocompleteModule,
    PPlusReportsModule
  ],
  exports: [
    SelfviewComponent
  ],
  providers: [
    HttpErrorHandler,
    NbToastrService,
    DecimalPipe
  ],
  entryComponents: [
    TabsComponent
  ]
})
export class SelfviewModule {
}
