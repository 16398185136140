import { Component, OnInit, ViewChild } from '@angular/core';
import { PVSelfCalculationService } from '../../../services/pvself-calculation.service';
import { PVSelfEngineService } from '../../../services/pvself-engine.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PVSelfCalculation } from '../../../models/PvSelfCalculation.model';
import * as moment from 'moment';
import * as localization from 'moment/locale/es';
import { DaterangepickerComponent, LocaleConfig, DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { PVSelfBalanceResults } from '../../../models/PVSelfResultsModel.model';
moment.updateLocale('es', localization);


@Component({
  selector: 'pplus-pvself-balance-results',
  templateUrl: './pvself-balance-results.component.html',
  styleUrls: ['./pvself-balance-results.component.scss']
})
export class PVSelfBalanceResultsComponent implements OnInit {
  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;
  balanceResults: PVSelfBalanceResults;
  _calculation: PVSelfCalculation;
  loaderId: string;
  picker: DaterangepickerComponent;
  rangeSelected: DateRange;
  typeCalculation: number;    // 1 = Energy, 2 = Economic

  locale: LocaleConfig = {
    applyLabel: 'Aceptar',
    format: 'DD/MM',
    firstDay: 1,
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort()
  };

  ranges: any = {
    'Año completo': [moment().year(2018).month(0).date(1), moment().year(2018).month(11).date(31)],
  };


  constructor(private calculationService: PVSelfCalculationService,
    private engineService: PVSelfEngineService,
    private ngxLoaderService: NgxUiLoaderService) { }

  ngOnInit() {
    this.typeCalculation = 1;

    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });

    this.loaderId = 'pplus-pvself-balance-results-component' + Math.floor((Math.random() * 100) + 1);
    this.picker = this.pickerDirective.picker;
    this.picker.minDate = moment().year(this._calculation.calculateYear).month(0).date(1).hour(0).minute(0).second(0);
    this.picker.maxDate = moment().year(this._calculation.calculateYear).month(11).date(31).hour(0).minute(0).second(0);
    this.rangeSelected = {
      startDate: this.picker.minDate,
      endDate: this.picker.maxDate,
    };

  }

  calcular() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.engineService.calculateBalanceResults(this._calculation).subscribe(res => {
      this.balanceResults = res;
      this.ngxLoaderService.stopLoader(this.loaderId);
    },
      err => {
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
  }

  rangeChange(date: DateRange) {
    if (date !== null && date.startDate !== null && date.endDate !== null) {
      this._calculation.balanceInputData.from = moment([date.startDate.toDate().getFullYear(), date.startDate.toDate().getMonth(), date.startDate.toDate().getDate(), 0, 0, 0]).utcOffset(0, true).toDate(); //date.startDate.toDate();
      this._calculation.balanceInputData.to = date.endDate.endOf('day').toDate();
      this.calcular();
    }
  }

  setTypeCalculation(e) {
    this.typeCalculation = e;
  }

}



class DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

