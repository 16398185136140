import { SelfviewModule } from './modules/selfview/selfview.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NbThemeService, NbThemeModule, NbLayoutModule, NbSidebarModule, NbCardModule, NbCheckboxModule, NbMenuModule } from '@nebular/theme';
import { AuthGuard } from './@core/_auth/auth.guard';
import { CoreModule } from './@core/core.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AdminModule } from './modules/admin/admin.module';
import { PvselfModule } from './modules/pvself/pvself.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { HttpErrorHandler } from './http-error-handler.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GestureConfig, MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { CommonModule } from '@angular/common';
import { PPlusRequestInterceptor } from 'projects/pplus/src/lib/interceptors/pplus.interceptor';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { IsAuthorizedDirective } from './@core/_auth/directives/isAuthorized.directive';
import { IsAuthorizedModule } from './@core/_auth/directives/isAuthorized.module';
import { UserErrorModule } from './user-error/user-error.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

/**
 * Date Formats
 */
export const PPLUS_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbEvaIconsModule,
    FormsModule,
    CoreModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NbLayoutModule,
    NbSidebarModule,
    AdminModule,
    PvselfModule,
    SelfviewModule,
    NbCardModule,
    NbCheckboxModule,
    MatCheckboxModule,
    DashboardModule,
    UserErrorModule,
    NbMenuModule.forRoot()
  ],
  providers: [
    NbThemeService,
    AuthGuard,
    HttpErrorHandler,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: GestureConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PPlusRequestInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-Es' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: PPLUS_DATE_FORMATS },
  ],
  bootstrap: [
    AppComponent,
  ],
})
export class AppModule { }
