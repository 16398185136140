import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IUser, UserContacts, UserFinancials, UserProfiles } from './user.interface';
import { USER_COL_DEFS } from './user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUsers = environment.API_URL + '/api/admin/user';
  private apiUsersContacts = environment.API_URL + '/api/admin/usercontact';
  private apiUsersFinancial = environment.API_URL + '/api/admin/userfinancial';
  private apiUsersProfiles = environment.API_URL + '/api/admin/userprofile';

  constructor(private http: HttpClient) {}

  getAllUsersInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const body = {
      startRow,
      endRow,
      sortModel,
      filterModel
    };
    const url = `${this.apiUsers}/get-users`;
    return this.http
      .post(url, body)
      .pipe(map((response) => {
        return <any>response;
      }));
  }

  getAllUsers(): Observable<IUser[]> {
    return this.http.get(this.apiUsers).pipe(
      map(body => <IUser[]>body),
      catchError(() => of([]))
    );
  }

  getUsersList(): Observable<IUser[]> {
    return this.http.get(this.apiUsers + '/list').pipe(
      map(body => <IUser[]>body),
      catchError(() => of([]))
    );
  }

  getUser(id: number): Observable<IUser> {
    return this.http.get(`${this.apiUsers}/${id}`).pipe(
      map(body => <IUser>body),
      catchError(() => of(null))
    );
  }

  createUser(user: IUser): Observable<number> {
    return this.http.post(this.apiUsers, user, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? response.body : null),
      catchError(() => of(null))
    );
  }

  updateUser(user: IUser): Observable<boolean> {
    return this.http.put(`${this.apiUsers}/${user.userId}`, user, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  delete(userId: number): Observable<boolean> {
    return this.http.delete(`${this.apiUsers}/${userId}`, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  createOrUpdateContact(contact: UserContacts): Observable<boolean> {
    if (contact.userContactId === undefined || contact.userContactId === null) {
      return this.http.post(this.apiUsersContacts, contact, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else {
      return this.http.put(
        this.apiUsersContacts + '/' + contact.userContactId + '/' + contact.userId, contact, {observe: 'response'}
      ).pipe(
        map(response => response.status === 204),
        catchError(() => of(false))
      );
    }
  }

  deleteContact(contactId: number): Observable<boolean> {
    return this.http.delete(this.apiUsersContacts + '/' + contactId, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  createOrUpdateFinancial(financial: UserFinancials): Observable<boolean> {
    if (financial.userFinancialId === undefined || financial.userFinancialId === null) {
      return this.http.post(this.apiUsersFinancial, financial, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else {
      return this.http.put(
        this.apiUsersFinancial + '/' + financial.userFinancialId + '/' + financial.userId,
        financial, {observe: 'response'}
      ).pipe(
        map(response => response.status === 204),
        catchError(() => of(false))
      );
    }
  }

  deleteFinancial(financialId: number): Observable<boolean> {
    return this.http.delete(this.apiUsersFinancial + '/' + financialId, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  createProfiles(userProfiles: UserProfiles): Observable<boolean> {
    return this.http.post(this.apiUsersProfiles, userProfiles, {observe: 'response'}).pipe(
      map(response => response.status === 200),
      catchError(() => of(false))
    );
  }

  updateProfiles(profile: UserProfiles): Observable<boolean> {
    return this.http.put(`${this.apiUsersProfiles}/${profile.userProfileId}`, profile, {observe: 'response'}).pipe(
      map(res => res.status === 204),
      catchError(() => of(false))
    );
  }

  getUsersGeneralFilter(
    value: string, startRow: number, endRow: number, sortModel: any, filterModel: any
  ): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of USER_COL_DEFS) columnList.push(colDef.field);
    const body = {
      startRow,
      endRow,
      sortModel,
      filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel
      },
      columnList,
    };
    const url = `${this.apiUsers}/get-users/filterAll`;
    return this.http.post(url, body).pipe(map((response) => {
      return <any>response;
    }));
  }

}
