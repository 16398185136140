export const SPECIFICINSTALATIONDATA_HEADERS = [
  {
    headerName: 'ID',
    rowDrag: true,
    minWidth: 100,
    field: 'Id',
    filter: 'agTextColumnFilter',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
    headerCheckboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
    resizable: true,
    status: false,
  },


  {
    headerName: 'Id Pais',
    minWidth: 100,
    field: 'Id_country',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Nombre Característica',
    rowDrag: true,
    minWidth: 100,
    field: 'caracteristicName',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
];
