import { Component, OnInit, ViewChild } from '@angular/core';
import { PVSelfLossesComponent } from './pvself-losses/pvself-losses.component';
import { PVSelfElectricConfigComponent } from './pvself-electric-config/pvself-electric-config.component';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

  @ViewChild(PVSelfElectricConfigComponent, { static: true }) electricConfigComponent: PVSelfElectricConfigComponent;
  @ViewChild(PVSelfLossesComponent, { static: true }) lossesComponent: PVSelfLossesComponent;


  constructor() { }

  ngOnInit() {
  }

  changedResultsView(event) {
    if (event.selectedIndex === 0) { this.electricConfigComponent.calculatePIConfigs(); }
    if (event.selectedIndex === 1) { this.lossesComponent.calculateLosses(); }
  }


  calculatePIConfigs() {
    this.electricConfigComponent.calculatePIConfigs();
  }

}
