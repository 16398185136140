import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, AfterContentInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ReplaySubject, Subject, interval } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { MatSelect } from '@angular/material';

@Component({
  selector: 'lib-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit, OnDestroy {
  @Input() field: any = {};
  @Input() form: FormGroup;
  @ViewChild('multiSelect', { static: false }) multiSelect: MatSelect;

  public filterOpts: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  protected _onDestroy = new Subject<void>();

  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }

  constructor() { }

  ngOnInit() {

    if (this.field.disabled) {
      this.form.controls[this.field.name].disable();
    }

    if (this.field.multiple) {
      const int = setInterval(() => {
        this.filterOpts.next([...this.field.options]);
        if (this.field.options && this.field.options.length > 0) {
          clearInterval(int);
        }
      }, 500);

      this.form.controls[this.field.searchName].valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.filter(res);
        });
    }
  }


  protected filter(res) {
    if (!this.field.options) {
      return;
    }

    let search = res;
    if (!search) {
      this.filterOpts.next([...this.field.options]);
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filterOpts.next(
      this.field.options.filter(opt => opt.label.toLowerCase().indexOf(search) > -1)
    );
  }

  ngOnDestroy() {
    if (this.field.multiple) {
      this._onDestroy.next();
      this._onDestroy.complete();
    }
  }
}
