import { Component,  OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NbTokenService, NbAuthSimpleToken } from '@nebular/auth';
import { takeWhile } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserExternal, User } from 'projects/pplus/src/lib/models/AllModels';

@Component({
  selector: 'lib-callback-linkedin',
  template: '',
})
export class CallbackLinkedinComponent implements OnDestroy {
  alive = true;
  private urlAccessToken = 'https://www.linkedin.com/oauth/v2/accessToken';
  private urlLinkedInProfile = 'https://api.linkedin.com/v2/me?projection=(id,firstName,lastName,profilePicture(displayImage~:playableStreams))';
  private urlLinkedInEmail = 'https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))';

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private httpClient: HttpClient,
    private nbTokenService: NbTokenService,
  ) {
    this.activatedRouter.queryParams.subscribe(queryParams => {
      this.httpClient.post(
        environment.API_URL + '/api/user/linkedin-get-token',
        {
          Code: queryParams['code'],
          RedirectUri: environment.URL_CALLBACK + '/auth/callback/linkedin',
          ClientId: '86a0ritvsrh21z',
          ClientSecret: 'IXVnOtCGqENv7TBc',
          TokenUrl: this.urlAccessToken,
        }
      ).pipe(takeWhile(() => this.alive)).subscribe(res => {
        const accessToken = res['access_token'];
        if (accessToken) {
          this.nbTokenService.set(
            new NbAuthSimpleToken(accessToken, 'linkedin')
            // new NbAuthOAuth2Token(res['access_token'], 'linkedin')
            // new NbAuthOAuth2Token({token: res['access_token']}, 'linkedin')
          );
          this.httpClient.post(
            environment.API_URL + '/api/user/linkedin-get-profile',
            {
              UrlProfile: this.urlLinkedInProfile,
              UrlEmail: this.urlLinkedInEmail,
              AccessToken: accessToken,
            }
          ).subscribe((res: any) => {
            if (res.profile && res.email) {
              const user: UserExternal = {
                provider: 'linkedin',
                token: accessToken,
                fullName: this.getFullName(res.profile),
                email: this.getEmail(res.email),
                picture: res.profile.profilePicture ? res.profile.profilePicture.displayImage : ' ',
                providerkey: res.profile.id,
              };
              this.httpClient.post(environment.API_URL + '/api/user/external-login', user).subscribe((res: any) => {
                if (res) {
                  const user: User = {
                    token: res.token,
                    email: res.email,
                    fullName: res.fullName,
                    userId: res.userId,
                    picture: res.picture,
                    role: res.role,
                    language: res.language,
                    sessionId: res.sessionId,
                  };
                  localStorage.setItem('pplus', JSON.stringify(user));
                }
                this.router.navigateByUrl('/');
              });
            }
          });
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  private getFullName(profile: any): string {
    let fullName = '';
    if (profile) {
      let firstName = '';
      if (profile.firstName && profile.firstName.localized && profile.firstName.preferredLocale) {
        firstName = profile.firstName.localized[
          profile.firstName.preferredLocale.language + '_' + profile.firstName.preferredLocale.country
        ];
      }
      let lastName = '';
      if (profile.lastName && profile.lastName.localized && profile.lastName.preferredLocale) {
        lastName = profile.lastName.localized[
          profile.lastName.preferredLocale.language + '_' + profile.lastName.preferredLocale.country
        ];
      }
      if (firstName) fullName += firstName;
      if (firstName && lastName) fullName += ' ';
      if (lastName) fullName += lastName;
    }
    return fullName;
  }

  private getEmail(resEmail: any): string {
    let email = '';
    if (resEmail.elements && resEmail.elements[0] && resEmail.elements[0]['handle~']) {
      email = resEmail.elements[0]['handle~'].emailAddress;
    }
    return email;
  }

}
