import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SizeLogCalculation } from '../models/sizeLogCalculation.model';

@Injectable({
  providedIn: 'root'
})
export class SizeBasicLogCalculationsService {

  constructor(private httpClient: HttpClient) { }

  getList(desde: Date, hasta: Date): Observable<SizeLogCalculation[]> {

    const From = (desde.getMonth() + 1) + '-' + desde.getDate() + '-' + desde.getFullYear();
    const To = (hasta.getMonth() + 1) + '-' + hasta.getDate() + '-' + hasta.getFullYear();

    return this.httpClient.get<SizeLogCalculation[]>(
      environment.API_URL + '/api/size/logcalculations/GetList?From=' + From + '&To=' + To + '&origin=3').pipe(data => data);
  }
}
