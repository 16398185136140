import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import { PVSelfCalculation, PVSelfInstallationField, PVSelfFieldTechnologyType, PVSelfInclinationType, PVSelfOrientationType, PVSelfCalculationProducer } from 'src/app/modules/pvself/models/PvSelfCalculation.model';
import { SafeStyle, DomSanitizer } from '@angular/platform-browser';
import { PVSelfCalculationService } from 'src/app/modules/pvself/services/pvself-calculation.service';
import { PVSelfEngineService } from 'src/app/modules/pvself/services/pvself-engine.service';

@Component({
  selector: 'pplus-pvself-installation-field',
  templateUrl: './pvself-installation-field.component.html',
  styleUrls: ['./pvself-installation-field.component.scss']
})
export class PVSelfInstallationFieldComponent implements OnInit {
  @Input('tabCreationData') tabCreationData: any;
  _calculation: PVSelfCalculation;
//  _producer: PVSelfCalculationProducer;
  optimalInclination: number;
  selectedField: PVSelfInstallationField;

  @ViewChild('circle', { static: true }) circle: ElementRef;
  @ViewChild('circle2', { static: true }) circle2: ElementRef;
  @ViewChild('ppline', { static: true }) red: ElementRef;
  @ViewChild('pplygon', { static: true }) gris: ElementRef;
  @ViewChild('pplineScnd', { static: true }) rojo: ElementRef;
  @ViewChild('pplygonScnd', { static: true }) gray: ElementRef;
  @ViewChild('house', { static: true }) house: ElementRef;
  @ViewChild('formedm', { static: true }) edm: ElementRef;
  @ViewChild('columnFirst', { static: true }) columnFirst: ElementRef;
  @ViewChild('columnSecond', { static: true }) columnSecond: ElementRef;


  drawing: boolean;
  drawingType: boolean;

  styleRotateSurfaceAngle: SafeStyle;
  styleRotateAngle: SafeStyle;

  angle: number;
  surfaceAngle: number;
  south = 180;

  fixedBar = false;
  mobileScreen: boolean;

  constructor(private sanitizer: DomSanitizer,
    private calculationService: PVSelfCalculationService,
    private engineService: PVSelfEngineService) {
  }

  ngOnInit() {
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });

//    this._producer = this.tabCreationData.producer;
    this.optimalInclination = -1;
    this.selectedField = this.tabCreationData.producer.installation.fields[0];
  }


  defaultChoice() {
    if (this.selectedField.technology === PVSelfFieldTechnologyType.Fixed) {
      this.selectedField.inclinationType = PVSelfInclinationType.Coplanar;
      this.selectedField.orientationType = PVSelfOrientationType.Coaxial;
    } else if (this.selectedField.technology === PVSelfFieldTechnologyType.Axis_1) {
      this.selectedField.inclinationType = PVSelfInclinationType.Optimal;
      this.selectedField.orientationType = PVSelfOrientationType.Coaxial;
    } else if (this.selectedField.technology === PVSelfFieldTechnologyType.Axis_2) {
      this.selectedField.inclinationType = PVSelfInclinationType.Optimal;
      this.selectedField.orientationType = PVSelfOrientationType.Optimal;
    }
  }




  compassOne(e) {
    this.selectedField.realOrientation = e.target.value;
    if (this.selectedField.realOrientation <= 180) {
      this.angle = this.south - this.selectedField.realOrientation;
    } else {
      this.angle = (360 - (this.south + this.selectedField.realOrientation));
    }
    this.styleRotateAngle = this.sanitizer.bypassSecurityTrustStyle(`transform: rotate(${this.angle}deg)`);
  }

  compassTwo(e) {
    this.selectedField.orientation = e.target.value;
    if (this.selectedField.orientation <= 180) {
      this.surfaceAngle = this.south - this.selectedField.orientation;
    } else {
      this.surfaceAngle = (360 - (this.south + this.selectedField.orientation));
    }
    this.styleRotateSurfaceAngle = this.sanitizer.bypassSecurityTrustStyle(`transform: rotate(${this.surfaceAngle}deg)`);
  }

  @HostListener('window:mousemove', ['$event'])
  onWindowMouseMove(e) {
    let center_x, center_y, delta_x, delta_y, pos_x, pos_y, touch, circle, angle;
    if (this.drawing) {
      touch = void 0;
      if (this.drawingType) {
        circle = this.circle.nativeElement;
      } else {
        circle = this.circle2.nativeElement;
      }
      center_x = circle.getBoundingClientRect().left + circle.offsetWidth / 2;
      center_y = circle.getBoundingClientRect().top + circle.offsetHeight / 2;
      pos_x = e.clientX;
      pos_y = e.clientY;
      delta_y = center_y - pos_y;
      delta_x = center_x - pos_x;

      angle = Math.atan2(delta_y, delta_x) * (180 / Math.PI); // Calculate Angle between circle center and mouse pos
      angle -= 90;
      if (angle < 0) {
        angle = 360 + angle; // Always show angle positive
      }
      if (!this.drawingType) {
        this.surfaceAngle = Math.round(angle);
        this.styleRotateSurfaceAngle = this.sanitizer.bypassSecurityTrustStyle(`transform: rotate(${this.surfaceAngle}deg)`);
        if (this.angle <= 180) {
          this.selectedField.orientation = this.south - this.surfaceAngle;
        } else {
          this.selectedField.orientation = (360 - (this.south + this.surfaceAngle));
        }
      } else {
        this.angle = Math.round(angle);
        this.styleRotateAngle = this.sanitizer.bypassSecurityTrustStyle(`transform: rotate(${this.angle}deg)`);
        if (this.angle <= 180) {
          this.selectedField.realOrientation = this.south - this.angle;
        } else {
          this.selectedField.realOrientation = (360 - (this.south + this.angle));
        }
      }

      if (this.selectedField.orientationType === PVSelfOrientationType.Coaxial) {
        this.selectedField.orientation = this.selectedField.realOrientation;
      }
    }
  }


  @HostListener('window:mouseup', ['$event'])
  onMouseUp() {
    this.drawing = false;
  }


  setRotate($event, type: number) {

    let ev;
    if (type === 0 || type === 2) {
      if (type === 2) {
        ev = $event.target.value;
      } else {
        ev = $event.value;
      }

      let z: any;
      if (ev < 61) {
        z = ev;
      } else {
        z = 60;
      }
      this.selectedField.realInclination = ev;
      if (this.selectedField.inclinationType === PVSelfInclinationType.Coplanar) {
        this.selectedField.inclination = this.selectedField.realInclination;
      }
      const prov = z;
      const x = (Math.tan(prov / 57.29) * 25.85) - 14.3;
      this.red.nativeElement.setAttribute('points', '4.2,14.3 30,' + (-x) + ' 55.9,14.3 ');
      this.gris.nativeElement.setAttribute('points', '30,' + (-x) + ' 7,15.8 7,15.8 7,45.8 53,45.8 53,15.8 53,15.8 ');

    } else if (type === 1 || type === 3) {
      if (type === 3) {
        ev = $event.target.value;
      } else {
        ev = $event.value;
      }
      let z: any;
      if (ev < 61) {
        z = ev;
      } else {
        z = 60;
      }
      this.selectedField.inclination = ev;
      const prov = z;
      const x = (Math.tan(prov / 57.29) * 25.85) - 14.3;
      this.rojo.nativeElement.setAttribute('points', '4.2,14.3 30,' + (-x) + ' 55.9,14.3 ');
      this.gray.nativeElement.setAttribute('points', '30,' + (-x) + ' 7,15.8 7,15.8 7,45.8 53,45.8 53,15.8 53,15.8 ');
    }
  }

  changeSelected(item: PVSelfInstallationField) {
    this.selectedField = item;
  }


  inclinationTypeChanged(e) {
    this.optimalInclination = -1;
    if (this.selectedField.inclinationType === PVSelfInclinationType.Optimal &&
      this.selectedField.technology === PVSelfFieldTechnologyType.Fixed) {

      this.engineService.calculateOptimalInclination(this._calculation).subscribe(res => {
        this.optimalInclination = res;
      });
    }
  }

}


