import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { NbMenuService, NbMenuItem } from '@nebular/theme';
import { TabsComponent } from 'projects/pplus/src/lib/components/tabs/tabs.component';
import { MenuService } from 'src/app/@core/layout/Menu/menu.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements AfterViewInit {
  @ViewChild(TabsComponent, { static: false }) tabsComponent;
  @ViewChild('domains', { static: false }) domainsTemplate;
  @ViewChild('installations', { static: false }) installationsTemplate;
  @ViewChild('installationMeters', { static: false }) installationMetersTemplate;
  @ViewChild('installationInverters', { static: false }) installationInvertersTemplate;
  @ViewChild('installationStrings', { static: false }) installationStringsTemplate;
  @ViewChild('Users', { static: false }) userTemplate;
  @ViewChild('UserGroup', { static: false }) usergroupTemplate;
  @ViewChild('Entities', { static: false }) entitiesTemplate;
  @ViewChild('welcome', { static: false }) welcomeTemplate;
  @ViewChild('App', { static: false }) appTemplate;
  @ViewChild('AppType', { static: false }) apptypeTemplate;
  @ViewChild('AppGroup', { static: false }) appGroupTemplate;
  @ViewChild('Plant', { static: false }) plantTemplate;
  @ViewChild('PlantGroup', { static: false }) plantGroupTemplate;
  @ViewChild('Panels', { static: false }) panelsTemplate;
  @ViewChild('String', { static: false }) stringTemplate;
  @ViewChild('servicerecord', { static: false }) servicerecordTemplate;
  @ViewChild('servicetype', { static: false }) servicetypeTemplate;
  @ViewChild('specificinstalationdata', { static: false }) specificisntalationdataTemplate;
  @ViewChild('photonDB', { static: false }) photonDBTemplate;
  @ViewChild('styles', { static: false }) stylesTemplate;
  @ViewChild('permissions', { static: false }) permissionsTemplate;
  @ViewChild('effectivepermissions', { static: false }) effectivepermissionsTemplate;

  constructor(private nbMenuService: NbMenuService, private menuService: MenuService) {
    this.nbMenuService.onItemClick().subscribe(res => {
      const itemSelected = res.item;
      switch (itemSelected.queryParams.code) {
        case 'Domains':
          this.openTab(itemSelected.title, this.domainsTemplate, itemSelected);
          break;
        case 'Users':
          this.openTab(itemSelected.title, this.userTemplate, itemSelected);
          break;
        case 'User-Groups':
          this.openTab(itemSelected.title, this.usergroupTemplate, itemSelected);
          break;
        case 'Entities':
          this.openTab(itemSelected.title, this.entitiesTemplate, itemSelected);
          break;
        case 'Apps':
          this.openTab(itemSelected.title, this.appTemplate, itemSelected);
          break;
        case 'App-Types':
          this.openTab(itemSelected.title, this.apptypeTemplate, itemSelected);
          break;
        case 'App-Groups':
          this.openTab(itemSelected.title, this.appGroupTemplate, itemSelected);
          break;
        case 'Panels':
          this.openTab(itemSelected.title, this.panelsTemplate, itemSelected);
          break;
        case 'String':
          this.openTab(itemSelected.title, this.stringTemplate, itemSelected);
          break;
        case 'specificinstalationdata':
          this.openTab(itemSelected.title, this.specificisntalationdataTemplate, itemSelected);
          break;
        case 'Plants':
          this.openTab(itemSelected.title, this.plantTemplate, itemSelected);
          break;
        case 'Plant-Groups':
          this.openTab(itemSelected.title, this.plantGroupTemplate, itemSelected);
          break;
        case 'servicerecord':
          this.openTab(itemSelected.title, this.servicerecordTemplate, itemSelected);
          break;
        case 'servicetype':
          this.openTab(itemSelected.title, this.servicetypeTemplate, itemSelected);
          break;
        case 'PhotonDB':
          this.openTab(itemSelected.title, this.photonDBTemplate, itemSelected);
          break;
        case 'Styles':
          this.openTab(itemSelected.title, this.stylesTemplate, itemSelected);
          break;
        case 'Permissions':
          this.openTab(itemSelected.title, this.permissionsTemplate, itemSelected);
          break;
        case 'EffectivePermissions':
          this.openTab(itemSelected.title, this.effectivepermissionsTemplate, itemSelected);
          break;
        case 'Installations':
          this.openTab(itemSelected.title, this.installationsTemplate, itemSelected);
          break;
        case 'InstallationMeters':
          this.openTab(itemSelected.title, this.installationMetersTemplate, itemSelected);
          break;
        case 'InstallationInverters':
          this.openTab(itemSelected.title, this.installationInvertersTemplate, itemSelected);
          break;
        case 'InstallationStrings':
          this.openTab(itemSelected.title, this.installationStringsTemplate, itemSelected);
          break;
      }
    });
  }

  ngAfterViewInit() {
    this.menuService.currentMenu.subscribe((menu: NbMenuItem[]) => {
      menu.forEach(el => {
        const app = el.queryParams.code;
        const children = <NbMenuItem[]>el.children;
        if (app === 'Admin') {
          children.forEach(item => {
            const module = item.queryParams.code;
            if (module === 'Users') {
              this.openTab('Users', this.userTemplate, item);
            }
          });
        }
      });
    });
  }

  openTab(title: string, template: ViewChild, itemSelected) {
    this.tabsComponent.openTab(title, template, itemSelected, true);
  }

}
