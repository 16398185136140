import { Component, OnInit } from '@angular/core';
import { SelectionType } from 'src/app/modules/pvself/models/PVSelf.model';
import { ACTIVE_ENERGY_LEGENDS, ACTIVE_BAR_ENERGY_CHART_OPTIONS, ACTIVE_AREA_ENERGY_CHART_OPTIONS } from '../pvself-active-main.config';
import { GridOptions } from 'ag-grid-community';
import { ACTIVE_ENERGY_SERIES_DATA, PERIODS } from '../pvself-active-main.series';
import { EChartOption } from 'echarts';
import { PVSelfBalanceResults } from 'src/app/modules/pvself/models/PVSelfResultsModel.model';
import { PPlusChartType } from 'projects/pplus/src/lib/models/pplus.enums';
import { chartColors } from 'src/app/modules/pvself/pvself.graph.colors';
import { IPeriod } from 'src/app/modules/selfview/models/selfview.model';
import { PVSelfEngineService } from 'src/app/modules/pvself/services/pvself-engine.service';

@Component({
  selector: 'pplus-pvself-balance-main-energy-chart',
  templateUrl: './pvself-balance-main-energy-chart.component.html',
  styleUrls: ['./pvself-balance-main-energy-chart.component.scss']
})
export class PVSelfBalanceMainEnergyChartComponent implements OnInit {
  balanceResults: PVSelfBalanceResults;
  ACTIVE_ENERGY_SERIES_DATA = ACTIVE_ENERGY_SERIES_DATA;
  energyEchartsInstance: any;
  flipped: boolean;
  gridOptions: GridOptions;
  gridData: any;
  selectionType: SelectionType;
  energyLegends: any;
  activeEnergyChartOptions: EChartOption;
  periods: IPeriod[] = [];


  /**
   * Store areas chart series visibility state
   */
  areaEnergyChartState = {};
  barEnergyChartState = {};

  /**
   * Initial options for chart
   */
  initOpts = {
    renderer: 'svg',
    width: 'auto',
    height: 'auto'
  };

  countEnergyOptions = {
    duration: 5,
    separator: ' ',
    suffix: ' kWh',
  };


  constructor(private engineService: PVSelfEngineService) { }

  ngOnInit() {

    this.selectionType = SelectionType.Energy;
    this.flipped = false;
    this.energyLegends = ACTIVE_ENERGY_LEGENDS;

    this.initBarEnergyChartState();
    this.initAreaEnergyChartState();

    this.engineService.balanceEnergyResults.subscribe((res: PVSelfBalanceResults) => {
      this.balanceResults = res;
      if (res !== null) { this.showEnergyChartForGlobalType(); }
    });
  }



  /**
   * Called to show main chart.
   */
  showEnergyChartForGlobalType() {
    if (this.balanceResults !== null) {
      if (this.balanceResults.mainChartType === PPlusChartType.Bar) {
        this.initiateEnergyChartBar();
      } else {
        this.initiateEnergyChartArea();
//        this.initiatePeriods(this.balanceResults.hourlyData, 0);
      }
    }
  }

  initiateEnergyChartBar() {
    this.activeEnergyChartOptions = null;
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };

    const series = this.generateSeriesForActiveBarEnergyChart();

    const chartOptions: any = ACTIVE_BAR_ENERGY_CHART_OPTIONS;
    chartOptions.legend.data = this.barEnergyChartState;
    chartOptions.legend.selected = { ...this.barEnergyChartState };
    chartOptions.series = [...series];
    chartOptions.xAxis[0].data = this.balanceResults.groupedData.map(item => {
      const time = new Date(item.date);
      return time.toLocaleDateString('es-ES');
    });

    this.activeEnergyChartOptions = chartOptions;
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
  }



  initiateEnergyChartArea() {
    this.activeEnergyChartOptions = null;
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };

    const series = this.generateSeriesForActiveAreaEnergyChart();
    const chatOptions: any = ACTIVE_AREA_ENERGY_CHART_OPTIONS;
    chatOptions.legend.data = this.areaEnergyChartState,
      chatOptions.legend.selected = this.areaEnergyChartState,
      chatOptions.series = series,

      this.activeEnergyChartOptions = chatOptions;
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
  }



  /**
   * Generates series for active bar chart
   */
  generateSeriesForActiveBarEnergyChart(compareMode: boolean = false) {
    const series = [];
    const data = this.balanceResults.groupedData;

    data.forEach(element => {
      Object.keys(ACTIVE_ENERGY_SERIES_DATA).forEach((key, index) => {
        const serieName = ACTIVE_ENERGY_SERIES_DATA[key].id;

        if (!series[index]) {
          series[index] = [null, null, null, null, null, null];
        }
        // create series
        element[`${serieName}`].forEach((item, i) => {
          if (!series[index][i]) {
            series[index][i] = this.createActiveBarEnergyChartSerie(
              `${ACTIVE_ENERGY_SERIES_DATA[key].label} P${i + 1}`
              ,
              [],
              chartColors.periods[`${ACTIVE_ENERGY_SERIES_DATA[key].periodColorKey}P${i + 1}`
              ], ACTIVE_ENERGY_SERIES_DATA[key].id
            );
          }
          series[index][i].data.push(index !== 1 ? item : -item);
        });
      });
    });
    return series.reduce((a, b) => [...a, ...b], []);
  }




  /**
   * Generates series for reactive area chart
   */
  generateSeriesForActiveAreaEnergyChart() {
    const series = [];
    const data = this.balanceResults.hourlyData;

    data.forEach(element => {
      Object.keys(ACTIVE_ENERGY_SERIES_DATA).forEach((key, index) => {
        const serieName = ACTIVE_ENERGY_SERIES_DATA[key].id;

        let areaStyle = null;
        areaStyle = {
          normal: {
            color: chartColors[`${ACTIVE_ENERGY_SERIES_DATA[key].areaColorKey}Area`],
            areaStyle: { type: 'default' }
          }
        };

        if (!series[index]) {
          series[index] = this.createActiveAreaEnergyChartSerie(
            `${ACTIVE_ENERGY_SERIES_DATA[key].label}`,
            [],
            chartColors[`${ACTIVE_ENERGY_SERIES_DATA[key].areaColorKey}Line`],
            ACTIVE_ENERGY_SERIES_DATA[key].id,
            areaStyle,
            0
          );
        }
        series[index].data.push([element.hour, element.energy[serieName]]);
      });
    });

    return series;
  }


  createActiveBarEnergyChartSerie(name: string, data: any, color: string, stack: string) {
    return {
      name,
      type: 'bar',
      data,
      itemStyle: { color },
      stack,
      xAxisIndex: 0
    };
  }



  createActiveAreaEnergyChartSerie(name: string, data: any, color: string, stack: string, areaStyle: any = null, xAxisIndex: number = 0) {

    return {
      name,
      type: 'line',
      smooth: true,
      symbol: 'none',
      itemStyle: {
        normal: {
          color
        }
      },
      stack,
      areaStyle: areaStyle,
      markArea: {
        label: { show: true, position: 'top' },
        itemStyle: {
          color: 'rgba(38,44,44,0.11)',
          borderColor: 'rgba(38,44,44,0.27)',
          borderWidth: 0.5
        },
        data: []
      },
      data: data,
      xAxisIndex

    };

  }


  /**
   * Initiates periods for reactive area chart
   * @param data: chart data
   */
  initiatePeriods(data: any[], consumerIndex: number) {
    if (data[0]) {
      this.periods = [];
      let currentPeriod = data[0].period;
      let startDateDate = data[0].hour;
      let endDateDate = '';

      for (let i = 1; i < data.length; i++) {

        if (currentPeriod !== data[i].consumersHourlyData[consumerIndex].period) {
          endDateDate = data[i - 1].hour;
          this.periods.push({ startDate: startDateDate, endDate: endDateDate, period: currentPeriod });
          startDateDate = endDateDate;
        }

        if (i === (data.length - 1)) {
          if (this.periods[this.periods.length - 1].period !== data[i].consumersHourlyData[consumerIndex].period) {
            endDateDate = data[i].hour;
            this.periods.push({ startDate: startDateDate, endDate: endDateDate, period: data[i].consumersHourlyData[consumerIndex].period });
          }
        }
        currentPeriod = data[i].consumersHourlyData[consumerIndex].period;
      }
    }

    this.paintPeriods();
  }


  /**
   * Paints periods
   */
  paintPeriods() {
    const markers = [];
    for (const period of this.periods) {
      const marker = [
        { name: 'P' + period.period, xAxis: period.startDate, yAxis: 'min' },
        { yAxis: 'max', xAxis: period.endDate }
      ];
      markers.push(marker);
    }

    // create periods fake serie and concats to all series
    const periodsSerie = this.createPeriodsFakeSerie(markers);
    this.activeEnergyChartOptions.series = this.activeEnergyChartOptions.series.concat([periodsSerie]);
  }


  createPeriodsFakeSerie(data: any) {

    return {
      name: 'periods',
      type: 'line',
      markArea: {
        label: {
          show: true,
          position: 'top',
          textStyle: {
            color: '#000',
            fontStyle: 'italic'
          }
        },
        itemStyle: {
          color: 'rgba(38,44,44,0.11)',
          borderColor: 'rgba(38,44,44,0.27)',
          borderWidth: 0.5
        },
        data
      }
    };
  }



  initBarEnergyChartState() {
    Object.keys(ACTIVE_ENERGY_SERIES_DATA).forEach((key) => {
      const serieName = ACTIVE_ENERGY_SERIES_DATA[key].label;

      Object.keys(PERIODS).forEach((period: string) => {
        this.barEnergyChartState[`${serieName} ${period}`] = true;
      });
    });
  }


  initAreaEnergyChartState() {
    Object.keys(ACTIVE_ENERGY_SERIES_DATA).forEach((key) => {
      const serieName = ACTIVE_ENERGY_SERIES_DATA[key].label;
      this.areaEnergyChartState[`${serieName}`] = true;
    });
  }



  onEnergyChartInit(ec) {
    this.energyEchartsInstance = ec;
  }


  /**
   * Toggles serie for main chart.
   * @param serie: Current serie
   * @param $event: State
   */
  toggleEnergyChartSerie(serie: any, $event: any) {
    // Get the zoom level of the graph and apply it in legend selection
    let zoom = null;
    if (this.energyEchartsInstance) {
      zoom = this.energyEchartsInstance.getOption().dataZoom;
      this.activeEnergyChartOptions.dataZoom = zoom;
    }
    const state = $event.checked;

    Object.keys(PERIODS).forEach((key) => {
      this.barEnergyChartState[`${serie.label} ${key}`] = state;
    });

    this.areaEnergyChartState[`${serie.label}`] = state;

    if (this.balanceResults.mainChartType === PPlusChartType.Bar) {
      this.activeEnergyChartOptions.legend.selected = this.barEnergyChartState;
    } else {
      this.activeEnergyChartOptions.legend.selected = this.areaEnergyChartState;
    }
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
  }

}


