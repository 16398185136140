import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IEntityApp } from 'projects/pplus/src/lib/interfaces/interfaces';
import { APP_COL_DEFS } from './app.model';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private readonly apiUrl = environment.API_URL + '/api/application';
  private readonly apiApp = this.apiUrl + '/application';
  private readonly apiScrollInfinite = this.apiApp + '/get-applications';
  private readonly apiAssignGroups = this.apiUrl + '/assigngroups';
  private readonly apiAssignEntities = this.apiUrl + '/assignentities';

  constructor(private http: HttpClient) {}

  getAllAppsInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    return this.http.post(this.apiScrollInfinite, { startRow, endRow, sortModel, filterModel });
  }

  getAllApps(): Observable<IEntityApp[]> {
    return this.http.get(this.apiApp).pipe(
      map(response => <IEntityApp[]>response),
      catchError(() => of([]))
    );
  }

  getAppsList(): Observable<IEntityApp[]> {
    return this.http.get(this.apiApp + '/list').pipe(
      map(response => <IEntityApp[]>response),
      catchError(() => of([]))
    );
  }

  getApp(id: number): Observable<IEntityApp> {
    return this.http.get(`${this.apiApp}/${id}`).pipe(
      map(response => <IEntityApp>response),
      catchError(() => of(null))
    );
  }

  createApp(data: IEntityApp): Observable<IEntityApp> {
    return this.http.post(this.apiApp, data, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? <IEntityApp>response.body : null),
      catchError(() => of(null))
    );
  }

  updateApp(id: number, data: IEntityApp): Observable<boolean> {
    return this.http.put(`${this.apiApp}/${id}`, data, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  deleteApp(id: number): Observable<boolean> {
    return this.http.delete(`${this.apiApp}/${id}`, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  assignGroups(applicationId: number, applicationGroupIds: number[]): Observable<boolean> {
    if (applicationGroupIds && applicationGroupIds.length >= 0) {
      return this.http.post(this.apiAssignGroups, {applicationId, applicationGroupIds}, {observe: 'response'}).pipe(
        map(response => response.status === 200 || response.status === 204),
        catchError(() => of(false))
      );
    } else return of(true);
  }

  assignEntities(applicationId: number, entityIds: number[]): Observable<boolean> {
    if (entityIds && entityIds.length >= 0) {
      return this.http.post(this.apiAssignEntities, {applicationId, entityIds}, {observe: 'response'}).pipe(
        map(response => response.status === 200 || response.status === 204),
        catchError(() => of(false))
      );
    } else return of(true);
  }

  getAppsGeneralFilter(
    value: string, startRow: number, endRow: number, sortModel: any, filterModel: any
  ): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of APP_COL_DEFS) columnList.push(colDef.field);
    return this.http.post(this.apiScrollInfinite, {
      startRow, endRow, sortModel, filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel,
      },
      columnList,
    });
  }

}
