import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbCardModule, NbPopoverModule } from '@nebular/theme';
import { PPLusTreeDataModule } from '../tree-data/tree-data.module';
import { MatIconModule, MatButtonModule } from '@angular/material';
import { PlantsSearchComponent } from './plants-search.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    PlantsSearchComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    NbCardModule,
    PPLusTreeDataModule,
    TranslateModule,
    NbPopoverModule
  ],
  exports: [
    PlantsSearchComponent
  ]
})
export class PPlusPlantSearchModule { }
