import {Component, ElementRef, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import { REACTIVE_DATA } from '../reactive-main.series';
import { GlobalType } from '../../../models/selfview.model';
import { SelfViewService } from '../../../services/selfview-general.service';
import { Subscription } from 'rxjs';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-reactive-main-table',
  templateUrl: './reactive-main-table.component.html',
  styleUrls: ['./reactive-main-table.component.scss']
})
export class ReactiveMainTableComponent implements OnInit, OnDestroy {
  @ViewChild('table', {static: false, read: ElementRef}) table: ElementRef;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  // FIX TO USE ENUM IN TEMPLATE
  GlobalType = GlobalType;
  REACTIVE_DATA = REACTIVE_DATA;

  /**
   * Store local type selected
   */
  type: GlobalType;

  /**
   * Store global type selected
   */
  globalType: GlobalType;


  /**
   * Flipped state of component card
   */
  flipped: boolean;


  // GRID

  dataSource = new MatTableDataSource<any>([]);
  columnDefinitions = [
    { def: 'date', show: true},
    { def: 'consumption_I', show: true},
    { def: 'consumption_C', show: true},
    { def: 'networkConsumption_I', show: true},
    { def: 'networkConsumption_C', show: true},
    { def: 'excedentary_I', show: true},
    { def: 'excedentary_C', show: true},
    { def: 'generated_I', show: true},
    { def: 'generated_C', show: true},
    { def: 'powerFactor', show: true}
  ];



  comparePeriod = false;


  /**
   * Sets the data mode compare or not
   */
  compareMode: boolean;

  /**
   * Reactive Data from reactive service
   */
  reactiveDataPower: any;

  /**
   * Reactive Compare Data from reactive service
   */
  reactiveDataPowerComparison: any;

  private globalTypeSubscription: Subscription;
  private dataSubscription: Subscription;
  private dataCompareSubscription: Subscription;

  constructor(private translate: TranslateService, private selfviewService: SelfViewService) {
    this.flipped = true;
    this.type = GlobalType.Energy;
  }

  ngOnInit() {

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.globalTypeSubscription = this.selfviewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;

      // Aquí se cambiara, el tipo desde el global
      this.dataSubscription = this.selfviewService.dataReactive.subscribe((data: any) => {
        if (data) {
          this.reactiveDataPower = data.reactiveData;
          if (this.reactiveDataPower) {
            this.buildReactiveGrid();
         }
        }
      });

      this.dataCompareSubscription = this.selfviewService.dataReactiveCompare.subscribe((data: any) => {


      });

    });

  }

  ngOnDestroy(): void {
    this.globalTypeSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
    this.dataCompareSubscription.unsubscribe();
  }

  /**
   * Builds reactive grid
   */
  buildReactiveGrid() {
    let gridData: any;

    if (this.reactiveDataPower.groupedData && this.reactiveDataPower.groupedData.length) { // si el rango es de más de 7 días
      gridData = this.mapGeneral(this.reactiveDataPower);
      gridData = gridData.groupedData;
      this.dataSource.data = gridData;
    } else { // el rango es de 7 días o menos
      gridData = this.mapGeneral(this.reactiveDataPower);
      gridData = gridData.hourlyData;
      this.dataSource.data = gridData;
    }
  }

  hideGridColumns(energy: any, event) {
    this.columnDefinitions.filter( cd => cd.def === energy.INDUCTIVE.id).map( cd => cd.show = event.checked);
    this.columnDefinitions.filter( cd => cd.def === energy.CAPACITIVE.id).map( cd => cd.show = event.checked);
  }

  getDisplayedColumns() {
    return this.columnDefinitions.filter(cd => cd.show).map(cd => cd.def);
  }

  mapGeneral(data: any): any {
    if (data.groupedData) {
      data.groupedData.map( o => {
        const date = o.date;
        o.date = moment.utc(date).format('DD/MM/YYYY');
      });
    }
    if (data.hourlyData) {
      data.hourlyData.map( o => {
        const date = o.hour;
        o.date = moment.utc(date).format('DD/MM/YYYY hh:mm:ss');
      });
    }
    return data;
  }



  /**
   * Exports grid to excel
   */
  exportToExcel() {
    let ws: XLSX.WorkSheet;
    ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Table.xlsx');
  }



}
