import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IEntity, IEntityContact, IEntityFinancial } from 'projects/pplus/src/lib/interfaces/interfaces';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {
  private apiUrl = environment.API_URL + '/api/entity';

  constructor(private http: HttpClient) {}

  getAllEntitiesInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<IEntity[]> {
    const body = {
      startRow,
      endRow,
      sortModel,
      filterModel
    };
    const url = `${this.apiUrl}/get-entities`;
    return this.http.post(url, body).pipe(map(res => {
      return <IEntity[]>res;
    }));
  }

  getAllEntities(): Observable<IEntity[]> {
    return this.http.get(this.apiUrl).pipe(
      map(response => <IEntity[]>response),
      catchError(() => of([]))
    );
  }

  getEntitiesList(): Observable<IEntity[]> {
    return this.http.get(this.apiUrl + '/list').pipe(
      map(response => <IEntity[]>response),
      catchError(() => of([]))
    );
  }

  getEntity(entityId: number): Observable<IEntity> {
    return this.http.get(`${this.apiUrl}/${entityId}`).pipe(
      map(response => <IEntity>response),
      catchError(() => of(null))
    );
  }

  deleteEntity(entityId: number): Observable<boolean> {
    return this.http.delete(`${this.apiUrl}/${entityId}`, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  createEntity(data: IEntity): Observable<boolean> {
    const newData = {
      code: data.code,
      description: data.description,
      parentEntityId: data.parentEntityId,
      activated: Boolean(data.activated),
      // language: data.language,
      language: 'EN',
      // entityTypeId: 1,
    };
    return this.http.post(this.apiUrl, newData, {observe: 'response'}).pipe(
      map(response => response.status === 200),
      catchError(() => of(false))
    );
  }

  updateEntity(entityId: number, data: IEntity): Observable<boolean> {
    return this.http.put(`${this.apiUrl}/${entityId}`, data, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  assignUserToEntities(userId: number, entityIds: number[]): Observable<boolean> {
    return this.http.post(this.apiUrl + '/assignusers', {userId, entityIds}, {observe: 'response'}).pipe(
      map(response => response.status === 200),
      catchError(() => of(false))
    );
  }

  getEntityUserGroups(entityId: number): Observable<any[]> {
    if (entityId != null) {
      return this.http.get(this.apiUrl + '/userGroups/' + entityId).pipe(
        map((res: any) => res.length ? res : []),
        catchError(() => of([]))
      );
    } else {
      return of([]);
    }
  }

  assignUserGroupsToEntity(entityId: number, userGroupIds: number[]): Observable<boolean> {
    return this.http.post(this.apiUrl + '/assignusergroups', {entityId, userGroupIds}, {observe: 'response'}).pipe(
      map(response => response.status === 200),
      catchError(() => of(false))
    );
  }

  updateOrCreateEntityContact(entityContact: IEntityContact): Observable<boolean> {
    if (entityContact.entityContactId === undefined || entityContact.entityContactId === null) {
      return this.http.post(this.apiUrl + '/contact', entityContact, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else {
      return this.http.put(
        this.apiUrl + '/contact/' + entityContact.entityContactId, entityContact, {observe: 'response'}
      ).pipe(
        map(response => response.status === 204),
        catchError(() => of(false))
      );
    }
  }

  updateOrCreateEntityFinancial(entityFinancial: IEntityFinancial): Observable<boolean> {
    if (entityFinancial.entityFinancialId === undefined || entityFinancial.entityFinancialId === null) {
      return this.http.post(this.apiUrl + '/financial', entityFinancial, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else {
      return this.http.put(
        this.apiUrl + '/financial/' + entityFinancial.entityFinancialId, entityFinancial, {observe: 'response'}
      ).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    }
  }

}
