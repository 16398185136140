import { Injectable } from '@angular/core';
import { DynamicForm, Fields } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class InstallationMetersDetailsService {

  private readonly generalFields: Fields[] = [
    {
      name: 'installationMeterId',
      type: 'text',
      placeholder: 'ID',
      value: '',
      disabled: true,
    },
    {
      name: 'meterTypeId',
      type: 'select',
      label: 'TIPO',
      value: null,
      validators: [
        Validators.required,
      ],
      options: [],
    },
    {
      name: 'name',
      type: 'text',
      placeholder: 'NOMBRE',
      value: '',
      validators: [
        Validators.required,
      ],
    },
    {
      name: 'code',
      type: 'text',
      placeholder: 'CÓDIGO',
      value: '',
      validators: [
        Validators.required,
      ],
    },
    {
      name: 'installationId',
      type: 'select',
      label: 'INSTALACIÓN ASOCIADA',
      value: null,
      options: [],
    },
    {
      name: 'plantId',
      type: 'select',
      label: 'PLANTA ASOCIADA',
      value: null,
      options: [],
    },
    // plugins asociados
    {
      name: 'lastReadDate',
      type: 'text',
      placeholder: 'FECHA ÚLTIMA LECTURA',
      value: '',
      disabled: true,
    },
    {
      name: 'lastEventDate',
      type: 'text',
      placeholder: 'FECHA ÚLTIMO EVENTO',
      value: '',
      disabled: true,
    },
    {
      name: 'activated',
      type: 'checkbox',
      placeholder: 'ACTIVO',
      value: false,
    },
  ];

  private readonly meterDataFields: Fields[] = [
    {
      name: 'meterMarketId',
      type: 'select',
      label: 'MARCA',
      value: null,
      options: [],
    },
    {
      name: 'meterModelId',
      type: 'select',
      label: 'MODELO',
      value: null,
      options: [],
    },
    {
      name: 'serialNumber',
      type: 'text',
      placeholder: 'NÚMERO DE SERIE',
      value: '',
    },
    {
      name: 'creationDate',
      type: 'text',
      placeholder: 'FECHA DE ALTA',
      value: '',
      disabled: true,
    },
    {
      name: 'deletionDate',
      type: 'text',
      placeholder: 'FECHA DE BAJA',
      value: '',
      disabled: true,
    },
    {
      name: 'note',
      type: 'text',
      placeholder: 'NOTA',
      value: '',
    },
    {
      name: 'readingStartDate',
      type: 'text',
      placeholder: 'FECHA INICIO DESCARGA DE LECTURAS',
      value: '',
      disabled: true,
    },
  ];

  private readonly configDataFields: Fields[] = [
    {
      name: 'instantTimeOut',
      type: 'text',
      placeholder: 'TIME OUT CONTADORES INSTANTÁNEOS',
      value: '0',
    },
    {
      name: 'historicalTimeOut',
      type: 'text',
      placeholder: 'TIME OUT CONTADORES HISTÓRICOS',
      value: '0',
    },
    {
      name: 'addressLink',
      type: 'text',
      placeholder: 'DIRECCIÓN DE ENLACE',
      value: '',
    },
    {
      name: 'pointMeter',
      type: 'text',
      placeholder: 'PUNTO DE MEDIDA',
      value: '',
    },
    {
      name: 'key',
      type: 'text',
      placeholder: 'CLAVE',
      value: '',
    },
    {
      name: 'rtIntensity',
      type: 'text',
      placeholder: 'R.T. INTENSIDAD',
      value: '',
    },
    {
      name: 'rtTension',
      type: 'text',
      placeholder: 'R.T. TENSIÓN',
      value: '',
    },
    {
      name: 'meterProtocolId',
      type: 'select',
      label: 'PROTOCOLO CONTADOR',
      value: null,
      options: [],
    },
    {
      name: 'typeConexion',
      type: 'text',
      placeholder: 'TIPO DE CONEXIÓN',
      value: '',
    },
    {
      name: 'typeComunication',
      type: 'text',
      placeholder: 'TIPO DE COMUNICACIÓN',
      value: '',
    },
    {
      name: 'numberPhone',
      type: 'text',
      placeholder: 'Nº TELÉFONO SIM',
      value: '',
    },
    {
      name: 'ip',
      type: 'text',
      placeholder: 'DIRECCIÓN IP',
      value: '',
    },
    {
      name: 'portTcp',
      type: 'text',
      placeholder: 'PUERTO TCP',
      value: '',
    },
    {
      name: 'addressClient',
      type: 'text',
      placeholder: 'DIRECCIÓN DE CLIENTE',
      value: '',
    },
    {
      name: 'password',
      type: 'text',
      placeholder: 'CONTRASEÑA',
      value: '',
    },
    {
      name: 'physicalAddress',
      type: 'text',
      placeholder: 'DIRECCIÓN FÍSICA',
      value: '',
    },
    {
      name: 'logicAddress',
      type: 'text',
      placeholder: 'DIRECCIÓN LÓGICA',
      value: '',
    },
    {
      name: 'typeAuthentication',
      type: 'text',
      placeholder: 'TIPO DE AUTENTIFICACIÓN',
      value: '',
    },
  ];

  constructor() {}

  getDynamicForm(): DynamicForm[] {
    return [
      {
        title: 'DATOS GENERALES',
        fields: this.generalFields,
        open: true,
      },
      {
        title: 'DATOS DEL CONTADOR',
        fields: this.meterDataFields,
      },
      {
        title: 'DATOS DE CONFIGURACIÓN',
        fields: this.configDataFields,
      },
    ];
  }

}
