import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NbMenuItem } from '@nebular/theme';
import { map } from 'rxjs/operators';
import { ApplicationsService } from 'projects/pplus/src/lib/services/applications.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private _menuDataApi = environment.API_URL + '/api/application/getByUserWithModulesFilteredByApp';
  private appCode: string;
  private menuData  = new BehaviorSubject(<NbMenuItem []> []);
  currentMenu = this.menuData.asObservable();

  constructor(private http: HttpClient, private application: ApplicationsService) {
    this.application.currentApplication.subscribe( (app: string) => {
      this.appCode = app;
    });
  }

  getMenuData(): Observable<NbMenuItem []> {
    return this.http
      .get(this._menuDataApi, { params: { 'appcode': this.appCode } })
      .pipe(map((response: NbMenuItem []) => {
        const menu: NbMenuItem [] = response;
        this.menuData.next(menu);
        return menu;
    }));
  }
}
