import {Component, OnInit} from '@angular/core';
import {GlobalType, IPeriod, ISelfViewDto} from '../../../models/selfview.model';
import {TranslateService} from '@ngx-translate/core';
import {chartColors} from '../../../selfview.graph.colors';
import { SelfViewService } from '../../../services/selfview-general.service';

@Component({
  selector: 'app-reactive-periods-chart',
  templateUrl: './reactive-periods-chart.component.html',
  styleUrls: ['./reactive-periods-chart.component.scss']
})
export class ReactivePeriodsChartComponent implements OnInit {
  flipped: boolean;

  // Charts
  globalType: GlobalType;

  compareMode = false;
  reactiveData: any;
  reactiveDataCompare: any;
  periodChartOptions: any;
  periodChartOptionsNormal: any;
  periods: IPeriod[] = [];
  initForPeriodGraph = {
    renderer: 'svg',
    height: 310
  };
  type;
  type1;

  chartOptions;
  // Translations
  datagraf;
  datagraf2;
  ps = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'];
  psData = {
    'P1 I': true,
    'P1 C': true,
    'P2 I': true,
    'P2 C': true,
    'P3 I': true,
    'P3 C': true,
    'P4 I': true,
    'P4 C': true,
    'P5 I': true,
    'P5 C': true,
    'P6 I': true,
    'P6 C': true,
    'P1 I*': true,
    'P1 C*': true,
    'P2 I*': true,
    'P2 C*': true,
    'P3 I*': true,
    'P3 C*': true,
    'P4 I*': true,
    'P4 C*': true,
    'P5 I*': true,
    'P5 C*': true,
    'P6 I*': true,
    'P6 C*': true
  };

  graphInitOptions = {
    renderer: 'svg',
    width: null,
    height: 400
  };



  constructor(private translate: TranslateService, private selfViewService: SelfViewService) {
    this.flipped = true;
  }

  ngOnInit() {
    this.selfViewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;

      this.selfViewService.dataReactive.subscribe((data: any) => {
        if (data && data.reactiveData) {
          this.reactiveData = data.reactiveData;
          this.showChartForGlobalType();
        }
      });

      this.selfViewService.dataReactiveCompare.subscribe((data: any) => {
        this.showChartComparisonForGlobalType(data);
      });
    });

  }

  modifySerie(serieName: string, $event) {
    const state = $event.checked;
    this.psData[`${serieName} I`] = state;
    this.psData[`${serieName} C`] = state;
    this.psData[`${serieName} I*`] = state;
    this.psData[`${serieName} C*`] = state;
    this.periodChartOptions.legend.selected = this.psData;
    this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};
  }

  initiatePowerChart() {
    const series = {};
    this.ps.forEach( (element, index) => {
      series[`${element} I`] = [
        this.reactiveData.totalConsumptionByPeriod_I[index],
        this.reactiveData.totalNetworkConsumptionByPeriod_I[index],
        this.reactiveData.totalExcedentaryByPeriod_I[index],
        this.reactiveData.totalGeneratedByPeriod_I[index],
      ];

      series[`${element} C`] = [
        -this.reactiveData.totalConsumptionByPeriod_C[index],
        -this.reactiveData.totalNetworkConsumptionByPeriod_C[index],
        -this.reactiveData.totalExcedentaryByPeriod_C[index],
        -this.reactiveData.totalGeneratedByPeriod_C[index],
      ];
    });

    const periodGraphBarWith = 10;
    this.periodChartOptionsNormal = {
      tooltip: {
        confine: false,
        trigger: 'axis',
        position: function (pos, params, dom, rect, size) {
          // tooltip will be fixed on the right if mouse hovering on the left,
          // and on the left if hovering on the right.
          const obj = {top: 60};
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
          return obj;
        },
        axisPointer: {
          type: 'shadow'
        },
        formatter: params => {
          let values = '';
          for (const param of params) {
            const isDefined = typeof (param) === 'undefined';
            if (!isDefined) {

              values += param.seriesName + ': ' + param.value + ' kWh <br>';
            }
          }

          return values;

        }
      },
      legend: {
        show: false,
        data: this.ps,
        selected: {}
      },
      grid: {
        top: '5%',
        left: '15%',
        right: '4%',
        bottom: '15%',
      },
      xAxis: {
        type: 'value',
        name: 'kWh',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: [10, 10, 0, 0]
        },
        axisLabel: {
          show: true,
          formatter: '{value}',
          rotate: 35,
          fontSize: 10
        }
      },
      yAxis: {
        type: 'category',
        data: ['Consumo Total', 'Consumida Red', 'Excedentaria', 'Neta Generada'],
        axisLabel: {
          rotate: 35,
          fontSize: 10
        }
      },
      series: [
        {
          name: 'P1 I',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p1},
          barWidth: periodGraphBarWith,
          data: series['P1 I']
        },
        {
          name: 'P1 C',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p1},
          barWidth: periodGraphBarWith,
          data: series['P1 C']
        },
        {
          name: 'P2 I',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p2},
          barWidth: periodGraphBarWith,
          data: series['P2 I']
        },
        {
          name: 'P2 C',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p2},
          barWidth: periodGraphBarWith,
          data: series['P2 C']
        },
        {
          name: 'P3 I',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p3},
          barWidth: periodGraphBarWith,
          data: series['P3 I']
        },
        {
          name: 'P3 C',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p3},
          barWidth: periodGraphBarWith,
          data: series['P3 C']
        },
        {
          name: 'P4 I',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p4},
          barWidth: periodGraphBarWith,
          data: series['P4 I']
        },
        {
          name: 'P4 C',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p4},
          barWidth: periodGraphBarWith,
          data: series['P4 C']
        },
        {
          name: 'P5 I',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p5},
          barWidth: periodGraphBarWith,
          data: series['P5 I']
        },
        {
          name: 'P5 C',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p5},
          barWidth: periodGraphBarWith,
          data: series['P5 C']
        },
        {
          name: 'P6 I',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p6},
          barWidth: periodGraphBarWith,
          data: series['P6 I']
        },
        {
          name: 'P6 C',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p6},
          barWidth: periodGraphBarWith,
          data: series['P6 C']
        }
      ]

    };
    this.periodChartOptions = this.periodChartOptionsNormal;
  }

  initiatePowerChartComparison() {

    const series = {};
    console.log(this.reactiveDataCompare);
    this.ps.forEach( (element, index) => {
      series[`${element} I*`] = [
        this.reactiveDataCompare.totalConsumptionByPeriod_I[index],
        this.reactiveDataCompare.totalNetworkConsumptionByPeriod_I[index],
        this.reactiveDataCompare.totalExcedentaryByPeriod_I[index],
        this.reactiveDataCompare.totalGeneratedByPeriod_I[index],
      ];

      series[`${element} C*`] = [
        -this.reactiveDataCompare.totalConsumptionByPeriod_C[index],
        -this.reactiveDataCompare.totalNetworkConsumptionByPeriod_C[index],
        -this.reactiveDataCompare.totalExcedentaryByPeriod_C[index],
        -this.reactiveDataCompare.totalGeneratedByPeriod_C[index],
      ];
    });

    const periodGraphBarWith = 8;

    const seriesComparison = [
      {
        name: 'P1 I*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p1_2},
        barWidth: periodGraphBarWith,
        data: series['P1 I*']
      },
      {
        name: 'P1 C*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p1_2},
        barWidth: periodGraphBarWith,
        data: series['P1 C*']
      },
      {
        name: 'P2 I*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p2_2},
        barWidth: periodGraphBarWith,
        data: series['P2 I*']
      },
      {
        name: 'P2 C*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p2_2},
        barWidth: periodGraphBarWith,
        data: series['P2 C*']
      },
      {
        name: 'P3 I*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p3_2},
        barWidth: periodGraphBarWith,
        data: series['P3 I*']
      },
      {
        name: 'P3 C*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p3_2},
        barWidth: periodGraphBarWith,
        data: series['P3 C*']
      },
      {
        name: 'P4 I*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p4_2},
        barWidth: periodGraphBarWith,
        data: series['P4 I*']
      },
      {
        name: 'P4 C*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p4_2},
        barWidth: periodGraphBarWith,
        data: series['P4 C*']
      },
      {
        name: 'P5 I*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p5_2},
        barWidth: periodGraphBarWith,
        data: series['P5 I*']
      },
      {
        name: 'P5 C*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p5_2},
        barWidth: periodGraphBarWith,
        data: series['P5 C*']
      },
      {
        name: 'P6 I*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p6_2},
        barWidth: periodGraphBarWith,
        data: series['P6 I*']
      },
      {
        name: 'P6 C*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p6_2},
        barWidth: periodGraphBarWith,
        data: series['P6 C*']
      }
    ];


    if (this.periodChartOptions.series.length > 6) {
      this.periodChartOptions.series = this.periodChartOptions.series.splice(0, this.periodChartOptions.series.length - 6);

    }

    const totalSeries = this.periodChartOptions.series.concat(seriesComparison);
    this.periodChartOptions.series = totalSeries;

    this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};
  }
  translateAll() {
    this.translate.get('SELFVIEW.HTML.RANGES').subscribe((res: string) => {

    });
  }

  showChartForGlobalType() {

      if (this.reactiveData !== null) {
          if (this.globalType === GlobalType.Energy) {
            this.reactiveData = this.reactiveData;
            this.type = false;
            this.initiatePowerChart();
          } else if (this.globalType === GlobalType.Economic) {
            //this.reactiveDataEconomico = this.selfViewDataEconomico;
            //this.type = true;
            //this.initiateEconomicChart();
          }

        if (this.compareMode) {
          if (this.reactiveDataCompare !== null && this.globalType === GlobalType.Energy) {
            this.initiatePowerChartComparison();
          }
        }
      }
  }

  showChartForLocalType() {
    this.selfViewService.dataNormal.subscribe((data: ISelfViewDto) => {
      if (data !== null) {
        if (data.selfViewDataPower != null || data.selfViewDataEconomico != null) {
          if (this.type === false) {
            this.reactiveData = data.selfViewDataPower;
            this.initiatePowerChart();
          } else if (this.type === true) {

          }
        }

        if (this.compareMode) {
          if (this.reactiveDataCompare !== null && this.type === false) {
            this.initiatePowerChartComparison();
          }
        }
      }
    });
  }

  showChartComparisonForGlobalType(data: any) {
    if (data !== null) {
      if (data !== null) {
        this.compareMode = true;

        this.reactiveDataCompare = data;
        if (data !== null && this.globalType === GlobalType.Energy) {
          this.initiatePowerChartComparison();
        }
      }
    } else {
      if (this.compareMode) {

        this.psData = {
          'P1 I': true, 'P2 I': true, 'P3 I': true, 'P4 I': true, 'P5 I': true, 'P6 I': true,
          'P1 C': true, 'P2 C': true, 'P3 C': true, 'P4 C': true, 'P5 C': true, 'P6 C': true,
          'P1 I*': false, 'P2 I*': false, 'P3 I*': false, 'P4 I*': false, 'P5 I*': false, 'P6 I*': false,
          'P1 C*': false, 'P2 C*': false, 'P3 C*': false, 'P4 C*': false, 'P5 C*': false, 'P6 C*': false
        };
        this.periodChartOptions.legend.selected = this.psData;
        this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};
        this.compareMode = false;
      } else {

      }

    }
  }
}
