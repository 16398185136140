import { Component, OnInit } from '@angular/core';
import { PVSelfBalanceResults } from 'src/app/modules/pvself/models/PVSelfResultsModel.model';
import { GridOptions } from 'ag-grid-community';
import { SelectionType } from 'src/app/modules/pvself/models/PVSelf.model';
import { EChartOption } from 'echarts';
import { PVSelfEngineService } from 'src/app/modules/pvself/services/pvself-engine.service';
import { chartColors } from '../pvself-graph-colors';
import { PPlusChartType } from 'projects/pplus/src/lib/models/pplus.enums';
import { ACTIVE_ECONOMIC_LEGENDS, ACTIVE_BAR_ECONOMIC_CHART_OPTIONS } from '../pvself-active-main.config';
import { ACTIVE_ECONOMIC_SERIES_DATA, PERIODS } from '../pvself-active-main.series';

@Component({
  selector: 'pplus-pvself-balance-main-economic-chart',
  templateUrl: './pvself-balance-main-economic-chart.component.html',
  styleUrls: ['./pvself-balance-main-economic-chart.component.scss']
})
export class PVSelfBalanceMainEconomicChartComponent implements OnInit {
  balanceResults: PVSelfBalanceResults;
  ACTIVE_ECONOMIC_SERIES_DATA = ACTIVE_ECONOMIC_SERIES_DATA;
  economicEchartsInstance: any;
  flipped: boolean;
  gridOptions: GridOptions;
  gridData: any;
  selectionType: SelectionType;
  economicLegends: any;
  activeEconomicChartOptions: EChartOption;


  /**
   * Store areas chart series visibility state
   */
  areaEconomicChartState = {};
  barEconomicChartState = {};

  /**
   * Initial options for chart
   */
  initOpts = {
    renderer: 'svg',
//    width: 'auto',
    height: '350px'
  };

  countEconomicOptions = {
    duration: 5,
    separator: ' ',
    suffix: ' €',
  };


  constructor(private engineService: PVSelfEngineService) { }




  ngOnInit() {

    this.selectionType = SelectionType.Energy;
    this.flipped = false;
    this.economicLegends = ACTIVE_ECONOMIC_LEGENDS;

    this.initBarEconomicChartState();
    this.initAreaEconomicChartState();

    this.engineService.balanceEnergyResults.subscribe((res: PVSelfBalanceResults) => {
      this.balanceResults = res;
      if (res !== null) { this.showEconomicChartForGlobalType(); }
    });
  }


  onEconomicChartInit(ec) {
    this.economicEchartsInstance = ec;
  }


  /**
   * Inits bar chart state. Show All series
   */
  initBarEconomicChartState() {
    Object.keys(ACTIVE_ECONOMIC_SERIES_DATA).forEach((key) => {
      const serieName = ACTIVE_ECONOMIC_SERIES_DATA[key].label;

      Object.keys(PERIODS).forEach((period: string) => {
        this.barEconomicChartState[`${serieName} ${period}`] = true;
      });
    });
  }


  initAreaEconomicChartState() {
    Object.keys(ACTIVE_ECONOMIC_SERIES_DATA).forEach((key) => {
      const serieName = ACTIVE_ECONOMIC_SERIES_DATA[key].label;

      Object.keys(PERIODS).forEach((period: string) => {
        this.areaEconomicChartState[`${serieName} ${period}`] = true;
      });
    });
  }




  /**
   * Called to show main chart.
   */
  showEconomicChartForGlobalType() {
    if (this.balanceResults !== null) {
      //      this.chartType = this.activeData.chartType;
      this.initiateEconomicChartBar();
    }
  }


  initiateEconomicChartBar() {
    this.activeEconomicChartOptions = null;
    this.activeEconomicChartOptions = { ...this.activeEconomicChartOptions, ...this.activeEconomicChartOptions };

    const series = this.generateSeriesForActiveBarEconomicChart();

    const chartOptions: any = ACTIVE_BAR_ECONOMIC_CHART_OPTIONS;
    chartOptions.legend.data = this.barEconomicChartState;
    chartOptions.legend.selected = { ...this.barEconomicChartState };
    chartOptions.series = [...series];
    chartOptions.xAxis[0].data = this.balanceResults.economicGroupedData.map(item => {
      const time = new Date(item.date);
      return time.toLocaleDateString('es-ES');
    });

    this.activeEconomicChartOptions = chartOptions;
    this.activeEconomicChartOptions = { ...this.activeEconomicChartOptions, ...this.activeEconomicChartOptions };
  }






  /**
   * Generates series for active bar chart
   */
  generateSeriesForActiveBarEconomicChart(compareMode: boolean = false) {
    const series = [];
    const data = this.balanceResults.economicGroupedData;

    data.forEach(element => {
      Object.keys(ACTIVE_ECONOMIC_SERIES_DATA).forEach((key, index) => {
        const serieName = ACTIVE_ECONOMIC_SERIES_DATA[key].id;

        if (!series[index]) {
          series[index] = [null, null, null, null, null, null];
        }
        // create series
        element[`${serieName}`].forEach((item, i) => {
          if (!series[index][i]) {
            series[index][i] = this.createActiveBarEconomicChartSerie(
              `${ACTIVE_ECONOMIC_SERIES_DATA[key].label} P${i + 1}`
              ,
              [],
              chartColors.periods[`${ACTIVE_ECONOMIC_SERIES_DATA[key].periodColorKey}P${i + 1}`
              ],
              ACTIVE_ECONOMIC_SERIES_DATA[key].id
            );
          }
          series[index][i].data.push(item);
        });
      });
    });
    return series.reduce((a, b) => [...a, ...b], []);
  }


  createActiveBarEconomicChartSerie(name: string, data: any, color: string, stack: string) {
    return {
      name,
      type: 'bar',
      data,
      itemStyle: { color },
      stack,
      xAxisIndex: 0
    };
  }



  toggleEconomicChartSerie(serie: any, $event: any) {
    // Get the zoom level of the graph and apply it in legend selection
    let zoom = null;
    if (this.economicEchartsInstance) {
      zoom = this.economicEchartsInstance.getOption().dataZoom;
      this.activeEconomicChartOptions.dataZoom = zoom;
    }
    const state = $event.checked;

    Object.keys(PERIODS).forEach((key) => {
      this.barEconomicChartState[`${serie.label} ${key}`] = state;
    });

    this.areaEconomicChartState[`${serie.label}`] = state;

    if (this.balanceResults.mainChartType === PPlusChartType.Bar) {
      this.activeEconomicChartOptions.legend.selected = this.barEconomicChartState;
    } else {
      this.activeEconomicChartOptions.legend.selected = this.areaEconomicChartState;
    }
    this.activeEconomicChartOptions = { ...this.activeEconomicChartOptions, ...this.activeEconomicChartOptions };
  }


}


