import { NgModule, Optional, SkipSelf, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbOAuth2AuthStrategy, NbAuthModule, NbOAuth2ResponseType, NbOAuth2AuthStrategyOptions, NbAuthStrategyClass, NbPasswordAuthStrategy, NbAuthJWTToken } from '@nebular/auth';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { environment } from 'src/environments/environment';

@Injectable()
export class NbGoogleOAuth2Strategy extends NbOAuth2AuthStrategy {
  static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [NbGoogleOAuth2Strategy, options];
  }
}

@Injectable()
export class NbFacebookOAuth2Strategy extends NbOAuth2AuthStrategy {
  static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [NbFacebookOAuth2Strategy, options];
  }
}

@Injectable()
export class NbLinkedinOAuth2Strategy extends NbOAuth2AuthStrategy {
  static setup(options: NbOAuth2AuthStrategyOptions): [NbAuthStrategyClass, NbOAuth2AuthStrategyOptions] {
    return [NbLinkedinOAuth2Strategy, options];
  }
}

export const NB_CORE_PROVIDERS = [
  ...NbAuthModule.forRoot({
    strategies: [

      NbPasswordAuthStrategy.setup({
        name: 'email',
        token: {
          class: NbAuthJWTToken,
          key: 'token'
        },
        baseEndpoint: environment.API_URL,
        login: {
          endpoint: '/api/user/login',
          method: 'post',
        },
        register: {
          endpoint: '/api/user/register',
          method: 'post',
        },
        requestPass: {
          endpoint: '/api/user/forget-password',
          method: 'post',
        },
        resetPass: {
          endpoint: '/api/user/reset-password',
          method: 'post',
        }
      }),

      NbGoogleOAuth2Strategy.setup({
        name: 'google',
        clientId: '1064534416757-b957e46v7kue7j818prfijtc67tcm9jq.apps.googleusercontent.com',
        clientSecret: 'hfAisLJXDgJM8gofOe0JU-yS',
        authorize: {
          endpoint: 'https://accounts.google.com/o/oauth2/v2/auth',
          responseType: NbOAuth2ResponseType.TOKEN,
          scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email',
          redirectUri: environment.URL_CALLBACK + '/auth/callback/google',
        }
      }),

      NbFacebookOAuth2Strategy.setup({
        name: 'facebook',
        clientId: '909114142813217',
        clientSecret: '2bda1a3543f73975748204b11aeb5a77',
        authorize: {
          endpoint: 'https://www.facebook.com/v3.2/dialog/oauth',
          responseType: NbOAuth2ResponseType.TOKEN,
          redirectUri: environment.URL_CALLBACK + '/auth/callback/facebook',
        }
      }),

      NbLinkedinOAuth2Strategy.setup({
        name: 'linkedin',
        clientId: '86a0ritvsrh21z',
        clientSecret: 'IXVnOtCGqENv7TBc',
        authorize: {
          endpoint: 'https://www.linkedin.com/oauth/v2/authorization',
          responseType: NbOAuth2ResponseType.CODE,
          scope: 'r_liteprofile r_emailaddress',
          redirectUri: environment.URL_CALLBACK + '/auth/callback/linkedin',
        },
        // token: {
        //   class: NbAuthOAuth2Token,
        // },
      }),

    ],
    forms: {
      login: {
      },
      register: {
      },
    },
  }).providers,
  NbGoogleOAuth2Strategy,
  NbFacebookOAuth2Strategy,
  NbLinkedinOAuth2Strategy,
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }

  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS
      ],
    };
  }
}
