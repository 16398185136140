import { Directive, ViewContainerRef, TemplateRef, OnInit, Input, AfterViewInit, ElementRef } from '@angular/core';
const DATA = [
  {
    'id': 'btn_1',
    'disabled': true,
    'hidden': false
  },
  {
    'id': 'btn_2',
    'disabled': false,
    'hidden': true
  },
  {
    'id': 'btn_3',
    'disabled': false,
    'hidden': false
  },
  {
    'id': 'btn_4',
    'disabled': true,
    'hidden': false
  },
  {
    'id': 'btn_5',
    'disabled': false,
    'hidden': false
  }
];


@Directive({
  selector: '[isAuthorized]'
})
export class IsAuthorizedDirective implements OnInit {

  @Input()
  set isAuthorized(id: string) {
    this.idComponent = id;
  }


  idComponent: string;
  exampleData = DATA;
  elementRef: ElementRef;



  constructor(el: ElementRef) {
    this.elementRef = el;
  }

  ngOnInit(): void {
    const dataComp = this.exampleData.find(o => o.id === this.idComponent);
    this.hideElement(dataComp.hidden);
    this.disableElement(dataComp.disabled);
  }


  hideElement(hidden: boolean) {
    this.elementRef.nativeElement.hidden = hidden;
  }

  disableElement(disabled: boolean): void {
    this.elementRef.nativeElement.disabled = disabled;
  }
}

/*

@Directive({
  selector: '[isAuthorized]'
})
export class IsAuthorizedDirective implements OnInit {
  exampleData = DATA;
  @Input()
  set isAuthorized(id: string) {
    this.idComponent = id;
  }
  idComponent: string;

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) { }

  ngOnInit(): void {
    const dataComp = this.exampleData.find( o => o.id === this.idComponent);
    const state = this.updateView(dataComp.visible);
    if (state) {
      this.disabledElement(dataComp.disabled);
    }
  }

  updateView(state: boolean): boolean {
    if (state) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return true;
    } else {
      this.viewContainer.clear();
      return false;
    }
  }

  disabledElement(disabled: boolean): void {
    this.templateRef.elementRef.nativeElement.nextElementSibling.disabled = disabled;
  }
}

*/
