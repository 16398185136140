import { Component, OnInit, Input } from '@angular/core';
import { Options } from 'ng5-slider';
import { PVSelfCalculation } from 'src/app/modules/pvself/models/PvSelfCalculation.model';
import { PVSelfPredimensioningResults } from 'src/app/modules/pvself/models/PVSelfResultsModel.model';
import { chart1Opts } from './chart1Options';
import { chart3Opts } from './chart3Options';
import { chart2Opts } from './chart2Options';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PVSelfEngineService } from 'src/app/modules/pvself/services/pvself-engine.service';
import { PVSelfCalculationService } from 'src/app/modules/pvself/services/pvself-calculation.service';



@Component({
  selector: 'app-pre',
  templateUrl: './pre.component.html',
  styleUrls: ['./pre.component.scss']
})
export class PreComponent implements OnInit {
  _calculation: PVSelfCalculation;
  @Input('results') predimensioningResults: PVSelfPredimensioningResults[];
  selectedProducerIndex: number;
  selectedConsumerIndex: number;
  selectedPredimensioningIndex: number;
  selectedPredimensioning: PVSelfPredimensioningResults;
  loaderId: string;
  echart1Instance: any;
  echart2Instance: any;
  echart3Instance: any;
  dirty: boolean;
  sliderPowerFromMin: number;
  sliderPowerFromMax: number;
  sliderPowerToMin: number;
  sliderPowerToMax: number;
  sliderAccumulationMin: number;
  sliderAccumulationMax: number;
  sliderPredimensioningMin: number;
  sliderPredimensioningMax: number;


  optionsSliderPredimensioning: Options = {
    floor: 1,
    ceil: 5,
    showTicks: false,
    showTicksValues: false,
    disabled: false,
    stepsArray: [
      { value: 1 },
      { value: 2 },
      { value: 3 },
      { value: 4 },
      { value: 5 },
      { value: 6 },
      { value: 7 },
      { value: 8 },
      { value: 9 },
      { value: 10 }
    ]
  };


  disabled = true;
  chart1Options;
  chart2Options;
  chart3Options;
  chartInitOptions = {
    renderer: 'svg',
    width: null,
    height: 400
  };


  constructor(private ngxLoaderService: NgxUiLoaderService,
    private calculationService: PVSelfCalculationService,
    private engineService: PVSelfEngineService) {
  }

  ngOnInit() {
    this.dirty = false;
    this.chart1Options = chart1Opts;
    this.chart2Options = chart2Opts;
    this.chart3Options = chart3Opts;
    this.loaderId = 'loader-' + Math.floor((Math.random() * 1000) + 1);

    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
    this.selectedProducerIndex = 0;
    this.selectedConsumerIndex = 0;
    this.selectedPredimensioningIndex = 1;

    this.sliderAccumulationMin = 1;
    this.sliderAccumulationMax = 100;

    this.sliderPredimensioningMin = 1;
    this.sliderPredimensioningMax = 10;

    this.updatePredimensoningLimits();
  }

  updatePredimensoningLimits() {
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
    //    this._calculation = this.calculationService.get();
    const consumer = this._calculation.consumers[this.selectedConsumerIndex];
    let maxpower = 0;
    consumer.rates[0].contracts.forEach(x => {
      const contractPower = parseInt(x.power.toString(), 0);
      if (contractPower > maxpower) { maxpower = contractPower; }
    });

    this.sliderPowerFromMin = Math.round(maxpower * 0.10);
    this.sliderPowerFromMax = Math.round(maxpower * 0.9);

    this.sliderPowerToMin = Math.round(maxpower * 0.10);
    this.sliderPowerToMax = Math.round(maxpower * 0.9);

    this._calculation.predimensionedValues.minPower = this.sliderPowerFromMin;
    this._calculation.predimensionedValues.maxPower = this.sliderPowerToMax;

  }


  calcular() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.engineService.calculatePredimensioningResults(this._calculation).subscribe(res => {
      this.dirty = true;
      this.predimensioningResults = res;
      this.sliderPredimensioningMax = res.length;
      this.updateChartsData(res);

      this._calculation.producers[this.selectedProducerIndex].installation.fields.forEach(x => {
        x.configuratorPeakPower = res.find(y => y.isOptimal === true).power;
      });

      this.selectedPredimensioningIndex = 1 + res.findIndex(y => y.isOptimal === true);

      this.ngxLoaderService.stopLoader(this.loaderId);
    },
      err => {
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
  }

  updateChartsData(_predimensioningResults: PVSelfPredimensioningResults[]) {
    this.predimensioningResults = _predimensioningResults;
    this.updateChart1Data();
    this.updateChart2Data();
    this.updateChart3Data();
  }


  updateChart1Data() {
    this.chart1Options.series[0].data = [];
    this.chart1Options.series[1].data = [];

    this.predimensioningResults.forEach(x => {
      this.chart1Options.series[0].data.push(x.totalCost);
      this.chart1Options.series[1].data.push(x.surface);
    });

    this.chart1Options = { ...this.chart1Options, ...this.chart1Options };
  }


  updateChart2Data() {
    this.chart2Options.series[0].data = [];
    this.chart2Options.series[1].data = [];

    this.predimensioningResults.forEach(x => {
      this.chart2Options.series[0].data.push(100 * x.profitability);
      this.chart2Options.series[1].data.push(x.saving);
    });

    this.chart2Options = { ...this.chart2Options, ...this.chart2Options };
  }


  updateChart3Data() {
    this.chart3Options.series[0].data = [];
    this.chart3Options.series[1].data = [];

    this.predimensioningResults.forEach(x => {
      this.chart3Options.series[0].data.push(100 * x.coverage);
      this.chart3Options.series[1].data.push(100 * x.exploitation);
    });

    this.chart3Options = { ...this.chart3Options, ...this.chart3Options };
  }


  onChart1Init(ec) {
    this.echart1Instance = ec;
  }


  onChart2Init(ec) {
    this.echart2Instance = ec;
  }

  onChart3Init(ec) {
    this.echart3Instance = ec;
  }

  // sliderPowerFromChange(event) {
  //   this.dirty = true;
  //   this.sliderPowerToMin = event.value;
  // }

  // sliderPowerToChange(event) {
  //   this.dirty = true;
  //   this.sliderPowerFromMax = event.value;
  // }


  setSelectedPowerRange(event) {
    this._calculation.predimensionedValues.minPower = event.from;
    this._calculation.predimensionedValues.maxPower = event.to;
    this.dirty = true;
  }

  setSelectedAccumulation(event) {
    this._calculation.predimensionedValues.accumulationValue = event.from;
    this.dirty = true;
  }

  setSelectedPredimensioningIndex(event) {
    this.selectedPredimensioningIndex = event.from;
  }

  changedExcess() {
    this.dirty = true;
    if (this._calculation.predimensionedValues.withExcess) {
      this._calculation.optimalPowerCriteriaData.excedentaryPercentage = 50;
    } else {
      this._calculation.optimalPowerCriteriaData.excedentaryPercentage = 20;
    }
  }
}
