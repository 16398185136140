import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneratedComponent } from './generated/generated.component';
import { TranslateModule } from '@ngx-translate/core';
import { NbCardModule, NbPopoverModule } from '@nebular/theme';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatIconModule, MatButtonModule, MatDatepickerModule, MatInputModule } from '@angular/material';
import { CountUpModule } from 'countup.js-angular2';
import { NgxEchartsModule } from 'ngx-echarts';
import { ReactiveEnergyChartComponent } from './generated/reactive-energy-chart/reactive-energy-chart.component';
import { ActiveEnergyChartComponent } from './generated/active-energy-chart/active-energy-chart.component';
import { PPlusToggleModule } from '../../toggle/toggle.module';
import { PPlusPlantSearchModule } from '../../plants-search/plants-search.module';
import { PPlusDateRangeModule } from '../../date-range/date-range.module';
import { ConsumedComponent } from './consumed/consumed.component';
import { CosphiComponent } from './cosphi/cosphi.component';
import { FormsModule } from '@angular/forms';
import { CosphiChartComponent } from './cosphi/cosphi-chart/cosphi-chart.component';
import { DataDownloadComponent } from './data-download/data-download.component';
import { AgGridModule } from 'ag-grid-angular';
import { PPlusAgGridModule } from '../../ag-grid/ag-grid-components.module';
import { TotalizerComponent } from './totalizer/totalizer.component';

@NgModule({
  declarations: [
    GeneratedComponent,
    ReactiveEnergyChartComponent,
    ActiveEnergyChartComponent,
    ConsumedComponent,
    CosphiComponent,
    CosphiChartComponent,
    DataDownloadComponent,
    TotalizerComponent
  ],
  imports: [
    CommonModule,
    PPlusToggleModule,
    PPlusPlantSearchModule,
    PPlusDateRangeModule,
    TranslateModule,
    NbCardModule,
    NgxUiLoaderModule,
    MatIconModule,
    MatButtonModule,
    NbPopoverModule,
    CountUpModule,
    NgxEchartsModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    PPlusAgGridModule,
    AgGridModule,

  ],
  exports: [
    GeneratedComponent,
    ConsumedComponent,
    PPlusToggleModule,
    PPlusPlantSearchModule,
    PPlusDateRangeModule,
    CosphiComponent,
    DataDownloadComponent,
    TotalizerComponent
  ],
  providers: [

  ]
})
export class MeterModule { }
