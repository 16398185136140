import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { WeatherStation, WeatherStationDataParams, WeatherStationDataDto, WeatherForecast } from './weather-station.models';

@Injectable({
  providedIn: 'root'
})
export class WeatherStationService {
  private _weatherStationByInstallationApi = environment.API_URL + '/api/weatherstation/getByInstallationId/';
  private _weatherStationDatasBetweenDatesApi = environment.API_URL + '/api/bigquery/weatherstation/historic/GetWeatherDatasBetweenDates';
  private _weatherStationForecastApi = environment.API_URL + '/api/weatherforecast/';
  private _weatherStationLastInstantApi = environment.API_URL + '/api/bigquery/weatherstation/historic/GetLastWeatherData';

  private _weatherStations = new BehaviorSubject(<WeatherStation[]> null);
  private _weatherStationsDatas = new BehaviorSubject(<WeatherStationDataDto[][]> null);
  private _weatherForecast = new BehaviorSubject(<WeatherForecast[]> null);
  private _weatherStationLastInstant = new BehaviorSubject(<WeatherStationDataDto> null);

  weatherStations: Observable<WeatherStation[]> = this._weatherStations.asObservable();
  weatherStationsDatas: Observable<WeatherStationDataDto[][]> =  this._weatherStationsDatas.asObservable();
  weatherForecast: Observable<WeatherForecast[]> =  this._weatherForecast.asObservable();
  weatherStationLastInstant: Observable<WeatherStationDataDto> =  this._weatherStationLastInstant.asObservable();

  constructor(private http: HttpClient) { }


  getWeatherStations(id: number) {
    return this.getWeatherStationByInstallationId(id)
    .subscribe( (results: WeatherStation[]) => {
      this._weatherStations.next(results);
    });
  }

  getWeatherStationsDatas(data: WeatherStationDataParams) {
    return this.getWeatherStationDatasBetweenDates(data)
    .subscribe( (results: WeatherStationDataDto[][]) => {
      this._weatherStationsDatas.next(results);
    });
  }

  getForecast(id: number) {
    return this.getWeatherStationForecast(id)
    .subscribe( (results: WeatherForecast[]) => {
      this._weatherForecast.next(results);
    });
  }

  getLastInstant(id: number) {
    return this.getWeatherStationLastInstant(id)
    .subscribe( (results: WeatherStationDataDto) => {
      this._weatherStationLastInstant.next(results);
    });
  }



  private getWeatherStationByInstallationId(id: number): Observable<WeatherStation[]> {
    return this.http.get<WeatherStation[]>(this._weatherStationByInstallationApi + id);
  }

  private getWeatherStationDatasBetweenDates(data: WeatherStationDataParams): Observable<WeatherStationDataDto[][]> {
    let params = new HttpParams();

    data.weatherStationIds.forEach( id => {
      params = params.append('weatherStationIds', id.toString() );
    });
    params = params.append('from', data.from);
    params = params.append('to', data.to);

    return this.http.get<WeatherStationDataDto[][]>(this._weatherStationDatasBetweenDatesApi,{params});
  }

  private getWeatherStationForecast(id: number): Observable<WeatherForecast[]> {
    return this.http.get<WeatherForecast[]>(this._weatherStationForecastApi + id + '?days=5');
  }

  private getWeatherStationLastInstant(id: number): Observable<WeatherStationDataDto> {
    let params = new HttpParams();
    params = params.append('InstallationId', id.toString());

    return this.http.get<WeatherStationDataDto>(this._weatherStationLastInstantApi,{params});
  }
}
