import { Injectable } from '@angular/core';
import { EntityType } from '../models/components/entityType.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class EntityTypesService {

  constructor(private httpClient: HttpClient) {
  }

  getList(): Observable<EntityType[]> {
    return this.httpClient.get<EntityType[]>(environment.API_URL + '/api/admin/entitytypes/getList').pipe(
      map(data => data));
  }
}
