import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { SelfviewGeneralTablesService } from '../../../services/selfview-general-tables.service';
import { ActiveHourlyData, ActiveGroupedData, TableData } from '../../../models/selfview-main.model';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { SelfViewService } from '../../../services/selfview-general.service';
import * as XLSX from 'xlsx';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { SelfViewParams } from '../../../models/selfview.model';
import { EMPTY } from 'rxjs';
import { DateMoment } from '../../../models/selfview-date.model';

@Component({
  selector: 'pplus-selfview-main-table',
  templateUrl: './selfview-main-table.component.html',
  styleUrls: ['./selfview-main-table.component.scss']
})
export class SelfviewMainTableComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('table', {static: false, read: ElementRef}) table: ElementRef;

  filterDate = new DateMoment();
  data = new TableData();
  dataCompare = new TableData();
  dataSource = new MatTableDataSource<ActiveHourlyData | ActiveGroupedData>([]);
  displayedColumns: string [];
  subheaderCols: number [];
  periodMode = false;
  compareMode = false;
  subheaderDisplayedColumns = ['total', 'totalGenerated', 'totalSelfConsumption', 'totalExcedentary', 'totalNetworkConsumption', 'totalConsumption'];
  _subheaderDisplayedColumns = ['_total', '_totalGenerated', '_totalSelfConsumption', '_totalExcedentary', '_totalNetworkConsumption', '_totalConsumption'];
  constructor(private tableService: SelfviewGeneralTablesService, private selfviewGeneral: SelfViewService) {
    this.updatedTableForPeriod();
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;

    this.selfviewGeneral.params.pipe(
      switchMap( (params: SelfViewParams) => {
          return this.tableService.getMainTableDate(params);
        }
      )).subscribe( (data: TableData) => {
          data = this.mapGeneral(data);
          this.data = {...data};
          this.generateDataSource(data.chartType, data);
        }
      );

    this.selfviewGeneral.paramsCompare.pipe(
      switchMap( (params: SelfViewParams) => {
        if (!params) {
          this.compareMode = false;
          const data = {...this.data};
          this.generateDataSource(data.chartType, data);
          return EMPTY;
        } else {
          return this.tableService.getMainTableDate(params);
        }
      })).subscribe( (data: TableData) => {
        this.compareMode = true;
        data = this.mapGeneral(data);
        this.dataCompare = {...data};
        this.generateDataSourceWithCompare(data.chartType, data);
      });
  }

  generateDataSource(chartType: number, obj: TableData): void {
    let data = {...obj};
    this.updatedTableForPeriod();
    switch (chartType) {
      case 1:
        this.dataSource.data = data.hourlyData;
        break;
      case 2:
        if (this.periodMode) {
          data = this.mapGroupedDataWithPeriod(data);
        }
        this.dataSource.data = data.groupedData;
        // this.dataSource.data = data.hourlyData;
        break;
    }
    if (this.compareMode && this.dataCompare) {
      this.generateDataSourceWithCompare(chartType, this.dataCompare);
    }
  }

  generateDataSourceWithCompare(chartType: number, obj: TableData): void {
    let normalData = {...this.data};
    let data = {...obj};
    const _data = [];
    switch (chartType) {
      case 1:
        this.dataSource.data = data.hourlyData;
        break;
      case 2:
        if (this.periodMode) {
          data = this.mapGroupedDataWithPeriod(data);
          normalData = this.mapGroupedDataWithPeriod(normalData);
        }
        const groupedData = data.groupedData;
        for (let i = 0; i < groupedData.length; i++) {
          _data.push(normalData.groupedData[i]);
          _data.push(groupedData[i]);
        }
        this.dataSource.data = _data;
        // this.dataSource.data = data.hourlyData;
        break;
    }
  }

  mapGeneral(data: TableData): TableData {
    if (data.groupedData) {
      data.groupedData.map( o => {
        const date = o.date;
        o.date = moment.utc(date).format('DD/MM/YYYY');
        o.hour = moment.utc(date).format('HH:mm');
      });
    }
    if (data.hourlyData) {
      data.hourlyData.map( o => {
        const date = o.hour;
        o.date = moment.utc(date).format('DD/MM/YYYY');
        o.hour = moment.utc(date).format('HH:mm');
      });
    }
    return data;
  }

  mapGroupedDataWithPeriod(data: TableData): TableData {
    const groupedData: ActiveGroupedData [] = [];
    data.groupedData.map( o => {
      for (let i = 0; i < o.consumptionByPeriod.length; i++) {
        groupedData.push({
          consumption: o.consumptionByPeriod[i],
          date: o.date,
          excedentary: o.excedentaryByPeriod[i],
          generated: o.generatedByPeriod[i],
          hour: o.hour,
          selfConsumption: o.selfConsumption[i],
          networkConsumption: o.networkConsumption[i],
          period: i + 1
        });
      }
    });
    data.groupedData = groupedData;
    return data;
  }

  updatedTableForPeriod(): void {
    if (this.periodMode) {
      this.displayedColumns = ['date', 'hour', 'period', 'generated', 'selfConsumption', 'excedentary', 'networkConsumption', 'consumption'];
      this.subheaderCols = [3, 1, 1, 1, 1, 1];
    } else {
      this.displayedColumns = ['date', 'hour', 'generated', 'selfConsumption', 'excedentary', 'networkConsumption', 'consumption'];
      this.subheaderCols = [2, 1, 1, 1, 1, 1];
    }
  }

  exportToExcel() {
    let ws: XLSX.WorkSheet;
    ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, 'PPlus_General.xlsx');
  }
}
