import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { SizeCRMCalculation } from '../models/sizeCRMCalculation.model';


@Injectable({
  providedIn: 'root'
})
export class SizeBasicCRMCalculationsService {

  constructor(private httpClient: HttpClient) { }

  getList(desde: Date, hasta: Date): Observable<SizeCRMCalculation[]> {

    const From = (desde.getMonth() + 1) + '-' + desde.getDate() + '-' + desde.getFullYear();
    const To = (hasta.getMonth() + 1) + '-' + hasta.getDate() + '-' + hasta.getFullYear();

    return this.httpClient.get<SizeCRMCalculation[]>(
      environment.API_URL + '/api/size/crmcalculations/GetList?From=' + From + '&To=' + To + '&origin=3').pipe(data => data);
  }
}
