import { AfterViewInit, Component, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { NbMenuService } from '@nebular/theme';
import { Subscription } from 'rxjs';

import { TabsComponent } from 'projects/pplus/src/lib/components/tabs/tabs.component';

@Component({
  selector: 'app-selfview',
  templateUrl: './selfview.component.html',
  styleUrls: ['./selfview.component.scss']
})
export class SelfviewComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('General', { static: false }) selfViewTemplate;
  @ViewChild('MeterGenerated', { static: false }) meterGeneratedTemplate;
  @ViewChild('MeterConsumed', { static: false }) meterConsumedTemplate;
  @ViewChild('MeterCosphi', { static: false }) meterCosphiTemplate;
  @ViewChild('MeterDataDownload', { static: false }) meterDataDownloadTemplate;
  @ViewChild('MeterTotalizer', { static: false }) meterTotalizerTemplate;
  @ViewChild('Reactive', { static: false }) reactiveTemplate;
  @ViewChild('ZeroInjection', { static: false }) zeroInjectionTemplate;
  @ViewChild('WeatherStation', { static: false }) weatherStationTemplate;
  @ViewChild('ConsumptionMeter', { static: false }) consumptionMeterTemplate;
  @ViewChild('BorderMeter', { static: false }) borderMeterTemplate;
  @ViewChild('Inverters', { static: false }) invertersTemplate;
  @ViewChild('Active', { static: false }) activeTemplate;

  @ViewChild(TabsComponent, { static: false }) tabsComponent;
  private subscriptions: Subscription = new Subscription();

  static close(popover) {
    popover.hide();
  }

  constructor(private nbMenuService: NbMenuService) {
    this.nbMenuService.onItemClick().subscribe((res) => {
      const itemSelected = res.item;
      switch (itemSelected.queryParams.code) {
        case 'Self-View':
          this.openTab('General', this.selfViewTemplate);
          break;
        case 'Meter-Generated':
          this.openTab('Meter Generated', this.meterGeneratedTemplate);
          break;
        case 'Meter-Consumed':
          this.openTab('Meter Consumed', this.meterConsumedTemplate);
          break;
        case 'CosPhi':
          this.openTab('Cos Phi', this.meterCosphiTemplate);
          break;
        case 'DataDownload':
          this.openTab('Data Download', this.meterDataDownloadTemplate);
          break;
        case 'Totalizer':
          this.openTab('Totalizer', this.meterTotalizerTemplate);
          break;
        case 'Reactive':
          this.openTab('Reactive', this.reactiveTemplate);
          break;
        case 'ZeroInjection':
          this.openTab('Zero Injection', this.zeroInjectionTemplate);
          break;
        case 'WeatherStation':
          this.openTab('Weather Station', this.weatherStationTemplate);
          break;
        case 'ConsumptionMeter':
          this.openTab('Consumption Meter', this.consumptionMeterTemplate);
          break;
        case 'BorderMeter':
          this.openTab('Border Meter', this.borderMeterTemplate);
          break;
        case 'SelfViewInverter':
          this.openTab('Inverters', this.invertersTemplate);
          break;
        case 'SelfViewActive':
          this.openTab('Active', this.activeTemplate);
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {

  }

  openTab(title: string, template: ViewChild) {
    this.tabsComponent.openTab(title, template, {}, true);
  }

  ngAfterViewInit() {
    //    this.openTab('General', this.selfViewTemplate);
    this.openTab('Active', this.activeTemplate);
    //    this.openTab('Inverters', this.invertersTemplate);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}


