export const STRING_HEADERS = [
  {
    headerName: 'ID',
    rowDrag: true,
    minWidth: 100,
    field: 'Id',
    filter: 'agTextColumnFilter',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
    headerCheckboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
    resizable: true,
    status: false,
  },


  {
    headerName: 'Nombre',
    minWidth: 100,
    field: 'StringName',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Código',
    rowDrag: true,
    minWidth: 100,
    field: 'StringCode',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Tipo de panel asociado',
    rowDrag: true,
    minWidth: 100,
    field: 'AssociatedPanelType',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Nº de paneles asociados',
    rowDrag: true,
    minWidth: 100,
    field: 'NumberAsociatedPanels',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Potencia pico',
    rowDrag: true,
    minWidth: 100,
    field: 'PeakPower',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Nota',
    rowDrag: true,
    minWidth: 100,
    field: 'Note',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Inversor asociado',
    rowDrag: true,
    minWidth: 100,
    field: 'AsociatedInverter',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },

  {
    headerName: 'Recalculo de potencia pico',
    rowDrag: true,
    minWidth: 100,
    field: 'RecalPeakPower',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
];
