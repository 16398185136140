import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from './auth.component';
import { CallbackModule } from './callback/callback.module';
import { RouterModule, Route } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmPasswordComponent } from './components/confirm-password/confirm-password.component';
import { RegisterComponent } from './components/register/register.component';
import { RequestPasswordComponent } from './components/request-password/request-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TermsDialogComponent } from './components/register/terms-dialog/terms-dialog.component';
import { LoginComponent } from './components/login/login.component';
import { NbAuthModule } from '@nebular/auth';
import { NbInputModule, NbSelectModule, NbCardModule, NbLayoutModule, NbAlertModule, NbPopoverModule, NbCheckboxModule, NbButtonModule, NbDialogService, NbDialogModule } from '@nebular/theme';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PPlusRequestInterceptor } from 'projects/pplus/src/lib/interceptors/pplus.interceptor';
import { AuthGuard } from './auth.guard';
import { MatButtonModule, MatCheckboxModule, MatInputModule, MatFormFieldModule, MatCardModule, MatToolbarModule, MatSelectModule, MatSnackBarModule } from '@angular/material';

export const AuthRouter: Route [] = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'callback',
        loadChildren: () => import('./callback/callback.module').then(mod => mod.CallbackModule)
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'request-password',
        component: RequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'confirm',
        component: ConfirmPasswordComponent,
      },
    ],
  }
];

@NgModule({
  declarations: [
    AuthComponent,
    ConfirmPasswordComponent,
    RegisterComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    TermsDialogComponent,
    LoginComponent,
  ],
  imports: [
    CommonModule,
    CallbackModule,
    NbAuthModule,
    NbInputModule,
    RouterModule.forChild(AuthRouter),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    NbSelectModule,
    NbCardModule,
    NbLayoutModule,
    NbAlertModule,
    NbPopoverModule,
    NbCheckboxModule,
    NbButtonModule,
    NbDialogModule.forChild(),
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatToolbarModule,
    MatSelectModule,
    MatSnackBarModule
  ],
  exports: [
    AuthComponent,
    CallbackModule,
    ConfirmPasswordComponent,
    RegisterComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    LoginComponent
  ],
  entryComponents: [
    TermsDialogComponent
  ],
  providers: [
    AuthGuard,
    NbDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PPlusRequestInterceptor,
      multi: true
    }
  ]
})
export class AuthModule { }
