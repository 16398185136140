import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { FormControl, FormGroup} from '@angular/forms';
import { StyleService } from 'src/app/modules/admin/domains/components/style/style.service';
import { TermsDialogComponent } from '../../../../../../../src/app/@core/_auth/components/register/terms-dialog/terms-dialog.component';

@Component({
  selector: 'pplus-dialogColor',
  templateUrl: './dialog-color.component.html',
  styleUrls: ['./dialog-color.component.css']
})
export class DialogColorComponent implements OnInit{
  
  public arrayColors: any = {
    primary: '#2883e9',
    success: '#2883e9',
    info: '#2883e9',
    warning: '#2883e9',
    danger: '#2883e9'
  };

  public colors;
  public selectedColor: string = 'color1';
  form: FormGroup;
  title: string = 'Theme Color';
  logo: any;
  cssStyle: string;
  entityId: number;

  constructor(protected ref : NbDialogRef<TermsDialogComponent>, private styleService: StyleService, private toastService: NbToastrService) {
    this.form = new FormGroup({
      LogoFile : new FormControl(),
      CssFile:  new FormControl(),
      Description: new FormControl()
    })
  }

  addFile(event){
    if(event.target.files && event.target.files.length > 0) {
      this.logo = event.target.files[0];
    };
  }


  ngOnInit(){
    this.arrayColors['primary'] = this.colors.split(",")[0]
    this.arrayColors['success'] = this.colors.split(",")[1]
    this.arrayColors['info'] = this.colors.split(",")[2]
    this.arrayColors['warning'] = this.colors.split(",")[3]
    this.arrayColors['danger'] = this.colors.split(",")[4]
  }

  update(model: any){
    let formData: FormData = new FormData();

    formData.append('Description', this.form.get('Description').value)
    formData.append('Colors', this.getColors());
    formData.append('CssFile', this.form.get('Description').value);
    formData.append('LogoFile', this.logo, this.logo.name);

    this.styleService.updateStyle(this.entityId , formData )
    .subscribe( (res: Response) => {
      if(res.status == 200){
        this.toastService.show('Success',`Success Update`);
      }else{
        console.log('ERROR')
        this.toastService.show('Danger',`Error Update`);
      }
    })
  }

  dismiss(){
    this.ref.close();
  }

  getLogoFile() : string {
    return `data:image/gif;base64,${this.logo}`
  }

  getColors(){
    return this.arrayColors['primary'] + ',' 
    + this.arrayColors['success'] + ',' 
    + this.arrayColors['info'] + ',' 
    + this.arrayColors['warning'] + ','
    + this.arrayColors['danger']
  }
}
