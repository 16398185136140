import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { ProfilesService } from 'projects/pplus/src/lib/services/permissions/profiles.service';
import { FormGroup, FormControl } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { RolesService } from 'projects/pplus/src/lib/services/permissions/roles.service';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { RoleProfile, Profile } from 'projects/pplus/src/lib/models/permissions/profile.model';
import { map } from 'rxjs/operators';
import { PROFILES_FORM } from './profile-form';

@Component({
  selector: 'pplus-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  formData = PROFILES_FORM;
  formGroup: FormGroup;
  selectedRowData: any;

  constructor(private toastService: NbToastrService,
    private rolesService: RolesService,
    private dataRowService: RowDataService,
    private profilesService: ProfilesService) { }

  ngOnInit() {
    this.generateForm(this.formData);
    this.subscribeDataRowService();
  }

  generateForm(data: DynamicForm[]) {
    this.rolesService.getList().subscribe(roles => {
      const fieldsCtrls = {};
      data.forEach(item => {
        const fields = item.fields;
        fields.forEach(f => {
          switch (f.name) {
            case 'roleIds':
              f.options = [];
              roles.map(o => {
                f.options.push({
                  label: o.description,
                  value: o.roleId,
                });
              });
              break;
          }
          fieldsCtrls[f.name] = new FormControl(f.value, f.validators);
        });
        this.formGroup = new FormGroup(fieldsCtrls);
      });
    });
  }

  subscribeDataRowService() {
    this.dataRowService.currentRowData.pipe(map(o => {
      this.formData.forEach(data => {
        const fields = data.fields;
        fields.map(field => {

          switch (field.name) {
            case 'roleIds':
              const rp = o['roleProfiles'];
              const values = [];
              if (rp && rp.length) { for (const x of rp) { values.push(x.roleId); } }
              field.value = values;
              break;

            default:
              field.value = o[field.name];
              break;
          }
        });
      });
      return o;
    })).subscribe(data => {
      this.selectedRowData = data;
      this.generateForm(this.formData);
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      //      this.showFormValidationErrors();
    } else {
      const profile: Profile = this.formGroup.value;
      profile.roleProfiles = [];
      this.formGroup.value.roleIds.forEach(x => {
        const rp = new RoleProfile;
        rp.profileId = 0;
        rp.roleId = x;
        profile.roleProfiles.push(rp);
      });
      //      role.rolePermissions = this.formGroup.value.permissionIds;
      this.profilesService.create(profile).subscribe(success => {
        if (success) {
          this.toastService.show('Se ha creado el nuevo elemento', 'Elemento creado');
          this.formGroup.reset();
          //          this.clearData();
          this.updateDelete.emit({ Type: 'create' });
        } else {
          this.toastService.show('Se ha producido un error al crear el dominio: ', 'ERROR');
        }
      });
    }

  }

  update() {
    if (this.formGroup.invalid) {
      //      this.showFormValidationErrors();
    } else {
      const profile = this.formGroup.value;
      profile.profileId = this.selectedRowData.profileId;
      profile.roleProfiles = [];
      this.formGroup.value.roleIds.forEach(x => {
        const rp = new RoleProfile;
        rp.profileId = this.selectedRowData.profileId;
        rp.roleId = x;
        profile.roleProfiles.push(rp);
      });
      this.profilesService.update(profile).subscribe(success => {
        if (success) {
          //          this.showToast('', 'success', 'Se ha creado el nuevo dominio: ' + data.Fqdn, 'Elemento creado');
          this.toastService.show('Se ha modificado el elemento con éxito', 'Elemento modificado');
          this.formGroup.reset();
          //          this.clearData();
          this.updateDelete.emit({ Type: 'update' });
        } else {
          this.toastService.show('Se ha producido un error al modificar el elemento: ', 'ERROR');
          //          this.showToast('', 'danger', 'Se ha producido un error al crear el dominio: ' + data.Fqdn, 'ERROR');
        }
      });
    }
  }

  delete() {
    if (this.selectedRowData.permissionId == null) {
      this.toastService.show('Seleccione un elemento', 'Info');
    } else {
      this.rolesService.delete(this.selectedRowData.permissionId).subscribe(success => {
        if (success) {
          this.toastService.show('Se ha borrado el elemento', 'Elemento eliminado');
          //          this.updateDelete.emit({Type: 'delete', EntityDomainId: this.selectedRowData.permissionId});
          //          this.clearData();
        } else {
          this.toastService.show('Se ha producido un error al intentar borrar el elemento', 'Error');
        }
      });
    }
  }

  exit() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt: opt, mode: 'default' });
    //    this.clearData();
  }

  copy() {
    /*    if (this.data.EntityDomainId == null) {
          this.showToast('', 'info', 'Seleccione un dominio para copiar la información', 'Info');
        } else {
          this.formType = 'create';
          this.formGroup.setValue(Object.assign(this.data, this.formGroup.value, {
            EntityDomainId: null,
            Fqdn: '',
          }));
        }
      */
  }

  openDialog(dialog: TemplateRef<any>) {
    /*    if (this.data.EntityDomainId == null) {
          this.showToast('', 'info', 'No hay ningún dominio seleccionado', 'Info');
        } else {
          this.dialog.open(dialog, { hasScroll: false });
        }
      */
  }
}
