import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "src/app/@core/_auth/auth.guard";
import { UserErrorComponent } from "./user-error.component";
import { NgModule } from "@angular/core";



export const UserErrorRoutes: Routes = [
    {
        path: '',
//        canActivate: [AuthGuard],
        component: UserErrorComponent
    },
];

@NgModule({
    declarations: [UserErrorComponent],
    imports: [
        RouterModule.forChild(UserErrorRoutes),
    ],
    exports: [
        UserErrorComponent
    ]
})
export class UserErrorModule { }
