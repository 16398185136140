import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PVSelfCalculation } from '../../models/PvSelfCalculation.model';
import { PVSelfCalculationService } from '../../services/pvself-calculation.service';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-pvself-my-calculations',
  templateUrl: './pvself-my-calculations.component.html',
  styleUrls: ['./pvself-my-calculations.component.scss']
})
export class PVSelfMyCalculationsComponent implements OnInit {
  @Output() onOpenCalculation = new EventEmitter();
  calculations: PVSelfCalculation[];
  loaderId: string;

  columnDefs = [
    { headerName: 'ID', field: 'pvSelfCalculationId', resizable: true, hide: true },
    { headerName: 'Descripción', field: 'description', resizable: true },
    { headerName: 'Fecha de creación', field: 'creationDate', resizable: true, cellRenderer: (data) => moment(data.data.creationDate).format('DD/MM/YYYY HH:mm:ss') },
    { headerName: 'Fecha última modificación', field: 'lastModificationDate', resizable: true, cellRenderer: (data) => moment(data.data.lastModificationDate).format('DD/MM/YYYY HH:mm:ss') },
    { headerName: 'Bloqueado', field: 'locked', resizable: true, cellRenderer: params => `<input type='checkbox' disabled='disabled' ${params.value ? 'checked' : ''} />` }
  ];


  constructor(private calculationsService: PVSelfCalculationService,
    private ngxLoaderService: NgxUiLoaderService) { }

  ngOnInit() {
    this.calculations = [];
    this.loaderId = 'loader-mycalculations-component' + Math.floor((Math.random() * 100) + 1);
    this.refresh();
  }

  refresh() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.calculationsService.getByActiveUser().subscribe(res => {
      this.calculations = res;
      this.ngxLoaderService.stopLoader(this.loaderId);
    });
  }



  onGridReady(params) {
    //    this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();

    // window.addEventListener('resize', function () {
    //   setTimeout(function () {
    //     params.api.sizeColumnsToFit();
    //   });
    // });
  }

  rowDoubleClicked(event) {
    this.onOpenCalculation.emit(event.data.pvSelfCalculationId);
  }

}
