import { Plant } from 'projects/pplus/src/lib/models/components/selfview.model';
import { ColDef, GridOptions } from 'ag-grid-community';
import * as moment from 'moment';
import { DATE_FORMAT, ADMIN_DEFAULT_COL_DEF } from '../admin.model';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';
import { AgBoolFilterComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-bool-filter/ag-bool-filter.component';
import { AgBoolFloatingFilterComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-bool-filter/ag-bool-floating-filter/ag-bool-floating-filter.component';

export class InstallationResponse {
  allInstallations: Installation [];
  lastRow: number;
}

export class Installation {
  installationId: number;
  name: string;
  nameDemo: string;
  code: string;
  codeDemo: string;
  technologyId: number;
  typologyId: number;
  creationDate: Date;
  deletionDate: Date;
  activated: boolean;
  location: string;
  countryId: number;
  population: string;
  postalCode: string;
  nominalPower: number;
  peakPower: number;
  losses: number;
  deviationMargin: number;
  dataColletiponResponsible: string;
  ttrResponsable: string;
  utmCoordinates: string;
  notes: string;
  pricingDate: Date;
  timeZone: null;
  panels: [];
  stringModules: [];
  plantInstallations: Plant [];
  meteoSensorPlantInstallation: [];
  installationInverter: [];
  installationZeroInjection: [];
  installationTracker: [];
  entityInstallation: [];
  installationContacts: [];
  consumptionContracts: [];
  serviceRecords: [];
  plantGroupInstallations: [];
}

export const INSTALLATIONS_GRID_OPTIONS: GridOptions = {
  paginationPageSize: 20,
  rowDragManaged: true,
  floatingFilter: true,
  accentedSort: true,
  overlayLoadingTemplate: '<span class="ag-overlay-loading-center"><img src="assets/loader.svg" alt="loader" class="img-fluid" style="width:40px;height:40px;"></span>',
  rowSelection: 'multiple',
  rowModelType: 'infinite',
  infiniteInitialRowCount: 0,
  maxConcurrentDatasourceRequests: 2,
  maxBlocksInCache: 2,
  cacheOverflowSize: 2,
  animateRows: true,
  frameworkComponents: {
    checkBoxComponent: AgCheckboxComponent,
    boolFilter: AgBoolFilterComponent,
    boolFloatingFilter: AgBoolFloatingFilterComponent,
  }
};

export const INSTALLATIONS_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const INSTALLATIONS_COL_DEFS: ColDef[] = [
  {
    field: 'installationId',
    headerName: 'ID',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'nameDemo',
    headerName: 'Name Demo',
  },
  {
    field: 'code',
    headerName: 'Code',
  },
  {
    field: 'codeDemo',
    headerName: 'Code Demo',
  },
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    filter: 'agDateColumnFilter',
    valueFormatter: function (p) {
      if (p && p.data) {
        const date = moment(p.value);
        return date.isValid() ? p.data.creationDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'deletionDate',
    headerName: 'Deletion Date',
    filter: 'agDateColumnFilter',
    valueFormatter: function (p) {
      if (p && p.data) {
        const date = moment(p.value);
        return date.isValid() ? p.data.deletionDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'activated',
    headerName: 'Activated',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkBoxComponent',
  },
  {
    field: 'technology',
    headerName: 'Technology',
    valueFormatter: function (p) {
      if (p && p.data) {
        const technology = p.data.technology;
        return technology ? technology.name : '';
      }
    },
  },
  {
    field: 'typology',
    headerName: 'Typology',
    valueFormatter: function (p) {
      if (p && p.data) {
        const typology = p.data.typology;
        return typology ? typology.name : '';
      }
    },
  },
  {
    field: 'country',
    headerName: 'Country',
    valueFormatter: function (p) {
      if (p && p.data) {
        const country = p.data.country;
        return country ? country.name : '';
      }
    },
  },
  {
    field: 'province',
    headerName: 'Province',
  },
  {
    field: 'population',
    headerName: 'Population',
  },
  {
    field: 'postalCode',
    headerName: 'Postal Code',
  },
  {
    field: 'nominalPower',
    headerName: 'Nominal Power',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'peakPower',
    headerName: 'Peak Power',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'losses',
    headerName: 'Losses',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'deviationMargin',
    headerName: 'Deviation Margin',
    filter: 'agNumberColumnFilter',
    valueFormatter: function (p) {
      if (p) return `${p.value} %`;
    },
  },
  {
    field: 'serviceDetails',
    headerName: 'Service Details',
  },
  {
    field: 'serviceFinishDate',
    headerName: 'Service finish date',
    filter: 'agDateColumnFilter',
    valueFormatter: function (p) {
      if (p && p.data) {
        const date = moment(p.value);
        return date.isValid() ? p.data.serviceFinishDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'serviceStartDate',
    headerName: 'Service start date',
    filter: 'agDateColumnFilter',
    valueFormatter: function (p) {
      if (p && p.data) {
        const date = moment(p.value);
        return date.isValid() ? p.data.serviceStartDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'timeZone',
    headerName: 'Timezone',
  },
  // {
  //   field: 'serviceRecords',
  //   headerName: 'Service Records',
  // },
  {
    field: 'plantInstallations',
    headerName: 'Plants',
    valueFormatter: function (p) {
      if (p && p.data) {
        let value = '';
        const plants = p.data.plantInstallations;
        if (plants.length) for (let plant of plants) {
          if (value) value += ', ';
          value += plant.plant.name;
        }
        return value;
      }
    },
  },
  {
    field: 'meteoSensorPlantInstallation',
    headerName: 'Meteo Sensors',
    valueFormatter: function (p) {
      if (p && p.data) {
        let value = '';
        const meteoSensors = p.data.meteoSensorPlantInstallation;
        if (meteoSensors.length) for (let ms of meteoSensors) {
          if (value) value += ', ';
          value += ms.meteoSensor.model;
        }
        return value;
      }
    },
  },
  {
    field: 'installationInverter',
    headerName: 'Inverters',
    valueFormatter: function (p) {
      if (p && p.data) {
        let value = '';
        const inverters = p.data.installationInverter;
        if (inverters.length) for (let inv of inverters) {
          if (value) value += ', ';
          value += inv.name;
        }
        return value;
      }
    },
  },
  {
    field: 'consumptionContracts',
    headerName: 'Consumptions Contracts',
    valueFormatter: function (p) {
      if (p && p.data) {
        let value = '';
        const consumptionContracts = p.data.consumptionContracts;
        if (consumptionContracts.length) for (let cs of consumptionContracts) {
          if (value) value += ', ';
          value += cs.consumptionRate.description;
        }
        return value;
      }
    },
  },
  {
    field: 'plantGroupInstallations',
    headerName: 'Plants Groups',
    valueFormatter: function (p) {
      if (p && p.data) {
        let value = '';
        const plantGroups = p.data.plantGroupInstallations;
        if (plantGroups.length) for (let pg of plantGroups) {
          if (value) value += ', ';
          value += pg.plantGroup.name;
        }
        return value;
      }
    },
  },
  {
    field: 'installationContacts',
    headerName: 'Contacts',
    valueFormatter: function (p) {
      if (p && p.data) {
        let value = '';
        const contacts = p.data.installationContacts;
        if (contacts.length) for (let c of contacts) {
          if (value) value += ', ';
          value += c.contact.name;
        }
        return value;
      }
    },
  },
  {
    field: 'entityInstallation',
    headerName: 'Entities',
    valueFormatter: function (p) {
      if (p && p.data) {
        let value = '';
        const entities = p.data.entityInstallation;
        if (entities.length) for (let e of entities) {
          if (value) value += ', ';
          value += e.entity.code;
        }
        return value;
      }
    },
  },
  {
    field: 'installationTracker',
    headerName: 'Trackers',
    valueFormatter: function (p) {
      if (p && p.data) {
        let value = '';
        const trackers = p.data.installationTracker;
        if (trackers.length) for (let t of trackers) {
          if (value) value += ', ';
          value += t.tracker.model;
        }
        return value;
      }
    },
  },
  {
    field: 'installationZeroInjection',
    headerName: 'Zero Injection',
    valueFormatter: function (p) {
      if (p && p.data) {
        let value = '';
        const zeroInjections = p.data.installationZeroInjection;
        if (zeroInjections.length) for (let zi of zeroInjections) {
          if (value) value += ', ';
          value += zi.zeroInjection.model;
        }
        return value;
      }
    },
  },
  {
    field: 'stringModules',
    headerName: 'String',
    valueFormatter: function (p) {
      if (p && p.data) {
        let value = '';
        const strings = p.data.stringModules;
        if (strings.length) for (let s of strings) {
          if (value) value += ', ';
          value += s.name;
        }
        return value;
      }
    },
  },
];
