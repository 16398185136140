import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { GENERIC_PERMISSIONS_FORM } from './generic-permission-form';
import { FormGroup, FormControl } from '@angular/forms';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { EntityTypesService } from 'projects/pplus/src/lib/services/entity-types.service';
import { ElementTypesService } from 'projects/pplus/src/lib/services/element-types.service';
import { forkJoin } from 'rxjs';
import { NbToastrService } from '@nebular/theme';
import { PermissionsService } from 'projects/pplus/src/lib/services/permissions/permissions.service';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { map } from 'rxjs/operators';
import { OperationsService } from 'projects/pplus/src/lib/services/permissions/operations.service';

@Component({
  selector: 'pplus-generic-permission-details',
  templateUrl: './generic-permission-details.component.html',
  styleUrls: ['./generic-permission-details.component.scss']
})
export class GenericPermissionDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  formData = GENERIC_PERMISSIONS_FORM;
  formGroup: FormGroup;
  selectedRowData: any;

  constructor(private toastService: NbToastrService,
    private operationsService: OperationsService,
    private entityTypesService: EntityTypesService,
    private elementTypesService: ElementTypesService,
    private permissionsService: PermissionsService,
    private dataRowService: RowDataService) { }

  ngOnInit() {
    this.generateForm(this.formData);
    this.subscribeDataRowService();
  }

  generateForm(data: DynamicForm[]) {
    forkJoin([
      this.operationsService.getList(),
      this.entityTypesService.getList(),
      this.elementTypesService.getList()])
      .subscribe(([operations, entityTypes, elementTypes]: [any, any, any]) => {
        const fieldsCtrls = {};
        data.forEach(item => {
          const fields = item.fields;
          fields.forEach(f => {
            switch (f.name) {
              case 'operationId':
                f.options = [];
                operations.map(o => {
                  f.options.push({
                    label: o.description,
                    value: o.operationId,
                  });
                });
                break;
              case 'entityTypeId':
                f.options = [];
                entityTypes.map(o => {
                  f.options.push({
                    label: o.description,
                    value: o.entityTypeId,
                  });
                });
                break;
              case 'elementTypeId':
                f.options = [];
                elementTypes.map(o => {
                  f.options.push({
                    label: o.name,
                    value: o.elementTypeId,
                  });
                });
                break;
            }
            fieldsCtrls[f.name] = new FormControl(f.value, f.validators);
          });
        });
        this.formGroup = new FormGroup(fieldsCtrls);
      });
  }

  subscribeDataRowService() {
    this.dataRowService.currentRowData.pipe(map(o => {
      this.formData.forEach(data => {
        const fields = data.fields;
        fields.map(field => {
          field.value = o[field.name];
        });
      });
      return o;
    })).subscribe(data => {
      this.selectedRowData = data;
      this.generateForm(this.formData);
    });
  }



  onSubmit() {
    if (this.formGroup.invalid) {
      //      this.showFormValidationErrors();
    } else {
      this.permissionsService.create(this.formGroup.value).subscribe(success => {
        if (success) {
          this.toastService.show('Se ha creado el nuevo elemento', 'Elemento creado');
          this.formGroup.reset();
          //          this.clearData();
          this.updateDelete.emit({ Type: 'create' });
        } else {
          this.toastService.show('Se ha producido un error al crear el dominio: ', 'ERROR');
        }
      });
    }

  }


  update() {
    if (this.formGroup.invalid) {
      //      this.showFormValidationErrors();
    } else {
      const updateData = this.formGroup.value;
      updateData.permissionId = this.selectedRowData.permissionId;
      this.permissionsService.update(updateData).subscribe(success => {
        if (success) {
          //          this.showToast('', 'success', 'Se ha creado el nuevo dominio: ' + data.Fqdn, 'Elemento creado');
          this.toastService.show('Se ha modificado el elemento con éxito', 'Elemento modificado');
          this.formGroup.reset();
          //          this.clearData();
          this.updateDelete.emit({ Type: 'update' });
        } else {
          this.toastService.show('Se ha producido un error al modificar el elemento: ', 'ERROR');
          //          this.showToast('', 'danger', 'Se ha producido un error al crear el dominio: ' + data.Fqdn, 'ERROR');
        }
      });
    }
  }



  delete() {
    if (this.selectedRowData.permissionId == null) {
      this.toastService.show('Seleccione un elemento', 'Info');
    } else {
      this.permissionsService.delete(this.selectedRowData.permissionId).subscribe(success => {
        if (success) {
          this.toastService.show('Se ha borrado el elemento', 'Elemento eliminado');
//          this.updateDelete.emit({Type: 'delete', EntityDomainId: this.selectedRowData.permissionId});
//          this.clearData();
        } else {
          this.toastService.show('Se ha producido un error al intentar borrar el elemento', 'Error');
        }
      });
    }
  }



  exit() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt: opt, mode: 'default' });
//    this.clearData();
  }


  copy() {
/*    if (this.data.EntityDomainId == null) {
      this.showToast('', 'info', 'Seleccione un dominio para copiar la información', 'Info');
    } else {
      this.formType = 'create';
      this.formGroup.setValue(Object.assign(this.data, this.formGroup.value, {
        EntityDomainId: null,
        Fqdn: '',
      }));
    }
  */  }


  openDialog(dialog: TemplateRef<any>) {
    /*    if (this.data.EntityDomainId == null) {
          this.showToast('', 'info', 'No hay ningún dominio seleccionado', 'Info');
        } else {
          this.dialog.open(dialog, { hasScroll: false });
        }
      */
  }
}
