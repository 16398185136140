import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TextComponent } from './text/text.component';
import { MatFormFieldModule, MatInputModule, MatCheckboxModule, MatSelectModule } from '@angular/material';
import { SelectComponent } from './select/select.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NumberComponent } from './number/number.component';

@NgModule({
  declarations: [
    CheckboxComponent,
    TextComponent,
    SelectComponent,
    NumberComponent
  ],
  exports: [
    FormsModule,
    CheckboxComponent,
    TextComponent,
    SelectComponent,
    NumberComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    NgxMatSelectSearchModule
  ],
})
export class TypesModule { }
