export class PeriodTableData {
  periodData: PeriodData [];
  totalConsumption: number;
  totalNetworkConsumption: number;
  totalSelfConsumption: number;
  totalExcedentary: number;
  totalGenerated: number;
}

export class PeriodData {
  period: number;
  consumption: number;
  networkConsumption: number;
  selfConsumption: number;
  excedentary: number;
  generated: number;
}