import { Injectable } from '@angular/core';
import { PVSelfInstallation } from '../models/PvSelfCalculation.model';
import { TabsComponent } from 'projects/pplus/src/lib/components/tabs/tabs.component';

@Injectable({
  providedIn: 'root'
})
export class PVSelfInstallationsService {
  installationTabs: TabsComponent;

  constructor() { }

  setTabs(_tabs: TabsComponent) {
    this.installationTabs = _tabs;
  }

  getInstallations() {
    let installations: PVSelfInstallation[] = [];
    this.installationTabs.dynamicTabs.forEach(x => {
      x.dataContext.installation.description = x.title;
      installations.push(x.dataContext.installation);
    });
    return installations;
  }
}
