import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { PVSelfMyConfiguration } from '../models/PVSelfMyConfiguration.model';
import { HttpClient } from '@angular/common/http';
import { PVSelfCalculation, PVSelfConsumptionContract } from '../models/PvSelfCalculation.model';

@Injectable({
  providedIn: 'root'
})
export class PVSelfMyConfigurationService {

  constructor(private httpClient: HttpClient) { }

  getActiveUserMyConfiguration(): Observable<PVSelfMyConfiguration> {
    return this.httpClient.get<PVSelfMyConfiguration>(
      environment.API_URL + '/api/pvself/myconfiguration/getActiveUserMyConfiguration').pipe(
        map(data => data));
  }


  updateActiveUserMyConfiguration(myConfiguration: PVSelfMyConfiguration): Observable<Boolean> {
    return this.httpClient.post<Boolean>(
      environment.API_URL + '/api/pvself/myconfiguration/updateActiveUserMyConfiguration', myConfiguration).pipe(
        map(data => data));
  }

  getActiveUserMyConfigurationContracts(ConsumptionRateId: number): Observable<PVSelfConsumptionContract[]> {
    return this.httpClient.get<PVSelfConsumptionContract[]>(
      environment.API_URL + '/api/pvself/myconfiguration/getActiveUserMyConfigurationContracts?ConsumptionRateId=' + ConsumptionRateId).pipe(
        map(data => data));
  }

  getEntityMyConfiguration(EntityId: number): Observable<PVSelfMyConfiguration> {
    return this.httpClient.get<PVSelfMyConfiguration>(
      environment.API_URL + '/api/pvself/myconfiguration/getEntityMyConfiguration?EntityId=' + EntityId).pipe(
        map(data => data));
  }

  getEntityMyConfigurationContracts(ConsumptionRateId: number, EntityId: number): Observable<PVSelfConsumptionContract[]> {
    return this.httpClient.get<PVSelfConsumptionContract[]>(
      environment.API_URL + '/api/pvself/myconfiguration/getEntityMyConfigurationContracts?ConsumptionRateId=' + ConsumptionRateId + '&EntityId=' + EntityId).pipe(
        map(data => data));
  }


}



