import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SourceMeteorologicalData, MeteorologicalData } from '../models/meteorologicalData.model';

@Injectable({
  providedIn: 'root'
})
export class MeteorologicalDataService {

  constructor(private httpClient: HttpClient) {

  }

  getSourcesByTypeId(TypeId: number): Observable<SourceMeteorologicalData[]> {
    return this.httpClient.get<SourceMeteorologicalData[]>(environment.API_URL + '/api/Admin/meteorologicaldata/GetSourcesByTypeId?TypeId=' + TypeId).pipe(
      map(data => data));
  }


  get(TypeId: number, SourceId: number, latitude: number, longitude: number): Observable<MeteorologicalData> {
    return this.httpClient.get<MeteorologicalData>(environment.API_URL +
      '/api/Admin/meteorologicaldata/GetByTypeSourceLatLon?TypeId=' + TypeId + '&SourceId=' + SourceId + '&latitude=' + latitude + '&longitude=' + longitude).pipe(
        map(data => data));
  }
}
