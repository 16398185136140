// Se definen los elementtypes de la plataforma.
// Estos valores no deben cambiarse NUNCA, puesto que se correponden con los valores de la BBDD y
// con los asignados en el backend
export enum ElementType {
    Entity = 1,
    Plant = 2,
    Installation = 3,
    PhotovoltaicPanel = 4,
    Inverter = 5,
    Meter = 6
}

export enum PPlusMeterType
{
    GenerationBT = 1,
    GenerationMT = 2,
    Auxiliary = 3,
    Border = 4,
    Consumption = 5
}

export enum PPlusChartType {
  Area = 1,
  Bar = 2
}

export enum PPlusElectricPhase {
  R = 1,
  S = 2,
  T = 3
}

export enum PPlusEnergyDirection
{
    Generation = 1,
    Consumption = 2
}
