import { Component, OnInit } from '@angular/core';
import { PVSelfCalculation } from '../../models/PvSelfCalculation.model';
import { PVSelfReportsService } from '../../services/pvself-reports.service';
import { PVSelfCalculationService } from '../../services/pvself-calculation.service';

@Component({
  selector: 'pplus-pvself-report',
  templateUrl: './pvself-report.component.html',
  styleUrls: ['./pvself-report.component.scss']
})
export class PVSelfReportComponent implements OnInit {

  _calculation: PVSelfCalculation;

  constructor(private reportsService: PVSelfReportsService,
    private calculationService: PVSelfCalculationService) { }

  ngOnInit() {
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
  }



  generarInforme() {

    let code: string;
    const d: Date = new Date;
    code = this.getFormattedDate(d);

    const calculation: PVSelfCalculation = this.calculationService.get();
    this.reportsService.generateResultsReport(calculation, code)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = code + '.docx';
        link.click();
      });
  }




  getFormattedDate(date) {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    let hour = (date.getHours()).toString();
    hour = hour.length > 1 ? hour : '0' + hour;

    let minute = (date.getMinutes()).toString();
    minute = minute.length > 1 ? minute : '0' + minute;

    let second = (date.getSeconds()).toString();
    second = second.length > 1 ? second : '0' + second;

    return year + month + day + hour + minute + second;
  }

}
