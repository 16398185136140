import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IEntityAppGroup } from 'projects/pplus/src/lib/interfaces/interfaces';
import { APP_GROUP_COL_DEFS } from './app-group.model';

@Injectable({
  providedIn: 'root'
})
export class AppGroupService {

  private apiAppGroup = environment.API_URL + '/api/application/applicationgroup';
  private apiAssignApps = this.apiAppGroup + '/assignapplications';
  private apiAssignEntities = environment.API_URL + '/api/application/assignentitiesToApplicationGroup';

  constructor(private http: HttpClient) {}

  getAllAppGroupsInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const body = {
      startRow,
      endRow,
      sortModel,
      filterModel
    };
    const url = `${this.apiAppGroup}/get-appGroups`;
    return this.http
      .post(url, body)
      .pipe(map((response) => {
        return <any>response;
      }));
  }

  getAllAppGroups(): Observable<IEntityAppGroup[]> {
    return this.http.get(this.apiAppGroup).pipe(
      map(response => <IEntityAppGroup[]>response),
      catchError(() => of([]))
    );
  }

  getAppGroupsList(): Observable<IEntityAppGroup[]> {
    return this.http.get(this.apiAppGroup + '/list').pipe(
      map(response => <IEntityAppGroup[]>response),
      catchError(() => of([]))
    );
  }

  getAppGroup(id: number): Observable<IEntityAppGroup> {
    return this.http.get(`${this.apiAppGroup}/${id}`).pipe(
      map(response => <IEntityAppGroup>response),
      catchError(() => of(null))
    );
  }

  createAppGroup(data: IEntityAppGroup): Observable<IEntityAppGroup> {
    return this.http.post(this.apiAppGroup, data, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? <IEntityAppGroup>response.body : null),
      catchError(() => of(null))
    );
  }

  updateAppGroup(id: number, data: IEntityAppGroup): Observable<boolean> {
    return this.http.put(`${this.apiAppGroup}/${id}`, data, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  deleteAppGroup(id: number): Observable<boolean> {
    return this.http.delete(`${this.apiAppGroup}/${id}`, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  assignApps(applicationGroupId: number, applicationIds: number[]): Observable<boolean> {
    if (applicationIds && applicationIds.length >= 0) {
      return this.http.post(this.apiAssignApps, {applicationGroupId, applicationIds}, {observe: 'response'}).pipe(
        map(response => response.status === 200 || response.status === 204),
        catchError(() => of(false))
      );
    } else return of(true);
  }

  assignEntities(applicationGroupId: number, entityIds: number[]): Observable<boolean> {
    if (entityIds && entityIds.length >= 0) {
      return this.http.post(this.apiAssignEntities, {applicationGroupId, entityIds}, {observe: 'response'}).pipe(
        map(response => response.status === 200 || response.status === 204),
        catchError(() => of(false))
      );
    } else return of(true);
  }

  getAppGroupsGeneralFilter(
    value: string, startRow: number, endRow: number, sortModel: any, filterModel: any
  ): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of APP_GROUP_COL_DEFS) columnList.push(colDef.field);
    const body = {
      startRow,
      endRow,
      sortModel,
      filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel
      },
      columnList,
    };
    const url = `${this.apiAppGroup}/get-appGroups`;
    return this.http.post(url, body).pipe(map((response) => {
      return <any>response;
    }));
  }

}
