import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { WeatherStationService } from '../weather-station.service';
import { WeatherStation, WeatherForecast } from '../weather-station.models';
import { MatTableDataSource } from '@angular/material';
import * as moment from 'moment';

@Component({
  selector: 'app-weather-station-forecast',
  templateUrl: './weather-station-forecast.component.html',
  styleUrls: ['./weather-station-forecast.component.scss']
})
export class WeatherStationForecastComponent implements OnInit, OnDestroy {

  private weatherStationSubscription: Subscription;

  // GRIDS
  dataSource = new MatTableDataSource<WeatherForecast>([]);
  columnDefinitions = ['day', 'icon', 'max', 'min', 'wind', 'clouds'];

  constructor(
    private weatherStationService: WeatherStationService) {
  }

  ngOnInit() {
    this.weatherStationService.weatherForecast.subscribe( (results: WeatherForecast[] ) => {
      if (results) {
        this.dataSource.data = results.map(item => {
          item.timestamp = moment(item.timestamp).format('dddd');
          return item;
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.weatherStationSubscription.unsubscribe();
  }


}
