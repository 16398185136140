import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {chartColors} from '../../selfview.graph.colors';
import * as XLSX from 'xlsx';
import {GlobalType, IDatoHorario, IPeriod, ISelfViewData, ISelfViewDataEconomico, ISelfViewDto} from '../../models/selfview.model';
import {TranslateService} from '@ngx-translate/core';
import {SelfViewService} from '../../services/selfview-general.service';
import { SelfviewGeneralTablesService } from '../../services/selfview-general-tables.service';

@Component({
  selector: 'selfview-main',
  templateUrl: './selfview-main.component.html',
  styleUrls: ['./selfview-main.component.scss']
})
export class FirstCardComponent implements OnInit {
  @ViewChild('table', {static: false, read: ElementRef}) table: ElementRef;
  @Output() getPeriodCompare = new EventEmitter<any>();
  @Output() updateType = new EventEmitter<boolean>();

  echartsInstance: any;
  type: boolean;
  comparePeriod = false;
  flipped: boolean;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  typeDataSource = [
    {
      'id': 1,
      'icon': 'battery_charging_full',
      'viewValue': 'Calculado'
    },
    {
      'id': 4,
      'icon': 'battery_charging_full',
      'viewValue': 'Contador'
    },
    {
      'id': 3,
      'icon': 'battery_charging_full',
      'viewValue': 'Equipo Iny0'
    },
    {
      'id': 2,
      'icon': 'battery_charging_full',
      'viewValue': 'Inversor'
    },
    {
      'id': 5,
      'icon': 'battery_charging_full',
      'viewValue': 'Consumida Red'
    }
  ];

  serieData = {
    'consumoTotal': {
      'value': 0,
      'idType': 1,
      'compareValue': null
    },
    'consumidaRed': {
      'value': 0,
      'idType': 1,
      'compareValue': null
    },
    'autoConsumida': {
      'value': 0,
      'idType': 1,
      'compareValue': null
    },
    'excedentaria': {
      'value': 0,
      'idType': 1,
      'compareValue': null
    },
    'netaGenerada': {
      'value': 0,
      'idType': 1,
      'compareValue': null
    }
  };
  seriesBarChartComparison = {
    'Consumo P1': true,
    'Consumo P2': true,
    'Consumo P3': true,
    'Consumo P4': true,
    'Consumo P5': true,
    'Consumo P6': true,
    'Consumo Red P1': true,
    'Consumo Red P2': true,
    'Consumo Red P3': true,
    'Consumo Red P4': true,
    'Consumo Red P5': true,
    'Consumo Red P6': true,
    'Autoconsumo P1': true,
    'Autoconsumo P2': true,
    'Autoconsumo P3': true,
    'Autoconsumo P4': true,
    'Autoconsumo P5': true,
    'Autoconsumo P6': true,
    'Excedente P1': true,
    'Excedente P2': true,
    'Excedente P3': true,
    'Excedente P4': true,
    'Excedente P5': true,
    'Excedente P6': true,
    'Neta Generada P1': true,
    'Neta Generada P2': true,
    'Neta Generada P3': true,
    'Neta Generada P4': true,
    'Neta Generada P5': true,
    'Neta Generada P6': true,
    'Consumo P1*': true,
    'Consumo P2*': true,
    'Consumo P3*': true,
    'Consumo P4*': true,
    'Consumo P5*': true,
    'Consumo P6*': true,
    'Consumo Red P1*': true,
    'Consumo Red P2*': true,
    'Consumo Red P3*': true,
    'Consumo Red P4*': true,
    'Consumo Red P5*': true,
    'Consumo Red P6*': true,
    'Autoconsumo P1*': true,
    'Autoconsumo P2*': true,
    'Autoconsumo P3*': true,
    'Autoconsumo P4*': true,
    'Autoconsumo P5*': true,
    'Autoconsumo P6*': true,
    'Excedente P1*': true,
    'Excedente P2*': true,
    'Excedente P3*': true,
    'Excedente P4*': true,
    'Excedente P5*': true,
    'Excedente P6*': true,
    'Neta Generada P1*': true,
    'Neta Generada P2*': true,
    'Neta Generada P3*': true,
    'Neta Generada P4*': true,
    'Neta Generada P5*': true,
    'Neta Generada P6*': true
  };
  seriesLine = {
    'Consumo Total': true, 'Consumida Red': true, 'Auto Consumida': true, 'Excedente': true, 'Neta Generada': true,
    'Consumo Total*': true, 'Consumida Red*': true, 'Auto Consumida*': true, 'Excedente*': true, 'Neta Generada*': true
  };
  serieEconomicData = {
    'Factura': {
      'value': 0,
      'compareValue': null
    },
    'AhorroAutoconsumo': {
      'value': 0,
      'compareValue': null
    },
    'Compensacion': {
      'value': 0,
      'compareValue': null
    },
    'SinCompensacion': {
      'value': 0,
      'compareValue': null
    },
    'Compensable': {
      'value': 0,
      'compareValue': null
    }
  };

  datagraf;
  datagraf2;

  dataEs2 = ['Cobertura', 'Aprovechamiento'];
  dataEn2 = ['Coverage', 'Exploitation'];
  dataEs = ['Consumo Total', 'Consumida Red', 'Auto Consumida', 'Excedentaria', 'Neta Generada'];
  dataEn = ['Total Consumption', 'Network Consumed', 'Auto Consumed', 'Excedentary', 'Net Generated'];

  globalType: GlobalType;
  selfViewOptions: any;
  areaChartOptions: any;
  barChartOptions: any;
  economicOptions: any;

  graphicType: any;

  initOpts = {
    renderer: 'svg',
    width: 'auto',
    height: 'auto'
  };
  compareMode: boolean;
  consumoTotal = [];
  consumidaRed = [];
  autoConsumida = [];
  excedentaria = [];
  netaGenerada = [];

  consumoTotalComparison = [];
  consumidaRedComparison = [];
  autoConsumidaComparison = [];
  excedentariaComparison = [];
  netaGeneradaComparison = [];

  importeConsumo = [];
  importeAhorro = [];
  importeConsumoCompare = [];
  importeAhorroCompare = [];

  selfViewDataPower: ISelfViewData;
  selfViewDataEconomic: ISelfViewDataEconomico;

  selfViewDataPowerComparison: ISelfViewData;
  selfViewDataEconomicComparison: ISelfViewDataEconomico;

  periods: IPeriod[] = [];

  countOptions = {
    duration: 5,
    separator: ' ',
    suffix: 'kWh',
  };

  countOptionsEconomic = {
    duration: 4,
    decimalPlaces: 2,
    separator: ' ',
  };

  serieState: {};

  leyends: any;
  dataCompare: any;

  normalData: any;
  comparisonData: any;

  constructor(private translate: TranslateService, private selfviewService: SelfViewService,
    private tableService: SelfviewGeneralTablesService) {
    this.flipped = true;
    this.type = false; // false: ECONOMIC, true: POWER
  }

  ngOnInit() {
    this.selfviewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;

      // Aquí se cambiara, el tipo desde el global
      this.selfviewService.dataNormal.subscribe((data: ISelfViewDto) => {
        this.normalData = data;
        this.showChartForGlobalType();
      });

      this.selfviewService.dataComparison.subscribe((data: ISelfViewDto) => {
        if (data) {
          this.comparePeriod = true;
        }
        this.comparisonData = data;
        this.showChartComparisonForGlobalType();
      });

    });


    this.translateAll();
    this.leyends = [
      {
        name: this.datagraf[0],
        p: [
          chartColors.periods.consumoP1,
          chartColors.periods.consumoP2,
          chartColors.periods.consumoP3,
          chartColors.periods.consumoP4,
          chartColors.periods.consumoP5,
          chartColors.periods.consumoP6
        ]
      },
      {
        name: this.datagraf[1],
        p: [
          chartColors.periods.consumo_redP1,
          chartColors.periods.consumo_redP2,
          chartColors.periods.consumo_redP3,
          chartColors.periods.consumo_redP4,
          chartColors.periods.consumo_redP5,
          chartColors.periods.consumo_redP6,
        ]
      },
      {
        name: this.datagraf[2],
        p: [
          chartColors.periods.autoconsumoP1,
          chartColors.periods.autoconsumoP2,
          chartColors.periods.autoconsumoP3,
          chartColors.periods.autoconsumoP4,
          chartColors.periods.autoconsumoP5,
          chartColors.periods.autoconsumoP6
        ]
      },
      {
        name: this.datagraf[3],
        p: [
          chartColors.periods.excedenteP1,
          chartColors.periods.excedenteP2,
          chartColors.periods.excedenteP3,
          chartColors.periods.excedenteP4,
          chartColors.periods.excedenteP5,
          chartColors.periods.excedenteP6
        ]
      },
      {
        name: this.datagraf[4],
        p: [
          chartColors.periods.netaGeneradaP1,
          chartColors.periods.netaGeneradaP2,
          chartColors.periods.netaGeneradaP3,
          chartColors.periods.netaGeneradaP4,
          chartColors.periods.netaGeneradaP5,
          chartColors.periods.netaGeneradaP6
        ]
      }
    ];
  }

  initiatePowerChartBarra() {
    this.selfViewOptions = null;
    this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};

    const data = this.selfViewDataPower.datosAcumulados2;

    const consumoP1 = data.series.consumo.filter(p => p.period === 'P1')[0].data;
    const consumoP2 = data.series.consumo.filter(p => p.period === 'P2')[0].data;
    const consumoP3 = data.series.consumo.filter(p => p.period === 'P3')[0].data;
    const consumoP4 = data.series.consumo.filter(p => p.period === 'P4')[0].data;
    const consumoP5 = data.series.consumo.filter(p => p.period === 'P5')[0].data;
    const consumoP6 = data.series.consumo.filter(p => p.period === 'P6')[0].data;


    // Consumida Red will be shown upside down
    const consumo_redP1 = data.series.consumo_red.filter(p => p.period === 'P1')[0].data.map(item => -item);
    const consumo_redP2 = data.series.consumo_red.filter(p => p.period === 'P2')[0].data.map(item => -item);
    const consumo_redP3 = data.series.consumo_red.filter(p => p.period === 'P3')[0].data.map(item => -item);
    const consumo_redP4 = data.series.consumo_red.filter(p => p.period === 'P4')[0].data.map(item => -item);
    const consumo_redP5 = data.series.consumo_red.filter(p => p.period === 'P5')[0].data.map(item => -item);
    const consumo_redP6 = data.series.consumo_red.filter(p => p.period === 'P6')[0].data.map(item => -item);

    const autoconsumoP1 = data.series.autoconsumo.filter(p => p.period === 'P1')[0].data;
    const autoconsumoP2 = data.series.autoconsumo.filter(p => p.period === 'P2')[0].data;
    const autoconsumoP3 = data.series.autoconsumo.filter(p => p.period === 'P3')[0].data;
    const autoconsumoP4 = data.series.autoconsumo.filter(p => p.period === 'P4')[0].data;
    const autoconsumoP5 = data.series.autoconsumo.filter(p => p.period === 'P5')[0].data;
    const autoconsumoP6 = data.series.autoconsumo.filter(p => p.period === 'P6')[0].data;

    const excedenteP1 = data.series.excedente.filter(p => p.period === 'P1')[0].data;
    const excedenteP2 = data.series.excedente.filter(p => p.period === 'P2')[0].data;
    const excedenteP3 = data.series.excedente.filter(p => p.period === 'P3')[0].data;
    const excedenteP4 = data.series.excedente.filter(p => p.period === 'P4')[0].data;
    const excedenteP5 = data.series.excedente.filter(p => p.period === 'P5')[0].data;
    const excedenteP6 = data.series.excedente.filter(p => p.period === 'P6')[0].data;

    const generadaP1 = data.series.generacion.filter(p => p.period === 'P1')[0].data;
    const generadaP2 = data.series.generacion.filter(p => p.period === 'P2')[0].data;
    const generadaP3 = data.series.generacion.filter(p => p.period === 'P3')[0].data;
    const generadaP4 = data.series.generacion.filter(p => p.period === 'P4')[0].data;
    const generadaP5 = data.series.generacion.filter(p => p.period === 'P5')[0].data;
    const generadaP6 = data.series.generacion.filter(p => p.period === 'P6')[0].data;

    this.barChartOptions = {
      legend: {
        show: false,
        bottom: '0%',
        type: 'scroll',
        data: this.seriesBarChartComparison,
        selected: {
          'Consumo P1': this.seriesBarChartComparison['Consumo P1'],
          'Consumo P2': this.seriesBarChartComparison['Consumo P1'],
          'Consumo P3': this.seriesBarChartComparison['Consumo P1'],
          'Consumo P4': this.seriesBarChartComparison['Consumo P1'],
          'Consumo P5': this.seriesBarChartComparison['Consumo P1'],
          'Consumo P6': this.seriesBarChartComparison['Consumo P1'],
          'Consumo Red P1': this.seriesBarChartComparison['Consumo Red P1'],
          'Consumo Red P2': this.seriesBarChartComparison['Consumo Red P1'],
          'Consumo Red P3': this.seriesBarChartComparison['Consumo Red P1'],
          'Consumo Red P4': this.seriesBarChartComparison['Consumo Red P1'],
          'Consumo Red P5': this.seriesBarChartComparison['Consumo Red P1'],
          'Consumo Red P6': this.seriesBarChartComparison['Consumo Red P1'],
          'Autoconsumo P1': this.seriesBarChartComparison['Autoconsumo P1'],
          'Autoconsumo P2': this.seriesBarChartComparison['Autoconsumo P1'],
          'Autoconsumo P3': this.seriesBarChartComparison['Autoconsumo P1'],
          'Autoconsumo P4': this.seriesBarChartComparison['Autoconsumo P1'],
          'Autoconsumo P5': this.seriesBarChartComparison['Autoconsumo P1'],
          'Autoconsumo P6': this.seriesBarChartComparison['Autoconsumo P1'],
          'Excedente P1': this.seriesBarChartComparison['Excedente P1'],
          'Excedente P2': this.seriesBarChartComparison['Excedente P1'],
          'Excedente P3': this.seriesBarChartComparison['Excedente P1'],
          'Excedente P4': this.seriesBarChartComparison['Excedente P1'],
          'Excedente P5': this.seriesBarChartComparison['Excedente P1'],
          'Excedente P6': this.seriesBarChartComparison['Excedente P1'],
          'Neta Generada P1': this.seriesBarChartComparison['Neta Generada P1'],
          'Neta Generada P2': this.seriesBarChartComparison['Neta Generada P1'],
          'Neta Generada P3': this.seriesBarChartComparison['Neta Generada P1'],
          'Neta Generada P4': this.seriesBarChartComparison['Neta Generada P1'],
          'Neta Generada P5': this.seriesBarChartComparison['Neta Generada P1'],
          'Neta Generada P6': this.seriesBarChartComparison['Neta Generada P1'],
          'Consumo P1*': this.seriesBarChartComparison['Consumo P1*'],
          'Consumo P2*': this.seriesBarChartComparison['Consumo P1*'],
          'Consumo P3*': this.seriesBarChartComparison['Consumo P1*'],
          'Consumo P4*': this.seriesBarChartComparison['Consumo P1*'],
          'Consumo P5*': this.seriesBarChartComparison['Consumo P1*'],
          'Consumo P6*': this.seriesBarChartComparison['Consumo P1*'],
          'Consumo Red P1*': this.seriesBarChartComparison['Consumo Red P1*'],
          'Consumo Red P2*': this.seriesBarChartComparison['Consumo Red P1*'],
          'Consumo Red P3*': this.seriesBarChartComparison['Consumo Red P1*'],
          'Consumo Red P4*': this.seriesBarChartComparison['Consumo Red P1*'],
          'Consumo Red P5*': this.seriesBarChartComparison['Consumo Red P1*'],
          'Consumo Red P6*': this.seriesBarChartComparison['Consumo Red P1*'],
          'Autoconsumo P1*': this.seriesBarChartComparison['Autoconsumo P1*'],
          'Autoconsumo P2*': this.seriesBarChartComparison['Autoconsumo P1*'],
          'Autoconsumo P3*': this.seriesBarChartComparison['Autoconsumo P1*'],
          'Autoconsumo P4*': this.seriesBarChartComparison['Autoconsumo P1*'],
          'Autoconsumo P5*': this.seriesBarChartComparison['Autoconsumo P1*'],
          'Autoconsumo P6*': this.seriesBarChartComparison['Autoconsumo P1*'],
          'Excedente P1*': this.seriesBarChartComparison['Excedente P1*'],
          'Excedente P2*': this.seriesBarChartComparison['Excedente P1*'],
          'Excedente P3*': this.seriesBarChartComparison['Excedente P1*'],
          'Excedente P4*': this.seriesBarChartComparison['Excedente P1*'],
          'Excedente P5*': this.seriesBarChartComparison['Excedente P1*'],
          'Excedente P6*': this.seriesBarChartComparison['Excedente P1*'],
          'Neta Generada P1*': this.seriesBarChartComparison['Neta Generada P1*'],
          'Neta Generada P2*': this.seriesBarChartComparison['Neta Generada P1*'],
          'Neta Generada P3*': this.seriesBarChartComparison['Neta Generada P1*'],
          'Neta Generada P4*': this.seriesBarChartComparison['Neta Generada P1*'],
          'Neta Generada P5*': this.seriesBarChartComparison['Neta Generada P1*'],
          'Neta Generada P6*': this.seriesBarChartComparison['Neta Generada P1*']
        }
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontSize: 10,
          width: 500
        },
        /*position: function (pos, params, dom, rect, size) {
          // tooltip will be fixed on the right if mouse hovering on the left,
          // and on the left if hovering on the right.
          const obj = {top: 60};
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
          return obj;
        },*/
        formatter: (params) => {
          let values = '';
          values += params.seriesName + ': ' + params.data + ' kWh <br>';

          return values;
        }
      },
      toolbox: {
        show: true,
        left: 'center',
        itemSize: 20,
        top: '0%',
        feature: {
          dataZoom: {
            show: true,
            yAxisIndex: 'none',
            title: {zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar'},
          },
          restore: {show: true, title: 'Anular Todo'},
          saveAsImage: {title: 'Descargar'},
        }
      },
      xAxis: [{
        type: 'category',
        data: data.fechas.map(fecha => {
          const time = new Date(fecha);
          return time.toLocaleDateString('es-ES'); // + ' \n ' + time.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'});
        })
      }],
      grid: {left: '8%', right: '5%', bottom: '25%'},
      yAxis: {
        name: 'kWh',
        gridIndex: 0,
        axisLabel: {
          inside: false,
          formatter: '{value}',
          fontSize: 10
        }
      },
      dataZoom: [{
        show: true,
        height: 40,
        type: 'slider',
        bottom: '0%',
        xAxisIndex: [0],
      },
        {
          type: 'inside'
        }
      ],
      // dataZoom: [
      //   {
      //     type: 'inside'
      //   }
      // ],
      series: [
        {
          name: 'Consumo P1',
          type: 'bar',
          data: consumoP1,
          itemStyle: {color: chartColors.periods.consumoP1},
          stack: 'consumo',
          xAxisIndex: 0
        },
        {
          name: 'Consumo P2',
          type: 'bar',
          data: consumoP2,
          itemStyle: {color: chartColors.periods.consumoP2},
          stack: 'consumo',
          xAxisIndex: 0
        },
        {
          name: 'Consumo P3',
          type: 'bar',
          data: consumoP3,
          itemStyle: {color: chartColors.periods.consumoP3},
          stack: 'consumo',
          xAxisIndex: 0
        },
        {
          name: 'Consumo P4',
          type: 'bar',
          data: consumoP4,
          itemStyle: {color: chartColors.periods.consumoP4},
          stack: 'consumo',
          xAxisIndex: 0
        },
        {
          name: 'Consumo P5',
          type: 'bar',
          data: consumoP5,
          itemStyle: {color: chartColors.periods.consumoP5},
          stack: 'consumo',
          xAxisIndex: 0
        },
        {
          name: 'Consumo P6',
          type: 'bar',
          data: consumoP6,
          itemStyle: {color: chartColors.periods.consumoP6},
          stack: 'consumo',
          xAxisIndex: 0
        },
        {
          name: 'Consumo Red P1',
          type: 'bar',
          data: consumo_redP1,
          itemStyle: {color: chartColors.periods.consumo_redP1},
          stack: 'consumo_red',
          xAxisIndex: 0
        },
        {
          name: 'Consumo Red P2',
          type: 'bar',
          data: consumo_redP2,
          itemStyle: {color: chartColors.periods.consumo_redP2},
          stack: 'consumo_red',
          xAxisIndex: 0
        },
        {
          name: 'Consumo Red P3',
          type: 'bar',
          data: consumo_redP3,
          itemStyle: {color: chartColors.periods.consumo_redP3},
          stack: 'consumo_red',
          xAxisIndex: 0
        },
        {
          name: 'Consumo Red P4',
          type: 'bar',
          data: consumo_redP4,
          itemStyle: {color: chartColors.periods.consumo_redP4},
          stack: 'consumo_red',
          xAxisIndex: 0
        },
        {
          name: 'Consumo Red P5',
          type: 'bar',
          data: consumo_redP5,
          itemStyle: {color: chartColors.periods.consumo_redP5},
          stack: 'consumo_red',
          xAxisIndex: 0
        },
        {
          name: 'Consumo Red P6',
          type: 'bar',
          data: consumo_redP6,
          itemStyle: {color: chartColors.periods.consumo_redP6},
          stack: 'consumo_red',
          xAxisIndex: 0
        },
        {
          name: 'Autoconsumo P1',
          type: 'bar',
          data: autoconsumoP1,
          itemStyle: {color: chartColors.periods.autoconsumoP1},
          stack: 'autoconsumo',
          xAxisIndex: 0
        },
        {
          name: 'Autoconsumo P2',
          type: 'bar',
          data: autoconsumoP2,
          itemStyle: {color: chartColors.periods.autoconsumoP2},
          stack: 'autoconsumo',
          xAxisIndex: 0
        },
        {
          name: 'Autoconsumo P3',
          type: 'bar',
          data: autoconsumoP3,
          itemStyle: {color: chartColors.periods.autoconsumoP3},
          stack: 'autoconsumo',
          xAxisIndex: 0
        },
        {
          name: 'Autoconsumo P4',
          type: 'bar',
          data: autoconsumoP4,
          itemStyle: {color: chartColors.periods.autoconsumoP4},
          stack: 'autoconsumo',
          xAxisIndex: 0
        },
        {
          name: 'Autoconsumo P5',
          type: 'bar',
          data: autoconsumoP5,
          itemStyle: {color: chartColors.periods.autoconsumoP5},
          stack: 'autoconsumo',
          xAxisIndex: 0
        },
        {
          name: 'Autoconsumo P6',
          type: 'bar',
          data: autoconsumoP6,
          itemStyle: {color: chartColors.periods.autoconsumoP6},
          stack: 'autoconsumo',
          xAxisIndex: 0
        },
        {
          name: 'Excedente P1',
          type: 'bar',
          data: excedenteP1,
          itemStyle: {color: chartColors.periods.excedenteP1},
          stack: 'excedente',
          xAxisIndex: 0
        },
        {
          name: 'Excedente P2',
          type: 'bar',
          data: excedenteP2,
          itemStyle: {color: chartColors.periods.excedenteP2},
          stack: 'excedente',
          xAxisIndex: 0
        },
        {
          name: 'Excedente P3',
          type: 'bar',
          data: excedenteP3,
          itemStyle: {color: chartColors.periods.excedenteP3},
          stack: 'excedente',
          xAxisIndex: 0
        },
        {
          name: 'Excedente P4',
          type: 'bar',
          data: excedenteP4,
          itemStyle: {color: chartColors.periods.excedenteP4},
          stack: 'excedente',
          xAxisIndex: 0
        },
        {
          name: 'Excedente P5',
          type: 'bar',
          data: excedenteP5,
          itemStyle: {color: chartColors.periods.excedenteP5},
          stack: 'excedente',
          xAxisIndex: 0
        },
        {
          name: 'Excedente P6',
          type: 'bar',
          data: excedenteP6,
          itemStyle: {color: chartColors.periods.excedenteP6},
          stack: 'excedente',
          xAxisIndex: 0
        },
        {
          name: 'Neta Generada P1',
          type: 'bar',
          data: generadaP1,
          itemStyle: {color: chartColors.periods.netaGeneradaP1},
          stack: 'generacion',
          xAxisIndex: 0
        },
        {
          name: 'Neta Generada P2',
          type: 'bar',
          data: generadaP2,
          itemStyle: {color: chartColors.periods.netaGeneradaP2},
          stack: 'generacion',
          xAxisIndex: 0
        },
        {
          name: 'Neta Generada P3',
          type: 'bar',
          data: generadaP3,
          itemStyle: {color: chartColors.periods.netaGeneradaP3},
          stack: 'generacion',
          xAxisIndex: 0
        },
        {
          name: 'Neta Generada P4',
          type: 'bar',
          data: generadaP4,
          itemStyle: {color: chartColors.periods.netaGeneradaP4},
          stack: 'generacion',
          xAxisIndex: 0
        },
        {
          name: 'Neta Generada P5',
          type: 'bar',
          data: generadaP5,
          itemStyle: {color: chartColors.periods.netaGeneradaP5},
          stack: 'generacion',
          xAxisIndex: 0
        },
        {
          name: 'Neta Generada P6',
          type: 'bar',
          data: generadaP6,
          itemStyle: {color: chartColors.periods.netaGeneradaP6},
          stack: 'generacion',
          xAxisIndex: 0
        },
      ]
    };


    this.selfViewOptions = this.barChartOptions;
    this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
  }

  initiatePowerChartBarraComparison() {

    this.initiatePowerChartBarra();

    const data = this.selfViewDataPowerComparison.datosAcumulados2;
    const consumoP1Comparison = data.series.consumo.filter(p => p.period === 'P1')[0].data;
    const consumoP2Comparison = data.series.consumo.filter(p => p.period === 'P2')[0].data;
    const consumoP3Comparison = data.series.consumo.filter(p => p.period === 'P3')[0].data;
    const consumoP4Comparison = data.series.consumo.filter(p => p.period === 'P4')[0].data;
    const consumoP5Comparison = data.series.consumo.filter(p => p.period === 'P5')[0].data;
    const consumoP6Comparison = data.series.consumo.filter(p => p.period === 'P6')[0].data;

    const consumo_redP1Comparison = data.series.consumo_red.filter(p => p.period === 'P1')[0].data.map(item => -item);
    const consumo_redP2Comparison = data.series.consumo_red.filter(p => p.period === 'P2')[0].data.map(item => -item);
    const consumo_redP3Comparison = data.series.consumo_red.filter(p => p.period === 'P3')[0].data.map(item => -item);
    const consumo_redP4Comparison = data.series.consumo_red.filter(p => p.period === 'P4')[0].data.map(item => -item);
    const consumo_redP5Comparison = data.series.consumo_red.filter(p => p.period === 'P5')[0].data.map(item => -item);
    const consumo_redP6Comparison = data.series.consumo_red.filter(p => p.period === 'P6')[0].data.map(item => -item);

    const autoconsumoP1Comparison = data.series.autoconsumo.filter(p => p.period === 'P1')[0].data;
    const autoconsumoP2Comparison = data.series.autoconsumo.filter(p => p.period === 'P2')[0].data;
    const autoconsumoP3Comparison = data.series.autoconsumo.filter(p => p.period === 'P3')[0].data;
    const autoconsumoP4Comparison = data.series.autoconsumo.filter(p => p.period === 'P4')[0].data;
    const autoconsumoP5Comparison = data.series.autoconsumo.filter(p => p.period === 'P5')[0].data;
    const autoconsumoP6Comparison = data.series.autoconsumo.filter(p => p.period === 'P6')[0].data;

    const excedenteP1Comparison = data.series.excedente.filter(p => p.period === 'P1')[0].data;
    const excedenteP2Comparison = data.series.excedente.filter(p => p.period === 'P2')[0].data;
    const excedenteP3Comparison = data.series.excedente.filter(p => p.period === 'P3')[0].data;
    const excedenteP4Comparison = data.series.excedente.filter(p => p.period === 'P4')[0].data;
    const excedenteP5Comparison = data.series.excedente.filter(p => p.period === 'P5')[0].data;
    const excedenteP6Comparison = data.series.excedente.filter(p => p.period === 'P6')[0].data;

    const generadaP1Comparison = data.series.generacion.filter(p => p.period === 'P1')[0].data;
    const generadaP2Comparison = data.series.generacion.filter(p => p.period === 'P2')[0].data;
    const generadaP3Comparison = data.series.generacion.filter(p => p.period === 'P3')[0].data;
    const generadaP4Comparison = data.series.generacion.filter(p => p.period === 'P4')[0].data;
    const generadaP5Comparison = data.series.generacion.filter(p => p.period === 'P5')[0].data;
    const generadaP6Comparison = data.series.generacion.filter(p => p.period === 'P6')[0].data;

    const axisIndex = 0;
    const comparisonSeries = [
      {
        name: 'Consumo P1*',
        type: 'bar',
        data: consumoP1Comparison,
        itemStyle: {color: chartColors.comparison.consumoP1Comparison},
        stack: 'consumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo P2*',
        type: 'bar',
        data: consumoP2Comparison,
        itemStyle: {color: chartColors.comparison.consumoP2Comparison},
        stack: 'consumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo P3*',
        type: 'bar',
        data: consumoP3Comparison,
        itemStyle: {color: chartColors.comparison.consumoP3Comparison},
        stack: 'consumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo P4*',
        type: 'bar',
        data: consumoP4Comparison,
        itemStyle: {color: chartColors.comparison.consumoP4Comparison},
        stack: 'consumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo P5*',
        type: 'bar',
        data: consumoP5Comparison,
        itemStyle: {color: chartColors.comparison.consumoP5Comparison},
        stack: 'consumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo P6*',
        type: 'bar',
        data: consumoP6Comparison,
        itemStyle: {color: chartColors.comparison.consumoP6Comparison},
        stack: 'consumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo Red P1*',
        type: 'bar',
        data: consumo_redP1Comparison,
        itemStyle: {color: chartColors.comparison.consumo_redP1Comparison},
        stack: 'consumo_redComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo Red P2*',
        type: 'bar',
        data: consumo_redP2Comparison,
        itemStyle: {color: chartColors.comparison.consumo_redP2Comparison},
        stack: 'consumo_redComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo Red P3*',
        type: 'bar',
        data: consumo_redP3Comparison,
        itemStyle: {color: chartColors.comparison.consumo_redP3Comparison},
        stack: 'consumo_redComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo Red P4*',
        type: 'bar',
        data: consumo_redP4Comparison,
        itemStyle: {color: chartColors.comparison.consumo_redP4Comparison},
        stack: 'consumo_redComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo Red P5*',
        type: 'bar',
        data: consumo_redP5Comparison,
        itemStyle: {color: chartColors.comparison.consumo_redP5Comparison},
        stack: 'consumo_redComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Consumo Red P6*',
        type: 'bar',
        data: consumo_redP6Comparison,
        itemStyle: {color: chartColors.comparison.consumo_redP6Comparison},
        stack: 'consumo_redComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Autoconsumo P1*',
        type: 'bar',
        data: autoconsumoP1Comparison,
        itemStyle: {color: chartColors.comparison.autoconsumoP1Comparison},
        stack: 'autoconsumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Autoconsumo P2*',
        type: 'bar',
        data: autoconsumoP2Comparison,
        itemStyle: {color: chartColors.comparison.autoconsumoP2Comparison},
        stack: 'autoconsumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Autoconsumo P3*',
        type: 'bar',
        data: autoconsumoP3Comparison,
        itemStyle: {color: chartColors.comparison.autoconsumoP3Comparison},
        stack: 'autoconsumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Autoconsumo P4*',
        type: 'bar',
        data: autoconsumoP4Comparison,
        itemStyle: {color: chartColors.comparison.autoconsumoP4Comparison},
        stack: 'autoconsumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Autoconsumo P5*',
        type: 'bar',
        data: autoconsumoP5Comparison,
        itemStyle: {color: chartColors.comparison.autoconsumoP5Comparison},
        stack: 'autoconsumoComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Autoconsumo P6*',
        type: 'bar',
        data: autoconsumoP6Comparison,
        itemStyle: {color: chartColors.comparison.autoconsumoP6Comparison},
        stack: 'autoconsumo',
        xAxisIndex: axisIndex
      },
      {
        name: 'Excedente P1*',
        type: 'bar',
        data: excedenteP1Comparison,
        itemStyle: {color: chartColors.comparison.excedenteP1Comparison},
        stack: 'excedenteComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Excedente P2*',
        type: 'bar',
        data: excedenteP2Comparison,
        itemStyle: {color: chartColors.comparison.excedenteP2Comparison},
        stack: 'excedenteComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Excedente P3*',
        type: 'bar',
        data: excedenteP3Comparison,
        itemStyle: {color: chartColors.comparison.excedenteP3Comparison},
        stack: 'excedenteComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Excedente P4*',
        type: 'bar',
        data: excedenteP4Comparison,
        itemStyle: {color: chartColors.comparison.excedenteP4Comparison},
        stack: 'excedenteComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Excedente P5*',
        type: 'bar',
        data: excedenteP5Comparison,
        itemStyle: {color: chartColors.comparison.excedenteP5Comparison},
        stack: 'excedenteComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Excedente P6*',
        type: 'bar',
        data: excedenteP6Comparison,
        itemStyle: {color: chartColors.comparison.excedenteP6Comparison},
        stack: 'excedenteComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Neta Generada P1*',
        type: 'bar',
        data: generadaP1Comparison,
        itemStyle: {color: chartColors.comparison.netaGeneradaP1Comparison},
        stack: 'generacionComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Neta Generada P2*',
        type: 'bar',
        data: generadaP2Comparison,
        itemStyle: {color: chartColors.comparison.netaGeneradaP2Comparison},
        stack: 'generacionComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Neta Generada P3*',
        type: 'bar',
        data: generadaP3Comparison,
        itemStyle: {color: chartColors.comparison.netaGeneradaP3Comparison},
        stack: 'generacionComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Neta Generada P4*',
        type: 'bar',
        data: generadaP4Comparison,
        itemStyle: {color: chartColors.comparison.netaGeneradaP4Comparison},
        stack: 'generacionComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Neta Generada P5*',
        type: 'bar',
        data: generadaP5Comparison,
        itemStyle: {color: chartColors.comparison.netaGeneradaP5Comparison},
        stack: 'generacionComparison',
        xAxisIndex: axisIndex
      },
      {
        name: 'Neta Generada P6*',
        type: 'bar',
        data: generadaP6Comparison,
        itemStyle: {color: chartColors.comparison.netaGeneradaP6Comparison},
        stack: 'generacionComparison',
        xAxisIndex: axisIndex
      },
    ];

    if (this.selfViewOptions.series.length > 30) {
      this.selfViewOptions.series.splice(30, 31);
    }
    if (this.selfViewOptions.xAxis.length > 1) {
      this.selfViewOptions.xAxis.pop();
    }
    const newAxis = {
      type: 'category',
      position: 'bottom',
      boundaryGap: true,
      offset: 50,
      data: data.fechas.map(fecha => {
        const time = new Date(fecha);
        return time.toLocaleDateString('es-ES'); // + ' \n ' + time.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'});
      }),
      axisTick: {
        alignWithLabel: true, interval: 5
      },
      axisLine: {
        onZero: false,
        lineStyle: {
          color: '#327830'
        }
      }
    };

    this.selfViewOptions.xAxis.push(newAxis);
    this.selfViewOptions.dataZoom[0].xAxisIndex = [0, 1];

    const seriesTotal = this.selfViewOptions.series.concat(comparisonSeries);

    this.selfViewOptions.series = seriesTotal;

    this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
  }

  initiatePowerChartArea() {
    this.selfViewOptions = null;
    this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};

    this.graphicType = 'line';
    this.consumoTotal = [];
    this.consumidaRed = [];
    this.autoConsumida = [];
    this.excedentaria = [];
    this.netaGenerada = [];

    if (this.selfViewDataPower.datosHorarios.length > 0) {
      for (let i = 0; i < this.selfViewDataPower.datosHorarios.length; i++) {
        const data = this.selfViewDataPower.datosHorarios[i];
        this.netaGenerada.push([data.hora, data.generacion]);
        this.consumidaRed.push([data.hora, data.consumo_red]);
        this.autoConsumida.push([data.hora, data.autoconsumo]);
        this.excedentaria.push([data.hora, data.excedente]);
        this.consumoTotal.push([data.hora, data.consumo]);
      }

      this.areaChartOptions = {
        animation: true,
        title: {
          text: 'SelfView',
          show: false,
          left: 'center'
        },
        legend: {
          show: false,
          data: this.seriesLine,
          selected: {
            'Consumo Total': this.seriesLine['Consumo Total'],
            'Consumida Red': this.seriesLine['Consumida Red'],
            'Auto Consumida': this.seriesLine['Auto Consumida'],
            'Excedente': this.seriesLine['Excedente'],
            'Neta Generada': this.seriesLine['Neta Generada'],
            'Consumo Total*': this.seriesLine['Consumo Total*'],
            'Consumida Red*': this.seriesLine['Consumida Red*'],
            'Auto Consumida*': this.seriesLine['Auto Consumida*'],
            'Excedente*': this.seriesLine['Excedente*'],
            'Neta Generada*': this.seriesLine['Neta Generada*']
          }

        },
        tooltip: {
          show: true, // this.selfViewDataPower.tipo_grafico === 'bar',
          triggerOn: 'mousemove',
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }, /*
          formatter: params => {
            let values = '';
            for (const param of params) {
              const isDefined = typeof (param) === 'undefined';
              if (!isDefined) {
                values += param.seriesName + ': ' + param.dataNormal[1] + ' kWh <br>';
              }
            }
            return values;
          }*/
        },
        toolbox: {
          show: true,
          left: 'center',
          itemSize: 20,
          top: '0%',
          feature: {
            dataZoom: {
              show: true,
              yAxisIndex: true,
              title: {zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar'}
            },
            restore: {show: true, title: 'Anular Todo'},
            /*magicType: {
              show: true,
              type: ['line', 'bar', 'stack', 'tiled'],
              title: {line: 'Area', bar: 'Bar', stack: 'Stack', tiled: 'Tiled'}
            },*/
            saveAsImage: {title: 'Descargar'}
          }
        },
        xAxis: [{
          type: 'time',
          axisLabel: {
            fontSize: 10,
            formatter: (function (value) {
              const time = new Date(value);
              const resp = time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', {
                hour: '2-digit',
                minute: '2-digit'
              });
              return resp;
            })
          },
          splitLine: {
            show: false,
          }
        }],
        yAxis: {
          type: 'value',
          name: 'kWh',
          axisTick: {
            inside: false
          },
          splitLine: {
            show: false,
            lineStyle: {
              opacity: 0.2
            }
          },
          axisLabel: {
            inside: false,
            formatter: '{value}',
            fontSize: 10
          },
          z: 10
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '25%'
        },
        dataZoom: [{
          show: true,
          height: 40,
          type: 'slider',
          bottom: '0%',
          xAxisIndex: [0],
        },
          {
            type: 'inside'
          }
        ],
        series: [
          {
            name: 'Consumo Total',
            type: this.graphicType,
            smooth: false,
            symbol: 'none',
            symbolSize: 5,
            itemStyle: {
              normal: {
                color: chartColors.consumoTotalLine
              }
            },
            stack: 'a',
            areaStyle: {
              normal: {
                color: chartColors.consumoTotalArea,
                areaStyle: {type: 'default'}
              }
            },
            markArea: {
              label: {show: true, position: 'top'},
              itemStyle: {
                color: 'rgba(38,44,44,0.11)',
                borderColor: 'rgba(38,44,44,0.27)', borderWidth: 0.5,
              },
              data: []
            },
            data: this.consumoTotal
          },
          {
            name: 'Consumida Red',
            type: this.graphicType,
            smooth: true,
            stack: 'b',
            symbol: 'none',
            symbolSize: 5,
            sampling: 'average',
            itemStyle: {
              normal: {
                color: chartColors.consumidaRedLine
              }
            },
            areaStyle: {
              normal: {
                color: chartColors.consumidaRedArea
              }
            },
            markArea: {
              label: {show: true, position: 'top'},
              itemStyle: {
                color: 'rgba(38,44,44,0.11)',
                borderColor: 'rgba(38,44,44,0.27)',
                borderWidth: 0.5,
                opacity : 0,
              },
                data: []
            },
            data: this.consumidaRed
          },
          {
            name: 'Auto Consumida',
            type: this.graphicType,
            smooth: true,
            stack: 'c',
            symbol: 'none',
            symbolSize: 5,
            sampling: 'average',
            itemStyle: {
              normal: {
                color: chartColors.autoConsumidaLine
              }
            },
            areaStyle: {
              normal: {
                color: chartColors.autoConsumidaArea
              }
            },
            markArea: {
              label: {show: true, position: 'top'},
              silent: true,
              itemStyle: {
                color: 'rgba(38,44,44,0.11)',
                borderColor: 'rgba(38,44,44,0.27)',
                borderWidth: 0.5,
                opacity : 0,
              },
                data: []
            },
            data: this.autoConsumida
          },
          {
            name: 'Excedente',
            type: this.graphicType,
            smooth: true,
            stack: 'd',
            symbol: 'none',
            symbolSize: 5,
            sampling: 'average',
            itemStyle: {
              show: false,
              normal: {
                color: chartColors.excedentariaLine
              }
            },
            areaStyle: {
              normal: {
                color: chartColors.excedentariaArea
              }
            },
            markArea: {
              label: {show: true, position: 'top'},
              silent: true,
              itemStyle: {
                color: 'rgba(38,44,44,0.11)',
                borderColor: 'rgba(38,44,44,0.27)',
                borderWidth: 0.5,
                opacity : 0,
              },
                data: []
            },
            data: this.excedentaria
          },
          {
            name: 'Neta Generada',
            type: this.graphicType,
            smooth: true,
            stack: 'e',
            symbol: 'none',
            symbolSize: 5,
            sampling: 'average',
            itemStyle: {
              normal: {
                color: chartColors.netaGeneradaLine
              }
            },
            areaStyle: {
              normal: {
                color: chartColors.netaGeneradaArea
              }
            },
            markArea: {
              label: {show: true, position: 'top'},
              silent: true,
              itemStyle: {
                color: 'rgba(38,44,44,0.11)',
                borderColor: 'rgba(38,44,44,0.27)',
                borderWidth: 0.5,
                opacity : 0,
              },
                data: []
            },
            data: this.netaGenerada
          }
        ],
      };
      this.selfViewOptions = this.areaChartOptions;
    }
  }

  initiatePowerChartAreaComparison() {

    this.initiatePowerChartArea();

    this.graphicType = 'line';
    this.consumoTotalComparison = [];
    this.consumidaRedComparison = [];
    this.autoConsumidaComparison = [];
    this.excedentariaComparison = [];
    this.netaGeneradaComparison = [];

    if (this.selfViewDataPowerComparison.datosHorarios.length > 0) {
      for (let i = 0; i < this.selfViewDataPowerComparison.datosHorarios.length; i++) {
        const data = this.selfViewDataPowerComparison.datosHorarios[i];
        this.consumoTotalComparison.push([data.hora, data.consumo]);
        this.consumidaRedComparison.push([data.hora, data.consumo_red]);
        this.autoConsumidaComparison.push([data.hora, data.autoconsumo]);
        this.excedentariaComparison.push([data.hora, data.excedente]);
        this.netaGeneradaComparison.push([data.hora, data.generacion]);
      }

      const areaStyle = null;

      const series = [
        {

          name: 'Consumo Total*',
          type: this.graphicType,
          smooth: true,
          symbol: 'none',
          itemStyle: {
            normal: {
              color: chartColors.consumoTotalLine
            }
          },
          areaStyle: areaStyle,
          data: this.consumoTotalComparison,
          xAxisIndex: 1,
        },
        {
          name: 'Consumida Red*',
          type: this.graphicType,
          smooth: true,
          symbol: 'none',
          itemStyle: {
            normal: {
              color: chartColors.consumidaRedLine
            }
          },
          areaStyle: areaStyle,
          xAxisIndex: 1,
          data: this.consumidaRedComparison
        },
        {
          name: 'Auto Consumida*',
          type: this.graphicType,
          smooth: true,
          symbol: 'none',
          itemStyle: {
            normal: {
              color: chartColors.autoConsumidaLine
            }
          },
          areaStyle: areaStyle,
          xAxisIndex: 1,
          data: this.autoConsumidaComparison
        },
        {
          name: 'Excedente*',
          type: this.graphicType,
          smooth: true,
          symbol: 'none',
          itemStyle: {
            show: false,
            normal: {
              color: chartColors.excedentariaLine
            }
          },
          areaStyle: areaStyle,
          xAxisIndex: 1,
          data: this.excedentariaComparison
        },
        {
          name: 'Neta Generada*',
          type: this.graphicType,
          smooth: true,
          symbol: 'none',
          itemStyle: {
            normal: {
              color: chartColors.netaGeneradaLine
            }
          },
          areaStyle: areaStyle,
          xAxisIndex: 1,
          data: this.netaGeneradaComparison
        }
      ];


      const newAxis = {
        type: 'time',
        offset: 30,
        position: 'bottom',
        axisLabel: {
          fontSize: 10,
          formatter: (function (value) {
            const time = new Date(value);
            return time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'});
          })
        },
        splitLine: {
          show: false,
        }
      };

      if (this.selfViewOptions.xAxis.length > 1) {
        this.selfViewOptions.xAxis.pop();
      }
      this.selfViewOptions.xAxis.push(newAxis);


      if (this.selfViewOptions.series.length > 5) {
        this.selfViewOptions.series.splice(this.selfViewOptions.series.length / 2, this.selfViewOptions.series.length - 2);
      }


      this.selfViewOptions.series = this.selfViewOptions.series.concat(series);

      for (let i = 0; i < this.selfViewOptions.series.length; i++) {
        // this.selfViewOptions.seriesBarChartComparison[i].areaStyle = areaStyle;
      }
      this.selfViewOptions.tooltip.show = true;
      this.selfViewOptions.dataZoom[0].xAxisIndex = [0, 1];

      this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
      if (this.selfViewOptions.series[0]) {
        this.selfViewOptions.series[0].markArea.data = null;
      }
    }
  }

  initiateEconomicChart() {
    this.selfViewOptions.series = null;
    this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
    this.importeConsumo = [];
    this.importeAhorro = [];

    for (let i = 0; i < this.selfViewDataEconomic.datosHorarios.length; i++) {
      const data = this.selfViewDataEconomic.datosHorarios[i];
      const importeConsumo = Math.round(data.importe_consumo * 100) / 100;
      const importeAhorro = Math.round(data.importe_ahorro * 100) / 100;
      this.importeConsumo.push([data.hora, importeConsumo]);
      this.importeAhorro.push([data.hora, importeAhorro]);
    }

    this.economicOptions = {
      animation: true,
      title: {
        text: 'SelfView',
        show: false,
        left: 'center'
      },
      legend: {show: false},
      tooltip: {
        show: true,
        triggerOn: 'mousemove',
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        },
        /* formatter: params => {
           let values = '';
           for (const param of params) {
             const isDefined = typeof (param) === 'undefined';
             if (!isDefined) {
               values += param.seriesName + ': ' + param.dataNormal[1] + ' € <br>';
             }
           }
           return values;

         }*/
      },
      toolbox: {
        show: true,
        left: 'center',
        itemSize: 20,
        top: '0%',
        feature: {
          dataZoom: {
            show: true,
            yAxisIndex: true,
            title: {zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar'}
          },
          restore: {show: true, title: 'Anular Todo'},
          /*magicType: {
            show: true,
            type: ['line', 'bar', 'stack', 'tiled'],
            title: {line: 'Area', bar: 'Bar', stack: 'Stack', tiled: 'Tiled'}
          },*/
          saveAsImage: {title: 'Descargar'}
        }
      },
      xAxis: [{
        type: 'time',
        axisLabel: {
          fontSize: 10,
          formatter: (function (value) {
            const time = new Date(value);
            return time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'});
          })
        },
        splitLine: {
          show: false,
        }
      }],
      yAxis: {
        type: 'value',
        axisTick: {
          inside: false
        },
        splitLine: {
          show: false,
          lineStyle: {
            opacity: 0.2
          }
        },
        axisLabel: {
          inside: false,
          formatter: '{value} €',
          fontSize: 10
        },
        z: 10
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '25%'
      },
      dataZoom: [
        {
          show: true,
          height: 40,
          type: 'slider',
          bottom: '0%',
          xAxisIndex: [0],
        },
        {
          type: 'inside'
        }
      ],
      series: [
        {
          name: 'Importe Consumo',
          type: 'line',
          step: 'middle',
          symbol: 'none',
          itemStyle: {
            normal: {
              color: chartColors.importeConsumo
            }
          },
          data: this.importeConsumo
        },
        {
          name: 'Importe Ahorro',
          type: 'line',
          step: 'middle',
          symbol: 'none',
          itemStyle: {
            normal: {
              color: chartColors.importeAhorro
            }
          },
          data: this.importeAhorro
        }
      ]
    };

    this.selfViewOptions = this.economicOptions;
  }

  initiateEconomicChartComparison() {

    // We are cleaning the chart in case there is dataNormal
    this.selfViewOptions.series = null;
    this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
    this.initiateEconomicChart();

    this.importeConsumoCompare = [];
    this.importeAhorroCompare = [];
    for (let i = 0; i < this.selfViewDataEconomicComparison.datosHorarios.length; i++) {
      const data = this.selfViewDataEconomicComparison.datosHorarios[i];
      const importeConsumo = Math.round(data.importe_consumo * 100) / 100;
      const importeAhorro = Math.round(data.importe_ahorro * 100) / 100;
      this.importeConsumoCompare.push([data.hora, importeConsumo]);
      this.importeAhorroCompare.push([data.hora, importeAhorro]);
    }
    const xAxisIndex = 1;
    const seriesCompare = [
      {
        name: 'Importe Consumo *',
        xAxisIndex: xAxisIndex,
        type: 'line',
        step: 'middle',
        symbol: 'none',
        itemStyle: {
          normal: {
            color: chartColors.importeConsumo2
          }
        },
        data: this.importeConsumoCompare
      },
      {
        name: 'Importe Ahorro *',
        xAxisIndex: xAxisIndex,
        type: 'line',
        step: 'middle',
        symbol: 'none',
        itemStyle: {
          normal: {
            color: chartColors.importeAhorro2
          }
        },
        data: this.importeAhorroCompare
      }
    ];
    const newAxis = {
      type: 'time',
      offset: 30,
      position: 'bottom',
      axisLabel: {
        fontSize: 10,
        formatter: (function (value) {
          const time = new Date(value);
          return time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'});
        })
      },
      splitLine: {
        show: false,
      }
    };

    if (this.selfViewOptions.xAxis.length > 1) {
      this.selfViewOptions.xAxis.pop();
    }
    this.selfViewOptions.xAxis.push(newAxis);

    if (this.selfViewOptions.dataZoom) {
      this.selfViewOptions.dataZoom[0].xAxisIndex = [0, 1];
    }

    if (this.selfViewOptions.series.length > 2) {
      this.selfViewOptions.series.splice(this.selfViewOptions.series.length / 2, 2);
    }


    const totalSeries = this.selfViewOptions.series.concat(seriesCompare);
    this.selfViewOptions.series = totalSeries;
    this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};

  }

  exportToExcel() {
    let ws: XLSX.WorkSheet;
    ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Table.xlsx');
  }

  onChartRestore($event) {

  }

  onChartInit(ec) {
    this.echartsInstance = ec;
  }

  onChartDataZoom($event) {
    // console.log($event);
    /* let start: any;
     let end: any;
     start = $event.start;
     end = $event.end;

     // Is this draw zoom?
     const hasBatch = typeof ($event.batch) !== 'undefined';
     let isDraw = false;
     let isMouse = false;
     if (hasBatch) {
       console.log('HASBATCH');
       const hasEndValue = typeof ($event.batch[0].endValue) !== 'undefined';
       const hasStartValue = typeof ($event.batch[0].startValue) !== 'undefined';
       if (hasEndValue || hasStartValue) {
         console.log('ISDRAW');
         isDraw = true;
         isMouse = false;
       } else {
         isDraw = false;
         isMouse = true;
       }

     }

     let startValue, endValue: any;

     if (isDraw) {
       startValue = $event.batch[0].startValue;
       endValue = $event.batch[0].endValue;
     } else if (isMouse) {
       start = $event.batch[0].start;
       end = $event.batch[0].end;
       const zoomStart = Math.round(this.selfViewOptions.seriesBarChartComparison[0].dataNormal.length * (start / 100));
       const zoomEnd = Math.round(this.selfViewOptions.seriesBarChartComparison[0].dataNormal.length * (end / 100)) - 1;

       const startTime = this.selfViewOptions.seriesBarChartComparison[0].dataNormal[zoomStart];
       const endTime = this.selfViewOptions.seriesBarChartComparison[0].dataNormal[zoomEnd];

       startValue = startTime ? Date.parse(startTime.toString().split(',')[0]) : this.selfViewOptions.seriesBarChartComparison[0].dataNormal[0][0];
       endValue = endTime ? Date.parse(endTime.toString().split(',')[0]) : this.selfViewOptions.seriesBarChartComparison[0].dataNormal[3][0];
     } else {
       const zoomStart = Math.round(this.selfViewOptions.seriesBarChartComparison[0].dataNormal.length * (start / 100));
       const zoomEnd = Math.round(this.selfViewOptions.seriesBarChartComparison[0].dataNormal.length * (end / 100)) - 1;

       const startTime = this.selfViewOptions.seriesBarChartComparison[0].dataNormal[zoomStart];
       const endTime = this.selfViewOptions.seriesBarChartComparison[0].dataNormal[zoomEnd];

       startValue = startTime ? Date.parse(startTime.toString().split(',')[0]) : this.selfViewOptions.seriesBarChartComparison[0].dataNormal[0][0];
       endValue = endTime ? Date.parse(endTime.toString().split(',')[0]) : this.selfViewOptions.seriesBarChartComparison[0].dataNormal[3][0];
     }

     const theDifference = Number(endValue) - Number(startValue);

     if (this.globalType !== GlobalType.Economic) {
       if (theDifference < 1.728e+8) {
         this.selfViewOptions.tooltip.show = true;
         this.selfViewOptions.seriesBarChartComparison[Series.consumoTotal].itemStyle.normal.color = chartColors.consumoTotalBar;
         this.selfViewOptions.seriesBarChartComparison[Series.consumidaRed].itemStyle.normal.color = chartColors.consumidaRedBar;
         this.selfViewOptions.seriesBarChartComparison[Series.autoConsumida].itemStyle.normal.color = chartColors.autoConsumidaBar;
         this.selfViewOptions.seriesBarChartComparison[Series.excedentaria].itemStyle.normal.color = chartColors.excedentariaBar;
         this.selfViewOptions.seriesBarChartComparison[Series.netaGenerada].itemStyle.normal.color = chartColors.netaGeneradaBar;
         this.selfViewOptions.xAxis.axisLabel.formatter = (value) => {
           const time = new Date(value);
           return time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'});
         };
       } else {
         this.selfViewOptions.tooltip.show = false;
         this.selfViewOptions.seriesBarChartComparison[Series.consumoTotal].itemStyle.normal.color = chartColors.consumoTotalLine;
         this.selfViewOptions.seriesBarChartComparison[Series.consumidaRed].itemStyle.normal.color = chartColors.consumidaRedLine;
         this.selfViewOptions.seriesBarChartComparison[Series.autoConsumida].itemStyle.normal.color = chartColors.autoConsumidaLine;
         this.selfViewOptions.seriesBarChartComparison[Series.excedentaria].itemStyle.normal.color = chartColors.excedentariaLine;
         this.selfViewOptions.seriesBarChartComparison[Series.netaGenerada].itemStyle.normal.color = chartColors.netaGeneradaLine;
         this.selfViewOptions.xAxis.axisLabel.formatter = (value) => {
           const time = new Date(value);
           return time.toLocaleDateString('es-ES');
         };
       }
     }

     this.echartsInstance.setOption(this.selfViewOptions, false);*/
  }

  initiatePeriods(data: IDatoHorario[]) {
    if (typeof data[0] !== 'undefined') {
      let currentPeriod = data[0].periodo;
      let startDateDate = data[0].hora;
      let endDateDate = '';

      for (let i = 0; i < data.length; i++) {

        if (currentPeriod !== data[i].periodo) {
          endDateDate = data[i - 1].hora;
          this.periods.push({startDate: startDateDate, endDate: endDateDate, period: currentPeriod});
          startDateDate = endDateDate;
        }

        currentPeriod = data[i].periodo;
      }

    }

    this.paintPeriods();
  }

  paintPeriods() {
    const markers = [];
    for (const period of this.periods) {
      const marker = [
        {name: 'P' + period.period, xAxis: period.startDate, yAxis: 0},
        {yAxis: 80, xAxis: period.endDate}
      ];
      markers.push(marker);
    }
    this.selfViewOptions.series[0].markArea.data = markers;
    this.selfViewOptions.series[1].markArea.data = markers;
    this.selfViewOptions.series[2].markArea.data = markers;
    this.selfViewOptions.series[3].markArea.data = markers;
    this.selfViewOptions.series[4].markArea.data = markers;
  }

  modifySerie(serieName: string, $event) {
    // Get the zoom level of the graph and apply it in legend selection
    let zoom = null;
    if (this.echartsInstance) {
      zoom = this.echartsInstance.getOption().dataZoom;
      this.selfViewOptions.dataZoom = zoom;
    }
    const state = $event.checked;

    if (serieName === this.datagraf[0]) {
      this.seriesBarChartComparison['Consumo P1'] = state;
      this.seriesBarChartComparison['Consumo P2'] = state;
      this.seriesBarChartComparison['Consumo P3'] = state;
      this.seriesBarChartComparison['Consumo P4'] = state;
      this.seriesBarChartComparison['Consumo P5'] = state;
      this.seriesBarChartComparison['Consumo P6'] = state;
      this.seriesBarChartComparison['Consumo P1*'] = state;
      this.seriesBarChartComparison['Consumo P2*'] = state;
      this.seriesBarChartComparison['Consumo P3*'] = state;
      this.seriesBarChartComparison['Consumo P4*'] = state;
      this.seriesBarChartComparison['Consumo P5*'] = state;
      this.seriesBarChartComparison['Consumo P6*'] = state;
      this.seriesLine['Consumo Total'] = state;
      this.seriesLine['Consumo Total*'] = state;
    } else if (serieName === this.datagraf[1]) {
      this.seriesBarChartComparison['Consumo Red P1'] = state;
      this.seriesBarChartComparison['Consumo Red P2'] = state;
      this.seriesBarChartComparison['Consumo Red P3'] = state;
      this.seriesBarChartComparison['Consumo Red P4'] = state;
      this.seriesBarChartComparison['Consumo Red P5'] = state;
      this.seriesBarChartComparison['Consumo Red P6'] = state;
      this.seriesBarChartComparison['Consumo Red P1*'] = state;
      this.seriesBarChartComparison['Consumo Red P2*'] = state;
      this.seriesBarChartComparison['Consumo Red P3*'] = state;
      this.seriesBarChartComparison['Consumo Red P4*'] = state;
      this.seriesBarChartComparison['Consumo Red P5*'] = state;
      this.seriesBarChartComparison['Consumo Red P6*'] = state;
      this.seriesLine['Consumida Red'] = state;
      this.seriesLine['Consumida Red*'] = state;
    } else if (serieName === this.datagraf[2]) {
      this.seriesBarChartComparison['Autoconsumo P1'] = state;
      this.seriesBarChartComparison['Autoconsumo P2'] = state;
      this.seriesBarChartComparison['Autoconsumo P3'] = state;
      this.seriesBarChartComparison['Autoconsumo P4'] = state;
      this.seriesBarChartComparison['Autoconsumo P5'] = state;
      this.seriesBarChartComparison['Autoconsumo P6'] = state;
      this.seriesBarChartComparison['Autoconsumo P1*'] = state;
      this.seriesBarChartComparison['Autoconsumo P2*'] = state;
      this.seriesBarChartComparison['Autoconsumo P3*'] = state;
      this.seriesBarChartComparison['Autoconsumo P4*'] = state;
      this.seriesBarChartComparison['Autoconsumo P5*'] = state;
      this.seriesBarChartComparison['Autoconsumo P6*'] = state;
      this.seriesLine['Auto Consumida'] = state;
      this.seriesLine['Auto Consumida*'] = state;
    } else if (serieName === this.datagraf[3]) {
      this.seriesBarChartComparison['Excedente P1'] = state;
      this.seriesBarChartComparison['Excedente P2'] = state;
      this.seriesBarChartComparison['Excedente P3'] = state;
      this.seriesBarChartComparison['Excedente P4'] = state;
      this.seriesBarChartComparison['Excedente P5'] = state;
      this.seriesBarChartComparison['Excedente P6'] = state;
      this.seriesBarChartComparison['Excedente P1*'] = state;
      this.seriesBarChartComparison['Excedente P2*'] = state;
      this.seriesBarChartComparison['Excedente P3*'] = state;
      this.seriesBarChartComparison['Excedente P4*'] = state;
      this.seriesBarChartComparison['Excedente P5*'] = state;
      this.seriesBarChartComparison['Excedente P6*'] = state;
      this.seriesLine['Excedente'] = state;
      this.seriesLine['Excedente*'] = state;
    } else if (serieName === this.datagraf[4]) {
      this.seriesBarChartComparison['Neta Generada P1'] = state;
      this.seriesBarChartComparison['Neta Generada P2'] = state;
      this.seriesBarChartComparison['Neta Generada P3'] = state;
      this.seriesBarChartComparison['Neta Generada P4'] = state;
      this.seriesBarChartComparison['Neta Generada P5'] = state;
      this.seriesBarChartComparison['Neta Generada P6'] = state;
      this.seriesBarChartComparison['Neta Generada P1*'] = state;
      this.seriesBarChartComparison['Neta Generada P2*'] = state;
      this.seriesBarChartComparison['Neta Generada P3*'] = state;
      this.seriesBarChartComparison['Neta Generada P4*'] = state;
      this.seriesBarChartComparison['Neta Generada P5*'] = state;
      this.seriesBarChartComparison['Neta Generada P6*'] = state;
      this.seriesLine['Neta Generada'] = state;
      this.seriesLine['Neta Generada*'] = state;
    }
    if (this.graphicType === 'barras') {
      this.selfViewOptions.legend.selected = this.seriesBarChartComparison;
    } else {
      if (this.seriesBarChartComparison['Consumo P1']) {
        this.selfViewOptions.series[0].markArea.itemStyle.opacity = 1;
        this.selfViewOptions.series[1].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[2].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[3].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[4].markArea.itemStyle.opacity = 0;
      } else if (this.seriesBarChartComparison['Consumo Red P1']) {
        this.selfViewOptions.series[0].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[1].markArea.itemStyle.opacity = 1;
        this.selfViewOptions.series[2].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[3].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[4].markArea.itemStyle.opacity = 0;
      } else if (this.seriesBarChartComparison['Autoconsumo P1']) {
        this.selfViewOptions.series[0].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[1].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[2].markArea.itemStyle.opacity = 1;
        this.selfViewOptions.series[3].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[4].markArea.itemStyle.opacity = 0;
      } else if (this.seriesBarChartComparison['Excedente P1']) {
        this.selfViewOptions.series[0].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[1].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[2].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[3].markArea.itemStyle.opacity = 1;
        this.selfViewOptions.series[4].markArea.itemStyle.opacity = 0;
      } else if (this.seriesBarChartComparison['Neta Generada P1']) {
        this.selfViewOptions.series[0].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[1].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[2].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[3].markArea.itemStyle.opacity = 0;
        this.selfViewOptions.series[4].markArea.itemStyle.opacity = 1;
      }
      this.selfViewOptions.legend.selected = this.seriesLine;
    }
    this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
  }

  translateAll() {
    this.translate.get('SELFVIEW.HTML.RANGES').subscribe((res: string) => {
      if (res.toString() === 'rangesEn') {
        this.datagraf = this.dataEn;
        this.datagraf2 = this.dataEn2;
      } else {
        this.datagraf = this.dataEs;
        this.datagraf2 = this.dataEs2;
      }
    });
  }

  changeType(): void {
    this.type = !this.type;
    this.updateType.emit(this.type);
    this.showChartForLocalType();
    this.showChartComparisonForLocalType();
  }

  showChartForGlobalType() {
    if (this.normalData !== null) {
      const data = this.normalData;
      if ((data.selfViewDataPower != null || data.selfViewDataEconomico != null) && !this.compareMode) {
        if (this.globalType === GlobalType.Energy) {
          this.selfViewDataPower = data.selfViewDataPower;
          this.graphicType = data.selfViewDataPower.tipo_grafico;
          this.serieData.consumoTotal.value = data.selfViewDataPower.total_consumo;
          this.serieData.autoConsumida.value = data.selfViewDataPower.total_autoconsumo;
          this.serieData.consumidaRed.value = data.selfViewDataPower.total_consumo_red;
          this.serieData.excedentaria.value = data.selfViewDataPower.total_excedente;
          this.serieData.netaGenerada.value = data.selfViewDataPower.total_generacion;
          this.type = false;
          if (data.selfViewDataPower.tipo_grafico === 'barras') {
            this.initiatePowerChartBarra();
          } else {
            this.initiatePowerChartArea();
            this.initiatePeriods(data.selfViewDataPower.datosHorarios);
          }
          this.serieState = {
            [this.datagraf[0]]: true,
            [this.datagraf[1]]: true,
            [this.datagraf[2]]: true,
            [this.datagraf[3]]: true,
            [this.datagraf[4]]: true,
          };
        } else if (this.globalType === GlobalType.Economic) {
          this.selfViewDataEconomic = data.selfViewDataEconomico;
          this.serieEconomicData.Factura.value = data.selfViewDataEconomico.total_factura;
          this.serieEconomicData.Compensable.value = data.selfViewDataEconomico.total_compensable;
          this.serieEconomicData.Compensacion.value = data.selfViewDataEconomico.total_compensacion;
          this.serieEconomicData.SinCompensacion.value = data.selfViewDataEconomico.total_sin_compensacion;
          this.serieEconomicData.AhorroAutoconsumo.value = data.selfViewDataEconomico.total_ahorro;
          this.type = true;
          this.initiateEconomicChart();
        }
      }

      if (this.compareMode) {
        if (this.selfViewDataPowerComparison !== null && this.globalType === GlobalType.Energy) {
          if (data.selfViewDataPower.tipo_grafico === 'barras') {
            this.initiatePowerChartBarraComparison();
            this.selfViewOptions.legend.selected = this.seriesBarChartComparison;
            this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
          } else {
            this.initiatePowerChartAreaComparison();
            this.selfViewOptions.legend.selected = this.seriesLine;
            this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
            // this.initiatePeriods(dataNormal.selfViewDataPower.datosHorarios);
          }
        } else if (this.selfViewDataEconomicComparison !== null && this.globalType === GlobalType.Economic) {
          this.initiateEconomicChartComparison();
        }
      }
    }
  }

  showChartForLocalType() {
    if (this.normalData !== null) {
      const data = this.normalData;
      if (
        (data.selfViewDataPower != null || data.selfViewDataEconomico != null)) {
        if (this.type === false) {
          this.selfViewDataPower = data.selfViewDataPower;
          this.graphicType = data.selfViewDataPower.tipo_grafico;
          this.serieData.consumoTotal.value = data.selfViewDataPower.total_consumo;
          this.serieData.autoConsumida.value = data.selfViewDataPower.total_autoconsumo;
          this.serieData.consumidaRed.value = data.selfViewDataPower.total_consumo_red;
          this.serieData.excedentaria.value = data.selfViewDataPower.total_excedente;
          this.serieData.netaGenerada.value = data.selfViewDataPower.total_generacion;
          if (data.selfViewDataPower.tipo_grafico === 'barras') {
            this.initiatePowerChartBarra();
          } else {
            this.initiatePowerChartArea();
            this.initiatePeriods(data.selfViewDataPower.datosHorarios);
          }
          this.serieState = {
            [this.datagraf[0]]: true,
            [this.datagraf[1]]: true,
            [this.datagraf[2]]: true,
            [this.datagraf[3]]: true,
            [this.datagraf[4]]: true,
          };
        } else if (this.type === true) {
          this.selfViewDataEconomic = data.selfViewDataEconomico;
          this.serieEconomicData.Factura.value = data.selfViewDataEconomico.total_factura;
          this.serieEconomicData.Compensable.value = data.selfViewDataEconomico.total_compensable;
          this.serieEconomicData.Compensacion.value = data.selfViewDataEconomico.total_compensacion;
          this.serieEconomicData.SinCompensacion.value = data.selfViewDataEconomico.total_sin_compensacion;
          this.serieEconomicData.AhorroAutoconsumo.value = data.selfViewDataEconomico.total_ahorro;
          this.initiateEconomicChart();
        }
      }

      if (this.compareMode) {
        if (this.selfViewDataPowerComparison !== null && this.type === false) {
          if (data.selfViewDataPower.tipo_grafico === 'barras') {
            this.initiatePowerChartBarraComparison();
            this.selfViewOptions.legend.selected = this.seriesBarChartComparison;
            this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
          } else {
            this.initiatePowerChartAreaComparison();
            this.selfViewOptions.legend.selected = this.seriesLine;
            this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
            // this.initiatePeriods(dataNormal.selfViewDataPower.datosHorarios);
          }
        } else if (this.selfViewDataEconomicComparison !== null && this.type === true) {
          this.initiateEconomicChartComparison();
        }
      }
    }

  }

  showChartComparisonForGlobalType() {
    if (this.comparisonData) {
      this.type = false;
      const data = this.comparisonData;
      if (data.selfViewDataEconomico !== null || data.selfViewDataPower !== null) {
        this.compareMode = true;

        this.seriesBarChartComparison = {
          'Consumo P1': true,
          'Consumo P2': true,
          'Consumo P3': true,
          'Consumo P4': true,
          'Consumo P5': true,
          'Consumo P6': true,
          'Consumo Red P1': true,
          'Consumo Red P2': true,
          'Consumo Red P3': true,
          'Consumo Red P4': true,
          'Consumo Red P5': true,
          'Consumo Red P6': true,
          'Autoconsumo P1': true,
          'Autoconsumo P2': true,
          'Autoconsumo P3': true,
          'Autoconsumo P4': true,
          'Autoconsumo P5': true,
          'Autoconsumo P6': true,
          'Excedente P1': true,
          'Excedente P2': true,
          'Excedente P3': true,
          'Excedente P4': true,
          'Excedente P5': true,
          'Excedente P6': true,
          'Neta Generada P1': true,
          'Neta Generada P2': true,
          'Neta Generada P3': true,
          'Neta Generada P4': true,
          'Neta Generada P5': true,
          'Neta Generada P6': true,
          'Consumo P1*': true,
          'Consumo P2*': true,
          'Consumo P3*': true,
          'Consumo P4*': true,
          'Consumo P5*': true,
          'Consumo P6*': true,
          'Consumo Red P1*': true,
          'Consumo Red P2*': true,
          'Consumo Red P3*': true,
          'Consumo Red P4*': true,
          'Consumo Red P5*': true,
          'Consumo Red P6*': true,
          'Autoconsumo P1*': true,
          'Autoconsumo P2*': true,
          'Autoconsumo P3*': true,
          'Autoconsumo P4*': true,
          'Autoconsumo P5*': true,
          'Autoconsumo P6*': true,
          'Excedente P1*': true,
          'Excedente P2*': true,
          'Excedente P3*': true,
          'Excedente P4*': true,
          'Excedente P5*': true,
          'Excedente P6*': true,
          'Neta Generada P1*': true,
          'Neta Generada P2*': true,
          'Neta Generada P3*': true,
          'Neta Generada P4*': true,
          'Neta Generada P5*': true,
          'Neta Generada P6*': true
        };
        this.seriesLine = {
          'Consumo Total': true, 'Consumida Red': true, 'Auto Consumida': true, 'Excedente': true, 'Neta Generada': true,
          'Consumo Total*': true, 'Consumida Red*': true, 'Auto Consumida*': true, 'Excedente*': true, 'Neta Generada*': true
        };
        if (this.graphicType === 'barras') {
          this.selfViewOptions.legend.selected = this.seriesBarChartComparison;
        } else {
          this.selfViewOptions.legend.selected = this.seriesLine;
        }
        this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};

        this.selfViewDataEconomicComparison = data.selfViewDataEconomico;
        this.selfViewDataPowerComparison = data.selfViewDataPower;
        if (data.selfViewDataPower !== null && this.globalType === GlobalType.Energy) {
          this.selfViewDataPowerComparison = data.selfViewDataPower;
          this.serieData.consumidaRed.compareValue = data.selfViewDataPower.total_consumo_red;
          this.serieData.autoConsumida.compareValue = data.selfViewDataPower.total_autoconsumo;
          this.serieData.consumoTotal.compareValue = data.selfViewDataPower.total_consumo;
          this.serieData.excedentaria.compareValue = data.selfViewDataPower.total_excedente;
          this.serieData.netaGenerada.compareValue = data.selfViewDataPower.total_generacion;
          if (data.selfViewDataPower.tipo_grafico === 'barras') {
            this.initiatePowerChartBarraComparison();
            this.selfViewOptions.legend.selected = this.seriesBarChartComparison;
            this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
          } else {
            this.initiatePowerChartAreaComparison();
            this.selfViewOptions.legend.selected = this.seriesLine;
            this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
          }
        } else if (data.selfViewDataEconomico !== null && this.globalType === GlobalType.Economic) {
          this.serieEconomicData.Factura.compareValue = data.selfViewDataEconomico.total_factura;
          this.serieEconomicData.Compensable.compareValue = data.selfViewDataEconomico.total_compensable;
          this.serieEconomicData.Compensacion.compareValue = data.selfViewDataEconomico.total_compensacion;
          this.serieEconomicData.SinCompensacion.compareValue = data.selfViewDataEconomico.total_sin_compensacion;
          this.serieEconomicData.AhorroAutoconsumo.compareValue = data.selfViewDataEconomico.total_ahorro;
          this.initiateEconomicChartComparison();
        }
      }
    } else {
      if (this.comparePeriod === null) {
        this.seriesBarChartComparison = {
          'Consumo P1': true,
          'Consumo P2': true,
          'Consumo P3': true,
          'Consumo P4': true,
          'Consumo P5': true,
          'Consumo P6': true,
          'Consumo Red P1': true,
          'Consumo Red P2': true,
          'Consumo Red P3': true,
          'Consumo Red P4': true,
          'Consumo Red P5': true,
          'Consumo Red P6': true,
          'Autoconsumo P1': true,
          'Autoconsumo P2': true,
          'Autoconsumo P3': true,
          'Autoconsumo P4': true,
          'Autoconsumo P5': true,
          'Autoconsumo P6': true,
          'Excedente P1': true,
          'Excedente P2': true,
          'Excedente P3': true,
          'Excedente P4': true,
          'Excedente P5': true,
          'Excedente P6': true,
          'Neta Generada P1': true,
          'Neta Generada P2': true,
          'Neta Generada P3': true,
          'Neta Generada P4': true,
          'Neta Generada P5': true,
          'Neta Generada P6': true,
          'Consumo P1*': false,
          'Consumo P2*': false,
          'Consumo P3*': false,
          'Consumo P4*': false,
          'Consumo P5*': false,
          'Consumo P6*': false,
          'Consumo Red P1*': false,
          'Consumo Red P2*': false,
          'Consumo Red P3*': false,
          'Consumo Red P4*': false,
          'Consumo Red P5*': false,
          'Consumo Red P6*': false,
          'Autoconsumo P1*': false,
          'Autoconsumo P2*': false,
          'Autoconsumo P3*': false,
          'Autoconsumo P4*': false,
          'Autoconsumo P5*': false,
          'Autoconsumo P6*': false,
          'Excedente P1*': false,
          'Excedente P2*': false,
          'Excedente P3*': false,
          'Excedente P4*': false,
          'Excedente P5*': false,
          'Excedente P6*': false,
          'Neta Generada P1*': false,
          'Neta Generada P2*': false,
          'Neta Generada P3*': false,
          'Neta Generada P4*': false,
          'Neta Generada P5*': false,
          'Neta Generada P6*': false
        };
        this.seriesLine = {
          'Consumo Total': true, 'Consumida Red': true, 'Auto Consumida': true, 'Excedente': true, 'Neta Generada': true,
          'Consumo Total*': false, 'Consumida Red*': false, 'Auto Consumida*': false, 'Excedente*': false, 'Neta Generada*': false
        };
        if (this.graphicType === 'barras') {
          this.selfViewOptions.legend.selected = this.seriesBarChartComparison;
        } else {
          this.selfViewOptions.legend.selected = this.seriesLine;
        }
        this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
      }
    }

  }

  showChartComparisonForLocalType() {
    if (this.comparisonData !== null) {
      const data = this.comparisonData;
      if (data.selfViewDataEconomico !== null || data.selfViewDataPower !== null) {
        this.compareMode = true;
        // this.seriesBarChartComparison = {
        //   'Consumo P1': true,
        //   'Consumo P2': true,
        //   'Consumo P3': true,
        //   'Consumo P4': true,
        //   'Consumo P5': true,
        //   'Consumo P6': true,
        //   'Consumo Red P1': true,
        //   'Consumo Red P2': true,
        //   'Consumo Red P3': true,
        //   'Consumo Red P4': true,
        //   'Consumo Red P5': true,
        //   'Consumo Red P6': true,
        //   'Autoconsumo P1': true,
        //   'Autoconsumo P2': true,
        //   'Autoconsumo P3': true,
        //   'Autoconsumo P4': true,
        //   'Autoconsumo P5': true,
        //   'Autoconsumo P6': true,
        //   'Excedente P1': true,
        //   'Excedente P2': true,
        //   'Excedente P3': true,
        //   'Excedente P4': true,
        //   'Excedente P5': true,
        //   'Excedente P6': true,
        //   'Generado Neto P1': true,
        //   'Generado Neto P2': true,
        //   'Generado Neto P3': true,
        //   'Generado Neto P4': true,
        //   'Generado Neto P5': true,
        //   'Generado Neto P6': true,
        //   'Consumo P1*': true,
        //   'Consumo P2*': true,
        //   'Consumo P3*': true,
        //   'Consumo P4*': true,
        //   'Consumo P5*': true,
        //   'Consumo P6*': true,
        //   'Consumo Red P1*': true,
        //   'Consumo Red P2*': true,
        //   'Consumo Red P3*': true,
        //   'Consumo Red P4*': true,
        //   'Consumo Red P5*': true,
        //   'Consumo Red P6*': true,
        //   'Autoconsumo P1*': true,
        //   'Autoconsumo P2*': true,
        //   'Autoconsumo P3*': true,
        //   'Autoconsumo P4*': true,
        //   'Autoconsumo P5*': true,
        //   'Autoconsumo P6*': true,
        //   'Excedente P1*': true,
        //   'Excedente P2*': true,
        //   'Excedente P3*': true,
        //   'Excedente P4*': true,
        //   'Excedente P5*': true,
        //   'Excedente P6*': true,
        //   'Generado Neto P1*': true,
        //   'Generado Neto P2*': true,
        //   'Generado Neto P3*': true,
        //   'Generado Neto P4*': true,
        //   'Generado Neto P5*': true,
        //   'Generado Neto P6*': true
        // };
        // this.seriesLine = {
        //   'Consumo Total': true, 'Consumida Red': true, 'Auto Consumida': true, 'Excedente': true, 'Neta generada': true,
        //   'Consumo Total*': true, 'Consumida Red*': true, 'Auto Consumida*': true, 'Excedente*': true, 'Neta generada*': true
        // };
        if (this.graphicType === 'barras') {
          this.selfViewOptions.legend.selected = this.seriesBarChartComparison;
        } else {
          this.selfViewOptions.legend.selected = this.seriesLine;
        }
        this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};

        this.selfViewDataEconomicComparison = data.selfViewDataEconomico;
        this.selfViewDataPowerComparison = data.selfViewDataPower;
        if (data.selfViewDataPower !== null && this.type === false) {
          this.selfViewDataPowerComparison = data.selfViewDataPower;
          this.serieData.consumidaRed.compareValue = data.selfViewDataPower.total_consumo_red;
          this.serieData.autoConsumida.compareValue = data.selfViewDataPower.total_autoconsumo;
          this.serieData.consumoTotal.compareValue = data.selfViewDataPower.total_consumo;
          this.serieData.excedentaria.compareValue = data.selfViewDataPower.total_excedente;
          this.serieData.netaGenerada.compareValue = data.selfViewDataPower.total_generacion;
          if (data.selfViewDataPower.tipo_grafico === 'barras') {
            this.initiatePowerChartBarraComparison();
            this.selfViewOptions.legend.selected = this.seriesBarChartComparison;
            this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
          } else {
            this.initiatePowerChartAreaComparison();
            this.selfViewOptions.legend.selected = this.seriesLine;
            this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
          }
        } else if (data.selfViewDataEconomico !== null && this.type === true) {
          this.serieEconomicData.Factura.compareValue = data.selfViewDataEconomico.total_factura;
          this.serieEconomicData.Compensable.compareValue = data.selfViewDataEconomico.total_compensable;
          this.serieEconomicData.Compensacion.compareValue = data.selfViewDataEconomico.total_compensacion;
          this.serieEconomicData.SinCompensacion.compareValue = data.selfViewDataEconomico.total_sin_compensacion;
          this.serieEconomicData.AhorroAutoconsumo.compareValue = data.selfViewDataEconomico.total_ahorro;
          this.initiateEconomicChartComparison();
        }
      }
    } else {
      if (this.comparePeriod === null) {
        this.seriesBarChartComparison = {
          'Consumo P1': true,
          'Consumo P2': true,
          'Consumo P3': true,
          'Consumo P4': true,
          'Consumo P5': true,
          'Consumo P6': true,
          'Consumo Red P1': true,
          'Consumo Red P2': true,
          'Consumo Red P3': true,
          'Consumo Red P4': true,
          'Consumo Red P5': true,
          'Consumo Red P6': true,
          'Autoconsumo P1': true,
          'Autoconsumo P2': true,
          'Autoconsumo P3': true,
          'Autoconsumo P4': true,
          'Autoconsumo P5': true,
          'Autoconsumo P6': true,
          'Excedente P1': true,
          'Excedente P2': true,
          'Excedente P3': true,
          'Excedente P4': true,
          'Excedente P5': true,
          'Excedente P6': true,
          'Neta Generada P1': true,
          'Neta Generada P2': true,
          'Neta Generada P3': true,
          'Neta Generada P4': true,
          'Neta Generada P5': true,
          'Neta Generada P6': true,
          'Consumo P1*': false,
          'Consumo P2*': false,
          'Consumo P3*': false,
          'Consumo P4*': false,
          'Consumo P5*': false,
          'Consumo P6*': false,
          'Consumo Red P1*': false,
          'Consumo Red P2*': false,
          'Consumo Red P3*': false,
          'Consumo Red P4*': false,
          'Consumo Red P5*': false,
          'Consumo Red P6*': false,
          'Autoconsumo P1*': false,
          'Autoconsumo P2*': false,
          'Autoconsumo P3*': false,
          'Autoconsumo P4*': false,
          'Autoconsumo P5*': false,
          'Autoconsumo P6*': false,
          'Excedente P1*': false,
          'Excedente P2*': false,
          'Excedente P3*': false,
          'Excedente P4*': false,
          'Excedente P5*': false,
          'Excedente P6*': false,
          'Neta Generada P1*': false,
          'Neta Generada P2*': false,
          'Neta Generada P3*': false,
          'Neta Generada P4*': false,
          'Neta Generada P5*': false,
          'Neta Generada P6*': false
        };
        this.seriesLine = {
          'Consumo Total': true, 'Consumida Red': true, 'Auto Consumida': true, 'Excedente': true, 'Neta Generada': true,
          'Consumo Total*': false, 'Consumida Red*': false, 'Auto Consumida*': false, 'Excedente*': false, 'Neta Generada*': false
        };
        if (this.graphicType === 'barras') {
          this.selfViewOptions.legend.selected = this.seriesBarChartComparison;
        } else {
          this.selfViewOptions.legend.selected = this.seriesLine;
        }
        this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
      }
    }

  }

  disabledCompared() {
    this.compareMode = false;
    this.comparePeriod = null;
    this.dataCompare = null;
    this.serieData.consumidaRed.compareValue = null;
    this.serieData.autoConsumida.compareValue = null;
    this.serieData.consumoTotal.compareValue = null;
    this.serieData.excedentaria.compareValue = null;
    this.serieData.netaGenerada.compareValue = null;
    if (this.selfViewOptions.xAxis.length > 0) {
      this.selfViewOptions.xAxis.pop();
    }
    this.selfviewService.removeDataCompare();
    this.tableService.removeDataCompare();
  }

}
