import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IInstallationMeter } from 'projects/pplus/src/lib/interfaces/interfaces';
import { INSTALLATION_METERS_COL_DEFS } from './installation-meters.model';

@Injectable({
  providedIn: 'root'
})
export class InstallationMetersService {
  private readonly apiUrl = environment.API_URL + '/api/installation/';
  private readonly apiScrollInfinite = this.apiUrl + 'get-installationMeters';
  private readonly apiMeter = this.apiUrl + 'meter';

  constructor(private http: HttpClient) {}

  getInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const body = { startRow, endRow, sortModel, filterModel };
    return this.http.post(this.apiScrollInfinite, body).pipe(
      map(response => <any>response)
    );
  }

  getGeneralFilter(value: string, startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of INSTALLATION_METERS_COL_DEFS) columnList.push(colDef.field);
    return this.http.post(this.apiScrollInfinite, {
      startRow, endRow, sortModel, filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel,
      },
      columnList,
    });
  }

  getAll(): Observable<IInstallationMeter[]> {
    return this.http.get(this.apiMeter).pipe(
      map(response => <IInstallationMeter[]>response),
      catchError(() => of([]))
    );
  }

  get(installationMeterId: number): Observable<IInstallationMeter> {
    return this.http.get(`${this.apiMeter}/${installationMeterId}`).pipe(
      map(response => <IInstallationMeter>response),
      catchError(() => of(null))
    );
  }

  create(body: IInstallationMeter): Observable<IInstallationMeter> {
    return this.http.post(this.apiMeter, body, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? <IInstallationMeter>response.body : null),
      catchError(() => of(null))
    );
  }

  update(installationMeterId: number, body: IInstallationMeter): Observable<boolean> {
    return this.http.put(`${this.apiMeter}/${installationMeterId}`, body, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  delete(installationMeterId: number): Observable<boolean> {
    return this.http.delete(`${this.apiMeter}/${installationMeterId}`, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  getTypes(): Observable<any> {
    return this.http.get(this.apiMeter + '/types-list').pipe(
      map(response => response),
      catchError(() => of([]))
    );
  }

}
