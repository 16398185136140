import { Component, OnInit, Output, TemplateRef, EventEmitter } from '@angular/core';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { FormGroup, FormControl } from '@angular/forms';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { InstallationService } from '../installations.service';
import { NbDialogService } from '@nebular/theme';
import { AdminService } from '../../admin.service';
import { InstallationDetailsService } from './installation-details.service';
import { map } from 'rxjs/operators';
import { Installation } from '../installations.model';
import { forkJoin } from 'rxjs';
import { EntitiesService } from '../../entities/entities.service';

@Component({
  selector: 'app-installation-details',
  templateUrl: './installation-details.component.html',
  styleUrls: ['./installation-details.component.scss']
})
export class InstallationDetailsComponent implements OnInit {
  @Output() updateDelete = new EventEmitter();
  @Output() splitOptions = new EventEmitter();

  data: any;

  formData: DynamicForm [];
  formGroup: FormGroup;

  constructor(
    private dataRow: RowDataService,
    private installationService: InstallationService,
    private dialog: NbDialogService,
    private adminService: AdminService,
    private installationDetailsService: InstallationDetailsService,
    private entitiesService: EntitiesService,
  ) {
    this.formData = this.installationDetailsService.getDynamicForm();
  }

  generateForm(data: DynamicForm []) {
    const fieldsCtrls = {};
    data.forEach(item => {
      const fields = item.fields;
      fields.forEach(f => {
        fieldsCtrls[f.name] = new FormControl(f.value || '', f.validators);
      });
    });
    this.formGroup = new FormGroup(fieldsCtrls);
  }

  ngOnInit() {
    forkJoin([
      this.entitiesService.getEntitiesList(),
    ]).subscribe(([entities]) => {
      this.formData.map(o => {
        o.fields.forEach(f => {
          if (f.name === 'assignedEntityInstallation') {
            const opts = [];
            entities.map(e => opts.push({
              value: e.entityId,
              label: e.code,
            }));
            f.options = opts;
          }
        });
      });
      this.generateForm(this.formData);
    });

    this.dataRow.currentRowData.pipe(
      map(o => {
        this.formData.forEach(data => {
          const fields = data.fields;
          fields.map(field => {
            if (o[field.name]) {
              field.value = o[field.name];
            } else field.value = '';
          });
        });
        return o;
      })
    ).subscribe(data => {
      this.data = <Installation>data;
      this.generateForm(this.formData);
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <Installation>this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.installationService.createInstallation(data).subscribe(newInstallation => {
        if (newInstallation != null) {
          this.formGroup.patchValue(Object.assign(this.data, newInstallation));
          this.updateDelete.emit(true);
          this.adminService.showToast('success',
              'Se ha creado la nueva instalación: ' + data.name,
              'Elemento creado');
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al crear la instalación: ' + data.name,
              'Error');
        }
      });
    }
  }

  update() {
    const installationId = this.data.installationId;
    if (installationId == null) {
      this.onSubmit();
    } else if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <Installation>this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.installationService.updateInstallation(installationId, data).subscribe(success => {
        if (success) {
          this.updateDelete.emit();
          this.adminService.showToast('success',
              'Se ha actualizado la instalación: ' + data.name,
              'Elemento actualizado');
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al actualizar la instalación: ' + data.name,
              'Error');
        }
      });
    }
  }

  delete() {
    if (this.data.installationId == null) {
      this.adminService.showToast('info', 'Seleccione una instalación', 'Info');
    } else {
      this.installationService.deleteInstallation(this.data.installationId).subscribe(success => {
        if (success) {
          this.updateDelete.emit();
          this.adminService.showToast('success',
              'Se ha borrado la instalación: ' + this.data.name,
              'Elemento eliminado');
          this.clearData();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al borrar la instalación: ' + this.data.name,
              'Error');
        }
      });
    }
  }

  exit() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  copy() {
    if (this.data.entityDomainId == null) {
      this.adminService.showToast('info', 'Seleccione un dominio para copiar la información', 'Info');
    } else {
      this.formGroup.setValue(Object.assign(this.data, this.formGroup.value, {
        entityDomainId: null,
        fqdn: '',
      }));
    }
  }

  clearData() {
    this.data = <Installation>{};
    this.formData.forEach(data => {
      const fields = data.fields;
      fields.map(field => {
        field.value = '';
      });
    });
    this.generateForm(this.formData);
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.entityDomainId == null) {
      this.adminService.showToast('info', 'No hay ningún dominio seleccionado', 'Info');
    } else {
      this.dialog.open(dialog, { context: '¿Eliminar el dominio?', hasScroll: false });
    }
  }
}
