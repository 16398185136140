import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {PlatformLocation} from '@angular/common';
import {ItemNode, ItemFlatNode, TreeData} from '../../models/components/tree-data.model';

@Injectable()
export class ChecklistDatabase {
  dataChange = new BehaviorSubject<ItemNode []>(null);
  externalData: TreeData [];
  treeData: any[];
  dataSource = [];
  get data(): ItemNode [] {
    return this.dataChange.value;
  }

  get eData(): TreeData [] {
    return this.externalData;
  }

  loaderId: string;

  constructor(private platformLocation: PlatformLocation) {
  }

  initialize(data: TreeData []) {
    this.externalData = data;
    const newData = this.generateData(this.externalData);
    this.dataChange.next(newData);
  }

  generateData(data: TreeData []): ItemNode [] {
    this.dataSource = [];
    this.generateDataWithCode(data, 1, '0');
    this.treeData = this.dataSource;
    return this.buildFileTree(this.dataSource, '0');
  }

  generateDataWithCode(data: TreeData [], parent: number, code: string): any {
    let nCode = `${code}.${parent}`;
    data.forEach( item => {
      nCode = `${code}.${parent++}`;
      this.dataSource.push(
        {
          text: item.text,
          id: item.id,
          code: nCode,
          selected: item.selected,
          data: item.data
        }
      );
      const itemChildren = item.children;
      if (itemChildren && itemChildren.length > 0) {
        return this.generateDataWithCode(item.children, parent, nCode);
      }
    });
  }

  buildFileTree(obj: any[], level: string): ItemNode [] {
    return obj.filter(o =>
      (o.code as string).startsWith(level + '.')
      && (o.code.match(/\./g) || []).length === (level.match(/\./g) || []).length + 1
    )
      .map(o => {
        const node = new ItemNode();
        node.item = o.text;
        node.selected = o.selected;
        node.id = o.id;
        node.code = o.code;
        node.data = o.data;
        const children = obj.filter(so => (so.code as string).startsWith(level + '.'));
        if (children && children.length > 0) {
          node.children = this.buildFileTree(children, o.code);
        }
        return node;
      });
  }

  public filter(filterText: string) {
    this.treeData = this.generateDataWithCode(this.externalData, 1, '0');
    let filteredTreeData;
    if (filterText) {
      filteredTreeData = this.treeData.filter(d => d.text.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1);
      Object.assign([], filteredTreeData).forEach(ftd => {
        let str = (ftd.code as string);
        while (str.lastIndexOf('.') > -1) {
          const index = str.lastIndexOf('.');
          str = str.substring(0, index);
          if (filteredTreeData.findIndex(t => t.code === str) === -1) {
            const obj = this.treeData.find(d => d.code === str);
            if (obj) {
              filteredTreeData.push(obj);
            }
          }
        }
      });
    } else {
      filteredTreeData = this.treeData;
    }

    const data = this.buildFileTree(filteredTreeData, '0');
    this.dataChange.next(data);
  }

  public updateData(items: ItemFlatNode [], externalData ?: TreeData [] | null): void {
    this.externalData = this.recursiveUpdate(items, this.externalData);
  }

  recursiveUpdate(items: ItemFlatNode[], externalData: TreeData []): TreeData [] {
    externalData.map(data => {
      data.selected = false;
      items.forEach(el => {
        if (el.id === data.id) {
          data.selected = true;
        }
      });
      const children = data.children;
      if (children && data.children.length > 0) {
        this.recursiveUpdate(items, children);
      }
    });
    return externalData;
  }
}
