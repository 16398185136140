import { chartColors } from '../../selfview.graph.colors';
import { ACTIVE_ENERGY_SERIES_DATA, ACTIVE_ECONOMIC_SERIES_DATA } from './selfview-active-main.series';

/**
 * Bar char options
 */
export const ACTIVE_BAR_ENERGY_CHART_OPTIONS = {
  legend: {
    show: false,
    bottom: '0%',
    type: 'scroll'
  },
  tooltip: {
    trigger: 'item',
    textStyle: {
      fontSize: 10,
      width: 500
    },
    formatter: (params) => {
      let values = params.name + '<br>';
      values += params.seriesName + ': ' + parseFloat(params.data).toFixed(2) + ' kWh <br>';
      return values;
    }
  },
  toolbox: {
    show: true,
    left: 'center',
    itemSize: 20,
    top: '0%',
    feature: {
      dataZoom: {
        show: true,
        yAxisIndex: true,
        title: { zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar' }
      },
      restore: { show: true, title: 'Anular Todo' },
      saveAsImage: { title: 'Descargar' }
    }
  },
  xAxis: [{
    type: 'category'
  }],
  grid: { left: '8%', right: '5%', bottom: '25%' },
  yAxis: {
    name: 'kWh',
    gridIndex: 0,
    axisLabel: {
      inside: false,
      formatter: '{value}',
      fontSize: 10
    }
  },
  dataZoom: [
    {
      type: 'inside'
    }
  ],
  series: []
};


export const ACTIVE_AREA_ENERGY_CHART_OPTIONS = {
  animation: true,
  legend: {
    show: false,
  },
  tooltip: {
    show: true,
    triggerOn: 'mousemove',
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    show: true,
    left: 'center',
    itemSize: 20,
    top: '0%',
    feature: {
      dataZoom: {
        show: true,
        yAxisIndex: true,
        title: { zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar' }
      },
      restore: { show: true, title: 'Anular Todo' },
      saveAsImage: { title: 'Descargar' }
    }
  },
  xAxis: [{
    type: 'time',
    axisLabel: {
      fontSize: 10,
      formatter: (function (value) {
        const time = new Date(value);
        const resp = time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', {
          hour: '2-digit',
          minute: '2-digit'
        });
        return resp;
      })
    },
    splitLine: {
      show: false,
    }
  }],
  yAxis: {
    type: 'value',
    name: 'kWh',
    axisTick: {
      inside: false
    },
    splitLine: {
      show: false,
      lineStyle: {
        opacity: 0.2
      }
    },
    axisLabel: {
      inside: false,
      formatter: '{value}',
      fontSize: 10
    },
    z: 10
  },
  grid: {
    left: '5%',
    right: '5%',
    bottom: '25%'
  },
  dataZoom: [{
    show: true,
    height: 40,
    type: 'slider',
    bottom: '0%',
    xAxisIndex: [0],
  },
  {
    type: 'inside'
  }
  ],
  series: []
};



export const ACTIVE_BAR_ECONOMIC_CHART_OPTIONS = {
  legend: {
    show: false,
    bottom: '0%',
    type: 'scroll'
  },
  tooltip: {
    trigger: 'item',
    textStyle: {
      fontSize: 10,
      width: 500
    },
    formatter: (params) => {
      let values = params.name + '<br>';
      values += params.seriesName + ': ' + parseFloat(params.data).toFixed(2) + ' € <br>';
      return values;
    }
  },
  toolbox: {
    show: true,
    left: 'center',
    itemSize: 20,
    top: '0%',
    feature: {
      dataZoom: {
        show: true,
        yAxisIndex: true,
        title: { zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar' }
      },
      restore: { show: true, title: 'Anular Todo' },
      saveAsImage: { title: 'Descargar' }
    }
  },
  xAxis: [{
    type: 'category'
  }],
  grid: { left: '8%', right: '5%', bottom: '25%' },
  yAxis: {
    name: '€',
    gridIndex: 0,
    axisLabel: {
      inside: false,
      formatter: '{value}',
      fontSize: 10
    }
  },
  dataZoom: [
    {
      type: 'inside'
    }
  ],
  series: []
};




export const ACTIVE_AREA_ECONOMIC_CHART_OPTIONS = {
  animation: true,
  legend: {
    show: false,
  },
  tooltip: {
    show: true,
    triggerOn: 'mousemove',
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    show: true,
    left: 'center',
    itemSize: 20,
    top: '0%',
    feature: {
      dataZoom: {
        show: true,
        yAxisIndex: true,
        title: { zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar' }
      },
      restore: { show: true, title: 'Anular Todo' },
      saveAsImage: { title: 'Descargar' }
    }
  },
  xAxis: [{
    type: 'time',
    axisLabel: {
      fontSize: 10,
      formatter: (function (value) {
        const time = new Date(value);
        const resp = time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', {
          hour: '2-digit',
          minute: '2-digit'
        });
        return resp;
      })
    },
    splitLine: {
      show: false,
    }
  }],
  yAxis: {
    type: 'value',
    name: '€',
    axisTick: {
      inside: false
    },
    splitLine: {
      show: false,
      lineStyle: {
        opacity: 0.2
      }
    },
    axisLabel: {
      inside: false,
      formatter: '{value}',
      fontSize: 10
    },
    z: 10
  },
  grid: {
    left: '5%',
    right: '5%',
    bottom: '25%'
  },
  dataZoom: [{
    show: true,
    height: 40,
    type: 'slider',
    bottom: '0%',
    xAxisIndex: [0],
  },
  {
    type: 'inside'
  }
  ],
  series: []
};




/**
 * Charts legends
 */
export const ACTIVE_ENERGY_LEGENDS = [
  {
    name: ACTIVE_ENERGY_SERIES_DATA.CONSUMPTION.label,
    p: [
      chartColors.periods.consumoP1,
      chartColors.periods.consumoP2,
      chartColors.periods.consumoP3,
      chartColors.periods.consumoP4,
      chartColors.periods.consumoP5,
      chartColors.periods.consumoP6
    ]
  },
  {
    name: ACTIVE_ENERGY_SERIES_DATA.NETWORK_CONSUMPTION.label,
    p: [
      chartColors.periods.consumo_redP1,
      chartColors.periods.consumo_redP2,
      chartColors.periods.consumo_redP3,
      chartColors.periods.consumo_redP4,
      chartColors.periods.consumo_redP5,
      chartColors.periods.consumo_redP6,
    ]
  },
  {
    name: ACTIVE_ENERGY_SERIES_DATA.SELF_CONSUMPTION.label,
    p: [
      chartColors.periods.autoconsumoP1,
      chartColors.periods.autoconsumoP2,
      chartColors.periods.autoconsumoP3,
      chartColors.periods.autoconsumoP4,
      chartColors.periods.autoconsumoP5,
      chartColors.periods.autoconsumoP6
    ]
  },
  {
    name: ACTIVE_ENERGY_SERIES_DATA.EXCEDENTARY.label,
    p: [
      chartColors.periods.excedenteP1,
      chartColors.periods.excedenteP2,
      chartColors.periods.excedenteP3,
      chartColors.periods.excedenteP4,
      chartColors.periods.excedenteP5,
      chartColors.periods.excedenteP6
    ]
  },
  {
    name: ACTIVE_ENERGY_SERIES_DATA.GENERATED.label,
    p: [
      chartColors.periods.netaGeneradaP1,
      chartColors.periods.netaGeneradaP2,
      chartColors.periods.netaGeneradaP3,
      chartColors.periods.netaGeneradaP4,
      chartColors.periods.netaGeneradaP5,
      chartColors.periods.netaGeneradaP6
    ]
  }
];




export const ACTIVE_ECONOMIC_LEGENDS = [
  {
    name: ACTIVE_ECONOMIC_SERIES_DATA.BILLING.label,
    p: [
      chartColors.periods.billingP1,
      chartColors.periods.billingP2,
      chartColors.periods.billingP3,
      chartColors.periods.billingP4,
      chartColors.periods.billingP5,
      chartColors.periods.billingP6
    ]
  },
  {
    name: ACTIVE_ECONOMIC_SERIES_DATA.SELFCONSUMPTIONSAVING.label,
    p: [
      chartColors.periods.selfconsumptionsavingP1,
      chartColors.periods.selfconsumptionsavingP2,
      chartColors.periods.selfconsumptionsavingP3,
      chartColors.periods.selfconsumptionsavingP4,
      chartColors.periods.selfconsumptionsavingP5,
      chartColors.periods.selfconsumptionsavingP6,
    ]
  },
  {
    name: ACTIVE_ECONOMIC_SERIES_DATA.COMPENSATION.label,
    p: [
      chartColors.periods.compensationP1,
      chartColors.periods.compensationP2,
      chartColors.periods.compensationP3,
      chartColors.periods.compensationP4,
      chartColors.periods.compensationP5,
      chartColors.periods.compensationP6,
    ]
  },
  {
    name: ACTIVE_ECONOMIC_SERIES_DATA.WITHOUTCOMPENSATION.label,
    p: [
      chartColors.periods.withoutcompensationP1,
      chartColors.periods.withoutcompensationP2,
      chartColors.periods.withoutcompensationP3,
      chartColors.periods.withoutcompensationP4,
      chartColors.periods.withoutcompensationP5,
      chartColors.periods.withoutcompensationP6,
    ]
  },
  {
    name: ACTIVE_ECONOMIC_SERIES_DATA.COMPENSABLE.label,
    p: [
      chartColors.periods.compensableP1,
      chartColors.periods.compensableP2,
      chartColors.periods.compensableP3,
      chartColors.periods.compensableP4,
      chartColors.periods.compensableP5,
      chartColors.periods.compensableP6,
    ]
  }
];


/**
 * Reactive energy data sources
 */
export const ACTIVE_ENERGY_DATASOURCES = [
  {
    'id': 0,
    'viewValue': 'Calculated'
  },
  {
    'id': 1,
    'viewValue': 'Meter'
  },
  {
    'id': 2,
    'viewValue': 'Inverter'
  },
  {
    'id': 3,
    'viewValue': 'Zero Injection'
  }
];

