import { Component, OnInit} from '@angular/core';
import { MeterService } from '../../../../../projects/pplus/src/lib/components/elements/meter/meter.service';

@Component({
  selector: 'app-selfview-meter-generated',
  templateUrl: './selfview-meter-generated.component.html',
  styleUrls: ['./selfview-meter-generated.component.scss'],
  providers: [MeterService]
})
export class SelfviewMeterGeneratedComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }
}
