import { TotalsChartColors } from "./totals-chart.colors";

export const REACTIVE_TOTAL_CHART_SERIES = [
  {
    name: 'Capacitivo',
    type: 'bar',
    data: [],
    xAxisIndex: 0,
    color: TotalsChartColors.capacitivo,
    barWidth: 5,
    stack: 'a'
  },
  {
    name: 'Inductivo',
    type: 'bar',
    data: [],
    xAxisIndex: 0,
    color: TotalsChartColors.inductivo,
    barWidth: 5,
    stack: 'a'
  }
];

export const REACTIVE_TOTAL_CHART_SERIES_COMPARED = [
  {
    name: 'Capacitivo*',
    type: 'bar',
    data: [],
    xAxisIndex: 0,
    color: TotalsChartColors.capacitivoCompared,
    barWidth: 3,
    stack: 'b'
  },
  {
    name: 'Inductivo*',
    type: 'bar',
    data: [],
    xAxisIndex: 0,
    color: TotalsChartColors.inductivoCompared,
    barWidth: 3,
    stack: 'b'
  }
];
