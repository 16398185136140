import { Component, OnInit } from '@angular/core';
import { PVSelfCalculation } from '../../../models/PvSelfCalculation.model';
import { PVSelfCalculationService } from '../../../services/pvself-calculation.service';
import { PVSelfEngineService } from '../../../services/pvself-engine.service';
import { PVSelfEconomicSummaryResults } from '../../../models/PVSelfResultsModel.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pplus-pvself-economic-summary',
  templateUrl: './pvself-economic-summary.component.html',
  styleUrls: ['./pvself-economic-summary.component.scss']
})
export class PVSelfEconomicSummaryComponent implements OnInit {
  _calculation: PVSelfCalculation;
  _economicSummaryResults: PVSelfEconomicSummaryResults;
  loaderId: string;
  gridApi;

  columnsEconomicSummary = [
    {
      headerName: 'Costes y ahorros mensuales', resizable: true,
      children: [
        {
          headerName: 'Consumo', resizable: true,
          children: [
            { headerName: 'Mes', field: 'month', resizable: true },
            { headerName: 'Facturación antes', field: 'billingBeforeSelfConsumption', resizable: true, valueFormatter: this.numberFormatter },
            { headerName: 'Facturación después', field: 'billingAfterSelfConsumption', resizable: true, valueFormatter: this.numberFormatter },
            { headerName: 'Ahorro por autoconsumo', field: 'selfConsumptionSavingWCompensation', resizable: true, valueFormatter: this.numberFormatter },
            { headerName: 'Cobertura (energía)', field: 'energyCoverage', resizable: true, valueFormatter: this.numberFormatter },
            { headerName: 'Cobertura (euros)', field: 'coverage', resizable: true, valueFormatter: this.numberFormatter },
          ]
        },
        {
          headerName: 'Producción', resizable: true,
          children: [
            { headerName: 'Venta de energía', field: 'energySale', resizable: true, valueFormatter: this.numberFormatter },
          ]
        },
        {
          headerName: 'Balance', resizable: true,
          children: [
            { headerName: 'Ahorro por autoconsumo', field: 'selfConsumptionSavingWCompensation', resizable: true, valueFormatter: this.numberFormatter },
            { headerName: 'Venta de energía', field: 'energySale', resizable: true, valueFormatter: this.numberFormatter },
            { headerName: 'Balance', field: 'totalBalance', resizable: true, valueFormatter: this.numberFormatter },
          ]
        },
        // { headerName: 'Ingresos por energía', field: 'selfConsumptionIncome', resizable: true, valueFormatter: this.numberFormatter },
        // { headerName: 'Ahorro total', field: 'totalSaving', resizable: true, valueFormatter: this.numberFormatter }
      ]
    }
  ];

  constructor(private calculationService: PVSelfCalculationService,
    private ngxLoaderService: NgxUiLoaderService,
    private engineService: PVSelfEngineService,
    private translateService: TranslateService) { }

  ngOnInit() {
    this.loaderId = 'loader-economic-' + Math.floor((Math.random() * 100) + 1);
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
    //    this._calculation = this.calculationService.get();

  }


  calcular() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.engineService.calculateEconomicSummaryResults(this._calculation).subscribe(res => {
      this._economicSummaryResults = res;

      this._economicSummaryResults.monthlySummary[0].month = this.translateService.instant('COMMON.MONTHS.JANUARY');
      this._economicSummaryResults.monthlySummary[1].month = this.translateService.instant('COMMON.MONTHS.FEBRUARY');
      this._economicSummaryResults.monthlySummary[2].month = this.translateService.instant('COMMON.MONTHS.MARCH');
      this._economicSummaryResults.monthlySummary[3].month = this.translateService.instant('COMMON.MONTHS.APRIL');
      this._economicSummaryResults.monthlySummary[4].month = this.translateService.instant('COMMON.MONTHS.MAY');
      this._economicSummaryResults.monthlySummary[5].month = this.translateService.instant('COMMON.MONTHS.JUNE');
      this._economicSummaryResults.monthlySummary[6].month = this.translateService.instant('COMMON.MONTHS.JULY');
      this._economicSummaryResults.monthlySummary[7].month = this.translateService.instant('COMMON.MONTHS.AUGUST');
      this._economicSummaryResults.monthlySummary[8].month = this.translateService.instant('COMMON.MONTHS.SEPTEMBER');
      this._economicSummaryResults.monthlySummary[9].month = this.translateService.instant('COMMON.MONTHS.OCTOBER');
      this._economicSummaryResults.monthlySummary[10].month = this.translateService.instant('COMMON.MONTHS.NOVEMBER');
      this._economicSummaryResults.monthlySummary[11].month = this.translateService.instant('COMMON.MONTHS.DECEMBER');
      this._economicSummaryResults.monthlySummary[12].month = this.translateService.instant('COMMON.TOTAL');

      this.ngxLoaderService.stopLoader(this.loaderId);
    },
      err => {
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;

    //    this.gridApi.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        //        this.gridApi.sizeColumnsToFit();
      });
    });
  }

  numberFormatter(params) {
    return (params.value).toFixed(2);
  }





}
