import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { map } from 'rxjs/operators';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { AppGroupService } from '../app-group.service';
import { EntitiesService } from '../../entities/entities.service';
import { AppService } from '../../application/app.service';
import { forkJoin } from 'rxjs';
import { IEntityAppGroup } from 'projects/pplus/src/lib/interfaces/interfaces';
import { AdminService } from '../../admin.service';
import { AppGroupDetailsService } from './app-group-details.service';

@Component({
  selector: 'app-app-group-details',
  templateUrl: './app-group-details.component.html',
  styleUrls: ['./app-group-details.component.scss']
})
export class AppGroupDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  data: any;

  formGroup: FormGroup;
  formData: DynamicForm[];

  constructor(
    private dataRow: RowDataService,
    private appGroupService: AppGroupService,
    private entitiesService: EntitiesService,
    private appService: AppService,
    private dialog: NbDialogService,
    private adminService: AdminService,
    private appGroupDetailsService: AppGroupDetailsService,
  ) {
    this.formData = this.appGroupDetailsService.getDynamicForm();
  }

  generateForm(data: DynamicForm []) {
    const fieldsCtrls = {};
    data.forEach(item => {
      const fields = item.fields;
      fields.forEach(f => {
        fieldsCtrls[f.name] = new FormControl(f.value || '', f.validators);
      });
    });
    this.formGroup = new FormGroup(fieldsCtrls);
  }

  ngOnInit() {
    forkJoin([
      this.entitiesService.getEntitiesList(),
      this.appService.getAppsList(),
    ]).subscribe(([entities, applications]) => {
      this.formData.map(o => {
        const fields = o.fields;
        fields.forEach(f => {
          if (f.name === 'entities') {
            const opts = [];
            entities.map(o => {
              opts.push({
                value: o.entityId,
                label: o.code,
              });
            });
            f.options = opts;
          }
          else
          if (f.name === 'applications') {
            const opts = [];
            applications.map(o => {
              opts.push({
                value: o.applicationId,
                label: o.name,
              });
            });
            f.options = opts;
          }
        });
      });
      this.generateForm(this.formData);
    });

    this.dataRow.currentRowData.pipe(map(o => {
      this.formData.forEach(data => {
        const fields = data.fields;
        fields.map(field => {
          if (field.name === 'entities') {
            const entities = o['entityApplicationGroups'];
            const value = [];
            if (entities && entities.length) for (const e of entities) value.push(e.entityId);
            field.value = value;
          }
          else
          if (field.name === 'applications') {
            const apps = o['applicationGroupApplications'];
            const value = [];
            if (apps && apps.length) for (const app of apps) value.push(app.applicationId);
            field.value = value;
          }
          else field.value = o[field.name];
        });
      });
      return o;
    })).subscribe(data => {
      this.data = data;
      this.generateForm(this.formData);
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.appGroupService.createAppGroup(data).subscribe(newAppGroup => {
        if (newAppGroup !== null) {
          this.data.applicationGroupId = newAppGroup.applicationGroupId;
          this.formGroup.patchValue({applicationGroupId: this.data.applicationGroupId});
          forkJoin([
            this.appGroupService.assignEntities(newAppGroup.applicationGroupId, this.formGroup.value.entities),
            this.appGroupService.assignApps(newAppGroup.applicationGroupId, this.formGroup.value.applications),
          ]).subscribe(success => {
            if (success.every(b => b)) {
              this.adminService.showToast('success',
                  'Se ha creado el nuevo grupo de aplicaciones: ' + data.name,
                  'Elemento creado');
              this.updateDelete.emit(true);
            } else {
              this.adminService.showToast('danger',
                  'Se ha producido un error al asignar applicaciones y/o entidades al nuevo grupo: ' + data.name,
                  'Error');
            }
          });
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al crear el grupo de aplicaciones: ' + data.name,
              'Error');
        }
      });
    }
  }

  update() {
    if (this.data.applicationGroupId == null) {
      this.onSubmit();
    } else if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IEntityAppGroup>{
        name: this.formGroup.value.name,
        description: this.formGroup.value.description,
        activated: Boolean(this.formGroup.value.activated),
      };
      forkJoin([
        this.appGroupService.updateAppGroup(this.data.applicationGroupId, data),
        this.appGroupService.assignEntities(this.data.applicationGroupId, this.formGroup.value.entities),
        this.appGroupService.assignApps(this.data.applicationGroupId, this.formGroup.value.applications),
      ]).subscribe(success => {
        if (success.every(b => b)) {
          this.adminService.showToast('success',
              'Se ha actualizado el grupo de aplicaciones: ' + data.name,
              'Elemento actualizado');
          this.updateDelete.emit();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al actualizar el grupo de aplicaciones: ' + data.name,
              'Error');
        }
      });
    }
  }

  close() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  delete() {
    if (this.data.applicationGroupId == null) {
      this.adminService.showToast('info', 'Seleccione un grupo de aplicaciones', 'Info');
    } else {
      this.appGroupService.deleteAppGroup(this.data.applicationGroupId).subscribe(success => {
        if (success) {
          this.adminService.showToast('success',
              'Se ha borrado el grupo de aplicaciones: ' + this.data.name,
              'Elemento eliminado');
          this.updateDelete.emit();
          this.clearData();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al borrar el grupo de aplicaciones: ' + this.data.name,
              'Error');
        }
      });
    }
  }

  copy() {
    if (this.data.applicationGroupId == null) {
      this.adminService.showToast('info', 'Seleccione un grupo de aplicaciones para copiar la información', 'Info');
    } else {
      this.formGroup.setValue(Object.assign(this.data, this.formGroup.value, {
        applicationGroupId: null,
        name: '',
      }));
    }
  }

  clearData() {
    this.data = {};
    this.formGroup.reset();
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.applicationGroupId == null) {
      this.adminService.showToast('info', 'No hay ningún grupo de aplicaciones seleccionado', 'Info');
    } else {
      this.dialog.open(dialog, { context: '¿Desea eliminar el grupo de aplicaciones?', hasScroll: false });
    }
  }

}
