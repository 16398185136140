import { Injectable } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { Application } from '../models/application.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  private application = new BehaviorSubject(<string>null);
  currentApplication = this.application.asObservable();

  constructor(private platformLocation: PlatformLocation,
    private httpClient: HttpClient) {
    const app = this.transformToCodeApplication((platformLocation as any).location.pathname);
    this.application.next(app);
  }

  searchByDescription(description: string): Observable<Application[]> {
    return this.httpClient.get<Application[]>(environment.API_URL + '/api/application/searchByDescription?description=' + description).pipe(
      map(data => data));
  }

  transformToCodeApplication(pathname: string): string {
    switch (pathname) {
      case '/app/admin':
        return 'Admin';
      case '/app/pvself':
        return 'Pv-Self';
      case '/app/selfview':
        return 'Self-View';
      case '/app/sizelite':
        return 'sizelite';
      default:
        return 'Dashboard';
    }
  }
}
