export class PhotonDBManufacturer {
    name: string;
    existsInDB: boolean;
}


export class PhotonDBResponse {
    numUpdates: number;
    numInserted: number;
    numErrors: number;

    constructor() {
        this.numErrors = 0;
        this.numInserted = 0;
        this.numUpdates = 0;
    }
}