import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MeterService } from '../../meter.service';
import { CosPhiChartColors } from '../cosphi.graph.colors';
import { Subscription } from 'rxjs';
import { DataSetMeterHistoricEnergy, CosPhiSeries } from '../../meter.models';

@Component({
  selector: 'pplus-cosphi-chart',
  templateUrl: './cosphi-chart.component.html',
  styleUrls: ['./cosphi-chart.component.scss']
})
export class CosphiChartComponent implements OnInit, OnDestroy {

  @Input() title;

  private subscriptions: Subscription = new Subscription();

  data: CosPhiSeries;

  initOpts = {
    renderer: 'svg',
    width: 'auto',
    height: 'auto'
  };

  chartOptions;

  constructor(
    private meterService: MeterService
  ) { }

  ngOnInit() {
    this.subscriptions = this.meterService.dataCosphi$.subscribe((data: CosPhiSeries) => {
      this.data = data;
      if (data) {
        this.initChart();
      }
    });
  }

  initChart() {
    if (this.data) {


      this.chartOptions = {
        legend: {
          show: true,
          bottom: '0'
        },
        tooltip: {
          trigger: 'item',
          textStyle: {
            fontSize: 12,
            width: 500,
            color: '#fff'
          },
          formatter: (params) => {
            const values = `
              ${params.name}<br/>
              ${params.data[1]} ${params.seriesName}<br/>
            `;

            return values;
          }
        },
        toolbox: {
          show: true,
          left: 'center',
          itemSize: 20,
          top: '0%',
          feature: {
            saveAsImage: {title: 'Descargar'}
          }
        },
        xAxis: [{
          type: 'category',
        }],
        yAxis: {
          min: -1,
          max: 1,
          gridIndex: 0,
          axisLabel: {
            inside: false,
            formatter: '{value}',
            fontSize: 10
          }
        },
        series: [
          {
            name: 'Capacitivo',
            type: 'bar',
            data: this.data.capacitivo,
            xAxisIndex: 0,
            color: CosPhiChartColors.capacitivo,
            barWidth: 10
          },
          {
            name: 'Inductivo',
            type: 'bar',
            data: this.data.inductivo,
            xAxisIndex: 0,
            color: CosPhiChartColors.inductivo,
            barWidth: 10
          },
        ]
      };
    }
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
