export const chart3Opts = {
    legend: {
        data: ['Cobertura [%]', 'Aprovechamiento [%]'],
        x: 'center',
        selected: null
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {}
    },
    toolbox: {
        show: false,
        feature: {
            magicType: { type: ['line', 'bar'], title: { line: 'Line', bar: 'Bar' } },
            saveAsImage: { title: 'Descargar' }
        }
    },    xAxis: {
        type: 'category'
    },
    yAxis: {
        type: 'value'
    },
    series: [
        {
            name: 'Cobertura [%]',
            type: 'line',
            data: []
        },
        {
            name: 'Aprovechamiento [%]',
            type: 'line',
            data: []
        }
    ]
};