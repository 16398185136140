import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatStepperModule, MatButtonToggleModule, MatSelectModule, MatListModule, MatSliderModule, MatRadioModule, MatInputModule, MatDatepickerModule, MatIconModule } from '@angular/material';
import { PVSelfProducerComponent } from './pvself-producer.component';
import { PPlusTabsModule, PPlusMapModule } from 'projects/pplus/src/public_api';
import { TranslateModule } from '@ngx-translate/core';
import { NbCardModule, NbPopoverModule } from '@nebular/theme';
import { PVSelfInstallationComponent } from './installation/pvself-installation.component';
import { PVSelfInstallationLocationComponent } from './installation/pvself-installation-location/pvself-installation-location.component';
import { PVSelfInstallationFieldComponent } from './installation/pvself-installation-field/pvself-installation-field.component';
import { FormsModule } from '@angular/forms';
import { NgxEchartsModule } from 'ngx-echarts';
import { PVSelfFieldListComponent } from './installation/pvself-field-list/pvself-field-list.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AgGridModule } from 'ag-grid-angular';
import { IsAuthorizedModule } from 'src/app/@core/_auth/directives/isAuthorized.module';

@NgModule({
  declarations: [PVSelfProducerComponent, PVSelfInstallationComponent, PVSelfInstallationLocationComponent, PVSelfInstallationFieldComponent, PVSelfFieldListComponent],
  imports: [
    CommonModule,
    MatStepperModule,
    PPlusTabsModule,
    NbCardModule,
    PPlusMapModule,
    MatButtonToggleModule,
    TranslateModule,
    FormsModule,
    MatSelectModule,
    NgxEchartsModule,
    MatListModule,
    NgxUiLoaderModule,
    MatSliderModule,
    MatRadioModule,
    MatInputModule,
    AgGridModule,
    MatDatepickerModule,
    NbPopoverModule,
    MatIconModule,
    IsAuthorizedModule
  ],
  exports: [
    PVSelfProducerComponent
  ]
})
export class PVSelfProducerModule { }
