import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Headers } from '@angular/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IDomainsStyles } from 'projects/pplus/src/lib/interfaces/interfaces';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StyleService {

  private _getStyle = environment.API_URL + '/api/entity/style';
  private _updateStyle = environment.API_URL + '/api/entity/style';

  private _getHeaders(): Headers {
    const header = new Headers({
      'Content-Type' : 'application/json',
      'Accept': 'application/json'
    });
    return header;
  }

  constructor(private http: HttpClient) { }

  getStyle(entityId: number) {
    return this.http
    .get(`${this._getStyle}/${entityId}`)
    .pipe(map((response) => {
      return <IDomainsStyles>response;
    }));
  }

  updateStyle(entityId: number, data: FormData): Observable<any> {
    return this.http.post(`${this._updateStyle}/${entityId}`, data);
  }
}
