export const mainChartBillingColors = [ '#61c194', '#54a77b', '#468b5f', '#3d7a50', '#32653f', '#244928'];
export const mainChartSavingColors = [ '#991865', '#6c153c', '#57152c', '#421221', '#33111a', '#210f11'];
export const mainChartEnergySaleColors = [ '#d83632', '#b93432', '#9c2727', '#7f2020', '#651c1c', '#501818'];

export let _periodChartColors = [mainChartBillingColors[0], mainChartSavingColors[0], mainChartEnergySaleColors[0]];

export let periodChartOpts = {
    tooltip: {
        confine: false,
        trigger: 'axis',
        position: function (pos, params, dom, rect, size) {
            const obj = { top: 60 };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
            return obj;
        },
        axisPointer: {
            type: 'shadow'
        },
        formatter: params => {
            let values = '';
            for (const param of params) {
                const isDefined = typeof (param) === 'undefined';
                if (!isDefined) {

                    values += param.seriesName + ': ' + parseFloat(param.value).toFixed(2) + ' € <br>';
                }
            }

            return values;

        }
    },
    legend: {
        data: ['P1', 'P2', 'P3', 'P4', 'P5', 'P6']
    },
    grid: {
        top: '15%',
        left: '15%',
        right: '4%',
        bottom: '10%',
    },
    xAxis: {
        type: 'value',
        axisLabel: {
            show: true,
            formatter: '{value} kWh',
            rotate: 35,
            fontSize: 10
        }
    },
    yAxis: {
        type: 'category',
        data: ['Facturación', 'Ahorro autoconsumo', 'Energía vencida'],
        axisLabel: {
            rotate: 35,
            fontSize: 10
        }
    },
    series: [
        {
            name: 'P1',
            type: 'bar',
            stack: 'A',
            label: {
                show: false,
                normal: {
                    show: false,
                    position: 'insideRight'
                }
            },
            itemStyle: { color: '#948732' },
            barWidth: 10,
            data: 0
        },
        {
            name: 'P2',
            type: 'bar',
            stack: 'A',
            label: {
                show: false,
                normal: {
                    show: false,
                    position: 'insideRight'
                }
            },
            itemStyle: { color: '#292389' },
            barWidth: 10,
            data: 0
        },
        {
            name: 'P3',
            type: 'bar',
            stack: 'A',
            label: {
                show: false,
                normal: {
                    show: false,
                    position: 'insideRight'
                }
            },
            itemStyle: { color: '#194724' },
            barWidth: 10,
            data: 0
        },
        {
            name: 'P4',
            type: 'bar',
            stack: 'A',
            label: {
                show: false,
                normal: {
                    show: false,
                    position: 'insideRight'
                }
            },
            itemStyle: { color: '#109479' },
            barWidth: 10,
            data: 0
        },
        {
            name: 'P5',
            type: 'bar',
            stack: 'A',
            label: {
                show: false,
                normal: {
                    show: false,
                    position: 'insideRight'
                }
            },
            itemStyle: { color: '#593927' },
            barWidth: 10,
            data: 0
        },
        {
            name: 'P66',
            type: 'bar',
            stack: 'A',
            label: {
                show: false,
                normal: {
                    show: false,
                    position: 'insideRight'
                }
            },
            itemStyle: { color: '#237953' },
            barWidth: 10,
            data: 0
        }
    ]

};