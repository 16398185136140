import { Component, AfterViewInit, Input } from '@angular/core';
import { StiReportsService } from '../../../services/sti-reports.service';
import { environment } from 'src/environments/environment';

declare var Stimulsoft: any;


@Component({
  selector: 'lib-pplus-report-viewer',
  templateUrl: './pplus-report-viewer.component.html',
  styleUrls: ['./pplus-report-viewer.component.scss']
})
export class PPlusReportViewerComponent implements AfterViewInit {
//  @Input('reportFile') reportFile: string;
  @Input('report') report: any;
  @Input('data') data: any;

  viewerId = 'viewer_id';

  constructor(private stiReportsService: StiReportsService) { }

  ngAfterViewInit(): void {
    this.showViewer();

  }


  private showViewer(): void {

    Stimulsoft.Base.StiLicense.key = environment.STIMULSOFTKEY;

    const options = new Stimulsoft.Viewer.StiViewerOptions();
    options.toolbar.showPrintButton = true;
    options.toolbar.showSaveButton = true;
    options.toolbar.showOpenButton = false;

    const viewer = new Stimulsoft.Viewer.StiViewer(options, 'StiViewer', false);
    const report = new Stimulsoft.Report.StiReport();
    report.load(this.report);
    const dataSet = new Stimulsoft.System.Data.DataSet();
    dataSet.readJson(this.data);
    report.regData('', '', dataSet);
    viewer.report = report;
    viewer.renderHtml(this.viewerId);
  }

}
