import { Component, OnInit, Input } from '@angular/core';
import { PVSelfCalculationConsumer, PVSelfCalculation } from 'src/app/modules/pvself/models/PvSelfCalculation.model';
import { PVSelfEngineService } from 'src/app/modules/pvself/services/pvself-engine.service';
import { PVSelfCalculationService } from 'src/app/modules/pvself/services/pvself-calculation.service';

@Component({
  selector: 'app-chargeCurve',
  templateUrl: './chargeCurve.component.html',
  styleUrls: ['./chargeCurve.component.scss']
})
export class ChargeCurveComponent implements OnInit {
  @Input('consumer') _consumer: PVSelfCalculationConsumer;

  _calculation: PVSelfCalculation;

  consumptionExample = [];

  chartOptions: any;

  chartInitOptions = {
    renderer: 'svg',
    width: null,
    height: 400
  };

  constructor(private calculationService: PVSelfCalculationService,
    private engineService: PVSelfEngineService) {
  }

  ngOnInit() {

    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
      this.initChartOptions();

      if (this._calculation.consumers[0].consumptionCurve == null) {
        this.seedData();
      } else {

        this.engineService.getConsumptionFromExcelData(this._calculation.consumers[0].consumptionCurve).subscribe(data => {
          this.chartOptions.series[0].data = data;
          this.chartOptions = { ...this.chartOptions, ...this.chartOptions };
        });
      }
    });

  }




  initChartOptions() {
    this.chartOptions = {
      animation: true,
      legend: {
        x: 'center'
      },
      tooltip: {
        triggerOn: 'mousemove',
        trigger: 'axis'
      },
      xAxis: {
        type: 'category',
        splitLine: {
          show: true
        }
      },
      yAxis: {
        type: 'value',
        axisTick: {
          inside: true
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          inside: true,
          formatter: '{value}\n'
        },
        z: 10
      },
      grid: {
        top: 50,
        left: 50,
        right: 50,
        bottom: 100
      },
      series: [
        {
          name: 'Consumo',
          type: 'line',
          stack: 'b',
          smooth: true,
          symbol: 'square',
          symbolSize: 5,
          sampling: 'average',
          itemStyle: {
            normal: {
              color: '#0a9109'
            }
          },
          data: this.consumptionExample
        }
      ]
    };
  }


  onFileChange(event) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
      reader.onload = this.handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
    }
  }


  handleReaderLoaded(e) {
    const reader = e.target;
    const excelFileContent = reader.result;
    this._consumer.consumptionCurve = excelFileContent;

    this.engineService.getConsumptionFromExcelData(excelFileContent).subscribe(res => {
      this.chartOptions.series[0].data = res;
      this.chartOptions = { ...this.chartOptions, ...this.chartOptions };
    });


  }


  seedData() {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    for (let i = 0; i < 11; i++) {
      const currentMonth = monthNames[i];
      const randomNumber = Math.floor(Math.random() * 31) - 10;
      this.consumptionExample.push([currentMonth, randomNumber]);
    }
  }
}
