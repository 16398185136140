import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GoogleStorageFile } from '../models/google.model';
import { map } from 'rxjs/operators';
import { PhotonDBManufacturer, PhotonDBResponse } from '../models/photonDB.model';

@Injectable({
  providedIn: 'root'
})
export class PhotondbService {

  constructor(private httpClient: HttpClient) {
  }

  getStorageDBFiles(): Observable<GoogleStorageFile[]> {
    return this.httpClient.get<GoogleStorageFile[]>(environment.API_URL + '/api/photondb/getStorageDBFiles').pipe(
      map(data => data));
  }

  getManufacturersFromDBFile(file: string): Observable<PhotonDBManufacturer[]> {
    return this.httpClient.get<PhotonDBManufacturer[]>(environment.API_URL + '/api/photondb/getManufacturersFromDBFile?path=' + file).pipe(
      map(data => data));
  }


  updateDB(file: string, manufacturer: string): Observable<PhotonDBResponse> {
    return this.httpClient.get<PhotonDBResponse>(environment.API_URL + '/api/photondb/updateElementsFromDBFile?path=' + file + '&manufacturer=' + manufacturer).pipe(
      map(data => data));
  }



}
