import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { INSTALLATION_STRINGS_COL_DEFS } from './installation-strings.model';
import { IInstallationString } from 'projects/pplus/src/lib/interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class InstallationStringsService {
  private readonly apiUrl = environment.API_URL + '/api/installation/string/';
  private readonly apiScrollInfinite = environment.API_URL + '/api/installation/get-Strings';

  constructor(private http: HttpClient) {}

  getInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const body = { startRow, endRow, sortModel, filterModel };
    return this.http.post(this.apiScrollInfinite, body).pipe(
      map(response => <any>response),
      catchError(() => of({
        allInstallationStrings: [],
        lastRow: 0,
      }))
    );
  }

  getGeneralFilter(value: string, startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of INSTALLATION_STRINGS_COL_DEFS) columnList.push(colDef.field);
    return this.http.post(this.apiScrollInfinite, {
      startRow, endRow, sortModel, filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel,
      },
      columnList,
    });
  }

  getAll(): Observable<IInstallationString[]> {
    return this.http.get(this.apiUrl).pipe(
      map(response => <IInstallationString[]>response),
      catchError(() => of([]))
    );
  }

  get(installationStringId: number): Observable<IInstallationString> {
    return this.http.get(this.apiUrl + installationStringId).pipe(
      map(response => <IInstallationString>response),
      catchError(() => of(null))
    );
  }

  create(body: IInstallationString): Observable<IInstallationString> {
    return this.http.post(this.apiUrl, body, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? <IInstallationString>response.body : null),
      catchError(() => of(null))
    );
  }

  update(installationStringId: number, body: IInstallationString): Observable<boolean> {
    return this.http.put(this.apiUrl + installationStringId, body, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  delete(installationStringId: number): Observable<boolean> {
    return this.http.delete(this.apiUrl + installationStringId, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

}
