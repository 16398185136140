import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { SelfViewService } from '../../services/selfview-general.service';
import { ACTIVE_ENERGY_SERIES_DATA, PERIODS, ACTIVE_ECONOMIC_SERIES_DATA } from './selfview-active-main.series';
import { GlobalType, IPeriod } from '../../models/selfview.model';
import { EChartOption } from 'echarts';
import {
  ACTIVE_ENERGY_DATASOURCES, ACTIVE_ENERGY_LEGENDS, ACTIVE_BAR_ENERGY_CHART_OPTIONS, ACTIVE_AREA_ENERGY_CHART_OPTIONS,
  ACTIVE_ECONOMIC_LEGENDS, ACTIVE_BAR_ECONOMIC_CHART_OPTIONS, ACTIVE_AREA_ECONOMIC_CHART_OPTIONS
} from './selfview-active-main.config';
import { Subscription } from 'rxjs';
import { PPlusChartType } from 'projects/pplus/src/lib/models/pplus.enums';
import { chartColors } from '../../selfview.graph.colors';
import { DateMoment } from 'projects/pplus/src/lib/components/date-range/date-range.component';
import { SelfViewActiveEnergyResults } from '../../models/selfview-active.model';

@Component({
  selector: 'pplus-selfview-active-main',
  templateUrl: './selfview-active-main.component.html',
  styleUrls: ['./selfview-active-main.component.scss']
})
export class SelfviewActiveMainComponent implements OnInit, OnDestroy {
  @Output() changeDatasources = new EventEmitter<any>();
  @Output() getPeriodCompare = new EventEmitter<any>();
  @Output() updateType = new EventEmitter<GlobalType>();


  GlobalType = GlobalType;
  ACTIVE_ENERGY_SERIES_DATA = ACTIVE_ENERGY_SERIES_DATA;
  ACTIVE_ECONOMIC_SERIES_DATA = ACTIVE_ECONOMIC_SERIES_DATA;
  energyEchartsInstance: any;
  economicEchartsInstance: any;
  comparePeriod = false;
  flipped: boolean;
  gridOptions: GridOptions;
  gridData: any;
  WhToKwh = 0.001;

  /**
  * Store local type selected
  */
  localType: GlobalType;

  /**
   * Store global type selected
   */
  globalType: GlobalType;


  /**
 * Store datasource selected for each energy
 */
  datasources = {
    'consumption': 1,
    'networkConsumption': 1,
    'selfConsumption': 1,
    'excedentary': 1,
    'netGenerated': 1,
  };

  /**
   * Store bar chart series visibility state
   */
  barEnergyChartState = {};
  barEconomicChartState = {};

  /**
   * Store areas chart series visibility state
   */
  areaEnergyChartState = {};
  areaEconomicChartState = {};

  // ENERGY DATASOURCES
  /**
   * Energy data source for datasource selects
   */
  energyDataSource = ACTIVE_ENERGY_DATASOURCES;


  /**
   * Main chart options
   */
  activeEnergyChartOptions: EChartOption;
  activeEconomicChartOptions: EChartOption;

  chartType: any;

  /**
   * Initial options for chart
   */
  initOpts = {
    renderer: 'svg',
    width: 'auto',
    height: 'auto'
  };


  /**
   * Initial options for counts elements
   */
  countEnergyOptions = {
    duration: 5,
    separator: ' ',
    suffix: ' kWh',
  };

  countEconomicOptions = {
    duration: 5,
    separator: ' ',
    suffix: ' €',
  };


  /**
   * Sets the data mode compare or not
   */
  compareMode: boolean;

  /**
   * Active Data from active service
   */
  activeData: SelfViewActiveEnergyResults;

  /**
   * Active Compare Data from active service
   */
  activeDataComparison: SelfViewActiveEnergyResults;
  periods: IPeriod[] = [];
  energyLegends: any;
  economicLegends: any;

  private globalTypeSubscription: Subscription;
  private dataSubscription: Subscription;
  private dataCompareSubscription: Subscription;


  constructor(private selfviewService: SelfViewService) {
    this.flipped = false;
    this.localType = GlobalType.Energy;

    this.initBarEnergyChartState();
    this.initAreaEnergyChartState();
    this.initBarEconomicChartState();
    //    this.initAreaEconomicChartState();
  }


  ngOnInit() {

    this.globalTypeSubscription = this.selfviewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;

      // Aquí se cambiara, el tipo desde el global
      this.dataSubscription = this.selfviewService.dataActive.subscribe((data: SelfViewActiveEnergyResults) => {
        if (data) {
          this.activeData = data; // data.activeData;
          this.chartType = this.activeData.chartType;

          this.showEnergyChartForGlobalType();
          this.showEconomicChartForGlobalType();

          // if (this.globalType === GlobalType.Energy) {
          //   this.showEnergyChartForGlobalType();
          // }
          // if (this.globalType === GlobalType.Economic) {
          //   this.showEconomicChartForGlobalType();
          // }
        }
      });

      this.dataCompareSubscription = this.selfviewService.dataActiveCompare.subscribe((data: SelfViewActiveEnergyResults) => {
        if (data) {
          this.activeDataComparison = data;
          this.comparePeriod = true;
          this.showEnergyChartComparisonForGlobalType();
        }
      });
    });

    this.energyLegends = ACTIVE_ENERGY_LEGENDS;
    this.economicLegends = ACTIVE_ECONOMIC_LEGENDS;
  }

  ngOnDestroy(): void {
    this.globalTypeSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
    this.dataCompareSubscription.unsubscribe();
  }



  /**
 * Determines whether datasources change on
 */
  onDatasourcesChange() {
    this.changeDatasources.emit(this.datasources);
  }


  /**
   * Inits bar chart state. Show All series
   */
  initBarEnergyChartState() {
    Object.keys(ACTIVE_ENERGY_SERIES_DATA).forEach((key) => {
      const serieName = ACTIVE_ENERGY_SERIES_DATA[key].label;

      Object.keys(PERIODS).forEach((period: string) => {
        this.barEnergyChartState[`${serieName} ${period}`] = true;
        this.barEnergyChartState[`${serieName} ${period}*`] = true;
      });
    });
  }



  /**
   * Inits bar chart state. Show All series
   */
  initBarEconomicChartState() {
    Object.keys(ACTIVE_ECONOMIC_SERIES_DATA).forEach((key) => {
      const serieName = ACTIVE_ECONOMIC_SERIES_DATA[key].label;

      Object.keys(PERIODS).forEach((period: string) => {
        this.barEconomicChartState[`${serieName} ${period}`] = true;
        this.barEconomicChartState[`${serieName} ${period}*`] = true;
      });
    });
  }



  /**
 * Hides bar chart compare series
 */
  hideBarEnergyChartCompareSeries() {
    Object.keys(ACTIVE_ENERGY_SERIES_DATA).forEach((key) => {
      const serieName = ACTIVE_ENERGY_SERIES_DATA[key].label;

      Object.keys(PERIODS).forEach((period: string) => {
        this.barEnergyChartState[`${serieName} ${period}`] = true;
        this.barEnergyChartState[`${serieName} ${period}*`] = false;
      });
    });
  }


  /**
 * Inits bar chart state
 */
  initAreaEnergyChartState() {
    Object.keys(ACTIVE_ENERGY_SERIES_DATA).forEach((key) => {
      const serieName = ACTIVE_ENERGY_SERIES_DATA[key].label;
      this.areaEnergyChartState[`${serieName}`] = true;
      this.areaEnergyChartState[`${serieName}*`] = true;
    });
  }

  // initAreaEconomicChartState() {
  //   Object.keys(ACTIVE_ECONOMIC_SERIES_DATA).forEach((key) => {
  //     const serieName = ACTIVE_ECONOMIC_SERIES_DATA[key].label;
  //     this.areaEconomicChartState[`${serieName}`] = true;
  //     this.areaEconomicChartState[`${serieName}*`] = true;
  //   });
  // }


  /**
   * Hides area chart compare serie
   */
  hideAreaEnergyChartCompareSerie() {
    Object.keys(ACTIVE_ENERGY_SERIES_DATA).forEach((key) => {
      const serieName = ACTIVE_ENERGY_SERIES_DATA[key].label;
      this.areaEnergyChartState[`${serieName}`] = true;
      this.areaEnergyChartState[`${serieName}*`] = false;
    });
  }


  /**
   * Called to show main chart.
   */
  showEnergyChartForGlobalType() {
    if (this.activeData !== null) {
      const data = this.activeData;

      if (!this.compareMode) {
        this.chartType = this.activeData.chartType;
        //        this.localType = GlobalType.Power;
        if (data.chartType === PPlusChartType.Bar) { // PPlusChartType['bar']) {
          this.initiateEnergyChartBar();
        } else {
          this.initiateEnergyChartArea();
          this.initiatePeriods(data.hourlyData);
        }
      }

      if (this.compareMode) {
        if (this.activeDataComparison !== null) {
          if (this.activeDataComparison.chartType === PPlusChartType.Bar) { // } PPlusChartType['bar']) {
            this.initiateEnergyChartBarComparison();
            this.activeEnergyChartOptions.legend.selected = this.barEnergyChartState;
            this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
          } else {
            this.initiateEnergyChartAreaComparison();
            this.activeEnergyChartOptions.legend.selected = this.areaEnergyChartState;
            this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
          }
        }
      }
    }
  }


  /**
   * Called to show main chart.
   */
  showEconomicChartForGlobalType() {
    if (this.activeData !== null) {
      const data = this.activeData;

      if (!this.compareMode) {
        this.chartType = this.activeData.chartType;
        //        this.initiateEconomicChartBar();

        //        this.localType = GlobalType.Economic;
        if (data.chartType === PPlusChartType.Bar) {
          this.initiateEconomicChartBar();
        } else {
          this.initiateEconomicChartArea();
          this.initiatePeriods(data.hourlyData);
        }
      }

      /*      if (this.compareMode) {
              if (this.activeDataComparison !== null) {
                if (this.activeDataComparison.chartType === PPlusChartType.bar) { // } PPlusChartType['bar']) {
                  this.initiateEnergyChartBarComparison();
                  this.activeEnergyChartOptions.legend.selected = this.barEnergyChartState;
                  this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
                } else {
                  this.initiateEnergyChartAreaComparison();
                  this.activeEnergyChartOptions.legend.selected = this.areaEnergyChartState;
                  this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
                }
              }
            }
          */
    }
  }


  /**
   * Shows chart comparison for global type
   * Called when enabled compare mode
   */
  showEnergyChartComparisonForGlobalType() {
    if (this.activeDataComparison) {
      this.localType = GlobalType.Energy;
      const data = this.activeDataComparison;

      this.initBarEnergyChartState();
      this.initAreaEnergyChartState();

      if (this.chartType === PPlusChartType['bar']) {
        this.activeEnergyChartOptions.legend.selected = this.barEnergyChartState;
      } else {
        this.activeEnergyChartOptions.legend.selected = this.areaEnergyChartState;
      }

      this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };

      if (data !== null && this.globalType === GlobalType.Energy) {
        if (data.chartType === PPlusChartType['bar']) {
          this.initiateEnergyChartBarComparison();
        } else {
          this.initiateEnergyChartAreaComparison();
        }
      } else if (data !== null && this.globalType === GlobalType.Economic) {
        // TODO: implementar parte económica
      }

    } else {
      if (this.comparePeriod === null) {
        this.hideBarEnergyChartCompareSeries();
        this.hideAreaEnergyChartCompareSerie();
        if (this.chartType === PPlusChartType['bar']) {
          this.activeEnergyChartOptions.legend.selected = this.barEnergyChartState;
        } else {
          this.activeEnergyChartOptions.legend.selected = this.areaEnergyChartState;
        }
        this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
      }
    }
  }


  /**
   * Enable compare mode.
   * @param date: Date starts compare mode
   */
  comparePeriods(date: DateMoment) {
    this.compareMode = true;
    this.getPeriodCompare.emit(date);
  }

  /**
   * Disable compared mode
   */
  disabledComparedMode() {
    this.compareMode = false;
    this.comparePeriod = null;
    this.activeDataComparison = null;

    this.selfviewService.removeDataReactiveCompare();
  }


  initiateEnergyChartBar() {
    this.activeEnergyChartOptions = null;
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };

    const series = this.generateSeriesForActiveBarEnergyChart();

    const chartOptions: any = ACTIVE_BAR_ENERGY_CHART_OPTIONS;
    chartOptions.legend.data = this.barEnergyChartState;
    chartOptions.legend.selected = { ...this.barEnergyChartState };
    chartOptions.series = [...series];
    chartOptions.xAxis[0].data = this.activeData.groupedData.map(item => {
      const time = new Date(item.date);
      return time.toLocaleDateString('es-ES');
    });

    this.activeEnergyChartOptions = chartOptions;
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
  }


  initiateEconomicChartBar() {
    this.activeEconomicChartOptions = null;
    this.activeEconomicChartOptions = { ...this.activeEconomicChartOptions, ...this.activeEconomicChartOptions };

    const series = this.generateSeriesForActiveBarEconomicChart();

    const chartOptions: any = ACTIVE_BAR_ECONOMIC_CHART_OPTIONS;
    chartOptions.legend.data = this.barEconomicChartState;
    chartOptions.legend.selected = { ...this.barEconomicChartState };
    chartOptions.series = [...series];
    chartOptions.xAxis[0].data = this.activeData.groupedData.map(item => {
      const time = new Date(item.date);
      return time.toLocaleDateString('es-ES');
    });

    this.activeEconomicChartOptions = chartOptions;
    this.activeEconomicChartOptions = { ...this.activeEconomicChartOptions, ...this.activeEconomicChartOptions };
  }



  initiateEnergyChartArea() {
    this.activeEnergyChartOptions = null;
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };

    this.chartType = 'line';

    const series = this.generateSeriesForActiveAreaEnergyChart();
    const chatOptions: any = ACTIVE_AREA_ENERGY_CHART_OPTIONS;
    chatOptions.legend.data = this.areaEnergyChartState,
      chatOptions.legend.selected = this.areaEnergyChartState,
      chatOptions.series = series,

      this.activeEnergyChartOptions = chatOptions;
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
  }


  initiateEconomicChartArea() {
    this.activeEconomicChartOptions = null;
    this.activeEconomicChartOptions = { ...this.activeEconomicChartOptions, ...this.activeEconomicChartOptions };

    this.chartType = 'line';

    const series = this.generateSeriesForActiveAreaEconomicChart();

    const chatOptions: any = ACTIVE_AREA_ECONOMIC_CHART_OPTIONS;
    chatOptions.legend.data = this.areaEconomicChartState,
      chatOptions.legend.selected = this.areaEconomicChartState,
      chatOptions.series = series,

      this.activeEconomicChartOptions = chatOptions;
    this.activeEconomicChartOptions = { ...this.activeEconomicChartOptions, ...this.activeEconomicChartOptions };
  }

  /**
 * Determines whether chart init on
 * @param ec
 */
  onEnergyChartInit(ec) {
    this.energyEchartsInstance = ec;
  }

  onEconomicChartInit(ec) {
    this.economicEchartsInstance = ec;
  }



  /**
   * Initiates periods for reactive area chart
   * @param data: chart data
   */
  initiatePeriods(data: any[]) {
    if (data[0]) {
      this.periods = [];
      let currentPeriod = data[0].period;
      let startDateDate = data[0].hour;
      let endDateDate = '';

      for (let i = 0; i < data.length; i++) {

        if (currentPeriod !== data[i].period) {
          endDateDate = data[i - 1].hour;
          this.periods.push({ startDate: startDateDate, endDate: endDateDate, period: currentPeriod });
          startDateDate = endDateDate;
        }

        if (i === (data.length - 1)) {
          if (this.periods[this.periods.length - 1].period !== data[i].period) {
            endDateDate = data[i].hour;
            this.periods.push({ startDate: startDateDate, endDate: endDateDate, period: data[i].period });
          }
        }

        currentPeriod = data[i].period;
      }
    }

    this.paintPeriods();
  }



  /**
   * Paints periods
   */
  paintPeriods() {
    const markers = [];
    for (const period of this.periods) {
      const marker = [
        { name: 'P' + period.period, xAxis: period.startDate, yAxis: 'min' },
        { yAxis: 'max', xAxis: period.endDate }
      ];
      markers.push(marker);
    }

    // create periods fake serie and concats to all series
    const periodsSerie = this.createPeriodsFakeSerie(markers);
    this.activeEnergyChartOptions.series = this.activeEnergyChartOptions.series.concat([periodsSerie]);
  }

  /**
   * Toggles serie for main chart.
   * @param serie: Current serie
   * @param $event: State
   */
  toggleEnergyChartSerie(serie: any, $event: any) {
    // Get the zoom level of the graph and apply it in legend selection
    let zoom = null;
    if (this.energyEchartsInstance) {
      zoom = this.energyEchartsInstance.getOption().dataZoom;
      this.activeEnergyChartOptions.dataZoom = zoom;
    }
    const state = $event.checked;

    Object.keys(PERIODS).forEach((key) => {
      this.barEnergyChartState[`${serie.label} ${key}`] = state;
      this.barEnergyChartState[`${serie.label} ${key}*`] = state;
    });

    this.areaEnergyChartState[`${serie.label}`] = state;
    this.areaEnergyChartState[`${serie.label}*`] = state;

    if (this.chartType === PPlusChartType.Bar) {
      this.activeEnergyChartOptions.legend.selected = this.barEnergyChartState;
    } else {
      this.activeEnergyChartOptions.legend.selected = this.areaEnergyChartState;
    }
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
  }


  toggleEconomicChartSerie(serie: any, $event: any) {
    // Get the zoom level of the graph and apply it in legend selection
    let zoom = null;
    if (this.economicEchartsInstance) {
      zoom = this.economicEchartsInstance.getOption().dataZoom;
      this.activeEconomicChartOptions.dataZoom = zoom;
    }
    const state = $event.checked;

    Object.keys(PERIODS).forEach((key) => {
      this.barEconomicChartState[`${serie.label} ${key}`] = state;
      this.barEconomicChartState[`${serie.label} ${key}*`] = state;
    });

    this.areaEconomicChartState[`${serie.label}`] = state;
    this.areaEconomicChartState[`${serie.label}*`] = state;

    if (this.chartType === PPlusChartType.Bar) {
      this.activeEconomicChartOptions.legend.selected = this.barEconomicChartState;
    } else {
      this.activeEconomicChartOptions.legend.selected = this.areaEconomicChartState;
    }
    this.activeEconomicChartOptions = { ...this.activeEconomicChartOptions, ...this.activeEconomicChartOptions };
  }


  /**
   * Initiates Energy chart bar comparison
   */
  initiateEnergyChartBarComparison() {

    this.initiateEnergyChartBar();
    const data = this.activeDataComparison.groupedData;
    const series = this.generateSeriesForActiveBarEnergyChart(true);
    const xAxis = <EChartOption.XAxis[]>this.activeEnergyChartOptions.xAxis;

    if (xAxis.length > 1) {
      xAxis.pop();
    }
    const newAxis: EChartOption.XAxis = {
      position: 'bottom',
      boundaryGap: true,
      offset: 50,
      type: 'category',
      data: data.map(item => {
        const time = new Date(item.date);
        return time.toLocaleDateString('es-ES'); // + ' \n ' + time.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'});
      }),
      axisTick: {
        alignWithLabel: true, interval: 5
      },
      axisLine: {
        onZero: false,
        lineStyle: {
          color: '#327830'
        }
      }
    };

    xAxis.push(newAxis);
    this.activeEnergyChartOptions.dataZoom[0].xAxisIndex = [0, 1];
    const seriesTotal = this.activeEnergyChartOptions.series.concat(series);
    this.activeEnergyChartOptions.series = seriesTotal;
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
  }




  /**
   * Initiates Energy chart area comparison
   */
  initiateEnergyChartAreaComparison() {

    this.initiateEnergyChartArea();
    this.chartType = 'line';
    const series = this.generateSeriesForActiveAreaEnergyChart(true);
    const newAxis: EChartOption.XAxis = {
      type: 'time',
      offset: 30,
      position: 'bottom',
      axisLabel: {
        fontSize: 10,
        formatter: (function (value) {
          const time = new Date(value);
          return time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' });
        })
      },
      splitLine: {
        show: false,
      }
    };

    const xAxis = <EChartOption.XAxis[]>this.activeEnergyChartOptions.xAxis;

    if (xAxis.length > 1) {
      xAxis.pop();
    }
    xAxis.push(newAxis);

    this.activeEnergyChartOptions.series = this.activeEnergyChartOptions.series.concat(series);
    this.activeEnergyChartOptions.tooltip.show = true;
    this.activeEnergyChartOptions.dataZoom[0].xAxisIndex = [0, 1];
    this.activeEnergyChartOptions = { ...this.activeEnergyChartOptions, ...this.activeEnergyChartOptions };
    const firstSerie: EChartOption.SeriesLine = <EChartOption.SeriesLine>this.activeEnergyChartOptions.series[0];
    if (firstSerie) {
      (<EChartOption.SeriesLine>firstSerie.markArea).data = null;
    }

  }




  /**
   * Generates series for active bar chart
   */
  generateSeriesForActiveBarEnergyChart(compareMode: boolean = false) {
    const series = [];
    const data = compareMode ? this.activeDataComparison.groupedData : this.activeData.groupedData;

    data.forEach(element => {
      Object.keys(ACTIVE_ENERGY_SERIES_DATA).forEach((key, index) => {
        const serieName = ACTIVE_ENERGY_SERIES_DATA[key].id;

        if (!series[index * 2]) {
          series[index * 2] = [null, null, null, null, null, null];
        }
        // create series
        element[`${serieName}ByPeriod`].forEach((item, i) => {
          if (!series[index * 2][i]) {
            series[index * 2][i] = this.createActiveBarEnergyChartSerie(
              compareMode ?
                `${ACTIVE_ENERGY_SERIES_DATA[key].label} P${i + 1}*` :
                `${ACTIVE_ENERGY_SERIES_DATA[key].label} P${i + 1}`
              ,
              [],
              chartColors.periods[compareMode ?
                `${ACTIVE_ENERGY_SERIES_DATA[key].periodColorKey}P${i + 1}Comparison` :
                `${ACTIVE_ENERGY_SERIES_DATA[key].periodColorKey}P${i + 1}`
              ],
              compareMode ?
                `${ACTIVE_ENERGY_SERIES_DATA[key].id}Comparison` :
                ACTIVE_ENERGY_SERIES_DATA[key].id
            );
          }
          series[index * 2][i].data.push(item * this.WhToKwh);
        });
      });
    });
    return series.reduce((a, b) => [...a, ...b], []);
  }



  /**
   * Generates series for active bar chart
   */
  generateSeriesForActiveBarEconomicChart(compareMode: boolean = false) {
    const series = [];
    const data = compareMode ? this.activeDataComparison.groupedData : this.activeData.groupedData;

    data.forEach(element => {
      Object.keys(ACTIVE_ECONOMIC_SERIES_DATA).forEach((key, index) => {
        const serieName = ACTIVE_ECONOMIC_SERIES_DATA[key].id;

        if (!series[index * 2]) {
          series[index * 2] = [null, null, null, null, null, null];
        }
        // create series
        element[`${serieName}ByPeriod`].forEach((item, i) => {
          if (!series[index * 2][i]) {
            series[index * 2][i] = this.createActiveBarEconomicChartSerie(
              compareMode ?
                `${ACTIVE_ECONOMIC_SERIES_DATA[key].label} P${i + 1}*` :
                `${ACTIVE_ECONOMIC_SERIES_DATA[key].label} P${i + 1}`
              ,
              [],
              chartColors.periods[compareMode ?
                `${ACTIVE_ECONOMIC_SERIES_DATA[key].periodColorKey}P${i + 1}Comparison` :
                `${ACTIVE_ECONOMIC_SERIES_DATA[key].periodColorKey}P${i + 1}`
              ],
              compareMode ?
                `${ACTIVE_ECONOMIC_SERIES_DATA[key].id}Comparison` :
                ACTIVE_ECONOMIC_SERIES_DATA[key].id
            );
          }
          series[index * 2][i].data.push(item);
        });
      });
    });
    return series.reduce((a, b) => [...a, ...b], []);
  }



  /**
   * Generates series for active area chart
   */
  generateSeriesForActiveAreaEnergyChart(compareMode: boolean = false) {
    const series = [];
    const data = compareMode ? this.activeDataComparison.hourlyData : this.activeData.hourlyData;

    data.forEach(element => {
      Object.keys(ACTIVE_ENERGY_SERIES_DATA).forEach((key, index) => {
        const serieName = ACTIVE_ENERGY_SERIES_DATA[key].id;

        let areaStyle = null;
        if (!compareMode) {
          areaStyle = {
            normal: {
              color: chartColors[`${ACTIVE_ENERGY_SERIES_DATA[key].areaColorKey}Area`],
              areaStyle: { type: 'default' }
            }
          };
        }

        if (!series[index * 2]) {
          series[index * 2] = this.createActiveAreaEnergyChartSerie(
            compareMode ?
              `${ACTIVE_ENERGY_SERIES_DATA[key].label}*` :
              `${ACTIVE_ENERGY_SERIES_DATA[key].label}`
            ,
            [],
            chartColors[`${ACTIVE_ENERGY_SERIES_DATA[key].areaColorKey}Line`],
            ACTIVE_ENERGY_SERIES_DATA[key].id,
            areaStyle,
            compareMode ? 1 : 0
          );
        }
        series[index * 2].data.push([element.hour, element[serieName] * this.WhToKwh]);
      });
    });

    return series;
  }





  /**
   * Generates series for active area chart
   */
  generateSeriesForActiveAreaEconomicChart(compareMode: boolean = false) {
    const series = [];
    const data = compareMode ? this.activeDataComparison.hourlyData : this.activeData.hourlyData;

    data.forEach(element => {
      Object.keys(ACTIVE_ECONOMIC_SERIES_DATA).forEach((key, index) => {
        const serieName = ACTIVE_ECONOMIC_SERIES_DATA[key].id;

        let areaStyle = null;
        if (!compareMode) {
          areaStyle = {
            normal: {
              color: chartColors[`${ACTIVE_ECONOMIC_SERIES_DATA[key].areaColorKey}Area`],
              areaStyle: { type: 'default' }
            }
          };
        }

        if (!series[index * 2]) {
          series[index * 2] = this.createActiveAreaEnergyChartSerie(
            compareMode ?
              `${ACTIVE_ECONOMIC_SERIES_DATA[key].label}*` :
              `${ACTIVE_ECONOMIC_SERIES_DATA[key].label}`
            ,
            [],
            chartColors[`${ACTIVE_ECONOMIC_SERIES_DATA[key].areaColorKey}Line`],
            ACTIVE_ECONOMIC_SERIES_DATA[key].id,
            areaStyle,
            compareMode ? 1 : 0
          );
        }
        series[index * 2].data.push([element.hour, element[serieName]]);
      });
    });

    return series;
  }


  /**
   * Generates series for reactive area chart
   */
  // generateSeriesForActiveAreaEconomicChart(compareMode: boolean = false) {
  //   const series = [];
  //   const data = compareMode ? this.activeDataEnergyComparison.hourlyData : this.activeDataEnergy.hourlyData;

  //   data.forEach(element => {
  //     Object.keys(ACTIVE_ECONOMIC_SERIES_DATA).forEach((key, index) => {
  //       const serieName = ACTIVE_ECONOMIC_SERIES_DATA[key].id;

  //       let areaStyle = null;
  //       if (!compareMode) {
  //         areaStyle = {
  //           normal: {
  //             color: chartColors[`${ACTIVE_ECONOMIC_SERIES_DATA[key].areaColorKey}Area`],
  //             areaStyle: { type: 'default' }
  //           }
  //         };
  //       }

  //       if (!series[index * 2]) {
  //         series[index * 2] = this.createActiveAreaEconomicChartSerie(
  //           compareMode ?
  //             `${ACTIVE_ECONOMIC_SERIES_DATA[key].label}*` :
  //             `${ACTIVE_ECONOMIC_SERIES_DATA[key].label}`
  //           ,
  //           [],
  //           chartColors[`${ACTIVE_ECONOMIC_SERIES_DATA[key].areaColorKey}Line`],
  //           ACTIVE_ECONOMIC_SERIES_DATA[key].id,
  //           areaStyle,
  //           compareMode ? 1 : 0
  //         );
  //       }
  //       series[index * 2].data.push([element.hour, element[serieName]]);
  //     });
  //   });

  //   return series;
  // }




  /**
   * Creates active bar chart serie
   * @param name: serie name
   * @param data: serie data
   * @param color: serie color
   * @param stack: serie stack
   * @returns serie
   */
  createActiveBarEnergyChartSerie(name: string, data: any, color: string, stack: string) {
    return {
      name,
      type: 'bar',
      data,
      itemStyle: { color },
      stack,
      xAxisIndex: 0
    };
  }

  /**
   * Creates active area chart serie
   * @param name: serie name
   * @param data: serie data
   * @param color: serie color
   * @param stack: serie stack
   * @returns serie
   */
  createActiveAreaEnergyChartSerie(name: string, data: any, color: string, stack: string, areaStyle: any = null, xAxisIndex: number = 0) {

    return {
      name,
      type: 'line',
      smooth: true,
      symbol: 'none',
      itemStyle: {
        normal: {
          color
        }
      },
      stack,
      areaStyle: areaStyle,
      markArea: {
        label: { show: true, position: 'top' },
        itemStyle: {
          color: 'rgba(38,44,44,0.11)',
          borderColor: 'rgba(38,44,44,0.27)',
          borderWidth: 0.5
        },
        data: []
      },
      data: data,
      xAxisIndex

    };

  }




  /**
   * Creates active bar chart serie
   * @param name: serie name
   * @param data: serie data
   * @param color: serie color
   * @param stack: serie stack
   * @returns serie
   */
  createActiveBarEconomicChartSerie(name: string, data: any, color: string, stack: string) {
    return {
      name,
      type: 'bar',
      data,
      itemStyle: { color },
      stack,
      xAxisIndex: 0
    };
  }

  /**
   * Creates active area chart serie
   * @param name: serie name
   * @param data: serie data
   * @param color: serie color
   * @param stack: serie stack
   * @returns serie
   */
  // createActiveAreaEconomicChartSerie(name: string, data: any, color: string, stack: string, areaStyle: any = null, xAxisIndex: number = 0) {

  //   return {
  //     name,
  //     type: 'line',
  //     smooth: true,
  //     symbol: 'none',
  //     itemStyle: {
  //       normal: {
  //         color
  //       }
  //     },
  //     stack,
  //     areaStyle: areaStyle,
  //     markArea: {
  //       label: { show: true, position: 'top' },
  //       itemStyle: {
  //         color: 'rgba(38,44,44,0.11)',
  //         borderColor: 'rgba(38,44,44,0.27)',
  //         borderWidth: 0.5
  //       },
  //       data: []
  //     },
  //     data: data,
  //     xAxisIndex

  //   };

  // }

  /**
   * Creates periods fake serie
   * @param data: serie data
   * @returns serie
   */
  createPeriodsFakeSerie(data: any) {

    return {
      name: 'periods',
      type: 'line',
      markArea: {
        label: {
          show: true,
          position: 'top',
          textStyle: {
            color: '#000',
            fontStyle: 'italic'
          }
        },
        itemStyle: {
          color: 'rgba(38,44,44,0.11)',
          borderColor: 'rgba(38,44,44,0.27)',
          borderWidth: 0.5
        },
        data
      }
    };
  }



  /**
   * Change type to Energy or Economic mode
   */
  changeType(): void {
    if (this.localType === GlobalType.Economic) {
      this.localType = GlobalType.Energy;
    } else {
      this.localType = GlobalType.Economic;
    }

    this.updateType.emit(this.localType);

    // TODO: implementar funcionalidad para local type
  }


}
