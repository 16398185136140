import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule, MatButtonModule, MatTreeModule, MatCheckboxModule, MatIconModule } from '@angular/material';
import { NbCardModule } from '@nebular/theme';
import { TreeDataComponent } from './tree-data.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ChecklistDatabase } from './tree-data.service';

@NgModule({
  declarations: [
    TreeDataComponent
  ],
  imports: [
    CommonModule,
    NbCardModule,
    MatInputModule,
    MatButtonModule,
    MatTreeModule,
    MatCheckboxModule,
    NgxUiLoaderModule,
    MatIconModule,
    MatCheckboxModule
  ],
  exports: [
    TreeDataComponent
  ],
  providers: [
    ChecklistDatabase
  ]
})
export class PPLusTreeDataModule { }
