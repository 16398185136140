
export const GENERIC_PERMISSIONS_HEADERS = [
    {
        headerName: 'ID',
        rowDrag: false,
        minWidth: 50,
        field: 'permissionId',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: false,
    },
    {
        headerName: 'Description',
        rowDrag: false,
        minWidth: 50,
        field: 'description',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: false,
    },
    {
        headerName: 'Operation',
        minWidth: 100,
        field: 'operation.description',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: true,
    },
    {
        headerName: 'Element type',
        rowDrag: false,
        minWidth: 100,
        field: 'elementType.name',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: true,
    },
    {
        headerName: 'Entity type',
        rowDrag: false,
        minWidth: 100,
        field: 'entityType.description',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: true,
    },
    {
        headerName: 'Permitted',
        cellRenderer: 'checkBoxComponent',
        field: 'permitted',
        filter: false,
        minWidth: 100,
        resizable: true,
        status: true,
    },
];
