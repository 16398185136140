import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { NbRequestPasswordComponent, NbAuthService, NB_AUTH_OPTIONS, NbAuthResult } from '@nebular/auth';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.css']
})
export class RequestPasswordComponent extends NbRequestPasswordComponent implements OnInit {
  form: FormGroup;
  email: FormControl;

  constructor(service: NbAuthService, @Inject(NB_AUTH_OPTIONS) protected options = {}, cd: ChangeDetectorRef, router: Router, private dialogService: NbDialogService) {
    super(service, options, cd, router);

    this.email = new FormControl('', [
      Validators.required,
      Validators.email
    ]);

    this.form = new FormGroup({
      email: this.email
    });
  }

  ngOnInit() {
  }

  requestPass(): void {
    this.errors = this.messages = [];
    this.submitted = true;

    this.service.requestPassword(this.strategy, this.user)
    .subscribe(
      (result: NbAuthResult) => {
        this.submitted = false;
        setTimeout(() => {
          return this.router.navigateByUrl('auth/login');
        }, 5 * 1000);
        this.cd.detectChanges();
      },
      err => {}
    );
  }
}
