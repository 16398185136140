import { Component, OnInit, ViewChild } from '@angular/core';
import { SelfViewService } from '../services/selfview-general.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalType } from '../models/selfview.model';
import { isUndefined } from 'util';
import * as moment from 'moment';
import { StiReportsService } from 'projects/pplus/src/lib/services/sti-reports.service';
import { forkJoin } from 'rxjs';
import { SelfviewActiveMainComponent } from './selfview-active-main/selfview-active-main.component';


@Component({
  selector: 'pplus-self-view-active',
  templateUrl: './self-view-active.component.html',
  styleUrls: ['./self-view-active.component.scss']
})
export class SelfViewActiveComponent implements OnInit {
  @ViewChild(SelfviewActiveMainComponent, { static: false }) selfviewactivemain: SelfviewActiveMainComponent;


  idInstallation: number;
  dateRange;
  loaderId: string;
  globalType = GlobalType.Economic;
  indeterminate = false;
  showingViewer: boolean;
  showingDesigner: boolean;
  reportFile: string;
  report: any;
  reportData: any;


  datasources = {
    'consumption': 1,
    'networkConsumption': 1,
    'selfConsumption': 1,
    'excedentary': 1,
    'netGenerated': 1,
  };


  constructor(private selfViewService: SelfViewService,
    private ngxLoaderService: NgxUiLoaderService,
    private stiReportsService: StiReportsService) {

    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);
  }




  ngOnInit() {

    this.showingViewer = false;
    this.showingDesigner = false;

    this.selfViewService.globalType.subscribe((res: GlobalType) => {
      if (res !== null) {
        this.globalType = res;
      } else {
        this.indeterminate = true;
      }
    });

    this.selfViewService.dataActive.subscribe(data => {
      if (data) {
        this.ngxLoaderService.stopLoader(this.loaderId);
      }
    });

    this.selfViewService.dataActiveCompare.subscribe(data => {
      if (data) {
        this.ngxLoaderService.stopLoader(this.loaderId);
      }
    });
  }



  setDateRange(range) {

    const sDate = moment([range.startDate.getFullYear(), range.startDate.getMonth(), range.startDate.getDate(), 0, 0, 0]).utcOffset(0, true);
    const eDate = moment([range.endDate.getFullYear(), range.endDate.getMonth(), range.endDate.getDate(), 0, 0, 0]).utcOffset(0, true);

    this.dateRange = {
      startDate: sDate.toDate().toUTCString(),
      endDate: eDate.toDate().toUTCString(), //moment([range.endDate.getFullYear(), range.endDate.getMonth(), range.endDate.getDate(), 0, 0, 0]).utcOffset(0, true).toDate()
      // startDate: range.startDate.toUTCString(),
      // endDate: range.endDate.toUTCString(),
    };

    this.getActiveData();
  }


  setIdInstallation(id: number) {

    if (this.idInstallation !== id) {
      this.idInstallation = id;

      const last6Months = new Date();
      last6Months.setMonth(last6Months.getMonth() - 6);
      last6Months.setHours(0, 0, 0);
      const today = new Date();
      today.setHours(23, 59, 59);

      const sDate = moment([last6Months.getFullYear(), last6Months.getMonth(), last6Months.getDate(), 0, 0, 0]).utcOffset(0, true);
      const eDate = moment([today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0]).utcOffset(0, true);

      this.dateRange = {
        startDate: sDate.toDate().toUTCString(),
        endDate: eDate.toDate().toUTCString(),
        // startDate: last6Months.toUTCString(),
        // endDate: today.toUTCString(),
      };

      this.selfViewService.getEnergyDataOrigins(this.idInstallation).subscribe(res => {
        this.datasources.consumption = res.consumedEnergyDataSource;
        this.datasources.excedentary = res.excedentaryEnergyDataSource;
        this.datasources.netGenerated = res.generatedEnergyDataSource;
        this.datasources.networkConsumption = res.networkConsumedEnergyDataSource;
        this.datasources.selfConsumption = res.selfConsumedEnergyDataSource;
        this.selfviewactivemain.datasources = this.datasources;
        this.getActiveData();
      });
    }
  }


  getActiveData() {
    const opt = {
      installationId: this.idInstallation,
      from: this.dateRange.startDate,
      to: this.dateRange.endDate,
      consumedEnergyDataSource: this.datasources.consumption,
      generatedEnergyDataSource: this.datasources.netGenerated,
      selfConsumedEnergyDataSource: this.datasources.selfConsumption,
      excedentaryEnergyDataSource: this.datasources.excedentary,
      networkConsumedEnergyDataSource: this.datasources.networkConsumption
    };

    this.ngxLoaderService.startLoader(this.loaderId);
    this.selfViewService.getActiveData(opt);
  }


  onChangeDatasources(datasources) {
    this.datasources = datasources;
    this.getActiveData();
  }


  getActiveDataCompared(date: { startDate: moment.Moment, endDate: moment.Moment }): void {
    if (!isUndefined(date.startDate) && !isUndefined(date.endDate) && date.startDate !== null && date.endDate !== null) {
      const days = moment(this.dateRange.endDate).diff(moment(this.dateRange.startDate), 'days');
      const startDate = moment(date.startDate).toDate().toUTCString();
      const endDate = moment(date.endDate).add(days, 'days').endOf('day').toDate().toUTCString();

      const opt = {
        installationId: this.idInstallation,
        from: startDate,
        to: endDate,
        consumedEnergyDataSource: this.datasources.consumption,
        generatedEnergyDataSource: this.datasources.netGenerated,
        selfConsumedEnergyDataSource: this.datasources.selfConsumption,
        excedentaryEnergyDataSource: this.datasources.excedentary,
        networkConsumedEnergyDataSource: this.datasources.networkConsumption
      };


      this.ngxLoaderService.startLoader(this.loaderId);
      this.selfViewService.getActiveDataCompared(opt);
    }
  }

  setGlobalType($event) {
    const type = $event.currentValue;
    this.indeterminate = false;
    if (type) {
      this.selfViewService.updateGlobalType(GlobalType.Economic);
    } else {
      this.selfViewService.updateGlobalType(GlobalType.Energy);
    }
  }


  showViewer() {

    const desde = moment().year(moment().year()).month(moment().month()).add(-1, 'M').date(1).toDate();
    const hasta = moment().year(moment().year()).month(moment().month()).add(-1, 'M').date(1).endOf('month').toDate();

    if (!this.showingViewer) {
      forkJoin(
        [this.stiReportsService.getReport(),
        this.stiReportsService.getReportData(this.idInstallation, desde, hasta)]).subscribe(([report, reportData]) => {
          this.report = report;
          this.reportData = reportData;
          this.showingViewer = true;
        });
    } else {
      this.showingViewer = false;
    }

  }

  showDesigner() {

    const desde = moment().year(moment().year()).month(moment().month()).date(1).toDate();
    const hasta = moment().year(moment().year()).month(moment().month()).date(1).endOf('month').toDate();

    if (!this.showingDesigner) {
      forkJoin(
        [this.stiReportsService.getReport(),
        this.stiReportsService.getReportData(this.idInstallation, desde, hasta)]).subscribe(([report, reportData]) => {
          this.report = report;
          this.reportData = reportData;
          this.showingDesigner = true;
        });
    } else {
      this.showingDesigner = false;
    }

  }

}
