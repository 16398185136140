import { UserGroupService } from './../user-group.service';
import { Component, OnInit, EventEmitter, Output, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { EntitiesService } from '../../entities/entities.service';
import { map } from 'rxjs/operators';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { UserService } from '../../users/user.service';
import { forkJoin } from 'rxjs';
import { AdminService } from '../../admin.service';
import { UserGroupDetailsService } from './user-group-details.service';

@Component({
  selector: 'app-user-group-details',
  templateUrl: './user-group-details.component.html',
  styleUrls: ['./user-group-details.component.scss']
})
export class UserGroupDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  data: any;

  formData: DynamicForm[];
  formGroup: FormGroup;

  constructor(
    private dataRow: RowDataService,
    private dialog: NbDialogService,
    private userGroupService: UserGroupService,
    private userService: UserService,
    private entityService: EntitiesService,
    private adminService: AdminService,
    private userGroupDetailsService: UserGroupDetailsService,
  ) {
    this.formData = this.userGroupDetailsService.getDynamicForm();
  }

  generateForm(data: DynamicForm []) {
    const fieldsCtrls = {};
    data.forEach(item => {
      const fields = item.fields;
      fields.forEach(f => {
        fieldsCtrls[f.name] = new FormControl(f.value || '', f.validators);
      });
    });
    this.formGroup = new FormGroup(fieldsCtrls);
  }

  ngOnInit() {
    forkJoin([this.userService.getUsersList(), this.entityService.getEntitiesList()]).subscribe(([users, entities]) => {
      this.formData.map(o => {
        const userIds = o.fields.find(f => f.name === 'userIds');
        if (userIds) {
          userIds.options = [];
          users.map(u => {
            userIds.options.push({
              value: u.userId,
              label: u.fullName,
            });
          });
        }
        const entityId = o.fields.find(f => f.name === 'entityId');
        if (entityId) {
          entityId.options = [];
          entities.map(e => {
            entityId.options.push({
              value: e.entityId,
              label: e.code,
            });
          });
        }
        const ownerEntityId = o.fields.find(f => f.name === 'ownerEntityId');
        if (ownerEntityId) {
          ownerEntityId.options = [];
          entities.map(e => {
            ownerEntityId.options.push({
              value: e.entityId,
              label: e.code,
            });
          });
        }
      });
      this.generateForm(this.formData);
    });

    this.dataRow.currentRowData.pipe(map(o => {
      this.formData.forEach(data => {
        const fields = data.fields;
        fields.map(field => {
          if (field.name === 'userIds') {
            const options = [];
            if (o['userGroupMembers'] && o['userGroupMembers'].length)
              for (const el of o['userGroupMembers']) options.push(el.userId);
            field.value = options;
          }
          else field.value = o[field.name];
        });
      });
      return o;
    })).subscribe(data => {
      this.data = data;
      this.generateForm(this.formData);
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.userGroupService.createUserGroup(data).subscribe(res => {
        if (res) {
          if (res.group) {
            this.data.userGroupId = res.group.userGroupId;
            this.formGroup.patchValue({userGroupId: this.data.userGroupId});
          }
          this.adminService.showToast('success',
              'Se ha creado el nuevo grupo de usuarios: ' + data.description,
              'Elemento creado');
          this.updateDelete.emit(true);
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al crear el grupo de usuarios: ' + data.description,
              'Error');
        }
      });
    }
  }

  update() {
    if (this.data.userGroupId == null) {
      this.onSubmit();
    } else if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const payload = this.formGroup.value;
      payload.activated = Boolean(payload.activated);
      const onSuccessFunction = () => {
        this.adminService.showToast('success',
            'Se ha actualizado el grupo de usuarios: ' + payload.description,
            'Elemento actualizado');
        this.updateDelete.emit();
      };
      const onErrorFunction = () => {
        this.adminService.showToast('danger',
            'Se ha producido un error al actualizar el grupo de usuarios: ' + payload.description,
            'Error');
      };
      this.userGroupService.updateUserGroup(this.data.userGroupId, payload).subscribe(success => {
        if (success) {
          if (payload.userIds && payload.userIds.length >= 0) {
            this.userGroupService.addUsersToUserGroup(this.data.userGroupId, payload.userIds).subscribe(
              success => {
                if (success) onSuccessFunction();
                else onErrorFunction();
              }
            );
          } else onSuccessFunction();
        } else onErrorFunction();
      });
    }
  }

  delete() {
    if (this.data.userGroupId == null) {
      this.adminService.showToast('info', 'Seleccione un grupo de usuarios', 'Info');
    } else {
      this.userGroupService.deleteUserGroup(this.data.userGroupId).subscribe(success => {
        if (success) {
          this.adminService.showToast('success',
              'Se ha borrado el grupo de usuarios: ' + this.formGroup.value.description,
              'Elemento eliminado');
          this.updateDelete.emit();
          this.clearData();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al borrar el grupo de usuarios: ' + this.formGroup.value.description,
              'Error');
        }
      });
    }
  }

  copy() {
    if (this.data.userGroupId == null) {
      this.adminService.showToast('info', 'Seleccione un grupo de usuarios para copiar la información', 'Info');
    } else {
      this.formGroup.patchValue({
        userGroupId: this.data.userGroupId = null,
        name: this.data.name = '',
      });
    }
  }

  close() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  clearData() {
    this.data = {};
    this.formGroup.reset();
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.userGroupId == null) {
      this.adminService.showToast('info', 'No hay ningún grupo de usuarios seleccionado', 'Info');
    } else {
      this.dialog.open(dialog, { context: '¿Desea eliminar el grupo de usuarios?', hasScroll: false });
    }
  }

}
