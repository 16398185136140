import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { PVSelfCalculation, PVSelfCalculationConsumer, PVSelfCalculationProducer } from '../models/PvSelfCalculation.model';
import { PVSelfEngineCalculation, PVSelfEngineInstallation, PVSelfEngineInstallationField, PVSelfEngineConsumer, PVSelfEngineProducer } from '../models/PVSelfEngineCalculation.model';
import { PVSelfBalanceResults, PVSelfPIConfiguration, PVSelfEnginePIConfigurationResults, PVSelfPredimensioningResults, PVSelfEconomicSummaryResults, PVSelfBillingResults, PVSelfFinancialResults, PVSelfBalanceEnergyResults, PVSelfLossesResults } from '../models/PVSelfResultsModel.model';

@Injectable({
  providedIn: 'root'
})
export class PVSelfEngineService {

  private _balanceEnergyResults = new BehaviorSubject(<PVSelfBalanceResults>null);
  balanceEnergyResults = this._balanceEnergyResults.asObservable();

  constructor(private httpClient: HttpClient) { }

  calculateBalanceResults(calculation: PVSelfCalculation): Observable<PVSelfBalanceResults> {
    const engineCalculation: PVSelfEngineCalculation = this.convertToEngineModel(calculation);
    return this.httpClient.post<PVSelfBalanceResults>(
      environment.API_URL + '/api/pvself/engine/calculateBalanceResults', engineCalculation).pipe(
        map(data => {
          this._balanceEnergyResults.next(data);
          return data;
        }));
  }

  calculateOptimalInclination(calculation: PVSelfCalculation): Observable<number> {
    const engineCalculation: PVSelfEngineCalculation = this.convertToEngineModel(calculation);
    return this.httpClient.post<number>(
      environment.API_URL + '/api/pvself/engine/calculateOptimalInclination', engineCalculation).pipe(
        map(data => data));
  }


  getConsumptionFromExcelData(excelFileContent: string): Observable<number[]> {
    const postData = { excelFileContent: excelFileContent };
    return this.httpClient.post<number[]>(
      environment.API_URL + '/api/pvself/engine/getConsumptionFromExcelData', postData, {
    }).pipe(
      map(data => data));
  }


  calculatePIConfigs(calculation: PVSelfCalculation, selectedInstallationIndex: number, selectedFieldIndex: number): Observable<PVSelfPIConfiguration[]> {
    const engineCalculation: PVSelfEngineCalculation = this.convertToEngineModel(calculation);
    return this.httpClient.post<PVSelfPIConfiguration[]>(
      environment.API_URL + '/api/pvself/engine/calculatePIConfigs?selectedInstallationIndex' + selectedInstallationIndex + '&selectedFieldIndex=' + selectedFieldIndex, engineCalculation).pipe(
        map(data => data));
  }


  calculatePIConfigsFromPeakPower(calculation: PVSelfCalculation, selectedInstallationIndex: number, selectedFieldIndex: number): Observable<PVSelfPIConfiguration[]> {
    const engineCalculation: PVSelfEngineCalculation = this.convertToEngineModel(calculation);
    return this.httpClient.post<PVSelfPIConfiguration[]>(
      environment.API_URL + '/api/pvself/engine/calculatePIConfigsFromPeakPower?selectedInstallationIndex' + selectedInstallationIndex + '&selectedFieldIndex=' + selectedFieldIndex, engineCalculation).pipe(
        map(data => data));
  }


  calculatePIConfigResults(calculation: PVSelfCalculation, selectedInstallationIndex: number, selectedFieldIndex: number): Observable<PVSelfEnginePIConfigurationResults> {
    const engineCalculation: PVSelfEngineCalculation = this.convertToEngineModel(calculation);
    return this.httpClient.post<PVSelfEnginePIConfigurationResults>(
      environment.API_URL + '/api/pvself/engine/calculatePIConfigResults?selectedInstallationIndex' + selectedInstallationIndex + '&selectedFieldIndex=' + selectedFieldIndex, engineCalculation).pipe(
        map(data => data));
  }


  calculatePredimensioningResults(calculation: PVSelfCalculation): Observable<PVSelfPredimensioningResults[]> {
    const engineCalculation: PVSelfEngineCalculation = this.convertToEngineModel(calculation);
    return this.httpClient.post<PVSelfPredimensioningResults[]>(
      environment.API_URL + '/api/pvself/engine/calculatePredimensioningResults', engineCalculation).pipe(
        map(data => data));
  }


  calculateEconomicSummaryResults(calculation: PVSelfCalculation): Observable<PVSelfEconomicSummaryResults> {
    const engineCalculation: PVSelfEngineCalculation = this.convertToEngineModel(calculation);
    return this.httpClient.post<PVSelfEconomicSummaryResults>(
      environment.API_URL + '/api/pvself/engine/calculateEconomicSummaryResults', engineCalculation).pipe(
        map(data => data));
  }


  calculateBilling(calculation: PVSelfCalculation, consumerIndex: number): Observable<PVSelfBillingResults> {
    //    const engineConsumer: PVSelfEngineConsumer = this.convertConsumerToEngineModel(consumer);
    const engineCalculation: PVSelfEngineCalculation = this.convertToEngineModel(calculation);
    return this.httpClient.post<PVSelfBillingResults>(
      environment.API_URL + '/api/pvself/engine/calculateBillingResults?year=' + consumerIndex, engineCalculation).pipe(
        map(data => data));
  }



  calculateFinancialResults(calculation: PVSelfCalculation): Observable<PVSelfFinancialResults> {
    const engineCalculation: PVSelfEngineCalculation = this.convertToEngineModel(calculation);
    return this.httpClient.post<PVSelfFinancialResults>(
      environment.API_URL + '/api/pvself/engine/calculateFinancialResults', engineCalculation).pipe(
        map(data => data));
  }


  calculateLossesResults(calculation: PVSelfCalculation): Observable<PVSelfLossesResults> {
    const engineCalculation: PVSelfEngineCalculation = this.convertToEngineModel(calculation);
    return this.httpClient.post<PVSelfLossesResults>(
      environment.API_URL + '/api/pvself/engine/calculateLossesResults', engineCalculation).pipe(
        map(data => data));
  }


  convertToEngineModel(calculation: PVSelfCalculation) {
    let engineCalculation: PVSelfEngineCalculation;

    engineCalculation = new PVSelfEngineCalculation();
    engineCalculation.calculateYear = calculation.calculateYear;
    engineCalculation.economicValues = calculation.economicValues;
    engineCalculation.predimensionedValues = calculation.predimensionedValues;
    engineCalculation.electricIPC = calculation.electricIPC;
    engineCalculation.electricityPurchaseTax = calculation.electricityPurchaseTax;
    engineCalculation.electricitySaleTax = calculation.electricitySaleTax;
    engineCalculation.generalIPC = calculation.generalIPC;
    engineCalculation.purchaseIVA = calculation.purchaseIVA;
    engineCalculation.saleIVA = calculation.saleIVA;
    engineCalculation.deductibleIVA = calculation.deductibleIVA;

    // PRODUCERS
    calculation.producers.forEach(x => {
      const producer = this.convertProducerToEngineModel(x);
      engineCalculation.producers.push(producer);
    });

    // CONSUMERS
    calculation.consumers.forEach(x => {
      const consumer = this.convertConsumerToEngineModel(x);
      engineCalculation.consumers.push(consumer);
    });


    engineCalculation.balanceInputData = calculation.balanceInputData;
    engineCalculation.financialInputData = calculation.financialInputData;
    engineCalculation.optimalPowerCriteriaData = calculation.optimalPowerCriteriaData;
    engineCalculation.reportData = calculation.reportData;

    return engineCalculation;
  }

  convertProducerToEngineModel(producer: PVSelfCalculationProducer): PVSelfEngineProducer {
    const engineProducer: PVSelfEngineProducer = new PVSelfEngineProducer();

    engineProducer.installation = new PVSelfEngineInstallation();

    engineProducer.installation.latitude = producer.installation.latitude;
    engineProducer.installation.longitude = producer.installation.longitude;
    engineProducer.installation.ubication = producer.installation.ubication;
    engineProducer.installation.minTempData = producer.installation.minTempData;
    engineProducer.installation.maxTempData = producer.installation.maxTempData;
    engineProducer.installation.radiationData = producer.installation.radiationData;
    engineProducer.installation.losses = producer.installation.losses;

    producer.installation.fields.forEach(f => {
      const field: PVSelfEngineInstallationField = new PVSelfEngineInstallationField();
      field.inclination = f.inclination;
      field.inclinationType = f.inclinationType;
      field.orientation = f.orientation;
      field.orientationType = f.orientationType;
      field.ubication = f.ubication;
      field.technology = f.technology;
      field.panelId = f.panelId;
      field.inverterId = f.inverterId;
      field.inverterManufacturerId = f.inverterManufacturerId;
      field.configuratorPeakPower = f.configuratorPeakPower;
      field.numOfInverters = f.numOfInverters;
      field.numSerialPanels = f.numSerialPanels;
      field.numInverterSerials = f.numInverterSerials;
      field.useAccumulation = f.useAccumulation;
      field.accumulationCapacity = f.accumulationCapacity;
      engineProducer.installation.fields.push(field);
    });

    engineProducer.installation.gridType = producer.installation.gridType;

    return engineProducer;
  }

  convertConsumerToEngineModel(consumer: PVSelfCalculationConsumer): PVSelfEngineConsumer {
    const engineConsumer: PVSelfEngineConsumer = new PVSelfEngineConsumer();
    engineConsumer.marketType = consumer.marketType;
    engineConsumer.maximumLegalPower = consumer.maximumLegalPower;
    engineConsumer.rates = consumer.rates;
    engineConsumer.habitsDataType = consumer.habitsDataType;
    engineConsumer.billingData = consumer.billingData;
    engineConsumer.dailyHabits = consumer.dailyHabits;
    engineConsumer.weeklyHabits = consumer.weeklyHabits;
    engineConsumer.monthlyHabits = consumer.monthlyHabits;
    engineConsumer.consumptionProfiles = consumer.consumptionProfiles;
    engineConsumer.billingAnnualData = consumer.billingAnnualData;
    engineConsumer.consumptionOrigin = consumer.consumptionOrigin;
    engineConsumer.consumptionProfiles = consumer.consumptionProfiles;
    engineConsumer.calculatedPowerAdjust = consumer.calculatedPowerAdjust;
    engineConsumer.calculatedEnergyAdjust = consumer.calculatedEnergyAdjust;
    engineConsumer.calculatedTotalAdjust = consumer.calculatedTotalAdjust;
    engineConsumer.realEnergyAdjust = consumer.realEnergyAdjust;
    engineConsumer.realBillingAdjust = consumer.realBillingAdjust;
    engineConsumer.consumptionCurve = consumer.consumptionCurve;
    engineConsumer.manualTemplateConsumption = consumer.manualTemplateConsumption;

    return engineConsumer;
  }
}
