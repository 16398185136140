export class EnergyDataOrigin {
  consumedEnergyDataSource: number;
  generatedEnergyDataSource: number;
  excedentaryEnergyDataSource: number;
  networkConsumedEnergyDataSource: number;
  selfConsumedEnergyDataSource: number;
}

















export class SelfViewParams {
  from: string | Date;
  to: string | Date;
  installationId: number;
  consumedEnergyDataSource: number;
  selfConsumedEnergyDataSource: number;
  generatedEnergyDataSource: number;
  excedentaryEnergyDataSource: number;
  networkConsumedEnergyDataSource: number;
}

export interface ISelfViewData {
  datosHorarios: IDatoHorario[];
  datosAcumulados: IDatoAcumulado[];
  datosAcumulados2: IDatoAcumulado2;
  total_consumo: number;
  total_consumo_red: number;
  total_autoconsumo: number;
  total_excedente: number;
  total_generacion: number;
  cobertura_energia: number;
  cobertura_porcentaje: number;
  aprovechamiento_energia: number;
  aprovechamiento_porcentaje: number;
  resumen_periodos_energia: [];
  resumen_periodos_porcentaje: [];
  resumen_periodos_euros: [];
  resumen_maximetro_periodos_energia: [];
  resumen_maximetro_periodos_porcentaje: [];
  resumen_maximetro_periodos_euros: [];
  tipo_grafico: null;
  // IPeriod graph
  total_consumo_por_periodo: number[];
  total_consumo_red_por_periodo: number[];
  total_autoconsumo_por_periodo: number[];
  total_excedente_por_periodo: number[];
  total_generacion_por_periodo: number[];

}

export interface IDatoHorario {
  hora: string;
  consumo: number;
  consumo_red: number;
  autoconsumo: number;
  excedente: number;
  generacion: number;
  periodo: number;
}

export interface IDatoAcumulado {
  listDatos: IListDato[];
  periodo: number;
}

export interface IDatoAcumulado2 {
  fechas: string[];
  series: ISerie;
}

export interface ISerie {
  consumo: ISerieData[];
  consumo_red: ISerieData[];
  autoconsumo: ISerieData[];
  excedente: ISerieData[];
  generacion: ISerieData[];
}

export interface ISerieData {
  period: string;
  data: number[];
}

export interface IListDato {
  fecha: string;
  consumo: number;
  consumo_red: number;
  autoconsumo: number;
  excedente: number;
  generacion: number;
}

export interface IDatoHorarioEconomico {
  hora: string;
  importe_consumo: number;
  importe_ahorro: number;
  periodo: number;
}

export interface IDatoAcumuladoEconomico {
  hora: string;
  importe_consumo: number;
  importe_ahorro: number;
  periodo: number;
}

export interface IPeriod {
  startDate: string;
  endDate: string;
  period: number;
}

export enum Series {
  consumoTotal = 0,
  consumidaRed = 1,
  autoConsumida = 2,
  excedentaria = 3,
  netaGenerada = 4,
}


export interface IPeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export interface ISelfViewDataEconomico {
  total_factura: number;
  total_ahorro: number;
  total_compensacion: number;
  total_sin_compensacion: number;
  total_compensable: number;

  datosHorarios: IDatoHorarioEconomico[];
  datosAcumulados: IDatoAcumuladoEconomico[];

  tipo_grafico: string;

  ahorro_por_periodos: number[];
  porc_ahorro_por_periodos: number[];
  aprovechamiento_autoconsumo: number;
  aprovechamiento_compensacion: number;

  facturacion_por_periodos: number[];
  porc_facturacion_por_periodos: number[];

}

export interface ISelfViewDto {
  selfViewDataPower: ISelfViewData;
  selfViewDataEconomico: ISelfViewDataEconomico;
}

export enum GlobalType {
  Energy = 1,
  Economic = 2
}

export enum DataMode {
  Normal,
  Compare
}

