import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StyleService {
  private _apiStyles = environment.API_URL + '/api/entity/style';

  constructor(private http: HttpClient) { }

  getAllStyles(entityId: number): Observable<any> {
    return this.http
    .get(`${this._apiStyles}/2`)
    .pipe(map(response => {
      return <any>response;
    }));
  }

  deleteStyles(styleId: number): Observable<boolean> {
    return this.http.delete(`${this._apiStyles}/${styleId}`, {observe: 'response'}).pipe(map(res => {
      return res.status === 204;
    }));
  }

  createStyle(entityDomainId: number, data) {
    const createData = this.generateFormData(data);
    return this.http.post(`${this._apiStyles}/${entityDomainId}`, createData, {observe: 'response'})
      .pipe(map(res => {
        return res.status === 200;
      }));
  }

  updateStyles(entityDomainId: number, data) {
    const updateData = this.generateFormData(data);
    // const updateData = {
    //   entityDomainId: data.entityDomainId,
    //   entityDomainStyleId: data.entityDomainStyleId,
    //   description: data.description,
    //   colors: data.colors,
    //   css: data.css,
    //   logo: data.logo,
    //   activated: Boolean(data.activated),
    // };
    return this.http.put(`${this._apiStyles}/${entityDomainId}`, updateData, {observe: 'response'})
      .pipe(map(res => {
        return res.status === 200;
      }));
  }

  private generateFormData(data): FormData{
    const formData: FormData = new FormData();
    formData.append('Description', data.description);
    formData.append('Css', data.css);
    formData.append('Logo', data.logo);
    formData.append('Colors', data.colors);
    formData.append('Activated', data.activated);
    return formData;
  }
}
