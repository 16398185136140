import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgCheckboxComponent } from './ag-checkbox/ag-checkbox.component';
import { MenuComponent } from './menu/menu.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { NbPopoverModule, NbAccordionModule, NbButtonModule, NbCardModule } from '@nebular/theme';
import { DialogColorComponent } from './dialog-color/dialog-color.component';
import { MatFormFieldModule, MatInputModule, MatListModule, MatRippleModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';
import { AgBoolFilterComponent } from './ag-bool-filter/ag-bool-filter.component';
import { AgBoolFloatingFilterComponent } from './ag-bool-filter/ag-bool-floating-filter/ag-bool-floating-filter.component';

@NgModule({
  declarations: [
    AgCheckboxComponent,
    MenuComponent,
    ContextMenuComponent,
    DialogColorComponent,
    AgBoolFilterComponent,
    AgBoolFloatingFilterComponent,
  ],
  imports: [
    MatCardModule,
    MatCheckboxModule,
    NbPopoverModule,
    CommonModule,
    ColorPickerModule,
    FormsModule,
    ReactiveFormsModule,
    NbAccordionModule,
    NbButtonModule,
    MatFormFieldModule,
    MatInputModule,
    NbCardModule,
    TranslateModule,
    MatListModule,
    MatRippleModule,
  ],
  exports: [
    AgCheckboxComponent,
    MenuComponent,
    ContextMenuComponent,
    DialogColorComponent,
    AgBoolFilterComponent,
    AgBoolFloatingFilterComponent,
  ],
  entryComponents: [
    DialogColorComponent,
    AgBoolFilterComponent,
    AgBoolFloatingFilterComponent,
  ]
})
export class PPlusAgGridModule { }
