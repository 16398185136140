import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { TYPE_TIME, TimePicker } from '../time-picker.model';

@Component({
  selector: 'pplus-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit {
  @Output() time = new EventEmitter();
  @Input() seconds = true;

  data: TimePicker = {
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  constructor() { }

  ngOnInit() {
  }

  updateTime(type: TYPE_TIME, typeOp: boolean) {
    switch (type) {
      case TYPE_TIME.Hours:
        if (typeOp) {
          this.data.hours < 23 ? this.data.hours += 1 : null;
        } else {
          this.data.hours > 0 ? this.data.hours -= 1 : null;
        }
        break;
      case TYPE_TIME.Minutes:
        if (typeOp) {
          this.data.minutes < 60 ? this.data.minutes += 1 : null;
        } else {
          this.data.minutes > 0 ? this.data.minutes -= 1 : null;
        }
        break;
      case TYPE_TIME.Seconds:
        if (typeOp) {
          this.data.seconds < 60 ? this.data.seconds += 1 : null;
        } else {
          this.data.seconds > 0 ? this.data.seconds -= 1 : null;
        }
        break;
    }
    this.time.emit(this.data);
  }

}
