import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pplus-selfview-active-periods',
  templateUrl: './selfview-active-periods.component.html',
  styleUrls: ['./selfview-active-periods.component.scss']
})
export class SelfviewActivePeriodsComponent implements OnInit {
  @Output() updateType = new EventEmitter<boolean>();

  flipped: boolean;
  type;

  constructor() {
    this.flipped = true;
  }

  ngOnInit() {
  }

  changeType(): void {
    this.updateType.emit(this.type);
    this.type = !this.type;
  }


}
