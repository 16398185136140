import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF, DATE_FORMAT } from "../admin.model";
import * as moment from 'moment';

export const PLANT_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const PLANT_COL_DEFS: ColDef[] = [
  {
    field: 'plantId',
    headerName: 'ID',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'demoName',
    headerName: 'Demo Name',
  },
  {
    field: 'code',
    headerName: 'Code',
  },
  {
    field: 'demoCode',
    headerName: 'Demo Code',
  },
  {
    field: 'creationDate',
    headerName: 'Creation date',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.creationDate);
        return date.isValid() ? r.data.creationDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'deletionDate',
    headerName: 'Deletion date',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.deletionDate);
        return date.isValid() ? r.data.deletionDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'activated',
    headerName: 'Activated',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkboxComponent',
  },
  {
    field: 'nominalPower',
    headerName: 'Nominal Power (MW)',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'peakPower',
    headerName: 'Peak Power (MW)',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'ttrResponsible',
    headerName: 'TTR Responsible',
  },
  {
    field: 'losses',
    headerName: 'Losses',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'entityPlants',
    headerName: 'Owner Entity',
    valueFormatter: function (r) {
      if (r && r.data) {
        const entities = r.data.entityPlants;
        return entities && entities[0] ? entities[0].code : '';
      }
    },
  },
  {
    field: 'timeZone',
    headerName: 'Time Zone',
  },
  {
    field: 'location',
    headerName: 'Location',
  },
  {
    field: 'countryName',
    headerName: 'Country',
    valueFormatter: function (r) {
      if (r && r.data) {
        return r.data.countryName = r.data.country ? r.data.country.name : '';
      }
    },
  },
  {
    field: 'province',
    headerName: 'Province',
  },
  {
    field: 'population',
    headerName: 'Population',
  },
  {
    field: 'postalCode',
    headerName: 'Postal Code',
  },
  {
    field: 'utmCoordinates',
    headerName: 'UTMCoordinates',
  },
  {
    field: 'serviceProvided',
    headerName: 'Service Provided',
  },
  {
    field: 'serviceStartDate',
    headerName: 'Service Start Date',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.serviceStartDate);
        return date.isValid() ? r.data.serviceStartDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'serviceDetail',
    headerName: 'Service Detail',
  },
  {
    field: 'installationsNames',
    headerName: 'Installations',
    valueFormatter: function (r) {
      if (r && r.data) {
        let value = '';
        const installations = r.data.plantInstallations;
        if (installations && installations.length) for (const i of installations) {
          if (value) value += ', ';
          value += i.installation ? i.installation.name : '';
        }
        return r.data.installationsNames = value;
      }
    },
  },
  {
    field: 'meteoSensorsNames',
    headerName: 'Meteo Sensors',
    valueFormatter: function (r) {
      if (r && r.data) {
        let value = '';
        const meteoSensors = r.data.installationMeteoSensorViews;
        if (meteoSensors && meteoSensors.length) for (const ms of meteoSensors) {
          if (value) value += ', ';
          value += ms.model;
        }
        return r.data.meteoSensorsNames = value;
      }
    },
  },
  {
    field: 'metersNames',
    headerName: 'Meters',
    valueFormatter: function (r) {
      if (r && r.data) {
        let value = '';
        const meters = r.data.meterPlantInstallations;
        if (meters && meters.length) for (const m of meters) {
          if (value) value += ', ';
          value += m.name;
        }
        return r.data.metersNames = value;
      }
    },
  },
  {
    field: 'plantGroupsNames',
    headerName: 'Plant Groups',
    valueFormatter: function (r) {
      if (r && r.data) {
        let value = '';
        const groups = r.data.plantGroupPlants;
        if (groups && groups.length) for (const g of groups) {
          if (value) value += ', ';
          value += g.plantGroup ? g.plantGroup.name : '';
        }
        return r.data.plantGroupNames = value;
      }
    },
  },
];
