import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, tap } from 'rxjs/operators';
import { Entity } from '../models/entity.model';



@Injectable({
  providedIn: 'root'
})
export class EntitiesService {

  // private _userEntities = new BehaviorSubject<Entity[]>([]);
  // userEntities = this._userEntities.asObservable();

  // private _selectedEntities = new BehaviorSubject<Entity[]>([]);
  // selectedEntities = this._selectedEntities.asObservable();

  // Entidad seleccionada a través del dominio de entrada
  private _applicationEntity = new BehaviorSubject<Entity>(null);
  applicationEntity = this._applicationEntity.asObservable();


  constructor(private httpClient: HttpClient) { }

  searchByDescription(description: string): Observable<Entity[]> {
    return this.httpClient.get<Entity[]>(environment.API_URL + '/api/entity/searchByDescription?description=' + description).pipe(
      map(data => data));
  }


  // setUserEntities(entities: Entity[]) {
  //   this._userEntities.next(entities);
  // }

  // getUserEntities() {
  //   return this._userEntities.value;
  // }

  // setSelectedEntities(entities: Entity[]) {
  //   this._selectedEntities.next(entities);
  // }

  // getSelectedEntities() {
  //   return this._selectedEntities.value;
  // }

  setApplicationEntity(entity: Entity) {
    this._applicationEntity.next(entity);
  }

  getApplicationEntity() {
    return this._applicationEntity.value;
  }


  // getByUser(): Observable<Entity[]> {
  //   return this.httpClient.get<Entity[]>(
  //     environment.API_URL + '/api/entity/getByUser').pipe(tap(res => {
  //       this.setUserEntities(res);
  //     })
  //     );
  // }


  getByDomain(): Observable<Entity> {
    return this.httpClient.get<Entity>(
      environment.API_URL + '/api/entity/getByDomain').pipe(tap(res => {
//        this.setSelectedEntities([res]);
        this.setApplicationEntity(res);
      })
      );
  }
}
