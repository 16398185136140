import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF, DATE_FORMAT } from "../admin.model";
import * as moment from "moment";

export const INSTALLATION_METERS_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const INSTALLATION_METERS_COL_DEFS: ColDef[] = [
  {
    field: 'installationMeterId',
    headerName: 'ID',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'meterTypeId',
    headerName: 'Tipo',
    valueFormatter: function (r) {
      if (r && r.data) {
        const meterType = r.data.meterType;
        return meterType ? meterType.name : '';
      }
    },
  },
  {
    field: 'meterMarketId',
    headerName: 'Marca',
    valueFormatter: function (r) {
      if (r && r.data) {
        const meterMarket = r.data.meterMarket;
        return meterMarket ? meterMarket.name : '';
      }
    },
  },
  {
    field: 'meterModelId',
    headerName: 'Modelo',
    valueFormatter: function (r) {
      if (r && r.data) {
        const meterModel = r.data.meterModel;
        return meterModel ? meterModel.name : '';
      }
    },
  },
  {
    field: 'serialNumber',
    headerName: 'Número de serie',
  },
  {
    field: 'name',
    headerName: 'Nombre',
  },
  {
    field: 'code',
    headerName: 'Código',
  },
  {
    field: 'installationId',
    headerName: 'Instalación asociada',
    valueFormatter: function (r) {
      if (r && r.data) {
        const installation = r.data.installation;
        return installation ? installation.name : '';
      }
    },
  },
  {
    field: 'plantId',
    headerName: 'Planta asociada',
    valueFormatter: function (r) {
      if (r && r.data) {
        const plant = r.data.plant;
        return plant ? plant.name : '';
      }
    },
  },
  // plugins asociados
  {
    field: 'creationDate',
    headerName: 'Fecha de alta',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.creationDate);
        return date.isValid() ? r.data.creationDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'deletionDate',
    headerName: 'Fecha de baja',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.deletionDate);
        return date.isValid() ? r.data.deletionDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'note',
    headerName: 'Nota',
  },
  {
    field: 'readingStartDate',
    headerName: 'Fecha inicio lecturas',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.readingStartDate);
        return date.isValid() ? r.data.readingStartDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'lastReadDate',
    headerName: 'Fecha última lectura',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.lastReadDate);
        return date.isValid() ? r.data.lastReadDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'lastEventDate',
    headerName: 'Fecha último evento',
    filter: 'agDateColumnFilter',
    valueFormatter: function (r) {
      if (r && r.data) {
        const date = moment(r.data.lastEventDate);
        return date.isValid() ? r.data.lastEventDate = date.format(DATE_FORMAT) : '';
      }
    },
  },
  {
    field: 'instantTimeOut',
    headerName: 'Time out contadores instantáneos',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'historicalTimeOut',
    headerName: 'Time out contadores históricos',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'activated',
    headerName: 'Activo',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkboxComponent',
  },
  {
    field: 'addressLink',
    headerName: 'Dirección de enlace',
  },
  {
    field: 'pointMeter',
    headerName: 'Punto de medida',
  },
  {
    field: 'key',
    headerName: 'Clave',
  },
  {
    field: 'rtIntensity',
    headerName: 'R.T. Intensidad',
  },
  {
    field: 'rtTension',
    headerName: 'R.T. Tensión',
  },
  {
    field: 'meterProtocolId',
    headerName: 'Protocolo',
    valueFormatter: function (r) {
      if (r && r.data) {
        const meterProtocol = r.data.meterProtocol;
        return meterProtocol ? meterProtocol.name : '';
      }
    },
  },
  {
    field: 'typeConexion',
    headerName: 'Tipo de conexión',
  },
  {
    field: 'typeComunication',
    headerName: 'Tipo de comunicación',
  },
  {
    field: 'numberPhone',
    headerName: 'Nº teléfono',
  },
  {
    field: 'ip',
    headerName: 'Dirección IP',
  },
  {
    field: 'portTcp',
    headerName: 'Puerto TCP',
  },
  {
    field: 'addressClient',
    headerName: 'Dirección de cliente',
  },
  {
    field: 'password',
    headerName: 'Contraseña',
  },
  {
    field: 'physicalAddress',
    headerName: 'Dirección física',
  },
  {
    field: 'logicAddress',
    headerName: 'Dirección lógica',
  },
  {
    field: 'typeAuthentication',
    headerName: 'Tipo de autentificación',
  },
];
