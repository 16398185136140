import { ColDef } from "ag-grid-community";

export const STYLES_HEADERS: ColDef[] = [
  {
    field: 'entityDomainStyleId',
    headerName: 'ID',
    minWidth: 100,
    resizable: true,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'activated',
    headerName: 'Activated',
    minWidth: 100,
    resizable: true,
    filter: false,
    cellRenderer: 'checkBoxComponent',
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 100,
    resizable: true,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'logo',
    headerName: 'Logo',
    minWidth: 100,
    resizable: true,
    filter: 'agTextColumnFilter',
  },
  {
    field: 'colors',
    headerName: 'Colors',
    minWidth: 100,
    resizable: true,
    filter: 'agTextColumnFilter',
  },
];
