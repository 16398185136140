import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { map } from 'rxjs/operators';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { PlantService } from '../plant.service';
import { IEntityPlant } from 'projects/pplus/src/lib/interfaces/interfaces';
import { forkJoin } from 'rxjs';
import { EntitiesService } from '../../entities/entities.service';
import { PlantGroupService } from '../../plant-group/plant-group.service';
import { AdminService } from '../../admin.service';
import { PlantDetailsService } from './plant-details.service';
import * as moment from 'moment';
import { DATE_FORMAT } from '../../admin.model';

@Component({
  selector: 'app-plant-details',
  templateUrl: './plant-details.component.html',
  styleUrls: ['./plant-details.component.scss']
})
export class PlantDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  data: IEntityPlant;

  formGroup: FormGroup;
  formData: DynamicForm[];

  constructor(
    private dialog: NbDialogService,
    private dataRow: RowDataService,
    private plantService: PlantService,
    private plantGroupService: PlantGroupService,
    private entitiesService: EntitiesService,
    private adminService: AdminService,
    private plantDetailsService: PlantDetailsService,
  ) {
    this.formData = this.plantDetailsService.getDynamicForm();
  }

  private generateForm(data: DynamicForm []) {
    const fieldsCtrls = {};
    data.forEach(item => {
      const fields = item.fields;
      fields.forEach(f => {
        fieldsCtrls[f.name] = new FormControl(f.value == null ? '' : f.value, f.validators);
      });
    });
    this.formGroup = new FormGroup(fieldsCtrls);
  }

  ngOnInit() {
    forkJoin([
      this.entitiesService.getEntitiesList(),
      this.plantGroupService.getList(),
      this.plantService.getCountries(),
      this.plantService.getInstallationsList(),
      this.plantService.getMetersList(),
      this.plantService.getMeteoSensorsList(),
    ]).subscribe(([entities, plantGroups, countries, installations, meters, meteoSensors]) => {
      this.formData.map(o => {
        o.fields.forEach(f => {
          if (f.name === 'ownerEntityId') {
            const opts = [];
            entities.map(e => {
              opts.push({
                value: e.entityId,
                label: e.code,
              });
            });
            f.options = opts;
          }
          else
          if (f.name === 'plantGroups') {
            const opts = [];
            plantGroups.map(g => {
              opts.push({
                value: g.plantGroupId,
                label: g.name,
              });
            });
            f.options = opts;
          }
          else
          if (f.name === 'countryId') {
            const opts = [];
            countries.map(c => {
              opts.push({
                value: c.countryId,
                label: c.name,
              });
            });
            f.options = opts;
          }
          else
          if (f.name === 'installations') {
            const opts = [];
            installations.map(c => {
              opts.push({
                value: c.installationId,
                label: c.name,
              });
            });
            f.options = opts;
          }
          else
          if (f.name === 'assignedMeters') {
            const opts = [];
            meters.map(c => {
              opts.push({
                value: c.meterPlantInstallationId,
                label: c.name,
              });
            });
            f.options = opts;
          }
          else
          if (f.name === 'meteoSensors') {
            const opts = [];
            meteoSensors.map(c => {
              opts.push({
                value: c.meteoSensorId,
                label: c.model,
              });
            });
            f.options = opts;
          }
        });
      });
      this.generateForm(this.formData);
    });

    this.dataRow.currentRowData.pipe(map(o => {
      this.formData.forEach(data => {
        const fields = data.fields;
        fields.map(field => {
          if (field.name === 'plantGroups') {
            const plantGroups = o['plantGroupPlants'];
            const value = [];
            if (plantGroups && plantGroups.length)
              for (const g of plantGroups) value.push(g.plantGroupId);
            field.value = value;
          }
          else
          if (field.name === 'installations') {
            const plantInstallations = o['plantInstallations'];
            const value = [];
            if (plantInstallations && plantInstallations.length)
              for (const g of plantInstallations) value.push(g.installationId);
            field.value = value;
          }
          else
          if (field.name === 'assignedMeters') {
            const meterPlantInstallations = o['meterPlantInstallations'];
            const value = [];
            if (meterPlantInstallations && meterPlantInstallations.length)
              for (const g of meterPlantInstallations) value.push(g.meterPlantInstallationId);
            field.value = value;
          }
          else
          if (field.name === 'meteoSensors') {
            const meteoSensorPlantInstallation = o['meteoSensorPlantInstallation'];
            const value = [];
            if (meteoSensorPlantInstallation && meteoSensorPlantInstallation.length)
              for (const g of meteoSensorPlantInstallation) value.push(g.meteoSensorId);
            field.value = value;
          }
          else field.value = o[field.name];
        });
      });
      return o;
    })).subscribe(data => {
      this.data = <IEntityPlant>data;
      this.generateForm(this.formData);
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IEntityPlant>this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.plantService.createPlant(data).subscribe(newPlant => {
        if (newPlant != null) {
          newPlant.creationDate = moment(newPlant.creationDate).format(DATE_FORMAT);
          newPlant.deletionDate = moment(newPlant.deletionDate).format(DATE_FORMAT);
          newPlant.serviceStartDate = moment(newPlant.serviceStartDate).format(DATE_FORMAT);
          this.formGroup.patchValue(Object.assign(this.data, newPlant, {ownerEntityId: data.ownerEntityId}));
          forkJoin([
            this.plantService.assignPlantGroups(newPlant.plantId, data.plantGroups),
            this.plantService.assignPlantInstallations(newPlant.plantId, data.installations),
            this.plantService.assignMeteoSensors(newPlant.plantId, data.meteoSensors),
            this.plantService.assignMeters(newPlant.plantId, data.assignedMeters),
            this.plantService.assignOwnerEntity(newPlant.plantId, data.ownerEntityId),
          ]).subscribe(success => {
            if (success.every(b => b === true)) {
              this.adminService.showToast('success', 'Se ha creado la nueva planta: ' + data.name, 'Elemento creado');
              this.updateDelete.emit(true);
            } else {
              this.adminService.showToast('warning',
                  'Se ha producido un error al asignar relaciones a la nueva planta: ' + data.name,
                  'Error');
            }
          });
        } else {
          this.adminService.showToast('danger', 'Se ha producido un error al crear la planta: ' + data.name, 'Error');
        }
      });
    }
  }

  update() {
    const plantId = this.data.plantId;
    if (plantId == null) {
      this.onSubmit();
    } else if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IEntityPlant>this.formGroup.value;
      data.activated = Boolean(data.activated);
      forkJoin([
        this.plantService.updatePlant(plantId, data),
        this.plantService.assignPlantGroups(plantId, data.plantGroups),
        this.plantService.assignPlantInstallations(plantId, data.installations),
        this.plantService.assignMeteoSensors(plantId, data.meteoSensors),
        this.plantService.assignMeters(plantId, data.assignedMeters),
        this.plantService.assignOwnerEntity(plantId, data.ownerEntityId),
      ]).subscribe(success => {
        if (success.every(b => b === true)) {
          this.adminService.showToast('success', 'Se ha actualizado la planta: ' + data.name, 'Elemento actualizado');
          this.updateDelete.emit();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al actualizar la planta: ' + data.name,
              'Error');
        }
      });
    }
  }

  close() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  delete() {
    if (this.data.plantId == null) {
      this.adminService.showToast('info', 'Seleccione una planta', 'Info');
    } else {
      this.plantService.deletePlant(this.data.plantId).subscribe(success => {
        if (success) {
          this.adminService.showToast('success', 'Se ha borrado la planta: ' + this.data.name, 'Elemento eliminado');
          this.updateDelete.emit();
          this.clearData();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al borrar la planta: ' + this.data.name,
              'Error');
        }
      });
    }
  }

  copy() {
    if (this.data.plantId == null) {
      this.adminService.showToast('info', 'Seleccione una planta para copiar la información', 'Info');
    } else {
      this.formGroup.patchValue((Object.assign(this.data, <IEntityPlant>{
        plantId: null,
        name: '',
        demoName: '',
        code: null,
        demoCode: null,
        creationDate: '',
        deletionDate: '',
        nominalPower: 0,
        peakPower: 0,
        entityPlants: null,
      })));
    }
  }

  clearData() {
    this.data = <IEntityPlant>{};
    this.formGroup.reset();
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.plantId == null) {
      this.adminService.showToast('info', 'No hay ninguna planta seleccionada', 'Info');
    } else {
      this.dialog.open(dialog, { context: '¿Desea eliminar la planta?', hasScroll: false });
    }
  }

}
