import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UsersService } from '../../../services/users.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { User } from '../../../models/user.model';

@Component({
  selector: 'pplus-autocomplete-users',
  templateUrl: './autocomplete-users.component.html',
  styleUrls: ['./autocomplete-users.component.scss']
})
export class AutocompleteUsersComponent implements OnInit {
  users: any;
  isLoading = false;
  formControl = new FormControl();
  selectedValue: User;

  constructor(private usersService: UsersService) { }

  ngOnInit() {
    this.selectedValue = null;
    this.initSearch();
  }

  initSearch() {
    this.formControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.users = [];
          this.isLoading = true;
        }),
        switchMap(value => this.usersService.searchByFullName(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(data => {
        this.users = data;
      });
  }


  displayFn(value?: any) {
    return value ? value.fullName + ' [' + value.userName + ']' : '-';
  }


  optionSelected(value) {
    this.selectedValue = value;
  }

}
