import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ConsumptionBorderMeterService } from '../../consumption-border-meter.service';
import { PPlusChartType } from '../../../../../models/pplus.enums';
import { Subscription } from 'rxjs';
import { ChartColors } from '../../consumption-border-meter.chart.colors';
import { MeterEnergyByPeriod, MeterGroupedEnergyByPeriod, MeterEnergyByPeriodResults, MeterPlantInstallation } from '../../consumption-border-meter.models';
import { ENERGY_LINE_CHART_OPTIONS, ENERGY_BAR_CHART_OPTIONS } from './energy-chart.config';
import { IPeriod } from 'src/app/modules/selfview/models/selfview.model';
import { chartColors } from 'src/app/modules/selfview/selfview.graph.colors';
@Component({
  selector: 'pplus-energy-chart',
  templateUrl: './energy-chart.component.html',
  styleUrls: ['./energy-chart.component.scss']
})
export class EnergyChartComponent implements OnInit, OnDestroy {
  @Input() title;
  @Input() property;
  @Input() meters: MeterPlantInstallation[];
  @Input() height: number;

  private subscriptions: Subscription = new Subscription();

  /**
   * Data of energy chart component
   */
  data: MeterEnergyByPeriodResults;

  /**
   * Initial chart options
   */
  initOpts: any;

  /**
   * Chart options
   */
  chartOptions;

  /**
   * Chart periods
   */
  periods: IPeriod[] = [];

  /**
   * Chart colors
   */
  chartColors = chartColors;

  constructor(
    private meterService: ConsumptionBorderMeterService
  ) { }

  ngOnInit() {
    this.initOpts = {
      renderer: 'svg',
      width: 'auto',
      height: this.height !== null ? this.height : 'auto'
    };

    this.subscriptions = this.meterService.energyData$.subscribe((data: MeterEnergyByPeriodResults) => {
      if (data && data.meterData && data.meterData.length) {
        this.data = data;
        if (this.data.chartType === PPlusChartType.Bar) {
          this.initBarChart();
        } else {
          this.initLineChart();
        }
      }
    });
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Inits line chart
   */
  initLineChart() {
    const series = this.generateLineSeries();
    this.chartOptions = {...ENERGY_LINE_CHART_OPTIONS};
    this.chartOptions.series = [...series];

    this.initiatePeriods();

    this.chartOptions = {...this.chartOptions, ...this.chartOptions};
  }


  /**
   * Inits bar chart
   */
  initBarChart() {
    const series = this.generateBarSeries();
    this.chartOptions = {...ENERGY_BAR_CHART_OPTIONS};
    this.chartOptions.xAxis[0].data = this.data.meterData[0].groupedData.map(item => {
      const time = new Date(item.date);
      return time.toLocaleDateString('es-ES');
    });
    this.chartOptions.series = [...series];

    this.chartOptions = {...this.chartOptions, ...this.chartOptions};
  }

  /**
   * Generates line series
   * @returns
   */
  generateLineSeries() {
    const series = [];
    this.data.meterData.forEach((element: MeterEnergyByPeriod, i: number) => {
      const serieData =  element.hourlyData.map( item => {
        return [item.hour, item[this.property]];
      });

      series.push({
        name: this.meters[i].meterId,
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: serieData,
        xAxisIndex: 0,
        color: ChartColors.genereratedII
      });
    });

    if (this.data.totalData && this.data.totalData.hourlyData && this.data.totalData.hourlyData.length) {

      const serieData =  this.data.totalData.hourlyData.map( item => {
        return [item.hour, item[this.property]];
      });

      series.push({
        name: 'Total',
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: serieData,
        xAxisIndex: 0,
        color: ChartColors.total
      });
    }
    return series;
  }

  /**
   * Generates series for reactive bar chart
   */
  generateBarSeries() {
    const series = [];

    this.data.meterData.forEach((element: MeterEnergyByPeriod, indexMeter: number) => {


        for (let i = 0; i < 6; i++) {
          const serieData = element.groupedData.map( (meterGroupedEnergyByPeriod: MeterGroupedEnergyByPeriod) => {
            return meterGroupedEnergyByPeriod[this.property + 'ByPeriod'][i];
          });

          series.push(this.createBarChartSerie(
            this.meters[indexMeter].meterId + '  P' + (i + 1),
            serieData,
            chartColors.periods['consumoP' + (i + 1)],
            this.meters[indexMeter].meterId.toString()
          ));
        }

    });

    if (this.data.totalData && this.data.totalData.groupedData && this.data.totalData.groupedData.length) {
      for (let i = 0; i < 6; i++) {
        const serieData = this.data.totalData.groupedData.map( (meterGroupedEnergyByPeriod: MeterGroupedEnergyByPeriod) => {
          return meterGroupedEnergyByPeriod[this.property + 'ByPeriod'][i];
        });

        series.push(this.createBarChartSerie(
          'Total P' + (i + 1),
          serieData,
          chartColors.periods['autoconsumoP' + (i + 1)],
          'total'
        ));
      }
    }

    return series;
  }

  /**
   * Creates bar chart serie
   * @param name: serie name
   * @param data: serie data
   * @param color: serie hex color
   * @param stack: serie stack
   * @returns
   */
  createBarChartSerie(name: string, data: any, color: string, stack: string) {
    return {
      name,
      type: 'bar',
      data,
      itemStyle: {color},
      xAxisIndex: 0,
      stack
    };
}

  /**
   * Initiates periods for reactive area chart
   * @param data: chart data
   */
  initiatePeriods() {
    const data = this.data.meterData[0].hourlyData;
    if (data[0]) {
      this.periods = [];
      let currentPeriod = data[0].period;
      let startDateDate = data[0].hour;
      let endDateDate = '';

      for (let i = 0; i < data.length; i++) {

        if (currentPeriod !== data[i].period) {
          endDateDate = data[i - 1].hour;
          this.periods.push({startDate: startDateDate, endDate: endDateDate, period: currentPeriod});
          startDateDate = endDateDate;
        }

        if (i === (data.length - 1)) {
          if (this.periods[this.periods.length - 1].period !== data[i].period) {
            endDateDate = data[i].hour;
            this.periods.push({startDate: startDateDate, endDate: endDateDate, period: data[i].period});
          }
        }

        currentPeriod = data[i].period;
      }
    }
    this.paintPeriods();
  }

  /**
   * Paints periods
   */
  paintPeriods() {
    const markers = [];
    for (const period of this.periods) {
      const marker = [
        {name: 'P' + period.period, xAxis: period.startDate, yAxis: 'min'},
        {yAxis: 'max', xAxis: period.endDate}
      ];
      markers.push(marker);
    }

    // create periods fake serie and concats to all series
    const periodsSerie = this.createPeriodsFakeSerie(markers);
    this.chartOptions.series = this.chartOptions.series.concat([periodsSerie]);
  }

  /**
   * Creates periods fake serie
   * @param data: serie data
   * @returns serie
   */
  createPeriodsFakeSerie(data: any) {

    return {
      name: 'periods',
      type: 'line',
      markArea: {
        label: {
          show: true,
          position: 'top',
          textStyle:{
            color: '#000',
            fontStyle: 'italic'
          }
        },
        itemStyle: {
          color: 'rgba(38,44,44,0.11)',
          borderColor: 'rgba(38,44,44,0.27)',
          borderWidth: 0.5
        },
        data
      }
   };
  }

}
