import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IEntityAppType } from 'projects/pplus/src/lib/interfaces/interfaces';
import { APP_TYPE_COL_DEFS } from './app-type.model';
@Injectable({
  providedIn: 'root'
})
export class AppTypeService {
  private apiUrl = environment.API_URL + '/api/application/applicationtype';

  constructor(private http: HttpClient) {}

  getAllAppTypesInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const body = {
      startRow,
      endRow,
      sortModel,
      filterModel
    };
    const url = `${this.apiUrl}/get-appTypes`;
    return this.http
      .post(url, body)
      .pipe(map((response) => {
        return <any>response;
      }));
  }

  getAllAppTypes(): Observable<IEntityAppType[]> {
    return this.http.get(this.apiUrl).pipe(
      map(response => <IEntityAppType[]>response),
      catchError(() => of([]))
    );
  }

  getAppType(id: number): Observable<IEntityAppType> {
    return this.http.get(`${this.apiUrl}/${id}`).pipe(
      map(response => <IEntityAppType>response),
      catchError(() => of(null))
    );
  }

  createAppType(data: IEntityAppType): Observable<IEntityAppType> {
    return this.http.post(this.apiUrl, data, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? <IEntityAppType>response.body : null),
      catchError(() => of(null))
    );
  }

  updateAppType(id: number, data: IEntityAppType): Observable<boolean> {
    return this.http.put(`${this.apiUrl}/${id}`, data, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  deleteAppType(id: number): Observable<boolean> {
    return this.http.delete(`${this.apiUrl}/${id}`, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  getAppTypesGeneralFilter(
    value: string, startRow: number, endRow: number, sortModel: any, filterModel: any
  ): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of APP_TYPE_COL_DEFS) columnList.push(colDef.field);
    const body = {
      startRow,
      endRow,
      sortModel,
      filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel
      },
      columnList,
    };
    const url = `${this.apiUrl}/get-appTypes`;
    return this.http.post(url, body).pipe(map((response) => {
      return <any>response;
    }));
  }

}
