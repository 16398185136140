import { Component, OnInit } from '@angular/core';
import { PVSelfCalculationService } from '../../../services/pvself-calculation.service';
import { PVSelfCalculation } from '../../../models/PvSelfCalculation.model';
import { PVSelfFinancialResults, PVSelfEconomicSummary } from '../../../models/PVSelfResultsModel.model';
import { PVSelfEngineService } from '../../../services/pvself-engine.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-financial-summary',
  templateUrl: './financial-summary.component.html',
  styleUrls: ['./financial-summary.component.scss']
})


export class FinancialSummaryComponent implements OnInit {
  _calculation: PVSelfCalculation;
  _financialResults: PVSelfFinancialResults;
  selectedConsumerIndex: number;
  //  economicSummaryResults: PVSelfEconomicSummary[] = [];
  loaderId: string;
  dirty: boolean;

  gridCostsSavingsApi;
  gridEvolutionApi;

  periodicity = [
    { id: 1, name: 'COMMON.ANNUAL' },
    { id: 2, name: 'COMMON.SIXMONTHLY' },
    { id: 3, name: 'COMMON.FOURMONTHLY' },
    { id: 4, name: 'COMMON.QUARTERLY' },
    { id: 5, name: 'COMMON.BIMONTHLY' },
    { id: 6, name: 'COMMON.MONTHLY' },
    { id: 7, name: 'COMMON.FORTNIGHTLY' },
  ];

  columnEvolutionDefs = [
    {
      headerName: 'Costes y ahorros mensuales',
      children: [
        { headerName: 'Año', field: 'period', resizable: true },
        { headerName: 'Pago banco [€]', field: 'loanFee', resizable: true, valueFormatter: this.numberFormatter },
        { headerName: 'Costes [€]', field: 'outputs', resizable: true, valueFormatter: this.numberFormatter },
        { headerName: 'Ingresos por autoconsumo [€]', field: 'selfConsumptionIncome', resizable: true, valueFormatter: this.numberFormatter },
        { headerName: 'Ingresos por venta [€]', field: 'saleIncome', resizable: true, valueFormatter: this.numberFormatter },
        { headerName: 'Balance [€]', field: 'balance', resizable: true, valueFormatter: this.numberFormatter },
        { headerName: 'Balance acumulado [€]', field: 'accumulatedBalance', resizable: true, valueFormatter: this.numberFormatter }
      ]
    }
  ];


  constructor(private calculationService: PVSelfCalculationService,
    private engineService: PVSelfEngineService,
    private ngxLoaderService: NgxUiLoaderService) { }


  ngOnInit() {
    this.loaderId = 'loader-financial-' + Math.floor((Math.random() * 100) + 1);
    this.selectedConsumerIndex = 0;
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
    //    this._calculation = this.calculationService.get();
    this._financialResults = new PVSelfFinancialResults();
    this.dirty = true;
  }



  calcular() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.engineService.calculateFinancialResults(this._calculation).subscribe(res => {
      this._financialResults = res;
      this.ngxLoaderService.stopLoader(this.loaderId);
    },
      err => {
        this.ngxLoaderService.stopLoader(this.loaderId);
      });

  }


  /*
  calculateCostsAndSavings() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.engineService.calculateEconomicSummaryResults(this._calculation).subscribe(res => {
      this.economicSummaryResults = res.monthlySummary;

      this.economicSummaryResults[0].month = this.translateService.instant('COMMON.MONTHS.JANUARY');
      this.economicSummaryResults[1].month = this.translateService.instant('COMMON.MONTHS.FEBRUARY');
      this.economicSummaryResults[2].month = this.translateService.instant('COMMON.MONTHS.MARCH');
      this.economicSummaryResults[3].month = this.translateService.instant('COMMON.MONTHS.APRIL');
      this.economicSummaryResults[4].month = this.translateService.instant('COMMON.MONTHS.MAY');
      this.economicSummaryResults[5].month = this.translateService.instant('COMMON.MONTHS.JUNE');
      this.economicSummaryResults[6].month = this.translateService.instant('COMMON.MONTHS.JULY');
      this.economicSummaryResults[7].month = this.translateService.instant('COMMON.MONTHS.AUGUST');
      this.economicSummaryResults[8].month = this.translateService.instant('COMMON.MONTHS.SEPTEMBER');
      this.economicSummaryResults[9].month = this.translateService.instant('COMMON.MONTHS.OCTOBER');
      this.economicSummaryResults[10].month = this.translateService.instant('COMMON.MONTHS.NOVEMBER');
      this.economicSummaryResults[11].month = this.translateService.instant('COMMON.MONTHS.DECEMBER');
      this.economicSummaryResults[12].month = this.translateService.instant('COMMON.TOTAL');

      this.ngxLoaderService.stopLoader(this.loaderId);
    },
      err => {
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
  }
  */



  numberFormatter(params) {
    return (params.value).toFixed(2);
  }


  onCostsGridReady(params: any) {
    this.gridCostsSavingsApi = params.api;

    //    this.gridCostsSavingsApi.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        //        this.gridCostsSavingsApi.sizeColumnsToFit();
      });
    });
  }



  onEvolutionGridReady(params: any) {
    this.gridEvolutionApi = params.api;

    //    this.gridEvolutionApi.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        //        this.gridEvolutionApi.sizeColumnsToFit();
      });
    });
  }

}
