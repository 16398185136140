import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PVSelfCalculation } from '../models/PvSelfCalculation.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
import { PVSelfMyConfigurationService } from './pvself-my-configuration.service';
import { PvselfConsumersService } from './pvself-consumers.service';
import { PVSelfCalculationTabsService } from './pvself-calculation-tabs.service';
import { PVSelfProducersService } from './pvself-producers.service';

@Injectable({
  providedIn: 'root'
})
export class PVSelfCalculationService {

  private _calculation = new BehaviorSubject<PVSelfCalculation>(new PVSelfCalculation());
  calculation = this._calculation.asObservable();

  constructor(private httpClient: HttpClient,
    private myConfigurationService: PVSelfMyConfigurationService,
    private consumersService: PvselfConsumersService,
    private calculationTabsService: PVSelfCalculationTabsService,
    private producersService: PVSelfProducersService) {
  }

  set(calculation: PVSelfCalculation) {
    this._calculation.next(calculation);
    this.consumersService.setConsumers(calculation.consumers);
    this.producersService.setProducers(calculation.producers);
  }

  setId(calculation: PVSelfCalculation) {
    this._calculation.next(calculation);
    this.consumersService.setConsumers(calculation.consumers);
    this.producersService.setProducers(calculation.producers);
  }

  get() {
    return this._calculation.value;
  }

  lock() {
    this._calculation.value.locked = true;
  }

  updateCalculation() {
    const activeTab = this.calculationTabsService.get().dynamicTabs.filter(tab => tab.active)[0];
    this._calculation.value.description = activeTab.title;
    this._calculation.value.producers = this.producersService.getProducers();
    this._calculation.value.consumers = this.consumersService.getConsumers();
    return this._calculation.value;
  }


  createCalculation(entityId: number): Promise<PVSelfCalculation> {

    return new Promise((resolve, reject) => {
      const newCalculation: PVSelfCalculation = new PVSelfCalculation();
      this.myConfigurationService.getEntityMyConfiguration(entityId).subscribe(res => {
        newCalculation.producers[0].installation.latitude = res.latitude;
        newCalculation.producers[0].installation.longitude = res.longitude;

        newCalculation.producers[0].installation.radiationData.sourceMeteorologicalDataId = res.radiationData.sourceMeteorologicalDataId;
        newCalculation.producers[0].installation.radiationData.latitude = res.radiationData.latitude;
        newCalculation.producers[0].installation.radiationData.longitude = res.radiationData.longitude;
        newCalculation.producers[0].installation.radiationData.month1 = res.radiationData.month1;
        newCalculation.producers[0].installation.radiationData.month2 = res.radiationData.month2;
        newCalculation.producers[0].installation.radiationData.month3 = res.radiationData.month3;
        newCalculation.producers[0].installation.radiationData.month4 = res.radiationData.month4;
        newCalculation.producers[0].installation.radiationData.month5 = res.radiationData.month5;
        newCalculation.producers[0].installation.radiationData.month6 = res.radiationData.month6;
        newCalculation.producers[0].installation.radiationData.month7 = res.radiationData.month7;
        newCalculation.producers[0].installation.radiationData.month8 = res.radiationData.month8;
        newCalculation.producers[0].installation.radiationData.month9 = res.radiationData.month9;
        newCalculation.producers[0].installation.radiationData.month10 = res.radiationData.month10;
        newCalculation.producers[0].installation.radiationData.month11 = res.radiationData.month11;
        newCalculation.producers[0].installation.radiationData.month12 = res.radiationData.month12;
        newCalculation.producers[0].installation.radiationData.total = res.radiationData.total;

        newCalculation.producers[0].installation.minTempData.sourceMeteorologicalDataId = res.minTempData.sourceMeteorologicalDataId;
        newCalculation.producers[0].installation.minTempData.latitude = res.minTempData.latitude;
        newCalculation.producers[0].installation.minTempData.longitude = res.minTempData.longitude;
        newCalculation.producers[0].installation.minTempData.month1 = res.minTempData.month1;
        newCalculation.producers[0].installation.minTempData.month2 = res.minTempData.month2;
        newCalculation.producers[0].installation.minTempData.month3 = res.minTempData.month3;
        newCalculation.producers[0].installation.minTempData.month4 = res.minTempData.month4;
        newCalculation.producers[0].installation.minTempData.month5 = res.minTempData.month5;
        newCalculation.producers[0].installation.minTempData.month6 = res.minTempData.month6;
        newCalculation.producers[0].installation.minTempData.month7 = res.minTempData.month7;
        newCalculation.producers[0].installation.minTempData.month8 = res.minTempData.month8;
        newCalculation.producers[0].installation.minTempData.month9 = res.minTempData.month9;
        newCalculation.producers[0].installation.minTempData.month10 = res.minTempData.month10;
        newCalculation.producers[0].installation.minTempData.month11 = res.minTempData.month11;
        newCalculation.producers[0].installation.minTempData.month12 = res.minTempData.month12;
        newCalculation.producers[0].installation.minTempData.total = res.minTempData.total;

        newCalculation.producers[0].installation.maxTempData.sourceMeteorologicalDataId = res.maxTempData.sourceMeteorologicalDataId;
        newCalculation.producers[0].installation.maxTempData.latitude = res.maxTempData.latitude;
        newCalculation.producers[0].installation.maxTempData.longitude = res.maxTempData.longitude;
        newCalculation.producers[0].installation.maxTempData.month1 = res.maxTempData.month1;
        newCalculation.producers[0].installation.maxTempData.month2 = res.maxTempData.month2;
        newCalculation.producers[0].installation.maxTempData.month3 = res.maxTempData.month3;
        newCalculation.producers[0].installation.maxTempData.month4 = res.maxTempData.month4;
        newCalculation.producers[0].installation.maxTempData.month5 = res.maxTempData.month5;
        newCalculation.producers[0].installation.maxTempData.month6 = res.maxTempData.month6;
        newCalculation.producers[0].installation.maxTempData.month7 = res.maxTempData.month7;
        newCalculation.producers[0].installation.maxTempData.month8 = res.maxTempData.month8;
        newCalculation.producers[0].installation.maxTempData.month9 = res.maxTempData.month9;
        newCalculation.producers[0].installation.maxTempData.month10 = res.maxTempData.month10;
        newCalculation.producers[0].installation.maxTempData.month11 = res.maxTempData.month11;
        newCalculation.producers[0].installation.maxTempData.month12 = res.maxTempData.month12;
        newCalculation.producers[0].installation.maxTempData.total = res.maxTempData.total;

        newCalculation.producers[0].installation.fields[0].panelId = res.panelId;
        newCalculation.producers[0].installation.fields[0].panelManufacturerId = res.panelManufacturerId;
        newCalculation.producers[0].installation.fields[0].inverterId = res.inverterId;
        newCalculation.producers[0].installation.fields[0].inverterManufacturerId = res.inverterManufacturerId;
        newCalculation.producers[0].installation.fields[0].numOfInverters = res.numOfInverters;
        newCalculation.producers[0].installation.fields[0].numInverterSerials = res.numInverterSerials;
        newCalculation.producers[0].installation.fields[0].numSerialPanels = res.numSerialPanels;
        newCalculation.producers[0].installation.fields[0].inclination = res.inclination;
        newCalculation.producers[0].installation.fields[0].orientation = res.orientation;
        newCalculation.producers[0].installation.fields[0].technology = res.technology;

        newCalculation.predimensionedValues = res.predimensionedValues;
        newCalculation.electricIPC = res.electricIPC;
        newCalculation.electricityPurchaseTax = res.electricityPurchaseTax;
        newCalculation.electricitySaleTax = res.electricitySaleTax;
        newCalculation.purchaseIVA = res.purchaseIVA;
        newCalculation.saleIVA = res.saleIVA;
        newCalculation.deductibleIVA = res.deductibleIVA;
        //        newCalculation.financialInterest = res.financialInterest;
        newCalculation.generalIPC = res.generalIPC;


        newCalculation.consumers[0].consumptionTemplateId = res.consumptionTemplateId;
        newCalculation.consumers[0].consumptionTemplateUsageId = res.consumptionTemplateUsageId;
        newCalculation.consumers[0].dailyHabits = res.consumptionHabits.daily;
        newCalculation.consumers[0].weeklyHabits = res.consumptionHabits.weekly;
        newCalculation.consumers[0].monthlyHabits = res.consumptionHabits.monthly;
        newCalculation.consumers[0].marketType = res.marketType;
        newCalculation.consumers[0].rates[0].billingPeriod = res.billingPeriod;
        newCalculation.consumers[0].rates[0].contracts = res.contracts;
        newCalculation.consumers[0].rates[0].rateId = res.consumptionRateId;

        resolve(newCalculation);
      });
    });
  }

  openCalculation(idCalculation: number): Observable<PVSelfCalculation> {
    return this.httpClient.get<PVSelfCalculation>(
      environment.API_URL + '/api/pvself/calculations/get/' + idCalculation).pipe(tap(data => {
      })
      );
  }


  saveCalculation(): Observable<PVSelfCalculation> {
    const calculation = this.updateCalculation();
    return this.httpClient.post<PVSelfCalculation>(
      environment.API_URL + '/api/pvself/calculations/save', calculation).pipe(data => data);
  }

  lockCalculation(): Observable<boolean> {
    const calculation = this.updateCalculation();
    return this.httpClient.post<boolean>(
      environment.API_URL + '/api/pvself/calculations/lock/' + calculation.pvSelfCalculationId, '').pipe(data => data);
  }


  getByActiveUser(): Observable<PVSelfCalculation[]> {
    return this.httpClient.get<PVSelfCalculation[]>(
      environment.API_URL + '/api/pvself/calculations/getProByActiveUser').pipe(data => data);
  }
}

