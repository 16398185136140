import { Injectable } from '@angular/core';
import { DynamicForm, Fields } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { Validators } from '@angular/forms';
import { REGEXPS } from '../../admin.model';

@Injectable({
  providedIn: 'root'
})
export class InstallationDetailsService {

  private readonly generalFields: Fields [] = [
    {
      name: 'installationId',
      type: 'text',
      placeholder: 'ID',
      value: '',
      disabled: true,
    },
    {
      name: 'name',
      type: 'text',
      placeholder: 'NOMBRE',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(100),
      ],
    },
    {
      name: 'nameDemo',
      type: 'text',
      placeholder: 'NOMBRE DEMO',
      value: '',
      validators: [
        Validators.maxLength(100),
      ],
    },
    {
      name: 'code',
      type: 'text',
      placeholder: 'CODE',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(13),
      ],
    },
    {
      name: 'codeDemo',
      type: 'text',
      placeholder: 'NOMBRE CÓDIGO',
      value: '',
      validators: [
        Validators.maxLength(13),
      ],
    },
    {
      name: 'technology',
      type: 'select',
      label: 'TECNOLOGÍA DE INSTALACIÓN',
      value: '',
      options: [],
      validators: [
        Validators.required
      ]
    },
    {
      name: 'tipology',
      type: 'select',
      label: 'TIPOLOGÍA DE INSTALACIÓN',
      value: '',
      options: [],
      validators: [
        Validators.required
      ]
    },
    {
      name: 'creationDate',
      type: 'text',
      placeholder: 'FECHA DE ALTA',
      value: '',
      disabled: true,
    },
    {
      name: 'deletionDate',
      type: 'text',
      placeholder: 'FECHA DE BAJA',
      value: '',
      disabled: true,
    },
    {
      name: 'activated',
      type: 'checkbox',
      placeholder: 'ACTIVADO',
      value: true,
    },
    {
      name: 'nominalPower',
      type: 'text',
      placeholder: 'POTENCIA NOMINAL (MW)',
      value: '',
      validators: [
        Validators.pattern(REGEXPS.numberDecimal),
      ],
    },
    {
      name: 'peakPower',
      type: 'text',
      placeholder: 'POTENCIA PICO (MW)',
      value: '',
      validators: [
        Validators.pattern(REGEXPS.numberDecimal),
      ],
    },
    {
      name: 'losses',
      type: 'text',
      placeholder: 'PÉRDIDAS',
      value: '',
      validators: [
        Validators.pattern(REGEXPS.numberDecimal),
      ],
    },
    {
      name: 'desviationMargin',
      type: 'text',
      placeholder: 'MARGEN DESVÍO DE PRODUCCIÓN',
      value: '',
      validators: [
        Validators.pattern(REGEXPS.numberDecimal),
      ],
    },
    {
      name: 'assignedEntityInstallation',
      type: 'select',
      label: 'ENTIDAD PROPIETARIA DE LA INSTALACIÓN',
      multiple: true,
      searchName: 'assignedEntityInstallationSearch',
      value: [],
      options: [],
    },
    {
      name: 'ttrResponsible',
      type: 'text',
      placeholder: 'RESPONSABLE TTR',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'consumptionContracts',
      type: 'select',
      label: 'CONTRATOS',
      value: '',
      options: [],
    },
    {
      name: 'pricingDate',
      type: 'select',
      label: 'PERIODOS DE TARIFICACIÓN',
      value: '',
      options: []
    },
    {
      name: 'notes',
      type: 'text',
      placeholder: 'NOTAS',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
  ] ;

  private readonly locationFields: Fields [] = [
    {
      name: 'location',
      type: 'text',
      placeholder: 'UBICACIÓN',
      value: '',
    },
    {
      name: 'countryId',
      type: 'select',
      label: 'PAÍS',
      value: '',
      options: [],
    },
    {
      name: 'province',
      type: 'select',
      label: 'PROVINCIA / ESTADO',
      value: '',
      options: [
        {
          value: 'Castilla y León',
          label: 'Castilla y León',
        },
        {
          value: 'Madrid',
          label: 'Madrid',
        },
        {
          value: 'Cataluña',
          label: 'Cataluña',
        },
      ],
    },
    {
      name: 'population',
      type: 'text',
      placeholder: 'POBLACIÓN',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'postalCode',
      type: 'text',
      placeholder: 'CÓDIGO POSTAL',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'utmCoordinates',
      type: 'text',
      placeholder: 'COORDENADAS UTM',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'timeZone',
      type: 'select',
      label: 'ZONA HORARIA',
      value: '',
      validators: [
        Validators.required,
      ],
      options: [
        {
          value: 'CEST',
          label: 'CEST',
        },
        {
          value: 'CET',
          label: 'CET',
        },
        {
          value: 'BST',
          label: 'BST',
        },
        {
          value: 'IST',
          label: 'IST',
        },
        {
          value: 'CST',
          label: 'CST',
        },
        {
          value: 'MSK',
          label: 'MSK',
        },
        {
          value: 'CST',
          label: 'CST',
        },
        {
          value: 'JST',
          label: 'JST',
        },
        {
          value: 'AEST',
          label: 'AEST',
        },
        {
          value: 'NZST',
          label: 'NZST',
        },
      ],
    },
  ];

  private readonly serviceFields: Fields [] = [
    {
      name: 'serviceProvided',
      type: 'text',
      placeholder: 'SERVICIO PRESTADO',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'serviceStartDate',
      type: 'text',
      placeholder: 'FECHA INICIO SERVICIO',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'serviceDetail',
      type: 'text',
      placeholder: 'DETALLE DE SERVICIO',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
  ];

  private readonly elementsInstallationFields: Fields [] = [
    {
      name: 'meteoSensors',
      type: 'select',
      label: 'SENSORES METEO',
      multiple: true,
      searchName: 'meteoSensorsSearch',
      value: [],
      options: [],
      disabled: true,
    },
    {
      name: 'assignedMeters',
      type: 'select',
      label: 'CONTADORES',
      multiple: true,
      searchName: 'assignedMetersSearch',
      value: [],
      options: [],
      disabled: true,
    },
    {
      name: 'assignedInverters',
      type: 'select',
      label: 'INVERSORES',
      multiple: true,
      searchName: 'assignedInvertersSearch',
      value: [],
      options: [],
      disabled: true,
    },
    {
      name: 'assignedZeroInjection',
      type: 'select',
      label: 'EQUIPO DE INYECCIÓN CERO',
      multiple: true,
      searchName: 'assignedZeroInjectionSearch',
      value: [],
      options: [],
      disabled: true,
    },
    {
      name: 'assignedPanels',
      type: 'select',
      label: 'PANELES ASOCIADOS',
      multiple: true,
      searchName: 'assignedPanelsSearch',
      value: [],
      options: [],
      disabled: true,
    },
    {
      name: 'assignedStringModules',
      type: 'select',
      label: 'STRINGMODULES',
      multiple: true,
      searchName: 'assignedZeroInjectionSearch',
      value: [],
      options: [],
      disabled: true,
    },
    {
      name: 'assignedTrackers',
      type: 'select',
      label: 'SEGUIDORES',
      multiple: true,
      searchName: 'assignedTrackersSearch',
      value: [],
      options: [],
      disabled: true,
    },
  ];

  private readonly associationFields: Fields [] = [
    {
      name: 'assignedPlants',
      type: 'select',
      label: 'PLANTAS ASOCIADAS',
      multiple: true,
      searchName: 'assignedPlantsSearch',
      value: [],
      options: [],
      disabled: true,
    },
    {
      name: 'assignedPlantGroups',
      type: 'select',
      label: 'GRUPO DE PLANTAS ASOCIADOS',
      multiple: true,
      searchName: 'assignedPlantGroupsSearch',
      value: [],
      options: [],
    },
  ];

  private readonly contactFields: Fields [] = [
    {
      name: 'dataCollectionResponsible',
      type: 'text',
      placeholder: 'ENCARGADO DE LA LECTURA',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'OMTContacts',
      type: 'select',
      label: 'CONTACTOS OTM',
      multiple: true,
      searchName: 'otmContactsSearch',
      value: [],
      options: [],
    },
    {
      name: 'adminContacts',
      type: 'select',
      label: 'CONTACTOS ADMINISTRATIVOS',
      multiple: true,
      searchName: 'adminContactsSearch',
      value: [],
      options: [],
    },
    {
      name: 'clientContacts',
      type: 'select',
      label: 'CONTACTOS CLIENTE',
      multiple: true,
      searchName: 'clientContactsSearch',
      value: [],
      options: [],
    },
  ];
  constructor() { }

  getDynamicForm(): DynamicForm [] {
    return [
      {
        title: 'DATOS GENERALES',
        fields: this.generalFields,
        open: true,
      },
      {
        title: 'UBICACIÓN',
        fields: this.locationFields,
      },
      {
        title: 'SERVICIO',
        fields: this.serviceFields,
      },
      {
        title: 'ELEMENTOS DE LA INSTALACIÓN',
        fields: this.elementsInstallationFields,
      },
      {
        title: 'CONTACTOS',
        fields: this.contactFields,
      },
    ];
  }

}
