import { Component, OnInit, Input } from '@angular/core';
import { PVSelfCalculation, PVSelfCalculationProducer } from '../../../models/PvSelfCalculation.model';
import { PVSelfCalculationService } from '../../../services/pvself-calculation.service';
import { PVSelfMyConfigurationService } from '../../../services/pvself-my-configuration.service';
import { Entity } from 'projects/pplus/src/lib/models/entity.model';
import { EntitiesService } from 'projects/pplus/src/lib/services/entities.service';

@Component({
  selector: 'pplus-pvself-installation',
  templateUrl: './pvself-installation.component.html',
  styleUrls: ['./pvself-installation.component.scss']
})
export class PVSelfInstallationComponent implements OnInit {
  @Input('tabCreationData') tabCreationData: any;
  _calculation: PVSelfCalculation;
  _applicationEntity: Entity;

  constructor(private calculationService: PVSelfCalculationService,
    private myConfigurationService: PVSelfMyConfigurationService,
    private entitiesService: EntitiesService) { }

  ngOnInit() {
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });


    this.entitiesService.applicationEntity.subscribe(res => {
      this._applicationEntity = res;
      if (this.tabCreationData.tabContentOrigin === 'new') {
        this.createNewPVSelfProducer();
      } else {
        //      this._producer = this.tabCreationData.producer;
      }
    });
  }


  createNewPVSelfProducer() {
    this.tabCreationData.producer = new PVSelfCalculationProducer(this._calculation.calculateYear);
    //    this.tabCreationData.producer = this._producer;

    this.myConfigurationService.getEntityMyConfiguration(this._applicationEntity.entityId).subscribe(res => {
      this.tabCreationData.producer.installation.latitude = res.latitude;
      this.tabCreationData.producer.installation.longitude = res.longitude;
      this.tabCreationData.producer.installation.fields[0].automaticPIConfig = res.automaticPIConfig;
      this.tabCreationData.producer.installation.fields[0].inverterId = res.inverterId;
      this.tabCreationData.producer.installation.fields[0].inverterManufacturerId = res.inverterManufacturerId;
      this.tabCreationData.producer.installation.fields[0].numInverterSerials = res.numInverterSerials;
      this.tabCreationData.producer.installation.fields[0].numOfInverters = res.numOfInverters;
      this.tabCreationData.producer.installation.fields[0].numSerialPanels = res.numSerialPanels;
      this.tabCreationData.producer.installation.fields[0].panelId = res.panelId;
      this.tabCreationData.producer.installation.fields[0].panelManufacturerId = res.panelManufacturerId;
      this.tabCreationData.producer.installation.fields[0].inclination = res.inclination;
      this.tabCreationData.producer.installation.fields[0].orientation = res.orientation;
      this.tabCreationData.producer.installation.fields[0].technology = res.technology;
      this.tabCreationData.producer.producer.installation.gridType = res.gridType;

      this.tabCreationData.producer.installation.radiationData.pVSelfCalculationMeteorologicalDataId = 0;
      this.tabCreationData.producer.installation.radiationData.latitude = res.radiationData.latitude;
      this.tabCreationData.producer.installation.radiationData.longitude = res.radiationData.longitude;
      this.tabCreationData.producer.installation.radiationData.month1 = res.radiationData.month1;
      this.tabCreationData.producer.installation.radiationData.month2 = res.radiationData.month2;
      this.tabCreationData.producer.installation.radiationData.month3 = res.radiationData.month3;
      this.tabCreationData.producer.installation.radiationData.month4 = res.radiationData.month4;
      this.tabCreationData.producer.installation.radiationData.month5 = res.radiationData.month5;
      this.tabCreationData.producer.installation.radiationData.month6 = res.radiationData.month6;
      this.tabCreationData.producer.installation.radiationData.month7 = res.radiationData.month7;
      this.tabCreationData.producer.installation.radiationData.month8 = res.radiationData.month8;
      this.tabCreationData.producer.installation.radiationData.month9 = res.radiationData.month9;
      this.tabCreationData.producer.installation.radiationData.month10 = res.radiationData.month10;
      this.tabCreationData.producer.installation.radiationData.month11 = res.radiationData.month11;
      this.tabCreationData.producer.installation.radiationData.month12 = res.radiationData.month12;
      this.tabCreationData.producer.installation.radiationData.total = res.radiationData.total;

      this.tabCreationData.producer.installation.minTempData.pVSelfCalculationMeteorologicalDataId = 0;
      this.tabCreationData.producer.installation.minTempData.latitude = res.minTempData.latitude;
      this.tabCreationData.producer.installation.minTempData.longitude = res.minTempData.longitude;
      this.tabCreationData.producer.installation.minTempData.month1 = res.minTempData.month1;
      this.tabCreationData.producer.installation.minTempData.month2 = res.minTempData.month2;
      this.tabCreationData.producer.installation.minTempData.month3 = res.minTempData.month3;
      this.tabCreationData.producer.installation.minTempData.month4 = res.minTempData.month4;
      this.tabCreationData.producer.installation.minTempData.month5 = res.minTempData.month5;
      this.tabCreationData.producer.installation.minTempData.month6 = res.minTempData.month6;
      this.tabCreationData.producer.installation.minTempData.month7 = res.minTempData.month7;
      this.tabCreationData.producer.installation.minTempData.month8 = res.minTempData.month8;
      this.tabCreationData.producer.installation.minTempData.month9 = res.minTempData.month9;
      this.tabCreationData.producer.installation.minTempData.month10 = res.minTempData.month10;
      this.tabCreationData.producer.installation.minTempData.month11 = res.minTempData.month11;
      this.tabCreationData.producer.installation.minTempData.month12 = res.minTempData.month12;
      this.tabCreationData.producer.installation.minTempData.total = res.minTempData.total;

      this.tabCreationData.producer.installation.maxTempData.pVSelfCalculationMeteorologicalDataId = 0;
      this.tabCreationData.producer.installation.maxTempData.latitude = res.maxTempData.latitude;
      this.tabCreationData.producer.installation.maxTempData.longitude = res.maxTempData.longitude;
      this.tabCreationData.producer.installation.maxTempData.month1 = res.maxTempData.month1;
      this.tabCreationData.producer.installation.maxTempData.month2 = res.maxTempData.month2;
      this.tabCreationData.producer.installation.maxTempData.month3 = res.maxTempData.month3;
      this.tabCreationData.producer.installation.maxTempData.month4 = res.maxTempData.month4;
      this.tabCreationData.producer.installation.maxTempData.month5 = res.maxTempData.month5;
      this.tabCreationData.producer.installation.maxTempData.month6 = res.maxTempData.month6;
      this.tabCreationData.producer.installation.maxTempData.month7 = res.maxTempData.month7;
      this.tabCreationData.producer.installation.maxTempData.month8 = res.maxTempData.month8;
      this.tabCreationData.producer.installation.maxTempData.month9 = res.maxTempData.month9;
      this.tabCreationData.producer.installation.maxTempData.month10 = res.maxTempData.month10;
      this.tabCreationData.producer.installation.maxTempData.month11 = res.maxTempData.month11;
      this.tabCreationData.producer.installation.maxTempData.month12 = res.maxTempData.month12;
      this.tabCreationData.producer.installation.maxTempData.total = res.maxTempData.total;

      //      this.tabCreationData.producer = this._producer;
    });
  }


}
