import { Component, Input, ElementRef, ChangeDetectorRef } from '@angular/core';
// import * as html2canvas from 'html2canvas';

@Component({
  selector: 'my-tab',
  styles: [
    `
    .pane{
      padding: 0;
      position: relative;
      height: 95.2%;
    }
  `
  ],
  template: `
    <div [hidden]="!active" class="pane" #pane>
      <ng-container *ngIf="template"
        [ngTemplateOutlet]="template"
        [ngTemplateOutletContext]="{ tabCreationData: dataContext }">
      </ng-container>
    </div>
  `
})
export class TabComponent {
  @Input('tabTitle') title: string;
  @Input() active;
  @Input() isCloseable;
  @Input() template;
  @Input() editable;
  @Input() dataContext;
  @Input() tabType;
  screenshot: any;

  constructor(public element: ElementRef, private changeDetector: ChangeDetectorRef) { }


  //   // html2canvas(this.element.nativeElement, { logging: false }).then(canvas => {
  //   //   this.screenshot = canvas.toDataURL();
  //   // });
}
