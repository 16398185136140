import { PlantGroup } from '../plantGroup.model';
import { UserGroup } from '../userGroup.model';

export class PermittedApplication {
    applicationId: number;
    description: string;
    userGroups: string[];
}


export class PermittedPlant {
    plantId:  number;
    description: string;
    userGroups: UserGroup[];
    plantGroups: PlantGroup[];
}


export class EffectivePermission {
    isPermitted: boolean;
    info: EffectivePermissionInfo[];

    constructor() {
        this.info = [];
    }
}

export class EffectivePermissionInfo {
    entityId: number;
    entity: string;
    userGroup: string;
    profile: string;
    role: string;
    permission: string;
    operation: string;
    permitted: boolean;
}
