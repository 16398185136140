import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MeterService } from '../meter.service';
import { PPlusMeterType } from 'projects/pplus/src/lib/models/pplus.enums';
import { Subscription } from 'rxjs/Subscription';
import { CosPhiSeries } from '../meter.models';
import * as moment from 'moment';

@Component({
  selector: 'pplus-meter-totalizer',
  templateUrl: './totalizer.component.html',
  styleUrls: ['./totalizer.component.scss']
})
export class TotalizerComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();

  date: Date;
  idInstallation: number;

  loaderId: string;
  data: any;

  countEnergyOptions = {
    duration: 5,
    separator: ' ',
    suffix: ' kWh',
    decimalPlaces: 2,
  };

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private meterService: MeterService
  ) {

    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);

    this.date = new Date();
  }

  ngOnInit() {

  }

  dateChange(event) {
    this.date = event.value.toDate();
    this.getTotalizerData();
  }

  setIdInstallation(id: number) {

    if (this.idInstallation !== id) {
      this.idInstallation = id;
      this.getTotalizerData();
    }
  }

  private getTotalizerData() {
    this.ngxLoader.startLoader(this.loaderId);
    const opt = {
      InstallationId: this.idInstallation,
      meterType: PPlusMeterType.GenerationBT,
      from: this.date.toUTCString(),
      to: this.date.toUTCString()
    };

    this.meterService.getTotalizerData(opt).subscribe(response => {
      this.ngxLoader.stopLoader(this.loaderId);
      this.data = response;
    });
  }

  setDate(type: number) {
    switch (type) {
      case 1:
        this.date = moment().startOf('day').toDate();
        this.getTotalizerData();
        break;
      case 2:
        this.date = moment().startOf('day').subtract(1, 'days').toDate();
        this.getTotalizerData();
        break;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
