import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { SelfViewService } from '../../services/selfview-general.service';


@Component({
  selector: 'app-reactive-periods',
  templateUrl: './reactive-periods.component.html',
  styleUrls: ['./reactive-periods.component.scss']
})
export class ReactivePeriodsComponent implements OnInit {
  @Output() updateType = new EventEmitter<boolean>();

  flipped: boolean;

  type;

  constructor(private translate: TranslateService, private selfViewService: SelfViewService) {
    this.flipped = true;
  }

  ngOnInit() {

  }

  changeType(): void {
    this.updateType.emit(this.type);
    this.type = !this.type;
  }

}
