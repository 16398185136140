import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MeterService } from '../meter.service';
import { PPlusMeterType } from 'projects/pplus/src/lib/models/pplus.enums';
import { Subscription } from 'rxjs/Subscription';
import { DataSetMeterHistoricEnergy } from '../meter.models';

@Component({
  selector: 'pplus-meter-consumed',
  templateUrl: './consumed.component.html',
  styleUrls: ['./consumed.component.scss']
})
export class ConsumedComponent implements OnInit, OnDestroy {




  private subscriptions: Subscription = new Subscription();

  dateRange;
  idInstallation: number;
  disabled: boolean;

  loaderId: string;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private meterService: MeterService
  ) {

    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);

    const last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);
    last6Months.setHours(0, 0, 0);
    const today = new Date();
    today.setHours(23, 59, 59);

    this.dateRange = {
      startDate: last6Months.toUTCString(),
      endDate: today.toUTCString(),
    };

  }

  ngOnInit() {
    this.meterService.data$.subscribe( (data: DataSetMeterHistoricEnergy) => {
      if (data) {
        this.ngxLoader.stopLoader(this.loaderId);
      }
    });
  }

  setDateRange(range) {
    this.dateRange = range;
    const opt = {
      InstallationId: this.idInstallation,
      meterType: PPlusMeterType.Consumption,
      From: this.dateRange.startDate.toUTCString(),
      To: this.dateRange.endDate.toUTCString()
    };
    this.ngxLoader.startLoader(this.loaderId);
    this.meterService.getData(opt);
  }

  setIdInstallation(id: number) {
    const last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);
    last6Months.setHours(0, 0, 0);
    const today = new Date();
    today.setHours(23, 59, 59);

    if (this.idInstallation !== id) {
      this.idInstallation = id;
      this.dateRange = {
        startDate: last6Months.toUTCString(),
        endDate: today.toUTCString(),
      };

      const opt = {
        InstallationId: this.idInstallation,
        meterType: PPlusMeterType.Consumption,
        From: this.dateRange.startDate,
        To: this.dateRange.endDate
      };

      this.ngxLoader.startLoader(this.loaderId);
      this.meterService.getData(opt);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
