import { Component, OnInit, ViewChild } from '@angular/core';
import { PVSelfEngineService } from '../../services/pvself-engine.service';
import { PVSelfCalculation } from '../../models/PvSelfCalculation.model';
import { PVSelfPredimensioningResults } from '../../models/PVSelfResultsModel.model';
import { PreComponent } from './components/pre/pre.component';
import { PVSelfCalculationService } from '../../services/pvself-calculation.service';

@Component({
  selector: 'app-pre-dimensioned',
  templateUrl: './pre-dimensioned.component.html',
  styleUrls: ['./pre-dimensioned.component.scss']
})
export class PreDimensionedComponent implements OnInit {
  _calculation: PVSelfCalculation;
  predimensioningResults: PVSelfPredimensioningResults[];

  @ViewChild(PreComponent, {static: true}) predimensioningComponent: PreComponent;

  economicFormGroup;
  predimensionedFormGroup;
  isLinear;
  constructor(private calculationService: PVSelfCalculationService,
    private engineService: PVSelfEngineService) { }

  ngOnInit() {
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
    //    this._calculation = this.calculationService.get();
  }

  calcular() {
    this.predimensioningComponent.updatePredimensoningLimits();
    this.predimensioningComponent.calcular();
  }
}
