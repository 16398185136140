import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MeterService } from '../meter.service';
import { PPlusMeterType } from 'projects/pplus/src/lib/models/pplus.enums';
import { Subscription } from 'rxjs/Subscription';
import { MeterHistoricData } from '../meter.models';
import { DATA_DOWNLOAD_HEADERS } from '../meter.settings';
import { GridOptions, GridApi, ColumnApi } from 'ag-grid-community';
import * as moment from 'moment';


@Component({
  selector: 'pplus-meter-data-download',
  templateUrl: './data-download.component.html',
  styleUrls: ['./data-download.component.scss']
})
export class DataDownloadComponent implements OnInit, OnDestroy {
  @ViewChild('agGrid', { read: ElementRef, static: false }) agGridNativeElement: ElementRef;

  columnDefs = DATA_DOWNLOAD_HEADERS;
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  data: MeterHistoricData[];
  dateRange;
  idInstallation: number;
  disabled: boolean;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private meterService: MeterService
  ) {
    this.dateRange = {
      startDate: moment().startOf('day').subtract(1, 'days').toDate(),
      endDate: moment().startOf('day').toDate(),
    };


    this.gridOptions = <GridOptions>{};
    this.gridOptions.paginationPageSize = 20;
    this.gridOptions.rowDragManaged = true;
    this.gridOptions.floatingFilter = true;
    this.gridOptions.accentedSort = true;
    this.gridOptions.suppressHorizontalScroll = false;
    this.gridOptions.overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="assets/loader.svg" alt="loader" class="img-fluid" style="width:40px;height:40px;"></span>';
  }

  ngOnInit() {

  }

  setDateRange(range) {
    this.dateRange = range;
    this.getData();
  }

  setIdInstallation(id: number) {
    const today = moment().startOf('day');

    if (this.idInstallation !== id) {
      this.idInstallation = id;
      this.dateRange = {
        startDate: today.subtract(1, 'day').toDate(),
        endDate: today.toDate(),
      };

      this.getData();
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function() {
      setTimeout(function() {
        params.api.sizeColumnsToFit();
      });
    });

    this.agGridNativeElement.nativeElement.addEventListener('contextmenu', function ($event: any) {
      $event.preventDefault();
    });
  }

  quickSearch(data: string) {
    this.gridApi.setQuickFilter(data);
  }

  resizeGrid(event) {
    this.gridApi.sizeColumnsToFit();
  }

  getData(): void {
    this.gridApi.showLoadingOverlay();

    const opt = {
      InstallationId: this.idInstallation,
      Type: PPlusMeterType.GenerationBT,
      From: this.dateRange.startDate.toUTCString(),
      To: this.dateRange.endDate.toUTCString()
    };

    this.subscriptions = this.meterService.getDataDownloadData(opt)
    .subscribe( (res: MeterHistoricData[]) => {
      this.gridApi.hideOverlay();
      this.data = res;
    });
  }

  downloadExcel() {
    const params = {
      columnGroups: true,
      allColumns: true,
      fileName: 'exported-data'
    };
    this.gridApi.exportDataAsCsv(params);
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
