import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { IInstallationString } from 'projects/pplus/src/lib/interfaces/interfaces';
import { FormGroup, FormControl } from '@angular/forms';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { InstallationStringsService } from '../installation-strings.service';
import { InstallationStringsDetailsService } from './installation-strings-details.service';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { AdminService } from '../../admin.service';
import { NbDialogService } from '@nebular/theme';
import { InstallationInvertersService } from '../../installation-inverters/installation-inverters.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'pplus-installation-strings-details',
  templateUrl: './installation-strings-details.component.html',
  styleUrls: ['./installation-strings-details.component.scss']
})
export class InstallationStringsDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  data: IInstallationString;

  formGroup: FormGroup;
  formData: DynamicForm[];

  constructor(
    private installationStringsService: InstallationStringsService,
    private installationStringsDetailsService: InstallationStringsDetailsService,
    private rowDataService: RowDataService,
    private adminService: AdminService,
    private nbDialogService: NbDialogService,
    private installationInvertersService: InstallationInvertersService,
  ) {
    this.formData = this.installationStringsDetailsService.getDynamicForm();
  }

  ngOnInit() {
    forkJoin([
      this.installationInvertersService.getList(),
    ]).subscribe(([inverters]) => {
      this.formData.map(o => {
        o.fields.forEach(f => {
          if (f.name === 'inverterId') {
            const opts = [];
            inverters.map(i => opts.push({
              value: i.installationInverterId,
              label: i.name,
            }));
            f.options = opts;
          }
        });
      });
      this.generateForm(this.formData);
    });

    this.rowDataService.currentRowData.pipe(map(o => {
      this.formData.forEach(data => {
        data.fields.map(field => field.value = o[field.name]);
      });
      return o;
    })).subscribe(data => {
      this.data = <IInstallationString>data;
      this.generateForm(this.formData);
    });
  }

  private generateForm(data: DynamicForm[]) {
    const fieldsCtrls = {};
    data.forEach(el => {
      el.fields.forEach(f => {
        fieldsCtrls[f.name] = new FormControl(f.value == null ? '' : f.value, f.validators);
      });
    });
    this.formGroup = new FormGroup(fieldsCtrls);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IInstallationString>this.formGroup.value;
      this.installationStringsService.create(data).subscribe(newInstallationString => {
        if (newInstallationString != null) {
          this.formGroup.patchValue(Object.assign(this.data, newInstallationString));
          this.updateDelete.emit(true);
          this.adminService.showToast('success',
              'Se ha creado el nuevo string de instalación: ' + data.nameString,
              'Elemento creado');
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al crear el string de instalación: ' + data.nameString,
              'Error');
        }
      });
    }
  }

  update() {
    const id = this.data.installationStringId;
    if (id == null) {
      this.onSubmit();
    } else {
      const data = <IInstallationString>this.formGroup.value;
      this.installationStringsService.update(id, data).subscribe(success => {
        if (success) {
          this.updateDelete.emit();
          this.adminService.showToast('success',
              'Se ha actualizado el string de instalación: ' + data.nameString,
              'Elemento actualizado');
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al actualizar el string de instalación: ' + data.nameString,
              'Error');
        }
      });
    }
  }

  delete() {
    if (this.data.installationStringId == null) {
      this.adminService.showToast('info', 'Seleccione un string de instalación', 'Info');
    } else {
      this.installationStringsService.delete(this.data.installationStringId).subscribe(success => {
        if (success) {
          this.updateDelete.emit();
          this.adminService.showToast('success',
              'Se ha borrado el string de instalación: ' + this.data.nameString,
              'Elemento eliminado');
          this.clearData();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al borrar el string de instalación: ' + this.data.nameString,
              'Error');
        }
      });
    }
  }

  close() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  copy() {
    if (this.data.installationStringId == null) {
      this.adminService.showToast('info', 'Seleccione un string de instalación para copiar la información', 'Info');
    } else {
      this.formGroup.patchValue(Object.assign(this.data, <IInstallationString>{
        installationStringId: null,
        codeString: '',
        peakPower: '',
      }));
    }
  }

  clearData() {
    this.data = <IInstallationString>{};
    this.formGroup.reset();
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.installationStringId == null) {
      this.adminService.showToast('info', 'No hay ningún string de instalación seleccionado', 'Info');
    } else {
      this.nbDialogService.open(dialog, { context: '¿Desea eliminar el string de instalación?', hasScroll: false });
    }
  }

}
