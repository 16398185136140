import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PVSelfCalculation } from '../models/PvSelfCalculation.model';
import { Observable } from 'rxjs';
import { PVSelfBalanceResults } from '../models/PVSelfResultsModel.model';
import { PVSelfEngineCalculation } from '../models/PVSelfEngineCalculation.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { PVSelfEngineService } from './pvself-engine.service';

@Injectable({
  providedIn: 'root'
})
export class PVSelfReportsService {

  constructor(private httpClient: HttpClient,
    private engineService: PVSelfEngineService) { }

  generateResultsReport(calculation: PVSelfCalculation, code: string): Observable<Blob> {
    const engineCalculation: PVSelfEngineCalculation = this.engineService.convertToEngineModel(calculation);

    return this.httpClient.post(environment.API_URL + '/api/pvself/reports/generateResultsReport?code=' + code, engineCalculation,
      { responseType: 'blob' });
  }

  generateDevelopmentData(calculation: PVSelfCalculation): Observable<Blob> {
    const engineCalculation: PVSelfEngineCalculation = this.engineService.convertToEngineModel(calculation);
    return this.httpClient.post(environment.API_URL + '/api/pvself/developer/getDevelopmentHourlyData', engineCalculation,
      { responseType: 'blob' });
  }
}
