import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import { SelfViewService } from '../../../services/selfview-general.service';
import { MatTableDataSource } from '@angular/material';
import * as XLSX from 'xlsx';

@Component({
  selector: 'pplus-totals-table',
  templateUrl: './totals-table.component.html',
  styleUrls: ['./totals-table.component.scss']
})
export class TotalsTableComponent implements OnInit {
  @ViewChild('table', {static: false, read: ElementRef}) table: ElementRef;

  @Input() capacitivaProperty: string;
  @Input() inductivaProperty: string;

  /**
   * Diff in days between start and end date
  */
  rangeLenght: number = null;

  // GRID
  dataSource = new MatTableDataSource<any>([]);
  columnDefinitions = [];

  constructor(private selfviewService: SelfViewService) { }

  ngOnInit() {

    this.selfviewService.dataReactive.subscribe((data: any) => {
      setTimeout(() => {

        if (data && data.reactiveData) {
          this.rangeLenght = moment(data.dateRange.to).startOf('day').diff(moment(data.dateRange.from).startOf('day'), 'days');
          if (this.rangeLenght === 0) {
            const gridData = {'Hora' : 'F.P'};
            this.columnDefinitions = ['Hora'];

            data.reactiveData.hourlyData.forEach( element => {
              const key = moment(element.hour).format('HH:mm');
              gridData[key] = {powerFactor: element.powerFactor, penalty: element.penalty};
              this.columnDefinitions.push(key);
            });

            this.dataSource.data = [gridData];
          }
        }
      });
    });

    this.selfviewService.dataReactiveCompare.subscribe((data: any) => {
      if (data) {

      }
    });

  }

  /**
   * Exports grid to excel
   */
  exportToExcel() {
    let ws: XLSX.WorkSheet;
    ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Table.xlsx');
  }

}
