import { Component, OnInit, Input } from '@angular/core';
import { PVSelfCalculation, PVSelfCalculationConsumer, PVSelfBillingPeriod, PVSelfHabitsDataType, PVSelfBillingHabitsData } from 'src/app/modules/pvself/models/PvSelfCalculation.model';
import { PVSelfCalculationService } from 'src/app/modules/pvself/services/pvself-calculation.service';

@Component({
  selector: 'pplus-pvself-consumption-habits',
  templateUrl: './pvself-consumption-habits.component.html',
  styleUrls: ['./pvself-consumption-habits.component.scss']
})
export class PVSelfConsumptionHabitsComponent implements OnInit {
  _calculation: PVSelfCalculation;
  @Input('consumer') _consumer: PVSelfCalculationConsumer;

  dailyHabits: Array<string>;
  weeklyHabits: Array<string>;
  monthlyHabits: Array<string>;

  selectedBillingPeriod: number;

  constructor(private calculationService: PVSelfCalculationService) { }

  ngOnInit() {
    this.selectedBillingPeriod = 0;
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
    //    this._calculation = this.calculationService.get();
    this.updateHabits();

    if (this._consumer.billingData.length === 0) { this.updateBillingPeriods(); }
  }


  updateHabits() {
    this.dailyHabits = [];
    this.weeklyHabits = [];
    this.monthlyHabits = [];

    const daily = this._consumer.dailyHabits;
    this.dailyHabits = [];
    if ((daily & 0b001) === 0b001) { this.dailyHabits.push('night'); }
    if ((daily & 0b010) === 0b010) { this.dailyHabits.push('afternoon'); }
    if ((daily & 0b100) === 0b100) { this.dailyHabits.push('morning'); }

    const weekly = this._consumer.weeklyHabits;
    this.weeklyHabits = [];
    if ((weekly & 0b001) === 0b001) { this.weeklyHabits.push('weekend'); }
    if ((weekly & 0b010) === 0b010) { this.weeklyHabits.push('week'); }


    const monthly = this._consumer.monthlyHabits;
    this.monthlyHabits = [];
    if ((monthly & 0b000000000001) === 0b000000000001) { this.monthlyHabits.push('m12'); }
    if ((monthly & 0b000000000010) === 0b000000000010) { this.monthlyHabits.push('m11'); }
    if ((monthly & 0b000000000100) === 0b000000000100) { this.monthlyHabits.push('m10'); }
    if ((monthly & 0b000000001000) === 0b000000001000) { this.monthlyHabits.push('m9'); }
    if ((monthly & 0b000000010000) === 0b000000010000) { this.monthlyHabits.push('m8'); }
    if ((monthly & 0b000000100000) === 0b000000100000) { this.monthlyHabits.push('m7'); }
    if ((monthly & 0b000001000000) === 0b000001000000) { this.monthlyHabits.push('m6'); }
    if ((monthly & 0b000010000000) === 0b000010000000) { this.monthlyHabits.push('m5'); }
    if ((monthly & 0b000100000000) === 0b000100000000) { this.monthlyHabits.push('m4'); }
    if ((monthly & 0b001000000000) === 0b001000000000) { this.monthlyHabits.push('m3'); }
    if ((monthly & 0b010000000000) === 0b010000000000) { this.monthlyHabits.push('m2'); }
    if ((monthly & 0b100000000000) === 0b100000000000) { this.monthlyHabits.push('m1'); }


  }

  changedDailyHabits() {
    let daily = 0;
    if (this.dailyHabits.indexOf('morning') !== -1) { daily += 4; }
    if (this.dailyHabits.indexOf('afternoon') !== -1) { daily += 2; }
    if (this.dailyHabits.indexOf('night') !== -1) { daily += 1; }
    this._consumer.dailyHabits = daily;
  }


  changedWeeklyHabits() {
    let weekly = 0;
    if (this.weeklyHabits.indexOf('week') !== -1) { weekly += 1; }
    if (this.weeklyHabits.indexOf('weekend') !== -1) { weekly += 2; }
    this._consumer.weeklyHabits = weekly;
  }



  changedMonthlyHabits() {
    let monthly = 0;
    if (this.monthlyHabits.indexOf('m1') !== -1) { monthly += 0b000000000001; }
    if (this.monthlyHabits.indexOf('m2') !== -1) { monthly += 0b000000000010; }
    if (this.monthlyHabits.indexOf('m3') !== -1) { monthly += 0b000000000100; }
    if (this.monthlyHabits.indexOf('m4') !== -1) { monthly += 0b000000001000; }
    if (this.monthlyHabits.indexOf('m5') !== -1) { monthly += 0b000000010000; }
    if (this.monthlyHabits.indexOf('m6') !== -1) { monthly += 0b000000100000; }
    if (this.monthlyHabits.indexOf('m7') !== -1) { monthly += 0b000001000000; }
    if (this.monthlyHabits.indexOf('m8') !== -1) { monthly += 0b000010000000; }
    if (this.monthlyHabits.indexOf('m9') !== -1) { monthly += 0b000100000000; }
    if (this.monthlyHabits.indexOf('m10') !== -1) { monthly += 0b001000000000; }
    if (this.monthlyHabits.indexOf('m11') !== -1) { monthly += 0b010000000000; }
    if (this.monthlyHabits.indexOf('m12') !== -1) { monthly += 0b100000000000; }
    this._consumer.monthlyHabits = monthly;
  }


  updateBillingPeriods() {

    this.selectedBillingPeriod = 0;

    let billingPeriods = 1;

    if (this._consumer.rates[0].billingPeriod === PVSelfBillingPeriod.Semestral) { billingPeriods = 2; }
    if (this._consumer.rates[0].billingPeriod === PVSelfBillingPeriod.Cuatrimestral) { billingPeriods = 3; }
    if (this._consumer.rates[0].billingPeriod === PVSelfBillingPeriod.Trimestral) { billingPeriods = 4; }
    if (this._consumer.rates[0].billingPeriod === PVSelfBillingPeriod.Bimestral) { billingPeriods = 6; }
    if (this._consumer.rates[0].billingPeriod === PVSelfBillingPeriod.Mensual) { billingPeriods = 12; }
    if (this._consumer.rates[0].billingPeriod === PVSelfBillingPeriod.Quincenal) { billingPeriods = 24; }

    this._consumer.billingData = [];
    for (let i = 0; i < billingPeriods; i++) {
      const data = new PVSelfBillingHabitsData();
      data.billingPeriod = i + 1;
      this._consumer.billingData.push(data);
    }
  }


}
