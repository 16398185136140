import { Component, OnInit } from '@angular/core';
import { PVSelfCalculation } from '../../../models/PvSelfCalculation.model';
import { PVSelfCalculationService } from '../../../services/pvself-calculation.service';
import { PVSelfEngineService } from '../../../services/pvself-engine.service';
import { PVSelfLossesResults } from '../../../models/PVSelfResultsModel.model';
import * as moment from 'moment';
import { NgxUiLoaderService } from 'ngx-ui-loader';


// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   symbol: string;
// }

// const ELEMENT_DATA: PeriodicElement[] = [
//   { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
//   { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
//   { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
//   { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
//   { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
//   { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
//   { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
//   { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
//   { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
//   { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
// ];


@Component({
  selector: 'pplus-pvself-losses',
  templateUrl: './pvself-losses.component.html',
  styleUrls: ['./pvself-losses.component.scss']
})

export class PVSelfLossesComponent implements OnInit {
  _calculation: PVSelfCalculation;
  selectedProducerIndex: number;
  lossesResults: PVSelfLossesResults;
  lossesData: any[];
  loaderId: string;


  displayedColumns: string[] = ['month', 'soiling', 'iam', 'radiation', 'temperature', 'tolerancia', 'mismatch', 'cableado', 'inversor', 'sombreado', 'altatension', 'indisponibilidad'];
  //dataSource = ELEMENT_DATA;

  constructor(private calculationService: PVSelfCalculationService,
    private ngxLoaderService: NgxUiLoaderService,
    private engineService: PVSelfEngineService) { }

  ngOnInit() {
    this.selectedProducerIndex = 0;
    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);
    this.lossesResults = new PVSelfLossesResults();
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
    //    this._calculation = this.calculationService.get();
  }


  calculateLosses() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.engineService.calculateLossesResults(this._calculation).subscribe(res => {
      this.lossesResults = res;

      this.lossesData = [];

      for (let i = 0; i < 12; i++) {
        let month = moment().month(i).format('MMMM');
        month = month.charAt(0).toUpperCase() + month.slice(1);
        this.lossesData.push(
          {
            month: month, soiling: res.soiling[i] / 1000, iam: res.iam[i] / 1000, radiation: res.radiation[i] / 1000, temperature: res.temperature[i] / 1000,
            tolerance: res.tolerance[i] / 1000, mismatch: res.mismatch[i] / 1000, cabling: res.cabling[i] / 1000,
            inverter: res.inverter[i] / 1000, shading: res.shading[i] / 1000, highTension: res.highTension[i] / 1000, unavailability: res.unavailability[i] / 1000
          },
        );
      }

      this.lossesData.push(
        {
          month: 'Total', soiling: res.soiling[12] / 1000, iam: res.iam[12] / 1000, radiation: res.radiation[12] / 1000, temperature: res.temperature[12] / 1000,
          tolerance: res.tolerance[12] / 1000, mismatch: res.mismatch[12] / 1000, cabling: res.cabling[12] / 1000,
          inverter: res.inverter[12] / 1000, shading: res.shading[12] / 1000, highTension: res.highTension[12] / 1000, unavailability: res.unavailability[12] / 1000
        },
      );

      this._calculation.producers[this.selectedProducerIndex].installation.losses.soiling = res.soilingPercentage * 100;
      this._calculation.producers[this.selectedProducerIndex].installation.losses.iam = res.iamPercentage * 100;
      this._calculation.producers[this.selectedProducerIndex].installation.losses.radiation = res.radiationPercentage * 100;
      this._calculation.producers[this.selectedProducerIndex].installation.losses.temperature = res.temperaturePercentage * 100;
      this._calculation.producers[this.selectedProducerIndex].installation.losses.tolerance = res.tolerancePercentage * 100;
      this._calculation.producers[this.selectedProducerIndex].installation.losses.mismatch = res.mismatchPercentage * 100;
      this._calculation.producers[this.selectedProducerIndex].installation.losses.cabling = res.cablingPercentage * 100;
      this._calculation.producers[this.selectedProducerIndex].installation.losses.inverter = res.inverterPercentage * 100;
      this._calculation.producers[this.selectedProducerIndex].installation.losses.shading = res.shadingPercentage * 100;
      this._calculation.producers[this.selectedProducerIndex].installation.losses.highTension = res.highTensionPercentage * 100;
      this._calculation.producers[this.selectedProducerIndex].installation.losses.unavailability = res.unavailabilityPercentage * 100;

      this.ngxLoaderService.stopLoader(this.loaderId);
    });
  }


}
