import { Component, TemplateRef, ViewChild, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { PlantsService } from './plants.service';
import { ChecklistDatabase } from '../tree-data/tree-data.service';
import { ItemNode, TreeData } from '../../models/components/tree-data.model';
import { map } from 'rxjs/operators';
import { Installation, Plant } from '../../models/components/selfview.model';

@Component({
  selector: 'pplus-plantSearch',
  templateUrl: './plants-search.component.html',
  styleUrls: ['./plants-search.component.scss']
})
export class PlantsSearchComponent implements AfterViewInit {
  @ViewChild('dialog', { static: false}) dialog;
  @Input('disabled') disabledAll: boolean;
  @Output() idInstallation = new EventEmitter<number>();
  @Input() templatePortal;
  disabled = true;
  plant: string;
  groupPlant: string;
  installation: string;
  peakPower: string;
  dataSource: TreeData [] = [];

  constructor(private plantsService: PlantsService, private dialogService: NbDialogService, private dataTree: ChecklistDatabase) {
    this.plantsService.getAllInstallations()
      .subscribe( (data: Plant []) => {
        data.map( (itemSelfView: Plant) => {
          const itemTreeData = new TreeData();
          itemTreeData.id = itemSelfView.plantId;
          itemTreeData.text = itemSelfView.name;
          itemTreeData.children = [];
          if (itemSelfView.installations !== [] && itemSelfView.installations !== null) {
            itemSelfView.installations.map( (itemInstallation: Installation) => {
              const children = new TreeData();
              children.id = itemInstallation.id;
              children.text = itemInstallation.name;
              children.data = {
                parentId: itemTreeData.id,
                parentName: itemTreeData.text,
                peakPower: itemInstallation.peakPower,
              };
              itemTreeData.children.push(children);
            });
          }
          this.dataSource.push(itemTreeData);
        });
        if (this.dataSource !== []) {
          this.dataTree.initialize(this.dataSource);
        }
    });
  }

  ngAfterViewInit() {
    this.open(this.dialog);
  }

  open(dialog: TemplateRef<any>) {
    if (this.dataSource !== []) {
      this.dataTree.initialize(this.dataSource);
    }
    this.dialogService.open(dialog, { hasScroll: true, closeOnBackdropClick : false, closeOnEsc : false });
  }

  getData(itemNode: ItemNode) {
    const data = itemNode.data;
    this.installation = itemNode.item;
    this.plant = data.parentName;
    this.peakPower = data.peakPower;
    this.disabled = false;
    this.idInstallation.emit(itemNode.id);
  }

}
