import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabComponent } from './tab.component';

@Injectable({
  providedIn: 'root'
})
export class TabService {
  private tab  = new BehaviorSubject(<TabComponent> null);
  tabSelected = this.tab.asObservable();

  constructor() {}

  changeTab(tab: TabComponent) {
    this.tab.next(tab);
  }
}
