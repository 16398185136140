import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF } from "../admin.model";

export const APP_GROUP_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const APP_GROUP_COL_DEFS: ColDef[] = [
  {
    field: 'applicationGroupId',
    headerName: 'ID',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'description',
    headerName: 'Description',
  },
  {
    field: 'activated',
    headerName: 'Activated',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkBoxComponent',
  },
  {
    field: 'entityNames',
    headerName: 'Entities',
    valueFormatter: function (r) {
      if (r && r.data) {
        let value = '';
        const entities = r.data.entityApplicationGroups;
        if (entities && entities.length) {
          for (const e of entities) {
            if (value) value += ', ';
            value += e.entity ? e.entity.code : '';
          }
        }
        return r.data.entityNames = value;
      }
    },
  },
  {
    field: 'applicationNames',
    headerName: 'Applications',
    valueFormatter: function (r) {
      if (r && r.data) {
        let value = '';
        const apps = r.data.applicationGroupApplications;
        if (apps && apps.length) {
          for (const app of apps) {
            if (value) value += ', ';
            value += app.application ? app.application.name : '';
          }
        }
        return r.data.applicationNames = value;
      }
    },
  },
];
