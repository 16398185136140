import * as moment from 'moment';

/**
 * Initial options for chart
 */
export const INIT_OPTIONS = {
  renderer: 'svg',
  width: 'auto',
  height: 'auto'
};

export const REACTIVE_TOTALS_CHART_OPTIONS = {
  legend: {
    show: true,
    bottom: '0'
  },
  tooltip: {
    trigger: 'item',
    textStyle: {
      fontSize: 12,
      width: 500,
      color: '#fff'
    },
    formatter: (params) => {
      const values = `
        ${params.name}<br/>
        ${params.data[1]} ${params.seriesName}<br/>
      `;

      return values;
    }
  },
  toolbox: {
    show: true,
    left: 'center',
    itemSize: 20,
    top: '0%',
    feature: {
      saveAsImage: {title: 'Descargar'}
    }
  },
  xAxis: [{
    type: 'category'
  }],
  yAxis: {
    gridIndex: 0,
    axisLabel: {
      inside: false,
      formatter: '{value}',
      fontSize: 10
    }
  },
  series: []
};
