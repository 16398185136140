import {Component, OnInit, OnDestroy} from '@angular/core';
import { ZeroInjectionService } from '../zero-injection.service';
import { ZeroInjectionEffectiveResults, ZeroInjectionEffectiveDataProperties } from '../zero-injection.models';
import { MatTableDataSource } from '@angular/material';
import { DecimalPipe } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-zero-injection-efficacies',
  templateUrl: './zero-injection-efficacies.component.html',
  styleUrls: ['./zero-injection-efficacies.component.scss']
})
export class ZeroInjectionEfficaciesComponent implements OnInit, OnDestroy {

  // FIX TO USE ENUM IN TEMPLATE
  effectiveResults: ZeroInjectionEffectiveResults;

  // GRIDS
  dataSourceNetwork = new MatTableDataSource<any>([]);
  dataSourcePhotovoltaic = new MatTableDataSource<any>([]);
  dataSourceConsumption = new MatTableDataSource<any>([]);
  columnDefinitions = ['title', 'total', 'phaseR', 'phaseS', 'phaseT'];

  effectiveResultsSubscription: Subscription;

  constructor(
    private zeroInjectionService: ZeroInjectionService,
    private _decimalPipe: DecimalPipe
  ) {

  }

  ngOnInit() {
    this.effectiveResultsSubscription = this.zeroInjectionService.effectiveResults.subscribe( (results: ZeroInjectionEffectiveResults ) => {
      if (results) {
        this.effectiveResults = results;
        this.buildGrid();
      }
    });

  }

  ngOnDestroy(): void {
    this.effectiveResultsSubscription.unsubscribe();
  }

  buildGrid() {

    const networkData = this.mapNetWorkData();
    const photovoltaicData = this.mapPhotovoltaicData();
    const consumptionData = this.mapConsumptionData();


    this.dataSourceNetwork.data = networkData;
    this.dataSourcePhotovoltaic.data = photovoltaicData;
    this.dataSourceConsumption.data = consumptionData;
  }

  mapNetWorkData() {
    return Object.keys(ZeroInjectionEffectiveDataProperties).map( (key: string) => {
      const id = ZeroInjectionEffectiveDataProperties[key].id;
      const suffix = ZeroInjectionEffectiveDataProperties[key].suffix;

      return {
        title: ZeroInjectionEffectiveDataProperties[key].label,
        total: this.getMappedValue(this.effectiveResults.network_Total[id], suffix),
        phaseR: this.getMappedValue(this.effectiveResults.network_R[id], suffix),
        phaseS: this.getMappedValue(this.effectiveResults.network_S[id], suffix),
        phaseT: this.getMappedValue(this.effectiveResults.network_T[id], suffix),
      };

    });
  }

  mapConsumptionData() {
    return Object.keys(ZeroInjectionEffectiveDataProperties).map( (key: string) => {
      const id = ZeroInjectionEffectiveDataProperties[key].id;
      const suffix = ZeroInjectionEffectiveDataProperties[key].suffix;

      return {
        title: ZeroInjectionEffectiveDataProperties[key].label,
        total: this.getMappedValue(this.effectiveResults.consumption_Total[id], suffix),
        phaseR: this.getMappedValue(this.effectiveResults.consumption_R[id], suffix),
        phaseS: this.getMappedValue(this.effectiveResults.consumption_S[id], suffix),
        phaseT: this.getMappedValue(this.effectiveResults.consumption_T[id], suffix),
      };

    });
  }

  mapPhotovoltaicData() {
    return Object.keys(ZeroInjectionEffectiveDataProperties).map( (key: string) => {
      const id = ZeroInjectionEffectiveDataProperties[key].id;
      const suffix = ZeroInjectionEffectiveDataProperties[key].suffix;

      return {
        title: ZeroInjectionEffectiveDataProperties[key].label,
        total: this.getMappedValue(this.effectiveResults.photovoltaic_Total[id], suffix),
        phaseR: this.getMappedValue(this.effectiveResults.photovoltaic_R[id], suffix),
        phaseS: this.getMappedValue(this.effectiveResults.photovoltaic_S[id], suffix),
        phaseT: this.getMappedValue(this.effectiveResults.photovoltaic_T[id], suffix),
      };

    });
  }


  getMappedValue(value: number, suffix: string): string {
    return value !== null ? `${this._decimalPipe.transform(value, '1.2-2')} ${suffix}` : '-';
  }

}
