import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {chartColors} from '../../selfview.graph.colors';
import {TranslateService} from '@ngx-translate/core';
import { PPlusChartType } from '../../../../../../projects/pplus/src/lib/models/pplus.enums';
import { REACTIVE_DATA, PERIODS } from './reactive-main.series';
import { REACTIVE_BAR_CHART_OPTIONS, REACTIVE_AREA_CHART_OPTIONS, REACTIVE_LEGENDS, REACTIVE_ENERGY_DATASOURCES, INIT_OPTIONS } from './reactive-main.config';
import { GlobalType, IPeriod } from '../../models/selfview.model';
import { SelfViewService } from '../../services/selfview-general.service';
import { DateMoment } from '../../models/selfview-date.model';
import { EChartOption } from 'echarts';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reactive-main',
  templateUrl: './reactive-main.component.html',
  styleUrls: ['./reactive-main.component.scss']
})
export class ReactiveMainComponent implements OnInit, OnDestroy {
  @Output() getPeriodCompare = new EventEmitter<any>();
  @Output() updateType = new EventEmitter<GlobalType>();
  @Output() changeDatasources = new EventEmitter<any>();

  // FIX TO USE ENUM IN TEMPLATE
  GlobalType = GlobalType;
  REACTIVE_DATA = REACTIVE_DATA;

  /**
   * Echarts instance of reactive chart component
   */
  echartsInstance: any;

  /**
   * Store local type selected
   */
  type: GlobalType;

  /**
   * Store global type selected
   */
  globalType: GlobalType;


  /**
   * Flipped state of component card
   */
  flipped: boolean;

  // ENERGY DATASOURCES
  /**
   * Energy data source for datasource selects
   */
  energyDataSource = REACTIVE_ENERGY_DATASOURCES;

  /**
   * Store datasource selected for each energy
   */
  datasources = {
    'consumoTotal': 1,
    'consumidaRed': 1,
    'excedentaria': 1,
    'netaGenerada': 1,
  };

  /**
   * Store bar chart series visibility state
   */
  barChartState = {};

  /**
   * Store areas chart series visibility state
   */
  areaChartState = {};

  comparePeriod = false;

  /**
   * Main chart options
   */
  reactiveChartOptions: EChartOption;

  chartType: any;

  /**
   * Initial options for chart
   */
  initOpts = INIT_OPTIONS;

  /**
   * Initial options for counts elements
   */
  countOptions = {
    duration: 5,
    separator: ' ',
    suffix: 'kWh',
  };


  /**
   * Sets the data mode compare or not
   */
  compareMode: boolean;

  /**
   * Reactive Data from reactive service
   */
  reactiveDataPower: any;

  /**
   * Reactive Compare Data from reactive service
   */
  reactiveDataPowerComparison: any;


  periods: IPeriod[] = [];

  legends: any;

  private globalTypeSubscription: Subscription;
  private dataSubscription: Subscription;
  private dataCompareSubscription: Subscription;

  constructor(private translate: TranslateService, private selfviewService: SelfViewService) {
    this.flipped = true;
    this.type = GlobalType.Energy;


    this.initBarChartState();
    this.initAreaChartState();
  }

  ngOnInit() {

    this.globalTypeSubscription = this.selfviewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;

      // Aquí se cambiara, el tipo desde el global
      this.dataSubscription = this.selfviewService.dataReactive.subscribe((data: any) => {
        if (data) {
          this.reactiveDataPower = data.reactiveData;
          if (this.reactiveDataPower) {
            this.chartType = this.reactiveDataPower.chartType;
            this.showChartForGlobalType();
         }
        }
      });

      this.dataCompareSubscription = this.selfviewService.dataReactiveCompare.subscribe((data: any) => {
        if (data) {
          this.comparePeriod = true;
        }
        this.reactiveDataPowerComparison = data;
        this.showChartComparisonForGlobalType();

      });

    });

    this.translateAll();
    this.legends = REACTIVE_LEGENDS;
  }

  ngOnDestroy(): void {
    this.globalTypeSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
    this.dataCompareSubscription.unsubscribe();
  }

  initiatePowerChartBarra() {
    this.reactiveChartOptions = null;
    this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};

    const series = this.generateSeriesForReactiveBarChart();

    const chartOptions: any = REACTIVE_BAR_CHART_OPTIONS;
    chartOptions.legend.data =  this.barChartState;
    chartOptions.legend.selected = { ...this.barChartState};
    chartOptions.series = [...series];
    chartOptions.xAxis[0].data = this.reactiveDataPower.groupedData.map(item => {
      const time = new Date(item.date);
      return time.toLocaleDateString('es-ES');
    });


    this.reactiveChartOptions = chartOptions;
    this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};
  }

  /**
   * Initiates power chart barra comparison
   */
  initiatePowerChartBarComparison() {

    this.initiatePowerChartBarra();

    const data = this.reactiveDataPowerComparison.groupedData;

    const series = this.generateSeriesForReactiveBarChart(true);

    const xAxis = <EChartOption.XAxis[]> this.reactiveChartOptions.xAxis;

    if (xAxis.length > 1) {
      xAxis.pop();
    }
    const newAxis: EChartOption.XAxis = {
      position: 'bottom',
      boundaryGap: true,
      offset: 50,
      type: 'category',
      data: data.map(item => {
        const time = new Date(item.date);
        return time.toLocaleDateString('es-ES'); // + ' \n ' + time.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'});
      }),
      axisTick: {
        alignWithLabel: true, interval: 5
      },
      axisLine: {
        onZero: false,
        lineStyle: {
          color: '#327830'
        }
      }
    };

    xAxis.push(newAxis);
    this.reactiveChartOptions.dataZoom[0].xAxisIndex = [0, 1];

    const seriesTotal = this.reactiveChartOptions.series.concat(series);

    this.reactiveChartOptions.series = seriesTotal;

    this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};
  }

  /**
   * Init area chart
   */
  initiatePowerChartArea() {
    this.reactiveChartOptions = null;
    this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};

    this.chartType = 'line';

    const series = this.generateSeriesForReactiveAreaChart();
    const chatOptions: any = REACTIVE_AREA_CHART_OPTIONS;
    chatOptions.legend.data = this.areaChartState,
    chatOptions.legend.selected = this.areaChartState,
    chatOptions.series = series,

    this.reactiveChartOptions = chatOptions;
    this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};
  }

  /**
   * Initiates power chart area comparison
   */
  initiatePowerChartAreaComparison() {

    this.initiatePowerChartArea();

    this.chartType = 'line';

    const series = this.generateSeriesForReactiveAreaChart(true);

    const newAxis: EChartOption.XAxis = {
      type: 'time',
      offset: 30,
      position: 'bottom',
      axisLabel: {
        fontSize: 10,
        formatter: (function (value) {
          const time = new Date(value);
          return time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', {hour: '2-digit', minute: '2-digit'});
        })
      },
      splitLine: {
        show: false,
      }
    };

    const xAxis = <EChartOption.XAxis[]> this.reactiveChartOptions.xAxis;

    if (xAxis.length > 1) {
      xAxis.pop();
    }
    xAxis.push(newAxis);

    this.reactiveChartOptions.series = this.reactiveChartOptions.series.concat(series);
    this.reactiveChartOptions.tooltip.show = true;
    this.reactiveChartOptions.dataZoom[0].xAxisIndex = [0, 1];
    this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};
    const firstSerie: EChartOption.SeriesLine = <EChartOption.SeriesLine> this.reactiveChartOptions.series[0];
    if (firstSerie) {
      (<EChartOption.SeriesLine> firstSerie.markArea).data = null;
    }

  }


  /**
   * Translates all.
   * TODO: implement all translations
   */
  translateAll() {

  }

  /**
   * Called to show main chart.
   */
  showChartForGlobalType() {
    if (this.reactiveDataPower !== null) {
      const data = this.reactiveDataPower;
      if (!this.compareMode) {
        if (this.globalType === GlobalType.Energy) {
          this.chartType = this.reactiveDataPower.chartType;
          this.type = GlobalType.Energy;
          if (data.chartType === PPlusChartType['bar']) {
            this.initiatePowerChartBarra();
          } else {
            this.initiatePowerChartArea();
            this.initiatePeriods(data.hourlyData);
          }

        } else if (this.globalType === GlobalType.Economic) {
          // TODO: hacer la parte económica
        }
      }

      if (this.compareMode) {
        if (this.reactiveDataPowerComparison !== null && this.globalType === GlobalType.Energy) {
          if (this.reactiveDataPowerComparison.chartType === PPlusChartType['bar']) {
            this.initiatePowerChartBarComparison();
            this.reactiveChartOptions.legend.selected = this.barChartState;
            this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};
          } else {
            this.initiatePowerChartAreaComparison();
            this.reactiveChartOptions.legend.selected = this.areaChartState;
            this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};
          }
        }
        // TODO: implement economic in else clause

      }
    }
  }

  /**
   * Shows chart comparison for global type
   * Called when enabled compare mode
   */
  showChartComparisonForGlobalType() {
    if (this.reactiveDataPowerComparison) {
      this.type = GlobalType.Energy;
      const data = this.reactiveDataPowerComparison;

      this.initBarChartState();
      this.initAreaChartState();

      if (this.chartType === PPlusChartType['bar']) {
        this.reactiveChartOptions.legend.selected = this.barChartState;
      } else {
        this.reactiveChartOptions.legend.selected = this.areaChartState;
      }

      this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};

      if (data !== null && this.globalType === GlobalType.Energy) {
        if (data.chartType === PPlusChartType['bar']) {
          this.initiatePowerChartBarComparison();
        } else {
          this.initiatePowerChartAreaComparison();
        }
      } else if (data !== null && this.globalType === GlobalType.Economic) {
          // TODO: implementar parte económica
      }

    } else {
      if (this.comparePeriod === null) {
        this.hideBarChartCompareSeries();
        this.hideAreaChartCompareSerie();
        if (this.chartType ===  PPlusChartType['bar']) {
          this.reactiveChartOptions.legend.selected = this.barChartState;
        } else {
          this.reactiveChartOptions.legend.selected = this.areaChartState;
        }
        this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};
      }
    }
  }

  /**
   * Determines whether chart init on
   * @param ec
   */
  onChartInit(ec) {
    this.echartsInstance = ec;
  }

  /**
   * Initiates periods for reactive area chart
   * @param data: chart data
   */
  initiatePeriods(data: any[]) {
    if (data[0]) {
      this.periods = [];
      let currentPeriod = data[0].period;
      let startDateDate = data[0].hour;
      let endDateDate = '';

      for (let i = 0; i < data.length; i++) {

        if (currentPeriod !== data[i].period) {
          endDateDate = data[i - 1].hour;
          this.periods.push({startDate: startDateDate, endDate: endDateDate, period: currentPeriod});
          startDateDate = endDateDate;
        }

        if (i === (data.length - 1)) {
          if (this.periods[this.periods.length - 1].period !== data[i].period) {
            endDateDate = data[i].hour;
            this.periods.push({startDate: startDateDate, endDate: endDateDate, period: data[i].period});
          }
        }

        currentPeriod = data[i].period;
      }
    }

    this.paintPeriods();
  }

  /**
   * Paints periods
   */
  paintPeriods() {
    const markers = [];
    for (const period of this.periods) {
      const marker = [
        {name: 'P' + period.period, xAxis: period.startDate, yAxis: 'min'},
        {yAxis: 'max', xAxis: period.endDate}
      ];
      markers.push(marker);
    }

    // create periods fake serie and concats to all series
    const periodsSerie = this.createPeriodsFakeSerie(markers);
    this.reactiveChartOptions.series = this.reactiveChartOptions.series.concat([periodsSerie]);
  }

  /**
   * Toggles serie for main chart.
   * @param serie: Current serie
   * @param $event: State
   */
  toggleSerie(serie: any, $event) {
    // Get the zoom level of the graph and apply it in legend selection
    let zoom = null;
    if (this.echartsInstance) {
      zoom = this.echartsInstance.getOption().dataZoom;
      this.reactiveChartOptions.dataZoom = zoom;
    }
    const state = $event.checked;

    Object.keys(PERIODS).forEach((key) => {
      this.barChartState[`${serie.INDUCTIVE.label} ${key}`] = state;
      this.barChartState[`${serie.CAPACITIVE.label} ${key}`] = state;
      this.barChartState[`${serie.INDUCTIVE.label} ${key}*`] = state;
      this.barChartState[`${serie.CAPACITIVE.label} ${key}*`] = state;
    });

    this.areaChartState[`${serie.INDUCTIVE.label}`] = state;
    this.areaChartState[`${serie.CAPACITIVE.label}`] = state;
    this.areaChartState[`${serie.INDUCTIVE.label}*`] = state;
    this.areaChartState[`${serie.CAPACITIVE.label}*`] = state;

    if (this.chartType === PPlusChartType.Bar) {
      this.reactiveChartOptions.legend.selected = this.barChartState;
    } else {
      this.reactiveChartOptions.legend.selected = this.areaChartState;
    }
    this.reactiveChartOptions = {...this.reactiveChartOptions, ...this.reactiveChartOptions};
  }

  /**
   * Enable compare mode.
   * @param date: Date starts compare mode
   */
  comparePeriods(date: DateMoment) {
    this.compareMode = true;
    this.getPeriodCompare.emit(date);
  }

  /**
   * Disable compared mode
   */
  disabledComparedMode() {
    this.compareMode = false;
    this.comparePeriod = null;
    this.reactiveDataPowerComparison = null;

    this.selfviewService.removeDataReactiveCompare();
  }

  /**
   * Change type to Power or Economic mode
   */
  changeType(): void {
    if (this.type === GlobalType.Economic) {
      this.type = GlobalType.Energy;
    } else {
      this.type = GlobalType.Economic;
    }

    this.updateType.emit(this.type);

    // TODO: implementar funcionalidad para local type
  }

  /**
   * Determines whether datasources change on
   */
  onDatasourcesChange() {
    this.changeDatasources.emit(this.datasources);
  }


  /**
   * Generates series for reactive bar chart
   */
  generateSeriesForReactiveBarChart(compareMode: boolean = false) {
    const series = [];
    const data = compareMode ?  this.reactiveDataPowerComparison.groupedData : this.reactiveDataPower.groupedData;

    data.forEach(element => {
      Object.keys(REACTIVE_DATA).forEach((key , index) => {
        const serieIndName = REACTIVE_DATA[key].INDUCTIVE.id;
        const serieCapName = REACTIVE_DATA[key].CAPACITIVE.id;

        if (!series[index * 2]){
          series[index * 2] = [null, null, null, null , null , null];
        }
        if (!series[index * 2 + 1]){
          series[index * 2 + 1] = [null, null, null, null , null , null];
        }

        // create inductive series
        element[`${serieIndName}_ByPeriod`].forEach((item, i) => {
          if (!series[index * 2][i]) {
            series[index * 2][i] = this.createReactiveBarChartSerie(
              compareMode ?
                `${REACTIVE_DATA[key].INDUCTIVE.label} P${i + 1}*`:
                `${REACTIVE_DATA[key].INDUCTIVE.label} P${i + 1}`
              ,
              [],
              chartColors.periods[compareMode ?
                `${REACTIVE_DATA[key].periodColorKey}P${i + 1}Comparison` :
                `${REACTIVE_DATA[key].periodColorKey}P${i + 1}`
              ],
              compareMode ?
                `${REACTIVE_DATA[key].id}Comparison` :
                REACTIVE_DATA[key].id
            );
          }
          series[index * 2][i].data.push(item);
        });

        // create capacitive series
        element[`${serieCapName}_ByPeriod`].forEach((item, i) => {
          if (!series[index * 2 + 1][i]) {
            series[index * 2 + 1][i] = this.createReactiveBarChartSerie(
              compareMode ?
                `${REACTIVE_DATA[key].CAPACITIVE.label} P${i + 1}*` :
                `${REACTIVE_DATA[key].CAPACITIVE.label} P${i + 1}`
              ,
              [],
              chartColors.periods[compareMode ?
                `${REACTIVE_DATA[key].periodColorKey}P${i + 1}Comparison` :
                `${REACTIVE_DATA[key].periodColorKey}P${i + 1}`
              ],
              compareMode ?
                `${REACTIVE_DATA[key].id}Comparison` :
                REACTIVE_DATA[key].id
            );
          }
          series[index * 2 + 1][i].data.push(-item);
        });
      });
    });
    return series.reduce((a, b) => [...a, ...b], []);
  }

  /**
   * Generates series for reactive area chart
   */
  generateSeriesForReactiveAreaChart(compareMode: boolean = false) {
    const series = [];
    const data = compareMode ?  this.reactiveDataPowerComparison.hourlyData : this.reactiveDataPower.hourlyData;

    data.forEach(element => {
      Object.keys(REACTIVE_DATA).forEach( (key, index) => {
        const serieIndName = REACTIVE_DATA[key].INDUCTIVE.id;
        const serieCapName = REACTIVE_DATA[key].CAPACITIVE.id;

        let areaStyle = null;
        if (!compareMode) {
          areaStyle = {
            normal: {
              color: chartColors[`${REACTIVE_DATA[key].areaColorKey}Area`],
              areaStyle: {type: 'default'}
            }
          };
        }

        if (!series[index * 2]) {
          series[index * 2] = this.createReactiveAreaChartSerie(
            compareMode ?
            `${REACTIVE_DATA[key].INDUCTIVE.label}*` :
            `${REACTIVE_DATA[key].INDUCTIVE.label}`
            ,
            [],
            chartColors[`${REACTIVE_DATA[key].areaColorKey}Line`],
            REACTIVE_DATA[key].id,
            areaStyle,
            compareMode ? 1 : 0
          );
        }
        if (!series[index * 2 + 1]) {
          series[index * 2 + 1] = this.createReactiveAreaChartSerie(
            compareMode ?
            `${REACTIVE_DATA[key].CAPACITIVE.label}*` :
            `${REACTIVE_DATA[key].CAPACITIVE.label}`
            ,
            [],
            chartColors[`${REACTIVE_DATA[key].areaColorKey}Line`],
            REACTIVE_DATA[key].id,
            areaStyle,
            compareMode ? 1 : 0
          );
        }

        series[index * 2].data.push([element.hour, element[serieIndName]]);
        series[index * 2 + 1].data.push([element.hour, -element[serieCapName]]);
      });
    });

    return series;
  }

  /**
   * Creates reactive bar chart serie
   * @param name: serie name
   * @param data: serie data
   * @param color: serie color
   * @param stack: serie stack
   * @returns serie
   */
  createReactiveBarChartSerie(name: string, data: any, color: string, stack: string) {
      return {
        name,
        type: 'bar',
        data,
        itemStyle: {color},
        stack,
        xAxisIndex: 0
      };
  }

  /**
   * Creates reactive area chart serie
   * @param name: serie name
   * @param data: serie data
   * @param color: serie color
   * @param stack: serie stack
   * @returns serie
   */
  createReactiveAreaChartSerie(name: string, data: any, color: string, stack: string, areaStyle: any = null, xAxisIndex: number = 0 ) {

    return {
      name,
      type: 'line',
      smooth: true,
      symbol: 'none',
      itemStyle: {
        normal: {
          color
        }
      },
      stack,
      areaStyle: areaStyle,
      markArea: {
        label: {show: true, position: 'top'},
        itemStyle: {
          color: 'rgba(38,44,44,0.11)',
          borderColor: 'rgba(38,44,44,0.27)',
          borderWidth: 0.5
        },
        data: []
      },
      data: data,
      xAxisIndex

    };

  }

  /**
   * Creates periods fake serie
   * @param data: serie data
   * @returns serie
   */
  createPeriodsFakeSerie(data: any) {

    return {
      name: 'periods',
      type: 'line',
      markArea: {
        label: {
          show: true,
          position: 'top',
          textStyle:{
            color: '#000',
            fontStyle: 'italic'
          }
        },
        itemStyle: {
          color: 'rgba(38,44,44,0.11)',
          borderColor: 'rgba(38,44,44,0.27)',
          borderWidth: 0.5
        },
        data
      }
   };
  }

  /**
   * Inits bar chart state. Show All series
   */
  initBarChartState() {
    Object.keys(REACTIVE_DATA).forEach((key)=> {
      const serieIndName = REACTIVE_DATA[key].INDUCTIVE.label;
      const serieCapName = REACTIVE_DATA[key].CAPACITIVE.label;

      Object.keys(PERIODS).forEach ( (period: string) => {
        this.barChartState[`${serieIndName} ${period}`] = true;
        this.barChartState[`${serieCapName} ${period}`] = true;
        this.barChartState[`${serieIndName} ${period}*`] = true;
        this.barChartState[`${serieCapName} ${period}*`] = true;
      });
    });
  }

  /**
   * Hides bar chart compare series
   */
  hideBarChartCompareSeries() {
    Object.keys(REACTIVE_DATA).forEach((key) => {
      const serieIndName = REACTIVE_DATA[key].INDUCTIVE.label;
      const serieCapName = REACTIVE_DATA[key].CAPACITIVE.label;

      Object.keys(PERIODS).forEach ( (period: string) => {
        this.barChartState[`${serieIndName} ${period}`] = true;
        this.barChartState[`${serieCapName} ${period}`] = true;
        this.barChartState[`${serieIndName} ${period}*`] = false;
        this.barChartState[`${serieCapName} ${period}*`] = false;
      });
    });
  }

  /**
   * Inits bar chart state
   */
  initAreaChartState() {
    Object.keys(REACTIVE_DATA).forEach((key) => {
      const serieIndName = REACTIVE_DATA[key].INDUCTIVE.label;
      const serieCapName = REACTIVE_DATA[key].CAPACITIVE.label;
      this.areaChartState[`${serieIndName}`] = true;
      this.areaChartState[`${serieCapName}`] = true;
      this.areaChartState[`${serieIndName}*`] = true;
      this.areaChartState[`${serieCapName}*`] = true;
    });
  }

  /**
   * Hides area chart compare serie
   */
  hideAreaChartCompareSerie() {
    Object.keys(REACTIVE_DATA).forEach((key) => {
      const serieIndName = REACTIVE_DATA[key].INDUCTIVE.label;
      const serieCapName = REACTIVE_DATA[key].CAPACITIVE.label;
      this.areaChartState[`${serieIndName}`] = true;
      this.areaChartState[`${serieCapName}`] = true;
      this.areaChartState[`${serieIndName}*`] = false;
      this.areaChartState[`${serieCapName}*`] = false;
    });

  }
}
