import { Component, OnInit} from '@angular/core';
import { ConsumptionBorderMeterService } from 'projects/pplus/src/lib/components/elements/consumption-border-meter/consumption-border-meter.service';

@Component({
  selector: 'app-selfview-consumption-meter',
  templateUrl: './selfview-consumption-meter.component.html',
  styleUrls: ['./selfview-consumption-meter.component.scss'],
  providers: [ConsumptionBorderMeterService]
})
export class SelfviewConsumptionMeterComponent implements OnInit {

  constructor(

  ) {

  }

  ngOnInit() {

  }


}
