import * as moment from 'moment';

const filterParamatros = {
  clearButton: true,
  applyButton: true,
  debounceMs: 200
};
export const DATA_DOWNLOAD_HEADERS = [
  {
    headerName: 'Fecha',
    minWidth: 160,
    field: 'timestamp',
    filterParams: filterParamatros,
    filter: 'agTextColumnFilter',
    sortable: true,
    resizable: true,
    valueFormatter: function (r: any) {
      const value = r.data.timestamp;
      return moment(value).format('DD-MM-YYYY HH:mm');
    }
  },
  {
    headerName: 'Activa generada',
    minWidth: 160,
    field: 'eout',
    filterParams: filterParamatros,
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true,
    valueGetter: function(params) {
      return params.data.eout / 1000;
    }
  },
  {
    headerName: 'Activa consumida',
    minWidth: 160,
    field: 'ein',
    filterParams: filterParamatros,
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true,
    valueGetter: function(params) {
      return params.data.ein / 1000;
    }
  },
  {
    headerName: 'Reactiva capacitiva consumida',
    minWidth: 250,
    field: 'eq1',
    filterParams: filterParamatros,
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true,
    valueGetter: function(params) {
      return params.data.eq1 / 1000;
    }
  },
  {
    headerName: 'Reactiva capacitiva generada',
    minWidth: 250,
    field: 'eq2',
    filterParams: filterParamatros,
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true,
    valueGetter: function(params) {
      return params.data.eq2 / 1000;
    }
  },
  {
    headerName: 'Reactiva inductiva generada',
    minWidth: 250,
    field: 'eq3',
    filterParams: filterParamatros,
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true,
    valueGetter: function(params) {
      return params.data.eq3 / 1000;
    }
  },
  {
    headerName: 'Reactiva inductiva consumida',
    minWidth: 250,
    field: 'eq4',
    filterParams: filterParamatros,
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true,
    valueGetter: function(params) {
      return params.data.eq4 / 1000;
    }
  },
  {
    headerName: 'Factor de potencia',
    minWidth: 180,
    field: 'pf',
    filterParams: filterParamatros,
    filter: 'agTextColumnFilter',
    resizable: true,
    sortable: true
  }
];
