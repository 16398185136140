import { Component, OnInit } from '@angular/core';
import { InvertersService } from './inverters.service';

@Component({
  selector: 'app-selfview-inverters',
  templateUrl: './selfview-inverters.component.html',
  styleUrls: ['./selfview-inverters.component.scss'],
  providers: [InvertersService]
})
export class SelfviewInvertersComponent implements OnInit {

  idInstallation: number;

  loaderId: string;


  constructor(
    //    private ngxLoader: NgxUiLoaderService,
    private invertersService: InvertersService
  ) {

  }

  ngOnInit() {
    // this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);

    // this.invertersService.installationDailyData.subscribe( (invertersData: InverterDailyData[]) => {
    //   if (invertersData) {
    //     this.ngxLoader.stopLoader(this.loaderId);
    //   }
    // });

  }


  setIdInstallation(id: number) {
    if (this.idInstallation !== id) {
      this.idInstallation = id;
      this.getInverters();
      this.getInstantInvertersPower();
    }
  }

  private getInverters() {

    this.invertersService.getInvertersByInstallation(this.idInstallation);


    // const now = new Date().toUTCString();
    // const params: InverterDailyDataParams = {
    //   installationId: this.idInstallation,
    //   date: now
    // };
    // this.invertersService.getInstallationDailyData(params);
  }

  getInstantInvertersPower() {

    this.invertersService.getInstantInvertersPower(this.idInstallation);
  }

}
