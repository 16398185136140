import { Injectable } from '@angular/core';
import { DynamicForm, Fields } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ApplicationTypeDetailsService {

  private readonly generalFields: Fields[] = [
    {
      name: 'applicationTypeId',
      type: 'text',
      placeholder: 'ID',
      value: '',
      disabled: true,
    },
    {
      name: 'name',
      type: 'text',
      placeholder: 'NOMBRE',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(100),
      ],
    },
    {
      name: 'description',
      type: 'text',
      placeholder: 'DESCRIPCIÓN',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(999),
      ],
    },
    {
      name: 'activated',
      type: 'checkbox',
      placeholder: 'ACTIVATED',
      value: true,
    },
  ];

  constructor() {}

  getDynamicForm(): DynamicForm[] {
    return [
      {
        title: 'DATOS GENERALES',
        fields: this.generalFields,
        open: true,
      },
    ];
  }

}
