import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { PVSelfCalculation, PVSelfCalculationConsumer } from '../../../models/PvSelfCalculation.model';
import { PVSelfCalculationService } from '../../../services/pvself-calculation.service';
import { PVSelfEngineService } from '../../../services/pvself-engine.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PVSelfConsumptionHabitsComponent } from './consumption-habits/pvself-consumption-habits.component';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.scss']
})

export class ConsumptionComponent implements OnInit {
  @Input('tabCreationData') tabCreationData: any;
  @ViewChild(PVSelfConsumptionHabitsComponent, { static: true }) consumptionHabitsComponent: PVSelfConsumptionHabitsComponent;
  _calculation: PVSelfCalculation;
//  _consumer: PVSelfCalculationConsumer;
  loaderId: string;
  billingDataType = 1;

  public config: SwiperConfigInterface = {
    direction: 'vertical',
    slidesPerView: 2,
    keyboard: true,
    mousewheel: true,
    scrollbar: false,
    navigation: true,
    pagination: false
  };


  constructor(private calculationService: PVSelfCalculationService,
    private engineService: PVSelfEngineService,
    private ngxLoaderService: NgxUiLoaderService) {  }

  ngOnInit() {
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
//    this._calculation = this.calculationService.get();
//    this._consumer = this.tabCreationData.consumer;
    this.loaderId = 'loader-' + new Date().getTime(); // + Math.floor((Math.random() * 100) + 1);
  }

  updateConsumptionOrigin(value) {
    if (value === 'previous' && this.tabCreationData.consumer.consumptionOrigin > 1) { this.tabCreationData.consumer.consumptionOrigin--; }
    if (value === 'next' && this.tabCreationData.consumer.consumptionOrigin < 3) { this.tabCreationData.consumer.consumptionOrigin++; }
  }

  getBilling() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.engineService.calculateBilling(this._calculation, 0).subscribe(res => {
      this.tabCreationData.consumer.calculatedEnergyAdjust = res.energy;
      this.tabCreationData.consumer.calculatedPowerAdjust = res.power;
      this.tabCreationData.consumer.calculatedTotalAdjust = res.total;
      this.ngxLoaderService.stopLoader(this.loaderId);
    });
  }

  rateChanged() {
    this.consumptionHabitsComponent.updateBillingPeriods();
  }

}
