import { PPlusElectricPhase } from 'projects/pplus/src/lib/models/pplus.enums';

export interface ZeroInjectionInstantResults {
    instantData: ZeroInjectionInstantData[];
    armonicPercentageData: ZeroInjectionArmonicData[];
    armonicDegreeData: ZeroInjectionArmonicData[];
}

export interface ZeroInjectionInstantData {
  milliseconds: number;
  voltage: number;
  current: number;
  consumedCurrent: number;
  power: number;
  consumedPower: number;
}

export interface ZeroInjectionArmonicData {
  milliseconds: number;
  voltage: number;
  current: number;
  consumedCurrent: number;
  power: number;
  consumedPower: number;
}

export interface ZeroInjectionInstantResultsParams {
  idInstallation: number;
  phase: PPlusElectricPhase;
}


export interface ZeroInjectionEffectiveResults {
    network_R?: ZeroInjectionEffectiveData;
    network_S?: ZeroInjectionEffectiveData;
    network_T?: ZeroInjectionEffectiveData;
    network_Total?: ZeroInjectionEffectiveData;

    photovoltaic_R?: ZeroInjectionEffectiveData;
    photovoltaic_S?: ZeroInjectionEffectiveData;
    photovoltaic_T?: ZeroInjectionEffectiveData;
    photovoltaic_Total?: ZeroInjectionEffectiveData;

    consumption_R?: ZeroInjectionEffectiveData;
    consumption_S?: ZeroInjectionEffectiveData;
    consumption_T?: ZeroInjectionEffectiveData;
    consumption_Total?: ZeroInjectionEffectiveData;
}

export interface ZeroInjectionEffectiveData {
  voltage?: number;
  current?: number;
  activePower?: number;
  reactivePower?: number;
  apparentPower?: number;
  powerFactor?: number;
}

export const ZeroInjectionEffectiveDataProperties = {
  VOLTAGE: {
    id: 'voltage',
    label: 'Tensión',
    suffix: 'V'
  },
  CURRENT: {
    id: 'current',
    label: 'Intensidad',
    suffix: 'A'
  },
  ACTIVE_POWER: {
    id: 'activePower',
    label: 'Potencia Activa',
    suffix: 'kW'
  },
  REACTIVE_POWER: {
    id: 'reactivePower',
    label: 'Potencia Reactiva',
    suffix: 'kVAr'
  },
  APPARENT_POWER: {
    id: 'apparentPower',
    label: 'Potencia Aparente',
    suffix: 'kVA'
  },
  POWER_FACTOR: {
    id: 'powerFactor',
    label: 'Factor de Potencia',
    suffix: ''
  },

};


export interface ZeroInjectionEffectiveResultsParams {
  idInstallation: number;
}

export const ZERO_INJECTION_DATA = {
  VOLTAGE: {
    id: 'voltage',
    label: 'Tensión (V)',
    color: '#105567'
  },
  CURRENT: {
    id: 'current',
    label: 'Corriente red (A)',
    color: '#e95a5c'
  },
  CURRENT_CONSUMED: {
    id: 'consumedCurrent',
    label: 'Corriente consumida (A)',
    color: '#3ab0a9'
  },
  POWER: {
    id: 'power',
    label: 'Potencia red (kW)',
    color: '#9484bd'
  },
  POWER_CONSUMED: {
    id: 'consumedPower',
    label: 'Potencia consumida (kW)',
    color: '#c4d886'
  }
};


export enum ArmonicMode {
  Percentage = 1,
  Degree = 2
}

export enum ZeroInjectionChartMode {
  Phases = 1,
  Armonics = 2
}
