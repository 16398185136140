import { Injectable } from '@angular/core';
import { DynamicForm, Fields } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { Validators } from '@angular/forms';
import { REGEXPS } from '../../admin.model';

@Injectable({
  providedIn: 'root'
})
export class PlantDetailsService {

  private readonly generalFields: Fields[] = [
    {
      name: 'plantId',
      type: 'text',
      placeholder: 'ID',
      value: '',
      disabled: true,
    },
    {
      name: 'name',
      type: 'text',
      placeholder: 'NOMBRE',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(100),
      ],
    },
    {
      name: 'demoName',
      type: 'text',
      placeholder: 'NOMBRE DEMO',
      value: '',
      validators: [
        Validators.maxLength(100),
      ],
    },
    {
      name: 'code',
      type: 'text',
      placeholder: 'CÓDIGO',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(13),
      ],
    },
    {
      name: 'demoCode',
      type: 'text',
      placeholder: 'CÓDIGO DEMO',
      value: '',
      validators: [
        Validators.maxLength(13),
      ],
    },
    {
      name: 'creationDate',
      type: 'text',
      placeholder: 'FECHA DE ALTA',
      value: '',
      disabled: true,
    },
    {
      name: 'deletionDate',
      type: 'text',
      placeholder: 'FECHA DE BAJA',
      value: '',
      disabled: true,
    },
    {
      name: 'activated',
      type: 'checkbox',
      placeholder: 'ACTIVADA',
      value: false,
    },
    {
      name: 'nominalPower',
      type: 'text',
      placeholder: 'POTENCIA NOMINAL (MW)',
      value: '',
      validators: [
        Validators.pattern(REGEXPS.numberDecimal),
      ],
    },
    {
      name: 'peakPower',
      type: 'text',
      placeholder: 'POTENCIA PICO (MW)',
      value: '',
      validators: [
        Validators.pattern(REGEXPS.numberDecimal),
      ],
    },
    {
      name: 'ttrResponsible',
      type: 'text',
      placeholder: 'RESPONSABLE TTR',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'losses',
      type: 'text',
      placeholder: 'PÉRDIDAS',
      value: '',
      validators: [
        Validators.pattern(REGEXPS.numberDecimal),
      ],
    },
    {
      name: 'ownerEntityId',
      type: 'select',
      label: 'ENTIDAD PROPIETARIA',
      value: '',
      options: [],
    },
  ];

  private readonly locationFields: Fields[] = [
    {
      name: 'timeZone',
      type: 'select',
      label: 'ZONA HORARIA',
      value: '',
      validators: [
        Validators.required,
      ],
      options: [
        {
          value: 'CEST',
          label: 'CEST',
        },
        {
          value: 'CET',
          label: 'CET',
        },
        {
          value: 'BST',
          label: 'BST',
        },
        {
          value: 'IST',
          label: 'IST',
        },
        {
          value: 'CST',
          label: 'CST',
        },
        {
          value: 'MSK',
          label: 'MSK',
        },
        {
          value: 'CST',
          label: 'CST',
        },
        {
          value: 'JST',
          label: 'JST',
        },
        {
          value: 'AEST',
          label: 'AEST',
        },
        {
          value: 'NZST',
          label: 'NZST',
        },
      ],
    },
    {
      name: 'location',
      type: 'text',
      placeholder: 'UBICACIÓN',
      value: '',
    },
    {
      name: 'countryId',
      type: 'select',
      label: 'PAÍS',
      value: '',
      options: [],
    },
    {
      name: 'province',
      type: 'select',
      label: 'PROVINCIA / ESTADO',
      value: '',
      options: [
        {
          value: 'Castilla y León',
          label: 'Castilla y León',
        },
        {
          value: 'Madrid',
          label: 'Madrid',
        },
        {
          value: 'Cataluña',
          label: 'Cataluña',
        },
      ],
    },
    {
      name: 'population',
      type: 'text',
      placeholder: 'POBLACIÓN',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'postalCode',
      type: 'text',
      placeholder: 'CÓDIGO POSTAL',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'utmCoordinates',
      type: 'text',
      placeholder: 'COORDENADAS UTM',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
  ];

  private readonly serviceFields: Fields[] = [
    {
      name: 'serviceProvided',
      type: 'text',
      placeholder: 'SERVICIO PRESTADO',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'serviceStartDate',
      type: 'text',
      placeholder: 'FECHA INICIO SERVICIO',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'serviceDetail',
      type: 'text',
      placeholder: 'DETALLE DE SERVICIO',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
  ];

  private readonly associationFields: Fields[] = [
    {
      name: 'installations',
      type: 'select',
      label: 'INSTALACIONES',
      multiple: true,
      searchName: 'installationsSearch',
      value: [],
      options: [],
    },
    {
      name: 'meteoSensors',
      type: 'select',
      label: 'SENSORES METEO',
      multiple: true,
      searchName: 'meteoSensorsSearch',
      value: [],
      options: [],
    },
    {
      name: 'assignedMeters',
      type: 'select',
      label: 'CONTADORES',
      multiple: true,
      searchName: 'assignedMetersSearch',
      value: [],
      options: [],
    },
    {
      name: 'plantGroups',
      type: 'select',
      label: 'GRUPOS DE PLANTAS',
      multiple: true,
      searchName: 'plantGroupsSearch',
      value: [],
      options: [],
    },
  ];

  constructor() {}

  getDynamicForm(): DynamicForm[] {
    return [
      {
        title: 'DATOS GENERALES',
        fields: this.generalFields,
        open: true,
      },
      {
        title: 'UBICACIÓN',
        fields: this.locationFields,
      },
      {
        title: 'SERVICIO',
        fields: this.serviceFields,
      },
      {
        title: 'ASOCIACIONES',
        fields: this.associationFields,
      },
    ];
  }

}
