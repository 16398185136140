import { Component, OnDestroy } from '@angular/core';
import { takeWhile, switchMap } from 'rxjs/operators';
import { NbAuthResult, NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User, UserExternal } from 'projects/pplus/src/lib/models/AllModels';

@Component({
  selector: 'lib-callback-google',
  template: '',
  styles: ['']
})

export class CallbackGoogleComponent implements OnDestroy {
  alive = true;
  private _googleURL = 'https://www.googleapis.com/userinfo/v2/me?access_token=';
  private _externalLogin = environment.API_URL + '/api/user/external-login';
  private _redirectURL;
  constructor(private authService: NbAuthService, private router: Router, private http: HttpClient) {
    this.authService.authenticate('google')
    .pipe(
      takeWhile(() => this.alive), switchMap( (authResult: NbAuthResult) => {
        const googleToken = authResult.getToken().getValue();
        if (authResult.isSuccess() && authResult.getRedirect()) {
          this._redirectURL = authResult.getRedirect();
          return this.getExternalDataUser(this._googleURL + googleToken).pipe(
            switchMap( (data: any) => {
              const user: UserExternal = {
                provider: 'google',
                token: googleToken,
                fullName: data.name,
                email: data.email,
                picture: data.picture,
                providerkey: data.id,
              };
              return this.http.post(this._externalLogin, user);
            })
          );
        } else {
          this.router.navigateByUrl('auth/login');
        }
      })).subscribe( (data: any) => {
        if (this._redirectURL) {
          if (data) {
            const user: User = {
              token: data.token,
              email: data.email,
              fullName: data.fullName,
              userId: data.userId,
              picture: data.picture,
              role: data.role,
              language: data.language,
              sessionId: data.sessionId,
            };
            localStorage.setItem('pplus', JSON.stringify(user));
          }
          this.router.navigateByUrl(this._redirectURL);
        }
      });
  }

  getExternalDataUser(url: string) {
    return this.http.get(url);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
}