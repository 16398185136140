export enum TYPE_TIME {
  Hours,
  Minutes,
  Seconds
}
export class TimePicker {
  hours: any;
  minutes: any;
  seconds: any;
};

