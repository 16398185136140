import { Component, OnInit, ViewChild } from '@angular/core';
import { DaterangepickerDirective, DaterangepickerComponent, LocaleConfig } from 'ngx-daterangepicker-material';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { SizeLogCalculation } from '../models/sizeLogCalculation.model';
import { SizeBasicLogCalculationsService } from '../services/size-basic-log-calculations.service';
import { SizeBasicCRMCalculationsService } from '../services/size-basic-crmcalculations.service';
import { ColumnApi } from 'ag-grid-community';

@Component({
  selector: 'pplus-size-basic-log-calculations',
  templateUrl: './size-basic-log-calculations.component.html',
  styleUrls: ['./size-basic-log-calculations.component.scss']
})
export class SizeBasicLogCalculationsComponent implements OnInit {

  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;

  registers: any[];
  private gridApi;
  gridColumnApi: ColumnApi;


  picker: DaterangepickerComponent;
  rangeSelected: DateRange;
  costeDesde: number;
  costeHasta: number;
  selectedType: string;


  locale: LocaleConfig = {
    applyLabel: 'Aceptar',
    format: 'DD/MM/YYYY',
    firstDay: 1,
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort()
  };

  ranges: any = {
    'Año completo': [moment().year(moment().year()).month(0).date(1), moment().year(moment().year()).month(11).date(31)],
  };


  columnDefs = [
    { headerName: 'ID', field: 'pVSelfRegisterId', resizable: true, hide: true },
    {
      headerName: 'Fecha de creación', field: 'date', resizable: true, cellRenderer: (data) => moment(data.data.date).format('DD/MM/YYYY HH:mm:ss'),
      width: 180, filter: 'agDateColumnFilter',
      filterParams: {

        // provide comparator function
        comparator: function (filterDate, cellValue) {
          let dateAsString = cellValue;

          const index = dateAsString.indexOf('T');
          dateAsString = dateAsString.substring(0, index);
          if (dateAsString == null) { return 0; }

          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = dateAsString.split('-');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);

          if (filterDate.getDate() === day &&
            filterDate.getMonth() === month &&
            filterDate.getFullYear() === year) { return 0; }

          return 1;
        }
      }
    },

    { headerName: 'Latitud', field: 'latitude', resizable: true, width: 100, filter: false },
    { headerName: 'Longitud', field: 'longitude', resizable: true, width: 100, filter: false },
    { headerName: 'Nombre contacto', field: 'contactName', resizable: true, width: 150, filter: true },
    { headerName: 'Apellidos contacto', field: 'contactSurname', resizable: true, width: 150, filter: true, hide: (this.selectedType === 'LOG') },
    { headerName: 'Teléfono contacto', field: 'contactPhone', resizable: true, width: 250, filter: true, hide: (this.selectedType === 'LOG') },
    { headerName: 'Email contacto', field: 'contactEmail', resizable: true, width: 150, filter: true, hide: (this.selectedType === 'LOG') },
    { headerName: 'Municipio', field: 'town', resizable: true, width: 250, filter: true },
    { headerName: 'Provincia', field: 'city', resizable: true, width: 250, filter: true },
    { headerName: 'Región', field: 'state', resizable: true, width: 250, filter: true },
    { headerName: 'País', field: 'country', resizable: true, width: 250, filter: true },
    { headerName: 'Superficie disponible', field: 'availableSurface', resizable: true, cellRenderer: (data) => data.data.availableSurface !== undefined ? (100 * data.data.availableSurface).toFixed(2) : 0, width: 150, filter: true },
    { headerName: 'Superficie necesaria', field: 'requiredSurface', resizable: true, cellRenderer: (data) => data.data.requiredSurface !== undefined ? (100 * data.data.requiredSurface).toFixed(2) : 0, width: 150, filter: true },
    { headerName: 'Tipo instalación', field: 'installationType', resizable: true, width: 150, filter: true, cellRenderer: this.installationTypeCellRenderer },
    { headerName: 'Tipo cubierta', field: 'roofType', resizable: true, width: 150, filter: true, cellRenderer: this.roofTypeCellRenderer },
    { headerName: 'Ubicación', field: 'location', resizable: true, width: 250, filter: true },
    { headerName: 'Tarifa', field: 'consumptionRate.description', resizable: true, width: 200, filter: true },
    { headerName: 'Consumo [kWh]', field: 'consumption', resizable: true, cellRenderer: (data) => data.data.consumption !== undefined ? data.data.consumption.toFixed(2) : 0, width: 150, filter: true },
    { headerName: 'Rango consumo', field: 'consumptionRange', resizable: true, width: 150, filter: true },
    { headerName: 'Potencia contratada [kW]', field: 'hiredPower', resizable: true, width: 80, filter: true },
    { headerName: 'Núm. paneles', field: 'numPanels', resizable: true, width: 80, filter: true },
    { headerName: 'Pot. pico [Wp]', field: 'peakPower', resizable: true, cellRenderer: (data) => data.data.peakPower !== undefined ? (data.data.peakPower).toFixed(2) : 0, width: 80, filter: true },
    { headerName: 'Cobertura [%]', field: 'coverage', resizable: true, cellRenderer: (data) => data.data.coverage !== undefined ? (100 * data.data.coverage).toFixed(2) : 0, width: 80, filter: true },
    { headerName: 'Coste [€]', field: 'installationCost', resizable: true, cellRenderer: (data) => data.data.installationCost !== undefined ? data.data.installationCost.toFixed(2) : 0, width: 80, filter: true },
    { headerName: 'Antes Ud. pagaba [€]', field: 'billingBefore', resizable: true, cellRenderer: (data) => data.data.billingBefore !== undefined ? data.data.billingBefore.toFixed(2) : 0, width: 200, filter: true },
    { headerName: 'Ahora pagaría [€]', field: 'actualBilling', resizable: true, cellRenderer: (data) => data.data.actualBilling !== undefined ? data.data.actualBilling.toFixed(2) : 0, width: 200, filter: true },
    { headerName: 'Ahorro anual [€]', field: 'annualSaving', resizable: true, cellRenderer: (data) => data.data.annualSaving !== undefined ? data.data.annualSaving.toFixed(2) : 0, width: 200, filter: true },
    {
      headerName: 'Políticas aceptadas', field: 'acceptPolicy', resizable: true, cellRenderer: params => {
        return `<input type='checkbox' ${params.value ? 'checked' : ''} />`;
      }, width: 200, filter: true
    },
    {
      headerName: 'Envío aceptado', field: 'acceptSend', resizable: true,
      cellRenderer: params => {
        return `<input type='checkbox' ${params.value ? 'checked' : ''} />`;
      }, width: 200, filter: true
    },
  ];


  constructor(private logCalculationsService: SizeBasicLogCalculationsService,
    private crmCalculationsService: SizeBasicCRMCalculationsService) { }

  ngOnInit() {
    this.costeDesde = 0;
    this.costeHasta = 0;
    this.selectedType = 'LOG';

    this.picker = this.pickerDirective.picker;
    this.picker.minDate = moment().year(moment().year()).month(0).date(1).hour(0).minute(0).second(0);
    this.picker.maxDate = moment().year(moment().year()).month(11).date(31).hour(0).minute(0).second(0);
    this.rangeSelected = {
      startDate: this.picker.minDate,
      endDate: this.picker.maxDate,
    };

  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.paginationSetPageSize(25);
    this.gridColumnApi = params.columnApi;
    this.updateReports();
  }

  updateReports() {

    const desde = this.rangeSelected.startDate.toDate();
    const hasta = this.rangeSelected.endDate.endOf('day').toDate();

    if (this.selectedType === 'LOG') {
      this.logCalculationsService.getList(desde, hasta).subscribe(res => {
        this.registers = res;
        this.gridColumnApi.setColumnsVisible(['contactName', 'contactSurname', 'contactPhone', 'contactEmail', 'acceptPolicy', 'accepSend'], false);
        this.gridColumnApi.setColumnsVisible(['availableSurface', 'requiredSurface', 'roofType', 'installationType', 'consumptionRange'], true);
        this.gridApi.onFilterChanged();
        this.createPinnedRowData();
      });
    }

    if (this.selectedType === 'CRM') {
      this.crmCalculationsService.getList(desde, hasta).subscribe(res => {
        this.registers = res;
        this.gridColumnApi.setColumnsVisible(['contactName', 'contactSurname', 'contactPhone', 'contactEmail', 'acceptPolicy', 'accepSend'], true);
        this.gridColumnApi.setColumnsVisible(['availableSurface', 'requiredSurface', 'roofType', 'installationType', 'consumptionRange'], true);
        this.gridApi.onFilterChanged();
        this.createPinnedRowData();
      });
    }
  }



  exportToExcel() {
    let ws: XLSX.WorkSheet;

    const filteredData = this.gridApi.getModel().rootNode.childrenAfterFilter;

    const data = [];
    if (this.selectedType === 'LOG') {

      let installationType = '-';
      let roofType = '-';
      filteredData.forEach(x => {

        if (x.data.installationType === 1) { installationType = 'Industria'; }
        if (x.data.installationType === 2) { installationType = 'Vivienda unifamiliar'; }
        if (x.data.installationType === 4) { installationType = 'Hotel'; }

        if (x.data.roofType === 1) { roofType = 'Inclinada'; }
        if (x.data.roofType === 2) { roofType = 'Plana'; }

        const register = {
          Fecha: x.data.date,
          Latitud: x.data.latitude,
          Longitud: x.data.longitude,
          Ubicación: x.data.location,
          Municipio: x.data.town,
          Provincia: x.data.city,
          Región: x.data.state,
          País: x.data.country,
          'Superficie disponible': x.data.availableSurface,
          'Superficie necesaria': x.data.requiredSurface,
          'Tipo instalación': installationType,
          'Tipo cubierta': roofType,
          Tarifa: x.data.consumptionRate.description,
          Consumo: x.data.consumption,
          'Rango consumo': x.data.consumptionRange,
          Panel: x.data.panel,
          Inversor: x.data.inverter,
          Paneles: x.data.numPanels,
          'Potencia pico [Wp]': x.data.peakPower,
          'Cobertura [%]': x.data.coverage,
          'Coste instalación [€]': x.data.installationCost,
          'Potencia contratada [kW]': x.data.hiredPower,
          'Energía consumida [Wh]': x.data.consumedEnergy,
          'Energía producida [Wh]': x.data.generatedEnergy,
          'Energía compensada [Wh]': x.data.compensatedEnergy,
          'Antes Ud. pagaba [€]': x.data.actualBilling,
          'Ahorro anual [€]': x.data.annualSaving,
        };
        data.push(register);
      });
    }

    if (this.selectedType === 'CRM') {
      filteredData.forEach(x => {
        const register = {
          Fecha: x.data.date,
          Latitud: x.data.latitude,
          Longitud: x.data.longitude,
          'Nombre contacto': x.data.contactName,
          'Apellidos contacto': x.data.contactSurname,
          'Teléfono contacto': x.data.contactPhone,
          'Email contacto': x.data.contactEmail,
          Ubicación: x.data.location,
          Municipio: x.data.town,
          Provincia: x.data.city,
          Región: x.data.state,
          País: x.data.country,
          Tarifa: x.data.consumptionRate.description,
          Consumo: x.data.consumption,
          Panel: x.data.panel,
          Inversor: x.data.inverter,
          Paneles: x.data.numPanels,
          'Potencia pico [Wp]': x.data.peakPower,
          'Cobertura [%]': x.data.coverage,
          'Coste instalación [€]': x.data.installationCost,
          'Potencia contratada [kW]': x.data.hiredPower,
          'Energía consumida [Wh]': x.data.consumedEnergy,
          'Energía producida [Wh]': x.data.generatedEnergy,
          'Energía compensada [Wh]': x.data.compensatedEnergy,
          'Antes Ud. pagaba [€]': x.data.actualBilling,
          'Ahorro anual [€]': x.data.annualSaving,
          'Políticas aceptadas': x.data.acceptPolicy ? 'Sí' : 'No',
          'Envío aceptado': x.data.acceptSend ? 'Sí' : 'No',
        };
        data.push(register);
      });
    }



    ws = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reportes');
    XLSX.writeFile(wb, 'Reportes.xlsx');
  }

  onfilterChanged() {
    this.createPinnedRowData();
  }

  createPinnedRowData() {
    const data = [];

    const filteredData = this.gridApi.getModel().rootNode.childrenAfterFilter;

    data.push({
      date: filteredData.length + ' registros',
      latitude: '-',
      longitude: '-',
      consumptionRate: '-',
      consumption: filteredData.reduce((a, b) => a + b.data.consumption, 0),
      panel: '-',
      inverter: '-',
      numPanels: 0,
      peakPower: filteredData.reduce((a, b) => a + b.data.peakPower, 0),
      coverage: 0,
      installationCost: filteredData.reduce((a, b) => a + b.data.installationCost, 0),
      hiredPower: '-',
      consumedEnergy: filteredData.reduce((a, b) => a + b.data.consumedEnergy, 0),
      generatedEnergy: filteredData.reduce((a, b) => a + b.data.generatedEnergy, 0),
      compensatedEnergy: filteredData.reduce((a, b) => a + b.data.compensatedEnergy, 0),
      actualBilling: 0,
      annualSaving: filteredData.reduce((a, b) => a + b.data.annualSaving, 0),
    });

    this.gridApi.setPinnedBottomRowData(data);
  }


  roofTypeCellRenderer(params) {
    if (params.value === 1) { return 'Inclinada'; }
    if (params.value === 2) { return 'Plana'; }
  }

  installationTypeCellRenderer(params) {
    if (params.value === 1) { return 'Industria'; }
    if (params.value === 2) { return 'Vivienda unifamiliar'; }
    if (params.value === 4) { return 'Hotel'; }
  }

  rangeChange(event) {

  }
}



class DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}


