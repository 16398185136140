import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { Role } from 'projects/pplus/src/lib/models/permissions/role.model';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';
import { ROLES_HEADERS } from './columnDefs';
import { RolesService } from 'projects/pplus/src/lib/services/permissions/roles.service';

@Component({
  selector: 'pplus-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {

  splitOptions = {
    open: false,
    direction: 'horizontal',
    contentSize: 100,
    formSize: 0,
  };

  gridOptions: GridOptions;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  rowData: any[] = [];
  rolesList: Role[];
  selectedRow: any;
  titleForm = 'Detalles';

  constructor(private dataRowService: RowDataService,
    private breakPointObserver: BreakpointObserver,
    private rolesService: RolesService) { }

  ngOnInit() {
    this.gridOptions = <GridOptions>{};
    this.gridOptions.frameworkComponents = {
      checkBoxComponent: AgCheckboxComponent
    };
    this.columnDefs = ROLES_HEADERS;
    this.getRolesList();
  }

  getRolesList() {
    this.rolesService.getList().subscribe(res => {
      this.rolesList = res;
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  }

  onGridRowClicked(row) {
    this.selectedRow = JSON.parse(JSON.stringify(row.data));
    this.dataRowService.updateRowData(this.selectedRow);
  }

  rowDoubleClicked($event) {
    this.openSplit();
  }

  openSplit() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(res => {
      if (res.matches) {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 0,
          formSize: 100,
        };
      } else {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 70,
          formSize: 30,
        };
      }
    });
  }

  resizeGrid(event) {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  readSplitOptions(event: any) {
    if (event.opt) {
      this.splitOptions = event.opt;
    }
  }

  quickSearch(data: string) {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(data);
    }
  }

  clearRow() {
    this.selectedRow = new Role();
    this.dataRowService.updateRowData(this.selectedRow);
  }

  updateData(data) {

  }

}
