import { Component, ViewChild, AfterViewInit, Input, ViewChildren, QueryList } from '@angular/core';
import { PVSelfCalculation } from '../../models/PvSelfCalculation.model';
import { PVSelfCalculationService } from '../../services/pvself-calculation.service';
import { PvselfConsumersService } from '../../services/pvself-consumers.service';
import { TabsComponent } from 'projects/pplus/src/lib/components/tabs/tabs.component';
import { TranslateService } from '@ngx-translate/core';
import { ConsumptionComponent } from './consumption/consumption.component';

@Component({
  selector: 'app-consumer',
  templateUrl: './consumer.component.html',
  styleUrls: ['./consumer.component.scss'],
})
export class ConsumerComponent implements AfterViewInit {
  _calculation: PVSelfCalculation;
  @ViewChild(TabsComponent, { static: false }) tabsComponent;
  @ViewChild('consumer', { static: false }) consumerTemplate;
  @ViewChild('consumption', { static: true }) consumptionComponent: ConsumptionComponent;
  @ViewChildren(ConsumptionComponent) components !: QueryList<ConsumptionComponent>;
  @Input('tabType') tabType: string;


  constructor(private calculationService: PVSelfCalculationService,
    private consumersService: PvselfConsumersService,
    private translateService: TranslateService) { }

  ngAfterViewInit(): void {
    this.consumersService.setTabs(this.tabsComponent);
    this.createConsumers();
  }

  createConsumers() {
    this._calculation = this.calculationService.get();
    this._calculation.consumers.forEach(x => {
      this.tabsComponent.openTab((x.pvSelfCalculationConsumerId === 0 ? this.translateService.instant('COMMON.CONSUMER') : x.description), this.consumerTemplate, {
        'tabType': this.tabsComponent.tabType,
        'tabContentOrigin': '',
        '_calculation': this._calculation,
        'consumer': x
      }, true);
    });
  }

  rateChanged() {
    this.components.first.rateChanged();
  }

}
