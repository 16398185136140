export const PERIODS = {
  P1: 'P1',
  P2: 'P2',
  P3: 'P3',
  P4: 'P4',
  P5: 'P5',
  P6: 'P6',
};

export const REACTIVE_DATA = {
  CONSUMPTION: {
    id: 'consumption',
    label: 'Consumo Total',
    periodColorKey: 'consumo',
    areaColorKey: 'consumoTotal',
    INDUCTIVE: {
      id: 'consumption_I',
      label: 'Consumo Total Inductiva',
    },
    CAPACITIVE: {
      id: 'consumption_C',
      label: 'Consumo Total Capacitiva',
    }
  },
  NETWORK_CONSUMPTION: {
    id: 'networkConsumption',
    label: 'Consumida Red',
    periodColorKey: 'consumo_red',
    areaColorKey: 'consumidaRed',
    INDUCTIVE: {
      id: 'networkConsumption_I',
      label: 'Consumida Red Inductiva',
    },
    CAPACITIVE: {
      id: 'networkConsumption_C',
      label: 'Consumida Red Capacitiva',
    }
  },
  EXCEDENTARY: {
    id: 'excedentary',
    label: 'Excedentaria',
    periodColorKey: 'excedente',
    areaColorKey: 'excedentaria',
    INDUCTIVE: {
      id: 'excedentary_I',
      label: 'Excedentaria Inductiva',
    },
    CAPACITIVE: {
      id: 'excedentary_C',
      label: 'Excedentaria Capacitiva',
    }
  },
  GENERATED: {
    id: 'generated',
    label: 'Neta Generada',
    periodColorKey: 'netaGenerada',
    areaColorKey: 'netaGenerada',
    INDUCTIVE: {
      id: 'generated_I',
      label: 'Neta Generada Inductiva',
    },
    CAPACITIVE: {
      id: 'generated_C',
      label: 'Neta Generada Capacitiva',
    }
  }
};
