import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material';
import { SplitFormComponent } from './split-form.component';
import { AngularSplitModule } from 'angular-split';

@NgModule({
  declarations: [
    SplitFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    AngularSplitModule.forRoot()
  ],
  exports: [
    SplitFormComponent
  ]
})
export class PPlusSplitFormModule { }
