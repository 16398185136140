export const APP_EFFECTIVE_PERMISSIONS_HEADERS = [
    {
        headerName: 'ID',
        rowDrag: false,
        minWidth: 50,
        field: 'applicationId',
        resizable: true,
        status: false,
    },
    {
        headerName: 'Description',
        rowDrag: false,
        minWidth: 50,
        field: 'description',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: false,
    },
    {
        headerName: 'User groups',
        minWidth: 100,
        field: 'userGroups',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: true,
        valueFormatter: function (params: any) {
            const userGroups = params.data.userGroups;
            let data = '';
            for (let index = 0; index < userGroups.length; index++) {
                if (index > 0) { data += ', '; }
                data += userGroups[index];
            }
            return params.data.userGroups = data;
        }
    }
];

