import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IEntityUserGroup } from 'projects/pplus/src/lib/interfaces/interfaces';
import { USER_GROUP_COL_DEFS } from './user-group.model';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService {
  private apiUrl = environment.API_URL + '/api/admin/usergroup';

  constructor(private http: HttpClient) {}

  getAllUserGroupsInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const body = {
      startRow,
      endRow,
      sortModel,
      filterModel
    };
    const url = `${this.apiUrl}/get-usergroups`;
    return this.http
      .post(url, body)
      .pipe(map((response) => {
        return <any>response;
      }));
  }

  getAllUserGroups(): Observable<IEntityUserGroup[]> {
    return this.http.get(this.apiUrl).pipe(
      map(response => <IEntityUserGroup[]>response),
      catchError(() => of([]))
    );
  }

  getUserGroupsList(): Observable<IEntityUserGroup[]> {
    return this.http.get(this.apiUrl + '/list').pipe(
      map(response => <IEntityUserGroup[]>response),
      catchError(() => of([]))
    );
  }

  getUserGroup(id: number): Observable<IEntityUserGroup> {
    return this.http.get(`${this.apiUrl}/${id}`).pipe(
      map(response => <IEntityUserGroup>response),
      catchError(() => of(null))
    );
  }

  createUserGroup(data: IEntityUserGroup): Observable<any> {
    return this.http.post(this.apiUrl, data, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? response.body : null),
      catchError(() => of(null))
    );
  }

  updateUserGroup(id: number, data: IEntityUserGroup): Observable<boolean> {
    return this.http.put(`${this.apiUrl}/${id}`, data, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  deleteUserGroup(id: number): Observable<boolean> {
    return this.http.delete(`${this.apiUrl}/${id}`, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  addUsersToUserGroup(userGroupId: number, userIds: number[]): Observable<boolean> {
    return this.http.post(this.apiUrl + '/assignusers', {userGroupId, userIds}, {observe: 'response'}).pipe(
      map(response => response.status === 200),
      catchError(() => of(false))
    );
  }

  addUserGroupsToUser(userId: number, userGroupIds: number[]): Observable<boolean> {
    return this.http.post(this.apiUrl + '/assigngroups', {userId, userGroupIds}, {observe: 'response'}).pipe(
      map(response => response.status === 200),
      catchError(() => of(false))
    );
  }

  getUserGroupsGeneralFilter(
    value: string, startRow: number, endRow: number, sortModel: any, filterModel: any
  ): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of USER_GROUP_COL_DEFS) columnList.push(colDef.field);
    const body = {
      startRow,
      endRow,
      sortModel,
      filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel
      },
      columnList,
    };
    const url = `${this.apiUrl}/get-userGroups/filterAll`;
    return this.http.post(url, body).pipe(map((response) => {
      return <any>response;
    }));
  }
}
