import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IEntityDomain } from 'projects/pplus/src/lib/interfaces/interfaces';
import { DOMAINS_COL_DEFS } from './domains.model';

@Injectable({
  providedIn: 'root'
})
export class DomainsService {
  private apiUrl = environment.API_URL + '/api/entity/domain';
  private readonly apiScrollInfinite = this.apiUrl + '/get-domains';

  constructor(private http: HttpClient) {}

  getAllDomainsInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const body = { startRow, endRow, sortModel, filterModel };
    return this.http.post(this.apiScrollInfinite, body).pipe(map(response => {
      return <any>response;
    }));
  }

  getAllDomains(): Observable<IEntityDomain[]> {
    return this.http.get(this.apiUrl).pipe(
      map(response => <IEntityDomain[]>response),
      catchError(() => of([]))
    );
  }

  getDomainsList(): Observable<IEntityDomain[]> {
    return this.http.get(this.apiUrl + '/list').pipe(
      map(response => <IEntityDomain[]>response),
      catchError(() => of([]))
    );
  }

  createDomain(data: IEntityDomain): Observable<IEntityDomain> {
    return this.http.post(this.apiUrl, data, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? <IEntityDomain>response.body : null),
      catchError(() => of(null))
    );
  }

  updateDomain(domainId: number, data: IEntityDomain): Observable<boolean> {
    return this.http.put(`${this.apiUrl}/${domainId}`, data, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  deleteDomain(domainId: number): Observable<boolean> {
    return this.http.delete(`${this.apiUrl}/${domainId}`, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  getDomainsGeneralFilter(
    value: string, startRow: number, endRow: number, sortModel: any, filterModel: any
  ): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of DOMAINS_COL_DEFS) columnList.push(colDef.field);
    return this.http.post(this.apiScrollInfinite, {
      startRow, endRow, sortModel, filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel,
      },
      columnList,
    });
  }

}
