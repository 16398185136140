import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StiReportsService {

  constructor(private httpClient: HttpClient) { }


  getReport(): Observable<any> {
    return this.httpClient.get<any>(environment.API_URL + '/api/Stimulsoft/getReport').pipe(
      map(data => data));
  }

  getReportData(installationId: number, desde: Date, hasta: Date): Observable<any> {

    const From = (desde.getMonth() + 1) + '-' + desde.getDate() + '-' + desde.getFullYear();
    const To = (hasta.getMonth() + 1) + '-' + hasta.getDate() + '-' + hasta.getFullYear();

    return this.httpClient.get<any>(environment.API_URL + '/api/Stimulsoft/getReportData?InstallationId=' + installationId + '&From=' + From + '&To=' + To).pipe(
      map(data => data));
  }

  getReportPDF(installationId: number, desde: Date, hasta: Date): Observable<Blob> {

    const From = (desde.getMonth() + 1) + '-' + desde.getDate() + '-' + desde.getFullYear();
    const To = (hasta.getMonth() + 1) + '-' + hasta.getDate() + '-' + hasta.getFullYear();

    return this.httpClient.get(environment.API_URL + '/api/Stimulsoft/GetReportAsPDF?InstallationId=' + installationId + '&From=' + From + '&To=' + To, { responseType: 'blob' });
  }

}
