import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MeterService } from '../meter.service';
import { PPlusMeterType } from 'projects/pplus/src/lib/models/pplus.enums';
import { Subscription } from 'rxjs/Subscription';
import { CosPhiSeries } from '../meter.models';
import * as moment from 'moment';

@Component({
  selector: 'pplus-meter-cosphi',
  templateUrl: './cosphi.component.html',
  styleUrls: ['./cosphi.component.scss']
})
export class CosphiComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();

  date: Date;
  idInstallation: number;

  loaderId: string;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private meterService: MeterService
  ) {

    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);

    this.date = new Date();
  }

  ngOnInit() {
    this.subscriptions = this.meterService.dataCosphi$.subscribe( (data: CosPhiSeries) => {
      if (data) {
        this.ngxLoader.stopLoader(this.loaderId);
      }
    });
  }

  dateChange(event) {
    this.date = event.value.toDate();
    this.getCosphiData();
  }

  setIdInstallation(id: number) {

    if (this.idInstallation !== id) {
      this.idInstallation = id;
      this.getCosphiData();
    }
  }

  private getCosphiData() {
    const opt = {
      InstallationId: this.idInstallation,
      meterType: PPlusMeterType.GenerationBT,
      date: this.date.toUTCString()
    };

    this.ngxLoader.startLoader(this.loaderId);
    this.meterService.getCosphiData(opt);
  }

  setDate(type: number) {
    switch (type) {
      case 1:
        this.date = moment().startOf('day').toDate();
        this.getCosphiData();
        break;
     case 2:
        this.date = moment().startOf('day').subtract(1, 'days').toDate();
        this.getCosphiData();
        break;
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}
