import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GridOptions } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GoogleStorageFile } from 'projects/pplus/src/lib/models/google.model';
import { PhotonDBManufacturer, PhotonDBResponse } from 'projects/pplus/src/lib/models/photonDB.model';
import { PhotondbService } from 'projects/pplus/src/lib/services/photondb.service';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';
import { SelfViewService } from '../../selfview/services/selfview-general.service';

@Component({
  selector: 'app-photondb',
  templateUrl: './photondb.component.html',
  styleUrls: ['./photondb.component.scss']
})
export class PhotondbComponent implements OnInit {
  dbFilesInStorage: GoogleStorageFile[];
  selectedStorageFileName: string;
  manufacturers: PhotonDBManufacturer[];
  gridOptions: GridOptions;
  photonDBResponse: PhotonDBResponse;
  showResults: boolean;
  gridApi;
  loaderId: string;


  columnDefs = [
//    { headerName: "", field: 'selected', editable: false, checkboxSelection: true },
    { headerName: 'Fabricante', field: 'name', editable: false, checkboxSelection: true },
    { headerName: 'Nuevo', field: 'existsInDB', editable: false },
  ];


  constructor(private photonDBService: PhotondbService,
    private ngxLoaderService: NgxUiLoaderService,
    private selfViewService: SelfViewService,
    private translate: TranslateService) {

    this.gridOptions = <GridOptions>{};
    this.gridOptions.paginationPageSize = 20;
    this.gridOptions.rowDragManaged = true;
    this.gridOptions.enableSorting = true;
    this.gridOptions.enableFilter = true;
    this.gridOptions.floatingFilter = true;
    // this.gridOptions.suppressPaginationPanel = true;
    // this.gridOptions.suppressScrollOnNewData = true;
    // this.gridOptions.rowBuffer=20;
    this.gridOptions.frameworkComponents = {
      checkBoxComponent: AgCheckboxComponent
    };
    this.gridOptions.overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="assets/loader.svg" alt="loader" class="img-fluid" style="width:40px;height:40px;"></span>';
    this.gridOptions.rowSelection = 'multiple';
  }

  ngOnInit() {
    this.showResults = false;
    this.dbFilesInStorage = [];
    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);
    this.photonDBResponse = new PhotonDBResponse();
//    this.getDBFilesInStorage();
  }


  getDBFilesInStorage() {
    this.photonDBService.getStorageDBFiles().subscribe(res => this.dbFilesInStorage = res);
  }

  getManufacturers() {
    this.photonDBService.getManufacturersFromDBFile(this.selectedStorageFileName).subscribe(res => this.manufacturers = res);
  }

  updateDB() {
//    this.selfViewService.prueba2().subscribe();

    // this.ngxLoaderService.startLoader(this.loaderId);
    // this.showResults = false;
    // const selectedRows = this.gridApi.getSelectedRows();
    // selectedRows.forEach(x => {
    //   this.photonDBService.updateDB(this.selectedStorageFileName, x.name).subscribe(res => {
    //     this.photonDBResponse.numErrors += res.numErrors;
    //     this.photonDBResponse.numInserted += res.numInserted;
    //     this.photonDBResponse.numUpdates += res.numUpdates;
    //   });
    // });
    // this.showResults = true;
    // this.ngxLoaderService.stopLoader(this.loaderId);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    // this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  }
}
