import { NgModule } from '@angular/core';
import { NbAccordionModule, NbCardModule, NbPopoverModule } from '@nebular/theme';
import { AgGridModule } from 'ag-grid-angular';
import { PvselfMyConfigurationComponent } from './pvself-my-configuration/pvself-my-configuration.component';
import { Ng5SliderModule } from 'ng5-slider';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { PVSelfMyCalculationsComponent } from './pvself-my-calculations/pvself-my-calculations.component';
import { PPlusAgGridModule, PPlusSplitFormModule } from 'projects/pplus/src/public_api';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule, MatIconModule, MatFormFieldModule, MatSelectModule, MatCheckboxModule, MatRadioModule, MatInputModule, MatButtonModule, MatStepperModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { PVSelfRegistersComponent } from './pvself-registers/pvself-registers.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [
    PvselfMyConfigurationComponent,
    PVSelfMyCalculationsComponent,
    PVSelfRegistersComponent
  ],
  imports: [
    CommonModule,
    AgGridModule.withComponents(),
    PPlusAgGridModule,
    NbAccordionModule,
    NbCardModule,
    Ng5SliderModule,
    NgxUiLoaderModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PPlusSplitFormModule,
    MatButtonToggleModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatButtonModule,
    NbPopoverModule,
    NbCardModule,
    MatStepperModule,
    NgxDaterangepickerMd,
  ],
  exports: [
    PvselfMyConfigurationComponent,
    PVSelfMyCalculationsComponent,
    PVSelfRegistersComponent
  ],
})
export class AdminPvselfModule { }
