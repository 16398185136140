import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { NbResetPasswordComponent, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators, FormGroup, ValidatorFn, ValidationErrors } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'lib-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends NbResetPasswordComponent {
  private _url = environment.API_URL + '/api/user/reset-password';

  form: FormGroup;
  password: FormControl;
  rePass: FormControl;

  constructor(service: NbAuthService,
    private _snackBar: MatSnackBar,
    private http: HttpClient,
    private translate: TranslateService,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    cd: ChangeDetectorRef,
    router: Router,
    private activatedRouter: ActivatedRoute) {
    super(service, options, cd, router);
    this.password = new FormControl('', [
      Validators.required,
    ]);

    this.rePass = new FormControl('', [
      Validators.required,
    ]);

    this.form = new FormGroup( {
      password: this.password,
      rePass: this.rePass,
    });
    this.form.setValidators(this.comparisonValidator());
  }

  comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const c1 = group.controls['password'];
      const c2 = group.controls['rePass'];
      if (c1.value !== c2.value) {
        c2.setErrors({notEquivalent: true});
      } else {
        c2.setErrors(null);
      }
      return;
    };
  }

  confirm() {
    this.activatedRouter.queryParams.subscribe( params => {
      this.user.UserId = params['userId'];
      this.user.token = decodeURIComponent(params['token']);
    });
    this.http.post(this._url, this.user).subscribe(
      () => {
        this.translate.get('FORGOT-PASSWORD.MSG.SUCCESS').subscribe( msg => {
          this._snackBar.open(`🎉 ${msg}`, 'Ok', { duration: 10 * 1000, });
          setTimeout(() => {
            return this.router.navigateByUrl('auth/login');
          }, 10 * 1000);
          this.cd.detectChanges();
        });
      },
      err => {
        this.translate.get('FORGOT-PASSWORD.MSG.ERROR').subscribe( msg => {
          this._snackBar.open(`❌ ${msg}`, 'Ok', { duration: 10 * 1000, });
          setTimeout(() => {
            return this.router.navigateByUrl('auth/login');
          }, 10 * 1000);
          this.cd.detectChanges();
        });
      }
    );
  }
}
