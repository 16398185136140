import { Component, OnInit } from '@angular/core';
import { PVSelfBalanceResults } from 'src/app/modules/pvself/models/PVSelfResultsModel.model';
import { PVSelfEngineService } from 'src/app/modules/pvself/services/pvself-engine.service';
import { savingChartOpts } from './savingChartOptions';

@Component({
  selector: 'pplus-pvself-balance-saving-economic-chart',
  templateUrl: './pvself-balance-saving-economic-chart.component.html',
  styleUrls: ['./pvself-balance-saving-economic-chart.component.scss']
})
export class PVSelfBalanceSavingEconomicChartComponent implements OnInit {
  balanceResults: PVSelfBalanceResults;
  savingChartOptions: any;

  initSavingChartOptions = {
    renderer: 'svg',
    height: 200
  };

  constructor(private engineService: PVSelfEngineService) { }


  ngOnInit() {
    this.savingChartOptions = savingChartOpts;

    this.engineService.balanceEnergyResults.subscribe((res: PVSelfBalanceResults) => {
      this.balanceResults = res;
      if (res !== null) { this.showSavingChart(); }
    });
  }



  showSavingChart() {
    const ahorroPorPeriodos = [];

    for (let i = this.balanceResults.economics.selfConsumptionSavingWCompensationWTaxesByPeriod.length - 1; i >= 0; i--) {
      ahorroPorPeriodos.push(this.balanceResults.economics.selfConsumptionSavingWCompensationWTaxesByPeriod[i]);
    }

    const ahorroPorPeriodosPercentage = [0.1, 0.2, 0.3, 0.4, 0.5, 0.6];
    const colors = ['#69D2E7', '#A7DBD8', '#E0E4CC', '#F38630', '#C7F464', '#4ECDC4'];
    const ahorroChartData = [];

    for (let i = 0; i < ahorroPorPeriodos.length; i++) {
      const row = {
        value: Math.round(ahorroPorPeriodos[i] * 100) / 100,
        percentage: Math.round(ahorroPorPeriodosPercentage[i] * 100) / 100,
        itemStyle: { color: colors[i] }
      };
      ahorroChartData.push(row);
    }

    this.savingChartOptions.series[0].data = ahorroChartData;
    this.savingChartOptions = { ...this.savingChartOptions, ...this.savingChartOptions };
  }




}
