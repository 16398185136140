import { Validators } from '@angular/forms';
import { Fields, DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';

const GENERALES: Fields[] = [
    {
        disabled: true,
        name: 'roleId',
        placeholder: 'ID',
        type: 'text',
        value: '',
    },
    {
        name: 'description',
        placeholder: 'DESCRIPCION',
        type: 'text',
        value: '',
        validators: [
            Validators.required,
        ],
    },
    {
        name: 'permissionIds',
        value: [],
        type: 'select',
        multiple: true,
        searchName: 'permissionsSearch',
        label: 'PERMISOS',
        options: []
    },
    {
        name: 'activated',
        placeholder: 'ACTIVADO',
        type: 'checkbox',
        value: true,
    },
];

export const ROLES_FORM: DynamicForm[] = [
    {
        title: 'DATOS GENERALES',
        open: true,
        fields: GENERALES,
    },
];
