import { Component, Inject, ChangeDetectorRef, Input, EventEmitter } from '@angular/core';
import { NbResetPasswordComponent, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NbToastrService } from '@nebular/theme';
import { FormGroup, FormControl, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-confirm-password',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})

export class ConfirmPasswordComponent extends NbResetPasswordComponent {
  private _url = environment.API_URL + '/api/user/confirm';
  public flag: boolean;
  form: FormGroup;
  password: FormControl;
  rePass: FormControl;

  constructor(private _snackBar: MatSnackBar,
              private translate: TranslateService,
              private http: HttpClient,
              service: NbAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              cd: ChangeDetectorRef, router: Router,
              private activatedRouter: ActivatedRoute) {
    super(service, options, cd, router);
    this.password = new FormControl('', [
      Validators.required,
    ]);

    this.rePass = new FormControl('', [
      Validators.required,
    ]);

    this.form = new FormGroup( {
      password: this.password,
      rePass: this.rePass,
    });
    this.form.setValidators(this.comparisonValidator());
  }

  comparisonValidator(): ValidatorFn {
    return (group: FormGroup): ValidationErrors => {
      const c1 = group.controls['password'];
      const c2 = group.controls['rePass'];
      if (c1.value !== c2.value) {
        c2.setErrors({notEquivalent: true});
      } else {
        c2.setErrors(null);
      }
      return;
    };
  }

  confirm() {
    this.activatedRouter.queryParams.subscribe( params => {
      this.user.UserId = params['userId'];
      this.user.token = decodeURIComponent(params['token']);
    });
    this.http.post(this._url, this.user).subscribe(
      () => {
        this.translate.get('CONFIRM-REGISTER.MSG.SUCCESS').subscribe( msg => {
          this._snackBar.open(`🎉 ${msg}`, 'Ok', { duration: 5 * 1000, });
          setTimeout(() => {
            return this.router.navigateByUrl('auth/login');
          }, 5 * 1000);
          this.cd.detectChanges();
        });
      },
      err => {
        this.translate.get('CONFIRM-REGISTER.MSG.ERROR').subscribe( msg => {
          this._snackBar.open(`❌ ${msg}`, 'Ok', { duration: 5 * 1000, });
          setTimeout(() => {
            return this.router.navigateByUrl('auth/login');
          }, 5 * 1000);
          this.cd.detectChanges();
        });
      }
    );
  }
}
