import { NgModule } from '@angular/core';
import { ViewTabsComponent } from './view-tabs/view-tabs.component';
import { TabsComponent } from './tabs.component';
import { TabComponent } from './tab.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatTooltipModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NbCardModule, NbIconModule } from '@nebular/theme';
import { DynamicTabsDirective } from './dynamic-tabs.directive';


@NgModule({
  declarations: [
    ViewTabsComponent,
    TabsComponent,
    TabComponent,
    DynamicTabsDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    DragDropModule,
    MatTooltipModule,
    // BORRAR
    NbIconModule,
    NbCardModule
  ],
  exports: [
    TabsComponent
  ],
  entryComponents: [
    TabComponent
  ],
})
export class PPlusTabsModule { }
