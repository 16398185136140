import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MeterEnergyByPeriodResults, MeterPlantInstallation } from './consumption-border-meter.models';

@Injectable({
  providedIn: 'root'
})
export class ConsumptionBorderMeterService {
  private _metersInstallationApi = environment.API_URL + '/api/meterinstallation/GetByInstallation';
  private _meterEnergyDataApi = environment.API_URL + '/api/bigquery/meter/historic/GetMetersEnergyByPeriod';

  public meters$ = new BehaviorSubject(<MeterPlantInstallation[]> null);
  public energyData$ = new BehaviorSubject(<MeterEnergyByPeriodResults> null);

  constructor(private http: HttpClient) { }

  public getMeters(id: number, meterType: number) {
    return this.http
    .get(this._metersInstallationApi + '?InstallationId=' + id + '&meterType=' + meterType)
    .subscribe( (meters: MeterPlantInstallation[]) => {
        this.meters$.next(meters);
    });
  }

  public getEnergyData(data): void {
    this.http
    .get(this._meterEnergyDataApi, {params: data})
    .subscribe( (energyData: MeterEnergyByPeriodResults) => {
        this.energyData$.next(energyData);
    });
  }

}
