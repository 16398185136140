
export const ROLES_HEADERS = [
    {
        headerName: 'ID',
        rowDrag: false,
        minWidth: 50,
        field: 'roleId',
        filter: false,
        resizable: true,
        status: false,
    },
    {
        headerName: 'Rol',
        minWidth: 100,
        field: 'description',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: true,
    },
    {
        headerName: 'Activated',
        cellRenderer: 'checkBoxComponent',
        field: 'activated',
        filter: false,
        minWidth: 100,
        resizable: true,
        status: true,
    },
    {
        headerName: 'Permissions',
        minWidth: 100,
        field: 'permissionNames',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: true,
        valueFormatter: function (r: any) {
            let value = '';
            const elems = r.data.rolePermissions;
            if (elems && elems.length) {
                for (const e of elems) {
                    if (value) { value += ', '; }
                    value += e.permission ? e.permission.description : '';
                }
            }
            return r.data.permissionNames = value;
        },
    },
];
