import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { map } from 'rxjs/operators';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { AppService } from '../app.service';
import { AppTypeService } from '../../app-type/app-type.service';
import { AppGroupService } from '../../app-group/app-group.service';
import { forkJoin } from 'rxjs';
import { EntitiesService } from '../../entities/entities.service';
import { IEntityApp } from 'projects/pplus/src/lib/interfaces/interfaces';
import { AdminService } from '../../admin.service';
import { ApplicationDetailsService } from './application-details.service';

@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss']
})
export class ApplicationDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  data: any;

  formData: DynamicForm[];
  formGroup: FormGroup;

  constructor(
    private dataRow: RowDataService,
    private appService: AppService,
    private appTypeService: AppTypeService,
    private appGroupService: AppGroupService,
    private entitiesService: EntitiesService,
    private dialog: NbDialogService,
    private adminService: AdminService,
    private applicationDetailsService: ApplicationDetailsService,
  ) {
    this.formData = this.applicationDetailsService.getDynamicForm();
  }

  generateForm(data: DynamicForm []) {
    const fieldsCtrls = {};
    data.forEach(item => {
      const fields = item.fields;
      fields.forEach(f => {
        fieldsCtrls[f.name] = new FormControl(f.value || '', f.validators);
      });
    });
    this.formGroup = new FormGroup(fieldsCtrls);
  }

  ngOnInit() {
    forkJoin([
      this.appTypeService.getAllAppTypes(),
      this.appGroupService.getAppGroupsList(),
      this.entitiesService.getEntitiesList(),
    ]).subscribe(([appTypes, appGroups, entities]) => {
      this.formData.map(o => {
        const fields = o.fields;
        fields.forEach(f => {
          if (f.name === 'applicationTypeId') {
            const opts = [];
            appTypes.map(o => {
              opts.push({
                value: o.applicationTypeId,
                label: o.name,
              });
            });
            f.options = opts;
          }
          else
          if (f.name === 'applicationGroups') {
            const opts = [];
            appGroups.map(o => {
              opts.push({
                value: o.applicationGroupId,
                label: o.name,
              });
            });
            f.options = opts;
          }
          else
          if (f.name === 'entities') {
            const opts = [];
            entities.map(o => {
              opts.push({
                value: o.entityId,
                label: o.code,
              });
            });
            f.options = opts;
          }
        });
      });
      this.generateForm(this.formData);
    });

    this.dataRow.currentRowData.pipe(map(o => {
      this.formData.forEach( data => {
        const fields = data.fields;
        fields.map(field => {
          if (field.name === 'applicationGroups') {
            const appGroups = o['applicationGroupApplications'];
            const value = [];
            if (appGroups && appGroups.length) for (const g of appGroups) value.push(g.applicationGroupId);
            field.value = value;
          }
          else
          if (field.name === 'entities') {
            const entities = o['entityApplications'];
            const value = [];
            if (entities && entities.length) for (const e of entities) value.push(e.entityId);
            field.value = value;
          }
          else field.value = o[field.name];
        });
      });
      return o;
    })).subscribe(data => {
      this.data = data;
      this.generateForm(this.formData);
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.appService.createApp(data).subscribe(newApp => {
        if (newApp !== null) {
          this.formGroup.patchValue({applicationId: this.data.applicationId = newApp.applicationId});
          forkJoin([
            this.appService.assignGroups(newApp.applicationId, this.formGroup.value.applicationGroups),
            this.appService.assignEntities(newApp.applicationId, this.formGroup.value.entities),
          ]).subscribe(success => {
            if (success.every(b => b === true)) {
              this.adminService.showToast('success',
                  'Se ha creado la nueva aplicación: ' + data.name,
                  'Elemento creado');
              this.updateDelete.emit(true);
            } else {
              this.adminService.showToast('danger',
                  'Se ha producido un error al asignar grupos y/o entidades a la nueva aplicación: ' + data.name,
                  'Error');
            }
          });
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al crear la nueva aplicación: ' + data.name,
              'Error');
        }
      });
    }
  }

  update() {
    if (this.data.applicationId == null) {
      this.onSubmit();
    } else if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const app = <IEntityApp>this.formGroup.value;
      app.activated = Boolean(app.activated);
      forkJoin([
        this.appService.updateApp(this.data.applicationId, app),
        this.appService.assignGroups(this.data.applicationId, this.formGroup.value.applicationGroups),
        this.appService.assignEntities(this.data.applicationId, this.formGroup.value.entities),
      ]).subscribe(success => {
        if (success.every(b => b === true)) {
          this.adminService.showToast('success',
              'Se ha actualizado la aplicación: ' + app.name,
              'Elemento actualizado');
          this.updateDelete.emit();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al actualizar la aplicación: ' + app.name,
              'Error');
        }
      });
    }
  }

  exit() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  delete() {
    if (this.data.applicationId == null) {
      this.adminService.showToast('info', 'Seleccione una aplicación', 'Info');
    } else {
      this.appService.deleteApp(this.data.applicationId).subscribe(success => {
        if (success) {
          this.adminService.showToast('success',
              'Se ha borrado la aplicación: ' + this.data.name,
              'Elemento eliminado');
          this.updateDelete.emit();
          this.clearData();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al borrar la aplicación: ' + this.data.name,
              'Error');
        }
      });
    }
  }

  copy() {
    if (this.data.applicationId == null) {
      this.adminService.showToast('info', 'Seleccione una aplicación para copiar la información', 'Info');
    } else {
      this.formGroup.setValue(Object.assign(this.data, this.formGroup.value, {
        applicationId: null,
        name: '',
      }));
    }
  }

  clearData() {
    this.data = {};
    this.formGroup.reset();
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.applicationId == null) {
      this.adminService.showToast('info', 'No hay ninguna aplicación seleccionada', 'Info');
    } else {
      this.dialog.open(dialog, { context: '¿Desea eliminar la aplicación?', hasScroll: false });
    }
  }

}
