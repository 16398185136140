import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TypesModule } from './types/types.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminFormComponent } from './admin/admin-form.component';
import { BuilderComponent } from './builder/builder.component';
import { NbAccordionModule, NbCardModule } from '@nebular/theme';
import {MatTabsModule} from '@angular/material/tabs';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { DynamicFormComponent } from './dynamic-form.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatFormFieldModule, MatSelectModule } from '@angular/material';


@NgModule({
  declarations: [
    AdminFormComponent,
    BuilderComponent,
    DynamicFormComponent
  ],
  imports: [
    CommonModule,
    TypesModule,
    FormsModule,
    MatTabsModule,
    ReactiveFormsModule,
    NbAccordionModule,
    MatButtonModule,
    MatIconModule,
    NbCardModule,
    TranslateModule.forChild(),
    MatFormFieldModule,
    MatSelectModule,
  ],
  exports: [
    AdminFormComponent
  ]

})
export class PPlusDynamicFormModule { }
