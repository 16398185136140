import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF } from "../admin.model";

export const PLANT_GROUP_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const PLANT_GROUP_COL_DEFS: ColDef[] = [
  {
    field: 'plantGroupId',
    headerName: 'ID',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'demoName',
    headerName: 'Demo Name',
  },
  {
    field: 'code',
    headerName: 'Code',
  },
  {
    field: 'demoCode',
    headerName: 'Demo Code',
  },
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    filter: 'agDateColumnFilter',
  },
  {
    field: 'deletionDate',
    headerName: 'Deletion Date',
    filter: 'agDateColumnFilter',
  },
  {
    field: 'activated',
    headerName: 'Activated',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkboxComponent',
  },
  {
    field: 'nominalPower',
    headerName: 'Nominal Power',
  },
  {
    field: 'peakPower',
    headerName: 'Peak Power',
  },
  {
    field: 'plants',
    headerName: 'Plants',
    valueFormatter: function (params) {
      if (params && params.data) {
        let value = '';
        const plants = params.data.plantGroupPlants;
        if (plants && plants.length) for (const plant of plants) {
          if (value) value += ', ';
          value += plant.plant.name;
        }
        return params.data.plants = value;
      }
    },
  },
  {
    field: 'ownerEntity',
    headerName: 'Owner Entity',
    valueFormatter: function (params) {
      if (params && params.data) {
        const ownerEntity = params.data.ownerEntity;
        return ownerEntity ? ownerEntity.code : '';
      }
    },
  },
  {
    field: 'entities',
    headerName: 'Entities',
    valueFormatter: function (params) {
      if (params && params.data) {
        let value = '';
        const entities = params.data.entityPlantGroups;
        if (entities && entities.length) for (const entity of entities) {
          if (value) value += ', ';
          value += entity.entity.code;
        }
        return params.data.entities = value;
      }
    },
  },
  {
    field: 'responsableOfTtr',
    headerName: 'TTR Responsible',
  },
];
