import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { USER_DEFAULT_COL_DEF, USER_COL_DEFS } from './user.model';
import { UserService } from './user.service';
import { GridOptions, IGetRowsParams, GridApi, ColumnApi, IDatasource } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';
import { NbDialogService } from '@nebular/theme';
import { Observable, forkJoin } from 'rxjs';
import { AgBoolFilterComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-bool-filter/ag-bool-filter.component';
import { AgBoolFloatingFilterComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-bool-filter/ag-bool-floating-filter/ag-bool-floating-filter.component';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild('splitContent', { static: false }) splitContent;
  @ViewChild('agGrid', { read: ElementRef, static: false }) agGridNativeElement: ElementRef;
  @ViewChild('dialog', { read: TemplateRef, static: false }) dialogRef: TemplateRef<unknown>;

  splitOptions = {
    open: false,
    direction: 'horizontal',
    contentSize: 100,
    formSize: 0,
  };

  contextMenu = {
    X: 0,
    Y: 0,
    state: false,
  };

  row: any = {};

  TitleForm = 'Detalles';
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridOptions: GridOptions;
  defaultColDef = USER_DEFAULT_COL_DEF;
  columnDefs = USER_COL_DEFS;

  rowModelType = 'infinite';
  infiniteInitialRowCount = 0;
  maxConcurrentDatasourceRequests = 2;
  maxBlocksInCache = 2;
  cacheOverflowSize = 2;

  private agDataSource: IDatasource = {
    rowCount: null,
    getRows: (rowParams: IGetRowsParams) => this.userService.getAllUsersInfiniteScroll(
      rowParams.startRow, rowParams.endRow, rowParams.sortModel, rowParams.filterModel
    ).subscribe(res => rowParams.successCallback(this.processUserFields(res.allUsers), res.lastRow))
  };

  constructor(
    private userService: UserService,
    private dataRow: RowDataService,
    private breakPointObserver: BreakpointObserver,
    private nbDialogService: NbDialogService,
    private adminService: AdminService,
  ) {
    this.gridOptions = <GridOptions>{};
    this.gridOptions.paginationPageSize = 10;
    this.gridOptions.rowDragManaged = true;
    this.gridOptions.floatingFilter = true;
    this.gridOptions.accentedSort = true;
    this.gridOptions.frameworkComponents = {
      checkBoxComponent: AgCheckboxComponent,
      boolFilter: AgBoolFilterComponent,
      boolFloatingFilter: AgBoolFloatingFilterComponent,
    };
    this.gridOptions.overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="assets/loader.svg" alt="loader" class="img-fluid" style="width:40px;height:40px;"></span>';
    this.gridOptions.rowSelection = 'multiple';
  }

  ngOnInit() {}

  private processUserFields(users: any[]) {
    for (const user of users) {
      const datePipe = new DatePipe('en-US');
      user.creationDate = datePipe.transform(user.creationDate, 'dd/MM/yyyy');
      user.lastLogin = datePipe.transform(user.aspNetUser.lastLogin, 'dd/MM/yyyy');
      user.aspNetUser.lastLogin = user.lastLogin;
      user.userName = user.aspNetUser.userName;

      if (user.userContacts && user.userContacts.length > 0) {
        for (let i = 0; i < user.userContacts.length; i++) {
          for (const key in user.userContacts[i]) {
            user[key + i] = user.userContacts[i][key];
          }
        }
      }
      if (user.userFinancials && user.userFinancials.length > 0) {
        for (let i = 0; i < user.userFinancials.length; i++) {
          user['userFinancialId' + i] = user.userFinancials[i].userFinancialId;
          user['businessName' + i] = user.userFinancials[i].businessName;
          user['nifCif' + i] = user.userFinancials[i].nifCif;
          user['countryFinancial' + i] = user.userFinancials[i].country;
          user['stateFinancial' + i] = user.userFinancials[i].state;
          user['cityFinancial' + i] = user.userFinancials[i].city;
          user['postalAddressFinancial' + i] = user.userFinancials[i].postalAddress;
          user['postCodeFinancial' + i] = user.userFinancials[i].postCode;
          user['paymentType' + i] = user.userFinancials[i].paymentType;
          user['bankAccount' + i] = user.userFinancials[i].bankAccount;
        }
      }
      if (this.row && this.row.userId === user.userId) {
        this.row = JSON.parse(JSON.stringify(user));
        this.dataRow.updateRowData(this.row);
      }
    }
    return users;
  }

  onGridRowClicked(row: any) {
    this.row = JSON.parse(JSON.stringify(row.data));
    this.dataRow.updateRowData(this.row);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', function() {
      setTimeout(function() {
        params.api.sizeColumnsToFit();
      });
    });
    this.agGridNativeElement.nativeElement.addEventListener('contextmenu', function ($event: any) {
      $event.preventDefault();
    });
    this.gridOptions.api.setDatasource(this.agDataSource);
  }

  onCellContextMenu($event) {
    $event.event.preventDefault();
    const e = $event.event;

    let posx = 0;
    let posy = 0;

    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft +
      document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop +
      document.documentElement.scrollTop;
    }

    this.contextMenu = {
      X: posx,
      Y: posy,
      state: true
    };
  }

  rowDoubleClicked() {
    this.openSplit();
  }

  openSplit() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(res => {
      if (res.matches) {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 0,
          formSize: 100,
        };
      } else {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 70,
          formSize: 30,
        };
      }
    });
  }

  readSplitOptions(event: any) {
    this.splitOptions = event.opt;
  }

  updateData(isCreation: boolean) {
    if (isCreation) this.row = null;
    this.gridOptions.api.refreshInfiniteCache();
  }

  quickSearch(data: string) {
    this.gridApi.setQuickFilter(data);
  }

  resizeGrid(event: any) {
    this.gridApi.sizeColumnsToFit();
  }

  clearRow() {
    this.row = {};
    this.dataRow.updateRowData(this.row);
  }

  generalSearch(value: string) {
    if (value) this.gridOptions.api.setDatasource({
      rowCount: null,
      getRows: (rowParams: IGetRowsParams) => {
        this.userService.getUsersGeneralFilter(
          value, rowParams.startRow, rowParams.endRow, rowParams.sortModel, rowParams.filterModel
        ).subscribe(res => {
          rowParams.successCallback(this.processUserFields(res.allUsers), res.lastRow);
        });
      }
    });
    else this.gridOptions.api.setDatasource(this.agDataSource);
  }

  deleteSelected() {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows && selectedRows.length) this.nbDialogService.open(
        this.dialogRef,
        {context: 'Are you sure you want to delete all selected users?'}
      ).onClose.subscribe(accepted => {
        if (accepted) {
          const deleteCalls: Observable<boolean>[] = [];
          for (const row of selectedRows) deleteCalls.push(this.userService.delete(row.userId));
          forkJoin(deleteCalls).subscribe(success => {
            if (success.every(b => b === true)) {
              this.adminService.showToast('success', 'All selected users deleted');
            } else {
              this.adminService.showToast('danger', 'One or more users couldn\'t be deleted');
            }
            this.clearRow();
            this.gridApi.refreshInfiniteCache();
            this.gridApi.deselectAll();
          });
        }
      });
    }
  }

}
