import { Component, OnInit, Input, HostListener, Output, EventEmitter } from '@angular/core';
import { RowDataService } from '../../split-form/services/row-data.service';

@Component({
  selector: 'pplus-agContextMenu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {
  @Input() contextMenu;
  @Input() row;
  @Output()
  eventSplit = new EventEmitter();

  constructor(private dataRow: RowDataService) { }

  ngOnInit() {
    this.dataRow.currentRowData.subscribe(row => this.row = row);
  }

  sendDataToForm() {
    this.dataRow.updateRowData(this.row);
    this.eventSplit.emit();
  }

  @HostListener ('document: click', ['$event'])
  public documentClick (event: Event) {
    this.contextMenu.state = false;
  }
}
