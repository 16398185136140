import { FilterService } from './filterTemplate.service';
import { NbPopoverDirective, NbMenuService, NB_WINDOW, NbToastrService, NbMenuItem } from '@nebular/theme';
import { Component, OnInit, Input, Output , EventEmitter, Inject, ViewChild} from '@angular/core';
import { RowDataService } from '../../split-form/services/row-data.service';
import { ICheckTemplate, Iselected } from '../../../interfaces/interfaces';
import { FormControl } from '@angular/forms';
import { TabService } from '../../tabs/tab.service';
import { TabComponent } from '../../tabs/tab.component';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ifStmt } from '@angular/compiler/src/output/output_ast';

class UserTemplate {
  name: string;
  template: string;
  favorite ?= false;
  applicationModuleId: number;
  userTemplateId ?: number;
}

@Component({
  selector: 'pplus-agMenu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  @Input() columns: any;
  @Input() gridColumnApi: any;
  @Input() filters: ICheckTemplate[];
  @Input() nombre: string;
  @Output() splitOptions = new EventEmitter();
  @Output() newDomain = new EventEmitter();
  @Output() searchEvent = new EventEmitter();
  @Output() clear = new EventEmitter();
  @Output() generalSearch = new EventEmitter();
  @Output() deleteSelected = new EventEmitter();
  @ViewChild(NbPopoverDirective, { static: false}) popover: NbPopoverDirective;
  @Input() visible = false;

  userTemplate = new UserTemplate();
  itemSelected: number;
  inputItemFormControl = new FormControl();

  row;
  searchInput;
  checkbox;
  inputItemNgModel;
  inputItemChangeNgModel;
  selectedid;
  checkedAll;

  checksave: ICheckTemplate = {
    userTemplateId: null,
    applicationName: null,
    userId: null,
    template: null,
  };
  objetofav: Iselected = {
    TemplateName: null,
    favorite: false,
    editable: false,
    columns: [],
  };

  idApp: number;

  searchInputTimeout: NodeJS.Timer;

  constructor( private nbMenuService: NbMenuService,
    private toastr: NbToastrService,
    private dataRow: RowDataService,
    private filterService: FilterService,
    private tabService: TabService,
    @Inject(NB_WINDOW) private window) {
    this.filters = null;
    this.checkedAll = true;
    /*
    this.tabService.tabSelected.pipe(
      switchMap( (tab: TabComponent) => {
        const dataContext = <NbMenuItem> tab.dataContext;
        const menuItem = dataContext.queryParams;
        this.idApp = menuItem.applicationModuleId;
        return this.filterService.GetUserTemplate(this.idApp);
      })
    )
    .subscribe( res => {
      const tmp = [];
      res.forEach( el => {
        tmp.push({
          applicationName: el.applicationName,
          userTemplateId: el.userTemplateId,
          userId: el.userId,
          template : JSON.parse(el.template),
        });
      });
      this.filters = tmp.length < 1 ? null : tmp;
    });
    */

    // Fix for duplicate calls for each open tab whenever the selected tab changes
    this.tabService.tabSelected.pipe(
      switchMap( (tab: TabComponent) => {
        const dataContext = <NbMenuItem> tab.dataContext;
        const menuItem = dataContext.queryParams;
        if (this.idApp === undefined) {
          this.idApp = menuItem.applicationModuleId;
          return this.filterService.GetUserTemplate(this.idApp);
        } else {
          return new Observable();
        }
      })
    )
    .subscribe( res => {
      if (res) {
        const tmp = [];
        res.forEach( el => {
          tmp.push({
            applicationName: el.applicationName,
            userTemplateId: el.userTemplateId,
            userId: el.userId,
            template : JSON.parse(el.template),
          });
        });
        this.filters = tmp.length < 1 ? null : tmp;
      }
    });
  }

  ngOnInit() {
    this.columns.forEach(element => {
      this.columns[element.headerName] = true;
      this.objetofav.columns.push({
        Name: element.headerName,
        field: element.field,
        state: true,
      });
    });
  }

  showToast(icon, status, title , msg) {
    this.toastr.show(title, msg , { icon, status });
  }

  selectAll() {
    if (this.checkedAll) {
      this.columns.forEach(element => {
        this.columns[element.headerName] = true;
        this.objetofav.columns.push({
          Name: element.headerName,
          field: element.field,
          state: true,
        });
        this.columnChange(element);
      });
    } else {
      this.columns.forEach(element => {
        this.columns[element.headerName] = false;
        this.objetofav.columns.push({
          Name: element.headerName,
          field: element.field,
          state: true,
        });
        this.columnChange(element);
      });
    }
  }

  columnChange(column) {
    this.itemSelected = null;
    if (!this.columns[column.headerName]) {
      this.gridColumnApi.setColumnVisible(column.field , false);
      this.columns[column.headerName] = false;
      this.objetofav.columns.forEach(el => {
        if (el.Name === column.headerName) {
          el.state = false;
        }
         });
    } else {
      this.gridColumnApi.setColumnVisible(column.field , true);
      this.columns[column.headerName] = true;
      this.objetofav.columns.forEach(el => {
        if (el.Name === column.headerName) {
          el.state = true;
        }
      });
    }
  }

  doubleclick(item: any) {
    this.inputItemChangeNgModel = item.template.TemplateName;
    this.visible = true;
    this.selectedid = item.userTemplateId;
  }

  loadTemplates() {
    this.visible = false;
    this.dataRow.currentRowData.subscribe(row => this.row = row);
    this.filterService.GetUserTemplate(this.idApp)
    .subscribe( res => {
      const tmp = [];
      res.forEach(el => {
        tmp.push({
          name: el.name,
          userTemplateId: el.userTemplateId,
          userId: el.userId,
          favorite: el.favorite,
          template : JSON.parse(el.template),
        });
      });
     this.filters = tmp.length < 1 ? null : tmp;
    });
    this.popover.rebuild();
  }

  onSearchInputChange(value: string) {
    if (this.searchInputTimeout) clearTimeout(this.searchInputTimeout);
    this.searchInputTimeout = setTimeout(() => this.generalSearch.emit(value), 500);
  }

  setCheckValue(value: number) {
    this.itemSelected = value;
    this.visible = false;
      this.filters.forEach(element => {
        if (element.userTemplateId === value) {
          element.template.columns.forEach(element => {
            this.gridColumnApi.setColumnVisible(<any>element.field, <boolean>element.state);
            this.columns[element.Name] = element.state;
          });
        }
      });
    }

  create() {
    this.open('update');
    this.dataRow.updateType('create');
    this.clear.emit();
  }

  saveTemplate() {
    this.userTemplate = {
      applicationModuleId: this.idApp,
      name: this.inputItemNgModel,
      template: JSON.stringify(this.objetofav),
    };
    this.filterService.createFilter(this.userTemplate)
      .subscribe(
        () =>  this.showToast('', 'success', 'Ha guardado la plantilla con exito', '¡Todo correcto!'),
        err => this.showToast('', 'warn', 'Error al crear la plantilla', '¡ERROR!')
      );
    this.inputItemNgModel = null;
  }

  changeName(item) {
    let dataToSend = new UserTemplate();
    dataToSend = {
      applicationModuleId: this.idApp,
      favorite: item.favorite,
      name: item.name,
      template: JSON.stringify(item.template)
    }
    this.filterService.updateFilter(item.userTemplateId, dataToSend)
      .subscribe(
        () => this.showToast('', 'success', 'Ha cambiado la plantilla con exito', '¡Todo correcto!'),
        err => this.showToast('', 'warn', 'Error al modificar el nombre de la plantilla', '¡ERROR!')
      );
    this.inputItemNgModel = null;
    this.loadTemplates();
  }


  addfav(item) {
    let dataToSend = new UserTemplate();
    dataToSend = {
      applicationModuleId: this.idApp,
      favorite: item.favorite,
      name: item.name,
      template: JSON.stringify(item.template)
    };
    this.filterService.updateFilter(item.userTemplateId, dataToSend)
      .subscribe(
        () => {
          this.loadTemplates();
          this.showToast('', 'success', 'Ha cambiado la plantilla con exito', '¡Todo correcto!');
        },
        err => this.showToast('', 'warn', 'Error al modificar el nombre de la plantilla', '¡ERROR!')
      );
    this.inputItemNgModel = null;
  }

  deleteTemplate(value: any) {
    this.filters.forEach(element => {
      if (element.userTemplateId === value) {
        this.filterService.deleteFilter(value)
        .subscribe(
          () => {
                  this.columns.forEach(el => {
                    this.gridColumnApi.setColumnVisible(<any>el.field, true);
                    this.columns[el.headerName] = true;
                    this.objetofav.columns.push({
                      Name: el.headerName,
                      field: el.field,
                      state: true,
                    });
                  });
                  this.showToast('', 'info', 'Ha eliminado la plantilla con exito', '¡Todo correcto!')
                },
          err => this.showToast('', 'warn', 'Error al crear la plantilla', '¡ERROR!')
        );
      }
      this.loadTemplates();
    });
  }

  open(mode) {
    const opt = {
      open: true,
      direction: 'horizontal',
      contentSize: 70,
      formSize: 30,
    };
    this.splitOptions.emit({opt, mode});
  }

  quickSearch() {
    this.searchEvent.emit(this.searchInput);
  }
}
