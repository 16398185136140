export const chart2Opts = {
    legend: {
        data: ['Rentabilidad [%]', 'Ahorro [€]'],
        x: 'center',
        selected: null
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {}
    },
    toolbox: {
        show: false,
        feature: {
            magicType: { type: ['line', 'bar'], title: { line: 'Line', bar: 'Bar' } },
            saveAsImage: { title: 'Descargar' }
        }
    }, 
    xAxis: {
        type: 'category',
        splitLine: { show: true }
    },
    yAxis: [{
        type: 'value',
        splitLine: { show: true }
    }, {
        type: 'value',
        splitLine: { show: true }
    }],
    series: [
        {
            name: 'Rentabilidad [%]',
            type: 'line',
            data: []
        },
        {
            name: 'Ahorro [€]',
            type: 'line',
            yAxisIndex: 1,
            data: []
        }
    ]
};
