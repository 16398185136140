import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { INSTALLATION_INVERTERS_COL_DEFS } from './installation-inverters.model';
import { IInstallationInverter } from 'projects/pplus/src/lib/interfaces/interfaces';

@Injectable({
  providedIn: 'root'
})
export class InstallationInvertersService {
  private readonly apiUrl = environment.API_URL + '/api/inverter/'; // TODO
  private readonly apiScrollInfinite = this.apiUrl + 'get-inverters'; // TODO

  constructor(private http: HttpClient) {}

  getInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const body = { startRow, endRow, sortModel, filterModel };
    return this.http.post(this.apiScrollInfinite, body).pipe(
      map(response => <any>response),
      catchError(() => of({
        inverters: [],
        lastRow: 0,
      }))
    );
  }

  getGeneralFilter(value: string, startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of INSTALLATION_INVERTERS_COL_DEFS) columnList.push(colDef.field);
    return this.http.post(this.apiScrollInfinite, {
      startRow, endRow, sortModel, filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel,
      },
      columnList,
    });
  }

  getAll(): Observable<IInstallationInverter[]> {
    return this.http.get(this.apiUrl).pipe(
      map(response => <IInstallationInverter[]>response),
      catchError(() => of([]))
    );
  }

  getList(): Observable<IInstallationInverter[]> {
    return this.http.get(this.apiUrl + 'list').pipe(
      map(response => <IInstallationInverter[]>response),
      catchError(() => of([]))
    );
  }

  get(installationInverterId: number): Observable<IInstallationInverter> {
    return this.http.get(this.apiUrl + installationInverterId).pipe(
      map(response => <IInstallationInverter>response),
      catchError(() => of(null))
    );
  }

  create(body: IInstallationInverter): Observable<IInstallationInverter> {
    return this.http.post(this.apiUrl, body, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? <IInstallationInverter>response.body : null),
      catchError(() => of(null))
    );
  }

  update(installationInverterId: number, body: IInstallationInverter): Observable<boolean> {
    return this.http.put(this.apiUrl + installationInverterId, body, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  delete(installationInverterId: number): Observable<boolean> {
    return this.http.delete(this.apiUrl + installationInverterId, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

}
