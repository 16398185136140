import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatCardModule, MatRippleModule, MatIconModule } from '@angular/material';
import { NbIconModule } from '@nebular/theme';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../@core/_auth/auth.guard';

export const DashboardRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: DashboardComponent
  },
];
@NgModule({
  declarations: [DashboardComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatCardModule,
    MatIconModule,
    MatRippleModule,
    NbIconModule,
    RouterModule.forChild(DashboardRoutes)
  ]
})
export class DashboardModule { }
