import { Component, OnInit} from '@angular/core';
import { MeterService } from 'projects/pplus/src/lib/components/elements/meter/meter.service';

@Component({
  selector: 'app-selfview-meter-totalizer',
  templateUrl: './selfview-meter-totalizer.component.html',
  styleUrls: ['./selfview-meter-totalizer.component.scss'],
  providers: [MeterService]
})
export class SelfviewMeterTotalizerComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }
}
