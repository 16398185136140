export const ELEMENT_TYPE_EFFECTIVE_PERMISSIONS_HEADERS = [
    {
        headerName: 'Entity',
        rowDrag: false,
        minWidth: 50,
        field: 'entity',
        resizable: true,
        status: false,
    },
    {
        headerName: 'UserGroup',
        rowDrag: false,
        minWidth: 50,
        field: 'userGroup',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: false,
    },
    {
        headerName: 'Profile',
        rowDrag: false,
        minWidth: 50,
        field: 'profile',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: false,
    },
    {
        headerName: 'Role',
        rowDrag: false,
        minWidth: 50,
        field: 'role',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: false,
    },
    {
        headerName: 'Permission',
        rowDrag: false,
        minWidth: 50,
        field: 'permission',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: false,
    },
    {
        headerName: 'Operation',
        rowDrag: false,
        minWidth: 50,
        field: 'operation',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: false,
    },
    {
        headerName: 'Permitted',
        cellRenderer: 'checkBoxComponent',
        field: 'permitted',
        filter: false,
        minWidth: 100,
        resizable: true,
        status: true,
    },
];
