import { Injectable } from '@angular/core';
import { PVSelfCalculationConsumer } from '../models/PvSelfCalculation.model';
import { TabsComponent } from 'projects/pplus/src/lib/components/tabs/tabs.component';

@Injectable({
  providedIn: 'root'
})
export class PvselfConsumersService {
  //  private _consumerTabs = new BehaviorSubject<TabsComponent>(null);
  consumerTabs: TabsComponent; // = this._consumerTabs.asObservable();

  constructor() { }


  setTabs(_tabs: TabsComponent) {
    this.consumerTabs = _tabs;
  }

  getConsumers() {
    const consumers: PVSelfCalculationConsumer[] = [];
    this.consumerTabs.dynamicTabs.forEach(x => {
      x.dataContext.consumer.description = x.title;
      consumers.push(x.dataContext.consumer);
    });
    return consumers;
  }

  setConsumers(consumers: PVSelfCalculationConsumer[]) {
    if (this.consumerTabs !== undefined) {
      consumers.forEach((x, index) => {
        this.consumerTabs.dynamicTabs[index].dataContext.consumer = x;
      });
    }
  }

}
