import { Component, OnInit, ViewChild } from '@angular/core';
import { PVSelfRegistersService } from '../../services/pvself-registers.service';
import { PVSelfRegisterView } from '../../models/PVSelfRegister.model';
import * as moment from 'moment';
import { LocaleConfig, DaterangepickerDirective, DaterangepickerComponent } from 'ngx-daterangepicker-material';
import * as XLSX from 'xlsx';


@Component({
  selector: 'pplus-pvself-registers',
  templateUrl: './pvself-registers.component.html',
  styleUrls: ['./pvself-registers.component.scss']
})

export class PVSelfRegistersComponent implements OnInit {
  @ViewChild(DaterangepickerDirective, { static: true }) pickerDirective: DaterangepickerDirective;

  registers: PVSelfRegisterView[];
  private gridApi;

  picker: DaterangepickerComponent;
  rangeSelected: DateRange;
  costeDesde: number;
  costeHasta: number;


  locale: LocaleConfig = {
    applyLabel: 'Aceptar',
    format: 'DD/MM/YYYY',
    firstDay: 1,
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort()
  };

  ranges: any = {
    'Año completo': [moment().year(moment().year()).month(0).date(1), moment().year(moment().year()).month(11).date(31)],
  };


  columnDefs = [
    { headerName: 'ID', field: 'pVSelfRegisterId', resizable: true, hide: true },
    { headerName: 'Código', field: 'code', resizable: true, width: 120, filter: true },
    {
      headerName: 'Fecha de creación', field: 'date', resizable: true, cellRenderer: (data) => moment(data.data.date).format('DD/MM/YYYY HH:mm:ss'),
      width: 180, filter: 'agDateColumnFilter',
      filterParams: {

        // provide comparator function
        comparator: function (filterDate, cellValue) {
          let dateAsString = cellValue;

          const index = dateAsString.indexOf('T');
          dateAsString = dateAsString.substring(0, index);
          if (dateAsString == null) { return 0; }

          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts = dateAsString.split('-');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);

          if (filterDate.getDate() === day &&
            filterDate.getMonth() === month &&
            filterDate.getFullYear() === year) { return 0; }

          return 1;
        }
      }
    },

    { headerName: 'Usuario', field: 'user', resizable: true, width: 200, filter: true },
    { headerName: 'Latitud', field: 'latitude', resizable: true, width: 100, filter: false },
    { headerName: 'Longitud', field: 'longitude', resizable: true, width: 100, filter: false },
    { headerName: 'Ubicación', field: 'location', resizable: true, width: 250, filter: true },
    { headerName: 'Tarifa', field: 'consumptionRate', resizable: true, width: 200, filter: true },
    { headerName: 'Consumo [kWh]', field: 'consumption', resizable: true, cellRenderer: (data) => data.data.consumption !== undefined ? data.data.consumption.toFixed(2) : 0, width: 150, filter: true },
    { headerName: 'Panel', field: 'panel', resizable: true, width: 180, filter: true },
    { headerName: 'Inversor', field: 'inverter', resizable: true, width: 180, filter: true },
    { headerName: 'Núm. paneles', field: 'numPanels', resizable: true, width: 80, filter: true },
    { headerName: 'Núm. inversores', field: 'numInverters', resizable: true, width: 80, filter: true },
    { headerName: 'Pot. pico [Wp]', field: 'peakPower', resizable: true, cellRenderer: (data) => data.data.peakPower !== undefined ? (1000 * data.data.peakPower).toFixed(2) : 0, width: 80, filter: true },
    { headerName: 'Pot. nominal [W]', field: 'nominalPower', resizable: true, cellRenderer: (data) => data.data.nominalPower !== undefined ? (1000 * data.data.nominalPower).toFixed(2) : 0, width: 80, filter: true },
    { headerName: 'Cobertura [%]', field: 'coverage', resizable: true, cellRenderer: (data) => data.data.coverage !== undefined ? (100 * data.data.coverage).toFixed(2) : 0, width: 80, filter: true },
    { headerName: 'Coste [€]', field: 'installationCost', resizable: true, cellRenderer: (data) => data.data.installationCost !== undefined ? data.data.installationCost.toFixed(2) : 0, width: 80, filter: true },
    { headerName: 'Potencia contratada [kW]', field: 'hiredPower', resizable: true, width: 80, filter: true },
    { headerName: 'Energía consumida [kWh]', field: 'consumedEnergy', resizable: true, cellRenderer: (data) => data.data.consumedEnergy !== undefined ? data.data.consumedEnergy.toFixed(2) : 0, width: 200, filter: true },
    { headerName: 'Energía producida [kWh]', field: 'generatedEnergy', resizable: true, cellRenderer: (data) => data.data.generatedEnergy !== undefined ? data.data.generatedEnergy.toFixed(2) : 0, width: 200, filter: true },
    { headerName: 'Energía compensada [kWh]', field: 'compensatedEnergy', resizable: true, cellRenderer: (data) => data.data.compensatedEnergy !== undefined ? data.data.compensatedEnergy.toFixed(2) : 0, width: 200, filter: true },
    { headerName: 'Antes Ud. pagaba [€]', field: 'billingBefore', resizable: true, cellRenderer: (data) => data.data.billingBefore !== undefined ? data.data.billingBefore.toFixed(2) : 0, width: 200, filter: true },
    { headerName: 'Ahora pagaría [€]', field: 'actualBilling', resizable: true, cellRenderer: (data) => data.data.actualBilling !== undefined ? data.data.actualBilling.toFixed(2) : 0, width: 200, filter: true },
    { headerName: 'Ahorro anual [€]', field: 'annualSaving', resizable: true, cellRenderer: (data) => data.data.annualSaving !== undefined ? data.data.annualSaving.toFixed(2) : 0, width: 200, filter: true },
    { headerName: 'Amortización [años]', field: 'amortization', resizable: true, cellRenderer: (data) => data.data.amortization !== undefined ? data.data.amortization.toFixed(2) : 0, width: 200, filter: true },
  ];


  constructor(private registersService: PVSelfRegistersService) { }

  ngOnInit() {
    this.costeDesde = 0;
    this.costeHasta = 0;

    this.picker = this.pickerDirective.picker;
    this.picker.minDate = moment().year(moment().year()).month(0).date(1).hour(0).minute(0).second(0);
    this.picker.maxDate = moment().year(moment().year()).month(11).date(31).hour(0).minute(0).second(0);
    this.rangeSelected = {
      startDate: this.picker.minDate,
      endDate: this.picker.maxDate,
    };

    this.updateReports();
  }


  onGridReady(params) {
    this.gridApi = params.api;
    this.gridApi.paginationSetPageSize(25);
  }

  updateReports() {

    const desde = this.rangeSelected.startDate.toDate();
    const hasta = this.rangeSelected.endDate.endOf('day').toDate();

    if (this.costeDesde === undefined || this.costeDesde === null) { this.costeDesde = 0; }
    if (this.costeHasta === undefined || this.costeHasta === null) { this.costeHasta = 0; }

    this.registersService.getList(desde, hasta, this.costeDesde, this.costeHasta).subscribe(res => {
      this.registers = res;
      this.gridApi.onFilterChanged();
      this.createPinnedRowData();
    });

  }



  exportToExcel() {
    let ws: XLSX.WorkSheet;

    const filteredData = this.gridApi.getModel().rootNode.childrenAfterFilter;

    const data = [];
    filteredData.forEach(x => {
      const register = {
        Codigo: x.data.code,
        Fecha: x.data.date,
        Usuario: x.data.user,
        Latitud: x.data.latitude,
        Longitud: x.data.longitude,
        Ubicación: x.data.location,
        Tarifa: x.data.consumptionRate,
        Consumo: x.data.consumption,
        Panel: x.data.panel,
        Inversor: x.data.inverter,
        Paneles: x.data.numPanels,
        Inversores: x.data.numInverters,
        'Potencia pico [Wp]': x.data.peakPower,
        'Potencia nominal [W]': x.data.nominalPower,
        'Cobertura [%]': x.data.coverage,
        'Coste instalación [€]': x.data.installationCost,
        'Potencia contratada [kW]': x.data.hiredPower,
        'Energía consumida [Wh]': x.data.consumedEnergy,
        'Energía producida [Wh]': x.data.generatedEnergy,
        'Energía compensada [Wh]': x.data.compensatedEnergy,
        'Antes Ud. pagaba [€]': x.data.actualBilling,
        'Ahorro anual [€]': x.data.annualSaving,
        'Amortización [años]': x.data.amortization
      };
      data.push(register);
    });


    ws = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Reportes');
    XLSX.writeFile(wb, 'Reportes.xlsx');
  }

  onfilterChanged() {
    this.createPinnedRowData();
  }

  createPinnedRowData() {
    const data = [];

    const filteredData = this.gridApi.getModel().rootNode.childrenAfterFilter;

    data.push({
      code: filteredData.length + ' registros',
      date: '',
      user: '-',
      latitude: '-',
      longitude: '-',
      consumptionRate: '-',
      consumption: filteredData.reduce((a, b) => a + b.data.consumption, 0),
      panel: '-',
      inverter: '-',
      numPanels: 0,
      numInverters: 0,
      peakPower: filteredData.reduce((a, b) => a + b.data.peakPower, 0),
      nominalPower: 0,
      coverage: 0,
      installationCost: filteredData.reduce((a, b) => a + b.data.installationCost, 0),
      hiredPower: '-',
      consumedEnergy: filteredData.reduce((a, b) => a + b.data.consumedEnergy, 0),
      generatedEnergy: filteredData.reduce((a, b) => a + b.data.generatedEnergy, 0),
      compensatedEnergy: filteredData.reduce((a, b) => a + b.data.compensatedEnergy, 0),
      actualBilling: 0,
      annualSaving: filteredData.reduce((a, b) => a + b.data.annualSaving, 0),
      amortization: filteredData.reduce((a, b) => a + b.data.amortization, 0),
    });

    this.gridApi.setPinnedBottomRowData(data);
  }

  rangeChange(event) {

  }
}




class DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

