import { Component, OnInit } from '@angular/core';
import { COVERAGE_CHART_OPTIONS, initOptionsForPieCharts } from './coverage-chart.config';
import { PVSelfBalanceResults } from 'src/app/modules/pvself/models/PVSelfResultsModel.model';
import { PVSelfEngineService } from 'src/app/modules/pvself/services/pvself-engine.service';

@Component({
  selector: 'pplus-pvself-balance-coverage-chart',
  templateUrl: './pvself-balance-coverage-chart.component.html',
  styleUrls: ['./pvself-balance-coverage-chart.component.scss']
})
export class PVSelfBalanceCoverageChartComponent implements OnInit {

  balanceResults: PVSelfBalanceResults;

  coverageChartOptions: any = COVERAGE_CHART_OPTIONS;
  coverageChartInitOptions = initOptionsForPieCharts;

  constructor(private engineService: PVSelfEngineService) {
  }


  ngOnInit() {
    this.engineService.balanceEnergyResults.subscribe((res: PVSelfBalanceResults) => {
      this.balanceResults = res;
      if (res !== null) { this.updateCoverageChart(); }
    });
  }


  updateCoverageChart() {
    this.coverageChartOptions = COVERAGE_CHART_OPTIONS;

    const coverage = this.balanceResults.energy.coverage * 100;
    const exploitation = this.balanceResults.energy.exploitation * 100;

    this.coverageChartOptions.series[0].data[0].value = coverage;
    this.coverageChartOptions.series[0].data[1].value = 0;
    this.coverageChartOptions.series[0].data[2].value = 100 - coverage;

    this.coverageChartOptions.series[1].data[0].value = exploitation;
    this.coverageChartOptions.series[1].data[1].value = 0;
    this.coverageChartOptions.series[1].data[2].value = 100 - exploitation;

    this.coverageChartOptions = { ...this.coverageChartOptions, ...this.coverageChartOptions };
  }
}
