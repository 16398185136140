import { Component, ViewChild, ElementRef, AfterViewInit, OnInit } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SelfviewGeneralTablesService } from '../../../services/selfview-general-tables.service';
import * as XLSX from 'xlsx';
import { PeriodTableData, PeriodData } from '../../../models/selfview-period.model';
import { SelfViewParams, DataMode } from '../../../models/selfview.model';

@Component({
  selector: 'pplus-selfview-period-table',
  templateUrl: './selfview-period-table.component.html',
  styleUrls: ['./selfview-period-table.component.scss']
})
export class SelfviewPeriodTableComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('table', {static: false, read: ElementRef}) table: ElementRef;

  data = new PeriodTableData();
  dataCompare = new PeriodTableData();
  params = new SelfViewParams();
  paramsCompare = new SelfViewParams();
  dataSource = new MatTableDataSource<PeriodData>([]);
  displayedColumns = ['period', 'generated', 'selfConsumption', 'excedentary', 'networkConsumption', 'consumption'];
  subheaderCols = [1, 1, 1, 1, 1, 1];
  compareMode = false;
  enableCompare = false;

  subheaderDisplayedColumns = ['total', 'totalGenerated', 'totalSelfConsumption', 'totalExcedentary', 'totalNetworkConsumption', 'totalConsumption'];
  _subheaderDisplayedColumns = ['_total', '_totalGenerated', '_totalSelfConsumption', '_totalExcedentary', '_totalNetworkConsumption', '_totalConsumption'];

  constructor(private tableService: SelfviewGeneralTablesService) { }

  ngOnInit() {
    this.dataSource.sort = this.sort;
    this.tableService.getPeriodTableData(DataMode.Normal).subscribe( (data: PeriodTableData) => {
      this.data = {...data};
      this.dataSource.data = data.periodData;
    });

    this.tableService.getPeriodTableData(DataMode.Compare).subscribe( (data: PeriodTableData) => {
      this.compareMode = true;
      this.dataCompare = {...data};
      this.dataSource.data = this.mergeData();
    });

    this.tableService._removeCompare.subscribe( (opt: boolean) => {
      if (opt) {
        this.dataSource.data = [...this.data.periodData];
        this.compareMode = false;
      }
    });
  }

  mergeData(): PeriodData [] {
    const _data: PeriodData [] = [];
    const normalData = [...this.data.periodData];
    const compareData = [...this.dataCompare.periodData];
    for (let i = 0; i < normalData.length; i++) {
      _data.push(normalData[i]);
      _data.push(compareData[i]);
    }
    return _data;
  }

  exportToExcel(): void {
    let ws: XLSX.WorkSheet;
    ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Data');
    XLSX.writeFile(wb, 'PPlus_E.Period.xlsx');
  }

}
