import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResultsComponent } from './results.component';
import { FinancialSummaryComponent } from './financial-summary/financial-summary.component';
import { NbCardModule, NbPopoverModule, NbStepperModule } from '@nebular/theme';
import { MatStepperModule } from '@angular/material/stepper';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AgGridModule } from 'ag-grid-angular';
import { MatSelectModule } from '@angular/material/select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatIconModule, MatInputModule, MatButtonModule, MatSlideToggleModule } from '@angular/material';
import { PVSelfEconomicSummaryComponent } from './pvself-economic-summary/pvself-economic-summary.component';
import { CountUpModule } from 'countup.js-angular2';
import { PVSelfBalanceResultsComponent } from './pvself-balance-results/pvself-balance-results.component';
import { PVSelfBalanceMainEnergyChartComponent } from './pvself-balance-results/pvself-balance-main-energy-chart/pvself-balance-main-energy-chart.component';
import { PVSelfBalanceCoverageChartComponent } from './pvself-balance-results/pvself-balance-coverage-chart/pvself-balance-coverage-chart.component';
import { PVSelfBalancePeriodsChartComponent } from './pvself-balance-results/pvself-balance-periods-chart/pvself-balance-periods-chart.component';
import { PVSelfBalanceMainEconomicChartComponent } from './pvself-balance-results/pvself-balance-main-economic-chart/pvself-balance-main-economic-chart.component';
import { PVSelfBalanceSavingEconomicChartComponent } from './pvself-balance-results/pvself-balance-saving-economic-chart/pvself-balance-saving-economic-chart.component';
import { PVSelfBalancePeriodsEconomicChartComponent } from './pvself-balance-results/pvself-balance-periods-economic-chart/pvself-balance-periods-economic-chart.component';

@NgModule({
  declarations: [ResultsComponent, FinancialSummaryComponent, PVSelfEconomicSummaryComponent, PVSelfBalanceResultsComponent, PVSelfBalanceMainEnergyChartComponent, PVSelfBalanceCoverageChartComponent, PVSelfBalancePeriodsChartComponent, PVSelfBalanceMainEconomicChartComponent, PVSelfBalanceSavingEconomicChartComponent, PVSelfBalancePeriodsEconomicChartComponent],
  imports: [
    CommonModule,
    NbCardModule,
    NbPopoverModule,
    MatStepperModule,
    FormsModule,
    TranslateModule,
    AgGridModule,
    MatSelectModule,
    NbStepperModule,
    NgxDaterangepickerMd,
    NgxEchartsModule,
    NgxUiLoaderModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    CountUpModule,
    MatSlideToggleModule
  ], exports: [
    ResultsComponent
  ]
})
export class ResultsModule { }
