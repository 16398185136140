import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { DataSetMeterHistoricEnergy, MeterHistoricPowerFactorDto, CosPhiSeries, MeterHistoricData } from './meter.models';
import { map } from 'rxjs/operators';

const WhtokWh = 0.001;

@Injectable({
  providedIn: 'root'
})
export class MeterService {

  private _meterEnergyDataApi = environment.API_URL + '/api/bigquery/meter/historic/GetEnergyBetweenDates';
  private _meterCosphiDataApi = environment.API_URL + '/api/bigquery/meter/historic/GetPowerFactorByDate';
  private _meterDataDownloadDataApi = environment.API_URL + '/api/bigquery/meter/historic/GetHourlyDataBetweenDates2';
  private _meterTotalizerDataApi = environment.API_URL + '/api/bigquery/meter/historic/GetTotalEnergyBetweenDates';

  public data$ = new BehaviorSubject(<DataSetMeterHistoricEnergy>null);
  public dataCosphi$ = new BehaviorSubject(<CosPhiSeries>null);

  constructor(private http: HttpClient) { }

  public getData(data) {
    return this.http
      .get(this._meterEnergyDataApi, { params: data })
      .subscribe((energyData: DataSetMeterHistoricEnergy) => {
        this.data$.next(energyData);
      });
  }

  public getCosphiData(data): void {
    this.http
      .get(this._meterCosphiDataApi, { params: data }).pipe(
        map((cosphiData: MeterHistoricPowerFactorDto[]) => {
          return cosphiData.map((item: MeterHistoricPowerFactorDto) => {
            if (item.power_Factor !== 1 && item.power_Factor !== 0) {
              item.power_Factor = item.power_Factor / 10000;
            }
            return item;
          });
        }),
        map((cosphiData: MeterHistoricPowerFactorDto[]) => {
          return this.generateCosphiSeries(cosphiData);
        })
      )
      .subscribe((cosphiData: any) => {
        this.dataCosphi$.next(cosphiData);
      });
  }

  getDataDownloadData(data): Observable<MeterHistoricData[]> {
    return this.http
      .get<MeterHistoricData[]>(this._meterDataDownloadDataApi, { params: data });
  }

  // TODO: crear modelo correcto cuando se concrete el servicio
  getTotalizerData(data): Observable<any> {
    return this.http
      .get<any>(this._meterTotalizerDataApi, { params: data });
  }

  public generateSerieForProperty(data: DataSetMeterHistoricEnergy, property: string) {
    return data.data.map(item => {
      const dateTimeString = moment(item.timestamp).format('DD/MM/YYYY HH:mm');
      return [dateTimeString, item[property] * WhtokWh];
    });
  }

  public generateCosphiSeries(data: MeterHistoricPowerFactorDto[]): CosPhiSeries {
    const cosPhiSeries: CosPhiSeries = {
      capacitivo: [],
      inductivo: [],
    };
    data.forEach((item: MeterHistoricPowerFactorDto, index: number) => {
      const dateTimeString = moment().startOf('day').add(index, 'hours').format('HH:mm');
      if (item.power_Factor >= 0) {
        cosPhiSeries.inductivo.push([dateTimeString, item.power_Factor]);
        cosPhiSeries.capacitivo.push([dateTimeString, 0]);
      } else {
        cosPhiSeries.capacitivo.push([dateTimeString, item.power_Factor]);
        cosPhiSeries.inductivo.push([dateTimeString, 0]);
      }
    });
    return cosPhiSeries;

  }

}
