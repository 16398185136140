import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PVSelfRegisterView } from '../models/PVSelfRegister.model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PVSelfRegistersService {

  constructor(private httpClient: HttpClient) { }

  getList(desde: Date, hasta: Date, costeDesde: number, costeHasta: number): Observable<PVSelfRegisterView[]> {

    const From = (desde.getMonth() + 1) + '-' + desde.getDate() + '-' + desde.getFullYear();
    const To = (hasta.getMonth() + 1) + '-' + hasta.getDate() + '-' + hasta.getFullYear();

    return this.httpClient.get<PVSelfRegisterView[]>(
      environment.API_URL + '/api/pvself/registers/getList?From=' + From + '&To=' + To + '&installationCostFrom=' + costeDesde +  '&installationCostTo=' + costeHasta).pipe(data => data);
  }
}
