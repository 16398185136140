import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { calendarOptions, Calendar, Month } from './chart-options';
import { PVSelfCalculation, PVSelfCalculationConsumer, PVSelfCalculationConsumptionTemplateProfile, PVSelfCalculationConsumptionTemplateProfilePeriod } from 'src/app/modules/pvself/models/PvSelfCalculation.model';
import { PVSelfConsumptionTemplatesService } from 'src/app/modules/pvself/services/pvself-consumption-templates.service';
import {
  PVSelfConsumptionTemplate, PVSelfConsumptionTemplateUsage, PVSelfConsumptionTemplateProfile,
  PVSelfConsumptionTemplateProfilePeriod
} from 'src/app/modules/pvself/models/PVSelfConsumptionTemplate.model';
import { TranslateService } from '@ngx-translate/core';
import { MatTabGroup } from '@angular/material';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { PVSelfCalculationService } from 'src/app/modules/pvself/services/pvself-calculation.service';
import { PVSelfCalendarHeaderComponent } from 'src/app/modules/pvself/shared/components/pvself-calendar-header/pvself-calendar-header.component';
import { PVSelfDateAdapter, PVSELF_DATE_FORMATS } from 'src/app/modules/pvself/shared/components/pvself-calendar-header/pvself-date-adapter';

@Component({
  selector: 'app-consumption-templates',
  templateUrl: './consumption-templates.component.html',
  styleUrls: ['./consumption-templates.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: PVSelfDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: PVSELF_DATE_FORMATS },
  ]
})

export class ConsumptionTemplatesComponent implements OnInit {
  @Input() _consumer: PVSelfCalculationConsumer;
  _calculation: PVSelfCalculation;
  selectedProfile: number;
  consumptionTemplates: PVSelfConsumptionTemplate[];
  consumptionTemplateUsages: PVSelfConsumptionTemplateUsage[];
  consumptionTemplateProfiles: PVSelfConsumptionTemplateProfile[];
  consumptionTemplateProfilePeriods: PVSelfConsumptionTemplateProfilePeriod[];
  profileTabs: string[];
  profileChartOptions: any[];
  optionsPerfil;
  minPeriodsDate: Date;
  maxPeriodsDate: Date;
  profilePeriodFromDate: Date[];
  profilePeriodToDate: Date[];
  profilePeriodMonday: boolean[];
  profilePeriodTuesday: boolean[];
  profilePeriodWednesday: boolean[];
  profilePeriodThursday: boolean[];
  profilePeriodFriday: boolean[];
  profilePeriodSaturday: boolean[];
  profilePeriodSunday: boolean[];
  calendarHeader: any;

  @ViewChild('profileTabsObject', { static: true }) profileTabsObject: MatTabGroup;

  perfil = [
    { viewValue: 'Perfil 1', graph: 'Graph 1' },
    { viewValue: 'Perfil 2', graph: 'Graph 2' },
    { viewValue: 'Perfil 3', graph: 'Graph 3' },
    { viewValue: 'Perfil 4', graph: 'Graph 4' },
    { viewValue: 'Perfil 5', graph: 'Graph 5' }
  ];


  profileDataChart1;
  percentageDataChart1;
  profileDataChart2;
  percentageDataChart2;
  profileDataChart3;
  percentageDataChart3;
  profileDataChart4;
  percentageDataChart4;
  profileDataChart5;
  percentageDataChart5;

  // Calendar
  chartCalendarOptions;

  graphInitOptions = {
    renderer: 'svg',
    width: 'auto',
    height: 400
  };

  calendarInitOption = {
    renderer: 'svg',
    width: 'auto',
    height: 400
  };

  constructor(private calculationService: PVSelfCalculationService,
    private templatesService: PVSelfConsumptionTemplatesService,
    private translate: TranslateService) { }

  ngOnInit() {
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
    //    this._calculation = this.calculationService.get();
    this.minPeriodsDate = new Date(this._calculation.calculateYear, 0, 1);
    this.maxPeriodsDate = new Date(this._calculation.calculateYear, 11, 31);

    this.calendarHeader = PVSelfCalendarHeaderComponent;


    this.profileChartOptions = [];
    this.consumptionTemplateProfilePeriods = [];
    this.profilePeriodFromDate = [];
    this.profilePeriodToDate = [];
    this.profilePeriodMonday = [];
    this.profilePeriodTuesday = [];
    this.profilePeriodWednesday = [];
    this.profilePeriodThursday = [];
    this.profilePeriodFriday = [];
    this.profilePeriodSaturday = [];
    this.profilePeriodSunday = [];
    this.selectedProfile = 0;

    this.getConsumptionTemplates();
    this.initChartCalendarOptions();
  }



  getConsumptionTemplates() {
    this.templatesService.getList().subscribe(res => {
      this.consumptionTemplates = res;
      this.getUsages(this._consumer.consumptionTemplateId);
    });
  }


  getUsages(idTemplate: any) {
    this.templatesService.getUsagesByTemplate(idTemplate).subscribe(res => {
      this.consumptionTemplateUsages = res;
      if (this._consumer.consumptionProfiles.length === 0) {
        this.getUsageProfiles(this._consumer.consumptionTemplateUsageId);
      } else {
        this.loadCalculationProfiles();
      }
    });

  }


  getUsageProfiles(idUsage: any) {
    this.templatesService.getProfilesByUsage(idUsage).subscribe(res => {
      this.consumptionTemplateProfiles = res;
      this.profileTabs = [];

      this.profileChartOptions = [];
      let order = 1;
      this._consumer.consumptionProfiles = [];
      this.consumptionTemplateProfiles.forEach(x => {
        x.order = order++;
        this.profileTabs.push(this.translate.instant(x.description));
        const newProfile: PVSelfCalculationConsumptionTemplateProfile = new PVSelfCalculationConsumptionTemplateProfile(x);
        this._consumer.consumptionProfiles.push(newProfile);
      });

      const numTabs = this.profileTabs.length;
      for (let i = numTabs; i < 5; i++) {
        this.profileTabs.push(this.translate.instant('COMMON.PROFILE') + ' ' + (i + 1));
        const newProfile: PVSelfCalculationConsumptionTemplateProfile = new PVSelfCalculationConsumptionTemplateProfile(null);
        newProfile.order = i + 1;
        newProfile.description = this.translate.instant('COMMON.PROFILE') + ' ' + (i + 1);
        this._consumer.consumptionProfiles.push(newProfile);
      }

      this._consumer.consumptionProfiles.forEach((x, index) => {
        this.initProfileCharOptions(index);
      });

      this.updatePeriodsInfoFromModelToView();
      this.profileTabsObject.selectedIndex = 0;

      this.updateCalendar();
    });
  }


  loadCalculationProfiles() {
    this.profileTabs = [];
    this.profileChartOptions = [];
    this._consumer.consumptionProfiles.forEach(x => {
      this.profileTabs.push(this.translate.instant(x.description));
    });

    const numTabs = this.profileTabs.length;
    for (let i = numTabs; i < 5; i++) {
      this.profileTabs.push(this.translate.instant('COMMON.PROFILE') + ' ' + (i + 1));
      const newProfile: PVSelfCalculationConsumptionTemplateProfile = new PVSelfCalculationConsumptionTemplateProfile(null);
      newProfile.description = this.translate.instant('COMMON.PROFILE') + ' ' + (i + 1);
      this._consumer.consumptionProfiles.push(newProfile);
    }

    this._consumer.consumptionProfiles.forEach((x, index) => {
      this.initProfileCharOptions(index);
    });

    this.updatePeriodsInfoFromModelToView();
    this.profileTabsObject.selectedIndex = 0;

    this.updateCalendar();
  }


  initProfileCharOptions(index: number) {
    this.optionsPerfil = {
      //      title: { id: 'Perfil2' },
      legend: { bottom: '0%' },
      tooltip: {},
      xAxis: {
        type: 'category',
        data: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
          '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
      },
      grid: {
        left: '30',
        right: '30',
        bottom: '45'
      },
      yAxis: [
        {
          type: 'value',
          name: this.translate.instant('COMMON.PROFILE') + ' [kW]',
          nameLocation: 'center',
        }
      ],
      series: [{
        name: this.translate.instant('COMMON.PROFILE'),
        id: index,
        type: 'bar',
        itemStyle: {
          color: this._consumer.consumptionProfiles[index].color
        },
        data: this.convertToChartData(this._consumer.consumptionProfiles[index])
      }
      ]
    };

    this.profileChartOptions.push(this.optionsPerfil);

  }

  convertToChartData(profile: PVSelfCalculationConsumptionTemplateProfile) {

    let data: number[];
    data = [];
    data.push(profile.h0);
    data.push(profile.h1);
    data.push(profile.h2);
    data.push(profile.h3);
    data.push(profile.h4);
    data.push(profile.h5);
    data.push(profile.h6);
    data.push(profile.h7);
    data.push(profile.h8);
    data.push(profile.h9);
    data.push(profile.h10);
    data.push(profile.h11);
    data.push(profile.h12);
    data.push(profile.h13);
    data.push(profile.h14);
    data.push(profile.h15);
    data.push(profile.h16);
    data.push(profile.h17);
    data.push(profile.h18);
    data.push(profile.h19);
    data.push(profile.h20);
    data.push(profile.h21);
    data.push(profile.h22);
    data.push(profile.h23);

    return data;
  }

  onChartClick = ($event) => {
    if (!this._calculation.locked) {
      const newValue = prompt(this.translate.instant('PVSELF.CONSUMPTION.ENTERCONSUMPTIONVALUE'));
      const chartIndex = $event.seriesId;
      const dataIndex = $event.dataIndex;

      this.profileChartOptions[chartIndex].series[0].data[dataIndex] = newValue;
      this.profileChartOptions[chartIndex] = { ...this.profileChartOptions[chartIndex], ...this.profileChartOptions[chartIndex] };
      this.updateConsumptionProfiles(chartIndex);
    }
  }

  updateConsumptionProfiles(index: number) {

    const data = this.profileChartOptions[index].series[0].data;
    this._consumer.consumptionProfiles[index].h0 = parseInt(data[0], 0);
    this._consumer.consumptionProfiles[index].h1 = parseInt(data[1], 0);
    this._consumer.consumptionProfiles[index].h2 = parseInt(data[2], 0);
    this._consumer.consumptionProfiles[index].h3 = parseInt(data[3], 0);
    this._consumer.consumptionProfiles[index].h4 = parseInt(data[4], 0);
    this._consumer.consumptionProfiles[index].h5 = parseInt(data[5], 0);
    this._consumer.consumptionProfiles[index].h6 = parseInt(data[6], 0);
    this._consumer.consumptionProfiles[index].h7 = parseInt(data[7], 0);
    this._consumer.consumptionProfiles[index].h8 = parseInt(data[8], 0);
    this._consumer.consumptionProfiles[index].h9 = parseInt(data[9], 0);
    this._consumer.consumptionProfiles[index].h10 = parseInt(data[10], 0);
    this._consumer.consumptionProfiles[index].h11 = parseInt(data[11], 0);
    this._consumer.consumptionProfiles[index].h12 = parseInt(data[12], 0);
    this._consumer.consumptionProfiles[index].h13 = parseInt(data[13], 0);
    this._consumer.consumptionProfiles[index].h14 = parseInt(data[14], 0);
    this._consumer.consumptionProfiles[index].h15 = parseInt(data[15], 0);
    this._consumer.consumptionProfiles[index].h16 = parseInt(data[16], 0);
    this._consumer.consumptionProfiles[index].h17 = parseInt(data[17], 0);
    this._consumer.consumptionProfiles[index].h18 = parseInt(data[18], 0);
    this._consumer.consumptionProfiles[index].h19 = parseInt(data[19], 0);
    this._consumer.consumptionProfiles[index].h20 = parseInt(data[20], 0);
    this._consumer.consumptionProfiles[index].h21 = parseInt(data[21], 0);
    this._consumer.consumptionProfiles[index].h22 = parseInt(data[22], 0);
    this._consumer.consumptionProfiles[index].h23 = parseInt(data[23], 0);
  }



  selectedProfileChanged(tab) {
    this.selectedProfile = tab.index;
    this.updatePeriodsInfoFromModelToView();
  }

  getProfileFromDate(period: PVSelfConsumptionTemplateProfilePeriod) {
    return new Date(this._calculation.calculateYear, period.fromMonth - 1, period.fromDay);
  }

  getProfileToDate(period: PVSelfConsumptionTemplateProfilePeriod) {
    return new Date(this._calculation.calculateYear, period.toMonth - 1, period.toDay);
  }


  updatePeriodsInfoFromModelToView() {
    //    this.consumptionTemplateProfilePeriods = this._consumer.consumptionProfiles[this.selectedProfile].periods;
    this.consumptionTemplateProfilePeriods = [];
    this._consumer.consumptionProfiles[this.selectedProfile].periods.forEach(x => {
      const period = new PVSelfConsumptionTemplateProfilePeriod();
      period.fromDay = x.fromDay;
      period.fromMonth = x.fromMonth;
      period.pvSelfConsumptionTemplateProfileId = x.pvSelfCalculationConsumptionTemplateProfileId;
      period.pvSelfConsumptionTemplateProfilePeriodId = x.pvSelfCalculationConsumptionTemplateProfilePeriodId;
      period.toDay = x.toDay;
      period.toMonth = x.toMonth;
      period.weekdays = x.weekdays;
      this.consumptionTemplateProfilePeriods.push(period);
    });

    this.profilePeriodFromDate = [];
    this.profilePeriodToDate = [];
    this.profilePeriodMonday = [];
    this.profilePeriodTuesday = [];
    this.profilePeriodWednesday = [];
    this.profilePeriodThursday = [];
    this.profilePeriodFriday = [];
    this.profilePeriodSaturday = [];
    this.profilePeriodSunday = [];
    this.consumptionTemplateProfilePeriods.forEach(x => {
      this.profilePeriodFromDate.push(this.getProfileFromDate(x));
      this.profilePeriodToDate.push(this.getProfileToDate(x));
      this.profilePeriodMonday.push((x.weekdays & 0b1000000) === 0b1000000);
      this.profilePeriodTuesday.push((x.weekdays & 0b0100000) === 0b0100000);
      this.profilePeriodWednesday.push((x.weekdays & 0b0010000) === 0b0010000);
      this.profilePeriodThursday.push((x.weekdays & 0b0001000) === 0b0001000);
      this.profilePeriodFriday.push((x.weekdays & 0b0000100) === 0b0000100);
      this.profilePeriodSaturday.push((x.weekdays & 0b0000010) === 0b0000010);
      this.profilePeriodSunday.push((x.weekdays & 0b0000001) === 0b0000001);
    });
  }



  // Dada la forma en la que se almacenan los periodos, no se puede usar ngModel para actualizar el modelo desde la vista.
  // Por eso se crea esta función
  updatePeriodsInfoFromViewToModel() {
    // Update From and To data
    let modelPeriods = this._consumer.consumptionProfiles[this.selectedProfile].periods;
    modelPeriods = [];
    this.profilePeriodFromDate.forEach((x, index) => {
      const period = new PVSelfCalculationConsumptionTemplateProfilePeriod();
      period.fromDay = x.getDate();
      period.fromMonth = x.getMonth() + 1;
      period.toDay = this.profilePeriodToDate[index].getDate();
      period.toMonth = this.profilePeriodToDate[index].getMonth() + 1;
      period.weekdays = 0;

      if (this.profilePeriodMonday[index]) { period.weekdays += 0b1000000; }
      if (this.profilePeriodTuesday[index]) { period.weekdays += 0b0100000; }
      if (this.profilePeriodWednesday[index]) { period.weekdays += 0b0010000; }
      if (this.profilePeriodThursday[index]) { period.weekdays += 0b0001000; }
      if (this.profilePeriodFriday[index]) { period.weekdays += 0b0000100; }
      if (this.profilePeriodSaturday[index]) { period.weekdays += 0b0000010; }
      if (this.profilePeriodSunday[index]) { period.weekdays += 0b0000001; }

      modelPeriods.push(period);
    });

    this._consumer.consumptionProfiles[this.selectedProfile].periods = modelPeriods;
  }



  initChartCalendarOptions() {

    const january = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 0);
    const february = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 1);
    const march = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 2);
    const april = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 3);
    const may = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 4);
    const june = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 5);
    const july = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 6);
    const august = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 7);
    const september = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 8);
    const october = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 9);
    const november = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 10);
    const december = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, 11);

    this.chartCalendarOptions = {
      backgroundColor: '#ffffff',
      tooltip: {
        position: 'top',
        formatter: value => {
          const data = `Date: ${value.data[0]} <br>
                        Day: ${value.data[1]} <br>
                        Selected: ${value.data[2]} <br>
                        Weekend: ${value.data[3]} <br>
                        Profile: ${value.data[4]}`;
          return data;

        }
      },
      calendar: [
        {
          cellSize: calendarOptions.cellSize,
          id: 'January',
          left: '5%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.January, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.January),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'February',
          left: '20%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.February, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.February),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'March',
          left: '35%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.March, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.March),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'April',
          left: '50%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.April, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.April),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'May',
          left: '65%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.May, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.May),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'June',
          left: '80%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.June, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.June),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'July',
          top: calendarOptions.secondHalfTop,
          left: '5%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.July, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.July),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'August',
          top: calendarOptions.secondHalfTop,
          left: '20%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.August, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.August),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'September',
          top: calendarOptions.secondHalfTop,
          left: '35%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.September, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.September),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'October',
          top: calendarOptions.secondHalfTop,
          left: '50%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.October, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.October),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'November',
          top: calendarOptions.secondHalfTop,
          left: '65%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.November, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.November),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        },
        {
          cellSize: calendarOptions.cellSize,
          id: 'December',
          top: calendarOptions.secondHalfTop,
          left: '80%',
          orient: 'vertical',
          range: Calendar.getMonth(Month.December, this._calculation.calculateYear),
          splitLine: calendarOptions.splitLine,
          yearLabel: calendarOptions.yearLabel(Month.December),
          monthLabel: calendarOptions.monthLabel,
          dayLabel: calendarOptions.dayLabel,
          itemStyle: calendarOptions.itemStyle
        }
      ],
      series: [
        {
          name: this.translate.instant('COMMON.MONTHS.JANUARY'),
          type: 'custom',
          coordinateSystem: 'calendar',
          calendarIndex: 0,
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: january,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.FEBRUARY'),
          type: 'custom',
          calendarIndex: 1,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: february,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.MARCH'),
          type: 'custom',
          calendarIndex: 2,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: march,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.APRIL'),
          type: 'custom',
          calendarIndex: 3,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: april,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.MAY'),
          type: 'custom',
          calendarIndex: 4,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: may,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.JUNE'),
          type: 'custom',
          calendarIndex: 5,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: june,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.JULY'),
          type: 'custom',
          calendarIndex: 6,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: july,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.AUGUST'),
          type: 'custom',
          calendarIndex: 7,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: august,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.SEPTEMBER'),
          type: 'custom',
          calendarIndex: 8,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: september,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.OCTOBER'),
          type: 'custom',
          calendarIndex: 9,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: october,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.NOVEMBER'),
          type: 'custom',
          calendarIndex: 10,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: november,
          z: 20
        },
        {
          name: this.translate.instant('COMMON.MONTHS.DECEMBER'),
          type: 'custom',
          calendarIndex: 11,
          coordinateSystem: 'calendar',
          renderItem: this.renderItem,
          dimensions: [null, { type: 'ordinal' }],
          data: december,
          z: 20
        }
      ]
    };
  }




  renderItem(params, api) {
    const cellPoint = api.coord(api.value(0));
    const cellWidth = params.coordSys.cellWidth;
    const cellHeight = params.coordSys.cellHeight;
    const isSelected = api.value(2);
    const isWeekend = api.value(3);

    //    const profileName = api.value(4);

    let backgroundColor = null;
    backgroundColor = isWeekend ? '#ff4b44' : 'rgb(7,0,113)';


    const group = {
      type: 'group',
      children: [
        {
          type: 'rect',
          z: 0,
          invisible: !isSelected,
          shape: {
            x: cellPoint[0] - cellWidth / 2,
            y: cellPoint[1] - cellHeight / 2,
            width: cellWidth,
            height: cellHeight
          },
          style: {
            text: null,
            fill: backgroundColor,
            textFont: api.font({ fontSize: 10 }),
            textAlign: 'center'
          }
        },
        {
          z: 100000,
          type: 'text',
          style: {
            x: cellPoint[0],
            y: cellPoint[1] - cellHeight / 2 + 4,
            text: new Date(api.value(0)).getDate(),
            fill: isSelected ? '#ffffff' : 'rgb(0,0,0)',
            textFont: api.font({ fontSize: 10 }),
            textAlign: 'center'
          }
        }]
    };
    return group;
  }



  updateCalendar() {

    const profiles = this._consumer.consumptionProfiles;

    this.cleanCalendar();

    for (let month = 0; month < 12; month++) {
      const monthDays = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, month);
      monthDays.forEach(x => {
        const date = x[5];

        profiles.forEach(profile => {
          const periods = profile.periods;
          periods.forEach(periodo => {
            if (date.getMonth() >= periodo.fromMonth - 1 && date.getMonth() <= periodo.toMonth - 1) {

              if (periodo.fromMonth === periodo.toMonth && date.getDate() >= periodo.fromDay && date.getDate() <= periodo.toDay) {
                if (!x[2]) { x[2] = this.verifyWeekday(date, periodo.weekdays); }
              }

              if (periodo.fromMonth !== periodo.toMonth) {
                if (month === periodo.fromMonth - 1 && date.getDate() >= periodo.fromDay) {
                  if (!x[2]) { x[2] = this.verifyWeekday(date, periodo.weekdays); }
                }

                if (month === periodo.toMonth - 1 && date.getDate() <= periodo.toDay) {
                  if (!x[2]) { x[2] = this.verifyWeekday(date, periodo.weekdays); }
                }

                if (month > periodo.fromMonth - 1 && month < periodo.toMonth - 1) {
                  if (!x[2]) { x[2] = this.verifyWeekday(date, periodo.weekdays); }
                }
              }
            }
          });
        });
      });

      this.chartCalendarOptions.series[month].data = monthDays;
    }

    this.chartCalendarOptions = { ...this.chartCalendarOptions, ...this.chartCalendarOptions };
  }

  verifyWeekday(date, profileWeekDays) {
    let weekday = date.getDay() - 1;
    if (weekday === -1) { weekday = 6; }
    weekday = Math.pow(2, (6 - weekday)); // Notación binaria del día de la semana

    return (weekday & profileWeekDays) === weekday;

  }


  cleanCalendar() {
    for (let m = 0; m < 12; m++) {
      this.chartCalendarOptions.series[m].data = Calendar.getAllDaysOfMonth(this._calculation.calculateYear, m);
    }
  }

}


