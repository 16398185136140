export class PVSelfConsumptionTemplate {
    pvSelfConsumptionTemplateId: number;
    description: string;
    usages: Array<PVSelfConsumptionTemplateUsage>;
}


export class PVSelfConsumptionTemplateUsage {
    pvSelfConsumptionTemplateUsageId: number;
    description: string;
    pvSelfConsumptionTemplateId: number;
    profiles: Array<PVSelfConsumptionTemplateProfile>;
}


export class PVSelfConsumptionTemplateProfile {
    pvSelfConsumptionTemplateProfileId: number;
    description: string;
    color: string;
    h0: number;
    h1: number;
    h2: number;
    h3: number;
    h4: number;
    h5: number;
    h6: number;
    h7: number;
    h8: number;
    h9: number;
    h10: number;
    h11: number;
    h12: number;
    h13: number;
    h14: number;
    h15: number;
    h16: number;
    h17: number;
    h18: number;
    h19: number;
    h20: number;
    h21: number;
    h22: number;
    h23: number;
    pvSelfConsumptionTemplateUsageId: number;
    periods: Array<PVSelfConsumptionTemplateProfilePeriod>;
    order: number;

    constructor() {
        this.h0 = this.h1 = this.h2 = this.h3 = this.h4 = this.h5 = this.h6 = this.h7 = this.h8 = this.h9 = this.h10 = 0;
        this.h11 = this.h12 = this.h13 = this.h14 = this.h15 = this.h16 = this.h17 = this.h18 = this.h19 = this.h20 = 0;
        this.h21 = this.h22 = this.h23 = 0;
        this.color = '#fe9462';
        this.periods = [];
    }
}



export class PVSelfConsumptionTemplateProfilePeriod {
    pvSelfConsumptionTemplateProfilePeriodId: number;
    fromDay: number;
    fromMonth: number;
    toDay: number;
    toMonth: number;
    weekdays: number;
    pvSelfConsumptionTemplateProfileId: number;
}

