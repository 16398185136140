export class ActiveHourlyData {
    date?: string;
    hour: string;
    period: number;
    consumption?: number;
    networkConsumption?: number;
    selfConsumption?: number;
    excedentary?: number;
    generated?: number;
}

export class ActiveGroupedData {
    date: string;
    hour?: string;
    period?: number;
    consumption: number;
    networkConsumption: number;
    selfConsumption: number;
    excedentary: number;
    generated: number;
    consumptionByPeriod?: number [];
    networkConsumptionByPeriod?: number [];
    selfConsumptionByPeriod?: number [];
    excedentaryByPeriod?: number [];
    generatedByPeriod?: number [];
}

export class TableData {
    hourlyData: ActiveHourlyData [];
    groupedData: ActiveGroupedData [];
    chartType: number;
    totalConsumption: number;
    totalNetworkConsumption: number;
    totalSelfConsumption: number;
    totalExcedentary: number;
    totalGenerated: number;
    totalConsumptionByPeriod: number [];
    totalNetworkConsumptionByPeriod: number [];
    totalSelfConsumptionByPeriod: number [];
    totalExcedentaryByPeriod: number [];
    totalGeneratedByPeriod: number [];
}











