import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { IInstallationInverter } from 'projects/pplus/src/lib/interfaces/interfaces';
import { FormGroup, FormControl } from '@angular/forms';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { InstallationInvertersService } from '../installation-inverters.service';
import { InstallationInvertersDetailsService } from './installation-inverters-details.service';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { AdminService } from '../../admin.service';
import { NbDialogService } from '@nebular/theme';
import { InstallationService } from '../../installations/installations.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { DATE_FORMAT } from '../../admin.model';

@Component({
  selector: 'pplus-installation-inverters-details',
  templateUrl: './installation-inverters-details.component.html',
  styleUrls: ['./installation-inverters-details.component.scss']
})
export class InstallationInvertersDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  data: IInstallationInverter;

  formGroup: FormGroup;
  formData: DynamicForm[];

  constructor(
    private installationInvertersService: InstallationInvertersService,
    private installationInvertersDetailsService: InstallationInvertersDetailsService,
    private rowDataService: RowDataService,
    private adminService: AdminService,
    private nbDialogService: NbDialogService,
    private installationService: InstallationService,
  ) {
    this.formData = this.installationInvertersDetailsService.getDynamicForm();
  }

  ngOnInit() {
    forkJoin([
      this.installationService.getList(),
    ]).subscribe(([installations]) => {
      this.formData.map(o => {
        o.fields.forEach(f => {
          if (f.name === 'installationId') {
            const opts = [];
            installations.map(i => opts.push({
              value: i.installationId,
              label: i.name,
            }));
            f.options = opts;
          }
        });
      });
      this.generateForm(this.formData);
    });

    this.rowDataService.currentRowData.pipe(map(o => {
      this.formData.forEach(data => {
        const fields = data.fields;
        fields.map(field => field.value = o[field.name]);
      });
      return o;
    })).subscribe(data => {
      this.data = <IInstallationInverter>data;
      this.generateForm(this.formData);
    });
  }

  private generateForm(data: DynamicForm[]) {
    const fieldsCtrls = {};
    data.forEach(el => {
      const fields = el.fields;
      fields.forEach(f => {
        fieldsCtrls[f.name] = new FormControl(f.value == null ? '' : f.value, f.validators);
      });
    });
    this.formGroup = new FormGroup(fieldsCtrls);
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IInstallationInverter>this.formGroup.value;
      data.activated = Boolean(data.activated);
      data.dataLogger = Boolean(data.dataLogger);
      this.installationInvertersService.create(data).subscribe(newInstallationInverter => {
        if (newInstallationInverter != null) {
          newInstallationInverter.creationDate = moment(newInstallationInverter.creationDate).format(DATE_FORMAT);
          newInstallationInverter.deletionDate = moment(newInstallationInverter.deletionDate).format(DATE_FORMAT);
          newInstallationInverter.readingStartDate = moment(newInstallationInverter.readingStartDate).format(DATE_FORMAT);
          newInstallationInverter.lastReadDate = moment(newInstallationInverter.lastReadDate).format(DATE_FORMAT);
          newInstallationInverter.lastEventDate = moment(newInstallationInverter.lastEventDate).format(DATE_FORMAT);
          this.formGroup.patchValue(Object.assign(this.data, newInstallationInverter));
          this.updateDelete.emit(true);
          this.adminService.showToast('success',
              'Se ha creado el nuevo inversor de instalación: ' + data.name,
              'Elemento creado');
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al crear el inversor de instalación: ' + data.name,
              'Error');
        }
      });
    }
  }

  update() {
    const installationInverterId = this.data.installationInverterId;
    if (installationInverterId == null) {
      this.onSubmit();
    } else if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IInstallationInverter>this.formGroup.value;
      data.activated = Boolean(data.activated);
      data.dataLogger = Boolean(data.dataLogger);
      this.installationInvertersService.update(installationInverterId, data).subscribe(success => {
        if (success) {
          this.updateDelete.emit();
          this.adminService.showToast('success',
              'Se ha actualizado el inversor de instalación: ' + data.name,
              'Elemento actualizado');
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al actualizar el inversor de instalación: ' + data.name,
              'Error');
        }
      });
    }
  }

  delete() {
    if (this.data.installationInverterId == null) {
      this.adminService.showToast('info', 'Seleccione un inversor de instalación', 'Info');
    } else {
      this.installationInvertersService.delete(this.data.installationInverterId).subscribe(success => {
        if (success) {
          this.updateDelete.emit();
          this.adminService.showToast('success',
              'Se ha borrado el inversor de instalación: ' + this.data.name,
              'Elemento eliminado');
          this.clearData();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al borrar el inversor de instalación: ' + this.data.name,
              'Error');
        }
      });
    }
  }

  close() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  copy() {
    if (this.data.installationInverterId == null) {
      this.adminService.showToast('info', 'Seleccione un inversor de instalación para copiar la información', 'Info');
    } else {
      this.formGroup.patchValue(Object.assign(this.data, <IInstallationInverter>{
        installationInverterId: null,
        code: '',
        lastReadDate: '',
        lastEventDate: '',
        peakPower: '',
        serialNumber: '',
        deletionDate: '',
        ip: '',
        port: '',
      }));
    }
  }

  clearData() {
    this.data = <IInstallationInverter>{};
    this.formGroup.reset();
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.installationInverterId == null) {
      this.adminService.showToast('info', 'No hay ningún inversor de instalación seleccionado', 'Info');
    } else {
      this.nbDialogService.open(dialog, { context: '¿Desea eliminar el inversor de instalación?', hasScroll: false });
    }
  }

}
