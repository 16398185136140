import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Manufacturer } from '../models/manufacturer.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Entity } from '../models/entity.model';

@Injectable({
  providedIn: 'root'
})
export class ManufacturerService {

  constructor(private httpClient: HttpClient) {
  }

  getByElementType(ElementTypeId: number, EntityId: number = 0): Observable<Manufacturer[]> {
    return this.httpClient.get<Manufacturer[]>(environment.API_URL + '/api/admin/manufacturers/getByElementType/' + ElementTypeId + '?EntityId=' + EntityId).pipe(
      map(data => data));
  }

}
