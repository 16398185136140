export const SERVICESRECORD_HEADERS = [
  {
    headerName: 'ID',
    rowDrag: true,
    minWidth: 100,
    field: 'Id',
    filter: 'agTextColumnFilter',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
    headerCheckboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
    resizable: true,
    status: false,
  },


  {
    headerName: 'Id isntalación',
    minWidth: 100,
    field: 'Id_Intalation',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Id tipo de servicio',
    rowDrag: true,
    minWidth: 100,
    field: 'Id_serviceType',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Fecha de inicio',
    rowDrag: true,
    minWidth: 100,
    field: 'CreationDate',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Fecha de finalización',
    rowDrag: true,
    minWidth: 100,
    field: 'FinishDate',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Cadencia de Facturación',
    rowDrag: true,
    minWidth: 100,
    field: 'cadenceBilling',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Importe',
    field: 'amount',
    filter: 'agTextColumnFilter',
    minWidth: 100,
    resizable: true,
    status: true,
  },
  {
    headerName: 'Particularidades del Servicio',
    rowDrag: true,
    minWidth: 100,
    field: 'serviceParticularities',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
];
