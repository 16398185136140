import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Installation, InstallationResponse } from './installations.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { INSTALLATIONS_COL_DEFS } from './installations.model';

@Injectable({
  providedIn: 'root'
})
export class InstallationService {
  private urlInstalltions = environment.API_URL + '/api/installation';

  constructor(private http: HttpClient) { }

  getAllInstallationsInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<InstallationResponse>{
    return this.http.post(`${this.urlInstalltions}/get-installations`, { startRow, endRow, sortModel, filterModel })
      .pipe(
        map( (installations: InstallationResponse) => {
          return installations;
        })
      );
  }

  getAllInstallations(): Observable<Installation []> {
    return this.http.get(this.urlInstalltions).pipe(
      map(
        (installations: Installation []) => {
          return installations;
        }
      )
    );
  }

  getList(): Observable<Installation[]> {
    return this.http.get(this.urlInstalltions + '/list').pipe(
      map(response => <Installation[]>response),
      catchError(() => of([]))
    );
  }

  getInstallation(id: number): Observable<Installation> {
    return this.http.get(`${this.urlInstalltions}/${id}`).pipe(
      map(
        (installation: Installation) => {
          return installation;
        }
      )
    );
  }

  createInstallation(data: Installation): Observable<Installation> {
    return this.http.post(this.urlInstalltions, data, {observe: 'response'}).pipe(
      map(
        (response: HttpResponse<Installation>) => {
          if (response.status === 200) {
            return response.body;
          }
          return null;
        }
      )
    );
  }

  updateInstallation(id: number, data: Installation): Observable<boolean> {
    return this.http.put(this.urlInstalltions + '/' + id, data, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  deleteInstallation(id: number): Observable<boolean> {
    return this.http.delete(`${this.urlInstalltions}/${id}`, {observe: 'response'}).pipe(
      map(
        (response: HttpResponse<any>) => {
          if (response.status === 200 ) { return response.body; }
          return null;
        }
      ),
    );
  }

  getAppsGeneralFilter(
    value: string, startRow: number, endRow: number, sortModel: any, filterModel: any
  ): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of INSTALLATIONS_COL_DEFS) { columnList.push(colDef.field); }
    return this.http.post(`${this.urlInstalltions}/get-installations`, {
      startRow, endRow, sortModel, filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel,
      },
      columnList,
    });
  }

}
