import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IEntityPlant } from 'projects/pplus/src/lib/interfaces/interfaces';
import { PLANT_COL_DEFS } from './plant.model';

@Injectable({
  providedIn: 'root'
})
export class PlantService {
  private readonly apiUrl = environment.API_URL + '/api/plant';
  private readonly apiScrollInfinite = this.apiUrl + '/get-plants';
  private readonly apiAssignGroups = this.apiUrl + '/plantgroup/assigngroupsplants';
  private readonly apiAssignInstallations = this.apiUrl + '/assignInstall';
  private readonly apiAssignMeteoSensors = this.apiUrl + '/assignSensorMeteo';
  private readonly apiAssignMeter = this.apiUrl + '/assignMeter';
  private readonly apiAssignEntity = this.apiUrl + '/assignEntity';

  constructor(private http: HttpClient) {}

  getAllPlantsInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const body = { startRow, endRow, sortModel, filterModel };
    return this.http.post(this.apiScrollInfinite, body).pipe(map(response => {
      return <any>response;
    }));
  }

  getPlantsGeneralFilter(
    value: string, startRow: number, endRow: number, sortModel: any, filterModel: any
  ): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of PLANT_COL_DEFS) columnList.push(colDef.field);
    return this.http.post(this.apiScrollInfinite, {
      startRow, endRow, sortModel, filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel,
      },
      columnList,
    });
  }

  getAllPlants(): Observable<IEntityPlant[]> {
    return this.http.get(this.apiUrl).pipe(
      map(response => <IEntityPlant[]>response),
      catchError(() => of([]))
    );
  }

  getPlantList(): Observable<IEntityPlant[]> {
    return this.http.get(this.apiUrl + '/list').pipe(
      map(response => <IEntityPlant[]>response),
      catchError(() => of([]))
    );
  }

  getPlant(id: number): Observable<IEntityPlant> {
    return this.http.get(`${this.apiUrl}/${id}`).pipe(
      map(response => <IEntityPlant>response),
      catchError(() => of(null))
    );
  }

  createPlant(data: IEntityPlant): Observable<IEntityPlant> {
    return this.http.post(this.apiUrl, data, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? <IEntityPlant>response.body : null),
      catchError(() => of(null))
    );
  }

  updatePlant(id: number, data: IEntityPlant): Observable<boolean> {
    return this.http.put(`${this.apiUrl}/${id}`, data, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  deletePlant(id: number): Observable<boolean> {
    return this.http.delete(`${this.apiUrl}/${id}`, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  assignOwnerEntity(plantId: number, entityId: number): Observable<boolean> {
    if (entityId) {
      return this.http.post(this.apiAssignEntity, {plantId, entityId}, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else return of(true);
  }

  assignPlantGroups(plantId: number, plantGroupIds: number[]): Observable<boolean> {
    if (plantGroupIds && plantGroupIds.length >= 0) {
      return this.http.post(this.apiAssignGroups, {plantId, plantGroupIds}, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else return of(true);
  }

  assignPlantInstallations(plantId: number, installationIds: number[]): Observable<boolean> {
    if (installationIds && installationIds.length >= 0) {
      return this.http.post(this.apiAssignInstallations, {plantId, installationIds}, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else return of(true);
  }

  assignMeteoSensors(plantId: number, meteoSensorId: number[]): Observable<boolean> {
    if (meteoSensorId && meteoSensorId.length >= 0) {
      return this.http.post(this.apiAssignMeteoSensors, {plantId, meteoSensorId}, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else return of(true);
  }

  assignMeters(plantId: number, meterPlantInstallationId: number[]): Observable<boolean> {
    if (meterPlantInstallationId && meterPlantInstallationId.length >= 0) {
      return this.http.post(this.apiAssignMeter, {plantId, meterPlantInstallationId}, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else return of(true);
  }

  getCountries(): Observable<any[]> {
    return this.http.get(environment.API_URL + '/api/country').pipe(
      map(response => <any[]>response),
      catchError(() => of([]))
    );
  }

  getInstallationsList(): Observable<any[]> {
    return this.http.get(environment.API_URL + '/api/installation/list').pipe(
      map(response => <any[]>response),
      catchError(() => of([]))
    );
  }

  getMetersList(): Observable<any[]> {
    return this.http.get(environment.API_URL + '/api/meterinstallation/list').pipe(
      map(response => <any[]>response),
      catchError(() => of([]))
    );
  }

  getMeteoSensorsList(): Observable<any[]> {
    return this.http.get(this.apiUrl + '/meteosensor/list').pipe(
      map(response => <any[]>response),
      catchError(() => of([]))
    );
  }

}
