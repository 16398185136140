import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Operation } from '../../models/permissions/operation.model';


@Injectable({
  providedIn: 'root'
})
export class OperationsService {

  constructor(private httpClient: HttpClient) {
  }

  getList(): Observable<Operation[]> {
    return this.httpClient.get<Operation[]>(environment.API_URL + '/api/permissions/operations/getList').pipe(
      map(data => data));
  }

  searchByDescription(description: string): Observable<Operation[]> {
    return this.httpClient.get<Operation[]>(environment.API_URL + '/api/permissions/operations/searchByDescription?description=' + description).pipe(
      map(data => data));
  }
}
