import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../../../../projects/pplus/src/lib/models/AllModels';
import { NbAuthToken, NbAuthService } from '@nebular/auth';

@Injectable()
export class DataService {
  pplusUser: any;
  user: User;
  constructor(private authService: NbAuthService) { }

  getUserData(): Observable<User> {
    return this.authService.onTokenChange()
      .pipe(map((token: NbAuthToken) => {
        if (token.getOwnerStrategyName() === 'email') {
          const payload = token.getPayload();
          this.user = {
            provider : 'email',
            sessionId: payload.sessionId,
            fullName: payload.given_name,
            email: payload.unique_name,
            token: token.getValue(),
            userId: payload.sub
          };
          localStorage.setItem('pplus', JSON.stringify(this.user));
          return this.user;
        } else {
          this.pplusUser = localStorage.getItem('pplus');
          return JSON.parse(this.pplusUser);
        }
      }));
  }

}
