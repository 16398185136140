import { Component, OnInit } from '@angular/core';
import { Application } from '../../../models/application.model';
import { ApplicationsService } from '../../../services/applications.service';
import { FormControl } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';

@Component({
  selector: 'pplus-autocomplete-applications',
  templateUrl: './autocomplete-applications.component.html',
  styleUrls: ['./autocomplete-applications.component.scss']
})

export class AutocompleteApplicationsComponent implements OnInit {
  applications: any;
  isLoading = false;
  formControl = new FormControl();
  selectedValue: Application;


  constructor(private applicationsService: ApplicationsService) { }

  ngOnInit() {
    this.selectedValue = null;
    this.initApplicationsSearch();
  }

  initApplicationsSearch() {
    this.formControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.applications = [];
          this.isLoading = true;
        }),
        switchMap(value => this.applicationsService.searchByDescription(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(data => {
        this.applications = data;
      });
  }

  displayFn(value?: any) {
    return value ? value.description + ' [' + value.name + ']' : '-';
  }


  optionSelected(value) {
    this.selectedValue = value;
  }
}
