import { Component, OnDestroy, OnInit} from '@angular/core';
import { SelfViewService } from '../services/selfview-general.service';
import { Subscription} from 'rxjs';
import { ZeroInjectionService } from './zero-injection.service';
import { PPlusElectricPhase } from 'projects/pplus/src/lib/models/pplus.enums';
import { ZeroInjectionInstantResultsParams, ZeroInjectionEffectiveResultsParams } from './zero-injection.models';

@Component({
  selector: 'app-selfview-zero-injection',
  templateUrl: './selfview-zero-injection.component.html',
  styleUrls: ['./selfview-zero-injection.component.scss'],
  providers: [SelfViewService]
})
export class SelfviewZeroInjectionComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription = new Subscription();

  idInstallation: number;
  phase: PPlusElectricPhase = PPlusElectricPhase.R;
  dateRange;

  loaderId: string;
  disabled: boolean;

  constructor(private zeroInjectionService: ZeroInjectionService) {

    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);

    const today = new Date();
    this.dateRange = {
      startDate: today,
      endDate: today
    };

  }

  ngOnInit() {
  }

  setIdInstallation(id: number) {
    if (this.idInstallation !== id) {
      this.idInstallation = id;
      this.getInstantResult();
      this.getEfficaciesResult();
    }
  }


  onChangePhase(phase: PPlusElectricPhase) {
    this.phase = phase;
    this.getInstantResult();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getInstantResult() {
    const data: ZeroInjectionInstantResultsParams = {
      idInstallation : this.idInstallation,
      phase: this.phase
    };
    this.zeroInjectionService.getInstantResults(data);
  }

  getEfficaciesResult() {
    const data: ZeroInjectionEffectiveResultsParams = {
      idInstallation : this.idInstallation,
    };
    this.zeroInjectionService.getEffectiveResults(data);
  }
}


