import { NgModule } from "@angular/core";
import { PVSelfReportComponent } from "./pvself-report.component";
import { MatInputModule, MatIconModule, MatButtonModule } from "@angular/material";
import { FormsModule } from "@angular/forms";




@NgModule({
    declarations: [PVSelfReportComponent],
    imports: [
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        FormsModule
    ],
    exports: [
        PVSelfReportComponent,
    ]
})
export class PVSelfReportModule { }
