import { NgModule } from '@angular/core';
import { DomainDetailsComponent } from './domain-details/domain-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbAccordionModule, NbCheckboxModule, NbCardModule } from '@nebular/theme';
import { CommonModule } from '@angular/common';
import { PPlusDynamicFormModule } from 'projects/pplus/src/public_api';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    DomainDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NbAccordionModule,
    NbCheckboxModule,
    PPlusDynamicFormModule,
    NbCardModule,
    TranslateModule
  ],
  exports: [
    DomainDetailsComponent
  ]
})
export class DomainsComponentsModule { }
