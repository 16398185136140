import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigComponent } from './config.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { NbCardModule, NbPopoverModule } from '@nebular/theme';
import { AgGridModule } from 'ag-grid-angular';
import { MatSlideToggleModule, MatCheckboxModule, MatInputModule, MatButtonModule, MatRadioModule, MatChipsModule, MatSnackBarModule, MatStepperModule, MatTableModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { PVSelfElectricConfigComponent } from './pvself-electric-config/pvself-electric-config.component';
import { PVSelfLossesComponent } from './pvself-losses/pvself-losses.component';

@NgModule({
  declarations: [ConfigComponent, PVSelfElectricConfigComponent, PVSelfLossesComponent],
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    NbCardModule,
    NbPopoverModule,
    AgGridModule.withComponents([]),
    MatSlideToggleModule,
    MatExpansionModule,
    NgxUiLoaderModule,
    MatCheckboxModule,
    MatRadioModule,
    MatChipsModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTableModule
  ], exports: [
    ConfigComponent
  ]
})
export class ConfigModule { }
