import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'pplus-agCheckbox',
  templateUrl: './ag-checkbox.component.html',
  styleUrls: ['./ag-checkbox.component.scss']
})
export class AgCheckboxComponent implements ICellRendererAngularComp{

  value;

  agInit(params: any): void{
    this.value = params.value;
  }

  refresh() : boolean{
    return false;
  }

  constructor(){

  }
  
}
