import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConsumptionRate, ConsumptionRateDefaultPrice } from '../models/consumptionRate.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MarketType } from 'src/app/modules/pvself/models/PvSelfCalculation.model';

@Injectable({
  providedIn: 'root'
})
export class ConsumptionRateService {

  constructor(private httpClient: HttpClient) {
  }


  getList(): Observable<ConsumptionRate[]> {
    return this.httpClient.get<ConsumptionRate[]>(environment.API_URL + '/api/Admin/rate/getList').pipe(
      map(data => data));
  }

  getByMarketType(marketType: MarketType): Observable<ConsumptionRate[]> {
    return this.httpClient.get<ConsumptionRate[]>(environment.API_URL + '/api/Admin/rate/getByMarketType/' + marketType).pipe(
      map(data => data));
  }

  getDefaultPrices(rateId: number): Observable<ConsumptionRateDefaultPrice[]> {
    return this.httpClient.get<ConsumptionRateDefaultPrice[]>(environment.API_URL + '/api/Admin/rate/getDefaultPrices/' + rateId).pipe(
      map(data => data));
  }


}
