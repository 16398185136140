/**
 * The main component that renders single TabComponent
 * instances.
 */

import {
  Component,
  ContentChildren,
  QueryList,
  ViewChild,
  ComponentFactoryResolver,
  ViewContainerRef,
  TemplateRef,
  ElementRef,
  Input,
  AfterViewChecked,
  ChangeDetectorRef,
  OnInit
} from '@angular/core';

import { TabComponent } from './tab.component';
import { DynamicTabsDirective } from './dynamic-tabs.directive';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subscription, fromEvent, BehaviorSubject } from 'rxjs';
import { TemplatePortal } from '@angular/cdk/portal';
import { filter, take } from 'rxjs/operators';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { NbDialogService } from '@nebular/theme';
import { PVSelfCalculation } from 'src/app/modules/pvself/models/PvSelfCalculation.model';
import { TabService } from "./tab.service";
@Component({
  selector: 'pplus-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit, AfterViewChecked {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @ViewChild(DynamicTabsDirective, { static: true }) dynamicTabPlaceholder: DynamicTabsDirective;
  @ViewChild('tabMenu', { static: true }) tabMenu: TemplateRef<any>;
  @ViewChild('container', { static: true }) container: ElementRef;
  @Input() tabType: string;
  @Input() showButtons: boolean;

  _calculation: PVSelfCalculation;
  dynamicTabs: TabComponent[] = [];
  _showButtons: boolean;

  overlayRef: OverlayRef | null;
  sub: Subscription;
  tabContextMenu: TabComponent;


  /*
    Alternative approach of using an anchor directive
    would be to simply get hold of a template variable
    as follows
  */
  // @ViewChild('container', {read: ViewContainerRef}) dynamicTabPlaceholder;

  constructor(private _componentFactoryResolver: ComponentFactoryResolver,
    public overlay: Overlay,
    public elementRef: ElementRef,
    public viewContainerRef: ViewContainerRef,
    private dialogService: NbDialogService,
    private tabService: TabService,
    private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.showButtons !== undefined) this._showButtons = this.showButtons;
    else this._showButtons = true;
  }

  ngAfterViewChecked(): void {
    if (this.tabs.length > 0) {
      const activeTabs = this.tabs.filter(tab => tab.active);
      if (activeTabs.length === 0) {
        this.selectTab(this.tabs.first);
      }
    }
  }

  // contentChildren are set
  // ngAfterContentInit() {
  //   // get all active tabs
  //   if (this.tabs.length > 0) {
  //     const activeTabs = this.tabs.filter(tab => tab.active);

  //     // if there is no active tab set, activate the first
  //     if (activeTabs.length === 0) {
  //       this.selectTab(this.tabs.first);
  //     }
  //   }
  // }

  openTab(title: string, template, data, isCloseable = false) {
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(TabComponent);
    const viewContainerRef = this.dynamicTabPlaceholder.viewContainer;

    const componentRef = viewContainerRef.createComponent(componentFactory);

    const instance: TabComponent = componentRef.instance as TabComponent;
    instance.title = title;
    instance.template = template;
    instance.dataContext = data;
    instance.isCloseable = isCloseable;
    instance.tabType = this.tabType;

    this.dynamicTabs.push(componentRef.instance as TabComponent);
    this.selectTab(this.dynamicTabs[this.dynamicTabs.length - 1]);
    componentRef.changeDetectorRef.detectChanges();
    this.changeDetector.detectChanges();
  }

  selectTab(tab: TabComponent) {
    this.tabService.changeTab(tab);
    this.dynamicTabs.forEach(x => (x.active = false));
    tab.active = true;
  }

  closeTab(tab: TabComponent) {
    this.closeContextMenu();
    if (tab === undefined && this.tabContextMenu != null) {
      tab = this.tabContextMenu;
    }
    for (let i = 0; i < this.dynamicTabs.length; i++) {
      if (this.dynamicTabs[i] === tab) {
        this.dynamicTabs.splice(i, 1);

        let viewContainerRef = this.dynamicTabPlaceholder.viewContainer;
        viewContainerRef.remove(i);

        this.selectTab(this.dynamicTabs[0]);

        break;
      }
    }
    this.tabContextMenu == null;
  }

  closeActiveTab() {
    const activeTabs = this.dynamicTabs.filter(tab => tab.active);
    if (activeTabs.length > 0) {
      this.closeTab(activeTabs[0]);
    }
  }

  drop(event: CdkDragDrop<TabComponent[]>) {
    moveItemInArray(this.dynamicTabs, event.previousIndex, event.currentIndex);
  }

  editMode(tab: TabComponent, event) {
    tab.editable = true;
  }

  onBlurMethod(value) {
    this.dynamicTabs.forEach(tab => {
      tab.editable = false;
    });
  }

  open({ x, y }: MouseEvent, tab: TabComponent) {
    this.tabContextMenu = tab;
    this.closeContextMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.tabMenu, this.viewContainerRef, {
      $implicit: tab
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContextMenu());
  }

  closeContextMenu() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  cloneTab(tab: TabComponent) {
    this.closeContextMenu();
    this.openTab(this.tabContextMenu.title, this.tabContextMenu.template, {}, true);
  }

  openHistory(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

  closeTabSelected() {
    this.dynamicTabs.forEach(tab => {
      if (tab.active) {
        this.closeTab(tab);
      }
    });
  }

  cloneTabSelected() {
    this.dynamicTabs.forEach(tab => {
      if (tab.active) {
        this.openTab(tab.title, tab.template, tab.dataContext, true);
      }
    });
  }

  addNewTab() {
    this.dynamicTabs.forEach(tab => {
      if (tab.active) {
        var dataContext = {
          tabContentOrigin: "new"
        }
        this.openTab(tab.title, tab.template, dataContext, true);
      }
    });
  }


  getOpenedTabs() {
    return this.dynamicTabs;
  }

  closeAllTabs(): void {
    while (this.dynamicTabs.length > 0) {
      this.closeTab(this.dynamicTabs.pop());
    }
  }

}
