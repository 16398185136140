import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { DaterangepickerDirective, DaterangepickerComponent } from 'ngx-daterangepicker-material';
import { TranslateService } from '@ngx-translate/core';
export interface DateMoment {
  startDate: moment.Moment;
  endDate: moment.Moment;
}
@Component({
  selector: 'pplus-dateRange',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss']
})
export class DateRangeComponent implements OnInit {
  @Output() dateRange = new EventEmitter<any>();
  @Input() disabled: boolean;
  @Input() defaultRange: DateMoment;
  @ViewChild(DaterangepickerDirective, {static : true}) pickerDirective: DaterangepickerDirective;
  dateSelected: DateMoment;
  picker: DaterangepickerComponent;

  constructor(private translate: TranslateService) {

  }

  setDate(type: number) {
    switch (type) {
      case 1:
        this.dateSelected = {
          startDate: moment().startOf('day'),
          endDate: moment().endOf('day'),
        };
        break;
     case 2:
        this.dateSelected = {
          startDate: moment().startOf('day').subtract(1, 'days'),
          endDate: moment().endOf('day').subtract(1, 'days'),
        };
        break;
      case 3:
        this.dateSelected = {
          startDate: moment().startOf('day').subtract(6, 'days'),
          endDate: moment().endOf('day'),
        };
        break;
      case 4:
        this.dateSelected = {
          startDate: moment().startOf('month').startOf('day'),
          endDate: moment().startOf('day')
        };
        break;
    }
    const startDate = this.dateSelected.startDate.toDate();
    const endDate = this.dateSelected.endDate.endOf('day').toDate();
    this.dateRange.emit({startDate: startDate, endDate: endDate});
  }

  ngOnInit() {
    if (this.defaultRange) {
      this.dateSelected = this.defaultRange;
    } else {
      this.dateSelected = {
        startDate: moment().startOf('day').subtract(6, 'months'),
        endDate: moment().endOf('day'),
      };
    }

    this.picker = this.pickerDirective.picker;
  }

  rangeChange(date: DateMoment) {
    if (date.endDate && date.startDate) {
      const startDate = date.startDate.toDate();
      const endDate = date.endDate.endOf('day').toDate();
      this.dateRange.emit({startDate: startDate, endDate: endDate});
    }
  }

  open($event) {
    this.pickerDirective.open($event);
  }

}
