import { Injectable } from '@angular/core';
import { DynamicForm, Fields } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class InstallationInvertersDetailsService {

  private readonly generalFields: Fields[] = [
    {
      name: 'installationInverterId',
      type: 'text',
      placeholder: 'ID',
      value: '',
      disabled: true,
    },
    {
      name: 'name',
      type: 'text',
      placeholder: 'NOMBRE',
      value: '',
      validators: [
        Validators.required,
      ],
    },
    {
      name: 'code',
      type: 'text',
      placeholder: 'CÓDIGO',
      value: '',
      validators: [
        Validators.required,
      ],
    },
    {
      name: 'installationId',
      type: 'select',
      label: 'INSTALACIÓN ASOCIADA',
      value: null,
      options: [],
    },
    {
      name: 'lastReadDate',
      type: 'text',
      placeholder: 'FECHA ÚLTIMA LECTURA',
      value: '',
    },
    {
      name: 'lastEventDate',
      type: 'text',
      placeholder: 'FECHA ÚLTIMO EVENTO',
      value: '',
    },
    {
      name: 'activated',
      type: 'checkbox',
      placeholder: 'ACTIVO',
      value: false,
    },
  ];

  private readonly inverterFields: Fields[] = [
    {
      name: 'nominalPower',
      type: 'text',
      placeholder: 'POTENCIA NOMINAL (Wp)',
      value: '',
    },
    {
      name: 'peakPower',
      type: 'text',
      placeholder: 'POTENCIA PICO (Wp)',
      value: '',
    },
    {
      name: 'serialNumber',
      type: 'text',
      placeholder: 'NÚMERO DE SERIE',
      value: '',
    },
    {
      name: 'creationDate',
      type: 'text',
      placeholder: 'FECHA DE ALTA',
      value: '',
      disabled: true,
    },
    {
      name: 'deletionDate',
      type: 'text',
      placeholder: 'FECHA DE BAJA',
      value: '',
      disabled: true,
    },
    {
      name: 'note',
      type: 'text',
      placeholder: 'NOTA',
      value: '',
    },
    {
      name: 'readingStartDate',
      type: 'text',
      placeholder: 'FECHA INICIO DESCARGA DE LECTURAS',
      value: '',
      disabled: true,
    },
  ];

  private readonly configFields: Fields[] = [
    {
      name: 'deviationProduction',
      type: 'text',
      placeholder: '% PRODUCCIÓN',
      value: '',
    },
    {
      name: 'deviationMeter',
      type: 'text',
      placeholder: '% RESPECTO CONTADOR',
      value: '',
    },
    {
      name: 'instantTimeOut',
      type: 'text',
      placeholder: 'TIME OUT INVERSORES INSTANTÁNEOS',
      value: '',
    },
    {
      name: 'historicalTimeOut',
      type: 'text',
      placeholder: 'TIME OUT INVERSORES HISTÓRICOS',
      value: '',
    },
    {
      name: 'dataLogger',
      type: 'checkbox',
      placeholder: 'DATA LOGGER',
      value: false,
    },
    {
      name: 'ip',
      type: 'text',
      placeholder: 'DIRECCIÓN IP',
      value: '',
    },
    {
      name: 'port',
      type: 'text',
      placeholder: 'PUERTO TCP',
      value: '',
    },
  ];

  constructor() {}

  getDynamicForm(): DynamicForm[] {
    return [
      {
        title: 'DATOS GENERALES',
        fields: this.generalFields,
        open: true,
      },
      {
        title: 'DATOS INVERSOR',
        fields: this.inverterFields,
      },
      {
        title: 'DATOS DE CONFIGURACIÓN',
        fields: this.configFields,
      },
    ];
  }

}
