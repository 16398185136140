import { Component, OnInit, Input } from '@angular/core';
import { MeterService } from '../../meter.service';
import { PPlusChartType } from '../../../../../../../../../projects/pplus/src/lib/models/pplus.enums';
import { generatedChartColors } from '../generated.graph.colors';
import { Subscription } from 'rxjs';
import { DataSetMeterHistoricEnergy } from '../../meter.models';

const WhtokWh = 0.001;

@Component({
  selector: 'pplus-active-energy-chart',
  templateUrl: './active-energy-chart.component.html',
  styleUrls: ['./active-energy-chart.component.scss']
})
export class ActiveEnergyChartComponent implements OnInit {

  @Input() title;
  @Input() totalProperty;

  private subscriptions: Subscription = new Subscription();

  total: number;
  data: DataSetMeterHistoricEnergy;

  countEnergyOptions = {
    duration: 5,
    separator: ' ',
    suffix: ' kWh',
  };

  initOpts = {
    renderer: 'svg',
    width: 'auto',
    height: 'auto'
  };

  chartOptions;

  constructor(
    private meterService: MeterService
  ) { }

  ngOnInit() {
    this.meterService.data$.subscribe((data: DataSetMeterHistoricEnergy) => {
      this.data = data;
      if (data) {
        this.total = data.total[this.totalProperty] * WhtokWh;
        this.initChart();
      } else {
        this.total = null;
      }
    });
  }

  initChart() {
    const serie = this.meterService.generateSerieForProperty(this.data, this.totalProperty);
    this.chartOptions = {
      legend: {
        show: false
      },
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontSize: 10,
          width: 500,
          color: '#fff'
        },
        position: function (pos, params, dom, rect, size) {
          // tooltip will be fixed on the right if mouse hovering on the left,
          // and on the left if hovering on the right.
          const obj = { top: 60 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
          return obj;
        },
        formatter: (params) => {
          const values = `
            ${params.seriesName}<br/>
            ${params.name}<br/>
            ${params.value[1]} kWh<br/>
          `;

          return values;
        }
      },
      toolbox: {
        show: true,
        left: 'center',
        itemSize: 20,
        top: '0%',
        feature: {
          saveAsImage: { title: 'Descargar' }
        }
      },
      xAxis: [{
        type: 'category',
      }],
      yAxis: {
        name: 'kWh',
        gridIndex: 0,
        axisLabel: {
          inside: false,
          formatter: '{value}',
          fontSize: 10
        }
      },
      series: [
        {
          name: this.title,
          type: this.data.chartType === PPlusChartType.Bar ? 'bar' : 'line',
          data: serie,
          xAxisIndex: 0,
          color: generatedChartColors.active
        },
      ]
    };
  }

}
