export let savingChartOpts = {

    tooltip: {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow',
        },
        formatter: (params) => {
            return params[0].name + ': ' + params[0].data.value + ' €';
        }
    },
    xAxis: {
        show: false,
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false }
    },
    grid: {
        top: '0%',
        left: '8%',
        right: '12%',
        bottom: '3%'
    },
    yAxis: {
        type: 'category',
        data: ['P6', 'P5', 'P4', 'P3', 'P2', 'P1'],
        axisTick: { show: false },

    },
    series: [
        {
            type: 'bar',
            name: 'Ahorro',
            label: {
                normal: {
                    show: true,
                    position: 'right',
                    color: 'black',
                    fontSize: 10,
                    backgroundColor: '#ffe30d',
                    borderColor: '#777',
                    borderWidth: 1,
                    borderRadius: 4,
                    formatter: function (params) {
                        const euro = params.data.value;
                        return euro + ' €'; // + '/' + percentage + ' %';
                    }
                }
            },
            data: []
        }
    ]
};
