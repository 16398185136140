import { PVSelfConsumptionTemplateProfile, PVSelfConsumptionTemplateProfilePeriod } from './PVSelfConsumptionTemplate.model';
import * as moment from 'moment';
import { MeteorologicalData } from 'projects/pplus/src/lib/models/meteorologicalData.model';

// Datos del cálculo que se almacenarán en BBDD para poder recuperarse.
// Incluyen todos los datos necesarios para rellenar el interfaz completo de la aplicación
export class PVSelfCalculation {
    pvSelfCalculationId: number;
    description: string;
    locked: boolean;
    calculateYear: number;
    producers: PVSelfCalculationProducer[];
    consumers: PVSelfCalculationConsumer[];

    economicValues: PVSelfEconomicValues;
    predimensionedValues: PVSelfPredimensionedValues;


    // TAXES
    generalIPC: number;
    electricIPC: number;
    electricityPurchaseTax: number;
    electricitySaleTax: number;
    saleIVA: number;
    purchaseIVA: number;
    deductibleIVA: boolean;

    balanceInputData: PVSelfBalanceInputData;
    economicInputData: PVSelfEconomicInputData;
    financialInputData: PVSelfFinancialInputData;

    optimalPowerCriteriaData: PVSelfOptimalPowerCriteriaData;

    reportData: PVSelfReportData;

    version: string;


    constructor() {
        this.pvSelfCalculationId = 0;
        this.calculateYear = 2018;
        this.locked = false;
        this.producers = [];
        this.producers.push(new PVSelfCalculationProducer(this.calculateYear));
        this.consumers = [];
        this.consumers.push(new PVSelfCalculationConsumer(this.calculateYear));
        this.economicValues = new PVSelfEconomicValues();
        this.predimensionedValues = new PVSelfPredimensionedValues();
        this.generalIPC = 5;
        this.electricIPC = 6;
        this.electricityPurchaseTax = 5.11;
        this.electricitySaleTax = 7;
        this.deductibleIVA = false;
        this.balanceInputData = new PVSelfBalanceInputData(this.calculateYear);
        this.economicInputData = new PVSelfEconomicInputData();
        this.financialInputData = new PVSelfFinancialInputData();
        this.optimalPowerCriteriaData = new PVSelfOptimalPowerCriteriaData();
        this.reportData = new PVSelfReportData();
        this.version = 'Pro';
    }
}


export class PVSelfReportData {
    name: string;
    surname: string;
    phone: string;
    email: string;
    cups: string;
    notes: string;
}


export class PVSelfCalculationProducer {
    pvSelfCalculationProducerId: number;
    description: string;
    installation: PVSelfInstallation;

    constructor(_calculateYear: number) {
        this.pvSelfCalculationProducerId = 0;
        this.description = 'Nueva instalación';
        this.installation = new PVSelfInstallation();
    }
}


export class PVSelfInstallation {
    pvSelfInstallationId: number;
    description: string;
    ubication: string;
    latitude: number;
    longitude: number;
    fields: PVSelfInstallationField[];
    radiationData: PVSelfCalculationMeteorologicalData;
    minTempData: PVSelfCalculationMeteorologicalData;
    maxTempData: PVSelfCalculationMeteorologicalData;
    gridType: GridType;
    losses: PVSelfInstallationLosses;

    constructor() {
        this.pvSelfInstallationId = 0;
        this.latitude = 40.416775;
        this.longitude = -3.703790;
        this.gridType = GridType.inside;
        this.description = 'Nueva instalación'; // this.translate.instant('COMMON.ELEMENTS.INSTALLATION') + ' ' + this.pvSelfInstallationId;
        this.fields = [];
        this.fields.push(new PVSelfInstallationField());
        this.radiationData = new PVSelfCalculationMeteorologicalData();
        this.minTempData = new PVSelfCalculationMeteorologicalData();
        this.maxTempData = new PVSelfCalculationMeteorologicalData();
        this.losses = new PVSelfInstallationLosses();
    }
}


export class PVSelfInstallationLosses {
    soilingAuto: boolean;
    soiling: number;
    iamAuto: boolean;
    iam: number;
    radiationAuto: boolean;
    radiation: number;
    temperatureAuto: boolean;
    temperature: number;
    toleranceAuto: boolean;
    tolerance: number;
    mismatchAuto: boolean;
    mismatch: number;
    cablingAuto: boolean;
    cabling: number;
    inverterAuto: boolean;
    inverter: number;
    shadingAuto: boolean;
    shading: number;
    highTensionAuto: boolean;
    highTension: number;
    unavailabilityAuto: boolean;
    unavailability: number;

    constructor() {
        this.soilingAuto = true;
        this.soiling = 0;
        this.iamAuto = true;
        this.iam = 0;
        this.radiationAuto = true;
        this.radiation = 0;
        this.temperatureAuto = true;
        this.temperature = 0;
        this.toleranceAuto = true;
        this.tolerance = 0;
        this.mismatchAuto = true;
        this.mismatch = 0;
        this.cablingAuto = true;
        this.cabling = 0;
        this.inverterAuto = true;
        this.inverter = 0;
        this.shadingAuto = true;
        this.shading = 0;
        this.highTensionAuto = true;
        this.highTension = 0;
        this.unavailabilityAuto = true;
        this.unavailability = 0;
    }
}


export class PVSelfCalculationMeteorologicalData {
    pVSelfCalculationMeteorologicalDataId: number;
    sourceMeteorologicalDataId: number;
    latitude: number;
    longitude: number;
    month1: number;
    month2: number;
    month3: number;
    month4: number;
    month5: number;
    month6: number;
    month7: number;
    month8: number;
    month9: number;
    month10: number;
    month11: number;
    month12: number;
    total: number;
}



export class PVSelfInstallationField {
    pvSelfInstallationFieldId: number;
    name: string;
    editable: boolean;
    polygon: string;
    surface: number;
    panelId: number;
    panelManufacturerId: number;
    inverterId: number;
    inverterManufacturerId: number;
    configuratorPeakPower: number;
    numOfInverters: number;
    numSerialPanels: number;
    numInverterSerials: number;
    automaticPIConfig: boolean;
    configPIType: PVSelfPIConfigType;
    useAccumulation: boolean;
    accumulationCapacity: number;

    available_surface: number;
    realInclination: number;
    realOrientation: number;

    ubication: PVSelfFieldUbicationType;
    technology: PVSelfFieldTechnologyType;
    inclination: number;
    orientation: number;
    inclinationType: PVSelfInclinationType;
    orientationType: PVSelfOrientationType;

    constructor() {
        this.pvSelfInstallationFieldId = new Date().getTime();
        this.name = 'Nuevo campo'; // this.translate.instant('PVSELF.NEWFIELD');
        this.editable = false;
        this.polygon = '';
        this.surface = 0;
        this.numOfInverters = 1;
        this.numSerialPanels = 1;
        this.numInverterSerials = 1;
        this.automaticPIConfig = false;
        this.configPIType = PVSelfPIConfigType.Automatic;
        this.useAccumulation = false;
        this.accumulationCapacity = 25;

        this.available_surface = 0;
        this.realInclination = 30;
        this.realOrientation = 0;

        this.ubication = PVSelfFieldUbicationType.Roof;
        this.technology = PVSelfFieldTechnologyType.Fixed;
        this.inclination = 30;
        this.orientation = 0;
        this.inclinationType = PVSelfInclinationType.Coplanar;
        this.orientationType = PVSelfOrientationType.Coaxial;

        this.configuratorPeakPower = 1;
    }
}


export class PVSelfCalculationConsumer {
    pvSelfCalculationConsumerId: number;
    description: string;
    marketType: MarketType;
    maximumLegalPower: number;
    rates: PVSelfConsumptionRate[];
    consumptionOrigin: PVSelfConsumptionOrigin;
    habitsDataType: PVSelfHabitsDataType;
    billingData: PVSelfBillingHabitsData[];
    dailyHabits: number;
    weeklyHabits: number;
    monthlyHabits: number;
    billingAnnualData: number;
    consumptionTemplateId: number;
    consumptionTemplateUsageId: number;
    manualTemplateConsumption: boolean;
    consumptionProfiles: PVSelfCalculationConsumptionTemplateProfile[];
    calculatedPowerAdjust: number;
    calculatedEnergyAdjust: number;
    calculatedTotalAdjust: number;
    realEnergyAdjust: number;
    realBillingAdjust: boolean;
    consumptionCurve: string;

    constructor(_calculateYear: number) {
        this.pvSelfCalculationConsumerId = 0; // new Date().getTime();
        this.description = 'Nuevo consumidor'; // this.translate.instant('PVSELF.CONSUMER.CONSUMER');
        this.marketType = MarketType.Free;
        this.rates = [];
        this.rates.push(new PVSelfConsumptionRate(_calculateYear));
        this.consumptionOrigin = PVSelfConsumptionOrigin.Template;
        this.maximumLegalPower = 10;
        this.dailyHabits = 0b110;
        this.weeklyHabits = 0b11;
        this.monthlyHabits = 0b111111111111;
        this.billingAnnualData = 0;
        this.consumptionTemplateId = 1;
        this.consumptionTemplateUsageId = 1;
        this.manualTemplateConsumption = false;
        this.consumptionProfiles = [];
        this.calculatedPowerAdjust = 0;
        this.calculatedEnergyAdjust = 0;
        this.calculatedTotalAdjust = 0;
        this.realEnergyAdjust = 0;
        this.realBillingAdjust = false;
        this.consumptionCurve = null;
        this.habitsDataType = PVSelfHabitsDataType.Basic;
        this.billingData = [];
    }
}

export class PVSelfCalculationConsumptionTemplateProfile {
    pvSelfCalculationConsumptionTemplateProfileId: number;
    description: string;
    color: string;
    h0: number;
    h1: number;
    h2: number;
    h3: number;
    h4: number;
    h5: number;
    h6: number;
    h7: number;
    h8: number;
    h9: number;
    h10: number;
    h11: number;
    h12: number;
    h13: number;
    h14: number;
    h15: number;
    h16: number;
    h17: number;
    h18: number;
    h19: number;
    h20: number;
    h21: number;
    h22: number;
    h23: number;
    periods: Array<PVSelfCalculationConsumptionTemplateProfilePeriod>;
    order: number;

    constructor(profile: PVSelfConsumptionTemplateProfile) {

        if (profile == null) {
            this.h0 = this.h1 = this.h2 = this.h3 = this.h4 = this.h5 = this.h6 = this.h7 = this.h8 = this.h9 = this.h10 = 0;
            this.h11 = this.h12 = this.h13 = this.h14 = this.h15 = this.h16 = this.h17 = this.h18 = this.h19 = this.h20 = 0;
            this.h21 = this.h22 = this.h23 = 0;
            this.color = '#fe9462';
            this.periods = [];
        } else {
            this.description = profile.description;
            this.h0 = profile.h0;
            this.h1 = profile.h1;
            this.h2 = profile.h2;
            this.h3 = profile.h3;
            this.h4 = profile.h4;
            this.h5 = profile.h5;
            this.h6 = profile.h6;
            this.h7 = profile.h7;
            this.h8 = profile.h8;
            this.h9 = profile.h9;
            this.h10 = profile.h10;
            this.h11 = profile.h11;
            this.h12 = profile.h12;
            this.h13 = profile.h13;
            this.h14 = profile.h14;
            this.h15 = profile.h15;
            this.h16 = profile.h16;
            this.h17 = profile.h17;
            this.h18 = profile.h18;
            this.h19 = profile.h19;
            this.h20 = profile.h20;
            this.h21 = profile.h21;
            this.h22 = profile.h22;
            this.h23 = profile.h23;
            this.order = profile.order;

            this.periods = [];
            profile.periods.forEach(x => {
                const period = new PVSelfCalculationConsumptionTemplateProfilePeriod();
                period.fromDay = x.fromDay;
                period.fromMonth = x.fromMonth;
                period.pvSelfCalculationConsumptionTemplateProfileId = x.pvSelfConsumptionTemplateProfileId;
                period.pvSelfCalculationConsumptionTemplateProfilePeriodId = x.pvSelfConsumptionTemplateProfilePeriodId;
                period.toDay = x.toDay;
                period.toMonth = x.toMonth;
                period.weekdays = x.weekdays;
                this.periods.push(period);
            });
        }
    }
}


export class PVSelfCalculationConsumptionTemplateProfilePeriod {
    pvSelfCalculationConsumptionTemplateProfilePeriodId: number;
    pvSelfCalculationConsumptionTemplateProfileId: number;
    fromDay: number;
    fromMonth: number;
    toDay: number;
    toMonth: number;
    weekdays: number;
}

export class PVSelfConsumptionRate {
    pvSelfConsumptionRateId: number;
    rateId: number;
    from: Date;
    to: Date;
    contracts: PVSelfConsumptionContract[];
    billingPeriod: PVSelfBillingPeriod;

    constructor(year: number) {
        this.rateId = 3;
        this.contracts = [];
        this.from = moment.utc({ year: year, month: 0, date: 1 }).locale('es-ES').toDate(); // new Date(year, 0, 1);
        this.to = moment.utc({ year: year, month: 11, date: 31 }).locale('es-ES').toDate(); // new Date(year, 11, 31);
        this.billingPeriod = PVSelfBillingPeriod.Mensual;
    }
}


export class PVSelfConsumptionContract {
    period: number;
    power: number;
    powerPurchasePrice: number;
    energyPurchasePrice: number;
    energySalePrice: number;
}


export class PVSelfConsumptionHabits {
    daily: number;
    weekly: number;
    monthly: number;

    constructor() {
        this.daily = 0b110;
        this.weekly = 0b11;
        this.monthly = 0b111111111111;
    }
}


export class PVSelfEconomicValues {
    partitionCriteria: PVSelfPartitionCriteria;
    partitionCoefficient: number[];

    constructor() {
        this.partitionCriteria = PVSelfPartitionCriteria.Consumption;
        this.partitionCoefficient = [];
    }
}


export class PVSelfPredimensionedValues {
    minPower: number;
    maxPower: number;
    minManualPower: number;
    maxManualPower: number;
    useAccumulation: boolean;
    accumulationValue: number;
    withExcess: boolean;
    withCompensation: boolean;
    predimensioningType: PVSelfPredimensioningType;

    constructor() {
        this.minPower = 20;
        this.maxPower = 80;
        this.minManualPower = 20;
        this.maxManualPower = 80;
        this.useAccumulation = false;
        this.accumulationValue = 25;
        this.withExcess = false;
        this.withCompensation = true;
        this.predimensioningType = PVSelfPredimensioningType.HiredPower;
    }
}

export class PVSelfBalanceInputData {
    from: Date;
    to: Date;
    selectedProducerIndex: number;
    selectedConsumerIndex: number;

    constructor(year) {
        this.from = moment().year(year).month(0).date(1).toDate();
        this.to = moment().year(year).month(11).date(31).toDate();
        this.selectedProducerIndex = 0;
        this.selectedConsumerIndex = 0;
    }
}



export class PVSelfFieldCost {
    pvSelfFieldCostId: number;
    powerFrom: number;
    powerTo: number;
    ubication: PVSelfFieldUbicationType;
    technology: PVSelfFieldTechnologyType;
    inclinationType: PVSelfInclinationType;
    executionCost: number;
    exploitationCost: number;
    loaderCost: number;
    htConnectionCosrt: number;
}


export class PVSelfFinancialInputData {
    pvSelfFinancialInputDataId: number;
    selectedProducerIndex: number;
    selectedConsumerIndex: number;
    extraCostsMoney: number;
    extraCostsPercentage: number;
    financedPercentage: number;
    periodicity: PVSelfFinancialPeriod;
    numberOfFees: number;
    lack: number;
    financialInterest: number;
    openingCommissionPercentage: number;
    studyExpenses: number;

    constructor() {
        this.selectedProducerIndex = 0;
        this.selectedConsumerIndex = 0;
        this.extraCostsMoney = 0;
        this.extraCostsPercentage = 0;
        this.financedPercentage = 0;
        this.periodicity = PVSelfFinancialPeriod.Mensual;
        this.numberOfFees = 300;
        this.lack = 12;
        this.financialInterest = 2.3;
        this.openingCommissionPercentage = 1.2;
        this.studyExpenses = 350;
    }
}


export class PVSelfEconomicInputData {
    selectedProducerIndex: number;
    selectedConsumerIndex: number;

    constructor() {
        this.selectedProducerIndex = 0;
        this.selectedConsumerIndex = 0;
    }
}



export class PVSelfBillingHabitsData {
    pvSelfBillingHabitsDataId: number;
    billingPeriod: number;
    energyP0: number;
    energyP1: number;
    energyP2: number;
    energyP3: number;
    energyP4: number;
    energyP5: number;
    energyP6: number;

    constructor() {
        this.pvSelfBillingHabitsDataId = 0;
        this.billingPeriod = 0;
        this.energyP0 = 0;
        this.energyP1 = 0;
        this.energyP2 = 0;
        this.energyP3 = 0;
        this.energyP4 = 0;
        this.energyP5 = 0;
        this.energyP6 = 0;
    }
}


export class PVSelfOptimalPowerCriteriaData {

    pvSelfOptimalPowerCriteriaDataId: number;
    excedentaryPercentage: number;
    minNominalPowerPercentage: number;
    maxNominalPowerPercentage: number;
    minPeakPowerPercentage: number;
    maxPeakPowerPercentage: number;
    oversizeCoefficient: number;

    constructor() {
        this.excedentaryPercentage = 50;
        this.minNominalPowerPercentage = 80;
        this.maxNominalPowerPercentage = 100;
        this.minPeakPowerPercentage = 85;
        this.maxPeakPowerPercentage = 110;
        this.oversizeCoefficient = 0;
    }

}











export enum PVSelfFieldTechnologyType {
    Fixed = 1,
    Axis_1 = 2,
    Axis_2 = 3
}

export enum PVSelfFieldUbicationType {
    Roof = 1,
    Floor = 2
}

export enum PVSelfInclinationType {
    Indifferent = 0,
    Optimal = 1,
    Coplanar = 2,
    Define = 3,
}

export enum PVSelfOrientationType {
    Optimal = 1,
    Coaxial = 2,
    Define = 3,
}

export enum GridType {
    inside = 1,
    outside = 2
}


export enum MarketType {
    Free = 1,
    Regulated = 2
}

export enum PVSelfBillingPeriod {
    Anual = 1,
    Semestral = 2,
    Cuatrimestral = 3,
    Trimestral = 4,
    Bimestral = 5,
    Mensual = 6,
    Quincenal = 7,
    Semanal = 8,
    Diario = 9
}

export enum PVSelfFinancialPeriod {
    Anual = 1,
    Semestral = 2,
    Cuatrimestral = 3,
    Trimestral = 4,
    Bimestral = 5,
    Mensual = 6,
    Quincenal = 7
}


export enum PVSelfPartitionCriteria {
    Power = 1,
    Consumption = 2,
    Defined = 3
}


export enum PVSelfConsumptionOrigin {
    Habits = 1,
    Template = 2,
    Curve = 3,
    TemplateWithEnergyAdjust = 4
}


export enum PVSelfHabitsDataType {
    Basic = 1,
    Intermediate = 2,
    Complete = 3
}


export enum PVSelfPIConfigType {
    Automatic = 1,
    Semiautomatic = 2,
    Manual = 3
}


export enum PVSelfPredimensioningType {
    OptimalPeakPower = 1,
    AutoRange = 2,
    ManualRange = 3,
    HiredPower = 4
}

