import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF } from "../admin.model";

const contactFields = {
  userContacts: [
    'nameContact', 'surnameContact', 'movil', 'email',
    'country', 'state', 'city', 'postalAddress', 'postCode', 'telephone', 'professionalActivity',
    'professionalTitle', 'department', 'webPage', 'comment', 'contactBy'
  ],
  userFinancials: [
    'businessName', 'nifCif', 'countryFinancial', 'stateFinancial', 'cityFinancial',
    'postalAddressFinancial', 'postCodeFinancial', 'paymentType', 'bankAccount'
  ],
};

function getContactColDefs(): ColDef[] {
  const colDefs: ColDef[] = [];
  for (const key in contactFields) for (const field of contactFields[key]) colDefs.push({
    field: key + '.' + field,
    headerName: field[0].toUpperCase() + field.slice(1),
    valueFormatter: function (params) {
      let value = '';
      if (params && params.data) {
        const contacts = params.data[key];
        if (contacts && contacts.length) for (const contact of contacts) {
          const f = String(field).split('Financial')[0];
          if (contact[f]) {
            if (value) value += ', ';
            value += contact[f];
          }
        }
      }
      return value;
    },
  });
  return colDefs;
}

export const USER_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const USER_COL_DEFS: ColDef[] = [
  {
    field: 'userId',
    headerName: 'ID',
    checkboxSelection: function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'fullName',
    headerName: 'Name',
  },
  {
    field: 'aspNetUser.userName',
    headerName: 'User',
  },
  {
    field: 'aspNetUser.email',
    headerName: 'Notification Email',
  },
  {
    field: 'creationDate',
    headerName: 'Creation Date',
    filter: 'agDateColumnFilter',
  },
  {
    field: 'deletionDate',
    headerName: 'Deletion Date',
    filter: 'agDateColumnFilter',
  },
  {
    field: 'activated',
    headerName: 'Activated',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkBoxComponent',
  },
  {
    field: 'aspNetUser.lastLogin',
    headerName: 'Last Login',
    filter: 'agDateColumnFilter',
  },
  {
    field: 'language',
    headerName: 'Language',
  },
  {
    field: 'timeZone',
    headerName: 'Time Zone',
  },
  ...getContactColDefs(),
  {
    field: 'userGroups.userGroupNames',
    headerName: 'User Group Members',
    valueFormatter: function (params) {
      const users = params.data.userGroupMembers;
      let data = '';
      for (let index = 0; index < users.length; index++) {
        if (index > 0) data += ', ';
        data += users[index].userGroup.name;
      }
      return params.data.userGroupNames = data;
    },
  },
  {
    field: 'userEntity.entityNames',
    headerName: 'Entity',
    valueFormatter: function (params) {
      let value = '';
      if (params.data.entityUsers && params.data.entityUsers.length) {
        for (const el of params.data.entityUsers) {
          if (value) value += ', ';
          value += el.entity.code;
        }
      }
      return params.data.entityNames = value;
    },
  },
];
