import { Component, OnInit } from '@angular/core';
import { PVSelfCalculation } from 'src/app/modules/pvself/models/PvSelfCalculation.model';
import { PVSelfCalculationService } from 'src/app/modules/pvself/services/pvself-calculation.service';

@Component({
  selector: 'pplus-pvself-criteria-options',
  templateUrl: './pvself-criteria-options.component.html',
  styleUrls: ['./pvself-criteria-options.component.scss']
})
export class PVSelfCriteriaOptionsComponent implements OnInit {
  _calculation: PVSelfCalculation;

  constructor(private calculationService: PVSelfCalculationService) { }

  ngOnInit() {
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
    //    this._calculation = this.calculationService.get();
  }
}
