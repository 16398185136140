import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { APP_EFFECTIVE_PERMISSIONS_HEADERS } from './columnDefs';
import { EffectivePermissionsService } from 'projects/pplus/src/lib/services/permissions/effective-permissions.service';
import { PermittedApplication } from 'projects/pplus/src/lib/models/permissions/effectivePermissions.model';
import { AutocompleteUsersComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-users/autocomplete-users.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AutocompleteEntitiesComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-entities/autocomplete-entities.component';


@Component({
  selector: 'pplus-effective-permissions-applications',
  templateUrl: './effective-permissions-applications.component.html',
  styleUrls: ['./effective-permissions-applications.component.scss']
})
export class EffectivePermissionsApplicationsComponent implements OnInit {
  @ViewChild(AutocompleteUsersComponent, { static: true }) searchUser: AutocompleteUsersComponent;
  @ViewChild(AutocompleteEntitiesComponent, { static: true }) searchEntity: AutocompleteEntitiesComponent;


  entities: any;
  entitiesSearch = new FormControl();
  isLoading = false;
  gridApi: any;
  columnDefs: any;
  appList: PermittedApplication[];
  loaderId: string;

  constructor(private effectivePermissionsService: EffectivePermissionsService,
    private ngxLoaderService: NgxUiLoaderService) { }

  ngOnInit() {
    this.columnDefs = APP_EFFECTIVE_PERMISSIONS_HEADERS;
    this.loaderId = 'loader-' + new Date().getTime();

  }

  getPermittedApplications() {
    if (this.searchEntity.selectedValue !== undefined && this.searchUser.selectedValue !== undefined &&
      this.searchEntity.selectedValue !== null && this.searchUser.selectedValue !== null) {
      this.ngxLoaderService.startLoader(this.loaderId);
      this.effectivePermissionsService.getPermittedApplications(this.searchEntity.selectedValue.entityId, this.searchUser.selectedValue.userId).subscribe(
        res => {
          this.appList = res;
          this.ngxLoaderService.stopLoader(this.loaderId);
        },
        error => {
          this.appList = [];
          this.ngxLoaderService.stopLoader(this.loaderId);
        });
    }
  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  }



}
