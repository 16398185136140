import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF } from "../admin.model";

export const DOMAINS_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const DOMAINS_COL_DEFS: ColDef[] = [
  {
    field: 'entityDomainId',
    headerName: 'ID',
    checkboxSelection: function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'fqdn',
    headerName: 'FQDN',
  },
  {
    field: 'activated',
    headerName: 'Activated',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkboxComponent',
  },
  {
    field: 'entity.code',
    headerName: 'Owner Entity',
  },
  {
    field: 'style',
    headerName: 'Style',
    sortable: false,
    filter: false,
    cellRenderer: 'styleComponent',
  },
];
