import { Component, OnInit } from '@angular/core';
import { chartColors } from '../pvself-graph-colors';
import { TranslateService } from '@ngx-translate/core';
import { PVSelfEngineService } from 'src/app/modules/pvself/services/pvself-engine.service';
import { PVSelfBalanceResults } from 'src/app/modules/pvself/models/PVSelfResultsModel.model';

@Component({
  selector: 'pplus-pvself-balance-periods-chart',
  templateUrl: './pvself-balance-periods-chart.component.html',
  styleUrls: ['./pvself-balance-periods-chart.component.scss']
})
export class PVSelfBalancePeriodsChartComponent implements OnInit {
  balanceResults: PVSelfBalanceResults;
  periodChartOptions: any;
  periodChartOptionsNormal: any;

  initForPeriodGraph = {
    renderer: 'svg',
    height: 310
  };

  ps = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'];
  psData = {
    'P1': true,
    'P2': true,
    'P3': true,
    'P4': true,
    'P5': true,
    'P6': true,
    'P1*': true,
    'P2*': true,
    'P3*': true,
    'P4*': true,
    'P5*': true,
    'P6*': true,
  };

  graphInitOptions = {
    renderer: 'svg',
    width: null,
    height: 400
  };



  constructor(private translate: TranslateService,
    private engineService: PVSelfEngineService) {
  }


  ngOnInit() {

    this.engineService.balanceEnergyResults.subscribe((res: PVSelfBalanceResults) => {
      this.balanceResults = res;
      if (res !== null) {
        this.showChartForGlobalType();
      }
    });
  }


  showChartForGlobalType() {
    if (this.balanceResults !== null) {
      this.initiatePowerChart();
    }
  }


  initiatePowerChart() {
    const series = {};
    this.ps.forEach((element, index) => {
      series[`${element}`] = [
        this.balanceResults.energy.consumptionByPeriod[index],
        this.balanceResults.energy.networkConsumedByPeriod[index],
        this.balanceResults.energy.autoconsumedByPeriod[index],
        this.balanceResults.energy.excedentaryByPeriod[index],
        this.balanceResults.energy.netGeneratedByPeriod[index]
      ];
    });

    const periodGraphBarWith = 10;
    this.periodChartOptionsNormal = {
      tooltip: {
        confine: false,
        trigger: 'axis',
        position: function (pos, params, dom, rect, size) {
          // tooltip will be fixed on the right if mouse hovering on the left,
          // and on the left if hovering on the right.
          const obj = { top: 60 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
          return obj;
        },
        axisPointer: {
          type: 'shadow'
        },
        formatter: params => {
          let values = '';
          for (const param of params) {
            const isDefined = typeof (param) === 'undefined';
            if (!isDefined) {

              values += param.seriesName + ': ' + param.value + ' kWh <br>';
            }
          }
          return values;
        }
      },
      legend: {
        show: false,
        data: this.ps,
        selected: {}
      },
      grid: {
        top: '5%',
        left: '15%',
        right: '4%',
        bottom: '15%',
      },
      xAxis: {
        type: 'value',
        name: 'kWh',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: [10, 10, 0, 0]
        },
        axisLabel: {
          show: true,
          formatter: '{value}',
          rotate: 35,
          fontSize: 10
        }
      },
      yAxis: {
        type: 'category',
        data: ['Consumo Total', 'Consumida Red', 'Autoconsumida', 'Excedentaria', 'Neta Generada'],
        axisLabel: {
          rotate: 35,
          fontSize: 10
        }
      },
      series: [
        {
          name: 'P1',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: { color: chartColors.p1 },
          barWidth: periodGraphBarWith,
          data: series['P1']
        },
        {
          name: 'P2',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: { color: chartColors.p2 },
          barWidth: periodGraphBarWith,
          data: series['P2']
        },
        {
          name: 'P3',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: { color: chartColors.p3 },
          barWidth: periodGraphBarWith,
          data: series['P3']
        },
        {
          name: 'P4',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: { color: chartColors.p4 },
          barWidth: periodGraphBarWith,
          data: series['P4']
        },
        {
          name: 'P5',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: { color: chartColors.p5 },
          barWidth: periodGraphBarWith,
          data: series['P5']
        },
        {
          name: 'P6',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: { color: chartColors.p6 },
          barWidth: periodGraphBarWith,
          data: series['P6']
        }
      ]

    };
    this.periodChartOptions = this.periodChartOptionsNormal;
  }






  modifySerie(serieName: string, $event) {
    const state = $event.checked;
    this.psData[`${serieName}`] = state;
    this.psData[`${serieName}*`] = state;
    this.periodChartOptions.legend.selected = this.psData;
    this.periodChartOptions = { ...this.periodChartOptions, ...this.periodChartOptions };
  }


}