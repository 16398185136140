import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PermittedPlant } from 'projects/pplus/src/lib/models/permissions/effectivePermissions.model';
import { EffectivePermissionsService } from 'projects/pplus/src/lib/services/permissions/effective-permissions.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PLANT_EFFECTIVE_PERMISSIONS_HEADERS } from './columnDefs';
import { AutocompleteUsersComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-users/autocomplete-users.component';
import { AutocompleteEntitiesComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-entities/autocomplete-entities.component';
import { AutocompleteApplicationsComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-applications/autocomplete-applications.component';

@Component({
  selector: 'pplus-effective-permissions-plants',
  templateUrl: './effective-permissions-plants.component.html',
  styleUrls: ['./effective-permissions-plants.component.scss']
})
export class EffectivePermissionsPlantsComponent implements OnInit {
  @ViewChild(AutocompleteUsersComponent, { static: true }) searchUser: AutocompleteUsersComponent;
  @ViewChild(AutocompleteEntitiesComponent, { static: true }) searchEntity: AutocompleteEntitiesComponent;
  @ViewChild(AutocompleteApplicationsComponent, { static: true }) searchApplication: AutocompleteApplicationsComponent;


  entities: any;
  entitiesSearch = new FormControl();
  isLoading = false;
  gridApi: any;
  columnDefs: any;
  plantList: PermittedPlant[];
  loaderId: string;

  constructor(private effectivePermissionsService: EffectivePermissionsService,
    private ngxLoaderService: NgxUiLoaderService) { }

  ngOnInit() {
    this.columnDefs = PLANT_EFFECTIVE_PERMISSIONS_HEADERS;
    this.loaderId = 'loader-' + new Date().getTime();

  }

  getPermittedPlants() {
    if (this.searchEntity.selectedValue !== undefined && this.searchUser.selectedValue !== undefined &&
      this.searchEntity.selectedValue !== null && this.searchUser.selectedValue !== null) {
      this.ngxLoaderService.startLoader(this.loaderId);
      this.effectivePermissionsService.getPermittedPlants(this.searchEntity.selectedValue.entityId,
        this.searchUser.selectedValue.userId, this.searchApplication.selectedValue.applicationId).subscribe(
          res => {
            this.plantList = res;
            this.ngxLoaderService.stopLoader(this.loaderId);
          },
          error => {
            this.plantList = [];
            this.ngxLoaderService.stopLoader(this.loaderId);
          });
    }
  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  }


}
