import { Component, OnInit} from '@angular/core';
import { MeterService } from 'projects/pplus/src/lib/components/elements/meter/meter.service';

@Component({
  selector: 'app-selfview-border-meter',
  templateUrl: './selfview-border-meter.component.html',
  styleUrls: ['./selfview-border-meter.component.scss'],
  providers: [MeterService]
})
export class SelfviewBorderMeterComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {
  }
}
