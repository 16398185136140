import { Component, AfterViewInit, Input } from '@angular/core';
import { StiReportsService } from '../../../services/sti-reports.service';
import { environment } from 'src/environments/environment';


declare var Stimulsoft: any;


@Component({
  selector: 'lib-pplus-report-designer',
  templateUrl: './pplus-report-designer.component.html',
  styleUrls: ['./pplus-report-designer.component.scss']
})
export class PPlusReportDesignerComponent implements AfterViewInit {
  @Input('report') report: any;
  @Input('data') data: any;

  viewerId = 'viewer_id';

  constructor(private stiReportsService: StiReportsService) { }

  ngAfterViewInit(): void {
    this.showDesigner();
  }



  private showDesigner(): void {

    Stimulsoft.Base.StiLicense.key = environment.STIMULSOFTKEY;

    const options = new Stimulsoft.Designer.StiDesignerOptions();
    const designer = new Stimulsoft.Designer.StiDesigner(options, 'StiDesigner', false);

    this.stiReportsService.getReport().subscribe(res => {

      const report = new Stimulsoft.Report.StiReport();
      report.load(res);

      this.data = '{"nombre": "Rafael"}';

      const dataSet = new Stimulsoft.System.Data.DataSet('Demo');
      dataSet.readJson(this.data);
      report.dictionary.databases.clear();
      report.regData('Demo', 'Demo', dataSet);

      designer.report = report;
      designer.renderHtml(this.viewerId);
    });


    // designer.report = new Stimulsoft.Report.StiReport();
    // designer.report.loadFile(this.reportFile);
    // designer.renderHtml(this.viewerId);
  }
}
