import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NbToastrService } from '@nebular/theme';
import { PanelsService } from '../../panels/panels.service';
import { IservicesRecord } from 'projects/pplus/src/lib/interfaces/interfaces';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';

@Component({
  selector: 'app-servicerecord-details',
  templateUrl: './servicerecord-details.component.html',
  styleUrls: ['./servicerecord-details.component.scss']
})
export class ServicerecordDetailsComponent implements OnInit {


  formGroup: FormGroup;
  selectedType;
  selectedLanguage;
  selectedParent;
  @Output()
  splitOptions = new EventEmitter();
  @Output()
  updateDelete = new EventEmitter();
  @Input() formStatus = false;

  data: IservicesRecord = {
    Id: null,
    Id_instalation: null,
    Id_serviceType: null,
    CreationDate: null,
    FinishDate: null,
    cadenceBilling: null,
    amount: null,
    serviceParticularities: null,

   };

  // dataService: CompleterData;
  searchData;
  entitySelected: any;
  autocomplete: string;
  constructor(private toastService: NbToastrService,
              private dataRow: RowDataService,
              private entityService: PanelsService,
              // private completerService: CompleterService,
            ) {
    /*this.formGroup = new FormGroup({
      MODEL : new FormControl(this.data.Id),
      NUMBERSTRING: new FormControl(this.data.Name),
      PEAKPOWER: new FormControl(this.data.PowerPeak),
      ASOCIATEDSTRING: new FormControl(this.data.NominalPower),
      CREATIONDATE: new FormControl(this.data.CreationDate),
      DELETIONDATE: new FormControl(this.data.DeletionDate)
    });*/
  }

    ngOnInit() {
      /*this.dataRow.currentRowData.subscribe(row => {
        this.data = <IEntity>row;
        if (this.data.EntityId != null) {
          this.entitieService.getEntity(this.data.EntityId)
          .subscribe( (res) => {
            this.autocomplete = res.code;
            this.entitySelected = res.entityId;
            this.formGroup.patchValue({
              FQDN: this.data.Domain,
              entity: this.autocomplete,
              activated: this.data.Activated
            });
          });
        }
        if (row === isEmpty()) {
         this.clearData();
        }
      });*/
    }

    // onEntitySelected(selected: CompleterItem) {
    //   if (selected != null) {
    //     this.entitySelected = selected.originalObject;
    //   }
    // }

  showToast(icon, status, title , msg) {
    this.toastService.show(title, msg , { icon, status });
  }

  onSubmit(data) {
    /*this.entityService.createEntity(data)
    .subscribe( (res) => {
      if (res === 200) {
        this.showToast('', 'success', 'Se ha creado el nuevo dominio : ' + data.CODE , 'Elemento creado');
        this.clearData();
        this.updateDelete.emit({ Type: 'create'});
      } else {
        this.showToast('', 'danger', 'Se ha producido un error al crear el dominio :' + data.CODE , 'ERROR');
      }
    });
    this.clearData();*/
  }

  copy() {
    /*if (this.data.EntityId == null) {
      this.showToast('', 'info', 'Seleccione una entidad para copiar su información', 'Info');
    } else {
      this.formStatus = true;
      this.data.Code = null;
    }*/
  }

  delete() {
  /*  if (this.data.EntityId == null) {
      this.showToast('', 'info', 'Seleccione una entidad', 'Info');
    } else {
      this.entityService.deleteEntity(this.data.EntityId)
      .subscribe( (res) => {
        if (res === 204) {
          this.showToast('', 'success', 'Se ha borrado la entidad : ' + this.data.Code , 'Entidad eliminada');
          this.updateDelete.emit({ Type: 'delete', EntityId: this.data.EntityId});
          this.clearData();
        } else {
          this.showToast('', 'danger', 'Se ha producido un error al intentar borrar la entidad : ' + this.data.Code , 'Error');
        }
      });
    }*/
  }

  update() {
   /* this.showToast('', 'danger', 'Se ha producido un error al intentar actualizar la entidad :' + this.data.Code , 'Error');
    const updateData = <IEntity>{
      Code: this.formGroup.value.CODE,
      EntityId: this.data.EntityId,
      Activated: this.formGroup.value.ACTIVATED,
      Description: this.formGroup.value.DESCRIPTION,
      Domain: this.formGroup.value.DOMAIN,
      ParentId: this.formGroup.value.PARENTID
    };

    if (this.data.EntityId == null) {
      this.showToast('', 'info', 'Seleccione un dominio', 'Info');
    } else {
      this.entityService.updateEntity(this.data.EntityId, updateData)
      .subscribe( (res) => {
        if (res === 204) {
          this.showToast('', 'success', 'Se ha actualizado la entidad : ' + this.data.Code , 'Entidad actualizada');
          this.updateDelete.emit({ Type: 'create'});
        } else {
          this.showToast('', 'danger', 'Se ha producido un error al intentar actualizar la entidad :' + this.data.Code , 'Error');
        }
      });
    }
    this.clearData();*/
  }


  exit() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.formStatus = false;
    this.splitOptions.emit({opt: opt, mode : 'default'});
    this.clearData();
  }


  clearData() {
  /*  this.data = {
      EntityId: null,
      Code: '',
      Description: '',
      Domain: '',
      Activated: null,
      ParentId: null,
      Language: '',
      CreationDate: null,
    };
    this.entitySelected = null;
    this.autocomplete = null;
    this.formGroup.patchValue({
      EntityId: this.data.EntityId,
      Code: this.data.Code,
      Description: this.data.Description,
      Domain: this.data.Domain,
      Activated: this.data.Activated,
      ParentId: this.data.ParentId,
      Language: this.data.Language,
      CreationDate: this.data.CreationDate,
    });*/
  }
}
