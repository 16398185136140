import { REACTIVE_DATA } from "./reactive-main.series";
import { chartColors } from "../../selfview.graph.colors";

/**
 * Bar char options
 */
export const REACTIVE_BAR_CHART_OPTIONS = {
  legend: {
    show: false
  },
  tooltip: {
    trigger: 'item',
    textStyle: {
      fontSize: 10,
      width: 500
    },
    formatter: (params) => {
      let values = '';
      values += params.seriesName + ': ' + params.data + ' kWh <br>';
      return values;
    }
  },
  toolbox: {
    show: true,
    left: 'center',
    itemSize: 20,
    top: '0%',
    feature: {
      dataZoom: {
        show: true,
        yAxisIndex: true,
        title: {zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar'}
      },
      restore: {show: true, title: 'Anular Todo'},
      saveAsImage: {title: 'Descargar'}
    }
  },
  xAxis: [{
    type: 'category'
  }],
  grid: {left: '8%', right: '5%', bottom: '25%'},
  yAxis: {
    name: 'kWh',
    gridIndex: 0,
    axisLabel: {
      inside: false,
      formatter: '{value}',
      fontSize: 10
    }
  },
  dataZoom: [
    {
      type: 'inside'
    }
  ],
  series: []
};

export const REACTIVE_AREA_CHART_OPTIONS = {
  animation: true,
  legend: {
    show: false,
  },
  tooltip: {
    show: true,
    triggerOn: 'mousemove',
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    show: true,
    left: 'center',
    itemSize: 20,
    top: '0%',
    feature: {
      dataZoom: {
        show: true,
        yAxisIndex: true,
        title: {zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar'}
      },
      restore: {show: true, title: 'Anular Todo'},
      saveAsImage: {title: 'Descargar'}
    }
  },
  xAxis: [{
    type: 'time',
    axisLabel: {
      fontSize: 10,
      formatter: (function (value) {
        const time = new Date(value);
        const resp = time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', {
          hour: '2-digit',
          minute: '2-digit'
        });
        return resp;
      })
    },
    splitLine: {
      show: false,
    }
  }],
  yAxis: {
    type: 'value',
    name: 'kWh',
    axisTick: {
      inside: false
    },
    splitLine: {
      show: false,
      lineStyle: {
        opacity: 0.2
      }
    },
    axisLabel: {
      inside: false,
      formatter: '{value}',
      fontSize: 10
    },
    z: 10
  },
  grid: {
    left: '5%',
    right: '5%',
    bottom: '25%'
  },
  dataZoom: [{
      show: true,
      height: 40,
      type: 'slider',
      bottom: '0%',
      xAxisIndex: [0],
    },
    {
      type: 'inside'
    }
  ],
  series: []
};

/**
 * Charts legends
 */
export const REACTIVE_LEGENDS = [
  {
    name: REACTIVE_DATA.CONSUMPTION.label,
    p: [
     chartColors.periods.consumoP1,
      chartColors.periods.consumoP2,
      chartColors.periods.consumoP3,
      chartColors.periods.consumoP4,
      chartColors.periods.consumoP5,
      chartColors.periods.consumoP6
    ]
  },
  {
    name: REACTIVE_DATA.NETWORK_CONSUMPTION.label,
    p: [
      chartColors.periods.consumo_redP1,
      chartColors.periods.consumo_redP2,
      chartColors.periods.consumo_redP3,
      chartColors.periods.consumo_redP4,
      chartColors.periods.consumo_redP5,
      chartColors.periods.consumo_redP6,
    ]
  },
  {
    name: REACTIVE_DATA.EXCEDENTARY.label,
    p: [
      chartColors.periods.excedenteP1,
      chartColors.periods.excedenteP2,
      chartColors.periods.excedenteP3,
      chartColors.periods.excedenteP4,
      chartColors.periods.excedenteP5,
      chartColors.periods.excedenteP6
    ]
  },
  {
    name: REACTIVE_DATA.GENERATED.label,
    p: [
      chartColors.periods.netaGeneradaP1,
      chartColors.periods.netaGeneradaP2,
      chartColors.periods.netaGeneradaP3,
      chartColors.periods.netaGeneradaP4,
      chartColors.periods.netaGeneradaP5,
      chartColors.periods.netaGeneradaP6
    ]
  }
];

/**
 * Reactive energy data sources
 */
export const REACTIVE_ENERGY_DATASOURCES = [
  {
    'id': 0,
    'viewValue': 'Calculado'
  },
  {
    'id': 1,
    'viewValue': 'Meter'
  },
  {
    'id': 3,
    'viewValue': 'Zero Injection'
  }
];


/**
 * Initial options for chart
 */
export const INIT_OPTIONS = {
  renderer: 'svg',
  width: 'auto',
  height: 'auto'
};
