import { chartColors } from '../pvself-graph-colors';
import { formatNumber } from '@angular/common';

export const initOptionsForPieCharts = {
    renderer: 'svg',
    height: 90
};


export const COVERAGE_CHART_OPTIONS = {
    tooltip: {
        trigger: 'item',
        formatter: '{b0}: {c0}%',
    },
    legend: {
        orient: 'horizontal',
        x: 'center',
        data: ['Cobertura', 'Aprovechamiento'],
        show: false,
        selected: {}
    },
    series: [
        {
            name: 'Cobertura', // this.datagraf2[0],
            type: 'pie',
            startAngle: 180,
            center: ['25%', '70%'],
            radius: ['75%', '100%'],
            avoidLabelOverlap: false,
            tooltip: { show: false },
            labelLine: { show: false },
            itemStyle: {
                normal: {
                    color: chartColors.cobertura
                }
            },
            data: [
                {
                    value: 0, // this.cobertura,
                    name: 'Cobertura',
                    label: {
                        normal: {
                            formatter: function (data) {
                                return parseFloat(data.value).toFixed(2);
                            },
                            show: true,
                            position: 'center',
                            fontSize: 14,
                        },

                    }
                },
                {
                    value: 100, // 100 - this.cobertura,
                    name: '100-cobertura',
                    //                    itemStyle: { color: this.approvechamiento < 10 ? 'rgba(0,0,0,0.09)' : 'transparent' },
                    label: { show: false },
                    tooltip: {
                        show: true,
                        //                        formatter: 'Cobertura: ' + this.cobertura + '%'
                    }
                },
                {
                    value: 100, //  100 - this.cobertura,
                    name: '100-cobertura',
                    itemStyle: { color: 'transparent' },
                    label: { show: false },
                    tooltip: { show: false }
                },
            ]
        },
        {
            name: 'Aprovechamiento', // this.datagraf2[1],
            type: 'pie',
            startAngle: 180,
            center: ['75%', '70%'],
            radius: ['75%', '100%'],
            avoidLabelOverlap: false, labelLine: { show: false },
            itemStyle: {
                normal: {
                    color: chartColors.aprovechamiento
                }
            },

            data: [
                {
                    value: 0, // this.approvechamiento,
                    name: 'Aprovechamiento',
                    tooltip: { show: false },
                    label: {
                        normal: {
                            formatter: function (data) {
                                return parseFloat(data.value).toFixed(2);
                            },
                            show: true,
                            position: 'center',
                            fontSize: 14,
                        }
                    }

                },
                {
                    value: 100, // 100 - this.approvechamiento,
                    name: '100 -Aprovechamiento',
                    //                    itemStyle: { color: this.approvechamiento < 10 ? 'rgba(0,0,0,0.09)' : 'transparent' },
                    label: { show: false },
                    tooltip: {
                        show: true,
                        //                        formatter: 'Aprovechamiento: ' + this.approvechamiento + '%'
                    }
                },
                {
                    value: 100, // 100 - this.approvechamiento,
                    name: '100 -Aprovechamiento',
                    itemStyle: { color: 'transparent' },
                    label: { show: false },
                    tooltip: { show: false }
                }
            ]
        }
    ]
};
