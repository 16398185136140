import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ZeroInjectionInstantResults, ZeroInjectionInstantResultsParams, ZeroInjectionEffectiveResultsParams, ZeroInjectionEffectiveResults, } from './zero-injection.models';
@Injectable({
  providedIn: 'root'
})
export class ZeroInjectionService {
  private _zeroInjectionInstantApi = environment.API_URL + '/api/zeroinjection/instant/GetInstantResults';
  private _zeroInjectionEffectiveApi = environment.API_URL + '/api/zeroinjection/instant/GetEffectiveResults';

  private _instantResults = new BehaviorSubject(<ZeroInjectionInstantResults> null);
  private _effectiveResults = new BehaviorSubject(<ZeroInjectionEffectiveResults> null);

  instantResults: Observable<ZeroInjectionInstantResults> = this._instantResults.asObservable();
  effectiveResults: Observable<ZeroInjectionEffectiveResults> =  this._effectiveResults.asObservable();

  constructor(private http: HttpClient) { }


  getInstantResults(data: ZeroInjectionInstantResultsParams) {
    return this.getZeroInjectionInstantResult(data)
    .subscribe( (results: ZeroInjectionInstantResults) => {
      this._instantResults.next(results);
    });
  }

  private getZeroInjectionInstantResult(data: ZeroInjectionInstantResultsParams): Observable<ZeroInjectionInstantResults> {

    const params = new HttpParams({ fromObject: {
      idInstallation: data.idInstallation.toString(),
      phase: data.phase.toString()
    }} );

    return this.http.get<ZeroInjectionInstantResults>(this._zeroInjectionInstantApi, {params});
  }

  getEffectiveResults(data: ZeroInjectionEffectiveResultsParams) {
    return this.getZeroInjectionEffectiveResult(data)
    .subscribe( (results: ZeroInjectionEffectiveResults) => {
      this._effectiveResults.next(results);
    });
  }

  private getZeroInjectionEffectiveResult(data: ZeroInjectionEffectiveResultsParams): Observable<ZeroInjectionEffectiveResults> {

    const params = new HttpParams({ fromObject: {
      idInstallation: data.idInstallation.toString()
    }} );

    return this.http
      .get<ZeroInjectionEffectiveResults>(this._zeroInjectionEffectiveApi, {params});
  }
}
