import { Component, OnInit } from '@angular/core';
import { NbAuthComponent, NbAuthService } from '@nebular/auth';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent extends NbAuthComponent implements OnInit {
  selectedItem = 'es';
  constructor( auth: NbAuthService, location: Location, public translate: TranslateService) {
    super(auth, location);
    this.translate.use(this.selectedItem);
    localStorage.setItem('language', this.selectedItem);
  }

  ngOnInit() {

  }

}
