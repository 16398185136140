import { Component, OnInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { TranslateService } from '@ngx-translate/core';
import { PVSelfMyConfiguration } from '../../models/PVSelfMyConfiguration.model';
import { PVSelfMyConfigurationService } from '../../services/pvself-my-configuration.service';
import { PVSelfConsumptionTemplatesService } from '../../services/pvself-consumption-templates.service';
import { PVSelfConsumptionTemplate, PVSelfConsumptionTemplateUsage } from '../../models/PVSelfConsumptionTemplate.model';
import { Options } from 'ng5-slider';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PVSelfFieldUbicationType, PVSelfFieldTechnologyType, PVSelfInclinationType } from '../../models/PvSelfCalculation.model';
import { ConsumptionRate } from 'projects/pplus/src/lib/models/consumptionRate.model';
import { Manufacturer } from 'projects/pplus/src/lib/models/manufacturer.model';
import { InverterModel } from 'projects/pplus/src/lib/models/elements/inverterModel.model';
import { PanelModel } from 'projects/pplus/src/lib/models/elements/panelModel.model';
import { MeteorologicalDataService } from 'projects/pplus/src/lib/services/meteorological-data.service';
import { ConsumptionRateService } from 'projects/pplus/src/lib/services/rate.service';
import { InverterModelService } from 'projects/pplus/src/lib/services/elements/inverter-model.service';
import { ManufacturerService } from 'projects/pplus/src/lib/services/manufacturer.service';
import { PanelModelService } from 'projects/pplus/src/lib/services/elements/panel-model.service';
import { ElementType } from 'projects/pplus/src/lib/models/pplus.enums';
import { Entity } from 'projects/pplus/src/lib/models/entity.model';
import { EntitiesService } from 'projects/pplus/src/lib/services/entities.service';

declare const google;
@Component({
  selector: 'app-pvself-my-configuration',
  templateUrl: './pvself-my-configuration.component.html',
  styleUrls: ['./pvself-my-configuration.component.scss']
})
export class PvselfMyConfigurationComponent implements OnInit {
  myConfiguration: PVSelfMyConfiguration;
  meteorologicalData: any[];
  consumptionRates: ConsumptionRate[];
  dailyHabits: Array<string>;
  weeklyHabits: Array<string>;
  monthlyHabits: Array<string>;
  consumptionTemplates: PVSelfConsumptionTemplate[];
  consumptionTemplateUsages: PVSelfConsumptionTemplateUsage[];
  manufacturersInverter: Manufacturer[];
  manufacturersPanel: Manufacturer[];
  inverters: InverterModel[];
  panels: PanelModel[];
  loaderId: string;
  costsUbicationType: PVSelfFieldUbicationType;
  costsTechnology: PVSelfFieldTechnologyType;
  costsInclinationType: PVSelfInclinationType;
  showControls = false;
  _applicationEntity: Entity;


  optionsPotencia: Options = {
    floor: 0,
    ceil: 100
  };
  optionsAcumulacion: Options = {
    floor: 0,
    ceil: 100,
    disabled: false
  };


  //  contractData: any;
  gridOptions;
  gridFieldCostsOptions: any;
  gridFieldCostsOptionsApi;
  gridApi;
  defaultColDef;


  periodicity = [
    { id: 1, name: 'COMMON.ANNUAL' },
    { id: 2, name: 'COMMON.SIXMONTHLY' },
    { id: 3, name: 'COMMON.FOURMONTHLY' },
    { id: 4, name: 'COMMON.QUARTERLY' },
    { id: 5, name: 'COMMON.BIMONTHLY' },
    { id: 6, name: 'COMMON.MONTHLY' },
    { id: 7, name: 'COMMON.FORTNIGHTLY' },
    { id: 8, name: 'COMMON.WEEKLY' },
    { id: 9, name: 'COMMON.DAILY' }
  ];

  columnDefs = [
    { headerName: 'Magnitud', field: 'magnitude', editable: false },
    { headerName: 'Enero', field: 'mes1', editable: true },
    { headerName: 'Febrero', field: 'mes2', editable: true },
    { headerName: 'Marzo', field: 'mes3', editable: true },
    { headerName: 'Abril', field: 'mes4', editable: true },
    { headerName: 'Mayo', field: 'mes5', editable: true },
    { headerName: 'Junio', field: 'mes6', editable: true },
    { headerName: 'Julio', field: 'mes7', editable: true },
    { headerName: 'Agosto', field: 'mes8', editable: true },
    { headerName: 'Septiembre', field: 'mes9', editable: true },
    { headerName: 'Octubre', field: 'mes10', editable: true },
    { headerName: 'Noviembre', field: 'mes11', editable: true },
    { headerName: 'Diciembre', field: 'mes12', editable: true },
  ];


  columnDefsContratcs = [
    { headerName: this.translate.instant('COMMON.PERIOD'), field: 'period' },
    { headerName: this.translate.instant('PVSELF.HIREDPOWER'), field: 'power', editable: true },
    { headerName: this.translate.instant('PVSELF.POWERTERM'), field: 'powerPurchasePrice', editable: true },
    { headerName: this.translate.instant('PVSELF.ENERGYTERM'), field: 'energyPurchasePrice', editable: true },
    { headerName: this.translate.instant('PVSELF.ENERGYSALE'), field: 'energySalePrice', editable: true }
  ];

  columnDefsCosts = [
    { headerName: 'Power', field: 'power', editable: false },
    { headerName: 'Ubication', field: 'ubication', hide: true },
    { headerName: 'Technology', field: 'technology', hide: true },
    { headerName: 'Inclination type', field: 'inclinationType', hide: true },
    { headerName: 'Execution costs', field: 'executionCost', editable: false },
    { headerName: 'Exploitation costs', field: 'exploitationCost', editable: false },
    { headerName: 'Loader costs', field: 'loaderCost', editable: false },
    { headerName: 'HT Connection costs', field: 'htConnetionCost', editable: false },
    { headerName: 'Other costs', field: 'otherCost', editable: false }
  ];


  constructor(private translate: TranslateService,
    private meteorologicalDataService: MeteorologicalDataService,
    private rateService: ConsumptionRateService,
    private templatesService: PVSelfConsumptionTemplatesService,
    private myConfigurationService: PVSelfMyConfigurationService,
    private manufacturerService: ManufacturerService,
    private inverterService: InverterModelService,
    private panelService: PanelModelService,
    private ngxLoaderService: NgxUiLoaderService,
    private entitiesService: EntitiesService) { }

  ngOnInit() {
    this.costsUbicationType = 1;
    this.costsTechnology = 1;
    this.costsInclinationType = 2;
    this.gridOptions = <GridOptions>{};
    this.gridFieldCostsOptions = <GridOptions>{};
    this.showControls = false;

    this.entitiesService.applicationEntity.subscribe(res => {
      this._applicationEntity = res;
      this.getConsumptionRates();
      this.getUserMyConfiguration();
    });



    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);
    this.myConfiguration = new PVSelfMyConfiguration();
    this.ngxLoaderService.startLoader(this.loaderId);

  }


  initGooglePlaces() {
    const input = document.getElementById('googlePlacesInput');
    const autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      this.onPlaceChanged(place);
    });
  }


  onPlaceChanged(place) {
    this.myConfiguration.latitude = place.geometry.location.lat();
    this.myConfiguration.longitude = place.geometry.location.lng();
  }


  setGridFieldCostsFilters() {

    const filterUbication = this.gridFieldCostsOptionsApi.getFilterInstance('ubication');
    const filterUbicationData = { filter: this.costsUbicationType, filterType: 'text', type: 'contains' };
    filterUbication.setModel(filterUbicationData);

    const filterTechnology = this.gridFieldCostsOptionsApi.getFilterInstance('technology');
    const filterTechnologyData = { filter: this.costsTechnology, filterType: 'text', type: 'contains' };
    filterTechnology.setModel(filterTechnologyData);

    if (this.costsTechnology === 1) {
      const filterInclinationType = this.gridFieldCostsOptionsApi.getFilterInstance('inclinationType');
      const filterInclinationTypeData = { filter: this.costsInclinationType, filterType: 'number', type: 'equals' };
      filterInclinationType.setModel(filterInclinationTypeData);
    }

    if (this.costsTechnology === 2) {
      const filterInclinationType = this.gridFieldCostsOptionsApi.getFilterInstance('inclinationType');
      const filterInclinationTypeData = { filter: PVSelfInclinationType.Indifferent, filterType: 'number', type: 'equals' };
      filterInclinationType.setModel(filterInclinationTypeData);
    }


    this.gridFieldCostsOptionsApi.onFilterChanged();
  }


  getUserMyConfiguration() {
    this.myConfigurationService.getEntityMyConfiguration(this._applicationEntity.entityId).subscribe(res => {
      this.myConfiguration = res;
      this.updateMeteorologicalDataFromMyConfiguration();
      this.updateHabits();
      this.getConsumptionTemplates();
      this.getManufacturers();
      this.showControls = true;
      this.ngxLoaderService.stopLoader(this.loaderId);
      this.initGooglePlaces();
    });
  }



  guardarConfiguracion() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.updateMyConfigurationMeteorologicalData();
    this.myConfigurationService.updateActiveUserMyConfiguration(this.myConfiguration).subscribe(res => {
      this.getUserMyConfiguration();
    });
  }




  getConsumptionRates() {
    this.rateService.getList().subscribe(res => {
      this.consumptionRates = res;
    });
  }


  updateMeteorologicalDataFromMyConfiguration() {
    this.meteorologicalData = [];
    this.meteorologicalData.push({
      magnitude: 'Radiación', mes1: this.myConfiguration.radiationData.month1, mes2: this.myConfiguration.radiationData.month2, mes3: this.myConfiguration.radiationData.month3,
      mes4: this.myConfiguration.radiationData.month4, mes5: this.myConfiguration.radiationData.month5, mes6: this.myConfiguration.radiationData.month6,
      mes7: this.myConfiguration.radiationData.month7, mes8: this.myConfiguration.radiationData.month8, mes9: this.myConfiguration.radiationData.month9,
      mes10: this.myConfiguration.radiationData.month10, mes11: this.myConfiguration.radiationData.month11, mes12: this.myConfiguration.radiationData.month12
    });
    this.meteorologicalData.push({
      magnitude: 'Temperatura mínima', mes1: this.myConfiguration.minTempData.month1, mes2: this.myConfiguration.minTempData.month2, mes3: this.myConfiguration.minTempData.month3,
      mes4: this.myConfiguration.minTempData.month4, mes5: this.myConfiguration.minTempData.month5, mes6: this.myConfiguration.minTempData.month6,
      mes7: this.myConfiguration.minTempData.month7, mes8: this.myConfiguration.minTempData.month8, mes9: this.myConfiguration.minTempData.month9,
      mes10: this.myConfiguration.minTempData.month10, mes11: this.myConfiguration.minTempData.month11, mes12: this.myConfiguration.minTempData.month12
    });
    this.meteorologicalData.push({
      magnitude: 'Temperatura máxima', mes1: this.myConfiguration.maxTempData.month1, mes2: this.myConfiguration.maxTempData.month2, mes3: this.myConfiguration.maxTempData.month3,
      mes4: this.myConfiguration.maxTempData.month4, mes5: this.myConfiguration.maxTempData.month5, mes6: this.myConfiguration.maxTempData.month6,
      mes7: this.myConfiguration.maxTempData.month7, mes8: this.myConfiguration.maxTempData.month8, mes9: this.myConfiguration.maxTempData.month9,
      mes10: this.myConfiguration.maxTempData.month10, mes11: this.myConfiguration.maxTempData.month11, mes12: this.myConfiguration.maxTempData.month12
    });
  }


  updateMeteorologicalDataFromServer() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.meteorologicalDataService.get(1, 1, this.myConfiguration.latitude, this.myConfiguration.longitude).subscribe(res => {
      this.myConfiguration.radiationData.month1 = res.month1;
      this.myConfiguration.radiationData.month2 = res.month2;
      this.myConfiguration.radiationData.month3 = res.month3;
      this.myConfiguration.radiationData.month4 = res.month4;
      this.myConfiguration.radiationData.month5 = res.month5;
      this.myConfiguration.radiationData.month6 = res.month6;
      this.myConfiguration.radiationData.month7 = res.month7;
      this.myConfiguration.radiationData.month8 = res.month8;
      this.myConfiguration.radiationData.month9 = res.month9;
      this.myConfiguration.radiationData.month10 = res.month10;
      this.myConfiguration.radiationData.month11 = res.month11;
      this.myConfiguration.radiationData.month12 = res.month12;

      this.updateMeteorologicalDataFromMyConfiguration();
      this.ngxLoaderService.stopLoader(this.loaderId);
    });

    this.meteorologicalDataService.get(6, 1, this.myConfiguration.latitude, this.myConfiguration.longitude).subscribe(res => {
      this.myConfiguration.minTempData.month1 = res.month1;
      this.myConfiguration.minTempData.month2 = res.month2;
      this.myConfiguration.minTempData.month3 = res.month3;
      this.myConfiguration.minTempData.month4 = res.month4;
      this.myConfiguration.minTempData.month5 = res.month5;
      this.myConfiguration.minTempData.month6 = res.month6;
      this.myConfiguration.minTempData.month7 = res.month7;
      this.myConfiguration.minTempData.month8 = res.month8;
      this.myConfiguration.minTempData.month9 = res.month9;
      this.myConfiguration.minTempData.month10 = res.month10;
      this.myConfiguration.minTempData.month11 = res.month11;
      this.myConfiguration.minTempData.month12 = res.month12;

      this.updateMeteorologicalDataFromMyConfiguration();
      this.ngxLoaderService.stopLoader(this.loaderId);
    });

    this.meteorologicalDataService.get(5, 1, this.myConfiguration.latitude, this.myConfiguration.longitude).subscribe(res => {
      this.myConfiguration.maxTempData.month1 = res.month1;
      this.myConfiguration.maxTempData.month2 = res.month2;
      this.myConfiguration.maxTempData.month3 = res.month3;
      this.myConfiguration.maxTempData.month4 = res.month4;
      this.myConfiguration.maxTempData.month5 = res.month5;
      this.myConfiguration.maxTempData.month6 = res.month6;
      this.myConfiguration.maxTempData.month7 = res.month7;
      this.myConfiguration.maxTempData.month8 = res.month8;
      this.myConfiguration.maxTempData.month9 = res.month9;
      this.myConfiguration.maxTempData.month10 = res.month10;
      this.myConfiguration.maxTempData.month11 = res.month11;
      this.myConfiguration.maxTempData.month12 = res.month12;

      this.updateMeteorologicalDataFromMyConfiguration();
      this.ngxLoaderService.stopLoader(this.loaderId);
    });
  }

  updateMyConfigurationMeteorologicalData() {
    this.myConfiguration.radiationData.month1 = this.meteorologicalData[0].mes1;
    this.myConfiguration.radiationData.month2 = this.meteorologicalData[0].mes2;
    this.myConfiguration.radiationData.month3 = this.meteorologicalData[0].mes3;
    this.myConfiguration.radiationData.month4 = this.meteorologicalData[0].mes4;
    this.myConfiguration.radiationData.month5 = this.meteorologicalData[0].mes5;
    this.myConfiguration.radiationData.month6 = this.meteorologicalData[0].mes6;
    this.myConfiguration.radiationData.month7 = this.meteorologicalData[0].mes7;
    this.myConfiguration.radiationData.month8 = this.meteorologicalData[0].mes8;
    this.myConfiguration.radiationData.month9 = this.meteorologicalData[0].mes9;
    this.myConfiguration.radiationData.month10 = this.meteorologicalData[0].mes10;
    this.myConfiguration.radiationData.month11 = this.meteorologicalData[0].mes11;
    this.myConfiguration.radiationData.month12 = this.meteorologicalData[0].mes12;

    this.myConfiguration.minTempData.month1 = this.meteorologicalData[1].mes1;
    this.myConfiguration.minTempData.month2 = this.meteorologicalData[1].mes2;
    this.myConfiguration.minTempData.month3 = this.meteorologicalData[1].mes3;
    this.myConfiguration.minTempData.month4 = this.meteorologicalData[1].mes4;
    this.myConfiguration.minTempData.month5 = this.meteorologicalData[1].mes5;
    this.myConfiguration.minTempData.month6 = this.meteorologicalData[1].mes6;
    this.myConfiguration.minTempData.month7 = this.meteorologicalData[1].mes7;
    this.myConfiguration.minTempData.month8 = this.meteorologicalData[1].mes8;
    this.myConfiguration.minTempData.month9 = this.meteorologicalData[1].mes9;
    this.myConfiguration.minTempData.month10 = this.meteorologicalData[1].mes10;
    this.myConfiguration.minTempData.month11 = this.meteorologicalData[1].mes11;
    this.myConfiguration.minTempData.month12 = this.meteorologicalData[1].mes12;

    this.myConfiguration.maxTempData.month1 = this.meteorologicalData[2].mes1;
    this.myConfiguration.maxTempData.month2 = this.meteorologicalData[2].mes2;
    this.myConfiguration.maxTempData.month3 = this.meteorologicalData[2].mes3;
    this.myConfiguration.maxTempData.month4 = this.meteorologicalData[2].mes4;
    this.myConfiguration.maxTempData.month5 = this.meteorologicalData[2].mes5;
    this.myConfiguration.maxTempData.month6 = this.meteorologicalData[2].mes6;
    this.myConfiguration.maxTempData.month7 = this.meteorologicalData[2].mes7;
    this.myConfiguration.maxTempData.month8 = this.meteorologicalData[2].mes8;
    this.myConfiguration.maxTempData.month9 = this.meteorologicalData[2].mes9;
    this.myConfiguration.maxTempData.month10 = this.meteorologicalData[2].mes10;
    this.myConfiguration.maxTempData.month11 = this.meteorologicalData[2].mes11;
    this.myConfiguration.maxTempData.month12 = this.meteorologicalData[2].mes12;
  }



  updateHabits() {
    this.dailyHabits = [];
    this.weeklyHabits = [];
    this.monthlyHabits = [];

    const daily = this.myConfiguration.consumptionHabits.daily;
    this.dailyHabits = [];
    if ((daily & 0b001) === 0b001) { this.dailyHabits.push('night'); }
    if ((daily & 0b010) === 0b010) { this.dailyHabits.push('afternoon'); }
    if ((daily & 0b100) === 0b100) { this.dailyHabits.push('morning'); }

    const weekly = this.myConfiguration.consumptionHabits.weekly;
    this.weeklyHabits = [];
    if ((weekly & 0b001) === 0b001) { this.weeklyHabits.push('weekend'); }
    if ((weekly & 0b010) === 0b010) { this.weeklyHabits.push('week'); }


    const monthly = this.myConfiguration.consumptionHabits.monthly;
    this.monthlyHabits = [];
    if ((monthly & 0b000000000001) === 0b000000000001) { this.monthlyHabits.push('m12'); }
    if ((monthly & 0b000000000010) === 0b000000000010) { this.monthlyHabits.push('m11'); }
    if ((monthly & 0b000000000100) === 0b000000000100) { this.monthlyHabits.push('m10'); }
    if ((monthly & 0b000000001000) === 0b000000001000) { this.monthlyHabits.push('m9'); }
    if ((monthly & 0b000000010000) === 0b000000010000) { this.monthlyHabits.push('m8'); }
    if ((monthly & 0b000000100000) === 0b000000100000) { this.monthlyHabits.push('m7'); }
    if ((monthly & 0b000001000000) === 0b000001000000) { this.monthlyHabits.push('m6'); }
    if ((monthly & 0b000010000000) === 0b000010000000) { this.monthlyHabits.push('m5'); }
    if ((monthly & 0b000100000000) === 0b000100000000) { this.monthlyHabits.push('m4'); }
    if ((monthly & 0b001000000000) === 0b001000000000) { this.monthlyHabits.push('m3'); }
    if ((monthly & 0b010000000000) === 0b010000000000) { this.monthlyHabits.push('m2'); }
    if ((monthly & 0b100000000000) === 0b100000000000) { this.monthlyHabits.push('m1'); }
  }



  changedDailyHabits() {
    let daily = 0;
    if (this.dailyHabits.indexOf('morning') !== -1) { daily += 1; }
    if (this.dailyHabits.indexOf('afternoon') !== -1) { daily += 2; }
    if (this.dailyHabits.indexOf('night') !== -1) { daily += 4; }
    this.myConfiguration.consumptionHabits.daily = daily;
  }


  changedWeeklyHabits() {
    let weekly = 0;
    if (this.weeklyHabits.indexOf('morning') !== -1) { weekly += 1; }
    if (this.weeklyHabits.indexOf('afternoon') !== -1) { weekly += 2; }
    this.myConfiguration.consumptionHabits.weekly = weekly;
  }



  changedMonthlyHabits() {
    let monthly = 0;
    if (this.monthlyHabits.indexOf('m1') !== -1) { monthly += 0b000000000001; }
    if (this.monthlyHabits.indexOf('m2') !== -1) { monthly += 0b000000000010; }
    if (this.monthlyHabits.indexOf('m3') !== -1) { monthly += 0b000000000100; }
    if (this.monthlyHabits.indexOf('m4') !== -1) { monthly += 0b000000001000; }
    if (this.monthlyHabits.indexOf('m5') !== -1) { monthly += 0b000000010000; }
    if (this.monthlyHabits.indexOf('m6') !== -1) { monthly += 0b000000100000; }
    if (this.monthlyHabits.indexOf('m7') !== -1) { monthly += 0b000001000000; }
    if (this.monthlyHabits.indexOf('m8') !== -1) { monthly += 0b000010000000; }
    if (this.monthlyHabits.indexOf('m9') !== -1) { monthly += 0b000100000000; }
    if (this.monthlyHabits.indexOf('m10') !== -1) { monthly += 0b001000000000; }
    if (this.monthlyHabits.indexOf('m11') !== -1) { monthly += 0b010000000000; }
    if (this.monthlyHabits.indexOf('m12') !== -1) { monthly += 0b100000000000; }
    this.myConfiguration.consumptionHabits.monthly = monthly;
  }



  getConsumptionTemplates() {
    this.templatesService.getList().subscribe(res => {
      this.consumptionTemplates = res;
      this.getUsages(this.myConfiguration.consumptionTemplateId);
    });
  }


  getUsages(idTemplate: any) {
    this.templatesService.getUsagesByTemplate(idTemplate).subscribe(res => {
      this.consumptionTemplateUsages = res;
    });
  }


  getManufacturers() {
    this.manufacturerService.getByElementType(ElementType.Inverter).subscribe(res => {
      this.manufacturersInverter = res;
      this.getInverters();
    });
    this.manufacturerService.getByElementType(ElementType.PhotovoltaicPanel).subscribe(res => {
      this.manufacturersPanel = res;
      this.getPanels();
    });
  }


  getInverters() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.inverterService.getByManufacturer(this.myConfiguration.inverterManufacturerId).subscribe(res => {
      this.inverters = res;
      this.ngxLoaderService.stopLoader(this.loaderId);
    },
      err => {
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
  }


  getPanels() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.panelService.getByManufacturer(this.myConfiguration.panelManufacturerId).subscribe(res => {
      this.panels = res;
      this.ngxLoaderService.stopLoader(this.loaderId);
    },
      err => {
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
  }

  updateContratcs(consumptionRateId) {
    this.myConfigurationService.getEntityMyConfigurationContracts(consumptionRateId.value, this._applicationEntity.entityId).subscribe(res => {
      this.myConfiguration.contracts = res;
    });
  }



  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  }



  onFieldCostsGridReady(params: any) {
    this.gridFieldCostsOptionsApi = params.api;
    params.api.sizeColumnsToFit();
    this.setGridFieldCostsFilters();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  }



}
