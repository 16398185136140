import { Component, OnInit, Output, EventEmitter, Input, QueryList, ViewChildren, ViewChild } from '@angular/core';
import { TimePicker, TYPE_TIME } from './time-picker.model';
import { OverlayConfig, Overlay, CdkOverlayOrigin, OverlayRef, ConnectedPositionStrategy, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { Portal, TemplatePortalDirective, CdkPortal, ComponentPortal } from '@angular/cdk/portal';

@Component({
  selector: 'pplus-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Input() seconds = false;
  @Input() compare = true;
  @Output() time = new EventEmitter();

  @ViewChild('cdkOverlayOrigin', {static: false}) cdkOverlayOrigin: CdkOverlayOrigin;
  @ViewChildren(CdkPortal) templatePortals: QueryList<Portal<any>>;

  _time: TimePicker;
  _compareTime: TimePicker;

  constructor(private overlay: Overlay, private overlayPositionBuilder: OverlayPositionBuilder) { }

  ngOnInit() {
  }

  openTimePicker(): void {
    const strategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.cdkOverlayOrigin.elementRef)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]).withLockedPosition(true);

    const config = new OverlayConfig(
      {
        hasBackdrop: true,
        backdropClass: 'backdrop-class',
        positionStrategy: strategy,
        scrollStrategy: this.overlay.scrollStrategies.reposition()
      }
    );
    const overlayRef = this.overlay.create(config);

    overlayRef.attach(this.templatePortals.first);
    overlayRef.backdropClick().subscribe(() => overlayRef.detach());
  }

  calculateTime(): void {
    const from = new Date();
    from.setHours(this._time.hours);
    from.setMinutes(this._time.minutes);
    from.setSeconds(this._time.seconds);

    const to = new Date();
    to.setHours(this._compareTime.hours);
    to.setMinutes(this._compareTime.minutes);
    to.setSeconds(this._compareTime.seconds);

    const range = {
      from: from,
      to: to
    };
    this.time.emit(range);
  }

  setTime(time: TimePicker): void {
    this._time = time;
  }

  setCompareTime(time: TimePicker): void {
    this._compareTime = time;
  }
}
