import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'lib-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.css']
})
export class TermsDialogComponent {

  title: string = 'Terms & Conditions';
  
  constructor(protected ref : NbDialogRef<TermsDialogComponent>) { }

  dismiss(){
    this.ref.close();
  }
  
}
