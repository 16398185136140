import { map } from 'rxjs/operators';
import { Component, OnInit, EventEmitter, Output, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { IUser, UserContacts, UserFinancials } from '../user.interface';
import { UserService } from '../user.service';
import { forkJoin, Observable } from 'rxjs';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { EntitiesService } from '../../entities/entities.service';
import { UserGroupService } from '../../user-group/user-group.service';
import { AdminService } from '../../admin.service';
import { UserDetailsService } from './user-details.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateData = new EventEmitter();

  data: IUser = <IUser>{};
  formData: DynamicForm[];
  form: FormGroup;

  constructor(
    private dataRow: RowDataService,
    private dialog: NbDialogService,
    private userService: UserService,
    private userGroupService: UserGroupService,
    private entityService: EntitiesService,
    private adminService: AdminService,
    private userDetailsService: UserDetailsService,
  ) {
    this.formData = this.userDetailsService.getDynamicForm();
  }

  generateForm(data: DynamicForm[]) {
    const fieldsCtrls = {};
    data.forEach(item => {
      const fields = item.fields;
      fields.forEach(f => {
        fieldsCtrls[f.name] = new FormControl(f.value || '', f.validators);
      });
    });
    this.form = new FormGroup(fieldsCtrls);
  }

  ngOnInit() {
    forkJoin([
      this.userGroupService.getUserGroupsList(),
      this.entityService.getEntitiesList(),
    ]).subscribe(([userGroups, entities]) => {
      this.formData.map(dynamicForm => {
        const groupUsersField = dynamicForm.fields.find(f => f.name === 'groupUsers');
        if (groupUsersField) {
          groupUsersField.options = [];
          userGroups.map(ug => groupUsersField.options.push({
            value: ug.userGroupId,
            label: ug.name,
          }));
        }
        const entitiesField = dynamicForm.fields.find(f => f.name === 'entities');
        if (entitiesField) {
          entitiesField.options = [];
          entities.map(e => entitiesField.options.push({
            value: e.entityId,
            label: e.code,
          }));
        }
      });
      this.generateForm(this.formData);
    });

    this.dataRow.currentRowData.pipe(map(o => {
      const user = <IUser>o;
      this.formData = this.userDetailsService.getDynamicForm(
        user.userContacts ? user.userContacts.length : 0,
        user.userFinancials ? user.userFinancials.length : 0,
      );
      this.formData.forEach(data => {
        data.fields.map(field => {
          if (field.name === 'groupUsers') {
            const usersGroups: any[] = o['userGroupMembers'];
            const usersId: number[] = [];
            if (usersGroups && usersGroups.length) {
              usersGroups.forEach(el => usersId.push(el.userGroupId));
            }
            field.value = usersId;
          } else if (field.name === 'entities') {
            const entities: any[] = o['entityUsers'];
            const entityIds: number[] = [];
            if (entities && entities.length) {
              entities.forEach(el => entityIds.push(el.entityId));
            }
            field.value = entityIds;
          } else {
            field.value = o[field.name];
          }
        });
      });
      return o;
    })).subscribe(data => {
      this.data = <IUser>data;
      this.generateForm(this.formData);
    });
  }

  onSubmit() {
    if (this.form.valid) {
      if (!this.form.value.password) {
        this.adminService.showToast('danger', 'La contraseña es obligatoria.' , 'Error de validación');
        return;
      }
      const dataUser: IUser = {
        userId: null,
        fullName: this.form.value.fullName,
        notificationEmail: this.form.value.notificationEmail,
        activated: Boolean(this.form.value.activated),
        deleted: false,
        password: this.form.value.password,
        language: this.form.value.language,
        timeZone: this.form.value.timeZone,
        userName: this.form.value.userName,
        userGroupMembers: this.form.value.groupUsers,
        userContacts: [],
        aspNetUser: {
          userName: this.form.value.userName,
          activated: this.form.value.activated,
          fullName: this.form.value.fullName,
        },
        userFinancials: [],
      };
      for (let i = 0; i < 1; i++) {
        dataUser.userContacts.push({
          nameContact: this.form.value['nameContact' + i],
          surnameContact: this.form.value['surnameContact' + i],
          movil: this.form.value['movil' + i],
          email: this.form.value['email' + i],
          userContactId: null,
          userId: null,
          country: this.form.value['country' + i],
          state: this.form.value['state' + i],
          city: this.form.value['city' + i],
          postalAddress: this.form.value['postalAddress' + i],
          postCode: this.form.value['postCode' + i],
          telephone: this.form.value['telephone' + i],
          professionalActivity: this.form.value['professionalActivity' + i],
          professionalTitle: this.form.value['professionalTitle' + i],
          department: this.form.value['department' + i],
          webPage: this.form.value['webPage' + i],
          comment: this.form.value['comment' + i],
          contactBy: this.form.value['contactBy' + i],
        });
        dataUser.userFinancials.push({
          userFinancialId: null,
          userId: null,
          businessName: this.form.value['businessName' + i],
          nifCif: this.form.value['nifCif' + i],
          country: this.form.value['countryFinancial' + i],
          state: this.form.value['stateFinancial' + i],
          city: this.form.value['cityFinancial' + i],
          postalAddress: this.form.value['postalAddressFinancial' + i],
          postCode: this.form.value['postCodeFinancial' + i],
          paymentType: this.form.value['paymentType' + i],
          bankAccount: this.form.value['bankAccount' + i],
        });
      }
      this.userService.createUser(dataUser).subscribe(userId => {
        if (userId != null) {
          this.form.patchValue({userId: userId});
          this.data.userId = userId;
          this.userService.getUser(userId).subscribe(res => this.data = <IUser>res);
          const serviceCalls: Observable<boolean>[] = [];
          if (this.form.value.groupUsers) {
            serviceCalls.push(this.userGroupService.addUserGroupsToUser(userId, this.form.value.groupUsers));
          }
          if (this.form.value.entities) {
            serviceCalls.push(this.entityService.assignUserToEntities(userId, this.form.value.entities));
          }
          for (let i = 0; i < 1; i++) {
            dataUser.userContacts[i].userId = userId;
            dataUser.userFinancials[i].userId = userId;
            serviceCalls.push(
              this.userService.createOrUpdateContact(dataUser.userContacts[i]),
              this.userService.createOrUpdateFinancial(dataUser.userFinancials[i]),
            );
          }
          forkJoin(serviceCalls).subscribe(success => {
            if (success.every(b => b === true)) {
              this.adminService.showToast('success',
                  'Se ha creado el nuevo usuario: ' + this.form.value.fullName,
                  'Elemento creado');
              this.updateData.emit(true);
            } else {
              this.adminService.showToast('danger',
                  'Se ha producido un error al crear el nuevo usuario:' + this.form.value.fullName,
                  'Error');
            }
          });
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al crear el nuevo usuario:' + this.form.value.fullName,
              'Error');
        }
      });
    } else {
      this.adminService.showFormValidationErrors(this.form, this.formData);
    }
  }

  update() {
    const data = this.form.value;
    let userProfileId = null;
    let userEntityId = null;

    if (this.data.userProfiles && this.data.userProfiles.length > 0) {
      if (this.data.userProfiles[0].userProfileId != null) {
        userProfileId = this.data.userProfiles[0].userProfileId;
      }
      if (this.data.userProfiles[0].entityId != null) {
        userEntityId = this.data.userProfiles[0].entityId;
      }
    }
    if (this.data.userId != null) {
      if (this.form.valid) {
        const dataUser: IUser = {
          userId: this.data.userId,
          fullName: data.fullName,
          deleted: false,
          notificationEmail: data.notificationEmail,
          activated: data.activated,
          password: data.password,
          language: data.language,
          timeZone: data.timeZone,
          userName: data.userName,
          userGroupMembers: data.groupUsers,
          userContacts: [],
          aspNetUser: {
            userName: data.userName,
            activated: data.activated,
            fullName: data.fullName,
          },
          userFinancials: [],
          userProfiles: [
            {
              userProfileId: userProfileId,
              entityId: userEntityId,
              activated: true,
              comment: data.comment,
              professionalActivity: data.professionalActivity,
              professionalTitle: data.professionalTitle,
              webPage: data.webPage,
              userId: this.data.userId,
              contactBy: data.contacBy,
              department: data.department,
              notificationEmail: data.notificationEmail,
              name: data.fullName,
            }
          ]
        };
        const serviceCalls: Observable<boolean>[] = [
          this.userService.updateUser(dataUser),
        ];
        if (data.groupUsers) {
          this.userGroupService.addUserGroupsToUser(this.data.userId, data.groupUsers);
        }
        if (data.entities) {
          this.entityService.assignUserToEntities(this.data.userId, data.entities);
        }
        if (this.data.userContacts) {
          for (let i = 0; i < this.data.userContacts.length; i++) {
            dataUser.userContacts.push({
              nameContact: this.form.value['nameContact' + i],
              surnameContact: this.form.value['surnameContact' + i],
              movil: this.form.value['movil' + i],
              email: this.form.value['email' + i],
              userContactId: this.data.userContacts[i].userContactId != null ? this.data.userContacts[i].userContactId : null,
              userId: this.data.userId,
              country: data['country' + i],
              state: data['state' + i],
              city: data['city' + i],
              postalAddress: data['postalAddress' + i],
              postCode: data['postCode' + i],
              telephone: data['telephone' + i],
              professionalActivity: data['professionalActivity' + i],
              professionalTitle: data['professionalTitle' + i],
              department: data['department' + i],
              webPage: data['webPage' + i],
              comment: data['comment' + i],
              contactBy: data['contactBy' + i],
            });
            serviceCalls.push(this.userService.createOrUpdateContact(dataUser.userContacts[i]));
          }
        }
        if (this.data.userFinancials) {
          for (let i = 0; i < this.data.userFinancials.length; i++) {
            dataUser.userFinancials.push({
              userFinancialId: this.data.userFinancials[i].userFinancialId != null ? this.data.userFinancials[i].userFinancialId : null,
              userId: this.data.userId,
              businessName: data['businessName' + i],
              nifCif: data['nifCif' + i],
              country: data['countryFinancial' + i],
              state: data['stateFinancial' + i],
              city: data['cityFinancial' + i],
              postalAddress: data['postalAddressFinancial' + i],
              postCode: data['postCodeFinancial' + i],
              paymentType: data['paymentType' + i],
              bankAccount: data['bankAccount' + i],
            });
            serviceCalls.push(this.userService.createOrUpdateFinancial(dataUser.userFinancials[i]));
          }
        }
        forkJoin(serviceCalls).subscribe(success => {
          if (success.every(b => b === true)) {
            this.adminService.showToast('success',
                'Se ha actualizado el usuario: ' + data.fullName,
                'Elemento actualizado');
            this.updateData.emit();
          } else {
            this.adminService.showToast('danger',
                'Se ha producido un error al actualizar el usuario: ' + data.fullName,
                'Error');
          }
        });
      } else {
        this.adminService.showFormValidationErrors(this.form, this.formData);
      }
    } else {
      this.onSubmit();
    }
  }

  delete() {
    const userId = this.form.controls['userId'].value;
    const fullName = this.form.controls['fullName'].value;
    this.userService.delete(userId).subscribe(success => {
      if (success) {
        this.adminService.showToast('success', 'Se ha borrado el usuario: ' + fullName, 'Elemento eliminado');
        this.updateData.emit();
        this.clearData();
      } else {
        this.adminService.showToast('danger', 'Se ha producido un error al borrar el usuario: ' + fullName, 'Error');
      }
    });
  }

  close() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  copy() {
    this.form.patchValue({
      userId: null,
      userName: null,
      bankAccount: null,
      creationDate: null,
      deletedeletionDate: null,
      entities: null,
    });
    this.data.userId = null;
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.userId == null) {
      this.adminService.showToast('info', 'No hay ningún usuario seleccionado', 'Info');
    } else {
      this.dialog.open(dialog, { context: '¿Desea eliminar el usuario?', hasScroll: false });
    }
  }

  clearData() {
    this.data = <IUser>{};
    this.form.reset();
  }

  deleteContact(index: number) {
    if (this.data.userContacts && this.data.userContacts[index]) {
      if (this.data.userContacts[index].userContactId == null) {
        this.data.userContacts.splice(index);
        this.dataRow.updateRowData(this.data);
      } else
      this.userService.deleteContact(this.data.userContacts[index].userContactId).subscribe(success => {
        if (success) {
          this.adminService.showToast('success',
              'Se ha borrado el contacto ' + (index + 1) + '.',
              'Contacto eliminado');
          this.updateData.emit();
        } else {
          this.adminService.showToast('danger',
              'Error al borrar el contacto ' + (index + 1) + '.',
              'Error');
        }
      });
    }
  }

  addContact() {
    if (this.data.userContacts) {
      this.data.userContacts.push(<UserContacts>{userId: this.data.userId});
      this.dataRow.updateRowData(this.data);
    }
  }

  deleteFinancial(index: number) {
    if (this.data.userFinancials && this.data.userFinancials[index]) {
      if (this.data.userFinancials[index].userFinancialId == null) {
        this.data.userFinancials.splice(index);
        this.dataRow.updateRowData(this.data);
      } else
      this.userService.deleteFinancial(this.data.userFinancials[index].userFinancialId).subscribe(success => {
        if (success) {
          this.adminService.showToast('success',
              'Se ha borrado el financiero ' + (index + 1) + '.',
              'Financiero eliminado');
          this.updateData.emit();
        } else {
          this.adminService.showToast('danger', 'Error al borrar el financiero ' + (index + 1) + '.', 'Error');
        }
      });
    }
  }

  addFinancial() {
    if (this.data.userFinancials) {
      this.data.userFinancials.push(<UserFinancials>{userId: this.data.userId});
      this.dataRow.updateRowData(this.data);
    }
  }

}
