import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { IInstallationMeter } from 'projects/pplus/src/lib/interfaces/interfaces';
import { GridApi, ColumnApi, GridOptions, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';
import { AgBoolFilterComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-bool-filter/ag-bool-filter.component';
import { AgBoolFloatingFilterComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-bool-filter/ag-bool-floating-filter/ag-bool-floating-filter.component';
import { INSTALLATION_METERS_DEFAULT_COL_DEF, INSTALLATION_METERS_COL_DEFS } from './installation-meters.model';
import { InstallationMetersService } from './installation-meters.service';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NbDialogService } from '@nebular/theme';
import { AdminService } from '../admin.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'pplus-installation-meters',
  templateUrl: './installation-meters.component.html',
  styleUrls: ['./installation-meters.component.scss']
})
export class InstallationMetersComponent implements OnInit {
  @ViewChild('splitContent', { static: false }) splitContent;
  @ViewChild('agGrid', { read: ElementRef, static: false }) agGridNativeElement: ElementRef;
  @ViewChild('dialog', { read: TemplateRef, static: false }) dialogRef: TemplateRef<unknown>;

  splitOptions = {
    open: false,
    direction: 'horizontal',
    contentSize: 100,
    formSize: 0,
  };

  contextMenu = {
    X: 0,
    Y: 0,
    state: false,
  };

  row: IInstallationMeter = <IInstallationMeter>{};

  TitleForm = 'Detalles';
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridOptions: GridOptions = {
    defaultColDef: INSTALLATION_METERS_DEFAULT_COL_DEF,
    columnDefs: INSTALLATION_METERS_COL_DEFS,
    rowModelType: 'infinite',
    rowSelection: 'multiple',
    frameworkComponents: {
      checkboxComponent: AgCheckboxComponent,
      boolFilter: AgBoolFilterComponent,
      boolFloatingFilter: AgBoolFloatingFilterComponent,
    },
    floatingFilter: true,
    accentedSort: true,
  };

  private agDataSource: IDatasource = {
    rowCount: null,
    getRows: (rowParams: IGetRowsParams) => this.installationMetersService.getInfiniteScroll(
      rowParams.startRow, rowParams.endRow, rowParams.sortModel, rowParams.filterModel
    ).subscribe(res => rowParams.successCallback(this.processFields(res.allInstallationMeters), res.lastRow))
  };

  constructor(
    private installationMetersService: InstallationMetersService,
    private rowDataService: RowDataService,
    private breakpointObserver: BreakpointObserver,
    private nbDialogService: NbDialogService,
    private adminService: AdminService,
  ) {}

  ngOnInit() {}

  private processFields(installationMeters: IInstallationMeter[]) {
    for (const installationMeter of installationMeters) {
      if (this.row && this.row.installationMeterId === installationMeter.installationMeterId) {
        this.row = <IInstallationMeter>JSON.parse(JSON.stringify(installationMeter));
        this.rowDataService.updateRowData(this.row);
      }
    }
    return installationMeters;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', () => setTimeout(() => params.api.sizeColumnsToFit()));
    if (this.agGridNativeElement) {
      this.agGridNativeElement.nativeElement.addEventListener('contextmenu', (event: any) => {
        event.preventDefault();
      });
    }
    this.gridOptions.api.setDatasource(this.agDataSource);
  }

  onGridRowClicked(row: any) {
    this.row = <IInstallationMeter>JSON.parse(JSON.stringify(row.data));
    this.rowDataService.updateRowData(this.row);
  }

  onCellContextMenu(event: any) {
    event.event.preventDefault();
    const e = event.event;
    let posx = 0;
    let posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft +
      document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop +
      document.documentElement.scrollTop;
    }
    this.contextMenu = {
      X: posx,
      Y: posy,
      state: true
    };
  }

  rowDoubleClicked(event: any) {
    this.openSplit();
  }

  openSplit() {
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe( res => {
      if (res.matches) {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 0,
          formSize: 100,
        };
      } else {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 70,
          formSize: 30,
        };
      }
    });
  }

  readSplitOptions(event: any) {
    if (event.opt) this.splitOptions = event.opt;
  }

  updateData(isCreation: boolean) {
    if (isCreation) this.row = null;
    this.gridOptions.api.refreshInfiniteCache();
  }

  quickSearch(data: string) {
    if (this.gridApi) this.gridApi.setQuickFilter(data);
  }

  resizeGrid(event: any) {
    if (this.gridApi) this.gridApi.sizeColumnsToFit();
  }

  clearRow() {
    this.row = <IInstallationMeter>{};
    this.rowDataService.updateRowData(this.row);
  }

  generalSearch(value: string) {
    if (value) this.gridOptions.api.setDatasource({
      rowCount: null,
      getRows: (rowParams: IGetRowsParams) => this.installationMetersService.getGeneralFilter(
        value, rowParams.startRow, rowParams.endRow, rowParams.sortModel, rowParams.filterModel
      ).subscribe(res => rowParams.successCallback(this.processFields(res.allInstallationMeters), res.lastRow))
    });
    else this.gridOptions.api.setDatasource(this.agDataSource);
  }

  deleteSelected() {
    const selectedRows = this.gridOptions.api.getSelectedRows();
    if (selectedRows && selectedRows.length) this.nbDialogService.open(
      this.dialogRef, { context: 'Are you sure you want to delete all selected installation meters?' }
    ).onClose.subscribe(accepted => {
      if (accepted) {
        const deleteCalls: Observable<boolean>[] = [];
        for (const row of selectedRows) deleteCalls.push(this.installationMetersService.delete(row.installationMeterId));
        forkJoin(deleteCalls).subscribe(success => {
          if (success.every(b => b === true)) {
            this.adminService.showToast('success', 'All selected installation meters deleted');
          } else {
            this.adminService.showToast('danger', 'One or more installation meters couldn\'t be deleted');
          }
          this.clearRow();
          this.gridOptions.api.refreshInfiniteCache();
          this.gridOptions.api.deselectAll();
        });
      }
    });
  }

}
