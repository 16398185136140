import { Validators } from '@angular/forms';
import { Fields, DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';

const GENERALES: Fields [] = [
  {
    disabled: true,
    name: 'entityId',
    placeholder: 'ID',
    type: 'text',
    value: '',
  },
  {
    name: 'code',
    placeholder: 'CODE',
    type: 'text',
    value: '',
    validators: [
      Validators.required,
      Validators.maxLength(13),
    ]
  },
  {
    name: 'description',
    placeholder: 'DESCRIPTION',
    type: 'text',
    value: '',
    validators: [
      Validators.required,
      Validators.maxLength(100),
    ]
  },
  {
    name: 'parentEntityId',
    label: 'ENTITY PARENT',
    type: 'select',
    value: '',
    options: [],
    validators: [
      Validators.required
    ]
  },
  {
    name: 'entityDomains',
    label: 'DOMAINS',
    type: 'select',
    value: '',
    options: []
  },
  {
    name: 'entityDomainStyles',
    label: 'STYLES',
    type: 'select',
    validators: [
      // Validators.required
    ],
    value: '',
    options: []
  },
  {
    name: 'activated',
    placeholder: 'ACTIVATED',
    type: 'checkbox',
    value: true
  },
  {
    name: 'userGroups',
    label: 'USER GROUPS',
    type: 'select',
    multiple: true,
    searchName: 'userGroupsSearch',
    value: [],
    options: []
  },
];

const USER_CONTACTS_FIELDS: Fields [] = [
  {
    type: 'select',
    name: 'country0',
    label: 'PAÍS',
    value: '',
    options: [
      {
        label: 'España',
        value: 'España'
      },
      {
        label: 'Francia',
        value: 'Francia'
      },
      {
        label: 'Italia',
        value: 'Italia'
      }
    ],
    stage: 'first'
  },
  {
    type: 'select',
    name: 'state0',
    value: '',
    label: 'PROVINCIA / ESTADO',
    options: [
      {
        label: 'Castilla y León',
        value: 'Castilla y León'
      },
      {
        label: 'Madrid',
        value: 'Madrid'
      },
      {
        label: 'Cataluña',
        value: 'Cataluña'
      }
    ],
    stage: 'first'
  },
  {
    type: 'text',
    name: 'city0',
    value: '',
    placeholder: 'POBLACIÓN',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'first'
  },
  {
    type: 'text',
    name: 'fiscalAddress0',
    value: '',
    placeholder: 'DIRECCIÓN FISCAL',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'first'
  },
  {
    type: 'text',
    name: 'postCode0',
    value: '',
    placeholder: 'CÓDIGO POSTAL',
    validators: [
      Validators.maxLength(5),
      Validators.minLength(5)
    ],
    stage: 'first'
  },
  {
    type: 'select',
    name: 'country1',
    label: 'PAÍS',
    value: '',
    options: [
      {
        label: 'España',
        value: 'España'
      },
      {
        label: 'Francia',
        value: 'Francia'
      },
      {
        label: 'Italia',
        value: 'Italia'
      }
    ],
    stage: 'second'
  },
  {
    type: 'select',
    name: 'state1',
    value: '',
    label: 'PROVINCIA / ESTADO',
    options: [
      {
        label: 'Castilla y León',
        value: 'Castilla y León'
      },
      {
        label: 'Madrid',
        value: 'Madrid'
      },
      {
        label: 'Cataluña',
        value: 'Cataluña'
      }
    ],
    stage: 'second'
  },
  {
    type: 'text',
    name: 'city1',
    value: '',
    placeholder: 'POBLACIÓN',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'second'
  },
  {
    type: 'text',
    name: 'fiscalAddress1',
    value: '',
    placeholder: 'DIRECCIÓN FISCAL',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'second'
  },
  {
    type: 'text',
    name: 'postCode1',
    value: '',
    placeholder: 'CÓDIGO POSTAL',
    validators: [
      Validators.maxLength(5),
      Validators.minLength(5)
    ],
    stage: 'second'
  },
  {
    type: 'select',
    name: 'country2',
    label: 'PAÍS',
    value: '',
    options: [
      {
        label: 'España',
        value: 'España'
      },
      {
        label: 'Francia',
        value: 'Francia'
      },
      {
        label: 'Italia',
        value: 'Italia'
      }
    ],
    stage: 'third'
  },
  {
    type: 'select',
    name: 'state2',
    value: '',
    label: 'PROVINCIA / ESTADO',
    options: [
      {
        label: 'Castilla y León',
        value: 'Castilla y León'
      },
      {
        label: 'Madrid',
        value: 'Madrid'
      },
      {
        label: 'Cataluña',
        value: 'Cataluña'
      }
    ],
    stage: 'third'
  },
  {
    type: 'text',
    name: 'city2',
    value: '',
    placeholder: 'POBLACIÓN',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'third'
  },
  {
    type: 'text',
    name: 'fiscalAddress2',
    value: '',
    placeholder: 'DIRECCIÓN FISCAL',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'third'
  },
  {
    type: 'text',
    name: 'postCode2',
    value: '',
    placeholder: 'CÓDIGO POSTAL',
    validators: [
      Validators.maxLength(5),
      Validators.minLength(5)
    ],
    stage: 'third'
  },
];

const USER_FINANCIAL_FIELDS: Fields [] = [
  {
    type: 'text',
    name: 'businessName0',
    value: '',
    placeholder: 'RAZÓN SOCIAL',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'first'
  },
  {
    type: 'text',
    name: 'nifCif0',
    value: '',
    placeholder: 'NIF / CIF',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'first'
  },
  {
    type: 'text',
    name: 'paymentType0',
    value: '',
    placeholder: 'FORMA DE PAGO',
    stage: 'first'
  },
  {
    type: 'text',
    name: 'bankAccount0',
    value: '',
    placeholder: 'Nº CUENTA BANCARIA',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'first'
  },
  {
    type: 'text',
    name: 'businessName1',
    value: '',
    placeholder: 'RAZÓN SOCIAL',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'second'
  },
  {
    type: 'text',
    name: 'nifCif1',
    value: '',
    placeholder: 'NIF / CIF',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'second'
  },
  {
    type: 'text',
    name: 'paymentType1',
    value: '',
    placeholder: 'FORMA DE PAGO',
    stage: 'second'
  },
  {
    type: 'text',
    name: 'bankAccount1',
    value: '',
    placeholder: 'Nº CUENTA BANCARIA',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'second'
  },
  {
    type: 'text',
    name: 'businessName2',
    value: '',
    placeholder: 'RAZÓN SOCIAL',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'third'
  },
  {
    type: 'text',
    name: 'nifCif2',
    value: '',
    placeholder: 'NIF / CIF',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'third'
  },
  {
    type: 'text',
    name: 'paymentType2',
    value: '',
    placeholder: 'FORMA DE PAGO',
    stage: 'third'
  },
  {
    type: 'text',
    name: 'bankAccount2',
    value: '',
    placeholder: 'Nº CUENTA BANCARIA',
    validators: [
      Validators.maxLength(999),
    ],
    stage: 'third'
  },
];

export const ENTITY_FORM: DynamicForm [] = [
  {
    title: 'DATOS GENERALES',
    open: true,
    fields: GENERALES,
  },
  {
    title: 'CONTACTO',
    fields: USER_CONTACTS_FIELDS,
  },
  {
    title: 'FINANCIERO',
    fields: USER_FINANCIAL_FIELDS,
  },
];
