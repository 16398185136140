import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { PVSelfCalculation, PVSelfInstallationField, PVSelfCalculationProducer } from 'src/app/modules/pvself/models/PvSelfCalculation.model';
import { MapComponent } from 'projects/pplus/src/lib/components/map/map.component';
import { SourceMeteorologicalData } from 'projects/pplus/src/lib/models/meteorologicalData.model';
import { PVSelfCalculationService } from 'src/app/modules/pvself/services/pvself-calculation.service';
import { TranslateService } from '@ngx-translate/core';
import { MeteorologicalDataService } from 'projects/pplus/src/lib/services/meteorological-data.service';
import { PVSelfFieldListComponent } from '../pvself-field-list/pvself-field-list.component';
import { PVSelfMyConfigurationService } from 'src/app/modules/pvself/services/pvself-my-configuration.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Entity } from 'projects/pplus/src/lib/models/entity.model';
import { EntitiesService } from 'projects/pplus/src/lib/services/entities.service';

@Component({
  selector: 'pplus-pvself-installation-location',
  templateUrl: './pvself-installation-location.component.html',
  styleUrls: ['./pvself-installation-location.component.scss']
})
export class PVSelfInstallationLocationComponent implements OnInit {
  @Input() tabCreationData: any;
  @ViewChild('map', { static: true }) map: MapComponent;
  @ViewChild('listComponent', { static: true }) listComponent: PVSelfFieldListComponent;

  _calculation: PVSelfCalculation;
  _applicationEntity: Entity;
  selectedField: PVSelfInstallationField;
  loaderId: string;

  initialLatitude: number;
  initialLongitude: number;
  ubication: string;
  polygon: object[];

  sourcesRadiation: SourceMeteorologicalData[];
  sourcesMinTemp: SourceMeteorologicalData[];
  sourcesMaxTemp: SourceMeteorologicalData[];

  selectedSourceRadiation: number;
  selectedSourceMinTemp: number;
  selectedSourceMaxTemp: number;

  pvSelfLocationChartOptions: any;
  locationChartInitOptions = {
    renderer: 'svg',
    width: null,
    height: 400
  };

  constructor(private calculationService: PVSelfCalculationService,
    private translate: TranslateService,
    private myConfigurationService: PVSelfMyConfigurationService,
    private ngxLoaderService: NgxUiLoaderService,
    private meteorologicalDataService: MeteorologicalDataService,
    private entitiesService: EntitiesService) { }

  ngOnInit() {

    this.loaderId = 'loader-' + Math.floor((Math.random() * 1000) + 1);
    this.polygon = [];

    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
      this.convertPolygon(this._calculation.producers[0].installation.fields[0].polygon);
    });

    this.entitiesService.applicationEntity.subscribe(res => {
      this._applicationEntity = res;
      if (this.tabCreationData.tabContentOrigin === 'new') {
        this.createNewPVSelfProducer();
      } else {
        //      this._producer = this.tabCreationData.producer;
      }
    });



    this.initialLatitude = this.tabCreationData.producer.installation.latitude;
    this.initialLongitude = this.tabCreationData.producer.installation.longitude;
    this.ubication = this.tabCreationData.producer.installation.ubication;
    this.initLocationChartOptions();
    this.getMeteorologicalDataSources();
    this.updateMeteorologicalChart();
  }



  createNewPVSelfProducer() {

    this.tabCreationData.producer = new PVSelfCalculationProducer(this._calculation.calculateYear);
    //    this.tabCreationData.producer = this._producer;
    this.myConfigurationService.getEntityMyConfiguration(this._applicationEntity.entityId).subscribe(res => {

      this.tabCreationData.producer.installation.latitude = res.latitude;
      this.tabCreationData.producer.installation.longitude = res.longitude;
      this.tabCreationData.producer.installation.fields[0].automaticPIConfig = res.automaticPIConfig;
      this.tabCreationData.producer.installation.fields[0].inverterId = res.inverterId;
      this.tabCreationData.producer.installation.fields[0].inverterManufacturerId = res.inverterManufacturerId;
      this.tabCreationData.producer.installation.fields[0].numInverterSerials = res.numInverterSerials;
      this.tabCreationData.producer.installation.fields[0].numOfInverters = res.numOfInverters;
      this.tabCreationData.producer.installation.fields[0].numSerialPanels = res.numSerialPanels;
      this.tabCreationData.producer.installation.fields[0].panelId = res.panelId;
      this.tabCreationData.producer.installation.fields[0].panelManufacturerId = res.panelManufacturerId;
      this.tabCreationData.producer.installation.fields[0].inclination = res.inclination;
      this.tabCreationData.producer.installation.fields[0].orientation = res.orientation;
      this.tabCreationData.producer.installation.fields[0].polygon = [];
      this.tabCreationData.producer.producer.installation.fields[0].technology = res.technology;
      this.tabCreationData.producer.installation.gridType = res.gridType;

      this.tabCreationData.producer.installation.radiationData.pVSelfCalculationMeteorologicalDataId = 0;
      this.tabCreationData.producer.installation.radiationData.sourceMeteorologicalDataId = res.radiationData.sourceMeteorologicalDataId;
      this.tabCreationData.producer.installation.radiationData.latitude = res.radiationData.latitude;
      this.tabCreationData.producer.installation.radiationData.longitude = res.radiationData.longitude;
      this.tabCreationData.producer.installation.radiationData.month1 = res.radiationData.month1;
      this.tabCreationData.producer.installation.radiationData.month2 = res.radiationData.month2;
      this.tabCreationData.producer.installation.radiationData.month3 = res.radiationData.month3;
      this.tabCreationData.producer.installation.radiationData.month4 = res.radiationData.month4;
      this.tabCreationData.producer.installation.radiationData.month5 = res.radiationData.month5;
      this.tabCreationData.producer.installation.radiationData.month6 = res.radiationData.month6;
      this.tabCreationData.producer.installation.radiationData.month7 = res.radiationData.month7;
      this.tabCreationData.producer.installation.radiationData.month8 = res.radiationData.month8;
      this.tabCreationData.producer.installation.radiationData.month9 = res.radiationData.month9;
      this.tabCreationData.producer.installation.radiationData.month10 = res.radiationData.month10;
      this.tabCreationData.producer.installation.radiationData.month11 = res.radiationData.month11;
      this.tabCreationData.producer.installation.radiationData.month12 = res.radiationData.month12;
      this.tabCreationData.producer.installation.radiationData.total = res.radiationData.total;

      this.tabCreationData.producer.installation.minTempData.pVSelfCalculationMeteorologicalDataId = 0;
      this.tabCreationData.producer.installation.minTempData.sourceMeteorologicalDataId = res.minTempData.sourceMeteorologicalDataId;
      this.tabCreationData.producer.installation.minTempData.latitude = res.minTempData.latitude;
      this.tabCreationData.producer.installation.minTempData.longitude = res.minTempData.longitude;
      this.tabCreationData.producer.installation.minTempData.month1 = res.minTempData.month1;
      this.tabCreationData.producer.installation.minTempData.month2 = res.minTempData.month2;
      this.tabCreationData.producer.installation.minTempData.month3 = res.minTempData.month3;
      this.tabCreationData.producer.installation.minTempData.month4 = res.minTempData.month4;
      this.tabCreationData.producer.installation.minTempData.month5 = res.minTempData.month5;
      this.tabCreationData.producer.installation.minTempData.month6 = res.minTempData.month6;
      this.tabCreationData.producer.installation.minTempData.month7 = res.minTempData.month7;
      this.tabCreationData.producer.installation.minTempData.month8 = res.minTempData.month8;
      this.tabCreationData.producer.installation.minTempData.month9 = res.minTempData.month9;
      this.tabCreationData.producer.installation.minTempData.month10 = res.minTempData.month10;
      this.tabCreationData.producer.installation.minTempData.month11 = res.minTempData.month11;
      this.tabCreationData.producer.installation.minTempData.month12 = res.minTempData.month12;
      this.tabCreationData.producer.installation.minTempData.total = res.minTempData.total;

      this.tabCreationData.producer.installation.maxTempData.pVSelfCalculationMeteorologicalDataId = 0;
      this.tabCreationData.producer.installation.maxTempData.sourceMeteorologicalDataId = res.maxTempData.sourceMeteorologicalDataId;
      this.tabCreationData.producer.installation.maxTempData.latitude = res.maxTempData.latitude;
      this.tabCreationData.producer.installation.maxTempData.longitude = res.maxTempData.longitude;
      this.tabCreationData.producer.installation.maxTempData.month1 = res.maxTempData.month1;
      this.tabCreationData.producer.installation.maxTempData.month2 = res.maxTempData.month2;
      this.tabCreationData.producer.installation.maxTempData.month3 = res.maxTempData.month3;
      this.tabCreationData.producer.installation.maxTempData.month4 = res.maxTempData.month4;
      this.tabCreationData.producer.installation.maxTempData.month5 = res.maxTempData.month5;
      this.tabCreationData.producer.installation.maxTempData.month6 = res.maxTempData.month6;
      this.tabCreationData.producer.installation.maxTempData.month7 = res.maxTempData.month7;
      this.tabCreationData.producer.installation.maxTempData.month8 = res.maxTempData.month8;
      this.tabCreationData.producer.installation.maxTempData.month9 = res.maxTempData.month9;
      this.tabCreationData.producer.installation.maxTempData.month10 = res.maxTempData.month10;
      this.tabCreationData.producer.installation.maxTempData.month11 = res.maxTempData.month11;
      this.tabCreationData.producer.installation.maxTempData.month12 = res.maxTempData.month12;
      this.tabCreationData.producer.installation.maxTempData.total = res.maxTempData.total;

      //      this.tabCreationData.producer = this._producer;
    });
  }



  initLocationChartOptions() {

    this.pvSelfLocationChartOptions = {
      animation: true,
      legend: {
        x: 'center'
      },
      tooltip: {
        triggerOn: 'mousemove',
        trigger: 'axis'
      },
      toolbox: {
        show: true,
        left: 'center',
        itemSize: 30,
        top: 20,
        feature: {
          dataZoom: {
            yAxisIndex: false,
            title: {
              zoom: 'Zoom',
              back: 'Anular.',
              refresh: 'Refrescar'
            }
          }, restore: {
            show: true,
            title: 'Anular Todo'
          },
          saveAsImage: {
            pixelRatio: 2,
            title: 'Descargar PNG',
          }
        }
      },
      xAxis: {
        type: 'category',
        splitLine: {
          show: true
        }
      },
      yAxis: [{
        name: this.translate.instant('COMMON.MAGNITUDES.TEMPERATURE') + ' [ºC]',
        type: 'value',
        axisTick: {
          inside: true
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          inside: true,
          formatter: '{value}\n'
        },
        z: 10
      }, {
        name: this.translate.instant('COMMON.MAGNITUDES.RADIATION') + '\n[kWh/m²/' + this.translate.instant('COMMON.DAY').toLowerCase() + ']',
        type: 'value',
        axisTick: {
          inside: true
        },
        splitLine: {
          show: false
        },
        axisLabel: {
          inside: true,
          formatter: '{value}\n'
        },
        z: 10
      }],
      grid: {
        top: 50,
        left: 50,
        right: 50,
        bottom: 100
      },
      series: [{
        type: 'line',
        name: 'Rad. [kWh/m²/día]',
        itemStyle: {
          normal: {
            color: '#b942f5'
          }
        },
        data: [],
        yAxisIndex: 1
      }, {
        type: 'bar',
        name: 'Min. Temp. [ºC]',
        itemStyle: {
          normal: {
            color: '#2209b0'
          }
        },
        data: [],
      }, {
        type: 'bar',
        name: 'Max. Temp. [ºC]',
        itemStyle: {
          normal: {
            color: '#d6382d'
          }
        },
        data: []
      }]
    };
  }

  getMeteorologicalDataSources() {
    this.meteorologicalDataService.getSourcesByTypeId(1).subscribe(res => {
      this.sourcesRadiation = res;
      if (this.sourcesRadiation.length > 0) {
        //        this.selectedSourceRadiation = this.sourcesRadiation[0].sourceMeteorologicalDataId;
        this.selectedSourceRadiation = this._calculation.producers[0].installation.radiationData.sourceMeteorologicalDataId;
      }
    });
    this.meteorologicalDataService.getSourcesByTypeId(6).subscribe(res => {
      this.sourcesMinTemp = res;
      if (this.sourcesMinTemp.length > 0) {
        this.selectedSourceMinTemp = this.sourcesMinTemp[0].sourceMeteorologicalDataId;
        //        this.selectedSourceMinTemp = this._calculation.producers[0].installation.minTempData.sourceMeteorologicalDataId;
      }
    });
    this.meteorologicalDataService.getSourcesByTypeId(5).subscribe(res => {
      this.sourcesMaxTemp = res;
      if (this.sourcesMaxTemp.length > 0) {
        //        this.selectedSourceMaxTemp = this.sourcesMaxTemp[0].sourceMeteorologicalDataId;
        this.selectedSourceMaxTemp = this._calculation.producers[0].installation.maxTempData.sourceMeteorologicalDataId;
      }
    });
  }

  updateAllMeteorologicalData() {
    this.updateMeteorologicalData(1);
    this.updateMeteorologicalData(5);
    this.updateMeteorologicalData(6);
  }

  updateMeteorologicalData(TypeId: number) {

    if (TypeId === 1) {
      this.ngxLoaderService.startLoader(this.loaderId);
      this.meteorologicalDataService.get(TypeId, this.selectedSourceRadiation, this.tabCreationData.producer.installation.latitude, this.tabCreationData.producer.installation.longitude).subscribe(res => {

        this.tabCreationData.producer.installation.radiationData.sourceMeteorologicalDataId = res.sourceMeteorologicalDataId;
        this.tabCreationData.producer.installation.radiationData.latitude = res.latitude;
        this.tabCreationData.producer.installation.radiationData.longitude = res.longitude;
        this.tabCreationData.producer.installation.radiationData.month1 = res.month1;
        this.tabCreationData.producer.installation.radiationData.month2 = res.month2;
        this.tabCreationData.producer.installation.radiationData.month3 = res.month3;
        this.tabCreationData.producer.installation.radiationData.month4 = res.month4;
        this.tabCreationData.producer.installation.radiationData.month5 = res.month5;
        this.tabCreationData.producer.installation.radiationData.month6 = res.month6;
        this.tabCreationData.producer.installation.radiationData.month7 = res.month7;
        this.tabCreationData.producer.installation.radiationData.month8 = res.month8;
        this.tabCreationData.producer.installation.radiationData.month9 = res.month9;
        this.tabCreationData.producer.installation.radiationData.month10 = res.month10;
        this.tabCreationData.producer.installation.radiationData.month11 = res.month11;
        this.tabCreationData.producer.installation.radiationData.month12 = res.month12;
        this.tabCreationData.producer.installation.radiationData.total = res.total;

        this.updateMeteorologicalChart();
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
    }
    if (TypeId === 6) {
      this.ngxLoaderService.startLoader(this.loaderId);
      this.meteorologicalDataService.get(TypeId, this.selectedSourceMinTemp, this.tabCreationData.producer.installation.latitude, this.tabCreationData.producer.installation.longitude).subscribe(res => {

        this.tabCreationData.producer.installation.minTempData.sourceMeteorologicalDataId = res.sourceMeteorologicalDataId;
        this.tabCreationData.producer.installation.minTempData.latitude = res.latitude;
        this.tabCreationData.producer.installation.minTempData.longitude = res.longitude;
        this.tabCreationData.producer.installation.minTempData.month1 = res.month1;
        this.tabCreationData.producer.installation.minTempData.month2 = res.month2;
        this.tabCreationData.producer.installation.minTempData.month3 = res.month3;
        this.tabCreationData.producer.installation.minTempData.month4 = res.month4;
        this.tabCreationData.producer.installation.minTempData.month5 = res.month5;
        this.tabCreationData.producer.installation.minTempData.month6 = res.month6;
        this.tabCreationData.producer.installation.minTempData.month7 = res.month7;
        this.tabCreationData.producer.installation.minTempData.month8 = res.month8;
        this.tabCreationData.producer.installation.minTempData.month9 = res.month9;
        this.tabCreationData.producer.installation.minTempData.month10 = res.month10;
        this.tabCreationData.producer.installation.minTempData.month11 = res.month11;
        this.tabCreationData.producer.installation.minTempData.month12 = res.month12;
        this.tabCreationData.producer.installation.minTempData.total = res.total;

        this.updateMeteorologicalChart();
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
    }
    if (TypeId === 5) {
      this.meteorologicalDataService.get(TypeId, this.selectedSourceMaxTemp, this.tabCreationData.producer.installation.latitude, this.tabCreationData.producer.installation.longitude).subscribe(res => {
        this.ngxLoaderService.startLoader(this.loaderId);

        this.tabCreationData.producer.installation.maxTempData.sourceMeteorologicalDataId = res.sourceMeteorologicalDataId;
        this.tabCreationData.producer.installation.maxTempData.latitude = res.latitude;
        this.tabCreationData.producer.installation.maxTempData.longitude = res.longitude;
        this.tabCreationData.producer.installation.maxTempData.month1 = res.month1;
        this.tabCreationData.producer.installation.maxTempData.month2 = res.month2;
        this.tabCreationData.producer.installation.maxTempData.month3 = res.month3;
        this.tabCreationData.producer.installation.maxTempData.month4 = res.month4;
        this.tabCreationData.producer.installation.maxTempData.month5 = res.month5;
        this.tabCreationData.producer.installation.maxTempData.month6 = res.month6;
        this.tabCreationData.producer.installation.maxTempData.month7 = res.month7;
        this.tabCreationData.producer.installation.maxTempData.month8 = res.month8;
        this.tabCreationData.producer.installation.maxTempData.month9 = res.month9;
        this.tabCreationData.producer.installation.maxTempData.month10 = res.month10;
        this.tabCreationData.producer.installation.maxTempData.month11 = res.month11;
        this.tabCreationData.producer.installation.maxTempData.month12 = res.month12;
        this.tabCreationData.producer.installation.maxTempData.total = res.total;

        this.updateMeteorologicalChart();
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
    }
  }



  updateMeteorologicalChart() {

    const graphRadiation = [];
    if (this.tabCreationData.producer.installation.radiationData !== undefined) {
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.JANUARY'), this.tabCreationData.producer.installation.radiationData.month1]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.FEBRUARY'), this.tabCreationData.producer.installation.radiationData.month2]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.MARCH'), this.tabCreationData.producer.installation.radiationData.month3]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.APRIL'), this.tabCreationData.producer.installation.radiationData.month4]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.MAY'), this.tabCreationData.producer.installation.radiationData.month5]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.JUNE'), this.tabCreationData.producer.installation.radiationData.month6]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.JULY'), this.tabCreationData.producer.installation.radiationData.month7]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.AUGUST'), this.tabCreationData.producer.installation.radiationData.month8]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.SEPTEMBER'), this.tabCreationData.producer.installation.radiationData.month9]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.OCTOBER'), this.tabCreationData.producer.installation.radiationData.month10]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.NOVEMBER'), this.tabCreationData.producer.installation.radiationData.month11]);
      graphRadiation.push([this.translate.instant('COMMON.MONTHS.DECEMBER'), this.tabCreationData.producer.installation.radiationData.month12]);
      this.pvSelfLocationChartOptions.series[0].data = graphRadiation;


      const graphMinTemp = [];
      graphMinTemp.length = 0;
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.JANUARY'), this.tabCreationData.producer.installation.minTempData.month1]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.FEBRUARY'), this.tabCreationData.producer.installation.minTempData.month2]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.MARCH'), this.tabCreationData.producer.installation.minTempData.month3]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.APRIL'), this.tabCreationData.producer.installation.minTempData.month4]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.MAY'), this.tabCreationData.producer.installation.minTempData.month5]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.JUNE'), this.tabCreationData.producer.installation.minTempData.month6]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.JULY'), this.tabCreationData.producer.installation.minTempData.month7]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.AUGUST'), this.tabCreationData.producer.installation.minTempData.month8]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.SEPTEMBER'), this.tabCreationData.producer.installation.minTempData.month9]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.OCTOBER'), this.tabCreationData.producer.installation.minTempData.month10]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.NOVEMBER'), this.tabCreationData.producer.installation.minTempData.month11]);
      graphMinTemp.push([this.translate.instant('COMMON.MONTHS.DECEMBER'), this.tabCreationData.producer.installation.minTempData.month12]);
      this.pvSelfLocationChartOptions.series[1].data = graphMinTemp;

      const graphMaxTemp = [];
      graphMaxTemp.length = 0;
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.JANUARY'), this.tabCreationData.producer.installation.maxTempData.month1]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.FEBRUARY'), this.tabCreationData.producer.installation.maxTempData.month2]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.MARCH'), this.tabCreationData.producer.installation.maxTempData.month3]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.APRIL'), this.tabCreationData.producer.installation.maxTempData.month4]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.MAY'), this.tabCreationData.producer.installation.maxTempData.month5]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.JUNE'), this.tabCreationData.producer.installation.maxTempData.month6]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.JULY'), this.tabCreationData.producer.installation.maxTempData.month7]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.AUGUST'), this.tabCreationData.producer.installation.maxTempData.month8]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.SEPTEMBER'), this.tabCreationData.producer.installation.maxTempData.month9]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.OCTOBER'), this.tabCreationData.producer.installation.maxTempData.month10]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.NOVEMBER'), this.tabCreationData.producer.installation.maxTempData.month11]);
      graphMaxTemp.push([this.translate.instant('COMMON.MONTHS.DECEMBER'), this.tabCreationData.producer.installation.maxTempData.month12]);
      this.pvSelfLocationChartOptions.series[2].data = graphMaxTemp;


      this.pvSelfLocationChartOptions = { ...this.pvSelfLocationChartOptions, ...this.pvSelfLocationChartOptions };

    }
  }

  updateLocation(place: { lat: number, lng: number }) {
    this.tabCreationData.producer.installation.latitude = place.lat;
    this.tabCreationData.producer.installation.longitude = place.lng;
    this.tabCreationData.producer.installation.ubication = this.map.input.value;
    this.updateAllMeteorologicalData();
  }

  userChangedMeteoData() {
    this.updateMeteorologicalChart();
  }

  dataDraw(data) {
    this.selectedField.polygon = '';
    for (let i = 0; i < data.path.length; i++) {
      const point = data.path.getAt(i);
      if (i > 0) { this.selectedField.polygon += '#'; }
      this.selectedField.polygon += point.lat() + ';' + point.lng();
    }
    this.selectedField.surface = data.surface;
  }

  disablePlaces(state: boolean): void {
    this.map.statePlaces = state;
  }

  changeDraw(item: PVSelfInstallationField) {
    //    this.map.removeLast();
    if (item) {
      this.map.drawPolygonFromObject(item);
    }
  }

  changeSelected(item: PVSelfInstallationField) {
    this.selectedField = item;
  }

  convertPolygon(pol: string) {
    this.polygon = [];
    pol.split('#').forEach(x => {
      const coordinates = x.split(';');
      if (coordinates.length === 2) {
        const point = { lat: parseFloat(coordinates[0]), lng: parseFloat(coordinates[1]) };
        this.polygon.push(point);
      }
    });
  }

  newDraw(e) {
    /*    const type = e.type;
        let item;
        if (type === 'clone') {
          item = {
            draw: this.list.length,
            editable: false,
            name: this.translate.instant('COMMON.FIELD') + ' ' + (this.list.length + 1),
            polygon: e.item.polygon,
            surface: e.item.surface,
            photovoltaic: e.item.photovoltaic,
          };
        } else {
          item = {
            name: this.translate.instant('COMMON.FIELD') + ' ' + (this.list.length + 1),
            draw: this.list.length,
            editable: false,
            polygon: [],
            surface: 0,
            photovoltaic: null,
          };
        }
        this.list.push(item);
        this.listComponent.changeSelected(this.list[this.list.length - 1]);
        this.map.removeLast();
      */
  }


}
