import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { WeatherStationService } from '../weather-station.service';
import { WeatherStationDataDto } from '../weather-station.models';
import * as moment from 'moment';

@Component({
  selector: 'app-weather-station-instant',
  templateUrl: './weather-station-instant.component.html',
  styleUrls: ['./weather-station-instant.component.scss']
})
export class WeatherStationInstantComponent implements OnInit, OnDestroy {

  private weatherStationSubscription: Subscription;

  lastInstant: WeatherStationDataDto;
  constructor(
    private weatherStationService: WeatherStationService) {
  }

  ngOnInit() {
    this.weatherStationService.weatherStationLastInstant.subscribe( (results: WeatherStationDataDto ) => {
      if (results) {
        console.log(results);
        this.lastInstant = results;
        this.lastInstant.timestamp = moment(this.lastInstant.timestamp).format('DD/MM/YYYY HH:mm');
      } else {
        this.lastInstant = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.weatherStationSubscription.unsubscribe();
  }


}
