export const chart1Opts = {
    legend: {
        data: ['Coste [€]', 'Superficie [m²]'],
        x: 'center',
        selected: null
    },
    tooltip: {
        trigger: 'item',
        textStyle: {
            fontSize: 10,
            width: 500
        },
        formatter: (params) => {
            let values = '';
            values += params.data;
            return values;
        }
    },
    toolbox: {
        show: false,
        feature: {
            magicType: { type: ['line', 'bar'], title: { line: 'Line', bar: 'Bar' } },
            saveAsImage: { title: 'Descargar' }
        }
    },
    xAxis: {
        type: 'category',
        splitLine: { show: true }
    },
    yAxis: [{
        type: 'value'
    }, {
        type: 'value'
    }],
    series: [
        {
            name: 'Coste [€]',
            type: 'line',
            yAxisIndex: 0,
            itemStyle: {
                color: '#ffd825'
            },
            data: []
        },
        {
            name: 'Superficie [m²]',
            type: 'line',
            yAxisIndex: 1,
            itemStyle: {
                color: '#007206'
            },
            data: []
        }
    ]
};
