const totalConsumption = '#105567';
const totalConsumption2 = '#628f9b';

const consumidaRed = '#e95a5c';
const consumidaRed2 = '#f29496';

const autoConsumida = '#3ab0a9';
const autoConsumida2 = '#7bc5c4';


const excedentary = '#9484bd';
const excedentary2 = '#b9afd3';

const netaGenerada = '#c4d886';
const netaGenerada2 = '#d8e5af';


export let chartColors = {

  importeConsumo: '#7c4539',
  importeConsumo2: '#a96547',

  importeAhorro: '#4996ae',
  importeAhorro2: '#5dc9ea',

  compensacion: '#90c068',
  compensacion2: '#6b904b',

  autoConsumo: '#d1c315',
  autoConsumo2: '#928513',


  cobertura: '#d1c315',
  cobertura2: '#dfd65b',
  aprovechamiento: '#90c068',
  aprovechamiento2: '#b0d296',

  consumoTotalLine: totalConsumption,
  consumoTotalArea: totalConsumption2,
  consumoTotalBar: totalConsumption2,

  consumidaRedLine: consumidaRed,
  consumidaRedArea: consumidaRed2,
  consumidaRedBar: consumidaRed2,

  autoConsumidaLine: autoConsumida,
  autoConsumidaArea: autoConsumida2,
  autoConsumidaBar: autoConsumida2,

  excedentariaLine: excedentary,
  excedentariaArea: excedentary2,
  excedentariaBar: excedentary2,

  netaGeneradaLine: netaGenerada,
  netaGeneradaArea: netaGenerada2,
  netaGeneradaBar: netaGenerada2,

  p1: '#83c2c4',
  p1_2: '#a8d4d6',

  p2: '#c1d35f',
  p2_2: '#d4df8f',

  p3: '#ee771d',
  p3_2: '#f3a15f',

  p4: '#5069af',
  p4_2: '#8396c7',

  p5: '#bd69a4',
  p5_2: '#d197c1',

  p6: '#f8e26f',
  p6_2: '#f8eb9b',


  periods: {
    consumoP1: '#105567',
    consumoP2: '#326e7d',
    consumoP3: '#407785',
    consumoP4: '#4a7f8c',
    consumoP5: '#588894',
    consumoP6: '#628f9b',

    consumo_redP1: '#e95a5c',
    consumo_redP2: '#ed7374',
    consumo_redP3: '#ee7b7c',
    consumo_redP4: '#ef8484',
    consumo_redP5: '#f18c8e',
    consumo_redP6: '#f29496',

    autoconsumoP1: '#3ab0a9',
    autoconsumoP2: '#52b6b2',
    autoconsumoP3: '#5bb9b6',
    autoconsumoP4: '#66bdba',
    autoconsumoP5: '#71c1be',
    autoconsumoP6: '#7bc5c4',

    excedenteP1: '#9484bd',
    excedenteP2: '#a397c6',
    excedenteP3: '#a99dca',
    excedenteP4: '#aea3cd',
    excedenteP5: '#b4a9d1',
    excedenteP6: '#b9afd3',

    netaGeneradaP1: '#c4d886',
    netaGeneradaP2: '#cbdc92',
    netaGeneradaP3: '#d0e09f',
    netaGeneradaP4: '#d3e2a4',
    netaGeneradaP5: '#d6e4ab',
    netaGeneradaP6: '#d8e5af',

    billingP1: '#105567',
    billingP2: '#326e7d',
    billingP3: '#407785',
    billingP4: '#4a7f8c',
    billingP5: '#588894',
    billingP6: '#628f9b',

    selfconsumptionsavingP1: '#e95a5c',
    selfconsumptionsavingP2: '#ed7374',
    selfconsumptionsavingP3: '#ee7b7c',
    selfconsumptionsavingP4: '#ef8484',
    selfconsumptionsavingP5: '#f18c8e',
    selfconsumptionsavingP6: '#f29496',

    compensationP1: '#3ab0a9',
    compensationP2: '#52b6b2',
    compensationP3: '#5bb9b6',
    compensationP4: '#66bdba',
    compensationP5: '#71c1be',
    compensationP6: '#7bc5c4',

    energySaleP1: '#9484bd',
    energySaleP2: '#a397c6',
    energySaleP3: '#a99dca',
    energySaleP4: '#aea3cd',
    energySaleP5: '#b4a9d1',
    energySaleP6: '#b9afd3',
  }
};



