import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EntitiesService } from 'projects/pplus/src/lib/services/entities.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [EntitiesService]
})
export class AppComponent {
  constructor(translate: TranslateService) {
    translate.addLangs(['en', 'es']);
    if (localStorage.getItem('language')) {
      translate.setDefaultLang(localStorage.getItem('language'));
      translate.use(localStorage.getItem('language'));
    } else {
        translate.setDefaultLang('en');
        translate.use('en');
        localStorage.setItem('language', 'en');
    }
  }
}
