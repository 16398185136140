import {Component, OnInit, EventEmitter, Output, TemplateRef} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {EntitiesService} from '../entities.service';
import {NbToastrService, NbDialogService} from '@nebular/theme';
import {map} from 'rxjs/operators';
import {IEntity, IEntityContact, IEntityFinancial} from 'projects/pplus/src/lib/interfaces/interfaces';
import {RowDataService} from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import {DynamicForm} from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import {ENTITY_FORM} from './entity-form';
import {forkJoin, Observable} from 'rxjs';
import {StyleService} from '../../styles/style-services.service';
import {DomainsService} from '../../domains/domains.service';
import {UserGroupService} from '../../user-group/user-group.service';
import { AdminService } from '../../admin.service';

@Component({
  selector: 'app-details-entity',
  templateUrl: './details-entity.component.html',
  styleUrls: ['./details-entity.component.scss']
})
export class DetailsEntityComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  formData = ENTITY_FORM;
  formGroup: FormGroup;

  data: IEntity = <IEntity>{};

  constructor(
    private toastService: NbToastrService,
    private dataRow: RowDataService,
    private entityService: EntitiesService,
    private styleService: StyleService,
    private domainService: DomainsService,
    private userGroupService: UserGroupService,
    private dialog: NbDialogService,
    private adminService: AdminService,
  ) {}

  generateForm(data: DynamicForm []) {
    const fieldsCtrls = {};
    data.forEach(item => {
      const fields = item.fields;
      fields.forEach(f => {
        fieldsCtrls[f.name] = new FormControl(f.value || '', f.validators);
      });
    });
    this.formGroup = new FormGroup(fieldsCtrls);
  }

  ngOnInit() {
    forkJoin([
      this.entityService.getEntitiesList(),
      this.styleService.getAllStyles(2),
      this.domainService.getDomainsList(),
      this.userGroupService.getUserGroupsList(),
    ]).subscribe(([entities, styles, domains, userGroups]) => {
      this.formData.map(dynamicForm => {
        const fields = dynamicForm.fields;
        fields.forEach(f => {
          switch (f.name) {
            case 'parentEntityId':
              const optsEntities = [];
              entities.map(o => {
                optsEntities.push({
                  label: o.code,
                  value: o.entityId,
                });
              });
              f.options = optsEntities;
              break;
            case 'entityDomains':
              const optsDomains = [];
              domains.map(o => {
                optsDomains.push({
                  label: o.fqdn,
                  value: o.entityDomainId,
                });
              });
              f.options = optsDomains;
              break;
            case 'entityDomainStyles':
              const optsStyles = [];
              styles.map(o => {
                optsStyles.push({
                  label: o.description ? o.description : 'no-name',
                  value: o.entityDomainStyleId,
                });
              });
              f.options = optsStyles;
              break;
            case 'userGroups':
              const optsUsersGroups = [];
              userGroups.map(o => {
                optsUsersGroups.push({
                  label: o.name,
                  value: o.userGroupId,
                });
              });
              f.options = optsUsersGroups;
              break;
          }
        });
      });
    });

    this.dataRow.currentRowData.pipe(map(o => {
      this.formData.forEach(data => {
        if (data.title === 'CONTACTO') {
          const contacts = o['entityContacts'];
          data.fields.map(field => {
            const i = Number(field.name.slice(field.name.length - 1));
            if (contacts && contacts[i]) {
              field.value = contacts[i][field.name.split(String(i))[0]];
            } else {
              field.value = '';
            }
          });
        } else if (data.title === 'FINANCIERO') {
          const financials = o['entityFinancials'];
          data.fields.map(field => {
            const i = Number(field.name.slice(field.name.length - 1));
            if (financials && financials[i]) {
              field.value = financials[i][field.name.split(String(i))[0]];
            } else {
              field.value = '';
            }
          });
        } else data.fields.map(field => field.value = o[field.name]);
      });
      return o;
    })).subscribe(data => {
      this.data = <IEntity>data;
      this.entityService.getEntityUserGroups(this.data.entityId).subscribe(entityUserGroups => {
        this.formData.map(dynamicForm => {
          const userGroupsField = dynamicForm.fields.find(f => f.name === 'userGroups');
          if (userGroupsField) {
            userGroupsField.value = [];
            entityUserGroups.map(ug => (<number[]>userGroupsField.value).push(ug.userGroupId));
          }
        });
        this.generateForm(this.formData);
      });
    });
  }

  showToast(icon, status, title, msg) {
    this.toastService.show(title, msg, {icon, status});
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
      return;
    }
    const data: IEntity = <IEntity>this.formGroup.value;
    this.entityService.createEntity(data).subscribe(success => {
      if (success) {
        this.showToast('', 'success', 'Se ha creado la nueva entidad: ' + data.code, 'Elemento creado');
        this.clearData();
        this.updateDelete.emit({Type: 'create'});
      } else {
        this.showToast('', 'danger', 'Se ha producido un error al crear la entidad: ' + data.code, 'ERROR');
      }
    });
  }

  copy() {
    if (this.data.entityId == null) {
      this.showToast('', 'info', 'Seleccione una entidad para copiar su información', 'Info');
    } else {
      this.formGroup.setValue(Object.assign(this.data, this.formGroup.value, {
        entityId: null,
        code: '',
      }));
    }
  }

  delete() {
    if (this.data.entityId == null) {
      this.showToast('', 'info', 'Seleccione una entidad', 'Info');
    } else {
      this.entityService.deleteEntity(this.data.entityId).subscribe(success => {
        if (success) {
          this.showToast('', 'success', 'Se ha borrado la entidad: ' + this.data.code, 'Entidad eliminada');
          this.updateDelete.emit({Type: 'delete', entityId: this.data.entityId});
          this.clearData();
        } else {
          this.showToast('', 'danger', 'Se ha producido un error al intentar borrar la entidad: ' + this.data.code, 'Error');
        }
      });
    }
  }

  update() {
    if (this.data.entityId == null) {
      this.showToast('', 'info', 'Seleccione una entidad', 'Info');
    } else if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const updateData = <IEntity>{
        entityId: this.data.entityId,
        code: this.formGroup.value.code,
        description: this.formGroup.value.description,
        domain: this.formGroup.value.domain,
        activated: Boolean(this.formGroup.value.activated),
        parentEntityId: this.formGroup.value.parentEntityId,
      };
      const updateContacts: IEntityContact[] = [];
      const updateFinancials: IEntityFinancial[] = [];
      const serviceCalls: Observable<boolean>[] = [
        this.entityService.updateEntity(this.data.entityId, updateData),
      ];
      for (let i = 0; i < 2; i++) {
        updateContacts.push({
          entityId: this.data.entityId,
          entityContactId: this.data.entityContacts[i] ? this.data.entityContacts[i].entityContactId : null,
          contactName: i === 0 ? 'Postal' : 'Billing',
          country: this.formGroup.value['country' + i],
          state: this.formGroup.value['state' + i],
          city: this.formGroup.value['city' + i],
          fiscalAddress: this.formGroup.value['fiscalAddress' + i],
          postCode: this.formGroup.value['postCode' + i],
        });
        updateFinancials.push({
          entityId: this.data.entityId,
          entityFinancialId: this.data.entityFinancials[i] ? this.data.entityFinancials[i].entityFinancialId : null,
          businessName: this.formGroup.value['businessName' + i],
          nifCif: this.formGroup.value['nifCif' + i],
          paymentType: this.formGroup.value['paymentType' + i],
          bankAccount: this.formGroup.value['bankAccount' + i],
        });
        serviceCalls.push(
          this.entityService.updateOrCreateEntityContact(updateContacts[i]),
          this.entityService.updateOrCreateEntityFinancial(updateFinancials[i]),
        );
      }
      const onSuccess = () => {
        this.showToast('', 'success', 'Se ha actualizado la entidad: ' + this.data.code, 'Entidad actualizada');
        this.updateDelete.emit({Type: 'create'});
      };
      const onError = () => {
        this.showToast('', 'danger', 'Se ha producido un error al intentar actualizar la entidad: ' + this.data.code, 'Error');
      };
      forkJoin(serviceCalls).subscribe(success => {
        if (success.every(b => b)) {
          this.entityService.assignUserGroupsToEntity(this.data.entityId, this.formGroup.value.userGroups).subscribe(
            success => {
              if (success) {
                onSuccess();
              } else {
                onError();
              }
            }
          );
        } else {
          onError();
        }
      });
    }
  }

  exit() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({opt: opt, mode: 'default'});
    this.clearData();
  }

  clearData() {
    this.data = <IEntity>{};
    this.formGroup.reset();
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.entityId == null) {
      this.showToast('', 'info', 'No hay ninguna entidad seleccionada', 'Info');
    } else {
      this.dialog.open(dialog, {context: 'Se va a eliminar la entidad ' + this.data.code, hasScroll: false});
    }
  }

}
