import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF } from "../admin.model";

export const APP_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const APP_COL_DEFS: ColDef[] = [
  {
    field: 'applicationId',
    headerName: 'ID',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'description',
    headerName: 'Description',
  },
  {
    field: 'activated',
    headerName: 'Activated',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkBoxComponent',
  },
  {
    field: 'type',
    headerName: 'Type',
    valueFormatter: function (r) {
      if (r && r.data) return  r.data.type = r.data.applicationType ? r.data.applicationType.name : '';
    },
  },
  {
    field: 'appGroups',
    headerName: 'App Groups',
    valueFormatter: function (r) {
      if (r && r.data) {
        let value = '';
        const groups = r.data.applicationGroupApplications;
        if (groups && groups.length) for (const g of groups) {
          if (value) value += ', ';
          value += g.applicationGroup ? g.applicationGroup.name : '';
        }
        return r.data.appGroups = value;
      }
    },
  },
  {
    field: 'entities',
    headerName: 'Entities',
    valueFormatter: function (r) {
      if (r && r.data) {
        let value = '';
        const entities = r.data.entityApplications;
        if (entities && entities.length) for (const e of entities) {
          if (value) value += ', ';
          value += e.entity ? e.entity.code : '';
        }
        return r.data.entities = value;
      }
    },
  },
];
