import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TabsComponent } from 'projects/pplus/src/lib/components/tabs/tabs.component';

@Injectable({
  providedIn: 'root'
})
export class PVSelfCalculationTabsService {

  private _calculationTabs = new BehaviorSubject<TabsComponent>(new TabsComponent(null, null, null, null, null, null, null));
  calculationTabs = this._calculationTabs.asObservable();

  set(calculationTabs: TabsComponent) {
    this._calculationTabs.next(calculationTabs);
  }

  get() {
    return this._calculationTabs.value;
  }

  constructor() { }
}
