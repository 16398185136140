/*
export let chartSettings3 = {
  profileBarWidth: 6,
  percentageBarWidth: 3,
  lineHeight: 36,
  colors: {
    chart1: {
      profile: '#7cb5ec',
      percentage: '#00aa00'
    },
    chart2: {
      profile: '#181b2a',
      percentage: '#00aa00'
    },
    chart3: {
      profile: '#5bec61',
      percentage: '#00aa00'
    },
    chart4: {
      profile: '#eca024',
      percentage: '#00aa00'
    },
    chart5: {
      profile: '#888eec',
      percentage: '#00aa00'
    },
  }
};
*/

let Meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

export let calendarOptions = {
  cellSize: 16,
  yearLabel: (month: Month) => {
    return {
      show: true,
      formatter: Meses[month],
      position: 'top',
      textStyle: {
        color: '#000',
        fontSize: 10
      }
    };
  },
  yearLabelTextStyle: {
    color: '#000',
    fontSize: 10,
  },
  monthLabel: {
    show: false,
    position: 'start'
  },
  dayLabel: {
    firstDay: 1,
    lineHeight: 0,
    padding: 0,
    nameMap: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
  },
  itemStyle: {},
  splitLine: {},
  secondHalfTop: '60%',

};

export class Calendar {
  static getMonth(month: Month, year: number) {
    const currentYear = year;
    const monthNumber = month + 1;
    const currentMonth = currentYear + '-' + monthNumber.toString();
    return currentMonth;
  }

  static getAllDays(year: number): Date[] {

    const firstDay = new Date(year, 0);
    const nextYear = Number(year) + 1;
    const lastDay = new Date(nextYear, 0);
    const daysOfYear: Date[] = [];
    for (const date = firstDay; date < lastDay; date.setDate(date.getDate() + 1)) {
      const time = new Date(date);
      daysOfYear.push(time);
    }
    return daysOfYear;
  }

  static getAllDaysOfMonth(year: number, month: number): Date[] {
    const firstDay = new Date(year, month, 1);
    const nextMonth = month + 1;
    const lastDay = new Date(year, nextMonth, 1);
    const data = [];
    for (const date = firstDay; date < lastDay; date.setDate(date.getDate() + 1)) {
      const time = new Date(date);
      const isWeekend = (time.getDay() === 0 || time.getDay() === 6);
      const formattedDate = this.formatDate(time);
      data.push([formattedDate, time.getDate(), false, isWeekend, 'Profile', time]);
    }
    return data;
  }

  static formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }
}

export enum Month {
  January,
  February,
  March,
  April,
  May,
  June,
  July,
  August,
  September,
  October,
  November,
  December
}


