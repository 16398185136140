import {Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalType, ISelfViewData, ISelfViewDataEconomico, ISelfViewDto, SelfViewParams} from '../models/selfview.model';
import {SelfViewService} from '../services/selfview-general.service';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {TranslateService} from '@ngx-translate/core';
import {Subject, Subscription, EMPTY, of} from 'rxjs';
import {isUndefined} from 'util';
import * as moment from 'moment';
import { SelfviewGeneralTablesService } from '../services/selfview-general-tables.service';
import { switchMap } from 'rxjs/operators';
import { TableData } from '../models/selfview-main.model';

@Component({
  selector: 'app-selfview-general',
  templateUrl: './selfview-general.component.html',
  styleUrls: ['./selfview-general.component.scss']
})
export class SelfviewGeneralComponent implements OnDestroy, OnInit {
  private clearData: Subject<any> = new Subject<any>();
  private subscriptions: Subscription = new Subscription();
  idInstallation: number;
  comparePeriod;
  dateRange;
  // dateRande: NbCalendarRange<Date>;
  error;
  errortitle;
  datagraf;
  datagraf2;

  dataEs2 = ['Cobertura', 'Aprovechamiento'];
  dataEn2 = ['Coverage', 'Exploitation'];
  dataEs = ['Consumo Total', 'Consumida Red', 'Auto Consumida', 'Excedentaria', 'Neta Generada'];
  dataEn = ['Total Consumption', 'Network Consumed', 'Auto Consumed', 'Excedentary', 'Net Generated'];

  typeDataSource = [
    {
      'id': 1,
      'icon': 'battery_charging_full',
      'viewValue': 'Calculado'
    },
    {
      'id': 4,
      'icon': 'battery_charging_full',
      'viewValue': 'Contador'
    },
    {
      'id': 3,
      'icon': 'battery_charging_full',
      'viewValue': 'Equipo Iny0'
    },
    {
      'id': 2,
      'icon': 'battery_charging_full',
      'viewValue': 'Inversor'
    },
    {
      'id': 5,
      'icon': 'battery_charging_full',
      'viewValue': 'Consumida Red'
    }
  ];

  serieData = {
    'consumoTotal': {
      'value': 0,
      'idType': 1,
      type: this.typeDataSource,
      'compareValue': null
    },
    'consumidaRed': {
      'value': 0,
      'idType': 1,
      'compareValue': null
    },
    'autoConsumida': {
      'value': 0,
      'idType': 1,
      'compareValue': null
    },
    'excedentaria': {
      'value': 0,
      'idType': 1,
      'compareValue': null
    },
    'netaGenerada': {
      'value': 0,
      'idType': 1,
      'compareValue': null
    }
  };

  indeterminate = false;
  type = [false, false, false];
  flipped = [true, true, true];
  globalType = GlobalType.Economic;


  approvechamiento = 0;
  cobertura = 0;

  selfViewData: ISelfViewData;
  selfViewDataEconomico: ISelfViewDataEconomico;

  loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);;
  disabled: boolean;


  static close(popover) {
    popover.hide();
  }

  constructor(private selfViewService: SelfViewService,
              private ngxLoader: NgxUiLoaderService,
              private translate: TranslateService,
              private tableService: SelfviewGeneralTablesService) { }

  ngOnInit() {

    this.selfViewService.params.pipe(
      switchMap( (params: SelfViewParams) => {
          return this.tableService.getMainTableDate(params);
        }
      )).subscribe( (data: TableData) => {
          this.tableService.data = data;
        }
      );
      this.selfViewService.paramsCompare.pipe(
        switchMap( (params: SelfViewParams) => {
          if (!params) {
            return EMPTY;
          } else {
            return this.tableService.getMainTableDate(params);
          }
        })).subscribe( (data: TableData) => {
          this.tableService.dataCompare = data;
        });

    this.selfViewService.globalType.subscribe((res: GlobalType) => {
      if (res !== null) {
        this.globalType = res;
      } else {
        this.indeterminate = true;
      }
    });

    this.selfViewService.dataNormal.subscribe(data => {
      if (data) {
        this.ngxLoader.stopLoader(this.loaderId);
      }
    });

    this.selfViewService.dataComparison.subscribe(data => {
      if (data) {
        this.ngxLoader.stopLoader(this.loaderId);
      }
    });
  }

  setGlobalType($event) {
    const type = $event.currentValue;
    this.indeterminate = false;
    if (type) {
      this.selfViewService.updateGlobalType(GlobalType.Economic);
    } else {
      this.selfViewService.updateGlobalType(GlobalType.Energy);
    }
  }

  setDateRange(range) {
    this.dateRange = range;
    const opt: SelfViewParams = {
      installationId: this.idInstallation,
      from: this.dateRange.startDate,
      to: this.dateRange.endDate,
      consumedEnergyDataSource: this.serieData.consumoTotal.idType,
      generatedEnergyDataSource: this.serieData.netaGenerada.idType,
      excedentaryEnergyDataSource: 1,
      networkConsumedEnergyDataSource: 1,
      selfConsumedEnergyDataSource: 1
    };
    this.ngxLoader.startLoader(this.loaderId);
    this.selfViewService.getData(opt);
  }


  setIdInstallation(id: number) {
    const last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);
    last6Months.setHours(0, 0, 0);
    const today = new Date();
    today.setHours(23, 59, 59);

    if (this.idInstallation !== id) {
      this.idInstallation = id;
      this.dateRange = {
        startDate: last6Months.toUTCString(),
        endDate: today.toUTCString(),
      };

      const opt: SelfViewParams = {
        installationId: this.idInstallation,
        from: this.dateRange.startDate,
        to: this.dateRange.endDate,
        consumedEnergyDataSource: this.serieData.consumoTotal.idType,
        generatedEnergyDataSource: this.serieData.netaGenerada.idType,
        excedentaryEnergyDataSource: 1,
        networkConsumedEnergyDataSource: 1,
        selfConsumedEnergyDataSource: 1
      };
      this.ngxLoader.startLoader(this.loaderId);
      this.selfViewService.getData(opt);
    }
  }

  translateAll() {
    let translations;
    translations = this.typeDataSource.forEach(element => {
      switch (element.viewValue) {
        case 'Calculado':
          this.translate.get('SELFVIEW.TYPEDATASOURCE.CALCULATED').subscribe((res: string) => {
            element.viewValue = res;
          });
          break;
        case 'Contador':
          this.translate.get('SELFVIEW.TYPEDATASOURCE.COUNTER').subscribe((res: string) => {
            element.viewValue = res;
          });
          break;
        case 'Equipo Iny0':
          this.translate.get('SELFVIEW.TYPEDATASOURCE.EQUIPEMENTINY0').subscribe((res: string) => {
            element.viewValue = res;
          });
          break;
        case 'Consumida Red':
          this.translate.get('SELFVIEW.SELECTED.NETCONSUMED').subscribe((res: string) => {
            element.viewValue = res;
          });
          break;
        case 'Inversor':
          this.translate.get('SELFVIEW.TYPEDATASOURCE.INVERTER').subscribe((res: string) => {
            element.viewValue = res;
          });
          break;
        default:
          break;
      }
    });
    this.subscriptions.add(translations);
    translations = this.translate.get('SELFVIEW.HTML.ERROR').subscribe((res: string) => {
      this.error = res;
    });
    this.subscriptions.add(translations);

    translations = this.translate.get('SELFVIEW.HTML.ERRORTITLE').subscribe((res: string) => {
      this.errortitle = res;
    });
    this.subscriptions.add(translations);

    translations = this.translate.get('SELFVIEW.HTML.RANGES').subscribe((res: string) => {
      if (res.toString() === 'rangesEn') {
        this.datagraf = this.dataEn;
        this.datagraf2 = this.dataEn2;
      } else {
        this.datagraf = this.dataEs;
        this.datagraf2 = this.dataEs2;
      }
    });
    this.subscriptions.add(translations);
  }

  getDataCompare(date: { startDate: moment.Moment, endDate: moment.Moment }): void {
    if (!isUndefined(date.startDate) && !isUndefined(date.endDate) && date.startDate !== null && date.endDate !== null) {
      const days = moment(this.dateRange.endDate).diff(moment(this.dateRange.startDate), 'days');
      const startDate = moment(date.startDate).toDate();
      const endDate = moment(date.endDate).add(days, 'days').endOf('day').toDate();

      const opt: SelfViewParams = {
        installationId: this.idInstallation,
        from: startDate,
        to: endDate,
        consumedEnergyDataSource: this.serieData.consumoTotal.idType,
        generatedEnergyDataSource: this.serieData.netaGenerada.idType,
        excedentaryEnergyDataSource: 1,
        networkConsumedEnergyDataSource: 1,
        selfConsumedEnergyDataSource: 1
      };
      this.ngxLoader.startLoader(this.loaderId);
      this.selfViewService.getDataCompare(opt);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

}


