import {Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import {GlobalType, IPeriod, IPeriodicElement, ISelfViewData, ISelfViewDataEconomico, ISelfViewDto} from '../../models/selfview.model';
import * as XLSX from 'xlsx';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {isUndefined} from 'util';
import {SelfViewService} from '../../services/selfview-general.service';
import {chartColors} from '../../selfview.graph.colors';


const ELEMENT_DATA: IPeriodicElement[] = [
  {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
  {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
  {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
  {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
];

@Component({
  selector: 'selfview-maximeter',
  templateUrl: './selfview-maximeter.component.html',
  styleUrls: ['./selfview-maximeter.component.scss']
})
export class ThirdCardComponent implements OnInit {
  @ViewChild('TABLE01', {static: false, read: ElementRef}) table01: ElementRef;
  @ViewChild('TABLE02', {static: false, read: ElementRef}) table02: ElementRef;
  @Output() updateType = new EventEmitter<boolean>();

  dataSource = ELEMENT_DATA;
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  flipped: boolean;

  // Charts
  globalType: GlobalType;

  compareMode = false;
  selfViewData: ISelfViewData;
  selfViewDataEconomico: ISelfViewDataEconomico;

  selfViewDataCompare: ISelfViewData;
  selfViewDataEconomicoCompare: ISelfViewDataEconomico;


  periodChartOptions: any;
  periodChartOptionsNormal: any;
  periodChartOptionsEconomico: any;
  periods: IPeriod[] = [];
  initForPeriodGraph = {
    renderer: 'svg',
    height: 310
  };
  type;
  type1;

  chartOptions;
  // Translations
  datagraf;
  datagraf2;
  ps = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'];
  psData = {
    'P1': true,
    'P2': true,
    'P3': true,
    'P4': true,
    'P5': true,
    'P6': true,
    'P1*': true,
    'P2*': true,
    'P3*': true,
    'P4*': true,
    'P5*': true,
    'P6*': true
  };
  // Maximetro
  MaximeterOption: any;
  dataConsumo = [];
  dataExportacion = [];

  graphInitOptions = {
    renderer: 'svg',
    width: null,
    height: 400
  };


  constructor(private translate: TranslateService, private selfViewService: SelfViewService) {
    this.flipped = true;
  }

  ngOnInit() {
    this.selfViewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;
      // Aquí se cambiara, el tipo desde el global
      this.showChartForGlobalType();

      this.selfViewService.dataComparison.subscribe((data: ISelfViewDto) => {
        this.showChartComparisonForGlobalType(data);
      });
    });


    this.MaximeterOption = {
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const exportacion = params[0].value + 'kW / ' + params[0].data.percentage + '%';
          const consumo = (-1) * params[1].value + 'kW / ' + params[1].data.percentage + '%';
          const value = 'Exportacion: ' + exportacion + '<br> Consumo: ' + consumo;

          return value;
        },
        axisPointer: {
          type: 'shadow'
        }
      },
      legend: {show: false},
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'value',
          name: 'kW',
          nameLocation: 'middle',
          nameTextStyle: {
            padding: [7, 0, 0, 0]
          },
          axisLabel: {
            formatter: function (value) {

              value = value < 0 ? value * -1 : value;

              return value;
            }
          }
        }
      ],
      yAxis: [
        {
          type: 'category',
          axisTick: {show: false},
          data: ['P1', 'P2', 'P3', 'P4', 'P5', 'P6']
        }
      ],
      series: [
        {
          name: 'Exportacion',
          type: 'bar',
          stack: 'X',
          barWidth: 40,
          label: {
            normal: {
              fontWeight: 'bold',
              color: 'black',
              show: true,
              position: 'insideTopLeft',
              fontSize: 11,
              formatter: function (params) {
                const euro = params.data.value;
                const percentage = params.data.percentage;

                // percentage.fontSize = 15;
                const date = params.data.date;
                const fecha = date.toLocaleDateString('es-ES');
                const total = Math.round((euro * 100) / percentage);

                return '[' + percentage + ' %]' + ' \n\n[' + euro + ' kW/' + total + 'kW] ' + fecha;

              },
              // rich: {
              //   a: {
              //     fontWeight: 'bold',
              //     color: 'white',
              //     fontSize: 15,
              //   },
              //   name: {
              //       fontSize: 30,
              //       color: '#fff'
              //   }
              // }
            }
          },
          data: this.dataExportacion,
          color: '#fbe5d6',
          markLine: {
            symbol: 'none',
            label: {show: true, formatter: '100'},
            lineStyle: {
              color: 'black',
              width: 3,
              type: 'solid'
            },
            data: [{
              name: 'Limit',
              xAxis: 100
            },
              {
                name: 'Limit',
                xAxis: -100
              }]
          }
        },
        {
          name: 'Consumo',
          type: 'bar',
          stack: 'X',
          barWidth: 40,
          label: {
            normal: {
              fontWeight: 'bold',
              show: true,
              color: 'black',
              position: 'insideTopRight',
              fontSize: 11,
              formatter: function (params) {
                const euro = -1 * params.data.value;
                const percentage = params.data.percentage;
                const date = params.data.date;
                const fecha = date.toLocaleDateString('es-ES');
                const total = Math.round((euro * 100) / percentage);
                return '[' + percentage + ' %]' + ' \n\n[' + euro + ' kW/' + total + 'kW] ' + fecha;

              }
            }
          },
          data: this.dataConsumo,
          color: '#c5e0b4',
        }
      ]
    };
    this.generateData();
  }

  modifySerie(serieName: string, $event) {
    const state = $event.checked;
    this.psData[serieName] = state;
    this.psData[serieName + '*'] = state;
    this.periodChartOptions.legend.selected = this.psData;
    this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};

    // this.serieData[serieName] = state;
    // //console.log(serieName+ "fajsdfkjñlasdfkjñ");
    // this.selfViewOptions.legend.selected = this.serieData;
    // this.selfViewOptions = {...this.selfViewOptions, ...this.selfViewOptions};
  }

  generateData() {
    for (let i = 1; i < 7; i++) {

      const row = {
        value: Math.round(-this.getRandomArbitrary(20, 160)),
        percentage: Math.round(this.getRandomArbitrary(20, 100)),
        date: this.randomDate(0, 22)
      };
      this.dataConsumo.push(row);
    }
    for (let i = 1; i < 7; i++) {
      const row = {
        value: Math.round(this.getRandomArbitrary(10, 150)),
        percentage: Math.round(this.getRandomArbitrary(10, 100)),
        date: this.randomDate(0, 22),
      };
      this.dataExportacion.push(row);
    }
  }

  randomDate(start, end) {
    const date = new Date(+start + Math.random() * (end - start));
    return date;
  }

  getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  initiatePowerChart() {

    const P1 = [
      this.selfViewData.total_consumo_por_periodo[0],
      this.selfViewData.total_generacion_por_periodo[0],
      this.selfViewData.total_excedente_por_periodo[0],
      this.selfViewData.total_autoconsumo_por_periodo[0],
      this.selfViewData.total_consumo_red_por_periodo[0],
    ];
    const P2 = [
      this.selfViewData.total_consumo_por_periodo[1],
      this.selfViewData.total_generacion_por_periodo[2],
      this.selfViewData.total_excedente_por_periodo[1],
      this.selfViewData.total_autoconsumo_por_periodo[1],
      this.selfViewData.total_consumo_red_por_periodo[1],
    ];
    const P3 = [
      this.selfViewData.total_consumo_por_periodo[2],
      this.selfViewData.total_generacion_por_periodo[2],
      this.selfViewData.total_excedente_por_periodo[2],
      this.selfViewData.total_autoconsumo_por_periodo[2],
      this.selfViewData.total_consumo_red_por_periodo[2],
    ];
    const P4 = [
      this.selfViewData.total_consumo_por_periodo[3],
      this.selfViewData.total_generacion_por_periodo[3],
      this.selfViewData.total_excedente_por_periodo[3],
      this.selfViewData.total_autoconsumo_por_periodo[3],
      this.selfViewData.total_consumo_red_por_periodo[3],
    ];
    const P5 = [
      this.selfViewData.total_consumo_por_periodo[4],
      this.selfViewData.total_generacion_por_periodo[4],
      this.selfViewData.total_excedente_por_periodo[4],
      this.selfViewData.total_autoconsumo_por_periodo[4],
      this.selfViewData.total_consumo_red_por_periodo[4],
    ];
    const P6 = [
      this.selfViewData.total_consumo_por_periodo[5],
      this.selfViewData.total_generacion_por_periodo[5],
      this.selfViewData.total_excedente_por_periodo[5],
      this.selfViewData.total_autoconsumo_por_periodo[5],
      this.selfViewData.total_consumo_red_por_periodo[5],
    ];

    const periodGraphBarWith = 10;
    this.periodChartOptionsNormal = {
      tooltip: {
        confine: false,
        trigger: 'axis',
        position: function (pos, params, dom, rect, size) {
          // tooltip will be fixed on the right if mouse hovering on the left,
          // and on the left if hovering on the right.
          const obj = {top: 60};
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
          return obj;
        },
        axisPointer: {
          type: 'shadow'
        },
        formatter: params => {
          let values = '';
          for (const param of params) {
            const isDefined = typeof (param) === 'undefined';
            if (!isDefined) {

              values += param.seriesName + ': ' + param.value + ' kWh <br>';
            }
          }

          return values;

        }
      },
      legend: {
        show: false,
        data: this.ps,
        selected: {}
      },
      grid: {
        top: '5%',
        left: '15%',
        right: '4%',
        bottom: '15%',
      },
      xAxis: {
        type: 'value',
        name: 'kWh',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: [10, 10, 0, 0]
        },
        axisLabel: {
          show: true,
          formatter: '{value}',
          rotate: 35,
          fontSize: 10
        }
      },
      yAxis: {
        type: 'category',
        data: ['Consumo Total', 'Consumida Red', 'Auto Consumida', 'Excedentaria', 'Neta Generada'],
        axisLabel: {
          rotate: 35,
          fontSize: 10
        }
      },
      series: [
        {
          name: 'P1',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p1},
          barWidth: periodGraphBarWith,
          data: P1
        },
        {
          name: 'P2',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p2},
          barWidth: periodGraphBarWith,
          data: P2
        },
        {
          name: 'P3',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p3},
          barWidth: periodGraphBarWith,
          data: P3
        },
        {
          name: 'P4',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p4},
          barWidth: periodGraphBarWith,
          data: P4
        },
        {
          name: 'P5',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p5},
          barWidth: periodGraphBarWith,
          data: P5
        },
        {
          name: 'P6',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p6},
          barWidth: periodGraphBarWith,
          data: P6
        }
      ]

    };
    this.periodChartOptions = this.periodChartOptionsNormal;
  }

  initiatePowerChartComparison() {

    const P1 = [
      this.selfViewDataCompare.total_consumo_por_periodo[0],
      this.selfViewDataCompare.total_generacion_por_periodo[0],
      this.selfViewDataCompare.total_excedente_por_periodo[0],
      this.selfViewDataCompare.total_autoconsumo_por_periodo[0],
      this.selfViewDataCompare.total_consumo_red_por_periodo[0],
    ];
    const P2 = [
      this.selfViewDataCompare.total_consumo_por_periodo[1],
      this.selfViewDataCompare.total_generacion_por_periodo[2],
      this.selfViewDataCompare.total_excedente_por_periodo[1],
      this.selfViewDataCompare.total_autoconsumo_por_periodo[1],
      this.selfViewDataCompare.total_consumo_red_por_periodo[1],
    ];
    const P3 = [
      this.selfViewDataCompare.total_consumo_por_periodo[2],
      this.selfViewDataCompare.total_generacion_por_periodo[2],
      this.selfViewDataCompare.total_excedente_por_periodo[2],
      this.selfViewDataCompare.total_autoconsumo_por_periodo[2],
      this.selfViewDataCompare.total_consumo_red_por_periodo[2],
    ];
    const P4 = [
      this.selfViewDataCompare.total_consumo_por_periodo[3],
      this.selfViewDataCompare.total_generacion_por_periodo[3],
      this.selfViewDataCompare.total_excedente_por_periodo[3],
      this.selfViewDataCompare.total_autoconsumo_por_periodo[3],
      this.selfViewDataCompare.total_consumo_red_por_periodo[3],
    ];
    const P5 = [
      this.selfViewDataCompare.total_consumo_por_periodo[4],
      this.selfViewDataCompare.total_generacion_por_periodo[4],
      this.selfViewDataCompare.total_excedente_por_periodo[4],
      this.selfViewDataCompare.total_autoconsumo_por_periodo[4],
      this.selfViewDataCompare.total_consumo_red_por_periodo[4],
    ];
    const P6 = [
      this.selfViewDataCompare.total_consumo_por_periodo[5],
      this.selfViewDataCompare.total_generacion_por_periodo[5],
      this.selfViewDataCompare.total_excedente_por_periodo[5],
      this.selfViewDataCompare.total_autoconsumo_por_periodo[5],
      this.selfViewDataCompare.total_consumo_red_por_periodo[5],
    ];

    const periodGraphBarWith = 10;

    const seriesComparison = [
      {
        name: 'P1*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p1_2},
        barWidth: periodGraphBarWith,
        data: P1
      },
      {
        name: 'P2*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p2_2},
        barWidth: periodGraphBarWith,
        data: P2
      },
      {
        name: 'P3*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        barWidth: periodGraphBarWith,
        itemStyle: {color: chartColors.p3_2},
        data: P3
      },
      {
        name: 'P4*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        barWidth: periodGraphBarWith,
        itemStyle: {color: chartColors.p4_2},
        data: P4
      },
      {
        name: 'P5*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        barWidth: periodGraphBarWith,
        itemStyle: {color: chartColors.p5_2},
        data: P5
      },
      {
        name: 'P6*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        barWidth: periodGraphBarWith,
        itemStyle: {color: chartColors.p6_2},
        data: P6
      }
    ];


    if (this.periodChartOptions.series.length > 6) {
      this.periodChartOptions.series = this.periodChartOptions.series.splice(0, this.periodChartOptions.series.length - 6);

    }

    const totalSeries = this.periodChartOptions.series.concat(seriesComparison);
    this.periodChartOptions.series = totalSeries;

    this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};
  }

  initiateEconomicChart() {
    const dataSet: any = [];
    const euroData = this.selfViewDataEconomico.facturacion_por_periodos;
    const percentageData = this.selfViewDataEconomico.porc_facturacion_por_periodos;
    const colors = [chartColors.p1, chartColors.p2, chartColors.p3, chartColors.p4, chartColors.p5, chartColors.p6];
    for (let i = 0; i < euroData.length; i++) {
      const value = Math.round(euroData[i] * 100) / 100;
      const percentage = Math.round(percentageData[i] * 100) / 100;
      const period = `P${i + 1}*`;
      dataSet.push({value, percentage, itemStyle: {color: colors[i]}});
    }
    this.periodChartOptionsEconomico = {
      dataset: {source: dataSet},
      tooltip: {
        trigger: 'axis',
        formatter: function (params) {
          const euro = params[0].data.value;
          const percentage = params[0].data.percentage;
          let euro2;
          let percentage2;
          if (params[1]) {
            euro2 = params[1].data.value;
            percentage2 = params[1].data.percentage;
            return `${params[0].name}: ${euro} € / ${percentage} (%) <br>
                     ${params[1].name}*: ${euro2} € / ${percentage2} (%)`;
          } else {
            return `${params[0].name}: ${euro} € / ${percentage} (%)`;
          }

        },
        axisPointer: {
          type: 'shadow',
        }
      },
      xAxis: {
        show: false,
        axisLine: {show: false},
        axisTick: {show: false},
        splitLine: {show: false}
      },
      yAxis: {
        type: 'category',
        data: ['P6', 'P5', 'P4', 'P3', 'P2', 'P1'],
        axisTick: {show: false},

      },
      grid: {
        top: '0%',
        left: '8%',
        right: '12%',
        bottom: '3%'
      },
      series: [
        {
          type: 'bar',
          label: {
            normal: {
              show: true,
              position: 'right',
              color: 'black',
              fontSize: 10,
              backgroundColor: '#ffe30d',
              borderColor: '#777',
              borderWidth: 1,
              borderRadius: 4,
              formatter: function (params) {
                const euro = params.data.value;
                const percentage = params.data.percentage;
                return euro + ' €' + '/' + percentage + ' %';
              }
            }
          },
          data: dataSet
        }
      ]
    };
    this.periodChartOptions = this.periodChartOptionsEconomico;
  }

  initiateEconomicChartComparison() {
    const dataSet: any = [];
    const euroData = this.selfViewDataEconomicoCompare.facturacion_por_periodos;
    const percentageData = this.selfViewDataEconomicoCompare.porc_facturacion_por_periodos;
    const colors = [chartColors.p1_2, chartColors.p2_2, chartColors.p3_2, chartColors.p4_2, chartColors.p5_2, chartColors.p6_2];

    for (let i = 0; i < euroData.length; i++) {
      const value = Math.round(euroData[i] * 100) / 100;
      const percentage = Math.round(percentageData[i] * 100) / 100;
      dataSet.push({value, percentage, itemStyle: {color: colors[i]}});
    }

    const series = [
      {
        type: 'bar',
        label: {
          normal: {
            show: true,
            position: 'right',
            fontSize: 10,
            color: '#ff4921',
            formatter: function (params) {
              const euro = params.data.value;
              const percentage = params.data.percentage;
              return euro + ' €' + '/' + percentage + ' %';
            }
          }
        },
        data: dataSet
      }
    ];


    if (this.periodChartOptions.series[1] != null) {
      this.periodChartOptions.series.pop();
    }

    const totalSeries = this.periodChartOptions.series.concat(series);
    this.periodChartOptions.series = totalSeries;

    this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};
  }

  initiateMaximetroPower() {
  }

  exportToExcel(type: number) {
    let ws: XLSX.WorkSheet;
    switch (type) {
      case 1:
        ws = XLSX.utils.table_to_sheet(this.table01.nativeElement);
        break;
      case 2:
        ws = XLSX.utils.table_to_sheet(this.table02.nativeElement);
        break;
    }
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Table.xlsx');
  }

  translateAll() {
    this.translate.get('SELFVIEW.HTML.RANGES').subscribe((res: string) => {

    });
  }

  changeType(): void {
    this.updateType.emit(this.type);
    this.type = !this.type;
    this.showChartForLocalType();
  }

  showChartForGlobalType() {

    this.selfViewService.dataNormal.subscribe((data: ISelfViewDto) => {
      if (data !== null) {
        if (data.selfViewDataPower != null || data.selfViewDataEconomico != null) {
          if (this.globalType === GlobalType.Energy) {
            this.selfViewData = data.selfViewDataPower;
            this.type = false;
            this.initiatePowerChart();
          } else if (this.globalType === GlobalType.Economic) {
            this.selfViewDataEconomico = data.selfViewDataEconomico;
            this.type = true;
            this.initiateEconomicChart();
          }
        }

        if (this.compareMode) {
          if (this.selfViewDataCompare !== null && this.globalType === GlobalType.Energy) {
            this.initiatePowerChartComparison();
          } else if (this.selfViewDataEconomicoCompare !== null && this.globalType === GlobalType.Economic) {
            this.initiateEconomicChartComparison();
          }
        }
      }
    });
  }

  showChartForLocalType() {
    this.selfViewService.dataNormal.subscribe((data: ISelfViewDto) => {
      if (data !== null) {
        if (data.selfViewDataPower != null || data.selfViewDataEconomico != null) {
          if (this.type === false) {
            this.selfViewData = data.selfViewDataPower;
            this.initiatePowerChart();
          } else if (this.type === true) {
            this.selfViewDataEconomico = data.selfViewDataEconomico;
            this.initiateEconomicChart();
          }
        }

        if (this.compareMode) {
          if (this.selfViewDataCompare !== null && this.type === false) {
            this.initiatePowerChartComparison();
          } else if (this.selfViewDataEconomicoCompare !== null && this.type === true) {
            this.initiateEconomicChartComparison();
          }
        }
      }
    });
  }

  showChartComparisonForGlobalType(data: ISelfViewDto) {
    if (data !== null) {
      if (data.selfViewDataEconomico !== null || data.selfViewDataPower !== null) {
        this.compareMode = true;
        /* this.psData = {
           'P1': true, 'P2': true, 'P3': true, 'P4': true, 'P5': true, 'P6': true,
           'P1*': true, 'P2*': true, 'P3*': true, 'P4*': true, 'P5*': true, 'P6*': true
         };
         this.periodChartOptions.legend.selected = this.psData;
         this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};*/
        this.selfViewDataEconomicoCompare = data.selfViewDataEconomico;
        this.selfViewDataCompare = data.selfViewDataPower;
        if (data.selfViewDataPower !== null && this.globalType === GlobalType.Energy) {
          this.initiatePowerChartComparison();
        } else if (data.selfViewDataEconomico !== null && this.globalType === GlobalType.Economic) {
          this.initiateEconomicChartComparison();
        }
      }
    } else {
      // console.log(this.compareMode + ' 2xxx');
      if (this.compareMode) {
        // console.log('BORRAR COMPARE');
        this.psData = {
          'P1': true, 'P2': true, 'P3': true, 'P4': true, 'P5': true, 'P6': true,
          'P1*': false, 'P2*': false, 'P3*': false, 'P4*': false, 'P5*': false, 'P6*': false
        };
        this.periodChartOptions.legend.selected = this.psData;
        this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};
        this.compareMode = false;
      } else {
        // console.log('BORRAR no');
      }
      // DAVID
      // DAVID
    }
  }
}
