import { Component, OnInit, Input } from '@angular/core';
import { PVSelfCalculation } from 'src/app/modules/pvself/models/PvSelfCalculation.model';
import { PVSelfCalculationService } from 'src/app/modules/pvself/services/pvself-calculation.service';


@Component({
  selector: 'app-economic-values',
  templateUrl: './economic-values.component.html',
  styleUrls: ['./economic-values.component.scss']
})
export class EconomicValuesComponent implements OnInit {
  _calculation: PVSelfCalculation;
  selectedConsumerIndex: number;

  constructor(private calculationService: PVSelfCalculationService) { }

  ngOnInit() {
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });
    //    this._calculation = this.calculationService.get();
    this.selectedConsumerIndex = 0;
  }
}



