import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbCardModule, NbPopoverModule, NbStepperModule } from '@nebular/theme';
import { MatStepperModule } from '@angular/material/stepper';
import { EconomicValuesComponent } from './components/economic-values/economic-values.component';
import { PreDimensionedComponent } from './pre-dimensioned.component';
import { PreComponent } from './components/pre/pre.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatSliderModule, MatButtonToggleModule, MatInputModule, MatCheckboxModule } from '@angular/material';
import { NgxEchartsModule } from 'ngx-echarts';
import { Ng5SliderModule } from 'ng5-slider';
import { MatDividerModule } from '@angular/material/divider';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { IonRangeSliderModule } from 'ng2-ion-range-slider';
import { PVSelfCriteriaOptionsComponent } from './components/pvself-criteria-options/pvself-criteria-options.component';

@NgModule({
  declarations: [PreDimensionedComponent, EconomicValuesComponent, PreComponent, PVSelfCriteriaOptionsComponent],
  imports: [
    NgxEchartsModule,
    TranslateModule,
    CommonModule,
    NbCardModule,
    MatStepperModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    NbPopoverModule,
    MatSliderModule,
    Ng5SliderModule,
    NbStepperModule,
    MatDividerModule,
    NgxUiLoaderModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    IonRangeSliderModule
  ],
  exports: [
    PreDimensionedComponent
  ]
})
export class PreDimensionedModule { }
