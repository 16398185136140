import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SelfViewActiveEnergyResults } from '../../models/selfview-active.model';
import { SelfViewService } from '../../services/selfview-general.service';
import { COVERAGE_CHART_OPTIONS, initOptionsForPieCharts } from './coverage-chart.config';
import { GlobalType } from '../../models/selfview.model';

@Component({
  selector: 'pplus-selfview-active-coverage',
  templateUrl: './selfview-active-coverage.component.html',
  styleUrls: ['./selfview-active-coverage.component.scss']
})
export class SelfviewActiveCoverageComponent implements OnInit {
  @Output() updateType = new EventEmitter<boolean>();

  flipped: boolean;
  globalType: GlobalType;
//  localType: GlobalType;

  normalData: SelfViewActiveEnergyResults;
  comparisonData: SelfViewActiveEnergyResults;

  coverageChartOptions: any = COVERAGE_CHART_OPTIONS;
  coverageChartInitOptions = initOptionsForPieCharts;

  constructor(private selfviewService: SelfViewService) {
    this.flipped = true;
//    this.localType = GlobalType.Energy;
  }


  ngOnInit() {

    this.selfviewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;

      this.selfviewService.dataActive.subscribe((data: SelfViewActiveEnergyResults) => {
        if (data) {
          this.normalData = data;
          this.updateCoverageChart();
        }
      });

      this.selfviewService.dataActiveCompare.subscribe((data: SelfViewActiveEnergyResults) => {
        if (data) {
          this.comparisonData = data;
          //        this.showChartComparisonForGlobalType();
        }
      });
    });
  }


  updateCoverageChart() {
    this.coverageChartOptions = COVERAGE_CHART_OPTIONS;
    this.coverageChartOptions.series[0].data[0].value = 100 * this.normalData.coverage;
    this.coverageChartOptions.series[0].data[1].value = 100 - (100 * this.normalData.coverage);
    this.coverageChartOptions.series[0].data[2].value = 100 - (100 * this.normalData.coverage);

    this.coverageChartOptions.series[1].data[0].value = 100 * this.normalData.exploitation;
    this.coverageChartOptions.series[1].data[1].value = 100 - this.normalData.exploitation;
    this.coverageChartOptions.series[1].data[2].value = 100 - this.normalData.exploitation;

    this.coverageChartOptions = { ...this.coverageChartOptions, ...this.coverageChartOptions };
  }


  changeLocalType(): void {
    if (this.globalType === GlobalType.Economic) {
      this.globalType = GlobalType.Energy;
    } else {
      this.globalType = GlobalType.Economic;
    }
    //    this.type = !this.type;
    //    this.updateType.emit(this.localType);
    //    this.showChartForLocalType();
    //    this.showChartComparisonForLocalType();
  }


  exportToExcel() {

  }

}
