import { Component, ChangeDetectorRef, Inject, OnDestroy, Injectable, OnInit} from '@angular/core';
import { NbRegisterComponent, NbAuthService, NB_AUTH_OPTIONS, NbAuthResult } from '@nebular/auth';
import { Router } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { TermsDialogComponent } from './terms-dialog/terms-dialog.component';
import { takeWhile } from 'rxjs/operators';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { TranslateService, TranslateParser } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'lib-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})

@Injectable()
export class RegisterComponent extends NbRegisterComponent implements OnDestroy {
  alive = true;
  terms: FormControl;
  fullName: FormControl;
  email: FormControl;
  formGroup: FormGroup;

  params = {
    max: this.getConfigValue('forms.validation.fullName.maxLength'),
    min: this.getConfigValue('forms.validation.fullName.minLength')
  };

  ngOnDestroy() {
    this.alive = false;
  }

  constructor(private translate: TranslateService,
              private _snackBar: MatSnackBar,
              service: NbAuthService,
              @Inject(NB_AUTH_OPTIONS) protected options = {},
              cd: ChangeDetectorRef,
              router: Router,
              private dialogService: NbDialogService) {
                super(service, options, cd, router);

    this.terms = new FormControl('', [
      Validators.requiredTrue,
    ]);

    this.fullName = new FormControl('', [
      Validators.required,
      Validators.maxLength(this.getConfigValue('forms.validation.fullName.maxLength')),
      Validators.minLength(this.getConfigValue('forms.validation.fullName.minLength')),
    ]);

    this.email = new FormControl('', [
      Validators.required,
      Validators.email
    ]);

    this.formGroup = new FormGroup({
      fullName : this.fullName,
      email: this.email,
      terms: this.terms
    });
  }

  open() {
    this.dialogService.open(TermsDialogComponent);
  }

  registerOAuth(type) {
    this.service.authenticate(type)
      .pipe(takeWhile(() => this.alive))
      .subscribe((authResult: NbAuthResult) => {
      });
  }

  register() {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service.register(this.strategy, this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;
      const response = result.getResponse();
      if (result.isSuccess()) {
        this.translate.get('REGISTER.MSG.SEND-EMAIL').subscribe( res => {
          this.messages.push(res);
          this._snackBar.open(`🎉 ${res}`, 'Ok', {
            duration: 10 * 1000,
          });
        });
      } else {
        if (response.error && response.error[0].code === 'DuplicateUserName') {
          this.translate.get('REGISTER.MSG.DUPLICATE').subscribe( res => {
            this.errors.push(res);
            this._snackBar.open(res, 'Ok', {
              duration: 10 * 1000,
            });
          });
        }
      }
      const redirect = result.getRedirect();
      if (redirect) {
        setTimeout(() => {
          return this.router.navigateByUrl(redirect);
        }, this.redirectDelay);
      }
      this.cd.detectChanges();
    });
  }
}