import {environment} from './../../../../../../../src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  constructor(private http: HttpClient) { }
  private _apiFilterTemplate = environment.API_URL + '/api/admin/usertemplate';

  GetUserTemplate(idApplication: number): Observable<any> {
    return this.http
    .get(`${this._apiFilterTemplate}/${idApplication}`)
    .pipe(map((response: Response) => {
      return <any>response;
    }));
  }

  createFilter(data) {
    return this.http
    .post(this._apiFilterTemplate, data);
  }

  updateFilter(templateId, data) {
    return this.http
    .put(`${this._apiFilterTemplate}/${templateId}`, data)
    .pipe( map( (response: Response) => {
      return response;
    }));
  }

  deleteFilter(userTemplateId: number): Observable<any> {
    return this.http
    .delete(`${this._apiFilterTemplate}/${userTemplateId}`)
    .pipe(map((response: Response) => {
      return response;
    }));
  }
}

