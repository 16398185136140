import { Component, OnDestroy, OnInit} from '@angular/core';
import { SelfViewService } from '../services/selfview-general.service';
import { Subscription} from 'rxjs';
import { WeatherStationService } from './weather-station.service';

@Component({
  selector: 'app-selfview-weather-station',
  templateUrl: './selfview-weather-station.component.html',
  styleUrls: ['./selfview-weather-station.component.scss'],
  providers: [SelfViewService]
})
export class SelfviewWeatherStationComponent implements OnDestroy, OnInit {
  private subscriptions: Subscription = new Subscription();

  idInstallation: number;

  loaderId: string;
  disabled: boolean;

  constructor(
    private weatherStationService: WeatherStationService
  ) {

    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);

  }

  ngOnInit() {

  }

  setIdInstallation(id: number) {
    if (this.idInstallation !== id) {
      this.idInstallation = id;
      this.getWeatherStation();
      this.getForecast();
      this.getLastInstant();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getWeatherStation() {
    this.weatherStationService.getWeatherStations(this.idInstallation);
  }

  getForecast() {
    this.weatherStationService.getForecast(this.idInstallation);
  }

  getLastInstant() {
    this.weatherStationService.getLastInstant(this.idInstallation);
  }

}


