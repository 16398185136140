import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbMenuItem } from '@nebular/theme';
import { MenuService } from '../@core/layout/Menu/menu.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})



export class DashboardComponent implements OnInit {
  menu: NbMenuItem[];
  cards: any[];



  constructor(private router: Router, private menuService: MenuService) {
    this.menuService.currentMenu.subscribe((menu: NbMenuItem[]) => {
      this.menu = menu;
      this.cards = [];
      this.menu.forEach(x => {
        if (x.title !== 'Dashboard') {
          const card = { 'title': x.title, 'url': x.url };
          this.cards.push(card);
        }
      });
    });
  }

  ngOnInit() {
  }

  goToApplication(app: string) {
    //    window.open(`${environment.URL_CALLBACK}/app/${app}`)
    // window.open(`/app/${app}`)
    window.open(app);
  }
}
