export class Profile {
    roleId: number;
    description: number;
    activated: boolean;
    roleProfiles ?: RoleProfile[];
}


export class RoleProfile {
    profileId: number;
    roleId: number;
}
