import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TableData} from '../models/selfview-main.model';
import { PeriodTableData, PeriodData } from '../models/selfview-period.model';
import { SelfViewParams, DataMode } from '../models/selfview.model';
import { Observable, EMPTY, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelfviewGeneralTablesService {
  private _tableDataApi = environment.API_URL + '/api/selfview/getActiveResults';
  private removeCompare = new Subject<Boolean>();
  _removeCompare = this.removeCompare.asObservable();

  private _tableData = new Subject<TableData>();
  _data = this._tableData.asObservable();
  set data(data: TableData) {
    this._tableData.next(data);
  }

  private _tableDataCompare = new Subject<TableData>();
  _dataCompare = this._tableDataCompare.asObservable();
  set dataCompare(data: TableData) {
    this._tableDataCompare.next(data);
  }

  constructor(private http: HttpClient) { }

  getMainTableDate(data: SelfViewParams): Observable<TableData> {
    return this.http.post(this._tableDataApi, data).pipe(
      map( (response: TableData) => {
        return response;
      })
    );
  }

  getPeriodTableData(type: DataMode): Observable<PeriodTableData> {
    if (type === DataMode.Normal) {
      return this._tableData.pipe(
        map((data: TableData) => {
          return this.mapPeriodData({...data});
        })
      );
    } else if (type === DataMode.Compare) {
      return this._tableDataCompare.pipe(
        map((data: TableData) => {
          return this.mapPeriodData({...data});
        })
      );
    }
  }

  mapPeriodData(data): PeriodTableData {
    const periodData: PeriodData [] = [];
    const length = data.totalConsumptionByPeriod.length;
    for (let i = 0; i < length; i++) {
      periodData.push({
        consumption: data.totalConsumptionByPeriod[i],
        excedentary: data.totalExcedentaryByPeriod[i],
        generated: data.totalGeneratedByPeriod[i],
        networkConsumption: data.totalNetworkConsumptionByPeriod[i],
        selfConsumption: data.totalSelfConsumptionByPeriod[i],
        period: i + 1,
      });
    }
    const _data: PeriodTableData = {
      periodData: periodData,
      totalConsumption: data.totalConsumption,
      totalExcedentary: data.totalExcedentary,
      totalGenerated: data.totalGenerated,
      totalNetworkConsumption: data.totalNetworkConsumption,
      totalSelfConsumption: data.totalSelfConsumption,
    };
    return _data;
  }

  removeDataCompare() {
    this.removeCompare.next(true);
  }
}
