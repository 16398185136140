import { ColDef } from "ag-grid-community";

export const ADMIN_DEFAULT_COL_DEF: ColDef = {
  minWidth: 100,
  resizable: true,
  sortable: true,
  filter: 'agTextColumnFilter',
};

export const REGEXPS = {
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  phone: /^\+?\d{9,13}$/,
  numberDecimal: /^\d+(\.\d{0,})?$/,
  numberInteger: /^\d+$/,
};

export const DATE_FORMAT = 'DD/MM/YYYY';
