import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbAuthResult } from '@nebular/auth';
import { takeWhile, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserExternal, User } from 'projects/pplus/src/lib/models/AllModels';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'lib-callback-facebook',
  template: '',
  styles: ['']
})
export class CallbackFacebookComponent implements OnDestroy {
  private _facebookURL = 'https://graph.facebook.com/me?fields=name,id,email,picture&access_token=';
  private _externalLogin = environment.API_URL + '/api/user/external-login';
  private _redirectURL;
  alive = true;

  constructor(private authService: NbAuthService, private router: Router, private http: HttpClient) {
    this.authService.authenticate('facebook')
    .pipe(
      takeWhile(() => this.alive), switchMap( (authResult: NbAuthResult) => {
        const facebookToken = authResult.getToken().getValue();
        if (authResult.isSuccess() && authResult.getRedirect()) {
          this._redirectURL = authResult.getRedirect();
          return this.getExternalDataUser(this._facebookURL + facebookToken).pipe(
            switchMap( (data: any) => {
              const user: UserExternal = {
                provider: 'facebook',
                token: facebookToken,
                fullName: data.name,
                email: data.email,
                picture: data.picture.data.url,
                providerkey: data.id,
              };
              return this.http.post(this._externalLogin, user);
            })
          );
        } else {
          this.router.navigateByUrl('auth/login');
        }
      })).subscribe( (data: any) => {
        if (this._redirectURL) {
          if (data) {
            const user: User = {
              token: data.token,
              email: data.email,
              fullName: data.fullName,
              userId: data.userId,
              picture: data.picture,
              role: data.role,
              language: data.language,
              sessionId: data.sessionId,
            };
            localStorage.setItem('pplus', JSON.stringify(user));
          }
          this.router.navigateByUrl(this._redirectURL);
        }
      });
  }

  getExternalDataUser(url: string) {
    return this.http.get(url);
  }
  ngOnDestroy(): void {
    this.alive = false;
  }


}
