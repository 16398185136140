import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule, MatCardModule } from '@angular/material';
import { EntitySelectorComponent } from './entity-selector.component';
import { PPLusTreeDataModule } from '../tree-data/tree-data.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    EntitySelectorComponent
  ],
  imports: [
    CommonModule,
    DragDropModule,
    MatButtonModule,
    PPLusTreeDataModule,
    TranslateModule,
    MatCardModule
  ],
  exports: [
    EntitySelectorComponent
  ]
})
export class PPlusEntitySelectorRodrigoModule { }
