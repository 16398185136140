import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PPlusReportViewerComponent } from './pplus-report-viewer/pplus-report-viewer.component';
import { PPlusReportDesignerComponent } from './pplus-report-designer/pplus-report-designer.component';

@NgModule({
  declarations: [
    PPlusReportViewerComponent,
    PPlusReportDesignerComponent
],
  imports: [
    CommonModule,
  ],
  exports: [
      PPlusReportViewerComponent,
      PPlusReportDesignerComponent
  ]
})
export class PPlusReportsModule { }
