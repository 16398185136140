import { Component, OnInit, ChangeDetectionStrategy, Input} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'lib-split-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './split-form.component.html',
  styleUrls: ['./split-form.component.scss']
})
export class SplitFormComponent implements OnInit {

  @Input() Title: string;
  @Input() options: {
    open: boolean,
    direction: string;
    contentSize: number;
    formSize: number;
  };

  // localStorageName = 'PPlus_Split';

  constructor(private breakPointObserver: BreakpointObserver) {
  }

  ngOnInit() {

  }

  openForm() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe( res => {
      if (res.matches) {
        if (!this.options.open) {
          this.options.open = true;
          this.options.contentSize = 0;
          this.options.formSize = 100;
        } else {
          this.options.open = false;
          this.options.contentSize = 100;
          this.options.formSize = 0;
        }
      } else {
        if (!this.options.open) {
          this.options.open = true;
          this.options.contentSize = 70;
          this.options.formSize = 30;
        } else {
          this.options.open = false;
          this.options.contentSize = 100;
          this.options.formSize = 0;
        }
      }
    });

  }

  // saveLocalStorage() {
  //   localStorage.setItem(this.localStorageName, JSON.stringify(this.config));
  // }
}
