import { Component, AfterViewInit, ViewChild, Input, OnInit } from '@angular/core';
import { NbMenuService } from '@nebular/theme';
import { PVSelfCalculationService } from './services/pvself-calculation.service';
import { TabsComponent } from 'projects/pplus/src/lib/components/tabs/tabs.component';
import { PVSelfCalculationTabsService } from './services/pvself-calculation-tabs.service';
import { Entity } from 'projects/pplus/src/lib/models/entity.model';
import { EntitiesService } from 'projects/pplus/src/lib/services/entities.service';

@Component({
  selector: 'app-pvself',
  templateUrl: './pvself.component.html',
  styleUrls: ['./pvself.component.scss'],
  providers: [PVSelfCalculationTabsService]
})

export class PvselfComponent implements AfterViewInit {
  @ViewChild('PvSelf', { static: false }) pvSelfTemplate;
  @ViewChild('MyCalculations', { static: false }) myCalculationsTemplate;
  @ViewChild('MyConfiguration', { static: false }) myConfigurationTemplate;
  @ViewChild('Registers', { static: false }) registersTemplate;
  @ViewChild('SizeBasicCalculations', { static: false }) sizeBasicCalculationsTemplate;
  @ViewChild(TabsComponent, { static: false }) tabsComponent;
  @Input('tabType') tabType: string;

  _applicationEntity: Entity;



  constructor(private nbMenuService: NbMenuService,
    private calculationService: PVSelfCalculationService,
    private calculationTabsService: PVSelfCalculationTabsService,
    private entitiesService: EntitiesService) {

    this.nbMenuService.onItemClick().subscribe((res) => {
      const itemSelected = res.item;
      switch (itemSelected.queryParams.code) {
        case 'New-Calculation':
          this.createNewPVSelfCalculation();
          break;
        case 'My-Calculations':
          this.openMyCalculations();
          break;
        case 'PVSelfMyConfiguration':
          this.openMyConfiguration();
          break;
        case 'PVSelfRegisters':
          this.openRegisters();
          break;
        case 'SizeBasicCalculations':
          this.openSizeCalculations();
          break;
        default:
          break;
      }
    });
  }



  ngAfterViewInit() {
    this.calculationTabsService.set(this.tabsComponent);

    this.entitiesService.applicationEntity.subscribe(res => {
      if (res !== null) {
        this._applicationEntity = res;
        this.createNewPVSelfCalculation();
      }
    });

  }


  createNewPVSelfCalculation() {
    this.calculationService.createCalculation(this._applicationEntity.entityId).then(res => {
      this.tabsComponent.openTab('Nuevo Cálculo', this.pvSelfTemplate, {
        'tabType': this.tabsComponent.tabType,
        'tabContentOrigin': 'new',
        '_calculation': res
      },
        true);
    });
  }

  openCalculation(idCalculation: number) {
    this.calculationService.openCalculation(idCalculation).subscribe(res => {
      this.tabsComponent.openTab(res.description, this.pvSelfTemplate, {
        'tabType': this.tabsComponent.tabType,
        'tabContentOrigin': 'open',
        '_calculation': res
      }, true);
    });
  }


  openMyCalculations() {
    this.tabsComponent.openTab('Mis cálculos', this.myCalculationsTemplate, {}, true);
  }

  openMyConfiguration() {
    this.tabsComponent.openTab('Mi configuración', this.myConfigurationTemplate, {}, true);
  }

  openRegisters() {
    this.tabsComponent.openTab('Registros', this.registersTemplate, {}, true);
  }

  openSizeCalculations() {
    this.tabsComponent.openTab('Cáculos SizeBasic', this.sizeBasicCalculationsTemplate, {}, true);
  }
}
