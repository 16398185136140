import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { NbDialogService } from '@nebular/theme';
import { StyleService } from './style.service';
import { DialogColorComponent } from 'projects/pplus/src/lib/components/ag-grid/dialog-color/dialog-color.component';

@Component({
  selector: 'app-style',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss']
})
export class StyleComponent implements ICellRendererAngularComp {
  params;
  data;
  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  openStyle() {
    this.styleService.getStyle(this.params.value)
    .subscribe( res => {
      this.data = res;
      const dialog = this.dialogService.open(DialogColorComponent);
      const dialogRef = dialog.componentRef;
      dialogRef.instance.title = this.data['description'];
      dialogRef.instance.entityId = this.data['entityId'];
      dialogRef.instance.logo = this.data['logoFile'];
      dialogRef.instance.colors = this.data['colors'];
      dialogRef.instance.cssStyle = this.data['cssFile'];
    });

  }

  constructor(private styleService: StyleService, private dialogService: NbDialogService) { }

}
