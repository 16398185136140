import { Component, Input, OnChanges } from '@angular/core';
import { Fields } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-number',
  templateUrl: './number.component.html',
  styleUrls: ['./number.component.scss']
})
export class NumberComponent implements OnChanges {
  @Input() field: Fields;
  @Input() form: FormGroup;
  get isValid() { return this.form.controls[this.field.name].valid; }
  get isDirty() { return this.form.controls[this.field.name].dirty; }

  constructor() { }

  ngOnChanges() {
    if (this.field.disabled) {
      this.form.controls[this.field.name].disable();
    }
  }
}
