import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { IEntityPlantGroup } from 'projects/pplus/src/lib/interfaces/interfaces';
import { FormGroup, FormControl } from '@angular/forms';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { PlantGroupService } from '../plant-group.service';
import { PlantGroupsDetailsService } from './plant-groups-details.service';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { PlantService } from '../../plant/plant.service';
import { EntitiesService } from '../../entities/entities.service';
import { AdminService } from '../../admin.service';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'app-plant-groups-details',
  templateUrl: './plant-groups-details.component.html',
  styleUrls: ['./plant-groups-details.component.scss']
})
export class PlantGroupsDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  data: IEntityPlantGroup;

  formGroup: FormGroup;
  formData: DynamicForm[];

  constructor(
    private plantGroupService: PlantGroupService,
    private plantGroupDetailsService: PlantGroupsDetailsService,
    private dataRow: RowDataService,
    private plantService: PlantService,
    private entitiesService: EntitiesService,
    private adminService: AdminService,
    private nbDialogService: NbDialogService,
  ) {
    this.formData = this.plantGroupDetailsService.getDynamicForm();
  }

  ngOnInit() {
    this.dataRow.currentRowData.pipe(map(row => {
      for (const group of this.formData) {
        group.fields.map(field => {
          if (field.name === 'assignedPlants') {
            const plantIds: number[] = [];
            const plantGroupPlants: any[] = row['plantGroupPlants'];
            if (plantGroupPlants && plantGroupPlants.length) {
              for (const el of plantGroupPlants) plantIds.push(el.plantId);
            }
            field.value = plantIds;
          } else
          if (field.name === 'assignedEntities') {
            const entityIds: number[] = [];
            const entityPlantGroups: any[] = row['entityPlantGroups'];
            if (entityPlantGroups && entityPlantGroups.length) {
              for (const el of entityPlantGroups) entityIds.push(el.entityId);
            }
            field.value = entityIds;
          }
          else field.value = row[field.name];
        });
      }
      return row;
    })).subscribe(row => {
      this.data = <IEntityPlantGroup>row;
      this.generateForm(this.formData);
    });
  }

  private generateForm(dynamicForm: DynamicForm[]) {
    forkJoin([
      this.plantService.getPlantList(),
      this.entitiesService.getEntitiesList(),
    ]).subscribe(([plants, entities]) => {
      const controls = {};
      for (const group of dynamicForm) {
        for (const field of group.fields) {
          if (field.name === 'assignedPlants') {
            const options = [];
            for (const plant of plants) {
              options.push({
                value: plant.plantId,
                label: plant.name,
              });
            }
            field.options = options;
          } else
          if (field.name === 'entityId' || field.name === 'assignedEntities') {
            const options = [];
            for (const entity of entities) {
              options.push({
                value: entity.entityId,
                label: entity.code,
              });
            }
            field.options = options;
          }
          controls[field.name] = new FormControl(field.value, field.validators);
        }
      }
      this.formGroup = new FormGroup(controls);
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IEntityPlantGroup>this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.plantGroupService.create(data).subscribe(newPlantGroup => {
        if (newPlantGroup != null) {
          this.formGroup.patchValue({plantGroupId: this.data.plantGroupId = newPlantGroup.plantGroupId});
          forkJoin([
            this.plantGroupService.assignPlants(newPlantGroup.plantGroupId, data.assignedPlants),
            this.plantGroupService.assignEntities(newPlantGroup.plantGroupId, data.assignedEntities),
          ]).subscribe(success => {
            if (success.every(b => b === true)) {
              this.adminService.showToast('success',
                  'Se ha creado el nuevo grupo de plantas: ' + data.name,
                  'Elemento creado');
              this.updateDelete.emit(true);
            } else {
              this.adminService.showToast('danger',
                  'Se ha producido un error al asignar plantas y/o entidades al nuevo grupo de plantas: ' + data.name,
                  'Error');
            }
          });
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al crear el grupo de plantas: ' + data.name,
              'Error');
        }
      });
    }
  }

  update() {
    if (this.data.plantGroupId == null) {
      this.onSubmit();
    } else if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IEntityPlantGroup>this.formGroup.value;
      data.activated = Boolean(data.activated);
      forkJoin([
        this.plantGroupService.update(this.data.plantGroupId, data),
        this.plantGroupService.assignPlants(this.data.plantGroupId, data.assignedPlants),
        this.plantGroupService.assignEntities(this.data.plantGroupId, data.assignedEntities),
      ]).subscribe(success => {
        if (success.every(b => b === true)) {
          this.adminService.showToast('success',
              'Se ha actualizado el grupo de plantas: ' + data.name,
              'Elemento actualizado');
          this.updateDelete.emit();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al actualizar el grupo de plantas: ' + data.name,
              'Error');
        }
      });
    }
  }

  delete() {
    if (this.data.plantGroupId == null) {
      this.adminService.showToast('info', 'Seleccione un grupo de plantas', 'Info');
    } else {
      this.plantGroupService.delete(this.data.plantGroupId).subscribe(success => {
        if (success) {
          this.adminService.showToast('success',
              'Se ha borrado el grupo de plantas: ' + this.data.name,
              'Elemento eliminado');
          this.updateDelete.emit();
          this.clearData();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al borrar el grupo de plantas: ' + this.data.name,
              'Error');
        }
      });
    }
  }

  private clearData() {
    this.data = <IEntityPlantGroup>{};
    this.formGroup.reset();
  }

  close() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  copy() {
    if (this.data.plantGroupId == null) {
      this.adminService.showToast('info', 'Seleccione un grupo de plantas para copiar la información', 'Info');
    } else {
      this.formGroup.setValue(Object.assign(this.data, this.formGroup.value, <IEntityPlantGroup>{
        plantGroupId: null,
        name: '',
        demoName: '',
        code: '',
        demoCode: '',
        creationDate: '',
        deletionDate: '',
        nominalPower: '',
        peakPower: '',
        entityId: null,
        ownerEntity: null,
      }));
    }
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.plantGroupId == null) {
      this.adminService.showToast('info', 'No hay ningún grupo de plantas seleccionado', 'Info');
    } else {
      this.nbDialogService.open(dialog, { context: '¿Eliminar el grupo de plantas?', hasScroll: false });
    }
  }

}
