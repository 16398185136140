
export const PROFILES_HEADERS = [
    {
        headerName: 'ID',
        rowDrag: false,
        minWidth: 50,
        field: 'profileId',
        filter: false,
        resizable: true,
        status: false,
    },
    {
        headerName: 'Profile',
        minWidth: 100,
        field: 'description',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: true,
    },
    {
        headerName: 'Activated',
        cellRenderer: 'checkBoxComponent',
        field: 'activated',
        filter: false,
        minWidth: 100,
        resizable: true,
        status: true,
    },
    {
        headerName: 'Roles',
        minWidth: 100,
        field: 'rolesNames',
        filter: 'agTextColumnFilter',
        resizable: true,
        status: true,
        valueFormatter: function (r: any) {
            let value = '';
            const elems = r.data.roleProfiles;
            if (elems && elems.length) {
                for (const e of elems) {
                    if (value) { value += ', '; }
                    value += e.role ? e.role.description : '';
                }
            }
            return r.data.rolesNames = value;
        },
    }
];
