import { Component, OnInit } from '@angular/core';
import { ElementType } from '../../../models/elementType.model';
import { ElementTypesService } from '../../../services/element-types.service';

@Component({
  selector: 'pplus-combo-element-types',
  templateUrl: './combo-element-types.component.html',
  styleUrls: ['./combo-element-types.component.scss']
})
export class ComboElementTypesComponent implements OnInit {

  selectedElement: ElementType;
  elements: ElementType[];

  constructor(private elementTypesService: ElementTypesService) { }

  ngOnInit() {
    this.loadElements();
  }

  loadElements() {
    this.elementTypesService.getList().subscribe(res => this.elements = res);
  }
}
