import { NgModule } from '@angular/core';
import { RateComponent } from './rate/rate.component';
import { ConsumptionComponent } from './consumption/consumption.component';
import { ConsumerComponent } from './consumer.component';
import { NbCardModule, NbPopoverModule, NbStepperModule } from '@nebular/theme';
import { MatRippleModule, MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { ChargeCurveComponent } from './consumption/chargeCurve/chargeCurve.component';
import { MatButtonModule } from '@angular/material/button';
import { NgxEchartsModule } from 'ngx-echarts';
import { AgGridModule } from 'ag-grid-angular';
import { MatSelectModule } from '@angular/material/select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ConsumptionTemplatesComponent } from './consumption/consumption-templates/consumption-templates.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule, MatInputModule, MatButtonToggleModule, MatDatepickerModule, MatListModule, MatCheckboxModule, MatStepperModule, MatRadioModule } from '@angular/material';
import { MatDividerModule } from '@angular/material/divider';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PPlusTabsModule } from 'projects/pplus/src/public_api';
import { PVSelfConsumptionHabitsComponent } from './consumption/consumption-habits/pvself-consumption-habits.component';
import { PVSelfCalendarHeaderComponent } from '../../shared/components/pvself-calendar-header/pvself-calendar-header.component';

@NgModule({
  declarations: [ChargeCurveComponent, RateComponent, ConsumptionComponent,
    ConsumerComponent, ConsumptionTemplatesComponent, PVSelfConsumptionHabitsComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    NbPopoverModule,
    NbCardModule,
    MatRippleModule,
    NgxEchartsModule,
    AgGridModule,
    MatSelectModule,
    NgxDaterangepickerMd.forRoot(),
    MatTabsModule,
    NbStepperModule,
    MatChipsModule,
    MatDividerModule,
    NgxUiLoaderModule,
    TranslateModule,
    MatInputModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatListModule,
    MatCheckboxModule,
    PPlusTabsModule,
    MatStepperModule,
    MatNativeDateModule,
    MatRadioModule
  ],
  exports: [
    ChargeCurveComponent,
    ConsumptionComponent,
    RateComponent,
    ConsumerComponent
  ],
  entryComponents: [PVSelfCalendarHeaderComponent],
})
export class ConsumerModule { }
