import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF } from "../admin.model";

export const ENTITIES_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const ENTITIES_COL_DEFS: ColDef[] = [
  {
    field: 'entityId',
    headerName: 'ID',
    checkboxSelection: function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'code',
    headerName: 'Code',
  },
  {
    field: 'description',
    headerName: 'Description',
    minWidth: 200,
  },
  {
    field: 'entityDomainNames',
    headerName: 'Domains',
    minWidth: 200,
    valueFormatter: function (params) {
      if (params && params.data) {
        let value = '';
        const domains = params.data.entityDomains;
        if (domains && domains.length) for (const domain of domains) {
          if (value) value += ', ';
          value += domain.fqdn;
        }
        return params.data.entityDomainNames = value;
      }
    },
  },
  {
    field: 'entityId',
    headerName: 'Style',
    sortable: false,
    filter: false,
    cellRenderer: 'styleComponent',
  },
  {
    field: 'activated',
    headerName: 'Activated',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkBoxComponent',
  },
  {
    field: 'language',
    headerName: 'Language',
  },
  {
    field: 'creationDate',
    headerName: 'Creation date',
    filter: 'agDateColumnFilter',
  },
];
