import { Component,  OnDestroy, Injectable, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { NbLoginComponent, NbAuthService, NB_AUTH_OPTIONS } from '@nebular/auth';
import { NbAuthResult } from '@nebular/auth';
import { takeWhile } from 'rxjs/operators';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

@Injectable()
export class LoginComponent extends NbLoginComponent implements OnDestroy {
  alive: boolean = true;
  email: FormControl;
  form: FormGroup;
  checked;
  password: FormControl;
  remember: FormControl;
  constructor(service: NbAuthService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    @Inject(NB_AUTH_OPTIONS) protected options = {},
    cd: ChangeDetectorRef,
    router: Router) {
    super(service, options, cd, router);

    this.email = new FormControl('', [
      Validators.required,
      Validators.email
    ]);
    this.password = new FormControl('', [
      Validators.required,
    ]);
    this.remember = new FormControl();

    this.form = new FormGroup({
      email : this.email,
      password: this.password,
      remember: this.remember
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }

  login(): void {
    this.errors = [];
    this.messages = [];
    this.submitted = true;

    this.service.authenticate(this.strategy, this.user).subscribe((result: NbAuthResult) => {
      this.submitted = false;
      if (result.isSuccess()) {
        this.translate.get('LOGIN.MSG.SUCCESS').subscribe( res => {
          this.messages.push(res);
          this._snackBar.open(`🎉 ${res}`, 'Ok', {
            duration: 10 * 1000,
          });
        });
      } else {
        this.translate.get('LOGIN.MSG.ERROR').subscribe( res => {
          this.errors.push(res);
          this._snackBar.open(`🚫 ${res}`, 'Ok', {
            duration: 10 * 1000,
          });
        });
      }
      const redirect = result.getRedirect();
        if (redirect) {
          setTimeout(() => {
            return this.router.navigateByUrl(redirect);
          }, this.redirectDelay);
        }
        this.cd.detectChanges();
    });
  }

  loginOAuth(type) {
    this.service.authenticate(type)
      .pipe(takeWhile(() => this.alive))
      .subscribe((authResult: NbAuthResult) => {
      });
  }

}
