import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ResultsComponent } from './results/results.component';
import { PVSelfCalculation } from '../models/PvSelfCalculation.model';
import { PVSelfCalculationService } from '../services/pvself-calculation.service';
import { PvselfConsumersService } from '../services/pvself-consumers.service';
import { PVSelfInstallationsService } from '../services/pvself-installations.service';
import { PreDimensionedComponent } from './pre-dimensioned/pre-dimensioned.component';
import { PVSelfReportsService } from '../services/pvself-reports.service';
import { PVSelfProducersService } from '../services/pvself-producers.service';
import { MatSnackBar } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConfigComponent } from './config/config.component';
import { EntitiesService } from 'projects/pplus/src/lib/services/entities.service';
import { Entity } from 'projects/pplus/src/lib/models/entity.model';

@Component({
  selector: 'app-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss'],
  providers: [PVSelfCalculationService, PvselfConsumersService, PVSelfInstallationsService, PVSelfProducersService]
})


export class CalculationComponent implements OnInit {
  @Input('tabCreationData') tabCreationData: any;
  @ViewChild(ConfigComponent, { static: true }) configComponent: ConfigComponent;
  @ViewChild(PreDimensionedComponent, { static: true }) predimensionedComponent: PreDimensionedComponent;
  @ViewChild(ResultsComponent, { static: true }) resultsComponent: ResultsComponent;
  _calculation: PVSelfCalculation;
  _applicationEntity: Entity;


  typeDataSource = [
    {
      'value': 0,
      'icon': 'ion-md-calculator',
      'viewValue': 'COMMON.ELEMENTS.INSTALLATIONS'
    },
    {
      'value': 1,
      'icon': 'ion-md-boat',
      'viewValue': 'COMMON.CONSUMERS'
    },
    {
      'value': 2,
      'icon': 'ion-md-boat',
      'viewValue': 'COMMON.PREDIMENSIONING'
    },
    {
      'value': 3,
      'icon': 'ion-md-boat',
      'viewValue': 'COMMON.CONFIGURATION'
    },
    {
      'value': 4,
      'icon': 'ion-md-boat',
      'viewValue': 'COMMON.RESULTS'
    },
    {
      'value': 5,
      'icon': 'ion-md-boat',
      'viewValue': 'Informe'
    }
  ];


  default: number;
  loaderId: string;


  constructor(private calculationService: PVSelfCalculationService,
    private reportsService: PVSelfReportsService,
    private ngxLoaderService: NgxUiLoaderService,
    private _snackBar: MatSnackBar,
    private entitiesService: EntitiesService) {
  }

  ngOnInit() {
    this._calculation = this.tabCreationData._calculation;
    this.calculationService.set(this._calculation);
    this.default = 0;
    this.loaderId = 'loader-calculation-component' + Math.floor((Math.random() * 1000) + 1);

    this.entitiesService.applicationEntity.subscribe(res => {
      this._applicationEntity = res;
    });
  }





  changeBlock(type: string) {
    switch (type) {
      case 'previous':
        if (this.default > 0) {
          this.default--;
          this.componentShowed();
        }
        break;
      case 'next':
        if (this.default < 5) {
          this.default++;
          this.componentShowed();
        }
        break;
      default:
        break;
    }

  }


  prueba() {
    const calculation: PVSelfCalculation = this.calculationService.updateCalculation();
    console.log(calculation);
    // this.calculationService.calculation.subscribe(res => {
    //   console.log(res);
    //   console.log(this.engineService.convertToEngineModel(res));
    // });
  }

  generateDevelopmentData() {
    const calculation: PVSelfCalculation = this.calculationService.get();
    this.reportsService.generateDevelopmentData(calculation)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'informe.xlsx';
        link.click();
      });
  }



  guardar() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.calculationService.saveCalculation().subscribe(res => {
      if (res !== null) {
        this.calculationService.setId(res);
        //        const calculation: PVSelfCalculation = this.calculationService.get();
        //        calculation = res;
        //        this.calculationService.set(calculation);
        this.ngxLoaderService.stopLoader(this.loaderId);
        this._snackBar.open(`El cálculo se ha guardado con éxito.`, 'Ok', {
          duration: 3 * 1000
        });
      } else {
        this.ngxLoaderService.stopLoader(this.loaderId);
        this._snackBar.open(`🚫 Se ha producido un error al guardar el cálculo.`, 'Ok', {
          duration: 4 * 1000
        });
      }
    },
      (err: string) => {
        this.ngxLoaderService.stopLoader(this.loaderId);
        this._snackBar.open(`🚫 Se ha producido un error al guardar el cálculo.`, 'Ok', {
          duration: 4 * 1000
        });
      }
    );
  }




  bloquear() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.calculationService.lockCalculation().subscribe(res => {
      if (res === true) {
        this.calculationService.lock();
        this.ngxLoaderService.stopLoader(this.loaderId);
        this._snackBar.open(`🚫 El cálculo se ha bloqueado con éxito.`, 'Ok', {
          duration: 5 * 1000
        });
      } else {
        this.ngxLoaderService.stopLoader(this.loaderId);
        this._snackBar.open(`🚫 Se ha producido un error al bloquear el cálculo.`, 'Ok', {
          duration: 5 * 1000
        });
      }
    });
  }

  componentShowed() {
    this.calculationService.updateCalculation();
    if (this.default === 2) { this.predimensionedComponent.calcular(); }
    if (this.default === 3) { this.configComponent.calculatePIConfigs(); }
    if (this.default === 4) { this.resultsComponent.calcular(); }
  }

}


