import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import {GlobalType, IPeriod,} from '../../../models/selfview.model';
import * as XLSX from 'xlsx';
import {TranslateService} from '@ngx-translate/core';
import { SelfViewService } from '../../../services/selfview-general.service';
import { REACTIVE_DATA } from '../../reactive-main/reactive-main.series';
import { MatTableDataSource } from '@angular/material';


@Component({
  selector: 'app-reactive-periods-table',
  templateUrl: './reactive-periods-table.component.html',
  styleUrls: ['./reactive-periods-table.component.scss']
})
export class ReactivePeriodsTableComponent implements OnInit {
  @ViewChild('table', {static: false, read: ElementRef}) table: ElementRef;

  flipped: boolean;

  // Charts
  globalType: GlobalType;
  REACTIVE_DATA = REACTIVE_DATA;

  compareMode = false;
  reactiveData: any;
  reactiveDataCompare: any;

  periods: IPeriod[] = [];

  type;

  ps = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'];

  // GRID

  dataSource = new MatTableDataSource<any>([]);
  columnDefinitions = [
    'period',
    'consumption_I',
    'consumption_C',
    'networkConsumption_I',
    'networkConsumption_C',
    'excedentary_I',
    'excedentary_C',
    'generated_I',
    'generated_C'
  ];



  gridData: any;
  showGrid = false;


  constructor(private translate: TranslateService, private selfViewService: SelfViewService) {
    this.flipped = true;
  }

  ngOnInit() {
    this.selfViewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;

      this.selfViewService.dataReactive.subscribe((data: any) => {
        if (data && data.reactiveData) {
          this.reactiveData = data.reactiveData;
          this.buildGrid();
        }
      });

      this.selfViewService.dataReactiveCompare.subscribe((data: any) => {

      });
    });

  }


  buildGrid() {

    const rows = [];

    // row totales
    rows.push({
      period: 'Total',
      consumption_I: this.reactiveData.totalConsumption_I,
      consumption_C: this.reactiveData.totalConsumption_C,
      networkConsumption_I: this.reactiveData.totalNetworkConsumption_I,
      networkConsumption_C: this.reactiveData.totalNetworkConsumption_C,
      generated_I: this.reactiveData.totalGenerated_I,
      generated_C: this.reactiveData.totalGenerated_C,
      excedentary_I: this.reactiveData.totalExcedentary_I,
      excedentary_C: this.reactiveData.totalExcedentary_C
    });

    this.ps.forEach( (element, index) => {
      const consumption_I: number = this.reactiveData.totalConsumptionByPeriod_I[index];
      const consumption_C: number = this.reactiveData.totalConsumptionByPeriod_C[index];
      const networkConsumption_I: number = this.reactiveData.totalNetworkConsumptionByPeriod_I[index];
      const networkConsumption_C: number = this.reactiveData.totalNetworkConsumptionByPeriod_C[index];
      const generated_I: number = this.reactiveData.totalGeneratedByPeriod_I[index];
      const generated_C: number = this.reactiveData.totalGeneratedByPeriod_C[index];
      const excedentary_I: number = this.reactiveData.totalExcedentaryByPeriod_I[index];
      const excedentary_C: number = this.reactiveData.totalExcedentaryByPeriod_C[index];
      const row = {
        period: `P${index + 1}`,
        consumption_I ,
        consumption_C ,
        networkConsumption_I,
        networkConsumption_C,
        generated_I,
        generated_C,
        excedentary_I,
        excedentary_C
      };

      rows.push(row);

    });

    this.dataSource.data = rows;
  }

  /**
   * Exports grid to excel
   */
  exportToExcel() {
    let ws: XLSX.WorkSheet;
    ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Table.xlsx');
  }

}
