import { Component, OnInit, OnDestroy} from '@angular/core';
import { Subscription } from 'rxjs';
import { WeatherStationService } from '../weather-station.service';
import { WeatherStation, WeatherStationDataParams, WeatherStationDataDto } from '../weather-station.models';
import * as moment from 'moment';
import { WEATHER_STATION_CHART_OPTIONS } from './weather-stations.config';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-weather-stations',
  templateUrl: './weather-stations.component.html',
  styleUrls: ['./weather-stations.component.scss']
})
export class WeatherStationsComponent implements OnInit, OnDestroy {

  private weatherStationSubscription: Subscription;

  stations: WeatherStation[] = [];
  dateRange;
  selectedStationsIds: number[];
  weatherStationDatas: WeatherStationDataDto[][] = [];

  temperatureChartOptions = WEATHER_STATION_CHART_OPTIONS;
  windChartOptions = WEATHER_STATION_CHART_OPTIONS;
  radiationChartOptions = WEATHER_STATION_CHART_OPTIONS;

  // series
  timeserie: string[];
  ambientTemperatureSeries: any[] = [];
  panelTemperatureSeries: any[] = [];
  windSeries: any[] = [];
  radiationSeries: any[] = [];

  constructor(
    private weatherStationService: WeatherStationService) {
      const last6Months = new Date();
      last6Months.setMonth(last6Months.getMonth() - 6);
      last6Months.setHours(0, 0, 0);
      const today = new Date();
      today.setHours(23, 59, 59);

      this.dateRange = {
        startDate: last6Months.toUTCString(),
        endDate: today.toUTCString()
      };
  }

  ngOnInit() {
    this.weatherStationService.weatherStations.subscribe( (results: WeatherStation[] ) => {
      if (results) {
        this.stations = results;
      }
    });

    this.weatherStationService.weatherStationsDatas.pipe(
      debounceTime(800),
      distinctUntilChanged(),
    ).subscribe( (results: WeatherStationDataDto[][] ) => {
      if (results) {
        this.weatherStationDatas = results;
        this.buildCharts();
      }
    });
  }

  ngOnDestroy(): void {
    this.weatherStationSubscription.unsubscribe();
  }

  setDateRange(range) {
    this.dateRange = {
      startDate: range.startDate.toUTCString(),
      endDate: range.endDate.toUTCString(),
    };
    if (this.selectedStationsIds && this.selectedStationsIds.length) {
      this.getWeatherStationsData();
    }

  }

  getWeatherStationsData() {
    const params: WeatherStationDataParams = {
      weatherStationIds: this.selectedStationsIds,
      from: this.dateRange.startDate,
      to: this.dateRange.endDate
    };

    this.weatherStationService.getWeatherStationsDatas(params);
  }

  buildCharts() {

    this.ambientTemperatureSeries = [];
    this.panelTemperatureSeries = [];
    this.windSeries = [];
    this.radiationSeries = [];

    if (this.weatherStationDatas && this.weatherStationDatas.length) {
      this.timeserie = this.weatherStationDatas[0].map( (item: WeatherStationDataDto)=> {
        return moment(item.timestamp).format('DD/MM/YYYY HH:mm');
      });

      this.weatherStationDatas.forEach( (stationData: WeatherStationDataDto[], i: number) => {
        const stationName: string = this.stations.find(station => station.weatherStationId === stationData[0].weatherStationId).description;
        const ambientTemperatureSerie: number[] = [];
        const panelTemperatureSerie: number[] = [];
        const windSerie: number[] = [];
        const radiationSerie: number[] = [];

        stationData.forEach( (data: WeatherStationDataDto) => {
          ambientTemperatureSerie.push(data.ambientTemperature);
          panelTemperatureSerie.push(data.panelTemperature);
          windSerie.push(data.wind);
          radiationSerie.push(data.radiation);
        });

        this.ambientTemperatureSeries.push(
          this.generateWeatherStationSerie(ambientTemperatureSerie, `Ambient temperature - ${stationName}`)
        );
        this.panelTemperatureSeries.push(
          this.generateWeatherStationSerie(panelTemperatureSerie, `Panel temperature - ${stationName}`)
        );
        this.windSeries.push(
          this.generateWeatherStationSerie(windSerie, `Wind - ${stationName}`)
        );
        this.radiationSeries.push(
          this.generateWeatherStationSerie(radiationSerie, `Radiation - ${stationName}`)
        );


      });

      this.buildTemperatureChart();
      this.buildWindChart();
      this.buildRadiationChart();
    }

  }

  buildTemperatureChart() {

    const series = [...this.ambientTemperatureSeries, ...this.panelTemperatureSeries];

    this.temperatureChartOptions.xAxis[0].data = this.timeserie;
    this.temperatureChartOptions.series = series;
    this.temperatureChartOptions = {...this.temperatureChartOptions, ...this.temperatureChartOptions};
  }

  buildWindChart() {

    const series = [...this.windSeries];

    this.windChartOptions.xAxis[0].data = this.timeserie;
    this.windChartOptions.series = series;
    this.windChartOptions = {...this.windChartOptions, ...this.windChartOptions};
  }

  buildRadiationChart() {
    const series = [...this.radiationSeries];

    this.radiationChartOptions.xAxis[0].data = this.timeserie;
    this.radiationChartOptions.series = series;
    this.radiationChartOptions = {...this.radiationChartOptions, ...this.radiationChartOptions};
  }

  generateWeatherStationSerie(serie: number[], name: string) {
    return {
      name: name,
      type: 'line',
      xAxisIndex: 0,
      yAxisIndex: 0,
      data: serie,
      smooth: true,
      symbol: 'none',
    };
  }
}
