import { Component, OnInit, Input } from '@angular/core';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { EntitiesService } from '../../../services/entities.service';
import { FormControl } from '@angular/forms';
import { Entity } from '../../../models/entity.model';

@Component({
  selector: 'pplus-autocomplete-entities',
  templateUrl: './autocomplete-entities.component.html',
  styleUrls: ['./autocomplete-entities.component.scss']
})
export class AutocompleteEntitiesComponent implements OnInit {
  entities: any;
  isLoading = false;
  formControl = new FormControl();
  selectedValue: Entity;

  constructor(private entitiesService: EntitiesService) { }

  ngOnInit() {
    this.selectedValue = null;
    this.initEntitiesSearch();
  }

  initEntitiesSearch() {
    this.formControl.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.entities = [];
          this.isLoading = true;
        }),
        switchMap(value => this.entitiesService.searchByDescription(value)
          .pipe(
            finalize(() => {
              this.isLoading = false;
            }),
          )
        )
      )
      .subscribe(data => {
        this.entities = data;
      });
  }

  displayFn(value?: any) {
    return value ? value.description + ' [' + value.code + ']' : '-';
  }


  optionSelected(value) {
    this.selectedValue = value;
  }


}
