import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { InverterEnergyResults, InverterEnergyParams, InverterDailyData, InverterDailyDataParams, InstantInvertersPower, InverterModel } from './inverters.models';
@Injectable({
  providedIn: 'root'
})
export class InvertersService {
  private invertersEnergyDataApi = environment.API_URL + '/api/bigquery/inverter/historic/GetInvertersEnergyData';
  private installationDailyDataApi = environment.API_URL + '/api/bigquery/inverter/historic/GetInstallationDailyData';
  private InstantInvertersPowerApi = environment.API_URL + '/api/bigquery/inverter/historic/GetInstantInvertersPower';

  private _invertersEnergyData = new BehaviorSubject(<InverterEnergyResults>null);
  private _installationDailyData = new BehaviorSubject(<InverterDailyData[]>null);
  private _instantInvertersPower = new BehaviorSubject(<InstantInvertersPower>null);
  private _installationInverters = new BehaviorSubject(<InverterModel[]>null);

  invertersEnergyData: Observable<InverterEnergyResults> = this._invertersEnergyData.asObservable();
  installationDailyData: Observable<InverterDailyData[]> = this._installationDailyData.asObservable();
  instantInvertersPower: Observable<InstantInvertersPower> = this._instantInvertersPower.asObservable();
  installationInverters: Observable<InverterModel[]> = this._installationInverters.asObservable();

  constructor(private http: HttpClient) { }


  getInvertersEnergyData(data: InverterEnergyParams) {
    return this.getEnergyData(data)
      .subscribe((results: InverterEnergyResults) => {
        this._invertersEnergyData.next(results);
      });
  }

  private getEnergyData(data): Observable<InverterEnergyResults> {
    return this.http.get<InverterEnergyResults>(this.invertersEnergyDataApi, { params: data });
  }

  getInstallationDailyData(data: InverterDailyDataParams) {
    return this.getInstallationData(data)
      .subscribe((results: InverterDailyData[]) => {
        this._installationDailyData.next(results);
      });
  }

  private getInstallationData(data): Observable<InverterDailyData[]> {
    return this.http.get<InverterDailyData[]>(this.installationDailyDataApi, { params: data });
  }

  getInstantInvertersPower(installationId: number) {
    return this.getInstantPower(installationId)
      .subscribe((results: InstantInvertersPower) => {
        this._instantInvertersPower.next(results);
      });
  }

  private getInstantPower(installationId: number): Observable<InstantInvertersPower> {
    const params = new HttpParams({
      fromObject: {
        InstallationId: installationId.toString()
      }
    });
    return this.http.get<InstantInvertersPower>(this.InstantInvertersPowerApi, { params });
  }


  getInvertersByInstallation(installationId: number) {
    return this.getByInstallation(installationId)
      .subscribe((results: InverterModel[]) => {
        this._installationInverters.next(results);
      });
  }


  getByInstallation(installationId: number) {
    return this.http.get<InverterModel[]>(environment.API_URL + '/api/admin/elements/installationinverter/GetByInstallationFromSqlServer/' + installationId);
  }

}
