import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NbCardModule, NbPopoverModule } from '@nebular/theme';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MatIconModule, MatButtonModule, MatDatepickerModule, MatInputModule, MatSelectModule, MatChipsModule, MatAutocompleteModule, MatSlideToggleModule } from '@angular/material';
import { CountUpModule } from 'countup.js-angular2';
import { NgxEchartsModule } from 'ngx-echarts';
import { PPlusToggleModule } from '../../toggle/toggle.module';
import { PPlusPlantSearchModule } from '../../plants-search/plants-search.module';
import { PPlusDateRangeModule } from '../../date-range/date-range.module';
import { FormsModule } from '@angular/forms';
import { AgGridModule } from 'ag-grid-angular';
import { PPlusAgGridModule } from '../../ag-grid/ag-grid-components.module';
import { ConsumptionMeterComponent } from './consumption/consumption-meter.component';
import { EnergyChartComponent } from './common/energy-chart/energy-chart.component';
import { BorderMeterComponent } from './border/border-meter.component';

@NgModule({
  declarations: [
    ConsumptionMeterComponent,
    BorderMeterComponent,
    EnergyChartComponent
  ],
  imports: [
    CommonModule,
    PPlusToggleModule,
    PPlusPlantSearchModule,
    PPlusDateRangeModule,
    TranslateModule,
    NbCardModule,
    NgxUiLoaderModule,
    MatIconModule,
    MatButtonModule,
    NbPopoverModule,
    CountUpModule,
    NgxEchartsModule,
    MatDatepickerModule,
    MatInputModule,
    FormsModule,
    PPlusAgGridModule,
    AgGridModule,
    MatSelectModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSlideToggleModule
  ],
  exports: [
    PPlusToggleModule,
    PPlusPlantSearchModule,
    PPlusDateRangeModule,
    ConsumptionMeterComponent,
    BorderMeterComponent,
    EnergyChartComponent
  ],
  providers: [

  ]
})
export class ConsumptionBorderMeterModule { }
