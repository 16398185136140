import { UserGroupDetailsComponent } from './user-group/user-group-details/user-group-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { UsersComponent } from './users/users.component';
import { EntitiesComponent } from './entities/entities.component';
import { AgGridModule } from 'ag-grid-angular';
import { DomainsComponent } from './domains/domains.component';
import { DetailsEntityComponent } from './entities/details-entity/details-entity.component';
import { RouterModule, Routes } from '@angular/router';
import { StyleComponent } from './domains/components/style/style.component';
import { DomainsComponentsModule } from './domains/components/domains-components.module';
import { AngularResizedEventModule } from 'angular-resize-event';
import { UserDetailsComponent } from './users/user-details/user-details.component';
import { DomainDetailsComponent } from './domains/components/domain-details/domain-details.component';
import { LayoutModule } from '@angular/cdk/layout';
import { UserGroupComponent } from './user-group/user-group.component';
import { AppTypeComponent } from './app-type/app-type.component';
import { ApplicationComponent } from './application/application.component';
import { AppGroupComponent } from './app-group/app-group.component';
import { plantComponent } from './plant/plant.component';
import { plantGroupComponent } from './plant-group/plant-group.component';
import { PanelsComponent } from './panels/panels.component';
import { StringComponent } from './string/string.component';
import { PanelsDetailsComponent } from './panels/panels-details/panels-details.component';
import { StringDetailsComponent } from './string/string-details/string-details.component';
import { ServicesRecordComponent } from './servicesRecord/servicesRecord.component';
import { ServicerecordDetailsComponent } from './servicesRecord/servicerecord-details/servicerecord-details.component';
import { ServicesTypeComponent } from './ServicesType/ServicesType.component';
import { ServiceTypeDetailsComponent } from './ServicesType/serviceType-details/serviceType-details.component';
import { SpecificInstalationDataComponent } from './specificInstalationData/specificInstalationData.component';
import { SpecificisntalationdataDetailsComponent } from './specificInstalationData/specificisntalationdata-details/specificisntalationdata-details.component';
import { MatInputModule } from '@angular/material/input';
import { AdminComponent } from './admin.component';
import { MatCheckboxModule, MatIconModule, MatButtonModule, MatCardModule, MatTabsModule, MatAutocompleteModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { PPlusDynamicFormModule, PPlusAgGridModule, PPlusSplitFormModule, PPlusTabsModule } from 'projects/pplus/src/public_api';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';
import { NbAccordionModule, NbCheckboxModule, NbCardModule, NbActionsModule } from '@nebular/theme';
import { PhotondbComponent } from './photondb/photondb.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuard } from 'src/app/@core/_auth/auth.guard';
import { CommonModule } from '@angular/common';
import { StylesComponent } from './styles/styles.component';
import { StylesDetailsComponent } from './styles/styles-details/styles-details.component';
import { ApplicationDetailsComponent } from './application/application-details/application-details.component';
import { ApplicationTypeDetailsComponent } from './app-type/application-type-details/application-type-details.component';
import { AppGroupDetailsComponent } from './app-group/app-group-details/app-group-details.component';
import { PlantDetailsComponent } from './plant/plant-details/plant-details.component';
import { PermissionsComponent } from './permissions/permissions.component';
import { GenericPermissionsComponent } from './permissions/generic-permissions/generic-permissions.component';
import { RolesComponent } from './permissions/roles/roles.component';
import { ProfilesComponent } from './permissions/profiles/profiles.component';
import { GenericPermissionDetailsComponent } from './permissions/generic-permissions/generic-permission-details/generic-permission-details.component';
import { ProfileDetailsComponent } from './permissions/profiles/profile-details/profile-details.component';
import { RoleDetailsComponent } from './permissions/roles/role-details/role-details.component';
import { PlantGroupsDetailsComponent } from './plant-group/plant-groups-details/plant-groups-details.component';
import { EffectivePermissionsComponent } from './permissions/effective-permissions/effective-permissions.component';
import { EffectivePermissionsApplicationsComponent } from './permissions/effective-permissions/effective-permissions-applications/effective-permissions-applications.component';
import { EffectivePermissionsPlantsComponent } from './permissions/effective-permissions/effective-permissions-plants/effective-permissions-plants.component';
import { EffectivePermissionsEntityTypesComponent } from './permissions/effective-permissions/effective-permissions-entity-types/effective-permissions-entity-types.component';
import { EffectivePermissionsElementTypesComponent } from './permissions/effective-permissions/effective-permissions-element-types/effective-permissions-element-types.component';
import { AutocompleteEntitiesComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-entities/autocomplete-entities.component';
import { AutocompleteUsersComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-users/autocomplete-users.component';
import { AutocompleteApplicationsComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-applications/autocomplete-applications.component';
import { AutocompleteOperationsComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-operations/autocomplete-operations.component';
import { ComboElementTypesComponent } from 'projects/pplus/src/lib/components/searchers/combo-element-types/combo-element-types.component';
import { InstallationsComponent } from './installations/installations.component';
import { InstallationDetailsComponent } from './installations/installation-details/installation-details.component';
import { InstallationMetersComponent } from './installation-meters/installation-meters.component';
import { InstallationMetersDetailsComponent } from './installation-meters/installation-meters-details/installation-meters-details.component';
import { InstallationInvertersComponent } from './installation-inverters/installation-inverters.component';
import { InstallationInvertersDetailsComponent } from './installation-inverters/installation-inverters-details/installation-inverters-details.component';
import { InstallationStringsComponent } from './installation-strings/installation-strings.component';
import { InstallationStringsDetailsComponent } from './installation-strings/installation-strings-details/installation-strings-details.component';

export const AdminRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: AdminComponent
  },
];
@NgModule({
  declarations: [
    SpecificisntalationdataDetailsComponent,
    SpecificInstalationDataComponent,
    ServiceTypeDetailsComponent,
    ServicesTypeComponent,
    ServicerecordDetailsComponent,
    ServicesRecordComponent,
    StringDetailsComponent,
    PanelsDetailsComponent,
    StringComponent,
    PanelsComponent,
    UsersComponent,
    EntitiesComponent,
    StyleComponent,
    DomainsComponent,
    DetailsEntityComponent,
    UserDetailsComponent,
    UserGroupComponent,
    UserGroupDetailsComponent,
    AppTypeComponent,
    ApplicationComponent,
    AppGroupComponent,
    plantComponent,
    plantGroupComponent,
    AdminComponent,
    PhotondbComponent,
    StylesComponent,
    StylesDetailsComponent,
    ApplicationDetailsComponent,
    ApplicationTypeDetailsComponent,
    AppGroupDetailsComponent,
    PlantDetailsComponent,
    PermissionsComponent,
    GenericPermissionsComponent,
    RolesComponent,
    ProfilesComponent,
    GenericPermissionDetailsComponent,
    ProfileDetailsComponent,
    RoleDetailsComponent,
    PlantGroupsDetailsComponent,
    EffectivePermissionsComponent,
    EffectivePermissionsApplicationsComponent,
    EffectivePermissionsPlantsComponent,
    EffectivePermissionsEntityTypesComponent,
    EffectivePermissionsElementTypesComponent,
    AutocompleteEntitiesComponent,
    AutocompleteUsersComponent,
    AutocompleteApplicationsComponent,
    AutocompleteOperationsComponent,
    ComboElementTypesComponent,
    InstallationsComponent,
    InstallationDetailsComponent,
    InstallationMetersComponent,
    InstallationMetersDetailsComponent,
    InstallationInvertersComponent,
    InstallationInvertersDetailsComponent,
    InstallationStringsComponent,
    InstallationStringsDetailsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(AdminRoutes),
    MatOptionModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatCardModule,
    NbCardModule,
    NbActionsModule,
    PPlusTabsModule,
    FormsModule,
    RouterModule,
    PPlusAgGridModule,
    AgGridModule.withComponents([StyleComponent, AgCheckboxComponent]),
    DomainsComponentsModule,
    AngularResizedEventModule,
    LayoutModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    PPlusDynamicFormModule,
    ReactiveFormsModule,
    NbCheckboxModule,
    PPlusSplitFormModule,
    NgxUiLoaderModule,
    NbAccordionModule,
    TranslateModule,
    MatTabsModule,
    MatAutocompleteModule
  ],
  exports: [
    SpecificisntalationdataDetailsComponent,
    SpecificInstalationDataComponent,
    ServiceTypeDetailsComponent,
    ServicesTypeComponent,
    ServicerecordDetailsComponent,
    ServicesRecordComponent,
    StringDetailsComponent,
    PanelsDetailsComponent,
    StringComponent,
    PanelsComponent,
    DomainsComponent,
    UsersComponent,
    EntitiesComponent,
    AppGroupComponent,
    plantGroupComponent,
    ApplicationComponent,
    plantComponent,
    AppTypeComponent,
    DomainDetailsComponent,
    UserDetailsComponent,
    UserGroupDetailsComponent,
    UserGroupComponent,
    DetailsEntityComponent
  ]
})
export class AdminModule { }
