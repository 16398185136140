import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Permission } from '../../models/permissions/permission.model';


@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private httpClient: HttpClient) {
  }

  getList(): Observable<Permission[]> {
    return this.httpClient.get<Permission[]>(environment.API_URL + '/api/admin/permissions/getList').pipe(
      map(data => data));
  }

  update(permission: Permission): Observable<Boolean> {
    return this.httpClient.post<Boolean>(environment.API_URL + '/api/admin/permissions/update', permission).pipe(
      map(data => data));
  }

  create(permission: Permission): Observable<Boolean> {
    return this.httpClient.post<Boolean>(environment.API_URL + '/api/admin/permissions/create', permission).pipe(
      map(data => data));
  }

  delete(permissionId: number): Observable<Boolean> {
    return this.httpClient.post<Boolean>(environment.API_URL + '/api/admin/permissions/delete/', permissionId).pipe(
      map(data => data));
  }
}
