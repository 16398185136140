import { Component, OnInit } from '@angular/core';
import { InvertersService } from '../inverters.service';
import { InstantInvertersPower } from '../inverters.models';
import { instantChartInitOptions, INSTANT_CHART_OPTIONS } from '../inverters-chart.config';
import * as moment from 'moment';

@Component({
  selector: 'app-inverters-instant-block',
  templateUrl: './inverters-instant-block.component.html',
  styleUrls: ['./inverters-instant-block.component.scss']
})
export class InvertersInstantBlockComponent implements OnInit {

  instantInvertersPower: InstantInvertersPower = null;
  instantChartInitOptions = instantChartInitOptions;
  instantChartOptions: any = null;
  constructor(private invertersService: InvertersService) {
  }


  ngOnInit() {

    this.invertersService.instantInvertersPower.subscribe((instantInvertersPower: InstantInvertersPower) => {
      this.instantInvertersPower = instantInvertersPower;
      if (instantInvertersPower) {
        this.instantInvertersPower.timestamp = moment(this.instantInvertersPower.timestamp).format('DD/MM/YYYY HH:mm');
        this.initGaugeChart();
      }
    });
  }

  initGaugeChart() {
    this.instantChartOptions = INSTANT_CHART_OPTIONS;
    this.instantChartOptions.series[0].data[0].value = this.instantInvertersPower.instantPercentagePower.toFixed(2);
    this.instantChartOptions.series[0].axisLine.lineStyle.color[0][0] = (this.instantInvertersPower.instantPercentagePower / 100).toFixed(2);

    this.instantChartOptions = { ...this.instantChartOptions, ...this.instantChartOptions };
  }

}
