
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { NbAuthService } from '@nebular/auth';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { User } from '../models/AllModels';
import { EntitiesService } from '../services/entities.service';

@Injectable()
export class PPlusRequestInterceptor implements HttpInterceptor {
    user: User;
    nbToken = '';
    session = '';
    alive = true;
    constructor(private authService: NbAuthService, private router: Router, private entitiesService: EntitiesService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.authService.getToken().subscribe(data => {
            this.nbToken = data.getValue();
            if (data.getOwnerStrategyName() !== 'email') {
                this.user = JSON.parse(localStorage.getItem('pplus'));
            } else {
                this.session = data.getPayload().sessionId;
            }
        });
        const token = this.user ? this.user.token : this.nbToken;
        const session = this.user ? this.user.sessionId : this.session;
        // Por ahora solo incluimos una entidad. La idea es que pueda seleccionarse más de una
        const selectedEntityIds = '2';
        // if (this.entitiesService.getSelectedEntities().length > 0) { selectedEntityIds = this.entitiesService.getSelectedEntities()[0].entityId.toString(); }

        const url = request.url;
        const domain = environment.API_URL.split('/')[2];
        if (url.includes(domain)) {
            request = request.clone({
                headers: request.headers.set('Authorization', 'Bearer ' + token)
                    .set('SessionId', session)
                    .set('selectedEntityIds', selectedEntityIds)
            });
        }
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 418 && error.error.ErrorCode === 'USERLOGGEDIN') {
                    localStorage.removeItem('pplus');
                    localStorage.removeItem('auth_app_token');
                    this.router.navigate(['/app/usererror']);
                }
                throw throwError(error);
            })
        );
    }
}






Injectable();
export class PPlusResponseInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // Si queremos hacer algo con la respuesta, éste es el sitio.
                    //                    console.log(event);
                }
            }, (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 404:
                            console.log('Página no encontrada!');
                            break;
                        case 418:

                            break;
                        default:
                            console.log('Error respuesta (' + err.status + '): ' + err.statusText);
                            break;
                    }
                }
            })
        );
    }
}
