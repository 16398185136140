import { PPlusChartType } from "projects/pplus/src/lib/models/pplus.enums";

export class PVSelfBalanceResults {
    hourlyData: PVSelfHourlyData[];
    groupedData: PVSelfBalanceResultsData[];
    economicGroupedData: PVSelfBalanceEconomicGroupedResultsData[];
    mainChartType: PPlusChartType;

    energy: PVSelfBalanceEnergyResults;
    economics: PVSelfBalanceEconomicResults;

    peakPower: number;
    nominalPower: number;

    constructor() {
        this.groupedData = [];
        this.economicGroupedData = [];
    }
}


export class PVSelfBalanceEnergyResults {
    totalConsumption: number;
    totalNetworkConsumed: number;
    totalAutoconsumed: number;
    totalRealExcedentary: number;
    totalVirtualExcedentary: number;
    totalNetGenerated: number;

    coverage: number;
    exploitation: number;

    consumptionByPeriod: number[];
    networkConsumedByPeriod: number[];
    autoconsumedByPeriod: number[];
    excedentaryByPeriod: number[];
    netGeneratedByPeriod: number[];
}

export class PVSelfBalanceEconomicResults {
    totalBillingBeforeWOTaxes: number;
    totalBillingBeforeWTaxes: number;
    totalBillingAfterWOTaxes: number;
    totalBillingAfterWTaxes: number;
    totalSelfConsumptionSavingWOCompensationWOTaxes: number;
    totalSelfConsumptionSavingWCompensationWOTaxes: number;
    totalSelfConsumptionSavingWOCompensationWTaxes: number;
    totalSelfConsumptionSavingWCompensationWTaxes: number;
    totalCompensationWOTaxes: number;
    totalCompensationWTaxes: number;
    totalEnergySaleWOTaxes: number;
    totalEnergySaleWTaxes: number;
    totalWithoutCompensation: number;
    totalCompensable: number;

    totalPercentSelfConsumptionSaving: number;
    totalPercentCompensation: number;

    billingBeforeWOTaxesByPeriod: number[];
    selfConsumptionSavingWOCompensationWOTaxesByPeriod: number[];
    selfConsumptionSavingWCompensationWOTaxesByPeriod: number[];
    energySaleWOTaxesByPeriod: number[];
    billingBeforeWTaxesByPeriod: number[];
    selfConsumptionSavingWOCompensationWTaxesByPeriod: number[];
    selfConsumptionSavingWCompensationWTaxesByPeriod: number[];
    energySaleWTaxesByPeriod: number[];

    constructor() {
        this.billingBeforeWOTaxesByPeriod = [];
        this.selfConsumptionSavingWOCompensationWOTaxesByPeriod = [];
        this.selfConsumptionSavingWCompensationWOTaxesByPeriod = [];
        this.energySaleWOTaxesByPeriod = [];
        this.billingBeforeWTaxesByPeriod = [];
        this.selfConsumptionSavingWOCompensationWTaxesByPeriod = [];
        this.selfConsumptionSavingWCompensationWTaxesByPeriod = [];
        this.energySaleWTaxesByPeriod = [];
    }
}


export class PVSelfBalanceResultsData {
    date: Date;
    consumption: number[];
    networkConsumed: number[];
    autoconsumed: number[];
    excedentary: number[];
    netGenerated: number[];

    constructor() {
        this.consumption = [];
        this.networkConsumed = [];
        this.autoconsumed = [];
        this.excedentary = [];
        this.netGenerated = [];
    }
}

export class PVSelfBalanceEconomicGroupedResultsData {
    date: Date;
    billingBeforeSelfConsumptionWOTaxes: number[];
    billingAfterSelfConsumptionWOTaxes: number[];
    selfConsumptionSavingWOCompensationWOTaxes: number[];
    selfConsumptionSavingWCompensationWOTaxes: number[];
    energySaleWOTaxes: number[];
    compensationWOTaxes: number[];

    billingBeforeSelfConsumptionWTaxes: number[];
    billingAfterSelfConsumptionWTaxes: number[];
    selfConsumptionSavingWOCompensationWTaxes: number[];
    selfConsumptionSavingWCompensationWTaxes: number[];
    energySaleWTaxes: number[];
    compensationWTaxes: number[];

    constructor() {
        this.billingBeforeSelfConsumptionWOTaxes = [];
        this.billingAfterSelfConsumptionWOTaxes = [];
        this.selfConsumptionSavingWOCompensationWOTaxes = [];
        this.selfConsumptionSavingWCompensationWOTaxes = [];
        this.energySaleWOTaxes = [];
        this.compensationWOTaxes = [];

        this.billingBeforeSelfConsumptionWTaxes = [];
        this.billingAfterSelfConsumptionWTaxes = [];
        this.selfConsumptionSavingWOCompensationWTaxes = [];
        this.selfConsumptionSavingWCompensationWTaxes = [];
        this.energySaleWTaxes = [];
        this.compensationWTaxes = [];
    }

}


export class PVSelfPIConfiguration {
    inverterId: number;
    inverterManufacturerId: number;
    panelsPerSerial: number;
    totalNominalPower: number;
    numInverters: number;
    inverterModel: string;
    peakPowerInverter: number;
    numSerials: number;
    panelsPerInverter: number;
    sizeRelation: number;
    nominalPower: number;
}

export class PVSelfEnginePIConfigurationResults {
    num_total_modulos: number;
    potencia_pico_total: number;
    potencia_nominal_total: number;
    relacion_tamano: number;

    tension_minima_al_inversor: number;
    tension_maxima_al_inversor: number;
    intensidad_nominal_al_inversor: number;
    intensidad_maxima_al_inversor: number;

    tension_minima_modulo: number;
    tension_maxima_modulo: number;
    intensidad_minima_modulo: number;
    intensidad_maxima_modulo: number;

    validationData: number;
}


export class PVSelfPredimensioningResults {
    power: number;
    wpExecutionCost: number;
    totalExecutionCost: number;
    wpStorageCost: number;
    totalStorageCost: number;
    kwpExploitationCost: number;
    totalExploitationCost: number;
    totalCost: number;
    surface: number;
    profitability: number;
    saving: number;
    compensation: number;
    energySale: number;
    totalInputs: number;
    coverage: number;
    exploitation: number;

    totalConsumption: number;
    totalSolarResource: number;
    totalNetGenerated: number;
    totalRealExcedentary: number;
    totalVirtualExcedentary: number;
    totalAutoconsumed: number;
    porcExcedentary: number;

    isOptimal: true;
}


export class PVSelfHourlyData {
    hour: Date;
    energy: PVSelfEnergyHourlyData;
    economic: PVSelfEconomicHourlyData;
}

export class PVSelfEnergyHourlyData {
    consumption: number;
    production: number;
    autoconsumed: number;
    networkConsumed: number;
    excedentary: number;
}


export class PVSelfEconomicHourlyData {
    billingBeforeSelfConsumption: number;
    billingAfterSelfConsumption: number;
    selfConsumptionSaving: number;
    energySale: number;
}


export class PVSelfEconomicSummaryResults {
    monthlySummary: PVSelfEconomicSummary[];

    peakPower: number;
    nominalPower: number;

    constructor() {
        this.monthlySummary = [];
    }
}

export class PVSelfEconomicSummary {
    month: string;
    billingBeforeSelfConsumption: number;
    billingAfterSelfConsumption: number;
    selfConsumptionSavingWOCompensation: number;
    selfConsumptionSavingWCompensation: number;
    energyCoverage: number;
    coverage: number;
    energySale: number;
    totalBalance: number;
}


export class PVSelfBillingResults {
    power: number;
    energy: number;
    total: number;
}


export class PVSelfFinancialResults {
    pvSelfFinancialDataId: number;
    initialInstallationCost: number;
    totalInstallationCost: number;
    installationCosts: PVSelfInstallationCosts;
    financedAmount: number;
    ownFunds: number;
    openingCommission: number;
    lackFee: number;
    loanFee: number;
    tir: number;
    van: number;
    amortization: number;
    yearlyFinancialData: PVSelfYearlyFinancialData[];

    peakPower: number;
    nominalPower: number;

    constructor() {
        this.yearlyFinancialData = [];
        this.installationCosts = new PVSelfInstallationCosts();
    }
}


export class PVSelfYearlyFinancialData {
    period: number;
    loan: number;
    outputs: number;
    selfConsumptionIncome: number;
    saleIncome: number;
    balance: number;
    accumulatedBalance: number;
}


export class PVSelfInstallationCosts {
    initialCost: number;
    extraCostInMoney: number;
    extraCostInPercentage: number;
    finalCost: number;
    finalWpCost: number;
    initialCostVAT: number;
    extraCostInMoneyVAT: number;
    extraCostInPercentageVAT: number;
    finalCostVAT: number;
    finalWpCostVAT: number;
    initialCostWithVAT: number;
    extraCostInMoneyWithVAT: number;
    extraCostInPercentageWithVAT: number;
    finalCostWithVAT: number;
    finalWpCostWithVAT: number;
}


export class PVSelfLossesResults {
    soiling: number[];
    iam: number[];
    radiation: number[];
    temperature: number[];
    tolerance: number[];
    mismatch: number[];
    cabling: number[];
    inverter: number[];
    shading: number[];
    highTension: number[];
    unavailability: number[];


    soilingPercentage: number;
    iamPercentage: number;
    radiationPercentage: number;
    temperaturePercentage: number;
    tolerancePercentage: number;
    mismatchPercentage: number;
    cablingPercentage: number;
    inverterPercentage: number;
    shadingPercentage: number;
    highTensionPercentage: number;
    unavailabilityPercentage: number;
}

