import { Component, OnInit } from '@angular/core';
import { PVSelfBalanceResults } from 'src/app/modules/pvself/models/PVSelfResultsModel.model';
import { PVSelfEngineService } from 'src/app/modules/pvself/services/pvself-engine.service';
import { periodChartOpts, _periodChartColors } from './periodsChartOptions';

@Component({
  selector: 'pplus-pvself-balance-periods-economic-chart',
  templateUrl: './pvself-balance-periods-economic-chart.component.html',
  styleUrls: ['./pvself-balance-periods-economic-chart.component.scss']
})
export class PVSelfBalancePeriodsEconomicChartComponent implements OnInit {
  balanceResults: PVSelfBalanceResults;
  periodChartOptions: any;

  initPeriodChartOptions = {
    renderer: 'svg',
    height: 200
  };

  constructor(private engineService: PVSelfEngineService) { }


  ngOnInit() {
    this.periodChartOptions = periodChartOpts;

    this.engineService.balanceEnergyResults.subscribe((res: PVSelfBalanceResults) => {
      this.balanceResults = res;
      if (res !== null) { this.showPeriodsChart(); }
    });
  }

  showPeriodsChart() {

    this.periodChartOptions.series = [];

    for (let i = 0; i < 6; i++) {
      this.periodChartOptions.series.push(this.createPeriodChartSerie('P' + (i + 1), 'A', _periodChartColors[i],
        [this.balanceResults.economics.billingBeforeWTaxesByPeriod[i], this.balanceResults.economics.selfConsumptionSavingWCompensationWTaxesByPeriod[i],
        this.balanceResults.economics.energySaleWTaxesByPeriod[i]]));
    }
    this.periodChartOptions = { ...this.periodChartOptions, ...this.periodChartOptions };
  }

  createPeriodChartSerie(name: string, stack: string, color: string, data: any) {
    const serie = {
      name: name,
      type: 'bar',
      stack: stack,
      itemStyle: {
        color: color
      },
      data: data,
      barWidth: 20
    };

    return serie;
  }



}
