import { Component, OnInit, Output, EventEmitter, ElementRef, TemplateRef } from '@angular/core';
import { ChecklistDatabase } from '../tree-data/tree-data.service';
import { ItemNode, TreeData } from '../../models/components/tree-data.model';
import { EntitySelectorService } from './services/entity-selector.service';
import { NbDialogService } from '@nebular/theme';


// interface IEntity2 {
//   code: string;
//   description: string;
//   entityId: number;
//   parentEntityId: number;
//   entities: IEntity2[];
// }

@Component({
  selector: 'pplus-entitySelector',
  templateUrl: './entity-selector.component.html',
  styleUrls: ['./entity-selector.component.scss']
})
export class EntitySelectorComponent implements OnInit {
  @Output() selectedChange = new EventEmitter<ItemNode[]>();
  @Output() close = new EventEmitter<any>();
  selectedNodes: ItemNode[];
  dataSource: TreeData[];
  dialogRef;
  constructor(private treeDataService: ChecklistDatabase,
    private entitySelectorService: EntitySelectorService,
    private dialog: NbDialogService) {
    this.entitySelectorService.currentdata.subscribe((data: TreeData[]) => {
      this.dataSource = JSON.parse(JSON.stringify(data));
      this.treeDataService.initialize(data);
    });
  }

  openDialog(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialog.open(dialog, { hasScroll: false });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getSelectedData(data: ItemNode[]): void {
    this.selectedNodes = data;
  }

  ngOnInit() {
    this.selectedNodes = null;
  }

  discardChanges() {
    this.entitySelectorService.changeData(this.dataSource);
    this.selectedNodes = null;
    this.close.emit();
  }

  saveChanges() {
    this.entitySelectorService.changeData(this.treeDataService.externalData);
    this.entitySelectorService.changeData(this.dataSource);
    this.selectedChange.emit(this.selectedNodes);
    this.close.emit();
    this.closeDialog();
  }
}
