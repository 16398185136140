import { Component, OnInit, Output, EventEmitter, Input, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'pplus-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {
  @Output() changeState = new EventEmitter<{ previousValue: boolean, currentValue: boolean}>();
  @Input() state: boolean | false;
  @Input() indeterminate: boolean | false;
  private previousValue: boolean;

  constructor() { }

  ngOnInit() { }

  changeChecked(type: boolean) {
    this.changeState.emit({previousValue: this.previousValue, currentValue: type});
    this.previousValue = type;
  }
}
