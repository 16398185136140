export const SERVICESTYPE_HEADERS = [
  {
    headerName: 'ID',
    rowDrag: true,
    minWidth: 100,
    field: 'Id',
    filter: 'agTextColumnFilter',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
    headerCheckboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
    resizable: true,
    status: false,
  },


  {
    headerName: 'Nombre de Servicio',
    minWidth: 100,
    field: 'serviceName',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Duración del Servicio',
    rowDrag: true,
    minWidth: 100,
    field: 'ServiceDuration',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Cadencia de Facturación',
    rowDrag: true,
    minWidth: 100,
    field: 'cadenceBilling',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Importe',
    field: 'amount',
    filter: 'agTextColumnFilter',
    minWidth: 100,
    resizable: true,
    status: true,
  },
];
