import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PVSelfCalculation, PVSelfCalculationConsumer, MarketType } from '../../../models/PvSelfCalculation.model';
import { PVSelfMyConfigurationService } from '../../../services/pvself-my-configuration.service';
import { PVSelfCalculationService } from '../../../services/pvself-calculation.service';
import { ConsumptionRateService } from 'projects/pplus/src/lib/services/rate.service';
import { ConsumptionRate } from 'projects/pplus/src/lib/models/consumptionRate.model';
import { PVSelfCalendarHeaderComponent } from '../../../shared/components/pvself-calendar-header/pvself-calendar-header.component';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material';
import { PVSelfDateAdapter, PVSELF_DATE_FORMATS } from '../../../shared/components/pvself-calendar-header/pvself-date-adapter';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Entity } from 'projects/pplus/src/lib/models/entity.model';
import { EntitiesService } from 'projects/pplus/src/lib/services/entities.service';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: DateAdapter, useClass: PVSelfDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ]
})

export class RateComponent implements OnInit {
  @Output() private rateChanged: EventEmitter<void> = new EventEmitter<void>();
  @Input() tabCreationData: any;
  _calculation: PVSelfCalculation;
  consumptionRates: ConsumptionRate[];
  gridApi;
  minDate = new Date(2018, 0, 1);
  maxDate = new Date(2018, 11, 31);
  selectedFreeConsumptionRateId: number;
  selectedRegulatedConsumptionRateId: number;
  calendarHeader: any;
  _applicationEntity: Entity;


  periodicity = [
    { id: 1, name: 'COMMON.ANNUAL' },
    { id: 2, name: 'COMMON.SIXMONTHLY' },
    { id: 3, name: 'COMMON.FOURMONTHLY' },
    { id: 4, name: 'COMMON.QUARTERLY' },
    { id: 5, name: 'COMMON.BIMONTHLY' },
    { id: 6, name: 'COMMON.MONTHLY' }
  ];
  columnDefs = [];


  constructor(private translate: TranslateService,
    private rateService: ConsumptionRateService,
    private myConfigurationService: PVSelfMyConfigurationService,
    private calculationService: PVSelfCalculationService,
    private entitiesService: EntitiesService) {
  }

  ngOnInit() {
    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });

    this.entitiesService.applicationEntity.subscribe(res => {
      this._applicationEntity = res;
    });


    this.calendarHeader = PVSelfCalendarHeaderComponent;
    this.minDate = new Date(this._calculation.calculateYear, 0, 1);
    this.maxDate = new Date(this._calculation.calculateYear, 11, 31);

    // Si la pestaña es de nueva creación (se está creando desde el botón + de Tabs) se crea un consumidor,
    // y se almacena en el tab
    if (this.tabCreationData.tabContentOrigin === 'new') {
      this.createNewPVSelfConsumer();
    } else {
      //      this._consumer = this.tabCreationData.consumer;
    }

    this.selectedFreeConsumptionRateId = this.tabCreationData.consumer.rates[0].rateId;
    this.selectedRegulatedConsumptionRateId = 15;

    this.updateContractColumns();
    this.getConsumptionRates();
  }


  updateContractColumns() {
    this.columnDefs = [
      { headerName: this.translate.instant('COMMON.PERIOD'), field: 'period' },
      { headerName: this.translate.instant('PVSELF.HIREDPOWER'), field: 'power', type: 'numericColumn', editable: this._calculation !== undefined ? !this._calculation.locked : true, filter: 'agNumberColumnFilter' },
      {
        headerName: this.translate.instant('PVSELF.POWERTERM'),
        field: 'powerPurchasePrice', type: 'numericColumn', editable: this._calculation !== undefined ? !this._calculation.locked : true, hide: this.tabCreationData.consumer.marketType === MarketType.Regulated
      },
      {
        headerName: this.translate.instant('PVSELF.ENERGYTERM'),
        field: 'energyPurchasePrice', type: 'numericColumn', editable: this._calculation !== undefined ? !this._calculation.locked : true, hide: this.tabCreationData.consumer.marketType === MarketType.Regulated
      },
      {
        headerName: this.translate.instant('PVSELF.ENERGYSALE'),
        field: 'energySalePrice', type: 'numericColumn', editable: this._calculation !== undefined ? !this._calculation.locked : true, hide: this.tabCreationData.consumer.marketType === MarketType.Regulated
      }
    ];
  }



  createNewPVSelfConsumer() {
    this.tabCreationData.consumer = new PVSelfCalculationConsumer(this._calculation.calculateYear);
    //    this.tabCreationData.consumer = this._consumer;
    this.myConfigurationService.getEntityMyConfiguration(this._applicationEntity.entityId).subscribe(res => {
      this.tabCreationData.consumer.dailyHabits = res.consumptionHabits.daily;
      this.tabCreationData.consumer.weeklyHabits = res.consumptionHabits.weekly;
      this.tabCreationData.consumer.monthlyHabits = res.consumptionHabits.monthly;
      this.tabCreationData.consumer.marketType = res.marketType;
      this.tabCreationData.consumer.consumptionTemplateId = res.consumptionTemplateId;
      this.tabCreationData.consumer.consumptionTemplateUsageId = res.consumptionTemplateUsageId;
      this.tabCreationData.consumer.rates[0].billingPeriod = res.billingPeriod;
      this.tabCreationData.consumer.rates[0].contracts = res.contracts;
      this.tabCreationData.consumer.rates[0].rateId = res.consumptionRateId;
      this.selectedFreeConsumptionRateId = this.tabCreationData.consumer.rates[0].rateId;
      //      this.tabCreationData.consumer = this._consumer;
    });
  }


  getConsumptionRates() {
    this.rateService.getByMarketType(this.tabCreationData.consumer.marketType).subscribe(res => {
      this.consumptionRates = res;
    });
  }


  updateDefaultPrices(rateId: number) {

    if (this.tabCreationData.consumer.marketType === MarketType.Free) { this.selectedFreeConsumptionRateId = rateId; }
    if (this.tabCreationData.consumer.marketType === MarketType.Regulated) { this.selectedRegulatedConsumptionRateId = rateId; }

    this.myConfigurationService.getEntityMyConfigurationContracts(rateId, this._applicationEntity.entityId).subscribe(res => {
      this.tabCreationData.consumer.rates[0].contracts = res;
    });
  }


  setMarketType(marketType: number) {
    this.rateService.getByMarketType(marketType).subscribe(res => {
      this.consumptionRates = res;

      if (marketType === MarketType.Free) { this.tabCreationData.consumer.rates[0].rateId = this.selectedFreeConsumptionRateId; }
      if (marketType === MarketType.Regulated) { this.tabCreationData.consumer.rates[0].rateId = this.selectedRegulatedConsumptionRateId; }

      this.updateContractColumns();
    });
  }

  rateChange() {
    this.rateChanged.emit();
  }


  cellValueChanged(value) {

    if (this.tabCreationData.consumer.rates[0].rateId <= 7 && value.colDef.field === 'power') {

      const nRows = this.gridApi.getDisplayedRowCount();

      for (let i = 0; i < nRows; i++) {
        if (value.oldValue !== value.newValue) {
          const rowNode = this.gridApi.getDisplayedRowAtIndex(i);
          rowNode.setDataValue('power', value.value);
        }
      }
    }


  }


  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  }

}

