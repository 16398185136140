import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { InverterModel } from '../../models/elements/inverterModel.model';

@Injectable({
  providedIn: 'root'
})
export class InverterModelService {

  constructor(private httpClient: HttpClient) {
  }

  getByManufacturer(ManufacturerId: number): Observable<InverterModel[]> {
    return this.httpClient.get<InverterModel[]>(environment.API_URL + '/api/admin/elements/invertermodels/getByManufacturer/' + ManufacturerId).pipe(
      map(data => data));
  }

}
