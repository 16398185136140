import { Injectable } from '@angular/core';
import { DynamicForm, Fields } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PlantGroupsDetailsService {

  private readonly generalFields: Fields[] = [
    {
      name: 'plantGroupId',
      type: 'text',
      placeholder: 'ID',
      value: '',
      disabled: true,
    },
    {
      name: 'name',
      type: 'text',
      placeholder: 'NOMBRE',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(100),
      ],
    },
    {
      name: 'demoName',
      type: 'text',
      placeholder: 'NOMBRE DEMO',
      value: '',
      validators: [
        Validators.maxLength(100),
      ],
    },
    {
      name: 'code',
      type: 'text',
      placeholder: 'CÓDIGO',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(13),
      ],
    },
    {
      name: 'demoCode',
      type: 'text',
      placeholder: 'CÓDIGO DEMO',
      value: '',
      validators: [
        Validators.maxLength(13),
      ],
    },
    {
      name: 'creationDate',
      type: 'text',
      placeholder: 'FECHA DE ALTA',
      value: '',
      disabled: true,
    },
    {
      name: 'deletionDate',
      type: 'text',
      placeholder: 'FECHA DE BAJA',
      value: '',
      disabled: true,
    },
    {
      name: 'activated',
      type: 'checkbox',
      placeholder: 'ACTIVO',
      value: true,
    },
    {
      name: 'nominalPower',
      type: 'text',
      placeholder: 'POTENCIA NOMINAL (MW)',
      value: '',
      disabled: true,
    },
    {
      name: 'peakPower',
      type: 'text',
      placeholder: 'POTENCIA PICO (MW)',
      value: '',
      disabled: true,
    },
    {
      name: 'entityId',
      type: 'select',
      label: 'ENTIDAD PROPIETARIA',
      value: '',
      validators: [
        Validators.required,
      ],
      options: [],
    },
    {
      name: 'responsableOfTtr',
      type: 'text',
      placeholder: 'ENCARGADO DE LA TTR',
      value: '',
    },
  ];

  private readonly associatedFields: Fields[] = [
    {
      name: 'assignedPlants',
      type: 'select',
      label: 'PLANTAS ASOCIADAS',
      multiple: true,
      searchName: 'plantsSearch',
      value: [],
      options: [],
    },
    {
      name: 'assignedEntities',
      type: 'select',
      label: 'ENTIDADES',
      multiple: true,
      searchName: 'entitiesSearch',
      value: [],
      options: [],
    },
  ];

  constructor() {}

  getDynamicForm(): DynamicForm[] {
    return [
      {
        title: 'DATOS GENERALES',
        fields: this.generalFields,
        open: true,
      },
      {
        title: 'DATOS ASOCIADOS',
        fields: this.associatedFields,
      }
    ];
  }

}
