import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RowDataService {
  private rowData  = new BehaviorSubject({});
  currentRowData = this.rowData.asObservable();

  private type  = new BehaviorSubject(null);
  currentType = this.type.asObservable();

  constructor() {}

  updateRowData(data: any) {
    this.rowData.next(data);
  }

  updateType(data: any) {
    this.type.next(data);
  }
}
