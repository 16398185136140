import { Component, OnInit } from '@angular/core';
import { GlobalType } from '../../../models/selfview.model';
import { TranslateService } from '@ngx-translate/core';
import { SelfViewService } from '../../../services/selfview-general.service';
import { chartColors } from '../../../selfview.graph.colors';

@Component({
  selector: 'pplus-selfview-active-periods-chart',
  templateUrl: './selfview-active-periods-chart.component.html',
  styleUrls: ['./selfview-active-periods-chart.component.scss']
})
export class SelfviewActivePeriodsChartComponent implements OnInit {
  flipped: boolean;
  globalType: GlobalType;
  compareMode = false;
  activeData: any;
  activeDataCompare: any;
  periodChartOptions: any;
  periodChartOptionsNormal: any;
  type;
  WhToKwh = 0.001;

  initForPeriodGraph = {
    renderer: 'svg',
    height: 310
  };

  ps = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'];
  psData = {
    'P1': true,
    'P2': true,
    'P3': true,
    'P4': true,
    'P5': true,
    'P6': true,
    'P1*': true,
    'P2*': true,
    'P3*': true,
    'P4*': true,
    'P5*': true,
    'P6*': true,
  };

  graphInitOptions = {
    renderer: 'svg',
    width: null,
    height: 400
  };



  constructor(private translate: TranslateService, private selfViewService: SelfViewService) {
    this.flipped = true;
  }


  ngOnInit() {
    this.selfViewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;

      this.selfViewService.dataActive.subscribe((data: any) => {
        if (data) {
          this.activeData = data; // data.activeData;
          this.showChartForGlobalType();
        }
      });

      this.selfViewService.dataActiveCompare.subscribe((data: any) => {
        this.showChartComparisonForGlobalType(data);
      });
    });
  }


  showChartForGlobalType() {

    if (this.activeData !== null) {
      if (this.globalType === GlobalType.Energy) {
        this.activeData = this.activeData;
        this.type = false;
        this.initiatePowerChart();
      } else if (this.globalType === GlobalType.Economic) {
        // this.reactiveDataEconomico = this.selfViewDataEconomico;
        // this.type = true;
        // this.initiateEconomicChart();
      }

      if (this.compareMode) {
        if (this.activeDataCompare !== null && this.globalType === GlobalType.Energy) {
          this.initiatePowerChartComparison();
        }
      }
    }
  }


  initiatePowerChart() {
    const series = {};
    this.ps.forEach((element, index) => {
      series[`${element}`] = [
        this.activeData.totalConsumptionByPeriod[index] * this.WhToKwh,
        this.activeData.totalNetworkConsumptionByPeriod[index] * this.WhToKwh,
        this.activeData.totalSelfConsumptionByPeriod[index] * this.WhToKwh,
        this.activeData.totalExcedentaryByPeriod[index] * this.WhToKwh,
        this.activeData.totalGeneratedByPeriod[index] * this.WhToKwh
      ];
    });

    const periodGraphBarWith = 10;
    this.periodChartOptionsNormal = {
      tooltip: {
        confine: false,
        trigger: 'axis',
        position: function (pos, params, dom, rect, size) {
          // tooltip will be fixed on the right if mouse hovering on the left,
          // and on the left if hovering on the right.
          const obj = {top: 60};
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
          return obj;
        },
        axisPointer: {
          type: 'shadow'
        },
        formatter: params => {
          let values = '';
          for (const param of params) {
            const isDefined = typeof (param) === 'undefined';
            if (!isDefined) {

              values += param.seriesName + ': ' + param.value + ' kWh <br>';
            }
          }
          return values;
        }
      },
      legend: {
        show: false,
        data: this.ps,
        selected: {}
      },
      grid: {
        top: '5%',
        left: '15%',
        right: '4%',
        bottom: '15%',
      },
      xAxis: {
        type: 'value',
        name: 'kWh',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: [10, 10, 0, 0]
        },
        axisLabel: {
          show: true,
          formatter: '{value}',
          rotate: 35,
          fontSize: 10
        }
      },
      yAxis: {
        type: 'category',
        data: ['Consumo Total', 'Consumida Red', 'Autoconsumida', 'Excedentaria', 'Neta Generada'],
        axisLabel: {
          rotate: 35,
          fontSize: 10
        }
      },
      series: [
        {
          name: 'P1',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p1},
          barWidth: periodGraphBarWith,
          data: series['P1']
        },
        {
          name: 'P2',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p2},
          barWidth: periodGraphBarWith,
          data: series['P2']
        },
        {
          name: 'P3',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p3},
          barWidth: periodGraphBarWith,
          data: series['P3']
        },
        {
          name: 'P4',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p4},
          barWidth: periodGraphBarWith,
          data: series['P4']
        },
        {
          name: 'P5',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p5},
          barWidth: periodGraphBarWith,
          data: series['P5']
        },
        {
          name: 'P6',
          type: 'bar',
          stack: 'A',
          label: {
            show: false,
            normal: {
              show: false,
              position: 'insideRight'
            }
          },
          itemStyle: {color: chartColors.p6},
          barWidth: periodGraphBarWith,
          data: series['P6']
        }
      ]

    };
    this.periodChartOptions = this.periodChartOptionsNormal;
  }




  initiatePowerChartComparison() {

    const series = {};
    console.log(this.activeDataCompare);
    this.ps.forEach( (element, index) => {
      series[`${element}*`] = [
        this.activeDataCompare.totalConsumptionByPeriod[index] * this.WhToKwh,
        this.activeDataCompare.totalNetworkConsumptionByPeriod[index] * this.WhToKwh,
        this.activeDataCompare.totalSelfConsumptionByPeriod[index] * this.WhToKwh,
        this.activeDataCompare.totalExcedentaryByPeriod[index] * this.WhToKwh,
        this.activeDataCompare.totalGeneratedByPeriod[index] * this.WhToKwh
      ];
    });

    const periodGraphBarWith = 8;

    const seriesComparison = [
      {
        name: 'P1*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p1_2},
        barWidth: periodGraphBarWith,
        data: series['P1*']
      },
      {
        name: 'P2*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p2_2},
        barWidth: periodGraphBarWith,
        data: series['P2*']
      },
      {
        name: 'P3*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p3_2},
        barWidth: periodGraphBarWith,
        data: series['P3*']
      },
      {
        name: 'P4*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p4_2},
        barWidth: periodGraphBarWith,
        data: series['P4*']
      },
      {
        name: 'P5*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p5_2},
        barWidth: periodGraphBarWith,
        data: series['P5*']
      },
      {
        name: 'P6*',
        type: 'bar',
        stack: 'B',
        label: {
          show: false,
          normal: {
            show: false,
            position: 'insideRight'
          }
        },
        itemStyle: {color: chartColors.p6_2},
        barWidth: periodGraphBarWith,
        data: series['P6*']
      }
    ];


    if (this.periodChartOptions.series.length > 6) {
      this.periodChartOptions.series = this.periodChartOptions.series.splice(0, this.periodChartOptions.series.length - 6);

    }

    const totalSeries = this.periodChartOptions.series.concat(seriesComparison);
    this.periodChartOptions.series = totalSeries;

    this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};
  }




  showChartComparisonForGlobalType(data: any) {
    if (data !== null) {
      if (data !== null) {
        this.compareMode = true;

        this.activeDataCompare = data;
        if (data !== null && this.globalType === GlobalType.Energy) {
          this.initiatePowerChartComparison();
        }
      }
    } else {
      if (this.compareMode) {

        this.psData = {
          'P1': true, 'P2': true, 'P3': true, 'P4': true, 'P5': true, 'P6': true,
          'P1*': false, 'P2*': false, 'P3*': false, 'P4*': false, 'P5*': false, 'P6*': false,
        };
        this.periodChartOptions.legend.selected = this.psData;
        this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};
        this.compareMode = false;
      } else {

      }

    }
  }


  modifySerie(serieName: string, $event) {
    const state = $event.checked;
    this.psData[`${serieName}`] = state;
    this.psData[`${serieName}*`] = state;
    this.periodChartOptions.legend.selected = this.psData;
    this.periodChartOptions = {...this.periodChartOptions, ...this.periodChartOptions};
  }


}
