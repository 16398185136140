import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { IEntityPlantGroup } from 'projects/pplus/src/lib/interfaces/interfaces';
import { PLANT_GROUP_COL_DEFS } from './plant-group.model';

@Injectable({
  providedIn: 'root'
})
export class PlantGroupService {
  private readonly apiUrl = environment.API_URL + '/api/plant/plantgroup';
  private readonly apiScrollInfinite = this.apiUrl + '/get-plantGroup';

  constructor(private http: HttpClient) {}

  getInfiniteScroll(startRow: number, endRow: number, sortModel: any, filterModel: any): Observable<any> {
    const body = { startRow, endRow, sortModel, filterModel };
    return this.http.post(this.apiScrollInfinite, body).pipe(map(response => response));
  }

  getGeneralFilter(
    value: string, startRow: number, endRow: number, sortModel: any, filterModel: any
  ): Observable<any> {
    const columnList: string[] = [];
    for (const colDef of PLANT_GROUP_COL_DEFS) columnList.push(colDef.field);
    return this.http.post(this.apiScrollInfinite, {
      startRow, endRow, sortModel, filterModel: {
        generalFilter: {
          type: 'contains',
          filter: value.toLowerCase(),
          filterType: 'text',
        },
        ...filterModel,
      },
      columnList,
    });
  }

  getAll(): Observable<IEntityPlantGroup[]> {
    return this.http.get(this.apiUrl).pipe(
      map(response => <IEntityPlantGroup[]>response),
      catchError(() => of([]))
    );
  }

  getList(): Observable<IEntityPlantGroup[]> {
    return this.http.get(this.apiUrl + '/list').pipe(
      map(response => <IEntityPlantGroup[]>response),
      catchError(() => of([]))
    );
  }

  get(id: number): Observable<IEntityPlantGroup> {
    return this.http.get(`${this.apiUrl}/${id}`).pipe(map(response => <IEntityPlantGroup>response));
  }

  create(plantGroup: IEntityPlantGroup): Observable<IEntityPlantGroup> {
    return this.http.post(this.apiUrl, plantGroup, {observe: 'response'}).pipe(
      map(response => response.status === 200 ? <IEntityPlantGroup>response.body : null),
      catchError(() => of(null))
    );
  }

  update(id: number, plantGroup: IEntityPlantGroup): Observable<boolean> {
    return this.http.put(`${this.apiUrl}/${id}`, plantGroup, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete(`${this.apiUrl}/${id}`, {observe: 'response'}).pipe(
      map(response => response.status === 204),
      catchError(() => of(false))
    );
  }

  assignPlants(plantGroupId: number, plantIds: number[]): Observable<boolean> {
    if (plantIds && plantIds.length >= 0) {
      return this.http.post(this.apiUrl + '/assignplants', {plantGroupId, plantIds}, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else return of(true);
  }

  assignEntities(plantGroupId: number, entityIds: number[]): Observable<boolean> {
    if (entityIds && entityIds.length >= 0) {
      return this.http.post(this.apiUrl + '/assignentity', {plantGroupId, entityIds}, {observe: 'response'}).pipe(
        map(response => response.status === 200),
        catchError(() => of(false))
      );
    } else return of(true);
  }

}
