import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallbackGoogleComponent } from './callback-google.component';
import { CallbackFacebookComponent } from './callback-facebook.component';
import { CallbackLinkedinComponent } from './callback-linkedin.component';
import { DataService } from '../services/data.service';
import { Route, RouterModule } from '@angular/router';

export const CallbackRouter: Route [] = [
  {
    path: 'google',
    component: CallbackGoogleComponent
  },
  {
    path: 'facebook',
    component: CallbackFacebookComponent
  },
  {
    path: 'linkedin',
    component: CallbackLinkedinComponent
  }
];

@NgModule({
  declarations: [CallbackGoogleComponent, CallbackFacebookComponent, CallbackLinkedinComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(CallbackRouter)
  ],
  exports: [ ],
  providers: [
    DataService
  ]
})
export class CallbackModule { }
