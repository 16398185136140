export const PANELS_HEADERS = [
  {
    headerName: 'ID',
    rowDrag: true,
    minWidth: 100,
    field: 'Id',
    filter: 'agTextColumnFilter',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
    headerCheckboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
    resizable: true,
    status: false,
  },


  {
    headerName: 'Marca/Modelo',
    minWidth: 100,
    field: 'Brand_Model',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Número de paneles por String',
    rowDrag: true,
    minWidth: 100,
    field: 'panelsNumberString',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'String Asociado',
    rowDrag: true,
    minWidth: 100,
    field: 'AsociatedString',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Potencia pico',
    rowDrag: true,
    minWidth: 100,
    field: 'PowerPeak',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Fecha de Alta',
    rowDrag: true,
    minWidth: 100,
    field: 'CreationDate',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Fecha de Baja',
    rowDrag: true,
    minWidth: 100,
    field: 'DeletionDate',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
  {
    headerName: 'Nota',
    rowDrag: true,
    minWidth: 100,
    field: 'Note',
    filter: 'agTextColumnFilter',
    resizable: true,
    status: true,
  },
];
