import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ConsumptionBorderMeterService } from '../consumption-border-meter.service';
import { Subscription } from 'rxjs/Subscription';
import { MeterEnergyByPeriodResults, MeterPlantInstallation } from '../consumption-border-meter.models';
import { PPlusEnergyDirection, PPlusMeterType } from '../../../../models/pplus.enums';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material';

@Component({
  selector: 'pplus-consumption-meter',
  templateUrl: './consumption-meter.component.html',
  styleUrls: ['./consumption-meter.component.scss']
})
export class ConsumptionMeterComponent implements OnInit, OnDestroy {

  @ViewChild('metersInput', {static: false}) metersInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;
  private subscriptions: Subscription = new Subscription();

  /**
   * Date range of consumption meter component
   */
  dateRange;

  /**
   * Id installation of consumption meter component
   */
  idInstallation: number;

  loaderId: string;

  /**
   * Meters  of consumption meter component
   */
  meters: MeterPlantInstallation[] = [];

  /**
   * Selected meters of consumption meter component
   */
  selectedMeters: MeterPlantInstallation[] = [];

  /**
   * Flag for total serie
   */
  total = false;

  constructor(
    private ngxLoader: NgxUiLoaderService,
    private meterService: ConsumptionBorderMeterService
  ) {

    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);

    const last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);
    last6Months.setHours(0, 0, 0);
    const today = new Date();
    today.setHours(23, 59, 59);

    this.dateRange = {
      startDate: last6Months.toUTCString(),
      endDate: today.toUTCString(),
    };

  }

  ngOnInit() {
    this.meterService.energyData$.subscribe( (data: MeterEnergyByPeriodResults) => {
      if (data) {
        this.ngxLoader.stopLoader(this.loaderId);
      }
    });

    this.meterService.meters$.subscribe( (meters: MeterPlantInstallation[]) => {
      this.total = false;
      if (meters) {
        this.meters = meters;
        if (this.meters.length === 1) {
          this.selectedMeters = this.meters;
          this.getMetersData();
        }
      }
    });
  }

  setDateRange(range) {
    this.dateRange = {
        startDate: range.startDate.toUTCString(),
        endDate: range.endDate.toUTCString(),
    };

    if (this.meterSelected.length) {
      this.getMetersData();
    }

  }

  setIdInstallation(id: number) {
    const last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);
    last6Months.setHours(0, 0, 0);
    const today = new Date();
    today.setHours(23, 59, 59);

    if (this.idInstallation !== id) {

      this.dateRange = {
        startDate: last6Months.toUTCString(),
        endDate: today.toUTCString(),
      };

      this.idInstallation = id;

      this.getMeters();
    }
  }

  getMeters() {
    this.meterService.getMeters(this.idInstallation, PPlusMeterType.Consumption);
  }

  getMetersData() {
    this.ngxLoader.startLoader(this.loaderId);

    const opt = {
      InstallationId: this.idInstallation,
      MeterIds: this.selectedMeters.map( meter => meter.meterId),
      From: this.dateRange.startDate,
      To: this.dateRange.endDate,
      EnergyDirection: PPlusEnergyDirection.Consumption,
      IncludeTotal: this.total
    };

    this.meterService.getEnergyData(opt);

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  meterSelected(event: MatAutocompleteSelectedEvent){
    console.log(event);
    this.selectedMeters.push(event.option.value);
    this.metersInput.nativeElement.value = '';

    if (this.meterSelected.length) {
      this.getMetersData();
    }
  }


  removeSelectedMeter(meter: MeterPlantInstallation): void {
    const index = this.selectedMeters.indexOf(meter);

    if (index >= 0) {
      this.selectedMeters.splice(index, 1);
      if (this.meterSelected.length) {
        this.getMetersData();
      }
    }
  }

  onChangeTotal() {
    if (this.meterSelected.length) {
      this.getMetersData();
    }
  }

}
