export const PERIODS = {
    P1: 'P1',
    P2: 'P2',
    P3: 'P3',
    P4: 'P4',
    P5: 'P5',
    P6: 'P6',
};

export const ACTIVE_ENERGY_SERIES_DATA = {
    CONSUMPTION: {
        id: 'consumption',
        label: 'Consumo Total',
        periodColorKey: 'consumo',
    },
    NETWORK_CONSUMPTION: {
        id: 'networkConsumed',
        label: 'Consumida Red',
        periodColorKey: 'consumo_red',
    },
    EXCEDENTARY: {
        id: 'realExcedentary',
        label: 'Excedentaria',
        periodColorKey: 'excedente',
    },
    SELF_CONSUMPTION: {
        id: 'autoconsumed',
        label: 'Autoconsumo',
        periodColorKey: 'autoconsumo',
    },
    GENERATED: {
        id: 'netGenerated',
        label: 'Neta Generada',
        periodColorKey: 'netaGenerada',
    }
};




export const ACTIVE_ECONOMIC_SERIES_DATA = {
    BILLING: {
        id: 'billingAfterSelfConsumptionWTaxes',
        label: 'Facturación',
        periodColorKey: 'billing',
    },
    SELFCONSUMPTIONSAVING: {
        id: 'selfConsumptionSavingWCompensationWTaxes',
        label: 'Ahorro autoconsumo',
        periodColorKey: 'selfconsumptionsaving',
    },
    COMPENSATION: {
        id: 'compensationWTaxes',
        label: 'Compensación',
        periodColorKey: 'compensation',
    },
    ENERGYSALE: {
        id: 'energySaleWTaxes',
        label: 'Venta energía',
        periodColorKey: 'energySale',
    }
};


export const seriesActiveBarChart: string[] = [
    'Consumption P1',
    'Consumption P2',
    'Consumption P3',
    'Consumption P4',
    'Consumption P5',
    'Consumption P6',
    'Network Consumption P1',
    'Network Consumption P2',
    'Network Consumption P3',
    'Network Consumption P4',
    'Network Consumption P5',
    'Network Consumption P6',
    'SelfConsumption P1',
    'SelfConsumption P2',
    'SelfConsumption P3',
    'SelfConsumption P4',
    'SelfConsumption P5',
    'SelfConsumption P6',
    'Excedentary P1',
    'Excedentary P2',
    'Excedentary P3',
    'Excedentary P4',
    'Excedentary P5',
    'Excedentary P6',
    'Net Generated P1',
    'Net Generated P2',
    'Net Generated P3',
    'Net Generated P4',
    'Net Generated P5',
    'Net Generated P6',
];
