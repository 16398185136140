import { chartColors } from '../selfview.graph.colors';

export const INVERTERS_LINE_CHART_OPTIONS = {
  legend: {
    show: true,
    bottom: '0'
  },
  tooltip: {
    show: true,
    triggerOn: 'mousemove',
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    show: true,
    left: 'center',
    itemSize: 20,
    top: '0%',
    feature: {
      saveAsImage: {title: 'Descargar'}
    }
  },
  xAxis: [{
    type: 'time',
    axisLabel: {
      fontSize: 10,
      formatter: (function (value) {
        const time = new Date(value);
        const resp = time.toLocaleDateString('es-ES') + ' \n ' + time.toLocaleTimeString('es-ES', {
          hour: '2-digit',
          minute: '2-digit'
        });
        return resp;
      })
    },
    splitLine: {
      show: false,
    }
  }],
  yAxis: {
    name: 'kVArh',
    gridIndex: 0,
    axisLabel: {
      inside: false,
      formatter: '{value}',
      fontSize: 10
    }
  },
  series: [
  ]
};

/**
 * Bar char options
 */
export const INVERTERS_BAR_CHART_OPTIONS = {
  legend: {
    show: false
  },
  tooltip: {
    trigger: 'item',
    textStyle: {
      fontSize: 10,
      width: 500
    },
    formatter: (params) => {
      let values = '';
      values += params.seriesName + ': ' + params.data + ' kWh <br>';
      return values;
    }
  },
  toolbox: {
    show: true,
    left: 'center',
    itemSize: 20,
    top: '0%',
    feature: {
      dataZoom: {
        show: true,
        yAxisIndex: true,
        title: {zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar'}
      },
      restore: {show: true, title: 'Anular Todo'},
      saveAsImage: {title: 'Descargar'}
    }
  },
  xAxis: [{
    type: 'category'
  }],
  yAxis: {
    name: 'kWh',
    gridIndex: 0,
    axisLabel: {
      inside: false,
      formatter: '{value}',
      fontSize: 10
    }
  },
  dataZoom: [
    {
      type: 'inside'
    }
  ],
  series: []
};



export const instantChartInitOptions = {
  renderer: 'svg',
  width: 125,
  height: 125
};

export const INSTANT_CHART_OPTIONS = {
  tooltip: {
      trigger: 'item',
      formatter: '{b0}: {c0}%',
  },
  legend: {
      show: false
  },
  series: [
    {
        name: 'Instant',
        type: 'gauge',
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 100,
        detail: {formatter: '{value}%', fontSize: 14, offsetCenter: [0, '9%'], color: chartColors.cobertura},
        data: [{value: 0}],
        axisLine: {
            lineStyle: {
                color: [
                  [0.01, chartColors.cobertura],
                  [1, '#cccccc']
                ],
                width: 12,
                shadowColor : '#fff',
                shadowBlur: 10
            }
        },
        axisTick: {
            show: false
        },
        pointer: {
            show: false
        },
        axisLabel: {
            show: false
        },
        splitLine: {
            show: false
        }
    }
]
};
