import { GridType, PVSelfBillingPeriod, PVSelfConsumptionContract, PVSelfConsumptionHabits, PVSelfPredimensionedValues, PVSelfFieldTechnologyType, PVSelfFieldCost, MarketType } from './PvSelfCalculation.model';
import { MeteorologicalData } from 'projects/pplus/src/lib/models/meteorologicalData.model';


export class PVSelfMyConfiguration {
    latitude: number;
    longitude: number;
    gridType: GridType;
    radiationData: MeteorologicalData;
    minTempData: MeteorologicalData;
    maxTempData: MeteorologicalData;
    inclination: number;
    orientation: number;
    technology: PVSelfFieldTechnologyType;
    marketType: MarketType;
    consumptionRateId: number;
    billingPeriod: PVSelfBillingPeriod;
    contracts: PVSelfConsumptionContract[];
    consumptionHabits: PVSelfConsumptionHabits;
    consumptionTemplateId: number;
    consumptionTemplateUsageId: number;
    generalIPC: number;
    electricIPC: number;
    electricityPurchaseTax: number;
    electricitySaleTax: number;
    financialInterest: number;
    saleIVA: number;
    purchaseIVA: number;
    deductibleIVA: boolean;
    predimensionedValues: PVSelfPredimensionedValues;
    panelManufacturerId: number;
    inverterManufacturerId: number;
    panelId: number;
    inverterId: number;
    numOfInverters: number;
    numSerialPanels: number;
    numInverterSerials: number;
    automaticPIConfig: boolean;
    fieldCosts: PVSelfFieldCost[];
}

