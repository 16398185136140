import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PermittedApplication, PermittedPlant, EffectivePermission } from '../../models/permissions/effectivePermissions.model';

@Injectable({
  providedIn: 'root'
})
export class EffectivePermissionsService {
  constructor(private httpClient: HttpClient) {
  }

  getPermittedApplications(EntityId: number, UserId: number): Observable<PermittedApplication[]> {
    return this.httpClient.get<PermittedApplication[]>(environment.API_URL +
      '/api/effectivepermissions/GetPermittedApplications?EntityId=' + EntityId + '&UserId=' + UserId).pipe(
        map(data => data));
  }

  getPermittedPlants(EntityId: number, UserId: number, ApplicationId: number): Observable<PermittedPlant[]> {
    return this.httpClient.get<PermittedPlant[]>(environment.API_URL +
      '/api/effectivepermissions/GetPermittedPlants?EntityId=' + EntityId + '&UserId=' + UserId + '&ApplicationId=' + ApplicationId).pipe(
        map(data => data));
  }

  getEffectivePermissions(EntityId: number, UserId: number, OperationId: number, ElementTypeId?: number, EntityTypeId?: number): Observable<EffectivePermission> {
    return this.httpClient.get<EffectivePermission>(environment.API_URL +
      '/api/effectivepermissions/GetEffectivePermissionInfo?EntityIds=' + EntityId + '&UserId=' + UserId + '&OperationId=' + OperationId + '&ElementTypeId=' + ElementTypeId).pipe(
        map(data => data));
  }


}

