import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NbDialogService } from '@nebular/theme';
import { DomainsService } from '../../domains.service';
import { EntitiesService } from '../../../entities/entities.service';
import { map } from 'rxjs/operators';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { IEntityDomain } from 'projects/pplus/src/lib/interfaces/interfaces';
import { AdminService } from '../../../admin.service';
import { DomainDetailsService } from './domain-details.service';

@Component({
  selector: 'app-domain-details',
  templateUrl: './domain-details.component.html',
  styleUrls: ['./domain-details.component.scss']
})
export class DomainDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  data: IEntityDomain;

  formGroup: FormGroup;
  formData: DynamicForm[];

  constructor(
    private dataRow: RowDataService,
    private domainService: DomainsService,
    private entitiesService: EntitiesService,
    private dialog: NbDialogService,
    private adminService: AdminService,
    private domainDetailsService: DomainDetailsService,
  ) {
    this.formData = this.domainDetailsService.getDynamicForm();
  }

  generateForm(data: DynamicForm[]) {
    this.entitiesService.getEntitiesList().subscribe(entities => {
      const fieldsCtrls = {};
      data.forEach(item => {
        const fields = item.fields;
        fields.forEach(f => {
          if (f.name === 'entityId') {
            const opts = [];
            entities.map(o => {
              opts.push({
                label: o.code,
                value: o.entityId,
              });
            });
            f.options = opts;
          }
          fieldsCtrls[f.name] = new FormControl(f.value , f.validators);
        });
      });
      this.formGroup = new FormGroup(fieldsCtrls);
    });
  }

  ngOnInit() {
    this.dataRow.currentRowData.pipe(map(o => {
      this.formData.forEach(data => {
        const fields = data.fields;
        fields.map(field => {
          if (o[field.name]) {
            field.value = o[field.name];
          } else field.value = '';
        });
      });
      return o;
    })).subscribe(data => {
      this.data = <IEntityDomain>data;
      this.generateForm(this.formData);
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IEntityDomain>this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.domainService.createDomain(data).subscribe(newDomain => {
        if (newDomain != null) {
          this.formGroup.patchValue({entityDomainId: this.data.entityDomainId = newDomain.entityDomainId});
          this.adminService.showToast('success', 'Se ha creado el nuevo dominio: ' + data.fqdn, 'Elemento creado');
          this.updateDelete.emit(true);
        } else {
          this.adminService.showToast('danger', 'Se ha producido un error al crear el dominio: ' + data.fqdn, 'Error');
        }
      });
    }
  }

  update() {
    if (this.data.entityDomainId == null) {
      this.onSubmit();
    } else if (this.formGroup.invalid) {
      this.adminService.showFormValidationErrors(this.formGroup, this.formData);
    } else {
      const data = <IEntityDomain>this.formGroup.value;
      data.activated = Boolean(data.activated);
      this.domainService.updateDomain(this.data.entityDomainId, data).subscribe(success => {
        if (success) {
          this.adminService.showToast('success', 'Se ha actualizado el dominio: ' + data.fqdn, 'Elemento actualizado');
          this.updateDelete.emit();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al actualizar el dominio: ' + data.fqdn,
              'Error');
        }
      });
    }
  }

  exit() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({ opt, mode: 'default' });
    this.clearData();
  }

  delete() {
    if (this.data.entityDomainId == null) {
      this.adminService.showToast('info', 'Seleccione un dominio', 'Info');
    } else {
      this.domainService.deleteDomain(this.data.entityDomainId).subscribe(success => {
        if (success) {
          this.adminService.showToast('success', 'Se ha borrado el dominio: ' + this.data.fqdn, 'Elemento eliminado');
          this.updateDelete.emit();
          this.clearData();
        } else {
          this.adminService.showToast('danger',
              'Se ha producido un error al borrar el dominio: ' + this.data.fqdn,
              'Error');
        }
      });
    }
  }

  copy() {
    if (this.data.entityDomainId == null) {
      this.adminService.showToast('info', 'Seleccione un dominio para copiar la información', 'Info');
    } else {
      this.formGroup.setValue(Object.assign(this.data, this.formGroup.value, {
        entityDomainId: null,
        fqdn: '',
      }));
    }
  }

  clearData() {
    this.data = <IEntityDomain>{};
    this.formData.forEach(data => {
      const fields = data.fields;
      fields.map(field => {
        field.value = '';
      });
    });
    this.generateForm(this.formData);
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.entityDomainId == null) {
      this.adminService.showToast('info', 'No hay ningún dominio seleccionado', 'Info');
    } else {
      this.dialog.open(dialog, { context: '¿Eliminar el dominio?', hasScroll: false });
    }
  }

}
