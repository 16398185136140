import { Injectable } from '@angular/core';
import { ElementType } from '../models/elementType.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ElementTypesService {

  constructor(private httpClient: HttpClient) {
  }

  getList(): Observable<ElementType[]> {
    return this.httpClient.get<ElementType[]>(environment.API_URL + '/api/admin/elementtypes/getList').pipe(
      map(data => data));
  }
}
