import { Component, OnInit, ViewChild } from '@angular/core';
import { FinancialSummaryComponent } from './financial-summary/financial-summary.component';
import { PVSelfReportsService } from '../../services/pvself-reports.service';
import { PVSelfCalculation } from '../../models/PvSelfCalculation.model';
import { PVSelfCalculationService } from '../../services/pvself-calculation.service';
import { PVSelfEconomicSummaryComponent } from './pvself-economic-summary/pvself-economic-summary.component';
import { PVSelfBalanceResultsComponent } from './pvself-balance-results/pvself-balance-results.component';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  balanceFormGroup;
  economicFormGroup;
  financialFormGroup;
  wiringDiagramFormGroup;
  isLinear;

  @ViewChild(PVSelfBalanceResultsComponent, { static: true }) balanceResultsComponent: PVSelfBalanceResultsComponent;
  @ViewChild(PVSelfEconomicSummaryComponent, { static: true }) economicSummaryComponent: PVSelfEconomicSummaryComponent;
  @ViewChild(FinancialSummaryComponent, { static: true }) financialSummaryComponent: FinancialSummaryComponent;


  constructor(private reportsService: PVSelfReportsService,
    private calculationService: PVSelfCalculationService) { }

  ngOnInit() {
  }

  calcular() {
    this.balanceResultsComponent.calcular();
  }

  changedResultsView(event) {
    if (event.selectedIndex === 0) { this.balanceResultsComponent.calcular(); }
    //    if (event.selectedIndex === 0) { this.balanceComponent.calcular(); }
    if (event.selectedIndex === 1) { this.economicSummaryComponent.calcular(); }
    if (event.selectedIndex === 2) { this.financialSummaryComponent.calcular(); }
  }

  generarInforme() {

    let code: string;
    const d: Date = new Date;
    code = this.getFormattedDate(d);

    const calculation: PVSelfCalculation = this.calculationService.get();
    this.reportsService.generateResultsReport(calculation, code)
      .subscribe((res: Blob) => {
        const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        const downloadURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = code +  '.docx';
        link.click();
      });
  }


  getFormattedDate(date) {
    const year = date.getFullYear();

    let month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    let hour = (date.getHours()).toString();
    hour = hour.length > 1 ? hour : '0' + hour;

    let minute = (date.getMinutes()).toString();
    minute = minute.length > 1 ? minute : '0' + minute;

    let second = (date.getSeconds()).toString();
    second = second.length > 1 ? second : '0' + second;

    return year + month + day + hour + minute + second;
  }
}

