import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as XLSX from 'xlsx';
import { GlobalType, IPeriodicElement, ISelfViewData, ISelfViewDataEconomico, ISelfViewDto } from '../../models/selfview.model';
import { TranslateService } from '@ngx-translate/core';
import { SelfViewService } from '../../services/selfview-general.service';
import { chartColors } from '../../selfview.graph.colors';


const ELEMENT_DATA: IPeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },

];

@Component({
  selector: 'selfview-coverage',
  templateUrl: './selfview-coverage.component.html',
  styleUrls: ['./selfview-coverage.component.scss']
})
export class SecondCardComponent implements OnInit {
  @ViewChild('TABLE', { static: false, read: ElementRef }) table: ElementRef;
  @Output() updateType = new EventEmitter<boolean>();

  flipped: boolean;

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSourceShort = ELEMENT_DATA;

  // Charts
  globalType: GlobalType;
  chartOptions: any;
  chartOptionsAhorro: any;
  chartOptionsAprocehamiento: any;

  selfViewDataPower: ISelfViewData;
  selfViewDataEconomic: ISelfViewDataEconomico;
  selfViewDataPowerComparison: ISelfViewData;
  selfViewDataEconomicComparison: ISelfViewDataEconomico;

  ahorroPorPeriodos: number[];
  ahorroPorPeriodosPercentage: number[];
  aprovechamientoAutoconsumo: number;
  aprovechamientoCompensacion: number;

  ahorroPorPeriodosCompare: number[];
  ahorroPorPeriodosPercentageCompare: number[];
  aprovechamientoAutoconsumoCompare: number;
  aprovechamientoCompensacionCompare: number;

  showAprovechamientoEconomico = false;
  initOptionsForPieCharts = {
    renderer: 'svg',
    height: 150
  };

  cobertura = 0;
  approvechamiento = 0;

  coberturaCompare = 0;
  approvechamientoCompare = 0;

  compareMode = false;

  // Translations
  datagraf;
  datagraf2;

  dataEs2 = ['Cobertura', 'Aprovechamiento'];
  dataEn2 = ['Coverage', 'Exploitation'];
  dataEs = ['Consumo Total', 'Consumida Red', 'Auto Consumida', 'Excedentaria', 'Neta Generada'];
  dataEn = ['Total Consumption', 'Network Consumed', 'Auto Consumed', 'Excedentary', 'Net Generated'];

//  type: boolean;
  serieData = { 'Coverage': true, 'Exploitation': true, 'Cobertura*': true, 'Aprovechamiento*': true };

  normalData: ISelfViewDto;
  comparisonData: ISelfViewDto;

  constructor(private translate: TranslateService, private selfviewService: SelfViewService) {
    this.flipped = true;
//    this.type = false;
  }

  ngOnInit() {
    this.selfviewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;

      this.selfviewService.dataNormal.subscribe((data: ISelfViewDto) => {
        this.normalData = data;
        this.showChartForGlobalType();
      });


      this.selfviewService.dataComparison.subscribe((data: ISelfViewDto) => {
        this.comparisonData = data;
        this.showChartComparisonForGlobalType();
      });

    });


    this.translateAll();
  }

  modifySerie(serieName: string, $event) {
    const state = $event.checked;
    this.serieData[serieName] = state;
    if (serieName === 'Coverage') {
      this.serieData['Cobertura*'] = state;
    } else if (serieName === 'Exploitation') {
      this.serieData['Aprovechamiento*'] = state;
    }
    this.chartOptions.legend.selected = this.serieData;
    this.chartOptions = { ...this.chartOptions, ...this.chartOptions };

  }

  initiatePowerChart() {
    this.chartOptions = null;
    this.chartOptions = { ...this.chartOptions, ...this.chartOptions };
    this.chartOptions = {
      tooltip: {
        trigger: 'item',
        formatter: '{b0}: {c0}%',
      },
      legend: {
        orient: 'horizontal',
        x: 'center',
        data: this.datagraf2,
        show: false,
        selected: {}
      },
      series: [
        {
          name: this.datagraf2[0],
          type: 'pie',
          startAngle: 180,
          center: ['25%', '70%'],
          radius: ['75%', '100%'],
          avoidLabelOverlap: false,
          tooltip: { show: false },
          labelLine: { show: false },
          itemStyle: {
            normal: {
              color: chartColors.cobertura
            }
          },
          data: [
            {
              value: this.cobertura, name: 'Cobertura',
              label: {
                normal: {
                  formatter: '{c0}% ',
                  show: true,
                  position: 'center',
                  fontSize: 14,
                },

              }
            },
            {
              value: 100 - this.cobertura, name: '100-cobertura',
              itemStyle: { color: this.approvechamiento < 10 ? 'rgba(0,0,0,0.09)' : 'transparent' },
              label: { show: false },
              tooltip: {
                show: true,
                formatter: 'Cobertura: ' + this.cobertura + '%'
              }
            },
            {
              value: 100 - this.cobertura, name: '100-cobertura',
              itemStyle: { color: 'transparent' },
              label: { show: false },
              tooltip: { show: false }
            },
          ]
        },
        {
          name: this.datagraf2[1],
          type: 'pie',
          startAngle: 180,
          center: ['75%', '70%'],
          radius: ['75%', '100%'],
          avoidLabelOverlap: false, labelLine: { show: false },
          itemStyle: {
            normal: {
              color: chartColors.aprovechamiento
            }
          },

          data: [
            {
              value: this.approvechamiento,
              name: 'Aprovechamiento',
              tooltip: { show: false },
              label: {
                normal: {
                  formatter: '{c0}%',
                  show: true,
                  position: 'center',
                  fontSize: 14,
                }
              }

            },
            {
              value: 100 - this.approvechamiento, name: '100 -Aprovechamiento',
              itemStyle: { color: this.approvechamiento < 10 ? 'rgba(0,0,0,0.09)' : 'transparent' },
              label: { show: false },
              tooltip: {
                show: true,
                formatter: 'Aprovechamiento: ' + this.approvechamiento + '%'
              }
            },
            {
              value: 100 - this.approvechamiento, name: '100 -Aprovechamiento',
              itemStyle: { color: 'transparent' },
              label: { show: false },
              tooltip: { show: false }
            }
          ]
        }
      ]

    };
  }

  initiatePowerChartComparison() {
    this.initiatePowerChart();
    const seriesComparison = [
      {
        name: 'Cobertura*',
        type: 'pie',
        startAngle: 180,
        center: ['25%', '70%'],
        radius: ['40%', '65%'],
        avoidLabelOverlap: false,
        labelLine: { show: false },
        itemStyle: {
          normal: {
            color: chartColors.cobertura2
          }
        },
        data: [
          {
            value: this.coberturaCompare, name: 'Cobertura*',
            tooltip: { show: false },
            label: {
              normal: {
                formatter: (params) => {
                  return '\n' + params.data.value + '%';
                },
                lineHeight: 40,
                show: true,
                position: 'center',
                fontSize: 14,
              }
            }
          },
          {
            value: 100 - this.coberturaCompare, name: '100-cobertura',
            itemStyle: { color: this.coberturaCompare < 10 ? 'rgba(0,0,0,0.09)' : 'transparent' },
            label: { show: false },
            tooltip: {
              show: true,
              formatter: 'Cobertura*: ' + this.cobertura + '%'
            }
          },
          {
            value: 100 - this.coberturaCompare, name: '100-cobertura',
            itemStyle: { color: 'transparent' },
            label: { show: false },
            tooltip: { show: false }
          },
        ]
      },
      {
        name: 'Aprovechamiento*',
        type: 'pie',
        startAngle: 180,
        center: ['75%', '70%'],
        radius: ['40%', '65%'],
        avoidLabelOverlap: false, labelLine: { show: false },
        itemStyle: {
          normal: {
            color: chartColors.aprovechamiento2
          }
        },

        data: [
          {
            value: this.approvechamientoCompare,
            name: 'Aprovechamiento*',
            tooltip: { show: false },
            label: {
              normal: {
                formatter: (params) => {
                  return '\n' + params.data.value + '%';
                },
                show: true,
                lineHeight: 40,
                position: 'center',
                fontSize: 14,
              }
            }
          },
          {
            value: 100 - this.approvechamientoCompare, name: '100 -Aprovechamiento',
            itemStyle: { color: this.approvechamientoCompare < 10 ? 'rgba(0,0,0,0.09)' : 'transparent' },
            label: { show: false },
            tooltip: {
              show: true,
              formatter: 'Aprovechamiento*: ' + this.cobertura + '%'
            }
          },
          {
            value: 100 - this.approvechamientoCompare, name: '100 -Aprovechamiento',
            itemStyle: { color: 'transparent' },
            label: { show: false },
            tooltip: { show: false }
          }
        ]
      }
    ];


    if (this.chartOptions.series.length > 2) {
      this.chartOptions.series = this.chartOptions.series.splice(0, 2);
      if (this.chartOptions.legend) {
        this.chartOptions.legend.data = ['Aprovechamiento', 'Cobertura'];
      }
    }

    const seriesTotal = this.chartOptions.series.concat(seriesComparison);
    this.chartOptions.series = seriesTotal;
    if (this.chartOptions.legend) {
      this.chartOptions.legend.data = ['Cobertura', 'Cobertura*', 'Aprovechamiento', 'Aprovechamiento*'];
    }


    this.chartOptions = { ...this.chartOptions, ...this.chartOptions };
  }

  initiateEconomicChart() {
    this.chartOptions = null;
    this.chartOptions = { ...this.chartOptions, ...this.chartOptions };

    this.chartOptionsAprocehamiento = null;
    this.chartOptionsAprocehamiento = { ...this.chartOptionsAprocehamiento, ...this.chartOptionsAprocehamiento };

    const ahorroPorPeriodos = this.ahorroPorPeriodos ? this.ahorroPorPeriodos : null;
    const ahorroPorPeriodosPercentage = this.ahorroPorPeriodosPercentage;
    const colors = ['#69D2E7', '#A7DBD8', '#E0E4CC', '#F38630', '#C7F464', '#4ECDC4'];
    const ahorroChartData = [];

    for (let i = 0; i < ahorroPorPeriodos.length; i++) {
      const row = {
        value: Math.round(ahorroPorPeriodos[i] * 100) / 100,
        percentage: Math.round(ahorroPorPeriodosPercentage[i] * 100) / 100,
        itemStyle: { color: colors[i] }
      };
      ahorroChartData.push(row);
    }


    this.chartOptionsAhorro = {
      tooltip: {
        trigger: 'axis',
        /* formatter: function (params) {
           const period = params[0].name;
           const euro = params[0].dataNormal.value;
           const percentage = params[0].dataNormal.percentage;
           let period2;
           let euro2;
           let percentage2;
           if (params[1] !== null) {
             period2 = params[1].name;
             euro2 = params[1].dataNormal.value;
             percentage2 = params[1].dataNormal.percentage;
             return `${period}: ${euro} € / ${percentage} (%) <br> Comparison:  ${period2}: ${euro2} € / ${percentage2} (%)`;
           } else {
             return `${period}: ${euro} € / ${percentage} (%)`;
           }


         },*/
        axisPointer: {
          type: 'shadow',
        }
      },
      xAxis: {
        show: false,
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false }
      },
      grid: {
        top: '0%',
        left: '8%',
        right: '12%',
        bottom: '3%'
      },
      yAxis: {
        type: 'category',
        data: ['P6', 'P5', 'P4', 'P3', 'P2', 'P1'],
        axisTick: { show: false },

      },
      series: [
        {
          type: 'bar',
          name: 'Ahorro',
          label: {
            normal: {
              show: true,
              position: 'right',
              color: 'black',
              fontSize: 10,
              backgroundColor: '#ffe30d',
              borderColor: '#777',
              borderWidth: 1,
              borderRadius: 4,
              formatter: function (params) {
                const euro = params.data.value;
                const percentage = params.data.percentage;
                return euro + ' €' + '/' + percentage + ' %';
              }
            }
          },
          data: ahorroChartData
        }
      ]
    };

    this.chartOptionsAprocehamiento = {
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)'
      },
      legend: {
        orient: 'vertical',
        left: '10%',
        bottom: '0%',
        data: [
          { name: 'Autoconsumo' },
          { name: 'Compensacion' },
        ]
      },
      series: [
        {
          name: 'Aprovechamiento',
          type: 'pie',
          radius: ['60%', '80%'],
          avoidLabelOverlap: false,
          label: {
            normal: {
              show: true,
              position: 'inside',
              formatter: '{d}%',
              textStyle: {
                fontSize: '12',
                color: '#000'
              }
            },
            emphasis: {
              show: true,
              textStyle: {
                fontSize: '16',
                fontWeight: 'bold',
                color: '#000'
              }
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: [
            {
              value: Math.round(this.aprovechamientoCompensacion * 100) / 100,
              name: 'Compensacion',
              itemStyle: { color: chartColors.compensacion }
            },
            {
              value: Math.round(this.aprovechamientoAutoconsumo * 100) / 100,
              name: 'Autoconsumo',
              itemStyle: { color: chartColors.autoConsumo }
            },
          ]
        }
      ]
    };

    this.chartOptions = this.chartOptionsAhorro;

  }

  initiateEconomicChartComparison() {
    this.initiateEconomicChart();

    const ahorroPorPeriodosCompare = this.ahorroPorPeriodosCompare;
    const ahorroPorPeriodosPercentageCompare = this.ahorroPorPeriodosPercentageCompare;
    const colors = ['#69D2E7', '#A7DBD8', '#E0E4CC', '#F38630', '#C7F464', '#4ECDC4'];
    const ahorroChartDataCompare = [];

    for (let i = 0; i < ahorroPorPeriodosCompare.length; i++) {
      const row = {
        value: Math.round(ahorroPorPeriodosCompare[i] * 100) / 100,
        percentage: Math.round(ahorroPorPeriodosPercentageCompare[i] * 100) / 100,
        itemStyle: { color: colors[i] }
      };
      ahorroChartDataCompare.push(row);
    }
    // console.log(ahorroChartDataCompare);

    const compareSerieAhorro = [
      {
        type: 'bar',
        itemStyle: { color: '#ff252d' },
        name: 'Ahorro*',
        label: {
          backgroundColor: '#ffe30d',
          normal: {
            show: true,
            position: 'right',
            backgroundColor: '#ffe30d',
            fontSize: 10,
            color: '#ff4921',
            formatter: function (params) {
              const euro = params.data.value;
              const percentage = params.data.percentage;
              return euro + ' €' + '/' + percentage + ' %';
            }
          }
        },
        data: ahorroChartDataCompare
      }
    ];

    this.chartOptionsAhorro.series = this.chartOptionsAhorro.series.concat(compareSerieAhorro);

    this.chartOptions = { ...this.chartOptions, ...this.chartOptionsAhorro };

    const compareSerieAprovechamiento = [
      {
        name: 'Aprovechamiento*',
        type: 'pie',
        radius: ['30%', '40%'],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: true,
            position: 'inside',
            formatter: '{d}%',
            textStyle: {
              fontSize: '12',
              color: '#000'
            }
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: '16',
              fontWeight: 'bold',
              color: '#000'
            }
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        data: [
          {
            value: Math.round(this.aprovechamientoCompensacionCompare * 100) / 100,
            name: 'Compensacion',
            itemStyle: { color: chartColors.compensacion2 }
          },
          {
            value: Math.round(this.aprovechamientoAutoconsumoCompare * 100) / 100,
            name: 'Autoconsumo',
            itemStyle: { color: chartColors.autoConsumo2 }
          },
        ]
      }
    ];

    this.chartOptionsAprocehamiento.series = this.chartOptionsAprocehamiento.series.concat(compareSerieAprovechamiento);

    this.chartOptionsAprocehamiento = { ...this.chartOptionsAprocehamiento, ...this.chartOptionsAprocehamiento };


  }

  exportToExcel() {
    let ws: XLSX.WorkSheet;
    ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Table.xlsx');
  }

  translateAll() {
    this.translate.get('SELFVIEW.HTML.RANGES').subscribe((res: string) => {
      if (res.toString() === 'rangesEn') {
        this.datagraf = this.dataEn;
        this.datagraf2 = this.dataEn2;
      } else {
        this.datagraf = this.dataEs;
        this.datagraf2 = this.dataEs2;
      }
    });
  }

  changeType(): void {
    // this.type = !this.type;
    // this.updateType.emit(this.type);
    this.showChartForLocalType();
    this.showChartComparisonForLocalType();
  }

  showChartForGlobalType() {
    if (this.normalData !== null) {
      const data = this.normalData;
      if ((data.selfViewDataPower != null || data.selfViewDataEconomico != null) && !this.compareMode) {
        if (this.globalType === GlobalType.Energy) {
          this.selfViewDataPower = data.selfViewDataPower;
//          this.type = false;
//          this.showAprovechamientoEconomico = false;
          this.cobertura = data.selfViewDataPower.cobertura_porcentaje;
          this.approvechamiento = data.selfViewDataPower.aprovechamiento_porcentaje;
          this.initiatePowerChart();
        } else if (this.globalType === GlobalType.Economic) {
          this.selfViewDataEconomic = data.selfViewDataEconomico;
          this.ahorroPorPeriodos = data.selfViewDataEconomico.ahorro_por_periodos;
          this.ahorroPorPeriodosPercentage = data.selfViewDataEconomico.porc_ahorro_por_periodos;
          this.aprovechamientoAutoconsumo = data.selfViewDataEconomico.aprovechamiento_autoconsumo;
          this.aprovechamientoCompensacion = data.selfViewDataEconomico.aprovechamiento_compensacion;
//          this.showAprovechamientoEconomico = true;
          this.initiateEconomicChart();
        }
      }

      if (this.compareMode) {
        if (this.selfViewDataPowerComparison !== null && this.globalType === GlobalType.Energy) {
          this.coberturaCompare = this.selfViewDataPowerComparison.cobertura_porcentaje;
          this.approvechamientoCompare = this.selfViewDataPowerComparison.aprovechamiento_porcentaje;

          this.initiatePowerChartComparison();

        } else if (this.selfViewDataEconomicComparison !== null && this.globalType === GlobalType.Economic) {
          this.ahorroPorPeriodosCompare = this.selfViewDataEconomicComparison.ahorro_por_periodos;
          this.ahorroPorPeriodosPercentageCompare = this.selfViewDataEconomicComparison.porc_ahorro_por_periodos;
          this.aprovechamientoAutoconsumoCompare = this.selfViewDataEconomicComparison.aprovechamiento_autoconsumo;
          this.aprovechamientoCompensacionCompare = this.selfViewDataEconomicComparison.aprovechamiento_compensacion;

          this.initiateEconomicChartComparison();
        }
      }
    }

  }

  showChartForLocalType() {

    if (this.normalData !== null) {
      const data = this.normalData;
      if ((data.selfViewDataPower != null || data.selfViewDataEconomico != null)) {
        if (this.globalType === GlobalType.Energy) { // if (this.type === false) {
          this.selfViewDataPower = data.selfViewDataPower;
//          this.showAprovechamientoEconomico = false;
          this.cobertura = data.selfViewDataPower.cobertura_porcentaje;
          this.approvechamiento = data.selfViewDataPower.aprovechamiento_porcentaje;
          this.initiatePowerChart();
        } else if (this.globalType === GlobalType.Economic) { // if (this.type === true) {
          this.selfViewDataEconomic = data.selfViewDataEconomico;
          this.ahorroPorPeriodos = data.selfViewDataEconomico.ahorro_por_periodos;
          this.ahorroPorPeriodosPercentage = data.selfViewDataEconomico.porc_ahorro_por_periodos;
          this.aprovechamientoAutoconsumo = data.selfViewDataEconomico.aprovechamiento_autoconsumo;
          this.aprovechamientoCompensacion = data.selfViewDataEconomico.aprovechamiento_compensacion;
//          this.showAprovechamientoEconomico = true;
          this.initiateEconomicChart();
        }
      }
      if (this.compareMode) {
        if (this.selfViewDataPowerComparison !== null && this.globalType === GlobalType.Energy) { // && this.type === false) {
          this.coberturaCompare = this.selfViewDataPowerComparison.cobertura_porcentaje;
          this.approvechamientoCompare = this.selfViewDataPowerComparison.aprovechamiento_porcentaje;
          this.initiatePowerChartComparison();
        } else if (this.selfViewDataEconomicComparison !== null  && this.globalType === GlobalType.Economic) { // && this.type === true) {
          this.ahorroPorPeriodosCompare = this.selfViewDataEconomicComparison.ahorro_por_periodos;
          this.ahorroPorPeriodosPercentageCompare = this.selfViewDataEconomicComparison.porc_ahorro_por_periodos;
          this.aprovechamientoAutoconsumoCompare = this.selfViewDataEconomicComparison.aprovechamiento_autoconsumo;
          this.aprovechamientoCompensacionCompare = this.selfViewDataEconomicComparison.aprovechamiento_compensacion;
          this.initiateEconomicChartComparison();
        }
      }
    }

  }

  showChartComparisonForGlobalType() {
    if (this.comparisonData !== null) {
      const data = this.comparisonData;
      if (data.selfViewDataEconomico !== null || data.selfViewDataPower !== null) {
        this.compareMode = true;
        this.serieData = { 'Coverage': true, 'Exploitation': true, 'Cobertura*': true, 'Aprovechamiento*': true };

        /* this.chartOptions.legend.selected = this.serieData;
         this.chartOptions = {...this.chartOptions, ...this.chartOptions};*/

        this.selfViewDataEconomicComparison = data.selfViewDataEconomico;
        this.selfViewDataPowerComparison = data.selfViewDataPower;
        if (data.selfViewDataPower !== null && this.globalType === GlobalType.Energy) {
          this.coberturaCompare = this.selfViewDataPowerComparison.cobertura_porcentaje;
          this.approvechamientoCompare = this.selfViewDataPowerComparison.aprovechamiento_porcentaje;
          this.initiatePowerChartComparison();
        } else if (data.selfViewDataEconomico !== null && this.globalType === GlobalType.Economic) {
//          this.showAprovechamientoEconomico = true;
          this.ahorroPorPeriodosCompare = this.selfViewDataEconomicComparison.ahorro_por_periodos;
          this.ahorroPorPeriodosPercentageCompare = this.selfViewDataEconomicComparison.porc_ahorro_por_periodos;
          this.aprovechamientoAutoconsumoCompare = this.selfViewDataEconomicComparison.aprovechamiento_autoconsumo;
          this.aprovechamientoCompensacionCompare = this.selfViewDataEconomicComparison.aprovechamiento_compensacion;
          this.initiateEconomicChartComparison();
        }
      }
    } else {
      // console.log(this.compareMode+ ' 2xxx');
      if (this.compareMode) {
        // console.log("BORRAR COMPARE");
        this.serieData = { 'Coverage': true, 'Exploitation': true, 'Cobertura*': false, 'Aprovechamiento*': false };
        this.chartOptions.legend.selected = this.serieData;
        this.chartOptions = { ...this.chartOptions, ...this.chartOptions };
        this.compareMode = false;

      } else {
        // console.log("BORRAR no");
      }
      // DAVID
    }

  }

  showChartComparisonForLocalType() {
    if (this.comparisonData !== null) {
      const data = this.comparisonData;
      if (data.selfViewDataEconomico !== null || data.selfViewDataPower !== null) {
        this.compareMode = true;
        /* this.serieData = {'Coverage': true, 'Exploitation': true, 'Cobertura*': true, 'Aprovechamiento*': true};
         this.chartOptions.legend.selected = this.serieData;
         this.chartOptions = {...this.chartOptions, ...this.chartOptions};*/
        this.selfViewDataEconomicComparison = data.selfViewDataEconomico;
        this.selfViewDataPowerComparison = data.selfViewDataPower;
        if (this.globalType === GlobalType.Energy) { // if (this.type === false) {
          this.coberturaCompare = this.selfViewDataPowerComparison.cobertura_porcentaje;
          this.approvechamientoCompare = this.selfViewDataPowerComparison.aprovechamiento_porcentaje;
          this.initiatePowerChartComparison();
        } else if (this.globalType === GlobalType.Economic) { // if (this.type === true) {
//          this.showAprovechamientoEconomico = true;
          this.ahorroPorPeriodosCompare = this.selfViewDataEconomicComparison.ahorro_por_periodos;
          this.ahorroPorPeriodosPercentageCompare = this.selfViewDataEconomicComparison.porc_ahorro_por_periodos;
          this.aprovechamientoAutoconsumoCompare = this.selfViewDataEconomicComparison.aprovechamiento_autoconsumo;
          this.aprovechamientoCompensacionCompare = this.selfViewDataEconomicComparison.aprovechamiento_compensacion;
          this.initiateEconomicChartComparison();
        }
      }
    } else {
      // console.log(this.compareMode+ ' 2xxx');
      if (this.compareMode) {

        this.serieData = { 'Coverage': true, 'Exploitation': true, 'Cobertura*': false, 'Aprovechamiento*': false };
        if (this.chartOptions.legend) {
          this.chartOptions.legend.selected = this.serieData;
          this.chartOptions = { ...this.chartOptions, ...this.chartOptions };
        }

        // this.compareMode = false;

      } else {
        // console.log("BORRAR no");
      }
      // DAVID
    }

  }
}
