import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF } from "../admin.model";

export const USER_GROUP_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const USER_GROUP_COL_DEFS: ColDef[] = [
  {
    field: 'userGroupId',
    headerName: 'ID',
    checkboxSelection: function(params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'description',
    headerName: 'Description',
  },
  {
    field: 'activated',
    headerName: 'Activated',
    filter: 'boolFilter',
    floatingFilterComponent: 'boolFloatingFilter',
    cellRenderer: 'checkBoxComponent',
  },
  {
    field: 'usernames',
    headerName: 'Users',
    valueFormatter: function (params) {
      if (params && params.data) {
        let value = '';
        const users = params.data.userGroupMembers;
        if (users && users.length) for (const user of users) {
          if (value) value += ', ';
          value += user.user.fullName;
        }
        return params.data.usernames = value;
      }
    },
  },
  {
    field: 'entity.code',
    headerName: 'Entity',
  },
  {
    field: 'ownerEntity',
    headerName: 'Owner Entity',
  },
];
