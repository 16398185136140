import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { NbToastrService, NbToastrConfig, NbComponentStatus, NbToastRef } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(
    private nbToastrService: NbToastrService,
    private translateService: TranslateService,
  ) {}

  private translateError(keyError: string): string {
    let error: string;
    switch(keyError) {
      case 'required':
      case 'maxlength':
      case 'minlength':
      case 'pattern':
        error = this.translateService.instant('ADMIN.' + keyError);
    }
    return error ? error : '';
  }

  /**
   * Shows the validation errors of an Administration form in warning toasts.
   * @param formGroup Angular FormGroup
   * @param dynamicForm PPlus DynamicForm model
   */
  showFormValidationErrors(formGroup: FormGroup, dynamicForm: DynamicForm[]) {
    let msgs: string[] = [];
    Object.keys(formGroup.controls).forEach(key => {
      const controlErrors = formGroup.get(key).errors;
      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          let fieldName = '';
          dynamicForm.forEach(item => {
            item.fields.forEach(f => {
              if (f.name === key) {
                fieldName = f.placeholder ? f.placeholder : f.label;
              }
            });
          });
          msgs.push(
            this.translateService.instant('ADMIN.field') + ' ' + fieldName + ' ' + this.translateError(keyError) + (
              keyError === 'maxlength' || keyError === 'minlength' ? ': ' + controlErrors[keyError].requiredLength : ''
            ) + '.'
          );
        });
      }
    });
    msgs.reverse();
    for (const msg of msgs) {
      this.showToast('warning', msg, this.translateService.instant('ADMIN.validationError'));
    }
  }

  /**
   * Shows an NbToast with our custom default configuration (without an icon).
   * @param status Color scheme for the toast
   * @param message Text content
   * @param title Optional title
   * @param userConfig Optional configuration
   */
  showToast(
    status: NbComponentStatus, message: string, title?: string, userConfig?: Partial<NbToastrConfig>
  ): NbToastRef {
    return this.nbToastrService.show(message, title, Object.assign(<NbToastrConfig>{
      icon: '',
      status,
    }, userConfig));
  }

}
