import { Component, Output, EventEmitter, Input, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ChecklistDatabase } from './tree-data.service';
import { ItemNode, ItemFlatNode } from '../../models/components/tree-data.model';

@Component({
  selector: 'lib-tree-data',
  templateUrl: './tree-data.component.html',
  styleUrls: ['./tree-data.component.scss']
})
export class TreeDataComponent implements OnDestroy, OnInit {
  @Output() data = new EventEmitter<ItemNode | ItemFlatNode []>();
  @Input() selectedFirst = false;
  @Input() checkbox = false;
  loaderId = 'loader-tree-data-' + Math.floor((Math.random() * 100) + 1);

  flatNodeMap = new Map<ItemFlatNode, ItemNode>();
  nestedNodeMap = new Map<ItemNode, ItemFlatNode>();

  selectedParent: ItemFlatNode | null = null;

  newItemName = '';

  treeControl: FlatTreeControl<ItemFlatNode>;

  treeFlattener: MatTreeFlattener<ItemNode, ItemFlatNode>;

  dataSource: MatTreeFlatDataSource<ItemNode, ItemFlatNode>;

  checklistSelection = new SelectionModel<ItemFlatNode>(true /* multiple */);
  constructor(private database: ChecklistDatabase, private ngxLoader: NgxUiLoaderService, private changeDetector: ChangeDetectorRef) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
      this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ItemFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.ngxLoader.startBackgroundLoader(this.loaderId);
  }

  ngOnInit(): void {
    this.database.dataChange.subscribe( data => {
      this.ngxLoader.stopBackgroundLoader(this.loaderId);
      if (this.selectedFirst) {
        data.forEach( el => {
          if (el.children) {
            el.children.forEach( item => {
              this.data.emit(item);
            });
          }
        });
      }
      this.dataSource.data = data;
    });
  }

  private getLevel = (node: ItemFlatNode) => node.level;

  private isExpandable = (node: ItemFlatNode) => node.expandable;

  private getChildren = (node: ItemNode): ItemNode [] => node.children;

  hasChild = (_: number, _nodeData: ItemFlatNode) => _nodeData.expandable;

  private transformer = (node: ItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    let flatNode;
    flatNode = existingNode && existingNode.item === node.item ? existingNode : new ItemNode();
    flatNode.item = node.item;
    flatNode.level = level;
    flatNode.code = node.code;
    flatNode.id = node.id;
    flatNode.selected = node.selected;
    flatNode.data = node.data;
    if (flatNode.selected) {
      this.checklistSelection.select(flatNode);
    }
    flatNode.expandable = node.children && node.children.length > 0;
    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  private descendantsAllSelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child));
  }

  private descendantsPartiallySelected(node: ItemFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.descendantsAllSelected(node);
  }

  private todoItemSelectionToggle(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    this.checklistSelection.isSelected(node)
      ? this.checklistSelection.select(...descendants)
      : this.checklistSelection.deselect(...descendants);
  }

  filterChanged(filterText: string) {
    this.database.filter(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }

  changeStatusNode(node: ItemFlatNode): void {
    this.checklistSelection.toggle(node);
    this.database.updateData(this.checklistSelection.selected);
    this.data.emit(this.checklistSelection.selected);
  }

  selectAll(mode: boolean): void {
    if (mode) {
      this.checklistSelection.select(...this.treeControl.dataNodes);
    } else {
      this.checklistSelection.deselect(...this.treeControl.dataNodes);
    }
    this.data.emit(this.checklistSelection.selected);
    this.database.updateData(this.checklistSelection.selected);
  }

  ngOnDestroy(): void {
    this.dataSource.data = [];
  }

}
