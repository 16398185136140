import { Component, OnInit, Input, EventEmitter, Output, TemplateRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DynamicForm } from '../../../models/components/dynamic-form.model';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { RowDataService } from '../../split-form/services/row-data.service';
import { IUser } from 'src/app/modules/admin/users/user.interface';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pplus-admin-form',
  templateUrl: './admin-form.component.html',
  styleUrls: ['./admin-form.component.scss']
})
export class AdminFormComponent implements OnInit {
  @Output() onSubmit = new EventEmitter();
  @Output() _copy = new EventEmitter();
  @Output() update = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() close = new EventEmitter();
  @Input() data: DynamicForm [] = [];
  @Input() form: FormGroup;

  @Output() deleteContact = new EventEmitter();
  @Output() addContact = new EventEmitter();
  @Output() deleteFinancial = new EventEmitter();
  @Output() addFinancial = new EventEmitter();

  contactSelected: number = 0;
  financialSelected: number = 0;

  private selectedRowSub: Subscription;

  constructor(
    private dialog: NbDialogService, private toastService: NbToastrService,
    private rowDataService: RowDataService
  ) {}

  ngOnInit() {
    this.generateForm(this.data);
  }

  openDialog(dialog: TemplateRef<any>) {
    this.dialog.open(dialog, { hasScroll: false });
  }

  showToast(icon, status, title , msg) {
    this.toastService.show(title, msg , { icon, status });
  }

  generateForm(data: DynamicForm []) {
    const fieldsCtrls = {};
    data.forEach(item => {
      const fields = item.fields;
      fields.forEach( f => {
        fieldsCtrls[f.name] = new FormControl(f.value || '', f.validators);
        if (f.type === 'select' && f.searchName) {
          fieldsCtrls[f.searchName] = new FormControl(f.value || '', f.validators);
        }
      });
    });
    this.form = new FormGroup(fieldsCtrls);
  }

  clearData() {
    this.data.forEach( data => {
      const fields = data.fields;
      fields.map(field => {
        field.value = '';
      });
    });
    this.generateForm(this.data);
  }

  closeDialog(ref) {
    ref.close();
    this.close.emit();
  }

  deleteRow(ref) {
    ref.close();
    this.delete.emit();
  }

  _addContact() {
    this.selectedRowSub = this.rowDataService.currentRowData.subscribe((row: IUser) => {
      if (row && row.userContacts) {
        setTimeout(() => {
          for (const el of this.data) el.open = el.title === 'CONTACTO';
        });
        this.contactSelected = row.userContacts.length - 1;
      }
      this.selectedRowSub.unsubscribe();
    });
    this.addContact.emit();
  }

  _addFinancial() {
    this.selectedRowSub = this.rowDataService.currentRowData.subscribe((row: IUser) => {
      if (row && row.userFinancials) {
        setTimeout(() => {
          for (const el of this.data) el.open = el.title === 'FINANCIERO';
        });
        this.financialSelected = row.userFinancials.length - 1;
      }
      this.selectedRowSub.unsubscribe();
    });
    this.addFinancial.emit();
  }

}
