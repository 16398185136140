import { Injectable } from '@angular/core';
import { DynamicForm, Fields } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DomainDetailsService {

  private readonly generalFields: Fields[] = [
    {
      name: 'entityDomainId',
      type: 'text',
      placeholder: 'ID',
      value: '',
      disabled: true,
    },
    {
      name: 'fqdn',
      type: 'text',
      placeholder: 'FQDN',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(100),
      ],
    },
    {
      name: 'activated',
      type: 'checkbox',
      placeholder: 'ACTIVADO',
      value: true,
    },
    {
      name: 'entityId',
      type: 'select',
      label: 'ENTIDAD PROPIETARIA',
      value: '',
      options: [],
      validators: [
        Validators.required,
      ],
    },
  ];

  constructor() {}

  getDynamicForm(): DynamicForm[] {
    return [
      {
        title: 'DATOS GENERALES',
        fields: this.generalFields,
        open: true,
      },
    ];
  }

}
