import { Component, OnInit } from '@angular/core';
import { PVSelfCalculation, PVSelfPIConfigType } from '../../../models/PvSelfCalculation.model';
import { PVSelfEngineService } from '../../../services/pvself-engine.service';
import { PVSelfPIConfiguration, PVSelfEnginePIConfigurationResults } from '../../../models/PVSelfResultsModel.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Manufacturer } from 'projects/pplus/src/lib/models/manufacturer.model';
import { InverterModel } from 'projects/pplus/src/lib/models/elements/inverterModel.model';
import { PanelModel } from 'projects/pplus/src/lib/models/elements/panelModel.model';
import { ManufacturerService } from 'projects/pplus/src/lib/services/manufacturer.service';
import { InverterModelService } from 'projects/pplus/src/lib/services/elements/inverter-model.service';
import { PanelModelService } from 'projects/pplus/src/lib/services/elements/panel-model.service';
import { ElementType } from 'projects/pplus/src/lib/models/pplus.enums';
import { MatSnackBar } from '@angular/material';
import { PVSelfCalculationService } from '../../../services/pvself-calculation.service';
import { EntitiesService } from 'projects/pplus/src/lib/services/entities.service';
import { Entity } from 'projects/pplus/src/lib/models/entity.model';

@Component({
  selector: 'pplus-pvself-electric-config',
  templateUrl: './pvself-electric-config.component.html',
  styleUrls: ['./pvself-electric-config.component.scss']
})
export class PVSelfElectricConfigComponent implements OnInit {
  _calculation: PVSelfCalculation;
  _applicationEntity: Entity;
  manufacturersInverter: Manufacturer[];
  manufacturersPanel: Manufacturer[];
  inverters: InverterModel[];
  panels: PanelModel[];
  selectedProducerIndex: number;
  selectedFieldIndex: number;
  piConfigs: PVSelfPIConfiguration[];
  piConfigResult: PVSelfEnginePIConfigurationResults;
  loaderId: string;
  configErrors: boolean[];
  //  configurationMode: number;

  columnDefs = [
    { headerName: 'Potencia pico (Wp)', field: 'totalPeakPower', resizable: true },
    { headerName: 'Potencia nominal (W)', field: 'nominalPower', resizable: true },
    { headerName: 'Núm inversores', field: 'numInverters', resizable: true },
    { headerName: '-', field: 'inverterManufacturerId', resizable: true, hide: true },
    { headerName: '-', field: 'inverterId', resizable: true, hide: true },
    { headerName: 'Inversor', field: 'inverterModel', resizable: true },
    { headerName: 'Potencia pico inversor (Wp)', field: 'peakPowerInverter', resizable: true },
    { headerName: 'Series', field: 'numSerials', resizable: true },
    { headerName: 'Paneles por serie', field: 'panelsPerSerial', resizable: true },
    { headerName: 'Módulos por inversor', field: 'panelsPerInverter', resizable: true }
  ];

  constructor(private manufacturerService: ManufacturerService,
    private inverterService: InverterModelService,
    private panelService: PanelModelService,
    private calculationService: PVSelfCalculationService,
    private ngxLoaderService: NgxUiLoaderService,
    private engineService: PVSelfEngineService,
    private _snackBar: MatSnackBar,
    private entitiesService: EntitiesService) { }

  ngOnInit() {
    this.piConfigs = [];
    this.configErrors = [];
    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);
    //    this.configurationMode = 1;

    this.calculationService.calculation.subscribe(res => {
      this._calculation = res;
    });

    this.entitiesService.applicationEntity.subscribe(res => {
      this._applicationEntity = res;
    });
    //    this._calculation = this.calculationService.get();

    this.selectedProducerIndex = 0;
    this.selectedFieldIndex = 0;

    this.getManufacturers();
  }


  getManufacturers() {
    this.manufacturerService.getByElementType(ElementType.Inverter, this._applicationEntity.entityId).subscribe(res => {
      this.manufacturersInverter = res;
      this.getInverters();
    });

    this.manufacturerService.getByElementType(ElementType.PhotovoltaicPanel, this._applicationEntity.entityId).subscribe(res => {
      this.manufacturersPanel = res;
      this.getPanels();
    });
  }


  updatePanelsAndInverters() {
    this.getInverters();
    this.getPanels();
  }


  getInverters() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.inverterService.getByManufacturer(this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].inverterManufacturerId).subscribe(res => {
      this.inverters = res;
      this.ngxLoaderService.stopLoader(this.loaderId);
    },
      err => {
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
  }


  getPanels() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.panelService.getByManufacturer(this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].panelManufacturerId).subscribe(res => {
      this.panels = res;
      this.ngxLoaderService.stopLoader(this.loaderId);
    },
      err => {
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
  }



  calculatePIConfigs() {

    //    if (this.configurationMode === 1) {
    if (this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].configPIType === PVSelfPIConfigType.Automatic) {
      this.ngxLoaderService.startLoader(this.loaderId);
      this.engineService.calculatePIConfigsFromPeakPower(this._calculation, this.selectedProducerIndex, this.selectedFieldIndex).subscribe(res => {
        this.piConfigs = res;

        if (this.piConfigs.length > 0) {
          this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].inverterId = this.piConfigs[0].inverterId;
          this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].numOfInverters = this.piConfigs[0].numInverters;
          this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].numSerialPanels = this.piConfigs[0].panelsPerSerial;
          this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].numInverterSerials = this.piConfigs[0].numSerials;
        } else {
          this._snackBar.open(`🚫 No se ha encontrado ninguna configuración válida.`, 'Ok', {
            duration: 10 * 1000,
          });
        }

        this.ngxLoaderService.stopLoader(this.loaderId);
      },
        err => {
          this.ngxLoaderService.stopLoader(this.loaderId);
        });
    }

    //    if (this.configurationMode === 2) {
    if (this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].configPIType === PVSelfPIConfigType.Semiautomatic) {
      this.ngxLoaderService.startLoader(this.loaderId);
      this.engineService.calculatePIConfigs(this._calculation, this.selectedProducerIndex, this.selectedFieldIndex).subscribe(res => {
        this.piConfigs = res;
        this.ngxLoaderService.stopLoader(this.loaderId);
      },
        err => {
          this.ngxLoaderService.stopLoader(this.loaderId);
        });
    }
  }




  selectedConfiguration(event) {
    this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].numOfInverters = event.data.numInverters;
    this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].inverterId = event.data.inverterId;
    this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].inverterManufacturerId = event.data.inverterManufacturerId;
    this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].numSerialPanels = event.data.panelsPerSerial;
    this._calculation.producers[this.selectedProducerIndex].installation.fields[this.selectedFieldIndex].numInverterSerials = event.data.numSerials;
    this.updateConfigResult();
  }

  updateConfigResult() {
    this.ngxLoaderService.startLoader(this.loaderId);
    this.engineService.calculatePIConfigResults(this._calculation, this.selectedProducerIndex, this.selectedFieldIndex).subscribe(res => {
      this.piConfigResult = res;

      this.configErrors = [];
      this.configErrors.push(((this.piConfigResult.validationData & 1) === 1) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 2) === 2) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 4) === 4) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 8) === 8) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 16) === 16) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 32) === 32) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 64) === 64) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 128) === 128) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 256) === 256) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 512) === 512) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 1024) === 1024) ? true : false);
      this.configErrors.push(((this.piConfigResult.validationData & 2048) === 2048) ? true : false);

      this.ngxLoaderService.stopLoader(this.loaderId);
    },
      err => {
        this.ngxLoaderService.stopLoader(this.loaderId);
      });
  }


  onGridReady(params: any) {
    //    this.gridApi = params.api;
    //    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();

    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  }

}
