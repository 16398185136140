import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ENTITIES_DEFAULT_COL_DEF, ENTITIES_COL_DEFS } from './entities.model';
import { EntitiesService } from './entities.service';
import { GridOptions, IGetRowsParams, GridApi, ColumnApi } from 'ag-grid-community';
import { StyleComponent } from '../domains/components/style/style.component';
import { NbToastrService } from '@nebular/theme';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';
import { AgBoolFilterComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-bool-filter/ag-bool-filter.component';
import { AgBoolFloatingFilterComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-bool-filter/ag-bool-floating-filter/ag-bool-floating-filter.component';

@Component({
  selector: 'app-entities',
  templateUrl: './entities.component.html',
  styleUrls: ['./entities.component.css']
})
export class EntitiesComponent implements OnInit {
  @ViewChild('splitContent', { static: false }) splitContent;
  @ViewChild('agGrid', { read: ElementRef, static: false }) agGridNativeElement: ElementRef;
  @ViewChild('dialog', { read: TemplateRef, static: false }) dialogRef: TemplateRef<unknown>;

  splitOptions = {
    open: false,
    direction: 'horizontal',
    contentSize: 100,
    formSize: 0,
  };

  contextMenu = {
    X: 0,
    Y: 0,
    state: false,
  };

  row = {};

  TitleForm = 'Detalles';
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridOptions: GridOptions = <GridOptions>{};
  defaultColDef = ENTITIES_DEFAULT_COL_DEF;
  columnDefs = ENTITIES_COL_DEFS;

  rowSelection = 'multiple';
  rowModelType = 'infinite';
  paginationPageSize = 10;
  cacheOverflowSize = 2;
  maxConcurrentDatasourceRequests = 2;
  infiniteInitialRowCount = 0;
  maxBlocksInCache = 2;

  constructor(
    private entityService: EntitiesService,
    private toastr: NbToastrService,
    private dataRow: RowDataService,
    private breakPointObserver: BreakpointObserver,
  ) {
    this.gridOptions.floatingFilter = true;
    this.gridOptions.accentedSort = true;
    this.gridOptions.frameworkComponents = {
      checkBoxComponent: AgCheckboxComponent,
      styleComponent: StyleComponent,
      boolFilter: AgBoolFilterComponent,
      boolFloatingFilter: AgBoolFloatingFilterComponent,
    };
    this.gridOptions.overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="assets/loader.svg" alt="loader" class="img-fluid" style="width:40px;height:40px;"></span>';
  }

  ngOnInit() {}

  onGridRowClicked(row) {
    this.row = JSON.parse(JSON.stringify(row.data));
    this.dataRow.updateRowData(this.row);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
    const service = this.entityService;

    const dataSource = {
      rowCount: null,
      getRows: function (rowParams: IGetRowsParams) {
        service.getAllEntitiesInfiniteScroll(
          rowParams.startRow, rowParams.endRow, rowParams.sortModel, rowParams.filterModel
        ).subscribe((data: any) => {
          rowParams.successCallback(data.listOfEntities, data.lastRow);
        });
      }
    };

    this.gridOptions.api.setDatasource(dataSource);
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    if (this.agGridNativeElement) {
      this.agGridNativeElement.nativeElement.addEventListener('contextmenu', function ($event: any) {
        $event.preventDefault();
      });
    }
  }

  onCellContextMenu($event) {
    $event.event.preventDefault();
    const e = $event.event;

    let posx = 0;
    let posy = 0;

    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft +
        document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop +
        document.documentElement.scrollTop;
    }

    this.contextMenu = {
      X: posx,
      Y: posy,
      state: true
    };

    this.row = JSON.parse(JSON.stringify($event.data));
  }

  rowDoubleClicked($event) {
    this.openSplit();
  }

  openSplit() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(res => {
      if (res.matches) {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 0,
          formSize: 100,
        };
      } else {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 70,
          formSize: 30,
        };
      }
    });
  }

  showToast(icon, status, title, msg) {
    this.toastr.show(title, msg, { icon, status });
  }

  readSplitOptions(event: any) {
    this.splitOptions = event.opt;
  }

  updateData(data) {
    switch (data.Type) {
      case 'delete':
      case 'create':
        this.gridOptions.api.purgeInfiniteCache();
    }
  }

  quickSearch(data: string) {
    this.gridApi.setQuickFilter(data);
  }

  resizeGrid(event) {
    this.gridApi.sizeColumnsToFit();
  }

  resetFilter() {
    // This method resets the filter, in case needed.
    // Para resetear los filteros applicados... (Mehmet)
    this.gridOptions.api.setFilterModel(null);
  }

  clearRow() {
    this.row = {};
    this.dataRow.updateRowData(this.row);
  }

  generalSearch(value: string) {}

  deleteSelected() {}

}
