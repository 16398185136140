import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApplicationsService } from '../../services/applications.service';
import { PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PlantsService {
  private _plantsApi = environment.API_URL + '/api/plant/plantgroup/byUserAndEntity';
  private appCode: string;
  private app: string;

  constructor(private http: HttpClient, private application: ApplicationsService, private platformLocation: PlatformLocation) {
    this.app = (platformLocation as any).location.pathname;
    this.appCode = this.transformToCodeApplication(this.app);
  }

  getAllInstallations(): Observable<any> {
    return this.http
      .get(this._plantsApi, { params: { 'appcode': this.appCode, 'entityId': '0' } })
      .pipe(map(response => {
        return response;
      }));
  }

  transformToCodeApplication(pathname: string): string {
    switch (pathname) {
      case '/app/admin':
        return 'Admin';
      case '/app/pvself':
        return 'Pv-Self';
      case '/app/selfview':
        return 'Self-View';
      case '/app/control':
        return 'Control';
      default:
        return 'Dashboard';
    }
  }
}
