import { AppTypeService } from './app-type.service';
import { APP_TYPE_DEFAULT_COL_DEF, APP_TYPE_COL_DEFS } from './app-type.model';
import { Component, OnInit, ViewChild, ElementRef, TemplateRef} from '@angular/core';
import { GridOptions, IGetRowsParams, GridApi, ColumnApi, IDatasource } from 'ag-grid-community';
import { NbDialogService } from '@nebular/theme';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';
import { IEntityAppType } from 'projects/pplus/src/lib/interfaces/interfaces';
import { AgBoolFilterComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-bool-filter/ag-bool-filter.component';
import { AgBoolFloatingFilterComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-bool-filter/ag-bool-floating-filter/ag-bool-floating-filter.component';
import { Observable, forkJoin } from 'rxjs';
import { AdminService } from '../admin.service';

@Component({
  selector: 'app-app-type',
  templateUrl: './app-type.component.html',
  styleUrls: ['./app-type.component.scss']
})
export class AppTypeComponent implements OnInit {
  @ViewChild('splitContent', { static: false}) splitContent;
  @ViewChild('agGrid', { read: ElementRef, static: false }) agGridNativeElement: ElementRef;
  @ViewChild('dialog', { read: TemplateRef, static: false }) dialogRef: TemplateRef<unknown>;

  splitOptions = {
    open: false,
    direction: 'horizontal',
    contentSize: 100,
    formSize: 0,
  };

  contextMenu = {
    X: 0,
    Y: 0,
    state: false,
  };

  rowData: IEntityAppType[];
  row: IEntityAppType = <IEntityAppType>{};

  TitleForm = 'Detalles';
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridOptions: GridOptions;
  defaultColDef = APP_TYPE_DEFAULT_COL_DEF;
  columnDefs = APP_TYPE_COL_DEFS;

  rowModelType = 'infinite';
  infiniteInitialRowCount = 0;
  maxConcurrentDatasourceRequests = 2;
  maxBlocksInCache = 2;
  cacheOverflowSize = 2;

  private agDataSource: IDatasource = {
    rowCount: null,
    getRows: (rowParams: IGetRowsParams) => this.appTypeService.getAllAppTypesInfiniteScroll(
      rowParams.startRow, rowParams.endRow, rowParams.sortModel, rowParams.filterModel
    ).subscribe(res => rowParams.successCallback(this.processAppTypesFields(res.applicationTypes), res.lastRow))
  };

  constructor(
    private appTypeService: AppTypeService,
    private dataRow: RowDataService,
    private breakPointObserver: BreakpointObserver,
    private nbDialogService: NbDialogService,
    private adminService: AdminService,
  ) {
    this.gridOptions = <GridOptions>{};
    this.gridOptions.paginationPageSize = 20;
    this.gridOptions.rowDragManaged = true;
    this.gridOptions.floatingFilter = true;
    this.gridOptions.accentedSort = true;
    this.gridOptions.frameworkComponents = {
      checkBoxComponent: AgCheckboxComponent,
      boolFilter: AgBoolFilterComponent,
      boolFloatingFilter: AgBoolFloatingFilterComponent,
    };
    this.gridOptions.overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="assets/loader.svg" alt="loader" class="img-fluid" style="width:40px;height:40px;"></span>';
    this.gridOptions.rowSelection = 'multiple';
  }

  ngOnInit() {}

  private processAppTypesFields(appTypes: any[]) {
    for (const app of appTypes) {
      if (this.row && this.row.applicationTypeId === app.applicationTypeId) {
        this.row = JSON.parse(JSON.stringify(app));
        this.dataRow.updateRowData(this.row);
      }
    }
    return appTypes;
  }

  onGridRowClicked(row) {
    this.row = <IEntityAppType>JSON.parse(JSON.stringify(row.data));
    this.dataRow.updateRowData(this.row);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    window.addEventListener("resize", function() {
      setTimeout(function() {
        params.api.sizeColumnsToFit();
      });
    });
    this.agGridNativeElement.nativeElement.addEventListener('contextmenu', function ($event: any) {
      $event.preventDefault();
    });
    this.gridOptions.api.setDatasource(this.agDataSource);
  }

  onCellContextMenu($event) {
    $event.event.preventDefault();
    const e = $event.event;

    let posx = 0;
    let posy = 0;

    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft +
      document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop +
      document.documentElement.scrollTop;
    }

    this.contextMenu = {
      X: posx,
      Y: posy,
      state: true
    };
  }

  rowDoubleClicked($event) {
    this.openSplit();
  }

  openSplit() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe( res => {
      if (res.matches) {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 0,
          formSize: 100,
        };
      } else {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 70,
          formSize: 30,
        };
      }
    });
  }

  readSplitOptions(event) {
    this.splitOptions = event.opt;
  }

  updateData(isCreation: boolean) {
    if (isCreation) this.row = null;
    this.gridOptions.api.refreshInfiniteCache();
  }

  quickSearch(data: string) {
    this.gridApi.setQuickFilter(data);
  }

  resizeGrid(event) {
    this.gridApi.sizeColumnsToFit();
  }

  clearRow() {
    this.row = <IEntityAppType>{};
    this.dataRow.updateRowData(this.row);
  }

  generalSearch(value: string) {
    if (value) this.gridOptions.api.setDatasource({
      rowCount: null,
      getRows: (rowParams: IGetRowsParams) => {
        this.appTypeService.getAppTypesGeneralFilter(
          value, rowParams.startRow, rowParams.endRow, rowParams.sortModel, rowParams.filterModel
        ).subscribe(res => {
          rowParams.successCallback(this.processAppTypesFields(res.applicationTypes), res.lastRow);
        });
      }
    });
    else this.gridOptions.api.setDatasource(this.agDataSource);
  }

  deleteSelected() {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows && selectedRows.length) this.nbDialogService.open(
        this.dialogRef,
        {context: 'Are you sure you want to delete all selected application types?'}
      ).onClose.subscribe(accepted => {
        if (accepted) {
          const deleteCalls: Observable<boolean>[] = [];
          for (const row of selectedRows) deleteCalls.push(this.appTypeService.deleteAppType(row.applicationTypeId));
          forkJoin(deleteCalls).subscribe(success => {
            if (success.every(b => b === true)) {
              this.adminService.showToast('success', 'All selected application types deleted');
            } else {
              this.adminService.showToast('danger', 'One or more application types couldn\'t be deleted');
            }
            this.clearRow();
            this.gridApi.refreshInfiniteCache();
            this.gridApi.deselectAll();
          });
        }
      });
    }
  }

}
