import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { GridApi, GridOptions, ColumnApi, IDatasource, IGetRowsParams } from 'ag-grid-community';
import { InstallationService } from './installations.service';
import { Installation, InstallationResponse} from './installations.model';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { INSTALLATIONS_GRID_OPTIONS, INSTALLATIONS_DEFAULT_COL_DEF, INSTALLATIONS_COL_DEFS } from './installations.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NbDialogService } from '@nebular/theme';
import { AdminService } from '../admin.service';
import { Observable, forkJoin } from 'rxjs';

@Component({
  selector: 'app-installations',
  templateUrl: './installations.component.html',
  styleUrls: ['./installations.component.scss']
})
export class InstallationsComponent implements OnInit {
  @ViewChild('splitContent', { static: false}) splitContent;
  @ViewChild('agGrid', { read: ElementRef, static: false }) agGridNativeElement: ElementRef;
  @ViewChild('dialog', { read: TemplateRef, static: false }) dialogRef: TemplateRef<any>;

  row: Installation = <Installation>{};
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  gridOptions: GridOptions = INSTALLATIONS_GRID_OPTIONS;
  defaultColDef = INSTALLATIONS_DEFAULT_COL_DEF;
  columnDefs = INSTALLATIONS_COL_DEFS;
  splitOptions = {
    open: false,
    direction: 'horizontal',
    contentSize: 100,
    formSize: 0,
  };

  contextMenu = {
    X: 0,
    Y: 0,
    state: false,
  };

  TitleForm = 'Detalles';

  private agDataSource: IDatasource = {
    rowCount: null,
    getRows: (rowParams: IGetRowsParams) => this.installationService.getAllInstallationsInfiniteScroll(
      rowParams.startRow,
      rowParams.endRow,
      rowParams.sortModel,
      rowParams.filterModel
    )
    .subscribe(
      (res: InstallationResponse) => rowParams.successCallback(this.processInstallationFields(res.allInstallations), res.lastRow)
    )
  };


  constructor(
    private installationService: InstallationService,
    private dataRow: RowDataService,
    private breakPointObserver: BreakpointObserver,
    private nbDialogService: NbDialogService,
    private adminService: AdminService,
  ) { }

  ngOnInit() {
  }

  private processInstallationFields(installations: Installation []) {
    for (const app of installations) {
      if (this.row && this.row.installationId === app.installationId) {
        this.row = <Installation>JSON.parse(JSON.stringify(app));
        this.dataRow.updateRowData(this.row);
      }
    }
    return installations;
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
    window.addEventListener('resize', function() {
      setTimeout(function() {
        params.api.sizeColumnsToFit();
      });
    });
    this.agGridNativeElement.nativeElement.addEventListener('contextmenu', function ($event: any) {
      $event.preventDefault();
    });
    this.gridOptions.api.setDatasource(this.agDataSource);
  }

  onCellContextMenu(event: any) {
    event.event.preventDefault();
    const e = event.event;
    let posx = 0;
    let posy = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft +
      document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop +
      document.documentElement.scrollTop;
    }
    this.contextMenu = {
      X: posx,
      Y: posy,
      state: true
    };
  }

  onGridRowClicked(row) {
    this.row = JSON.parse(JSON.stringify(row.data));
    this.dataRow.updateRowData(this.row);
  }

  rowDoubleClicked($event) {
    this.openSplit();
  }

  clearRow() {
    this.row = <Installation>{};
    this.dataRow.updateRowData(this.row);
  }

  openSplit() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe( res => {
      if (res.matches) {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 0,
          formSize: 100,
        };
      } else {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 70,
          formSize: 30,
        };
      }
    });
  }

  resizeGrid($event) {
    this.gridApi.sizeColumnsToFit();
  }

  readSplitOptions(event: any) {
    if (event.opt) {
      this.splitOptions = event.opt;
    }
  }

  updateData(isCreation: boolean) {
    if (isCreation) { this.row = null; }
    this.gridOptions.api.refreshInfiniteCache();
  }

  quickSearch(data: string) {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(data);
    }
  }

  generalSearch(value: string) {
    if (value) {
      this.gridOptions.api.setDatasource({
        rowCount: null,
        getRows: (rowParams: IGetRowsParams) => {
          this.installationService.getAppsGeneralFilter(
            value, rowParams.startRow, rowParams.endRow, rowParams.sortModel, rowParams.filterModel
          ).subscribe(res => {
            rowParams.successCallback(res.applicationGroups, res.lastRow);
          });
        }
      });
    } else { this.gridOptions.api.setDatasource(this.agDataSource); }
  }

  deleteSelected() {
    const selectedRows = this.gridOptions.api.getSelectedRows();
    if (selectedRows && selectedRows.length) this.nbDialogService.open(
      this.dialogRef, { context: 'Are you sure you want to delete all selected installations?' }
    ).onClose.subscribe(accepted => {
      if (accepted) {
        const deleteCalls: Observable<boolean>[] = [];
        for (const row of selectedRows) deleteCalls.push(this.installationService.deleteInstallation(row.installationId));
        forkJoin(deleteCalls).subscribe(success => {
          if (success.every(b => b === true)) {
            this.adminService.showToast('success', 'All selected installations deleted');
          } else {
            this.adminService.showToast('danger', 'One or more installations couldn\'t be deleted');
          }
          this.clearRow();
          this.gridOptions.api.refreshInfiniteCache();
          this.gridOptions.api.deselectAll();
        });
      }
    });
  }

}
