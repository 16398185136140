import { Component, OnInit, ViewChild } from '@angular/core';
import { EffectivePermissionInfo, EffectivePermission } from 'projects/pplus/src/lib/models/permissions/effectivePermissions.model';
import { ELEMENT_TYPE_EFFECTIVE_PERMISSIONS_HEADERS } from './columnDefs';
import { EffectivePermissionsService } from 'projects/pplus/src/lib/services/permissions/effective-permissions.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AutocompleteUsersComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-users/autocomplete-users.component';
import { AutocompleteEntitiesComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-entities/autocomplete-entities.component';
import { AutocompleteOperationsComponent } from 'projects/pplus/src/lib/components/searchers/autocomplete-operations/autocomplete-operations.component';
import { ComboElementTypesComponent } from 'projects/pplus/src/lib/components/searchers/combo-element-types/combo-element-types.component';
import { GridOptions } from 'ag-grid-community';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';

@Component({
  selector: 'pplus-effective-permissions-element-types',
  templateUrl: './effective-permissions-element-types.component.html',
  styleUrls: ['./effective-permissions-element-types.component.scss']
})
export class EffectivePermissionsElementTypesComponent implements OnInit {
  @ViewChild(AutocompleteUsersComponent, { static: true }) searchUser: AutocompleteUsersComponent;
  @ViewChild(AutocompleteEntitiesComponent, { static: true }) searchEntity: AutocompleteEntitiesComponent;
  @ViewChild(AutocompleteOperationsComponent, { static: true }) searchOperation: AutocompleteOperationsComponent;
  @ViewChild(ComboElementTypesComponent, { static: true }) searchElementType: ComboElementTypesComponent;

  gridOptions: GridOptions;
  gridApi: any;
  columnDefs: any;
  effectivePermission: EffectivePermission;
  loaderId: string;

  constructor(private effectivePermissionsService: EffectivePermissionsService,
    private ngxLoaderService: NgxUiLoaderService) { }

  ngOnInit() {
    this.gridOptions = <GridOptions>{};
    this.gridOptions.frameworkComponents = {
      checkBoxComponent: AgCheckboxComponent
    };

    this.effectivePermission = new EffectivePermission();
    this.columnDefs = ELEMENT_TYPE_EFFECTIVE_PERMISSIONS_HEADERS;
    this.loaderId = 'loader-' + new Date().getTime();
  }


  getPermissionInfo() {
    if (this.searchEntity.selectedValue !== undefined && this.searchUser.selectedValue !== undefined &&
      this.searchEntity.selectedValue !== null && this.searchUser.selectedValue !== null) {
      this.ngxLoaderService.startLoader(this.loaderId);
      this.effectivePermissionsService.getEffectivePermissions(
        this.searchEntity.selectedValue.entityId,
        this.searchUser.selectedValue.userId,
        this.searchOperation.selectedValue.operationId,
        this.searchElementType.selectedElement.elementTypeId, null).subscribe(
          res => {
            this.effectivePermission = res;
            this.ngxLoaderService.stopLoader(this.loaderId);
          },
          error => {
            this.effectivePermission = new EffectivePermission();
            this.ngxLoaderService.stopLoader(this.loaderId);
          });
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });
  }

}
