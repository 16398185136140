import { ColDef } from "ag-grid-community";
import { ADMIN_DEFAULT_COL_DEF } from "../admin.model";

export const INSTALLATION_STRINGS_DEFAULT_COL_DEF: ColDef = Object.assign({}, ADMIN_DEFAULT_COL_DEF, {
  // Component-specific options
});

export const INSTALLATION_STRINGS_COL_DEFS: ColDef[] = [
  {
    field: 'installationStringId',
    headerName: 'ID',
    checkboxSelection: function (params) {
      return params.columnApi.getRowGroupColumns().length === 0;
    },
  },
  {
    field: 'nameString',
    headerName: 'Nombre',
  },
  {
    field: 'codeString',
    headerName: 'Código',
  },
  {
    field: 'associatedPanelType',
    headerName: 'Tipo de panel asociado',
  },
  {
    field: 'associatedNumPanel',
    headerName: 'Nº paneles asociados',
    filter: 'agNumberColumnFilter',
  },
  {
    field: 'peakPower',
    headerName: 'Potencia pico',
  },
  {
    field: 'addedText',
    headerName: 'Nota',
  },
  {
    field: 'inverterId',
    headerName: 'Inversor asociado',
    valueFormatter: function (r) {
      if (r && r.data) {
        const inverter = r.data.inverter;
        return inverter ? inverter.name : '';
      }
    },
  },
];
