import { Validators } from '@angular/forms';
import { Fields, DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';

const GENERALES: Fields[] = [
    {
        disabled: true,
        name: 'permissionId',
        placeholder: 'ID',
        type: 'text',
        value: '',
    },
    {
        name: 'description',
        placeholder: 'DESCRIPCION',
        type: 'text',
        value: '',
    },
    {
        name: 'operationId',
        placeholder: 'OPERACION',
        type: 'select',
        value: '',
        options: [],
        validators: [
            Validators.required,
        ],
    },
    {
        name: 'elementTypeId',
        label: 'TIPO DE ELEMENTO',
        type: 'select',
        value: '',
        options: [],
        validators: [
            Validators.nullValidator,
        ],
    },
    {
        name: 'entityTypeId',
        label: 'TIPO DE ENTIDAD',
        type: 'select',
        value: '',
        options: [],
        validators: [
            Validators.nullValidator,
        ],
    },
    {
        name: 'permitted',
        placeholder: 'PERMITIDO',
        type: 'checkbox',
        value: false,
    },
];

export const GENERIC_PERMISSIONS_FORM: DynamicForm[] = [
    {
        title: 'DATOS GENERALES',
        open: true,
        fields: GENERALES,
    },
];
