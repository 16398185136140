export class DynamicForm {
    title: string;
    fields: Fields [];
    open ?= false;
    stages?: number[];
}

export class Fields {
    type: string;
    name: string;
    label?: string;
    hidden ?= false;
    placeholder ?= '';
    value: string | boolean | number | number [];
    disabled ?= false;
    validators?: any [];
    options ?: any [];
    stage ?: string;
    multiple ?= false;
    searchName ?: string;
}
