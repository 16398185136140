import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { STRING_HEADERS } from './string-settings';
import { GridOptions } from 'ag-grid-community';
import { NbToastrService } from '@nebular/theme';
import { StyleComponent } from '../domains/components/style/style.component';
import { StringService } from './string.service';
import { Istring } from 'projects/pplus/src/lib/interfaces/interfaces';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';

@Component({
  selector: 'app-string',
  templateUrl: './string.component.html',
  styleUrls: ['./string.component.scss']
})
export class StringComponent implements OnInit {

  @ViewChild('splitContent', { static: true}) splitContent;
  @ViewChild('agGrid', { read: ElementRef, static: true}) agGridNativeElement: ElementRef;

  splitOptions = {
    open: false,
    direction: 'horizontal',
    contentSize: 100,
    formSize: 0,
  };

  items = [
    { title: 'm1'},
    { title: 'm2'}
  ];

  contextMenu = {
    X: 0,
    Y: 0,
    state: false,
  };

  row: Istring = {
    Id: null,
    StringName: null,
    StringCode: null,
    AsociatedPanelType: null,
    NumberAsociatedPanels: null,
    PeakPower: null,
    Note: null,
    AsociatedInverter: null,
    RecalPeakPower: null,
  };

  TitleForm = 'Detalles';
  gridApi;
  gridColumnApi;
  detailsButton = 'Detalles Generales';
  data;
  gridOptions: GridOptions;
  columnDefs = STRING_HEADERS;
  pageX;
  pageY;

  formStatus: boolean;

  constructor(private stringService: StringService,
              private toastr: NbToastrService,
              private dataRow: RowDataService,
              private breakPointObserver: BreakpointObserver) {
    this.gridOptions = <GridOptions>{};
    this.gridOptions.paginationPageSize = 20;
    this.gridOptions.rowDragManaged = true;
    this.gridOptions.enableSorting = true;
    this.gridOptions.enableFilter = true;
    this.gridOptions.floatingFilter = true;
    this.gridOptions.frameworkComponents = {
      checkBoxComponent: AgCheckboxComponent,
      styleComponent: StyleComponent
    };
    this.gridOptions.overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="assets/loader.svg" alt="loader" class="img-fluid" style="width:40px;height:40px;"></span>';
    this.gridOptions.rowSelection = 'multiple';

  }

  ngAfterViewInit() {}

  ngOnInit() {
    this.dataRow.currentRowData.subscribe(row => this.row = <Istring>row);
   /* this.panelService.getAllDomains()
    .subscribe( (res) => {
      this.data = res;
    });*/
  }

  onGridRowClicked(row) {
    this.row = {
      Id: row.data.Id,
      StringName: row.data.StringName,
      StringCode: row.data.StringCode,
      AsociatedPanelType: row.data.AsociatedPanelType,
      NumberAsociatedPanels: row.data.NumberAsociatedPanels,
      PeakPower: row.data.PeakPower,
      Note: row.data.Note,
      AsociatedInverter: row.data.AsociatedInvestor,
      RecalPeakPower: row.data.RecalPeakPower,
    };
    this.dataRow.updateRowData(this.row);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();

    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function() {
      setTimeout(function() {
        params.api.sizeColumnsToFit();
      });
    });

    this.agGridNativeElement.nativeElement.addEventListener('contextmenu', function ($event: any) {
      $event.preventDefault();
    });
  }

  onCellContextMenu($event) {
    $event.event.preventDefault();
    const e = $event.event;
    // console.log($event);
    // console.log('X: ' + $event.event.clientX + '  Y:' + $event.event.clientY);

    let posx = 0;
    let posy = 0;

    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft +
      document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop +
      document.documentElement.scrollTop;
    }

    this.contextMenu = {
      X: posx,
      Y: posy,
      state: true
    };

    this.row = {
      Id: $event.data.Id,
      StringName: $event.data.StringName,
      StringCode: $event.data.StringCode,
      AsociatedPanelType: $event.data.AsociatedPanelType,
      NumberAsociatedPanels: $event.data.NumberAsociatedPanels,
      PeakPower: $event.data.PeakPower,
      Note: $event.data.Note,
      AsociatedInverter: $event.data.AsociatedInvestor,
      RecalPeakPower: $event.data.RecalPeakPower,
    };
  }

  rowDoubleClicked($event) {
    this.row = {
      Id: $event.data.Id,
      StringName: $event.data.StringName,
      StringCode: $event.data.StringCode,
      AsociatedPanelType: $event.data.AsociatedPanelType,
      NumberAsociatedPanels: $event.data.NumberAsociatedPanels,
      PeakPower: $event.data.PeakPower,
      Note: $event.data.Note,
      AsociatedInverter: $event.data.AsociatedInvestor,
      RecalPeakPower: $event.data.RecalPeakPower,
    };
    this.openSplit();
  }

  openSplit() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe( res => {
      if (res.matches) {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 0,
          formSize: 100,
        };
      } else {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 70,
          formSize: 30,
        };
      }
    });
  }

  showToast(icon, status, title , msg) {
    this.toastr.show(title, msg , { icon, status });
  }

  readSplitOptions(event) {
    this.splitOptions = event.opt;
    if (event.mode === 'create') {
      this.formStatus = true;
    } else {
      this.formStatus = false;
    }
  }

  // NO SE DEBERÍA IR A BD PARA ACTUALIZAR
  updateData(data) {
    switch (data.Type) {
      case 'delete':
       /* this.data.forEach( element => {
          if (element.entityDomainId === data.EntityDomainId){
            this.data.splice(this.data.indexOf(element), 1);
            this.gridApi.setRowData(this.data);
          }
        });
        break;
      case 'create':
        this.panelService.getAllDomains()
        .subscribe( (res) => {
          this.data = res;
        });*/
    }
  }

  quickSearch(data: string) {
    this.gridApi.setQuickFilter(data);
  }

  resizeGrid(event) {
    this.gridApi.sizeColumnsToFit();
  }

}
