import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PVSelfConsumptionTemplate, PVSelfConsumptionTemplateUsage, PVSelfConsumptionTemplateProfile } from '../models/PVSelfConsumptionTemplate.model';

@Injectable({
  providedIn: 'root'
})
export class PVSelfConsumptionTemplatesService {

  constructor(private httpClient: HttpClient) {
  }


  getList(): Observable<PVSelfConsumptionTemplate[]> {
    return this.httpClient.get<PVSelfConsumptionTemplate[]>(environment.API_URL + '/api/pvself/consumptiontemplates/getList').pipe(
      map(data => data));
  }

  getUsagesByTemplate(idTemplate: number): Observable<PVSelfConsumptionTemplateUsage[]> {
    return this.httpClient.get<PVSelfConsumptionTemplateUsage[]>(environment.API_URL + '/api/pvself/consumptiontemplates/getUsagesByTemplate/' + idTemplate).pipe(
      map(data => data));
  }

  getProfilesByUsage(idUsage: number): Observable<PVSelfConsumptionTemplateProfile[]> {
    return this.httpClient.get<PVSelfConsumptionTemplateProfile[]>(environment.API_URL + '/api/pvself/consumptiontemplates/getProfilesByUsage/' + idUsage).pipe(
      map(data => data));
  }



}
