import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {  } from '../models/entity.model';
import { User } from '../models/user.model';



@Injectable({
  providedIn: 'root'
})


export class UsersService {

  constructor(private httpClient: HttpClient) {
  }

  searchByFullName(fullName: string): Observable<User[]> {
    return this.httpClient.get<User[]>(environment.API_URL + '/api/user/searchByFullName?fullName=' + fullName).pipe(
      map(data => data));
  }
}
