import { Injectable } from '@angular/core';
import { DynamicForm, Fields } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { Validators } from '@angular/forms';
import { REGEXPS } from '../../admin.model';

@Injectable({
  providedIn: 'root'
})
export class UserDetailsService {

  private readonly generalFields: Fields[] = [
    {
      name: 'userId',
      type: 'text',
      placeholder: 'ID',
      value: '',
      disabled: true,
    },
    {
      name: 'fullName',
      type: 'text',
      placeholder: 'NOMBRE',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(100),
      ],
    },
    {
      name: 'userName',
      type: 'text',
      placeholder: 'USUARIO (@)',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(REGEXPS.email),
      ],
    },
    {
      name: 'notificationEmail',
      type: 'text',
      placeholder: 'EMAIL DE NOTIFICACIONES',
      value: '',
      validators: [
        Validators.required,
        Validators.maxLength(100),
        Validators.pattern(REGEXPS.email),
      ],
    },
    {
      name: 'password',
      type: 'text',
      placeholder: 'PASSWORD',
      value: '',
      validators: [
        Validators.minLength(8),
      ],
    },
    {
      name: 'creationDate',
      type: 'text',
      placeholder: 'FECHA DE ALTA',
      value: '',
      disabled: true,
    },
    {
      name: 'deletionDate',
      type: 'text',
      placeholder: 'FECHA DE BAJA',
      value: '',
      disabled: true,
    },
    {
      name: 'language',
      type: 'select',
      value: '',
      label: 'LANGUAGE',
      validators: [
        Validators.required,
      ],
      options: [
        {
          value: 'ES',
          label: 'ES',
        },
        {
          value: 'PL',
          label: 'PL',
        },
        {
          value: 'EN',
          label: 'EN',
        },
      ],
    },
    {
      name: 'timeZone',
      type: 'select',
      label: 'TIME ZONE',
      value: '',
      validators: [
        Validators.required,
      ],
      options: [
        {
          value: 'CEST',
          label: 'CEST',
        },
        {
          value: 'CET',
          label: 'CET',
        },
        {
          value: 'BST',
          label: 'BST',
        },
        {
          value: 'IST',
          label: 'IST',
        },
        {
          value: 'CST',
          label: 'CST',
        },
        {
          value: 'MSK',
          label: 'MSK',
        },
        {
          value: 'CST',
          label: 'CST',
        },
        {
          value: 'JST',
          label: 'JST',
        },
        {
          value: 'AEST',
          label: 'AEST',
        },
        {
          value: 'NZST',
          label: 'NZST',
        },
      ],
    },
    {
      name: 'activated',
      type: 'checkbox',
      placeholder: 'ACTIVO',
      value: false,
    },
  ];

  private readonly contactFields: Fields[] = [
    {
      name: 'nameContact',
      type: 'text',
      placeholder: 'NOMBRE',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'surnameContact',
      type: 'text',
      placeholder: 'APELLIDOS',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'movil',
      type: 'text',
      placeholder: 'MOVIL',
      value: '',
      validators: [
        Validators.pattern(REGEXPS.phone),
      ],
    },
    {
      name: 'email',
      type: 'text',
      placeholder: 'EMAIL',
      value: '',
      validators: [
        Validators.maxLength(100),
        Validators.pattern(REGEXPS.email),
      ],
    },
    {
      name: 'country',
      type: 'select',
      label: 'PAÍS',
      value: '',
      options: [
        {
          value: 'España',
          label: 'España',
        },
        {
          value: 'Francia',
          label: 'Francia',
        },
        {
          value: 'Italia',
          label: 'Italia',
        },
      ],
    },
    {
      name: 'state',
      type: 'select',
      label: 'PROVINCIA / ESTADO',
      value: '',
      options: [
        {
          value: 'Castilla y León',
          label: 'Castilla y León',
        },
        {
          value: 'Madrid',
          label: 'Madrid',
        },
        {
          value: 'Cataluña',
          label: 'Cataluña',
        },
      ],
    },
    {
      name: 'city',
      type: 'text',
      placeholder: 'POBLACIÓN',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'postalAddress',
      type: 'text',
      placeholder: 'DIRECCIÓN POSTAL',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'postCode',
      type: 'text',
      placeholder: 'CÓDIGO POSTAL',
      value: '',
      validators: [
        Validators.minLength(5),
        Validators.maxLength(5),
        Validators.pattern(/^\d+$/),
      ],
    },
    {
      name: 'telephone',
      type: 'text',
      placeholder: 'TELÉFONO',
      value: '',
      validators: [
        Validators.pattern(REGEXPS.phone),
      ],
    },
    {
      name: 'professionalActivity',
      type: 'text',
      placeholder: 'ACTIVIDAD PROFESIONAL',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'professionalTitle',
      type: 'text',
      placeholder: 'TÍTULO PROFESIONAL',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'department',
      type: 'text',
      placeholder: 'DEPARTAMENTO',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'webPage',
      type: 'text',
      placeholder: 'WEB',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'comment',
      type: 'text',
      placeholder: 'NOTA',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'contactBy',
      type: 'text',
      placeholder: 'CONTACTAR POR',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
  ];

  private readonly financialFields: Fields[] = [
    {
      name: 'businessName',
      type: 'text',
      placeholder: 'RAZÓN SOCIAL',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'nifCif',
      type: 'text',
      placeholder: 'NIF / CIF',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'countryFinancial',
      type: 'select',
      label: 'PAÍS',
      value: '',
      options: [
        {
          value: 'España',
          label: 'España',
        },
        {
          value: 'Francia',
          label: 'Francia',
        },
        {
          value: 'Italia',
          label: 'Italia',
        },
      ],
    },
    {
      name: 'stateFinancial',
      type: 'select',
      label: 'PROVINCIA',
      value: '',
      options: [
        {
          value: 'Castilla y León',
          label: 'Castilla y León',
        },
        {
          value: 'Madrid',
          label: 'Madrid',
        },
        {
          value: 'Cataluña',
          label: 'Cataluña',
        },
      ],
    },
    {
      name: 'cityFinancial',
      type: 'text',
      placeholder: 'POBLACIÓN',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'postalAddressFinancial',
      type: 'text',
      placeholder: 'DIRECCIÓN POSTAL',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
    {
      name: 'postCodeFinancial',
      type: 'text',
      placeholder: 'CÓDIGO POSTAL',
      value: '',
      validators: [
        Validators.minLength(5),
        Validators.maxLength(5),
      ],
    },
    {
      name: 'paymentType',
      type: 'text',
      placeholder: 'FORMA DE PAGO',
      value: '',
    },
    {
      name: 'bankAccount',
      type: 'text',
      placeholder: 'Nº CUENTA BANCARIA',
      value: '',
      validators: [
        Validators.maxLength(999),
      ],
    },
  ];

  private readonly relationsFields: Fields[] = [
    {
      name: 'groupUsers',
      type: 'select',
      label: 'GRUPOS DE USUARIOS',
      multiple: true,
      searchName: 'usersSearch',
      value: [],
      options: [],
    },
    {
      name: 'entities',
      type: 'select',
      label: 'ENTIDADES',
      multiple: true,
      searchName: 'entitiesSearch',
      value: [],
      options: [],
    },
  ];

  constructor() {}

  private getContacts(number: number, contactFields: Fields[]): Fields[] {
    const fields: Fields[] = [];
    for (let i = 0; i < number; i++) for (const f of contactFields) fields.push(Object.assign({}, f, {
      name: f.name + i,
      stage: i,
    }));
    return fields;
  }

  getDynamicForm(contactsNumber?: number, financialsNumber?: number): DynamicForm[] {
    return [
      {
        title: 'DATOS GENERALES',
        fields: this.generalFields,
        open: true,
      },
      {
        title: 'CONTACTO',
        fields: this.getContacts(contactsNumber ? contactsNumber : 1, this.contactFields),
        stages: Array.from(Array(contactsNumber).keys()),
      },
      {
        title: 'FINANCIERO',
        fields: this.getContacts(financialsNumber ? financialsNumber : 1, this.financialFields),
        stages: Array.from(Array(financialsNumber).keys()),
      },
      {
        title: 'RELACIONES DEL USUARIO',
        fields: this.relationsFields,
      },
    ];
  }

}
