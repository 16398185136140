import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SizeBasicLogCalculationsComponent } from './size-basic-log-calculations/size-basic-log-calculations.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatFormFieldModule, MatIconModule, MatInputModule, MatButtonModule, MatSelectModule } from '@angular/material';
import { AgGridModule } from 'ag-grid-angular';
import { NbCardModule } from '@nebular/theme';

@NgModule({
  declarations: [SizeBasicLogCalculationsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    NgxDaterangepickerMd,
    MatFormFieldModule,
    MatIconModule,
    AgGridModule.withComponents(),
    NbCardModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule
  ],
  exports: [
    SizeBasicLogCalculationsComponent
  ]
})
export class SizeBasicModule { }
