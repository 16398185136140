import { MeteorologicalData } from 'projects/pplus/src/lib/models/meteorologicalData.model';
import {
    GridType, PVSelfEconomicValues, PVSelfPredimensionedValues, PVSelfFieldTechnologyType, PVSelfInclinationType,
    PVSelfOrientationType, PVSelfConsumptionRate, PVSelfBalanceInputData, PVSelfFieldUbicationType,
    PVSelfConsumptionOrigin, PVSelfFinancialInputData, PVSelfCalculationConsumptionTemplateProfile,
    MarketType, PVSelfHabitsDataType, PVSelfBillingHabitsData, PVSelfCalculationMeteorologicalData, PVSelfInstallationLosses, PVSelfOptimalPowerCriteriaData, PVSelfReportData
} from './PvSelfCalculation.model';

// Datos de cálculo necesarios para pasar al motor de cálculo y generar los resultados

export class PVSelfEngineCalculation {

    // Año de cálculo
    calculateYear: number;

    // Latitud y longirud en grados
    // latitude: number;
    // longitude: number;

    economicValues: PVSelfEconomicValues;
    predimensionedValues: PVSelfPredimensionedValues;

    // TAXES
    generalIPC: number;
    electricIPC: number;
    electricityPurchaseTax: number;
    electricitySaleTax: number;
    saleIVA: number;
    purchaseIVA: number;
    deductibleIVA: boolean;
//    financialInterest: number;

    // Productores
    producers: PVSelfEngineProducer[];

    // Consumidores
    consumers: PVSelfEngineConsumer[];

    // Datos seleccionados por el usuario para el cálculo del balance
    balanceInputData: PVSelfBalanceInputData;
    financialInputData: PVSelfFinancialInputData;

    optimalPowerCriteriaData: PVSelfOptimalPowerCriteriaData;

    reportData: PVSelfReportData;


    constructor() {
        this.producers = [];
        this.consumers = [];
    }
}


export class PVSelfEngineProducer {
    installation: PVSelfEngineInstallation;
}


export class PVSelfEngineInstallation {
    latitude: number;
    longitude: number;
    ubication: string;
    radiationData: PVSelfCalculationMeteorologicalData;
    minTempData: PVSelfCalculationMeteorologicalData;
    maxTempData: PVSelfCalculationMeteorologicalData;
    gridType: GridType;
    fields: PVSelfEngineInstallationField[];
    losses: PVSelfInstallationLosses;

    constructor() {
        this.fields = [];
        this.losses = new PVSelfInstallationLosses();
    }
}



export class PVSelfEngineInstallationField {
    ubication: PVSelfFieldUbicationType;
    technology: PVSelfFieldTechnologyType;
    inclination: number;
    orientation: number;
    inclinationType: PVSelfInclinationType;
    orientationType: PVSelfOrientationType;
    panelId: number;
    inverterId: number;
    numOfInverters: number;
    numSerialPanels: number;
    numInverterSerials: number;
    useAccumulation: boolean;
    accumulationCapacity: number;
    inverterManufacturerId: number;
    configuratorPeakPower: number;
}




export class PVSelfEngineConsumer {
    marketType: MarketType;
    maximumLegalPower: number;
    rates: PVSelfConsumptionRate[];
    habitsDataType: PVSelfHabitsDataType;
    billingData: PVSelfBillingHabitsData[];
    dailyHabits: number;
    weeklyHabits: number;
    monthlyHabits: number;
    consumptionOrigin: PVSelfConsumptionOrigin;
    billingAnnualData: number;
    consumptionProfiles: PVSelfCalculationConsumptionTemplateProfile[];
    calculatedPowerAdjust: number;
    calculatedEnergyAdjust: number;
    calculatedTotalAdjust: number;
    realEnergyAdjust: number;
    realBillingAdjust: boolean;
    consumptionCurve: string;
    manualTemplateConsumption: boolean;
}

