import { Component, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { DynamicForm } from 'projects/pplus/src/lib/models/components/dynamic-form.model';
import { FormControl, FormGroup } from '@angular/forms';
import { NbToastrService, NbDialogService } from '@nebular/theme';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { EntitiesService } from '../../entities/entities.service';
import { STYLES_FORM } from './styles-form';
import { map } from 'rxjs/operators';
import { StyleService } from '../style-services.service';

@Component({
  selector: 'app-styles-details',
  templateUrl: './styles-details.component.html',
  styleUrls: ['./styles-details.component.scss']
})
export class StylesDetailsComponent implements OnInit {
  @Output() splitOptions = new EventEmitter();
  @Output() updateDelete = new EventEmitter();

  formGroup: FormGroup;
  formData = STYLES_FORM;

  data: any;

  constructor(
    private toastService: NbToastrService,
    private dataRow: RowDataService,
    private styleService: StyleService,
    private entitieService: EntitiesService,
    private dialog: NbDialogService,
  ) {
    this.generateForm(this.formData);
  }

  showToast(icon, status, title , msg) {
    this.toastService.show(title, msg , { icon, status });
  }

  generateForm(data: DynamicForm []) {
    this.entitieService.getEntitiesList().subscribe( (entities: any) => {
      const fieldsCtrls = {};
      data.forEach(item => {
        const fields = item.fields;
        fields.forEach( f => {
          if (f.name === 'entityDomainId') {
            const opts = [];
            entities.map( o => {
              opts.push({
                label: o.code,
                value: o.entityId,
              });
            });
            f.options = opts;
          }
          fieldsCtrls[f.name] = new FormControl(f.value, f.validators);
        });
      });
      this.formGroup = new FormGroup(fieldsCtrls);
    });
  }

  ngOnInit() {
    this.dataRow.currentRowData.pipe( map( o => {
      this.formData.forEach( data => {
        const fields = data.fields;
        fields.map(field => {
          if (o[field.name]) {
            field.value = o[field.name];
          } else field.value = '';
        });
      });
      return o;
    })).subscribe( data => {
      this.data = data;
      this.generateForm(this.formData);
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      this.showToast('', 'warning', 'Rellene todos los campos', 'Info');
    } else {
      const data = this.formGroup.value;
      const entityDomainId = data.entityDomainId;
      this.styleService.createStyle(entityDomainId, data).subscribe(success => {
        if (success) {
          this.showToast('', 'success', 'Se ha creado un nuevo estilo: ' + data.description, 'Elemento creado');
          this.clearData();
          this.updateDelete.emit({Type: 'create'});
        } else {
          this.showToast('', 'danger', 'Se ha producido un error al crear el estilo: ' + data.description, 'ERROR');
        }
      });
    }
  }

  update() {
    if (this.data.entityDomainStyleId == null) {
      this.showToast('', 'info', 'Seleccione un estilo', 'Info');
    } else {
      const data = this.formGroup.value;
      this.styleService.updateStyles(this.data.entityDomainId, data).subscribe(success => {
        if (success) {
          this.showToast('', 'success', 'Se ha actualizado el estilo: ' + data.description, 'Estilo actualizado');
          this.updateDelete.emit({Type: 'create'});
        } else {
          this.showToast('', 'danger', 'Se ha producido un error al intentar actualizar el estilo: ' + data.description, 'Error');
        }
      });
    }
  }

  delete() {
    if (this.data.entityDomainStyleId == null) {
      this.showToast('', 'info', 'Seleccione un estilo', 'Info');
    } else {
      this.styleService.deleteStyles(this.data.entityDomainStyleId).subscribe(success => {
        if (success) {
          this.showToast('', 'success', 'Se ha borrado el estilo: ' + this.data.description , 'Estilo eliminado');
          this.updateDelete.emit({Type: 'delete', entityDomainStyleId: this.data.entityDomainStyleId});
          this.clearData();
        } else {
          this.showToast('', 'danger', 'Se ha producido un error al intentar borrar el estilo: ' + this.data.description, 'Error');
        }
      });
    }
  }

  exit() {
    const opt = {
      open: false,
      direction: 'horizontal',
      contentSize: 100,
      formSize: 0,
    };
    this.splitOptions.emit({opt: opt, mode: 'default'});
    this.clearData();
  }

  copy() {
    if (this.data.entityDomainStyleId == null) {
      this.showToast('', 'info', 'Seleccione un estilo para copiar la información', 'Info');
    } else {
      this.formGroup.setValue(Object.assign(this.data, this.formGroup.value, {
        entityDomainStyleId: null,
        description: '',
      }));
    }
  }

  clearData() {
    this.formData.forEach( data => {
      const fields = data.fields;
      fields.map(field => {
        field.value = '';
      });
    });
    this.generateForm(this.formData);
  }

  openDialog(dialog: TemplateRef<any>) {
    if (this.data.entityDomainStyleId == null) {
      this.showToast('', 'info', 'No hay ninguna entidad seleccionada', 'Info');
    } else {
      this.dialog.open(dialog, {context: 'Se va a eliminar el estilo ' + this.data.description, hasScroll: false});
    }
  }

  closeDialog(ref) {
    ref.close();
  }

}
