import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, empty, forkJoin, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ISelfViewDto, ISelfViewData, ISelfViewDataEconomico, GlobalType, SelfViewParams, EnergyDataOrigin } from '../models/selfview.model';
import { isUndefined } from 'util';
import { SelfViewActiveEnergyResults } from '../models/selfview-active.model';

const predefined: ISelfViewDto = {
  selfViewDataPower: null,
  selfViewDataEconomico: null,
};

@Injectable({
  providedIn: 'root'
})
export class SelfViewService {
  private _selfViewDataApi = environment.API_URL + '/api/selfview/getGeneralResults';
  private _selfViewEconomicDataApi = environment.API_URL + '/api/selfview/getGeneralEconomicResults';
  private _selfViewReactiveDataApi = environment.API_URL + '/api/selfview/getReactiveResults';
  private _selfViewActiveDataApi = environment.API_URL + '/api/selfview/getActiveResults';

  private _globalType = new BehaviorSubject(<GlobalType>GlobalType.Energy);
  globalType = this._globalType.asObservable();

  private _data = new BehaviorSubject(<ISelfViewDto>null);
  private _dataCompare = new BehaviorSubject(<ISelfViewDto>null);
  private _dataActive = new BehaviorSubject(<SelfViewActiveEnergyResults>null);
  private _dataActiveCompare = new BehaviorSubject(<SelfViewActiveEnergyResults>null);
  private _dataReactive = new BehaviorSubject(<any>null);
  private _dataReactiveCompare = new BehaviorSubject(<any>null);

  dataNormal = this._data.asObservable();
  dataComparison = this._dataCompare.asObservable();
  dataActive = this._dataActive.asObservable();
  dataActiveCompare = this._dataActiveCompare.asObservable();
  dataReactive = this._dataReactive.asObservable();
  dataReactiveCompare = this._dataReactiveCompare.asObservable();

  private _params = new ReplaySubject<SelfViewParams>();
  private _paramsCompare = new ReplaySubject<SelfViewParams>();

  params = this._params.asObservable();
  paramsCompare = this._paramsCompare.asObservable();

  constructor(private http: HttpClient) { }


  getData(params: SelfViewParams) {
    this._params.next(params);
    return forkJoin([this.getSelfViewData(params), this.getSelfViewDataEconomico(params)])
      .subscribe(([powerData, economicData]: [ISelfViewData, ISelfViewDataEconomico]) => {
        const obj: ISelfViewDto = {
          selfViewDataPower: powerData,
          selfViewDataEconomico: economicData
        };
        this._data.next(obj);
      });
  }

  getDataCompare(params: SelfViewParams) {
    this._paramsCompare.next(params);
    return forkJoin([this.getSelfViewData(params), this.getSelfViewDataEconomico(params)])
      .subscribe(([powerData, economicData]: [ISelfViewData, ISelfViewDataEconomico]) => {
        const obj: ISelfViewDto = {
          selfViewDataPower: powerData,
          selfViewDataEconomico: economicData
        };
        this._dataCompare.next(obj);
      });
  }

  // TODO: tipar correctamente
  getActiveData(data: any) {
    return this.getSelfViewActiveData(data)
      .subscribe((activeData: SelfViewActiveEnergyResults) => {
        // const actData = {
        //   dateRange: {
        //     to: data.to,
        //     from: data.from
        //   },
        //   activeData,
        // };
        this._dataActive.next(activeData);
      });
  }

  getActiveDataCompared(data) {
    return this.getSelfViewActiveData(data)
      .subscribe((activeData: SelfViewActiveEnergyResults) => {
        this._dataActiveCompare.next(activeData);
      });
  }


  getReactiveData(data: any) {
    return this.getSelfViewReactiveData(data)
      .subscribe((reactiveData: any) => {
        const reactData = {
          dateRange: {
            to: data.to,
            from: data.from
          },
          reactiveData,
        };
        this._dataReactive.next(reactData);
      });
  }

  getReactiveDataCompared(data) {
    return this.getSelfViewReactiveData(data)
      .subscribe((reactiveData: any) => {
        this._dataReactiveCompare.next(reactiveData);
      });
  }

  private getSelfViewData(data): Observable<ISelfViewData> {
    return this.http
      .post(this._selfViewDataApi, data)
      .pipe(map((res: ISelfViewData) => {
        return res;
      }));
  }

  private getSelfViewDataEconomico(data): Observable<ISelfViewDataEconomico> {
    return this.http
      .post(this._selfViewEconomicDataApi, data)
      .pipe(map((res: ISelfViewDataEconomico) => {
        return res;
      }));
  }

  private getSelfViewActiveData(data): Observable<SelfViewActiveEnergyResults> {
    return this.http
      .post(this._selfViewActiveDataApi, {}, { params: data })
      .pipe(map((res: SelfViewActiveEnergyResults) => {
        return res;
      }));
  }

  private getSelfViewReactiveData(data): Observable<any> {
    return this.http
      .post(this._selfViewReactiveDataApi, {}, { params: data })
      .pipe(map((res: any) => {
        return res;
      }));
  }


  getEnergyDataOrigins(InstallationId: number): Observable<EnergyDataOrigin> {
    return this.http.get<EnergyDataOrigin>(
      environment.API_URL + '/api/energydataorigins/GetByInstallationId?InstallationId=' + InstallationId).pipe(tap(res => {
      })
      );
  }

  removeDataCompare() {
    this._paramsCompare.next(null);
    this._dataCompare.next(null);
  }

  removeDataReactiveCompare() {
    this._dataReactiveCompare.next(null);
  }

  updateGlobalType(type: GlobalType) {
    this._globalType.next(type);
  }
}
