import { Component } from '@angular/core';
import { IFloatingFilter, IFloatingFilterParams } from 'ag-grid-community';
import { AgFrameworkComponent } from 'ag-grid-angular';

export interface AgBoolFloatingFilterParams extends IFloatingFilterParams<any, any> {}

@Component({
  selector: 'ag-bool-floating-filter',
  templateUrl: './ag-bool-floating-filter.component.html',
  styleUrls: ['./ag-bool-floating-filter.component.scss']
})
export class AgBoolFloatingFilterComponent implements IFloatingFilter<any, any, AgBoolFloatingFilterParams>, AgFrameworkComponent<AgBoolFloatingFilterParams> {

  private params: AgBoolFloatingFilterParams;

  currentValue: boolean;

  constructor() { }

  agInit(params: AgBoolFloatingFilterParams) {
    this.params = params;
  }

  onParentModelChanged(parentModel: any) {
    this.currentValue = parentModel ? parentModel.filter : null;
  }

}
