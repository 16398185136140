import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { GlobalType, IPeriod } from '../../../models/selfview.model';
import { MatTableDataSource } from '@angular/material';
import { ACTIVE_ENERGY_SERIES_DATA } from '../../selfview-active-main/selfview-active-main.series';
import * as XLSX from 'xlsx';
import { SelfViewService } from '../../../services/selfview-general.service';



@Component({
  selector: 'pplus-selfview-active-periods-table',
  templateUrl: './selfview-active-periods-table.component.html',
  styleUrls: ['./selfview-active-periods-table.component.scss']
})
export class SelfviewActivePeriodsTableComponent implements OnInit {
  @ViewChild('table', { static: false, read: ElementRef }) table: ElementRef;

  flipped: boolean;

  // Charts
  globalType: GlobalType;
  ACTIVE_ENERGY_DATA = ACTIVE_ENERGY_SERIES_DATA;
  WhToKwh = 0.001;


  compareMode = false;
  activeData: any;
  activeDataCompare: any;

  periods: IPeriod[] = [];

  type;

  ps = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6'];

  // GRID

  dataSource = new MatTableDataSource<any>([]);
  columnDefinitions = [
    'period',
    'consumption',
    'networkConsumption',
    'selfConsumption',
    'excedentary',
    'generated'
  ];


  gridData: any;
  showGrid = false;

  constructor(private selfViewService: SelfViewService) {
    this.flipped = true;
  }

  ngOnInit() {
    this.selfViewService.globalType.subscribe((type: GlobalType) => {
      this.globalType = type;

      this.selfViewService.dataActive.subscribe((data: any) => {
        if (data) {
          this.activeData = data; // data.activeData;
          this.buildGrid();
        }
      });

      this.selfViewService.dataActiveCompare.subscribe((data: any) => {

      });
    });
  }


  buildGrid() {

    const rows = [];

    // row totales
    rows.push({
      period: 'Total',
      consumption: this.activeData.totalConsumption,
      networkConsumption: this.activeData.totalNetworkConsumption,
      selfConsumption: this.activeData.totalSelfConsumption,
      generated: this.activeData.totalGenerated,
      excedentary: this.activeData.totalExcedentary,
    });

    this.ps.forEach((element, index) => {
      const consumption: number = this.activeData.totalConsumptionByPeriod[index];
      const networkConsumption: number = this.activeData.totalNetworkConsumptionByPeriod[index];
      const selfConsumption: number = this.activeData.totalSelfConsumptionByPeriod[index];
      const generated: number = this.activeData.totalGeneratedByPeriod[index];
      const excedentary: number = this.activeData.totalExcedentaryByPeriod[index];
      const row = {
        period: `P${index + 1}`,
        consumption,
        networkConsumption,
        selfConsumption,
        generated,
        excedentary
      };

      rows.push(row);

    });

    this.dataSource.data = rows;
  }

  /**
   * Exports grid to excel
   */
  exportToExcel() {
    let ws: XLSX.WorkSheet;
    ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Table.xlsx');
  }

}
