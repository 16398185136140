import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { SelfViewService } from '../../../services/selfview-general.service';
import { INIT_OPTIONS, REACTIVE_TOTALS_CHART_OPTIONS } from './totals-chart.config';
import { REACTIVE_TOTAL_CHART_SERIES, REACTIVE_TOTAL_CHART_SERIES_COMPARED } from './totals-chart.series';
@Component({
  selector: 'pplus-totals-chart',
  templateUrl: './totals-chart.component.html',
  styleUrls: ['./totals-chart.component.scss']
})
export class TotalsChartComponent implements OnInit {
  @Input() capacitivaProperty: string;
  @Input() inductivaProperty: string;

  /**
   * Flag to show capacitivo of totals chart component
   */
  showCapacitivo = true;
  /**
   * Flag to show inductivo of totals chart component
   */
  showInductivo = true;

  /**
   * Chart Data from service
   */
  data: any; // TODO: tipar correctamente

  /**
   * Compare Chart Data from service
   */
  comparedData: any; // TODO: tipar correctamente

  /**
   * Diff in days between start and end date
  */
  rangeLenght: number = null;

  /**
   * Initial options for chart
   */
  initOpts = INIT_OPTIONS;

  /**
   * Chart options of totals chart component
   */
  chartOptions; // TODO: tipar correctamente

  constructor(private selfviewService: SelfViewService) { }

  ngOnInit() {

    this.selfviewService.dataReactive.subscribe((data: any) => {
      if (data) {
        // calculate range length
        this.rangeLenght = moment(data.dateRange.to).startOf('day').diff(moment(data.dateRange.from).startOf('day'), 'days');

        this.data = data.reactiveData.hourlyData;
        this.initChart();
      }
    });

    this.selfviewService.dataReactiveCompare.subscribe((data: any) => {
      if (data) {
        this.comparedData = data.hourlyData;
        this.initChart();
      }
    });

  }

  initChart() {
    if (this.data) {
      const capacitiva = this.data.map( item => -item[this.capacitivaProperty]);
      const inductiva = this.data.map( item => item[this.inductivaProperty]);

      const series = REACTIVE_TOTAL_CHART_SERIES;
      series[0].data = capacitiva;
      series[1].data = inductiva;

      if (this.comparedData) {
        const capacitivaCompared = this.comparedData.map( item => -item[this.capacitivaProperty]);
        const inductivaCompared = this.comparedData.map( item => item[this.inductivaProperty]);

        const comparedSeries = REACTIVE_TOTAL_CHART_SERIES_COMPARED;
        comparedSeries[0].data = capacitivaCompared;
        comparedSeries[1].data = inductivaCompared;
        series.push(...comparedSeries);
      }

      this.chartOptions = REACTIVE_TOTALS_CHART_OPTIONS;
      this.chartOptions.xAxis[0].data = this.data.map(item => moment(item.hour).format('HH:mm'));
      this.chartOptions.legend.selected = {
        'Capacitivo': this.showCapacitivo,
        'Capacitivo*': this.showCapacitivo,
        'Inductivo': this.showInductivo,
        'Inductivo*': this.showInductivo
      };
      this.chartOptions.series = series;
    }
  }

}
