import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { InvertersService } from '../inverters.service';
import { InverterDailyDataParams, InverterDailyData, InverterModel } from '../inverters.models';
import { MatTableDataSource } from '@angular/material';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-inverters-element-block',
  templateUrl: './inverters-element-block.component.html',
  styleUrls: ['./inverters-element-block.component.scss'],
})
export class InvertersElementBlockComponent implements OnInit {
  @ViewChild('table', { static: false, read: ElementRef }) table: ElementRef;
  @Input() idInstallation: number;

  flipped = false;

  date: Date = new Date();
  selectedInverter: InverterModel;
  selectedInverterPower: number;
  selectedInverterEnergy: number;
  inverters: InverterModel[] = [];
  invertersDailyData: InverterDailyData[] = [];
  dataSourceTable4 = new MatTableDataSource<any>([]);
  columnDefinitionsTable4 = ['title', 'date', 'energy', 'power'];

  constructor(
    private invertersService: InvertersService
  ) {

  }

  ngOnInit() {

    this.invertersService.installationInverters.subscribe(res => {
      this.inverters = [];
      this.dataSourceTable4.data = [];
      this.selectedInverter = null;
      this.selectedInverterEnergy = 0;
      this.selectedInverterPower = 0;

      if (res) {
        this.inverters = res;
        this.getDailyData();

        if (this.inverters.length === 1) {
          this.selectedInverter = this.inverters[0];
        }
      }
    });



    this.invertersService.installationDailyData.subscribe((dailyData: InverterDailyData[]) => {
      if (dailyData) {
        this.invertersDailyData = dailyData;
        this.dataSourceTable4.data = dailyData.map(inverter => {
          inverter.date = moment(inverter.date).format('DD/MM/YYYY');
          return inverter;
        });
        this.inverterSelected();

      }
    });


    // this.invertersService.installationDailyData.subscribe( (dailyData: InverterDailyData[]) => {
    //   if (dailyData) {
    //     this.invertersDailyData = dailyData;
    //     this.dataSourceTable4.data = dailyData.map( inverter => {
    //       inverter.date = moment(inverter.date).format('DD/MM/YYYY');
    //       return inverter;
    //     }
    //     );
    //     if (this.invertersDailyData.length === 1 ) {
    //       this.selectedElement = this.invertersDailyData[0];
    //     }
    //   } else {
    //     this.invertersDailyData = [];
    //     this.dataSourceTable4.data = [];
    //   }

    // });

  }


  /**
   * Data change event function for single element
   */
  dateChange(event) {
    //    this.selectedElement = null;
    this.date = event.value.toDate();
    if (this.selectedInverter) {
      this.getDailyData();
    }
  }

  getDailyData() {
    const params: InverterDailyDataParams = {
      installationId: this.idInstallation,
      date: this.date.toUTCString()
    };
    this.invertersService.getInstallationDailyData(params);
  }


  inverterSelected() {

    const id = this.selectedInverter.inverterId;
    const data = this.invertersDailyData.find(element => element.inverterId === id);
    this.selectedInverterEnergy = data.totalEnergy;
    this.selectedInverterPower = data.mediumPower;
  }

  exportToExcel(type: number) {
    let ws: XLSX.WorkSheet;
    ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Table.xlsx');
  }

}
