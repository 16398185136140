import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { TabsComponent } from 'projects/pplus/src/lib/components/tabs/tabs.component';
import { PVSelfCalculation } from '../../models/PvSelfCalculation.model';
import { PVSelfCalculationService } from '../../services/pvself-calculation.service';
import { TranslateService } from '@ngx-translate/core';
import { PVSelfProducersService } from '../../services/pvself-producers.service';

@Component({
  selector: 'pplus-pvself-producer',
  templateUrl: './pvself-producer.component.html',
  styleUrls: ['./pvself-producer.component.scss']
})
export class PVSelfProducerComponent implements AfterViewInit {
  _calculation: PVSelfCalculation;
  @ViewChild(TabsComponent, { static: false }) tabsComponent;
  @ViewChild('producer', { static: false}) producerTemplate;

  constructor(private calculationService: PVSelfCalculationService,
    private translateService: TranslateService,
    private producersService: PVSelfProducersService) { }

  ngAfterViewInit() {
    this.producersService.setTabs(this.tabsComponent);
    this.createProducers();
  }

  createProducers() {
    this._calculation = this.calculationService.get();
    this._calculation.producers.forEach(x => {
      this.tabsComponent.openTab((x.pvSelfCalculationProducerId === 0 ? this.translateService.instant('COMMON.ELEMENTS.INSTALLATION') : x.description), this.producerTemplate, {
        'tabType': this.tabsComponent.tabType,
        'tabContentOrigin': '',
        '_calculation': this._calculation,
        'producer': x
      }, true);
    });
  }

}
