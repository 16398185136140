import { EChartOption } from 'echarts';
/**
 * Instant char options
 */
export const WEATHER_STATION_CHART_OPTIONS: EChartOption = {
  tooltip: {
    show: true,
    triggerOn: 'mousemove',
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      crossStyle: {
        color: '#999'
      }
    }
  },
  toolbox: {
    show: true,
    left: 'center',
    itemSize: 20,
    top: '0%',
    feature: {
      dataZoom: {
        show: true,
        yAxisIndex: true,
        title: {zoom: 'Zoom', back: 'Anular.', refresh: 'Refrescar'}
      },
      restore: {show: true, title: 'Anular Todo'},
      saveAsImage: {title: 'Descargar'}
    }
  },
  dataZoom: [
    {type: 'inside', xAxisIndex: [0]},
    {type: 'slider', xAxisIndex: [0], left: 'center'}],
  legend: {
      show: true,
      bottom: 50
  },
  grid: [
      { left: '5%', top: '10%', width: '90%', bottom: '120'},
  ],
  xAxis: [
      {gridIndex: 0, type: 'category', data: []},
  ],
  yAxis: [
      {gridIndex: 0}
  ],
  series: []
};

/**
 * Initial options for chart
 */
export const INIT_OPTIONS = {
  renderer: 'svg',
  width: 'auto',
  height: 'auto'
};
