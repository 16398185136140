import {Component, EventEmitter, OnInit, Output, OnDestroy} from '@angular/core';
import { PPlusElectricPhase } from '../../../../../../projects/pplus/src/lib/models/pplus.enums';
import { EChartOption } from 'echarts';
import { Subscription } from 'rxjs';
import { ZeroInjectionService } from '../zero-injection.service';
import { ZeroInjectionInstantData, ZeroInjectionArmonicData, ZeroInjectionInstantResults, ZERO_INJECTION_DATA, ArmonicMode, ZeroInjectionChartMode } from '../zero-injection.models';
import { ZERO_INJECTION_INSTANT_CHART_OPTIONS, ZERO_INJECTION_ARMONIC_CHART_OPTIONS } from './zero-injection-instant.config';

@Component({
  selector: 'app-zero-injection-instant',
  templateUrl: './zero-injection-instant.component.html',
  styleUrls: ['./zero-injection-instant.component.scss']
})
export class ZeroInjectionInstantComponent implements OnInit, OnDestroy {
  @Output() changePhase = new EventEmitter<PPlusElectricPhase>();

  // FIX TO USE ENUM IN TEMPLATE
  ZERO_INJECTION_DATA = ZERO_INJECTION_DATA;
  PPlusElectricPhase = PPlusElectricPhase;
  ArmonicMode = ArmonicMode;
  ZeroInjectionChartMode = ZeroInjectionChartMode;

  chartMode: ZeroInjectionChartMode = ZeroInjectionChartMode.Phases;

  instantData: ZeroInjectionInstantData[];
  armonicPercentageData: ZeroInjectionArmonicData[];
  armonicDegreeData: ZeroInjectionArmonicData[];

  phase: PPlusElectricPhase = PPlusElectricPhase.R;
  armonicMode: ArmonicMode = ArmonicMode.Percentage;

  flipped = false;

  /**
   * Echarts instance of reactive chart component
   */
  echartsInstance: any;
  chartState: any = {};
  chartOptions = ZERO_INJECTION_INSTANT_CHART_OPTIONS;
  armonicChartOptions = ZERO_INJECTION_ARMONIC_CHART_OPTIONS;

  instantResultsSubscription: Subscription;

  constructor(
    private zeroInjectionService: ZeroInjectionService) {

  }

  ngOnInit() {
    this.instantResultsSubscription = this.zeroInjectionService.instantResults.subscribe( (results: ZeroInjectionInstantResults ) => {
      if (results) {
        this.instantData = results.instantData;
        this.armonicPercentageData = results.armonicPercentageData;
        this.armonicDegreeData = results.armonicDegreeData;
        this.buildIntantCharts();
        this.buildArmonicChart();
      }

    });

  }

  ngOnDestroy(): void {
    this.instantResultsSubscription.unsubscribe();
  }

  buildIntantCharts() {
    const timeSerie = this.instantData.map( element => element.milliseconds);
    const powerSerie = this.instantData.map( element => element.power);
    const voltageSerie = this.instantData.map( element => element.voltage);
    const currentSerie = this.instantData.map( element => element.current);
    const consumedPowerSerie = this.instantData.map( element => element.consumedPower);
    const consumedCurrentSerie = this.instantData.map( element => element.consumedCurrent);

    const series = [
      {
          name: ZERO_INJECTION_DATA.VOLTAGE.label,
          id: ZERO_INJECTION_DATA.VOLTAGE.id,
          type: 'line',
          xAxisIndex: 0,
          yAxisIndex: 0,
          data: voltageSerie,
          smooth: true,
          symbol: 'none',
          itemStyle: {
            normal: {
              color: ZERO_INJECTION_DATA.VOLTAGE.color
            }
          }
      },
      {
        name: ZERO_INJECTION_DATA.CURRENT.label,
        id: ZERO_INJECTION_DATA.CURRENT.id,
        type: 'line',
        xAxisIndex: 0,
        yAxisIndex: 1,
        data: currentSerie,
        smooth: true,
        symbol: 'none',
        itemStyle: {
          normal: {
            color: ZERO_INJECTION_DATA.CURRENT.color
          }
        }
      },
      {
        name: ZERO_INJECTION_DATA.CURRENT_CONSUMED.label,
        id: ZERO_INJECTION_DATA.CURRENT_CONSUMED.id,
        type: 'line',
        xAxisIndex: 0,
        yAxisIndex: 1,
        data: consumedCurrentSerie,
        smooth: true,
        symbol: 'none',
        itemStyle: {
          normal: {
            color: ZERO_INJECTION_DATA.CURRENT_CONSUMED.color
          }
        }
      },
      {
        name: ZERO_INJECTION_DATA.POWER.label,
        id: ZERO_INJECTION_DATA.POWER.id,
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 2,
        data: powerSerie,
        smooth: true,
        symbol: 'none',
        itemStyle: {
          normal: {
            color: ZERO_INJECTION_DATA.POWER.color
          }
        }
      },
      {
        name: ZERO_INJECTION_DATA.POWER_CONSUMED.label,
        id: ZERO_INJECTION_DATA.POWER_CONSUMED.id,
        type: 'line',
        xAxisIndex: 1,
        yAxisIndex: 2,
        data: consumedPowerSerie,
        smooth: true,
        symbol: 'none',
        itemStyle: {
          normal: {
            color: ZERO_INJECTION_DATA.POWER_CONSUMED.color
          }
        }
      }
    ];
    this.chartOptions.xAxis[0].data = timeSerie;
    this.chartOptions.xAxis[1].data = timeSerie;
    this.chartOptions.series = series;
    this.chartOptions = {...this.chartOptions, ...this.chartOptions};
  }

  buildArmonicChart() {
    let armonicData: ZeroInjectionArmonicData[];
    if (this.armonicMode === ArmonicMode.Percentage) {
      armonicData = this.armonicPercentageData;
    } else {
      armonicData = this.armonicDegreeData;
    }

    const timeSerie = armonicData.map( element => element.milliseconds);
    const powerSerie = armonicData.map( element => element.power);
    const voltageSerie = armonicData.map( element => element.voltage);
    const currentSerie = armonicData.map( element => element.current);
    const consumedPowerSerie = armonicData.map( element => element.consumedPower);
    const consumedCurrentSerie = armonicData.map( element => element.consumedCurrent);

    const series: EChartOption.SeriesLine[] = [
      {
          name: ZERO_INJECTION_DATA.VOLTAGE.label,
          id: ZERO_INJECTION_DATA.VOLTAGE.id,
          type: 'bar',
          data: voltageSerie,
          smooth: true,
          symbol: 'none',
          itemStyle: {
            normal: {
              color: ZERO_INJECTION_DATA.VOLTAGE.color
            }
          }
      },
      {
        name: ZERO_INJECTION_DATA.CURRENT.label,
        id: ZERO_INJECTION_DATA.CURRENT.id,
        type: 'bar',
        data: currentSerie,
        smooth: true,
        symbol: 'none',
        itemStyle: {
          normal: {
            color: ZERO_INJECTION_DATA.CURRENT.color
          }
        }
      },
      {
        name: ZERO_INJECTION_DATA.CURRENT_CONSUMED.label,
        id: ZERO_INJECTION_DATA.CURRENT_CONSUMED.id,
        type: 'bar',
        data: consumedCurrentSerie,
        smooth: true,
        symbol: 'none',
        itemStyle: {
          normal: {
            color: ZERO_INJECTION_DATA.CURRENT_CONSUMED.color
          }
        }
      },
      {
        name: ZERO_INJECTION_DATA.POWER.label,
        id: ZERO_INJECTION_DATA.POWER.id,
        type: 'bar',
        data: powerSerie,
        smooth: true,
        symbol: 'none',
        itemStyle: {
          normal: {
            color: ZERO_INJECTION_DATA.POWER.color
          }
        }
      },
      {
        name: ZERO_INJECTION_DATA.POWER_CONSUMED.label,
        id: ZERO_INJECTION_DATA.POWER_CONSUMED.id,
        type: 'bar',
        data: consumedPowerSerie,
        smooth: true,
        symbol: 'none',
        itemStyle: {
          normal: {
            color: ZERO_INJECTION_DATA.POWER_CONSUMED.color
          }
        }
      }
    ];
    this.armonicChartOptions.xAxis[0].data = timeSerie;
    this.armonicChartOptions.series = series;
    this.armonicChartOptions = {...this.armonicChartOptions, ...this.armonicChartOptions};
  }

  /**
   * Toggles serie for main chart.
   * @param serie: Current serie
   * @param $event: State
   */
  toggleSerie(serie: any, $event) {
    // Get the zoom level of the graph and apply it in legend selection
    let zoom = null;
    if (this.echartsInstance) {
      zoom = this.echartsInstance.getOption().dataZoom;
      this.chartOptions.dataZoom = zoom;
    }
    const state = $event.checked;

    this.chartState[serie.label] = state;
    this.chartOptions.legend.selected = this.chartState;
    this.chartOptions = {...this.chartOptions, ...this.chartOptions};
  }

  /**
   * Inits bar chart state. Show All series
   */
  initCharState() {
    Object.keys(ZERO_INJECTION_DATA).forEach((key) => {
      this.chartState[ZERO_INJECTION_DATA[key].label] = true;
    });
  }

  onPhaseChange() {
    console.log('onPhaseChange', this.phase);
    this.changePhase.emit(this.phase);
  }

  onArmonicChange() {
    this.buildArmonicChart();
  }

  changeChartMode() {
    if (this.chartMode === ZeroInjectionChartMode.Phases) {
      this.chartMode = ZeroInjectionChartMode.Armonics;
    } else {
      this.chartMode = ZeroInjectionChartMode.Phases;
    }
  }
}
