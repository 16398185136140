import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { NbToastrService } from '@nebular/theme';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { StyleService } from './style-services.service';
import { STYLES_HEADERS } from '../settings';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';
import { StylesDetailsComponent } from './styles-details/styles-details.component';

class Styles {
  entityDomainStyleId: number;
  description: string;
  css: string;
  // logo: string;
  colors: string;
  activated: boolean;
  deleted: boolean;
  entityDomainId: number;
}

@Component({
  selector: 'app-styles',
  templateUrl: './styles.component.html',
  styleUrls: ['./styles.component.scss']
})
export class StylesComponent implements OnInit, AfterViewInit {
  @ViewChild('splitContent', { static: false}) splitContent;
  @ViewChild('agGridStyles', { read: ElementRef, static: false }) agGridNativeElement: ElementRef;
  @ViewChild(StylesDetailsComponent, {static: false}) stylesDetails: StylesDetailsComponent;

  splitOptions = {
    open: false,
    direction: 'horizontal',
    contentSize: 100,
    formSize: 0,
  };

  items = [
    { title: 'm1'},
    { title: 'm2'}
  ];

  contextMenu = {
    X: 0,
    Y: 0,
    state: false,
  };

  row: Styles = new Styles();

  TitleForm = 'Detalles';
  gridApi;
  gridColumnApi;
  detailsButton = 'Detalles Generales';
  public data: any[] = [];
  gridOptions: GridOptions;
  columnDefs = STYLES_HEADERS;
  pageX;
  pageY;

  constructor(
    private styleService: StyleService,
    private toastr: NbToastrService,
    private dataRow: RowDataService,
    private breakPointObserver: BreakpointObserver,
  ) {
    this.gridOptions = <GridOptions>{};
    this.gridOptions.paginationPageSize = 20;
    this.gridOptions.rowDragManaged = true;
    this.gridOptions.floatingFilter = true;
    this.gridOptions.frameworkComponents = {
      checkBoxComponent: AgCheckboxComponent,
    };
    this.gridOptions.overlayLoadingTemplate = '<span class="ag-overlay-loading-center"><img src="assets/loader.svg" alt="loader" class="img-fluid" style="width:40px;height:40px;"></span>';
    this.gridOptions.rowSelection = 'multiple';
  }

  ngOnInit() {
    this.dataRow.currentRowData.subscribe(row => {
      this.row = <Styles>row;
    });
  }

  ngAfterViewInit(): void {
    this.styleService.getAllStyles(2).subscribe(res => {
      setTimeout(() => {
        this.gridApi.refreshView();
        this.data = res;
      });
    });
  }

  onGridRowClicked(row) {
    this.row = {
      activated: row.data.activated,
      colors: row.data.colors,
      css: row.data.css,
      deleted: row.data.deleted,
      description: row.data.description,
      entityDomainId: row.data.entityDomainId,
      entityDomainStyleId: row.data.entityDomainStyleId,
      // logo: row.data.logo,
    };
    this.dataRow.updateRowData(this.row);
  }

  clearRow() {
    this.row = {
      activated: false,
      colors: '',
      css: '',
      deleted: false,
      description: '',
      entityDomainId: null,
      entityDomainStyleId: null,
      // logo: '',
    };
    this.dataRow.updateRowData(this.row);
    this.stylesDetails.clearData();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();

    // window.addEventListener("resize", function() {
    //   setTimeout(function() {
    //     params.api.sizeColumnsToFit();
    //   });
    // });

    // this.agGridNativeElement.nativeElement.addEventListener('contextmenu', function ($event: any) {
    //   $event.preventDefault();
    // });
  }

  onCellContextMenu($event) {
    $event.event.preventDefault();
    const e = $event.event;

    let posx = 0;
    let posy = 0;

    if (e.pageX || e.pageY) {
      posx = e.pageX;
      posy = e.pageY;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX + document.body.scrollLeft +
      document.documentElement.scrollLeft;
      posy = e.clientY + document.body.scrollTop +
      document.documentElement.scrollTop;
    }

    this.contextMenu = {
      X: posx,
      Y: posy,
      state: true
    };

    this.row = {
      activated: $event.data.activated,
      colors: $event.data.colors,
      css: $event.data.css,
      deleted: $event.data.deleted,
      description: $event.data.description,
      entityDomainId: $event.data.entityDomainId,
      entityDomainStyleId: $event.data.entityDomainStyleId,
      // logo: $event.data.logo,
    };
  }

  rowDoubleClicked($event) {
    this.openSplit();
  }

  openSplit() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe( res => {
      if (res.matches) {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 0,
          formSize: 100,
        };
      } else {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 70,
          formSize: 30,
        };
      }
    });
  }

  showToast(icon, status, title , msg) {
    this.toastr.show(title, msg , { icon, status });
  }

  readSplitOptions(event) {
    this.splitOptions = event.opt;
  }

  // NO SE DEBERÍA IR A BD PARA ACTUALIZAR
  updateData(data) {
    switch (data.Type) {
      case 'delete':
        this.data.forEach( element => {
          if (element.entityDomainStyleId === data.entityDomainStyleId) {
            this.data.splice(this.data.indexOf(element), 1);
            this.gridApi.setRowData(this.data);
          }
        });
        break;
      case 'create':
        this.styleService.getAllStyles(2).subscribe(res => {
          this.data = res;
        });
    }
  }

  quickSearch(data: string) {
    if (this.gridApi) { this.gridApi.setQuickFilter(data); }
  }

  resizeGrid(event) {
    if (this.gridApi) { this.gridApi.sizeColumnsToFit(); }
  }

}
