import { Injectable } from '@angular/core';
import { TabsComponent } from 'projects/pplus/src/lib/components/tabs/tabs.component';
import { PVSelfCalculationProducer } from '../models/PvSelfCalculation.model';

@Injectable({
  providedIn: 'root'
})
export class PVSelfProducersService {
  producersTabs: TabsComponent;

  constructor() { }

  setTabs(_tabs: TabsComponent) {
    this.producersTabs = _tabs;
  }

  getProducers() {
    const producers: PVSelfCalculationProducer[] = [];
    this.producersTabs.dynamicTabs.forEach(x => {
      x.dataContext.producer.description = x.title;
      producers.push(x.dataContext.producer);
    });

    return producers;
  }


  setProducers(producers: PVSelfCalculationProducer[]) {
    if (this.producersTabs !== undefined) {
      producers.forEach((x, index) => {
        this.producersTabs.dynamicTabs[index].dataContext.producer = x;
      });
    }
  }


}



