import { Component, OnInit } from '@angular/core';
import { GENERIC_PERMISSIONS_HEADERS } from './columnDefs';
import { PermissionsService } from 'projects/pplus/src/lib/services/permissions/permissions.service';
import { Permission } from 'projects/pplus/src/lib/models/permissions/permission.model';
import { AgCheckboxComponent } from 'projects/pplus/src/lib/components/ag-grid/ag-checkbox/ag-checkbox.component';
import { GridOptions } from 'ag-grid-community';
import { RowDataService } from 'projects/pplus/src/lib/components/split-form/services/row-data.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'pplus-generic-permissions',
  templateUrl: './generic-permissions.component.html',
  styleUrls: ['./generic-permissions.component.scss']
})
export class GenericPermissionsComponent implements OnInit {

  splitOptions = {
    open: false,
    direction: 'horizontal',
    contentSize: 100,
    formSize: 0,
  };

  gridOptions: GridOptions;
  gridApi: any;
  gridColumnApi: any;
  columnDefs: any;
  rowData: any[] = [];
  permissionsList: Permission[];
  selectedRow: any;
  titleForm = 'Detalles';

  constructor(private permissionsService: PermissionsService,
    private dataRowService: RowDataService,
    private breakPointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.gridOptions = <GridOptions>{};
    this.gridOptions.frameworkComponents = {
      checkBoxComponent: AgCheckboxComponent
    };
    this.columnDefs = GENERIC_PERMISSIONS_HEADERS;
    this.getPermissionsList();
  }

  getPermissionsList() {
    this.permissionsService.getList().subscribe(res => {
      this.permissionsList = res;
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    /*       const service = this.domainService;
           const dataSource = {
             rowCount: null,
             getRows: function (rowParams: IGetRowsParams) {
               console.log('asking for ' + rowParams.startRow + ' to ' + rowParams.endRow);
               setTimeout(function () {
                 service.getAllDomainsInfiniteScroll(
                   rowParams.startRow, rowParams.endRow, rowParams.sortModel, rowParams.filterModel
                 ).subscribe((data: any) => {
                   rowParams.successCallback(data.entityDomains, data.lastRow);
                 });
               }, 1000);
             }
           };
           this.gridOptions.api.setDatasource(dataSource);
       */
    params.api.sizeColumnsToFit();

    window.addEventListener('resize', function () {
      setTimeout(function () {
        params.api.sizeColumnsToFit();
      });
    });

    // if (this.agGridNativeElement) {
    //   this.agGridNativeElement.nativeElement.addEventListener('contextmenu', function ($event: any) {
    //     $event.preventDefault();
    //   });
    // }
  }

  onGridRowClicked(row) {
    this.selectedRow = JSON.parse(JSON.stringify(row.data));
    this.dataRowService.updateRowData(this.selectedRow);
  }

  rowDoubleClicked($event) {
    this.openSplit();
  }

  openSplit() {
    this.breakPointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(res => {
      if (res.matches) {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 0,
          formSize: 100,
        };
      } else {
        this.splitOptions = {
          open: true,
          direction: 'horizontal',
          contentSize: 70,
          formSize: 30,
        };
      }
    });
  }

  resizeGrid(event) {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  readSplitOptions(event: any) {
    if (event.opt) {
      this.splitOptions = event.opt;
    }
  }

  quickSearch(data: string) {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(data);
    }
  }

  clearRow() {
    this.selectedRow = new Permission();
    this.dataRowService.updateRowData(this.selectedRow);
  }

  updateData(data) {

  }

}
