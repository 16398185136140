import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {GlobalType} from '../../models/selfview.model';
import * as moment from 'moment';
import { SelfViewService } from '../../services/selfview-general.service';

@Component({
  selector: 'app-reactive-totals-series',
  templateUrl: './reactive-totals-series.component.html',
  styleUrls: ['./reactive-totals-series.component.scss']
})
export class ReactiveTotalSeriesComponent implements OnInit {
  @Output() updateType = new EventEmitter<boolean>();

  // FIX TO USE ENUM IN TEMPLATE
  GlobalType = GlobalType;

  /**
   * Flipped state of component card
   */
  flipped: boolean;

  /**
   * Diff in days between start and end date
  */
  rangeLenght: number = null;

  /**
   * Store local type selected
   */
  type: GlobalType;

  // GRID
  /**
   * Sets when to show or hide de grid
   */
  showGrid = false;


  constructor(private selfviewService: SelfViewService) {
    this.flipped = true;
    this.type = GlobalType.Energy;
  }

  ngOnInit() {
    this.selfviewService.dataReactive.subscribe((data: any) => {
      this.showGrid = false;
      if (data && data.reactiveData) {
        this.rangeLenght = moment(data.dateRange.to).startOf('day').diff(moment(data.dateRange.from).startOf('day'), 'days');
        if (this.rangeLenght === 0) {
          this.showGrid = true;
        } else {
          this.showGrid = false;
        }
      }
    });
  }

  /**
   * Change type to Power or Economic mode
   */
  changeType() {
    if (this.type === GlobalType.Economic) {
      this.type = GlobalType.Energy;
    } else {
      this.type = GlobalType.Economic;
    }
  }

}
