import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PVSelfInstallation, PVSelfInstallationField } from 'src/app/modules/pvself/models/PvSelfCalculation.model';
import { TranslateService } from '@ngx-translate/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PVSelfMyConfigurationService } from 'src/app/modules/pvself/services/pvself-my-configuration.service';
import { Entity } from 'projects/pplus/src/lib/models/entity.model';
import { EntitiesService } from 'projects/pplus/src/lib/services/entities.service';

@Component({
  selector: 'pplus-pvself-field-list',
  templateUrl: './pvself-field-list.component.html',
  styleUrls: ['./pvself-field-list.component.scss']
})
export class PVSelfFieldListComponent implements OnInit {
  @Input('installation') _installation: PVSelfInstallation;
  selectedField: PVSelfInstallationField;
  loaderId: string;
  _applicationEntity: Entity;


  @Input() add: boolean;
  @Input('disabled') disabled: boolean;

  @Output() draw = new EventEmitter<any>();
  @Output() mapaPlaces = new EventEmitter<boolean>();
  @Output() changeDraw = new EventEmitter<PVSelfInstallationField>();
  @Output() itemSelected = new EventEmitter<PVSelfInstallationField>();

  constructor(private translate: TranslateService,
    private ngxLoaderService: NgxUiLoaderService,
    private myConfigurationService: PVSelfMyConfigurationService,
    private entitiesService: EntitiesService) { }

  ngOnInit() {
    this.entitiesService.applicationEntity.subscribe(res => {
      this._applicationEntity = res;
    });

    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);
    this.selectedField = this._installation.fields[0];
    this.itemSelected.emit(this.selectedField);
    this.mapaPlaces.emit(true);
  }

  onBlurMethod() {
    this._installation.fields.forEach(element => {
      element.editable = false;
    });
  }

  changeSelected(item: PVSelfInstallationField) {
    this.selectedField = item;
    this.changeDraw.emit(item);
    this.itemSelected.emit(this.selectedField);
    if (item === this._installation.fields[0]) {
      this.mapaPlaces.emit(true);
    } else {
      this.mapaPlaces.emit(false);
    }
  }

  editMode(item) {
    item.editable = true;
  }

  addNewField() {
    this.ngxLoaderService.startLoader(this.loaderId);
    const newField = new PVSelfInstallationField();
    newField.name = this.translate.instant('PVSELF.NEWFIELD')
    this.myConfigurationService.getEntityMyConfiguration(this._applicationEntity.entityId).subscribe(res => {
      newField.inclination = res.inclination;
      newField.orientation = res.orientation;
      newField.automaticPIConfig = res.automaticPIConfig;
      newField.inverterManufacturerId = res.inverterManufacturerId;
      newField.inverterId = res.inverterId;
      newField.numInverterSerials = res.numInverterSerials;
      newField.numOfInverters = res.numOfInverters;
      newField.numSerialPanels = res.numSerialPanels;
      newField.panelId = res.panelId;
      newField.panelManufacturerId = res.panelManufacturerId;
      newField.technology = res.technology;
      this._installation.fields.push(newField);
      this.ngxLoaderService.stopLoader(this.loaderId);
    });
  }

  deleteField(idField: number) {
    const index = this._installation.fields.findIndex(x => x.pvSelfInstallationFieldId === idField);
    this._installation.fields.splice(index, 1);
  }

  cloneField(field: PVSelfInstallationField) {
    const newField = Object.assign({}, field);
    this._installation.fields.push(newField);
  }
}
