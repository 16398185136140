import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './@core/_auth/auth.guard';

export const routes: Routes = [
  {
   path: 'app',
//   canActivate: [AuthGuard],
   loadChildren: () => import('./@core/layout/layout.module').then(mod => mod.PPlusLayoutModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./@core/_auth/auth.module').then(mod => mod.AuthModule)
  },
  {path: '', redirectTo: 'app/dashboard', pathMatch: 'full'},
  {path: '**', redirectTo: 'app/dashboard', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
