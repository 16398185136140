import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { InvertersService } from '../inverters.service';
import { InverterDailyData, InverterEnergyParams, InverterEnergyResults, InverterEnergyData, InverterGroupedEnergyData, InverterModel } from '../inverters.models';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatTableDataSource } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PPlusChartType } from 'projects/pplus/src/lib/models/pplus.enums';
import { INVERTERS_LINE_CHART_OPTIONS, INVERTERS_BAR_CHART_OPTIONS } from '../inverters-chart.config';
import { ChartColors } from '../inverters.chart.colors';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-inverters-main-block',
  templateUrl: './inverters-main-block.component.html',
  styleUrls: ['./inverters-main-block.component.scss']
})
export class InvertersMainBlockComponent implements OnInit {
  @ViewChild('invertersInput', { static: false }) invertersInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @ViewChild('table', { static: false, read: ElementRef }) table: ElementRef;
  @ViewChild('table2', { static: false, read: ElementRef }) table2: ElementRef;
  @ViewChild('table3', { static: false, read: ElementRef }) table3: ElementRef;

  PPlusChartType = PPlusChartType;
  /**
   * Date range of consumption meter component
   */
  dateRange;

  inverters: InverterModel[] = [];

  /**
   * Selected meters of consumption meter component
   */
  selectedInverters: InverterModel[] = [];

  loaderId: string;

  /**
   * Flag for total serie
   */
  total = false;


  invertersEnergyData: InverterEnergyResults;

  /**
 * Initial chart options
 */
  initOpts = {
    renderer: 'svg',
    width: 'auto',
    height: 'auto'
  };

  /**
   * Chart options
   */
  chartOptions;

  flipped = false;


  /** GRIDS */
  dataSourceTable1 = new MatTableDataSource<any>([]);
  dataSourceTable2 = new MatTableDataSource<any>([]);
  dataSourceTable3 = new MatTableDataSource<any>([]);

  columnDefinitionsTable1 = ['title', 'total'];
  columnDefinitionsTable3 = ['title', 'date', 'hour', 'power'];


  constructor(private ngxLoader: NgxUiLoaderService,
    private invertersService: InvertersService) { }



  ngOnInit() {
    this.loaderId = 'loader-' + Math.floor((Math.random() * 100) + 1);

    const last6Months = new Date();
    last6Months.setMonth(last6Months.getMonth() - 6);
    last6Months.setHours(0, 0, 0);
    const today = new Date();
    today.setHours(23, 59, 59);

    this.dateRange = {
      startDate: last6Months.toUTCString(),
      endDate: today.toUTCString(),
    };

    this.invertersService.installationInverters.subscribe(res => {
      this.inverters = res;
      this.selectedInverters = [];
      this.dataSourceTable1.data = [];
      this.dataSourceTable2.data = [];
      this.dataSourceTable3.data = [];
      if (this.chartOptions) {
        this.chartOptions.xAxis[0].data = [];
        this.chartOptions.series = [];
      }
    });


    this.invertersService.invertersEnergyData.subscribe((invertersEnergyData: InverterEnergyResults) => {
      if (invertersEnergyData) {
        this.ngxLoader.stopLoader(this.loaderId);
        this.invertersEnergyData = invertersEnergyData;
        if (invertersEnergyData.chartType === PPlusChartType.Bar) {
          this.initBarChart();
        } else {
          this.initLineChart();
        }

        // table1
        this.dataSourceTable1.data = [this.invertersEnergyData.totalData, ...this.invertersEnergyData.inverterData];
        // table2
        if (this.invertersEnergyData.chartType === PPlusChartType.Bar) {
          const groupedDataArray = [];
          this.invertersEnergyData.inverterData.forEach(inverterData => {
            groupedDataArray.push(...inverterData.groupedData.map(item => {
              return {
                inverterCode: inverterData.inverterCode,
                date: moment(item.date).format('DD/MM/YYYY'),
                hour: moment(item.date).format('HH:mm'),
                total: item.energy
              };
            }));
          });

          this.dataSourceTable2.data = groupedDataArray;
          // table3
        } else {
          const groupedDataArray = [];
          this.invertersEnergyData.inverterData.forEach(inverterData => {
            groupedDataArray.push(...inverterData.hourlyData.map(item => {
              return {
                inverterCode: inverterData.inverterCode,
                date: moment(item.hour).format('DD/MM/YYYY'),
                hour: moment(item.hour).format('HH:mm'),
                total: item.energy
              };
            }));
          });
          this.dataSourceTable3.data = [{
            total: this.invertersEnergyData.totalData.totalEnergy
          }, ...groupedDataArray];
          this.dataSourceTable2.data = groupedDataArray;
        }
      }
    });

  }


  setDateRange(range) {
    this.dateRange = {
      startDate: range.startDate.toUTCString(),
      endDate: range.endDate.toUTCString(),
    };

    if (this.selectedInverters.length) {
      this.getInvertersData();
    }

  }

  /**
   * Inits line chart
   */
  initLineChart() {
    const series = this.generateLineSeries();
    this.chartOptions = { ...INVERTERS_LINE_CHART_OPTIONS };
    this.chartOptions.series = [...series];
    this.chartOptions = { ...this.chartOptions, ...this.chartOptions };
  }


  /**
   * Inits bar chart
   */
  initBarChart() {
    const series = this.generateBarSeries();
    this.chartOptions = { ...INVERTERS_BAR_CHART_OPTIONS };
    this.chartOptions.xAxis[0].data = this.invertersEnergyData.inverterData[0].groupedData.map(item => {
      const time = new Date(item.date);
      return time.toLocaleDateString('es-ES');
    });

    this.chartOptions.series = [...series];
    this.chartOptions = { ...this.chartOptions, ...this.chartOptions };
  }

  /**
   * Generates line series
   *
   */
  generateLineSeries() {
    const series = [];
    this.invertersEnergyData.inverterData.forEach((element: InverterEnergyData, i: number) => {
      const serieData = element.hourlyData.map(item => {
        return [item.hour, item.energy];
      });

      series.push({
        name: this.inverters[i].name,
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: serieData,
        xAxisIndex: 0
      });
    });

    if (this.invertersEnergyData.totalData && this.invertersEnergyData.totalData.hourlyData && this.invertersEnergyData.totalData.hourlyData.length) {

      const serieData = this.invertersEnergyData.totalData.hourlyData.map(item => {
        return [item.hour, item.energy];
      });

      series.push({
        name: 'Total',
        type: 'line',
        smooth: true,
        symbol: 'none',
        data: serieData,
        xAxisIndex: 0
      });
    }
    return series;
  }

  /**
   * Generates series for bar chart
   */
  generateBarSeries() {
    const series = [];

    this.invertersEnergyData.inverterData.forEach((element: InverterEnergyData, indexInverter: number) => {

      const serieData = element.groupedData.map((inverterGroupedEnergyData: InverterGroupedEnergyData) => {
        return inverterGroupedEnergyData.energy;
      });

      series.push(this.createBarChartSerie(
        this.inverters[indexInverter].name,
        serieData,
        ChartColors.active
      ));

    });

    if (this.invertersEnergyData.totalData && this.invertersEnergyData.totalData.groupedData && this.invertersEnergyData.totalData.groupedData.length) {
      const serieData = this.invertersEnergyData.totalData.groupedData.map((inverterGroupedEnergyData: InverterGroupedEnergyData) => {
        return inverterGroupedEnergyData.energy;
      });

      series.push(this.createBarChartSerie(
        'Total',
        serieData,
        ChartColors.total
      ));

    }

    return series;
  }

  /**
   * Creates bar chart serie
   * @param name: serie name
   * @param data: serie data
   * @param color: serie hex color
   * @param stack: serie stack
   * @returns
   */
  createBarChartSerie(name: string, data: any, color: string) {
    return {
      name,
      type: 'bar',
      data,
      xAxisIndex: 0
    };
  }

  /**
   * Inverter selected
   * @param event
   */
  inverterSelected(event: MatAutocompleteSelectedEvent) {
    this.selectedInverters.push(event.option.value);
    this.invertersInput.nativeElement.value = '';

    if (this.selectedInverters.length) {
      this.getInvertersData();
    }
  }

  /**
   * Removes selected inverter
   * @param inverter
   */
  removeSelectedInverter(inverter: InverterModel): void {
    const index = this.selectedInverters.indexOf(inverter);

    if (index >= 0) {
      this.selectedInverters.splice(index, 1);
      if (this.selectedInverters.length) {
        this.getInvertersData();
      }
    }
  }

  /**
   * Selects all inverters
   */
  selectAllInverters() {
    this.selectedInverters = [...this.inverters];
    this.getInvertersData();
  }

  /**
   * Unselects all inverters
   */
  unselectAllInverters() {
    this.selectedInverters = [];
  }

  /**
   * Runs whens total property changes
   */
  onChangeTotal() {
    if (this.selectedInverters.length) {
      this.getInvertersData();
    }
  }

  /**
   * Gets inverters data
   */
  getInvertersData() {
    this.ngxLoader.startLoader(this.loaderId);

    const opt: InverterEnergyParams = {
      inverterIds: this.selectedInverters.map(inverter => inverter.inverterId),
      from: this.dateRange.startDate,
      to: this.dateRange.endDate,
      includeTotal: this.total
    };

    this.invertersService.getInvertersEnergyData(opt);
  }

  /**
   * Gets table2 displayed columns
   * @returns
   */
  getTable2DisplayedColumns() {
    if (this.invertersEnergyData.chartType === PPlusChartType.Bar) {
      return ['title', 'date', 'total'];
    } else {
      return ['title', 'date', 'hour', 'total'];
    }

  }

  exportToExcel(type: number) {
    let ws: XLSX.WorkSheet;
    switch (type) {
      case 1:
        ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        break;
      case 2:
        ws = XLSX.utils.table_to_sheet(this.table2.nativeElement);
        break;
      case 3:
        ws = XLSX.utils.table_to_sheet(this.table3.nativeElement);
        break;
    }
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Table.xlsx');
  }

}
